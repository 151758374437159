import Api from "@/api/api";

// initial state
const state = () => ({
  ConfApp: {
    instance: "alone",
    theme: "light",
    controls: {
      btn_theme: true,
      btn_account: false
    },
    tab: 0,
    community_tab: 0,
    transitionTab: 0,
    partner_id: 4,
    tooltips_shop_url: "",
    tooltips_sale_shop_url: "",
    isBuyedProTooltips: false,
    composite_shop_url: "",
    pdfs_shop_url: "",
    tooltips_buy_full_url: "",
    tooltip_button_type: 0,
    cp_tooltip_button_type: 0,
    tr_tooltip_button_type: 0,
    rt_tooltip_button_type: 0,
    text_rave: "",
    text_partner: "",
    messages: {},
    partner_url: "",
    partner_should_show_tooltips_for_guests: 0,
    show_app_banner: 0,
    renderReport: true,
    renderCommunity: false,
    firstOpenUpdates: true,
    firstOpenCompUpdates: true,
    firstOpenChildUpdates: true,
    blinkFirstime: false,
    firstOpenSocial: false,
    isSalesToDay: false
  },
  http_options: {
    headers: {
      "X-Api-Key": "human-design-token",
      "Client-Partner-Id": 4,
      "Client-Rave": "",
      "Client-Device-Os": "web",
      "Client-Email": ""
    },
    withCredentials: true
  },
  Messages: {},
  DarkTheme: {
    enable: false
  },
  RegTab: 0,
  logoutDialog: false,
  authDialog: false,
  serviceDialog: {
    enable: false,
    authDialog: false,
    message: ""
  },
  universalDialog: {
    enable: false,
    authDialog: false,
    title: "",
    message: "",
    background: "",
    button_name: "",
    button_url: "",
    button_target: "_self",
    button_go_tab: 0
  },
  errorDialog: {
    enable: false,
    message: "",
    image: "",
    button1: "",
    button2: ""
  },
  share_link: "",
  share_dialog: false
});

// getters
const getters = {
  get_messages: (state) => {
    return state.Messages;
  },
  show_tooltips_for_guests: (state) => {
    return state.ConfApp.partner_should_show_tooltips_for_guests;
  },
  get_share_link: (state) => {
    return state.share_link;
  }
};

// actions
const actions = {
  async getSettings({ state, commit, dispatch }) {
    try {
      const result = await Api.getSettings({}, state.http_options);
      if (result.success) {
        localStorage.setItem("messages", JSON.stringify(result.messages));
        state.Messages = result.messages;
        if (result.pro_tooltips_shop_url) {
          commit("update_tooltips_shop_url", result.pro_tooltips_shop_url);
        }
        if (result.composite_shop_url) {
          commit(
            "update_tooltips_composite_shop_url",
            result.composite_shop_url
          );
        }
        if (result.raves_count) {
          commit("account/set_user_raves_count", result.raves_count, {
            root: true
          });
        }
        localStorage.setItem("text_rave", result.text_rave);
        localStorage.setItem("text_partner", result.text_partner);
        localStorage.setItem("partner_url", result.partner.website);
        localStorage.setItem(
          "partner_should_show_tooltips_for_guests",
          result.partner.should_show_tooltips_for_guests
        );
        localStorage.setItem("messages", JSON.stringify(result.messages));
        commit("update_text_rave", result.text_rave);
        commit("update_text_partner", result.text_partner);
        commit("update_messages", result.messages);
        commit("update_partner_url", result.partner.website);
        commit(
          "update_partner_should_show_tooltips_for_guests",
          result.partner.should_show_tooltips_for_guests
        );
        // Add New
        if (result.pro_tooltips_sale_shop_url) {
          commit(
            "update_tooltips_sale_shop_url",
            result.pro_tooltips_sale_shop_url
          );
        }
        if (result.full_tooltips_url) {
          commit("update_tooltips_buy_full_url", result.full_tooltips_url);
        }
        if (result.pdfs_shop_url) {
          commit("update_tooltips_pdfs_shop_url", result.pdfs_shop_url);
        }
        // Community
        if (result.categories) {
          commit("account/set_user_posts_categories", result.categories, {
            root: true
          });
        }
        if (result.show_my_city_posts_only) {
          commit(
            "account/set_user_city_posts_only",
            result.show_my_city_posts_only,
            { root: true }
          );
        }
        if (result.sex) {
          const obj = result.sex;
          let parseTempArr = [];
          Object.keys(obj).forEach((key) => {
            parseTempArr.push({
              text: `${obj[key]}`,
              value: `${key}`
            });
          });
          const arr = parseTempArr.filter(({ value }) => +value > 0);
          commit("account/set_user_sexselect", obj, { root: true });
          commit("account/set_user_my_sexselect", arr, { root: true });
        }
        if (result.marital_statuses) {
          const data = {
            obj: result.marital_statuses,
            commit: "account/set_user_marital_statuses",
            root: true
          };
          dispatch("parseMainObjToArr", data);
        }
        if (result.children) {
          const data = {
            obj: result.children,
            commit: "account/set_user_children_select",
            root: true
          };
          dispatch("parseMainObjToArr", data);
        }
        if (result.targets) {
          const data = {
            obj: result.targets,
            commit: "account/set_user_target_select",
            root: true
          };
          dispatch("parseMainObjToArr", data);
        }
        if (result.chart_types) {
          commit(
            "account/set_search_vocabulary_chart_types",
            result.chart_types,
            { root: true }
          );
        }
        if (result.chart_authorities) {
          commit(
            "account/set_search_vocabulary_chart_authorities",
            result.chart_authorities,
            { root: true }
          );
        }
        if (result.chart_centers) {
          commit(
            "account/set_search_vocabulary_chart_centers",
            result.chart_centers,
            { root: true }
          );
        }
        if (result.chart_channels) {
          commit(
            "account/set_search_vocabulary_chart_channels",
            result.chart_channels,
            { root: true }
          );
        }
        if (result.chart_definitions) {
          commit(
            "account/set_search_vocabulary_chart_definitions",
            result.chart_definitions,
            { root: true }
          );
        }
        if (result.chart_gates) {
          commit(
            "account/set_search_vocabulary_chart_gates",
            result.chart_gates,
            { root: true }
          );
        }
        if (result.chart_profiles) {
          commit(
            "account/set_search_vocabulary_chart_profiles",
            result.chart_profiles,
            { root: true }
          );
        }
        if (result.chart_quarters) {
          commit(
            "account/set_search_vocabulary_chart_quarters",
            result.chart_quarters,
            { root: true }
          );
        }
      }
    } catch (e) {
      dispatch("errorHandler", e, { root: true });
    }
  },
  parseMainObjToArr({ commit }, data) {
    let parseTempArr = [];
    const keys = Object.keys(data.obj);
    keys.forEach((key) => {
      parseTempArr.push({
        text: `${data.obj[key]}`,
        value: `${key}`
      });
    });
    // return parseTempArr;
    if (data.root) {
      commit(data.commit, parseTempArr, { root: true });
    } else {
      commit(data.commit, parseTempArr);
    }
  }
};

// mutations
const mutations = {
  set_token(state, token) {
    state.http_options.headers["X-Api-Key"] = token;
  },
  update_dark_theme(state, v) {
    state.DarkTheme.enable = v;
  },
  set_conf_app(state, obj) {
    state.confApp = obj;
  },
  update_conf_app_first_open_updates(state, v) {
    state.ConfApp.firstOpenUpdates = v;
  },
  update_conf_app_first_open_comp_updates(state, v) {
    state.ConfApp.firstOpenCompUpdates = v;
  },
  update_conf_app_first_open_child_updates(state, v) {
    state.ConfApp.firstOpenChildUpdates = v;
  },
  update_conf_app_theme(state, val) {
    state.ConfApp.theme = val;
    // Send from iframe to wordpress change-theme-color directive
    /* const data = {
      theme: val
    };
    window.parent.postMessage(data, "*"); */
  },
  update_tooltips_shop_url(state, url) {
    state.ConfApp.tooltips_shop_url = url;
  },
  update_tooltips_composite_shop_url(state, url) {
    state.ConfApp.composite_shop_url = url;
  },
  update_tooltips_sale_shop_url(state, url) {
    state.ConfApp.tooltips_sale_shop_url = url;
  },
  update_tooltips_buy_full_url(state, url) {
    state.ConfApp.tooltips_buy_full_url = url;
  },
  update_tooltips_pdfs_shop_url(state, url) {
    state.ConfApp.pdfs_shop_url = url;
  },
  update_blink_first_time(state, v) {
    state.ConfApp.blinkFirstime = v;
  },
  update_tooltips_button_type(state, rave) {
    state.ConfApp.tooltip_button_type = rave;
  },
  update_cp_tooltips_button_type(state, cp) {
    state.ConfApp.cp_tooltip_button_type = cp;
  },
  update_text_rave(state, html) {
    state.ConfApp.text_rave = html;
  },
  update_text_partner(state, html) {
    state.ConfApp.text_partner = html;
  },
  update_messages(state, obj) {
    state.Messages = obj;
  },
  update_partner_url(state, url) {
    state.ConfApp.partner_url = url;
  },
  update_partner_should_show_tooltips_for_guests(state, v) {
    state.ConfApp.partner_should_show_tooltips_for_guests = v;
  },
  update_reg_tab(state, num) {
    state.RegTab = num;
  },
  update_service_modal(state, obj) {
    state.serviceDialog.enable = true;
    if (obj.authDialog) {
      state.serviceDialog.authDialog = obj.authDialog;
    }
    state.serviceDialog.message = obj.message;
  },
  update_service_close_modal(state, obj) {
    state.serviceDialog.enable = true;
    if (obj.authDialog) {
      state.serviceDialog.authDialog = obj.authDialog;
    }
    state.serviceDialog.message = obj.message;
  },
  set_service_modal_enable(state, v) {
    state.serviceDialog.enable = v;
  },
  update_logout_dialog(state, v) {
    state.logoutDialog = v;
  },
  update_login_dialog(state, v) {
    state.authDialog = v;
  },
  update_error_modal(state, obj) {
    state.errorDialog.enable = true;
    state.errorDialog.message = obj.message;
    if (obj.image) {
      state.errorDialog.image = obj.image;
    }
    if (obj.button1) {
      state.errorDialog.button1 = obj.button1;
    }
    if (obj.button2) {
      state.errorDialog.button2 = obj.button2;
    }
  },
  update_universal_modal(state, obj) {
    state.universalDialog.enable = true;
    if (obj.authDialog) {
      state.universalDialog.authDialog = obj.authDialog;
    }
    state.universalDialog.title = obj.title;
    state.universalDialog.message = obj.message;
    state.universalDialog.background = obj.background;
    state.universalDialog.button_name = obj.button_name;
    state.universalDialog.button_url = obj.button_url;
    state.universalDialog.button_target = obj.button_target;
    state.universalDialog.button_go_tab = obj.button_go_tab;
  },
  update_conf_app_partner_id(state, val) {
    state.ConfApp.partner_id = val;
  },
  update_is_buyed_pro_tooltips(state, val) {
    state.ConfApp.isBuyedProTooltips = val;
  },
  set_conf_app_partner_id(state, val) {
    state.http_options.headers["Client-Partner-Id"] = val;
  },
  set_user_ext_ref(state, val) {
    state.http_options.headers["Client-Rave"] = val;
  },
  set_user_device_os(state, val) {
    state.http_options.headers["Client-Device-Os"] = val;
  },
  set_user_email(state, val) {
    state.http_options.headers["Client-Email"] = val;
  },
  set_is_sales_today(state, boolean) {
    state.ConfApp.isSalesToDay = boolean;
  },
  update_first_open_social(state, v) {
    state.firstOpenSocial = v;
  },
  set_share_link(state, url) {
    state.share_link = url;
  },
  set_share_dialog(state, v) {
    state.share_dialog = v;
  },
  call_share_dialog() {}
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
