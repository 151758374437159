<template>
  <v-card flat d-flex pa-2 class="card-md">
    <v-card-text :class="{ 'mob-padding-10-4': chartBodygraphChart }">
      <v-row v-if="chartBodygraphChart">
        <v-col
          cols="12"
          sm="12"
          md="12"
          class="flex-basis__auto svg_bg svg_bg_composite"
          style="overflow:hidden;outline:none"
        >
          <svg
            id="svg1582_composite"
            ref="bodygraph"
            xmlns:dc="http://purl.org/dc/elements/1.1/"
            xmlns:cc="http://creativecommons.org/ns#"
            xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
            xmlns:svg="http://www.w3.org/2000/svg"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
            xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
            viewBox="0 0 880 789.99997"
            version="1.1"
            text-rendering="optimizeLegibility"
            sodipodi:docname="bodygraph.svg"
            width="100%"
            height="100%"
            inkscape:version="0.92.4 (unknown)"
          >
            <metadata id="metadata1588">
              <rdf:RDF>
                <cc:Work rdf:about="">
                  <dc:format>image/svg+xml</dc:format>
                  <dc:type
                    rdf:resource="http://purl.org/dc/dcmitype/StillImage"
                  />
                  <dc:title>human-design</dc:title>
                </cc:Work>
              </rdf:RDF>
            </metadata>
            <defs id="defs1586">
              <filter
                id="filter1874_composite"
                inkscape:collect="always"
                style="color-interpolation-filters:sRGB"
                x="-0.024505657"
                width="1.0490113"
                y="-0.0142239"
                height="1.0284478"
              >
                <feGaussianBlur
                  id="feGaussianBlur1876"
                  inkscape:collect="always"
                  stdDeviation="4.2793031"
                />
              </filter>
              <pattern
                id="bg-red-black-25"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
                patternTransform="rotate(-25)"
              >
                <line
                  stroke="#ff001d"
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="20"
                  stroke-width="20"
                ></line>
                <line
                  stroke="#3e4448"
                  x1="20"
                  y1="0"
                  x2="20"
                  y2="20"
                  stroke-width="20"
                ></line>
              </pattern>
              <pattern
                id="bg-red-black25"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
                patternTransform="rotate(25)"
              >
                <line
                  stroke="#ff001d"
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="20"
                  stroke-width="20"
                ></line>
                <line
                  stroke="#3e4448"
                  x1="20"
                  y1="0"
                  x2="20"
                  y2="20"
                  stroke-width="20"
                ></line>
              </pattern>
              <pattern
                id="bg-red-black-35"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
                patternTransform="rotate(-35)"
              >
                <line
                  stroke="#ff001d"
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="20"
                  stroke-width="20"
                ></line>
                <line
                  stroke="#3e4448"
                  x1="20"
                  y1="0"
                  x2="20"
                  y2="20"
                  stroke-width="20"
                ></line>
              </pattern>
              <pattern
                id="bg-red-black35"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
                patternTransform="rotate(35)"
              >
                <line
                  stroke="#ff001d"
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="20"
                  stroke-width="20"
                ></line>
                <line
                  stroke="#3e4448"
                  x1="20"
                  y1="0"
                  x2="20"
                  y2="20"
                  stroke-width="20"
                ></line>
              </pattern>
              <pattern
                id="bg-red-black70"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
                patternTransform="rotate(70)"
              >
                <line
                  stroke="#ff001d"
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="20"
                  stroke-width="20"
                ></line>
                <line
                  stroke="#3e4448"
                  x1="20"
                  y1="0"
                  x2="20"
                  y2="20"
                  stroke-width="20"
                ></line>
              </pattern>
              <pattern
                id="bg-red-black50"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
                patternTransform="rotate(50)"
              >
                <line
                  stroke="#ff001d"
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="20"
                  stroke-width="20"
                ></line>
                <line
                  stroke="#3e4448"
                  x1="20"
                  y1="0"
                  x2="20"
                  y2="20"
                  stroke-width="20"
                ></line>
              </pattern>
              <pattern
                id="bg-red-black90"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
                patternTransform="rotate(90)"
              >
                <line
                  stroke="#ff001d"
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="20"
                  stroke-width="20"
                ></line>
                <line
                  stroke="#3e4448"
                  x1="20"
                  y1="0"
                  x2="20"
                  y2="20"
                  stroke-width="20"
                ></line>
              </pattern>
              <pattern
                id="bg-red-black120"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
                patternTransform="rotate(120)"
              >
                <line
                  stroke="#ff001d"
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="20"
                  stroke-width="20"
                ></line>
                <line
                  stroke="#3e4448"
                  x1="20"
                  y1="0"
                  x2="20"
                  y2="20"
                  stroke-width="20"
                ></line>
              </pattern>
              <pattern
                id="bg-red-black128"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
                patternTransform="rotate(128)"
              >
                <line
                  stroke="#ff001d"
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="20"
                  stroke-width="20"
                ></line>
                <line
                  stroke="#3e4448"
                  x1="20"
                  y1="0"
                  x2="20"
                  y2="20"
                  stroke-width="20"
                ></line>
              </pattern>
              <pattern
                id="bg-red-black135"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
                patternTransform="rotate(135)"
              >
                <line
                  stroke="#ff001d"
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="20"
                  stroke-width="20"
                ></line>
                <line
                  stroke="#3e4448"
                  x1="20"
                  y1="0"
                  x2="20"
                  y2="20"
                  stroke-width="20"
                ></line>
              </pattern>
              <pattern
                id="bg-red-black150"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
                patternTransform="rotate(150)"
              >
                <line
                  stroke="#ff001d"
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="20"
                  stroke-width="20"
                ></line>
                <line
                  stroke="#3e4448"
                  x1="20"
                  y1="0"
                  x2="20"
                  y2="20"
                  stroke-width="20"
                ></line>
              </pattern>
            </defs>
            <sodipodi:namedview
              id="namedview1584"
              pagecolor="#f8f8f8"
              bordercolor="#666666"
              borderopacity="1"
              objecttolerance="10"
              gridtolerance="10"
              guidetolerance="10"
              inkscape:pageopacity="1"
              inkscape:pageshadow="2"
              inkscape:window-width="2560"
              inkscape:window-height="1386"
              showgrid="false"
              fit-margin-top="20"
              fit-margin-left="20"
              fit-margin-right="20"
              fit-margin-bottom="20"
              inkscape:zoom="1"
              inkscape:cx="145.87012"
              inkscape:cy="564.47925"
              inkscape:window-x="0"
              inkscape:window-y="25"
              inkscape:window-maximized="1"
              inkscape:current-layer="svg1582"
              inkscape:pagecheckerboard="false"
              borderlayer="true"
              showguides="true"
              inkscape:guide-bbox="true"
            />
            <title id="title2"></title>
            <g
              id="layer2"
              inkscape:groupmode="layer"
              inkscape:label="Background"
              style="display:inline"
            >
              <rect
                id="rect1853"
                @click="clearTooltips"
                style="opacity:1;fill-opacity:1;stroke:none;stroke-width:1.25;stroke-miterlimit:4;stroke-dasharray:9.99999993, 4.99999996;stroke-dashoffset:0;stroke-opacity:1"
                width="880"
                height="790"
                x="0"
                y="0"
              />
              <g
                id="Слой_1"
                data-name="Слой 1"
                transform="translate(30.727409,17.409983)"
              >
                <g id="Слой_4" data-name="Слой 4">
                  <g id="g8250">
                    <path
                      id="path4"
                      @click="clearTooltips"
                      style="fill:#e1e1e1"
                      inkscape:connector-curvature="0"
                      d="m 680.10834,596.59 -206,-510.7 c -11.9,-29.4 -30.6,-25.5 -62.3,-26.2 -0.8,0 -12.8,0.2 -13.7,0.2 -47.7,-1.8 -52,22.7 -54.3,28.4 l -205.3,508.3 a 25.57,25.57 0 0 0 2.3,23.7 c 51.1,78.7 152.1,132.3 268.5,132.3 116.4,0 217.4,-53.6 268.5,-132.3 a 26.16,26.16 0 0 0 2.3,-23.7 z"
                    />
                    <path
                      id="path6"
                      @click="clearTooltips"
                      style="fill:#b2bec0"
                      inkscape:connector-curvature="0"
                      d="m 443.76,239.59 c 1.9,14.2 7.7,20.3 7.7,20.3 6.9,9.8 32.3,17.3 64.7,22 34.5,5 37.1,28.7 54.4,71.3 l 88.5,222.5 a 32.72,32.72 0 0 1 -3.1,29.9 c -44,66 -123.9,112.7 -217.5,120.8 a 1.7,1.7 0 0 0 -0.7,0.1 c -4.5,0.4 -9.1,0.7 -13.7,0.9 h -0.8 c -1.8,0.1 -3.7,0.1 -5.5,0.2 h -1.9 c -2.2,0 -4.4,0.1 -6.6,0.1 -2.2,0 -4.4,0 -6.6,-0.1 h -1.9 c -1.8,0 -3.7,-0.1 -5.5,-0.2 h -0.8 c -4.6,-0.2 -9.2,-0.5 -13.7,-0.9 a 1.7,1.7 0 0 1 -0.7,-0.1 c -93.5,-8.1 -173.5,-54.8 -217.4,-120.8 a 32.11,32.11 0 0 1 -3.1,-29.9 l 88.5,-222.5 c 17.2,-42.7 19.9,-66.3 54.4,-71.3 20.5,-3 40.7,-8.9 53.9,-15.4 7.9,-3.9 15.4,-8.4 18.7,-16.6 l 2,-10.3 c 1.1,-11 -3.1,-24.4 -10.4,-33.4 -8.7,-10.9 -10.8,-24.2 -11.4,-37.8 a 7.86,7.86 0 0 0 -3.4,-6.7 25,25 0 0 1 -10.3,-16.9 c -0.7,-4.9 -1.9,-9.7 -2,-14.6 -0.2,-6.8 -0.6,-10.7 9.2,-11.3 0,-5.9 0.1,-11.7 0,-17.6 a 74,74 0 0 1 5.1,-28.7 c 9.1,-23 26.9,-34.1 50.6,-36.7 l 9.3,0.7 c 23.7,2.6 41.5,13.7 50.6,36.7 a 74,74 0 0 1 5.1,28.7 c -0.1,5.9 0,11.7 0,17.6 9.7,0.6 9.3,4.5 9.2,11.3 -0.1,4.9 -1.3,9.7 -2,14.6 -1,7.1 -4.7,12.6 -10.3,16.9 a 7.86,7.86 0 0 0 -3.4,6.7 c -0.6,13.5 -2.7,26.9 -11.4,37.8 -7.2,9.1 -9.1,19 -8,29.9 z"
                    />
                  </g>
                  <text
                    id="text20"
                    font-size="14.57"
                    style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:14.56933308px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:center;writing-mode:lr-tb;text-anchor:middle;isolation:isolate;fill:#3e4448"
                    x="409.01001"
                    y="744.23999"
                  >
                    Human-Design.Space
                  </text>
                </g>
              </g>
              <g
                id="g11508"
                :class="{ opacity1: !isChartToggle }"
                style="display: none"
                transform="translate(7.080552,8.962463)"
              >
                <g
                  id="environment_group"
                  transform="translate(22.209448,18.44752)"
                >
                  <path
                    id="color"
                    d="m 240.5,150.12 -14.2,24.5 a 2.31,2.31 0 0 0 2,3.5 h 28.3 a 2.3,2.3 0 0 0 2,-3.5 l -14.2,-24.5 a 2.18,2.18 0 0 0 -3.9,0 z"
                    transform="translate(-46.94,-28.51)"
                    stroke-miterlimit="10"
                    inkscape:connector-curvature="0"
                    class="col-strk-chart1"
                    style="fill:#49c4be;stroke-width:1;stroke-miterlimit:10"
                  />
                  <path
                    id="tone"
                    d="m 286.5,148.32 h -14.4 a 2.2,2.2 0 0 0 -2,1.2 L 262.9,162 a 2.35,2.35 0 0 0 0,2.3 l 7.2,12.5 a 2.4,2.4 0 0 0 2,1.2 h 14.4 a 2.2,2.2 0 0 0 2,-1.2 l 7.2,-12.5 a 2.35,2.35 0 0 0 0,-2.3 l -7.2,-12.5 a 2.4,2.4 0 0 0 -2,-1.18 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    class="col-strk-chart1"
                    style="fill:#6cd844;stroke-width:1;stroke-linejoin:round"
                  />
                  <text
                    id="environment_number_tone"
                    font-size="15.22"
                    font-weight="700"
                    style="font-weight:700;font-size:15.22000027px;isolation:isolate;fill:#ffffff"
                    x="227.85001"
                    y="141.17"
                  >
                    {{ chartBodygraphChart.mandalas[1][2].tone }}
                  </text>
                  <text
                    id="environment_number_color"
                    transform="translate(191.64,141.17)"
                    font-size="15.22"
                    font-weight="700"
                    style="font-weight:700;font-size:15.22000027px;isolation:isolate;fill:#ffffff"
                  >
                    {{ chartBodygraphChart.mandalas[1][2].color }}
                  </text>
                  <path
                    id="environment_arrow_right"
                    v-bind:class="{
                      opacity1: chartBodygraphChart.variablesArray[3] === 2
                    }"
                    d="M 302.18,172.32 H 306 v 5.2 c 0,1.1 1.7,1.7 2.8,0.9 l 19.8,-14.3 a 1,1 0 0 0 0,-1.8 L 308.78,148 c -1.1,-0.8 -2.8,-0.2 -2.8,0.9 v 5.2 h -3.8 z"
                    transform="translate(-46.94,-28.51)"
                    stroke-miterlimit="10"
                    inkscape:connector-curvature="0"
                    class="col-strk-chart1"
                    style="fill:none;stroke-width:1;stroke-miterlimit:10;opacity:0"
                  />
                  <path
                    id="environment_arrow_left"
                    v-bind:class="{
                      opacity1: chartBodygraphChart.variablesArray[3] === 1
                    }"
                    d="m 221.1,154.12 h -3.8 v -5.2 c 0,-1.1 -1.7,-1.7 -2.8,-0.9 l -19.8,14.3 a 1,1 0 0 0 0,1.8 l 19.8,14.3 c 1.1,0.8 2.8,0.2 2.8,-0.9 v -5.2 h 3.8 z"
                    transform="translate(-46.94,-28.51)"
                    stroke-miterlimit="10"
                    inkscape:connector-curvature="0"
                    class="col-strk-chart1"
                    style="fill:none;stroke-width:1;stroke-miterlimit:10;opacity:0"
                  />
                  <g id="environment_tone">
                    <text
                      id="text36"
                      font-size="11.68"
                      style="font-size:11.68000031px;isolation:isolate;fill:#58b730"
                      x="222.66"
                      y="113.09"
                    >
                      ТОН
                    </text>
                  </g>
                  <text
                    id="text43"
                    font-size="11.68"
                    style="font-size:11.68000031px;isolation:isolate;fill:#49c4be"
                    x="180.28"
                    y="113.46"
                  >
                    ЦВЕТ
                  </text>
                </g>
                <g id="food_group" transform="translate(22.209448,18.44752)">
                  <path
                    id="color-2"
                    data-name="color"
                    d="m 240.5,98.12 -14.2,24.5 a 2.31,2.31 0 0 0 2,3.5 h 28.3 a 2.3,2.3 0 0 0 2,-3.5 l -14.2,-24.5 a 2.18,2.18 0 0 0 -3.9,0 z"
                    transform="translate(-46.94,-28.51)"
                    stroke-miterlimit="10"
                    inkscape:connector-curvature="0"
                    class="col-strk-chart1"
                    style="fill:#49c4be;stroke-width:1;stroke-miterlimit:10"
                  />
                  <path
                    id="tone-2"
                    data-name="tone"
                    d="m 286.5,96.32 h -14.4 a 2.2,2.2 0 0 0 -2,1.2 L 262.9,110 a 2.35,2.35 0 0 0 0,2.3 l 7.2,12.5 a 2.4,2.4 0 0 0 2,1.2 h 14.4 a 2.2,2.2 0 0 0 2,-1.2 l 7.2,-12.5 a 2.35,2.35 0 0 0 0,-2.3 l -7.2,-12.5 a 2.4,2.4 0 0 0 -2,-1.18 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    class="col-strk-chart1"
                    style="fill:#6cd844;stroke-width:1;stroke-linejoin:round"
                  />
                  <text
                    id="food_number_tone"
                    font-size="15.22"
                    font-weight="700"
                    style="font-weight:700;font-size:15.22000027px;isolation:isolate;fill:#ffffff"
                    x="227.85001"
                    y="89.169998"
                  >
                    {{ chartBodygraphChart.mandalas[1][0].tone }}
                  </text>
                  <text
                    id="food_number_color"
                    transform="translate(191.64,89.17)"
                    font-size="15.22"
                    font-weight="700"
                    style="font-weight:700;font-size:15.22000027px;isolation:isolate;fill:#ffffff"
                  >
                    {{ chartBodygraphChart.mandalas[1][0].color }}
                  </text>
                  <path
                    id="food_arrow_right"
                    v-bind:class="{
                      opacity1: chartBodygraphChart.variablesArray[2] === 2
                    }"
                    d="M 302.18,120.32 H 306 v 5.2 c 0,1.1 1.7,1.7 2.8,0.9 l 19.8,-14.3 a 1,1 0 0 0 0,-1.8 L 308.78,96 c -1.1,-0.8 -2.8,-0.2 -2.8,0.9 v 5.2 h -3.8 z"
                    transform="translate(-46.94,-28.51)"
                    stroke-miterlimit="10"
                    inkscape:connector-curvature="0"
                    class="col-strk-chart1"
                    style="fill:none;stroke-width:1;stroke-miterlimit:10;opacity:0"
                  />
                  <path
                    id="food_arrow_left"
                    v-bind:class="{
                      opacity1: chartBodygraphChart.variablesArray[2] === 1
                    }"
                    d="m 221.1,102.12 h -3.8 v -5.2 c 0,-1.1 -1.7,-1.7 -2.8,-0.9 l -19.8,14.3 a 1,1 0 0 0 0,1.8 l 19.8,14.3 c 1.1,0.8 2.8,0.2 2.8,-0.9 v -5.2 h 3.8 z"
                    transform="translate(-46.94,-28.51)"
                    stroke-miterlimit="10"
                    inkscape:connector-curvature="0"
                    class="col-strk-chart1"
                    style="fill:none;stroke-width:1;stroke-miterlimit:10;opacity:0"
                  />
                  <g id="food_tone" />
                </g>
                <g id="vision_group" transform="translate(22.209448,16.44752)">
                  <path
                    id="color-3"
                    data-name="color"
                    d="m 632.52,152.12 -14.2,24.5 a 2.31,2.31 0 0 0 2,3.5 h 28.3 a 2.3,2.3 0 0 0 2,-3.5 l -14.2,-24.5 a 2.18,2.18 0 0 0 -3.9,0 z"
                    transform="translate(-46.94,-28.51)"
                    stroke-miterlimit="10"
                    inkscape:connector-curvature="0"
                    class="col-strk-person"
                    style="fill:#49c4be;stroke-width:1;stroke-miterlimit:10"
                  />
                  <path
                    id="tone-3"
                    data-name="tone"
                    d="m 678.52,150.32 h -14.4 a 2.2,2.2 0 0 0 -2,1.2 l -7.2,12.5 a 2.35,2.35 0 0 0 0,2.3 l 7.2,12.5 a 2.4,2.4 0 0 0 2,1.2 h 14.4 a 2.2,2.2 0 0 0 2,-1.2 l 7.2,-12.5 a 2.35,2.35 0 0 0 0,-2.3 l -7.2,-12.5 a 2.4,2.4 0 0 0 -2,-1.2 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    class="col-strk-person"
                    style="fill:#6cd844;stroke-width:1;stroke-linejoin:round"
                  />
                  <text
                    id="vision_number_color"
                    transform="translate(583.66,143.17)"
                    font-size="15.22"
                    font-weight="700"
                    style="font-weight:700;font-size:15.22000027px;isolation:isolate;fill:#ffffff"
                  >
                    {{ chartBodygraphChart.mandalas[0][2].color }}
                  </text>
                  <text
                    id="vision_number_tone"
                    font-size="15.22"
                    font-weight="700"
                    style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;font-size:15.22000027px;font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:start;writing-mode:lr-tb;text-anchor:start;isolation:isolate;fill:#ffffff"
                    x="619.87"
                    y="143.17"
                  >
                    <tspan
                      id="tspan8437"
                      sodipodi:role="line"
                      x="619.87"
                      y="143.17"
                    >
                      {{ chartBodygraphChart.mandalas[0][2].tone }}
                    </tspan>
                  </text>
                  <path
                    id="vision_arrow_right"
                    v-bind:class="{
                      opacity1: chartBodygraphChart.variablesArray[1] === 2
                    }"
                    d="M 694.19,174.32 H 698 v 5.2 c 0,1.1 1.7,1.7 2.8,0.9 l 19.8,-14.3 a 1,1 0 0 0 0,-1.8 L 700.79,150 c -1.1,-0.8 -2.8,-0.2 -2.8,0.9 v 5.2 h -3.8 z"
                    transform="translate(-46.94,-28.51)"
                    stroke-miterlimit="10"
                    inkscape:connector-curvature="0"
                    class="col-strk-person"
                    style="fill:none;stroke-width:1;stroke-miterlimit:10;opacity:0"
                  />
                  <path
                    id="vision_arrow_left"
                    v-bind:class="{
                      opacity1: chartBodygraphChart.variablesArray[1] === 1
                    }"
                    d="m 613.12,156.12 h -3.8 v -5.2 c 0,-1.1 -1.7,-1.7 -2.8,-0.9 l -19.8,14.3 a 1,1 0 0 0 0,1.8 l 19.8,14.3 c 1.1,0.8 2.8,0.2 2.8,-0.9 v -5.2 h 3.8 z"
                    transform="translate(-46.94,-28.51)"
                    stroke-miterlimit="10"
                    inkscape:connector-curvature="0"
                    class="col-strk-person"
                    style="fill:none;stroke-width:1;stroke-miterlimit:10;opacity:0"
                  />
                  <g id="vision_tone">
                    <text
                      id="text80"
                      font-size="11.68"
                      style="font-size:11.68000031px;isolation:isolate;fill:#58b730"
                      x="614.67999"
                      y="115.09"
                    >
                      ТОН
                    </text>
                  </g>
                  <text
                    id="text87"
                    font-size="11.68"
                    style="font-size:11.68000031px;isolation:isolate;fill:#49c4be"
                    x="572.29999"
                    y="115.46"
                  >
                    ЦВЕТ
                  </text>
                </g>
                <g
                  id="motivation_group"
                  transform="translate(22.209448,16.44752)"
                >
                  <path
                    id="color-4"
                    data-name="color"
                    d="m 632.52,100.12 -14.2,24.5 a 2.31,2.31 0 0 0 2,3.5 h 28.3 a 2.3,2.3 0 0 0 2,-3.5 l -14.2,-24.5 a 2.18,2.18 0 0 0 -3.9,0 z"
                    transform="translate(-46.94,-28.51)"
                    stroke-miterlimit="10"
                    inkscape:connector-curvature="0"
                    class="col-strk-person"
                    style="fill:#49c4be;stroke-width:1;stroke-miterlimit:10"
                  />
                  <path
                    id="tone-4"
                    data-name="tone"
                    d="m 678.52,98.32 h -14.4 a 2.2,2.2 0 0 0 -2,1.2 l -7.2,12.5 a 2.35,2.35 0 0 0 0,2.3 l 7.2,12.5 a 2.4,2.4 0 0 0 2,1.2 h 14.4 a 2.2,2.2 0 0 0 2,-1.2 l 7.2,-12.5 a 2.35,2.35 0 0 0 0,-2.3 l -7.2,-12.5 a 2.4,2.4 0 0 0 -2,-1.2 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    class="col-strk-person"
                    style="fill:#6cd844;stroke-width:1;stroke-linejoin:round"
                  />
                  <text
                    id="motivation_number_tone"
                    font-size="15.22"
                    font-weight="700"
                    style="font-weight:700;font-size:15.22000027px;isolation:isolate;fill:#ffffff"
                    x="619.87"
                    y="91.169998"
                  >
                    {{ chartBodygraphChart.mandalas[0][0].tone }}
                  </text>
                  <text
                    id="motivation_number_color"
                    transform="translate(583.66,91.17)"
                    font-size="15.22"
                    font-weight="700"
                    style="font-weight:700;font-size:15.22000027px;isolation:isolate;fill:#ffffff"
                  >
                    {{ chartBodygraphChart.mandalas[0][0].color }}
                  </text>
                  <path
                    id="motivation_arrow_right"
                    v-bind:class="{
                      opacity1: chartBodygraphChart.variablesArray[0] === 2
                    }"
                    d="M 694.19,122.32 H 698 v 5.2 c 0,1.1 1.7,1.7 2.8,0.9 l 19.8,-14.3 a 1,1 0 0 0 0,-1.8 L 700.79,98 c -1.1,-0.8 -2.8,-0.2 -2.8,0.9 v 5.2 h -3.8 z"
                    transform="translate(-46.94,-28.51)"
                    stroke-miterlimit="10"
                    inkscape:connector-curvature="0"
                    class="col-strk-person"
                    style="fill:none;stroke-width:1;stroke-miterlimit:10;opacity:0"
                  />
                  <path
                    id="motivation_arrow_left"
                    v-bind:class="{
                      opacity1: chartBodygraphChart.variablesArray[0] === 1
                    }"
                    d="m 613.12,104.12 h -3.8 v -5.2 c 0,-1.1 -1.7,-1.7 -2.8,-0.9 l -19.8,14.3 a 1,1 0 0 0 0,1.8 l 19.8,14.3 c 1.1,0.8 2.8,0.2 2.8,-0.9 v -5.2 h 3.8 z"
                    transform="translate(-46.94,-28.51)"
                    stroke-miterlimit="10"
                    inkscape:connector-curvature="0"
                    class="col-strk-person"
                    style="fill:none;stroke-width:1;stroke-miterlimit:10;opacity:0"
                  />
                  <g id="motivation_tone" />
                </g>
              </g>
              <g
                id="g11508_2"
                :class="{ opacity1: !isChart2Toggle }"
                style="display: none"
                transform="translate(7.080552,8.962463)"
              >
                <g
                  id="environment_group_2"
                  transform="translate(22.209448,18.44752)"
                >
                  <path
                    id="color_2"
                    d="m 240.5,150.12 -14.2,24.5 a 2.31,2.31 0 0 0 2,3.5 h 28.3 a 2.3,2.3 0 0 0 2,-3.5 l -14.2,-24.5 a 2.18,2.18 0 0 0 -3.9,0 z"
                    transform="translate(-46.94,-28.51)"
                    stroke-miterlimit="10"
                    inkscape:connector-curvature="0"
                    class="col-strk-chart1"
                    style="fill:#49c4be;stroke-width:1;stroke-miterlimit:10"
                  />
                  <path
                    id="tone_2"
                    d="m 286.5,148.32 h -14.4 a 2.2,2.2 0 0 0 -2,1.2 L 262.9,162 a 2.35,2.35 0 0 0 0,2.3 l 7.2,12.5 a 2.4,2.4 0 0 0 2,1.2 h 14.4 a 2.2,2.2 0 0 0 2,-1.2 l 7.2,-12.5 a 2.35,2.35 0 0 0 0,-2.3 l -7.2,-12.5 a 2.4,2.4 0 0 0 -2,-1.18 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    class="col-strk-chart1"
                    style="fill:#6cd844;stroke-width:1;stroke-linejoin:round"
                  />
                  <text
                    id="environment_number_tone_2"
                    font-size="15.22"
                    font-weight="700"
                    style="font-weight:700;font-size:15.22000027px;isolation:isolate;fill:#ffffff"
                    x="227.85001"
                    y="141.17"
                  >
                    {{ chartBodygraphChart2.mandalas[1][2].tone }}
                  </text>
                  <text
                    id="environment_number_color_2"
                    transform="translate(191.64,141.17)"
                    font-size="15.22"
                    font-weight="700"
                    style="font-weight:700;font-size:15.22000027px;isolation:isolate;fill:#ffffff"
                  >
                    {{ chartBodygraphChart2.mandalas[1][2].color }}
                  </text>
                  <path
                    id="environment_arrow_right_2"
                    v-bind:class="{
                      opacity1: chartBodygraphChart2.variablesArray[3] === 2
                    }"
                    d="M 302.18,172.32 H 306 v 5.2 c 0,1.1 1.7,1.7 2.8,0.9 l 19.8,-14.3 a 1,1 0 0 0 0,-1.8 L 308.78,148 c -1.1,-0.8 -2.8,-0.2 -2.8,0.9 v 5.2 h -3.8 z"
                    transform="translate(-46.94,-28.51)"
                    stroke-miterlimit="10"
                    inkscape:connector-curvature="0"
                    class="col-strk-chart1"
                    style="fill:none;stroke-width:1;stroke-miterlimit:10;opacity:0"
                  />
                  <path
                    id="environment_arrow_left_2"
                    v-bind:class="{
                      opacity1: chartBodygraphChart2.variablesArray[3] === 1
                    }"
                    d="m 221.1,154.12 h -3.8 v -5.2 c 0,-1.1 -1.7,-1.7 -2.8,-0.9 l -19.8,14.3 a 1,1 0 0 0 0,1.8 l 19.8,14.3 c 1.1,0.8 2.8,0.2 2.8,-0.9 v -5.2 h 3.8 z"
                    transform="translate(-46.94,-28.51)"
                    stroke-miterlimit="10"
                    inkscape:connector-curvature="0"
                    class="col-strk-chart1"
                    style="fill:none;stroke-width:1;stroke-miterlimit:10;opacity:0"
                  />
                  <g id="environment_tone_2">
                    <text
                      id="text36_2"
                      font-size="11.68"
                      style="font-size:11.68000031px;isolation:isolate;fill:#58b730"
                      x="222.66"
                      y="113.09"
                    >
                      ТОН
                    </text>
                  </g>
                  <text
                    id="text43_2"
                    font-size="11.68"
                    style="font-size:11.68000031px;isolation:isolate;fill:#49c4be"
                    x="180.28"
                    y="113.46"
                  >
                    ЦВЕТ
                  </text>
                </g>
                <g id="food_group_2" transform="translate(22.209448,18.44752)">
                  <path
                    id="color-2_2"
                    data-name="color"
                    d="m 240.5,98.12 -14.2,24.5 a 2.31,2.31 0 0 0 2,3.5 h 28.3 a 2.3,2.3 0 0 0 2,-3.5 l -14.2,-24.5 a 2.18,2.18 0 0 0 -3.9,0 z"
                    transform="translate(-46.94,-28.51)"
                    stroke-miterlimit="10"
                    inkscape:connector-curvature="0"
                    class="col-strk-chart1"
                    style="fill:#49c4be;stroke-width:1;stroke-miterlimit:10"
                  />
                  <path
                    id="tone-2_2"
                    data-name="tone"
                    d="m 286.5,96.32 h -14.4 a 2.2,2.2 0 0 0 -2,1.2 L 262.9,110 a 2.35,2.35 0 0 0 0,2.3 l 7.2,12.5 a 2.4,2.4 0 0 0 2,1.2 h 14.4 a 2.2,2.2 0 0 0 2,-1.2 l 7.2,-12.5 a 2.35,2.35 0 0 0 0,-2.3 l -7.2,-12.5 a 2.4,2.4 0 0 0 -2,-1.18 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    class="col-strk-chart1"
                    style="fill:#6cd844;stroke-width:1;stroke-linejoin:round"
                  />
                  <text
                    id="food_number_tone_2"
                    font-size="15.22"
                    font-weight="700"
                    style="font-weight:700;font-size:15.22000027px;isolation:isolate;fill:#ffffff"
                    x="227.85001"
                    y="89.169998"
                  >
                    {{ chartBodygraphChart2.mandalas[1][0].tone }}
                  </text>
                  <text
                    id="food_number_color"
                    transform="translate(191.64,89.17)"
                    font-size="15.22"
                    font-weight="700"
                    style="font-weight:700;font-size:15.22000027px;isolation:isolate;fill:#ffffff"
                  >
                    {{ chartBodygraphChart2.mandalas[1][0].color }}
                  </text>
                  <path
                    id="food_arrow_right_2"
                    v-bind:class="{
                      opacity1: chartBodygraphChart2.variablesArray[2] === 2
                    }"
                    d="M 302.18,120.32 H 306 v 5.2 c 0,1.1 1.7,1.7 2.8,0.9 l 19.8,-14.3 a 1,1 0 0 0 0,-1.8 L 308.78,96 c -1.1,-0.8 -2.8,-0.2 -2.8,0.9 v 5.2 h -3.8 z"
                    transform="translate(-46.94,-28.51)"
                    stroke-miterlimit="10"
                    inkscape:connector-curvature="0"
                    class="col-strk-chart1"
                    style="fill:none;stroke-width:1;stroke-miterlimit:10;opacity:0"
                  />
                  <path
                    id="food_arrow_left_2"
                    v-bind:class="{
                      opacity1: chartBodygraphChart2.variablesArray[2] === 1
                    }"
                    d="m 221.1,102.12 h -3.8 v -5.2 c 0,-1.1 -1.7,-1.7 -2.8,-0.9 l -19.8,14.3 a 1,1 0 0 0 0,1.8 l 19.8,14.3 c 1.1,0.8 2.8,0.2 2.8,-0.9 v -5.2 h 3.8 z"
                    transform="translate(-46.94,-28.51)"
                    stroke-miterlimit="10"
                    inkscape:connector-curvature="0"
                    class="col-strk-chart1"
                    style="fill:none;stroke-width:1;stroke-miterlimit:10;opacity:0"
                  />
                  <g id="food_tone_2" />
                </g>
                <g
                  id="vision_group_2"
                  transform="translate(22.209448,16.44752)"
                >
                  <path
                    id="color-3_2"
                    data-name="color"
                    d="m 632.52,152.12 -14.2,24.5 a 2.31,2.31 0 0 0 2,3.5 h 28.3 a 2.3,2.3 0 0 0 2,-3.5 l -14.2,-24.5 a 2.18,2.18 0 0 0 -3.9,0 z"
                    transform="translate(-46.94,-28.51)"
                    stroke-miterlimit="10"
                    inkscape:connector-curvature="0"
                    class="col-strk-person"
                    style="fill:#49c4be;stroke-width:1;stroke-miterlimit:10"
                  />
                  <path
                    id="tone-3_2"
                    data-name="tone"
                    d="m 678.52,150.32 h -14.4 a 2.2,2.2 0 0 0 -2,1.2 l -7.2,12.5 a 2.35,2.35 0 0 0 0,2.3 l 7.2,12.5 a 2.4,2.4 0 0 0 2,1.2 h 14.4 a 2.2,2.2 0 0 0 2,-1.2 l 7.2,-12.5 a 2.35,2.35 0 0 0 0,-2.3 l -7.2,-12.5 a 2.4,2.4 0 0 0 -2,-1.2 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    class="col-strk-person"
                    style="fill:#6cd844;stroke-width:1;stroke-linejoin:round"
                  />
                  <text
                    id="vision_number_color_2"
                    transform="translate(583.66,143.17)"
                    font-size="15.22"
                    font-weight="700"
                    style="font-weight:700;font-size:15.22000027px;isolation:isolate;fill:#ffffff"
                  >
                    {{ chartBodygraphChart2.mandalas[0][2].color }}
                  </text>
                  <text
                    id="vision_number_tone_2"
                    font-size="15.22"
                    font-weight="700"
                    style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;font-size:15.22000027px;font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:start;writing-mode:lr-tb;text-anchor:start;isolation:isolate;fill:#ffffff"
                    x="619.87"
                    y="143.17"
                  >
                    <tspan
                      id="tspan8437_2"
                      sodipodi:role="line"
                      x="619.87"
                      y="143.17"
                    >
                      {{ chartBodygraphChart2.mandalas[0][2].tone }}
                    </tspan>
                  </text>
                  <path
                    id="vision_arrow_right_2"
                    v-bind:class="{
                      opacity1: chartBodygraphChart2.variablesArray[1] === 2
                    }"
                    d="M 694.19,174.32 H 698 v 5.2 c 0,1.1 1.7,1.7 2.8,0.9 l 19.8,-14.3 a 1,1 0 0 0 0,-1.8 L 700.79,150 c -1.1,-0.8 -2.8,-0.2 -2.8,0.9 v 5.2 h -3.8 z"
                    transform="translate(-46.94,-28.51)"
                    stroke-miterlimit="10"
                    inkscape:connector-curvature="0"
                    class="col-strk-person"
                    style="fill:none;stroke-width:1;stroke-miterlimit:10;opacity:0"
                  />
                  <path
                    id="vision_arrow_left_2"
                    v-bind:class="{
                      opacity1: chartBodygraphChart2.variablesArray[1] === 1
                    }"
                    d="m 613.12,156.12 h -3.8 v -5.2 c 0,-1.1 -1.7,-1.7 -2.8,-0.9 l -19.8,14.3 a 1,1 0 0 0 0,1.8 l 19.8,14.3 c 1.1,0.8 2.8,0.2 2.8,-0.9 v -5.2 h 3.8 z"
                    transform="translate(-46.94,-28.51)"
                    stroke-miterlimit="10"
                    inkscape:connector-curvature="0"
                    class="col-strk-person"
                    style="fill:none;stroke-width:1;stroke-miterlimit:10;opacity:0"
                  />
                  <g id="vision_tone_2">
                    <text
                      id="text80_2"
                      font-size="11.68"
                      style="font-size:11.68000031px;isolation:isolate;fill:#58b730"
                      x="614.67999"
                      y="115.09"
                    >
                      ТОН
                    </text>
                  </g>
                  <text
                    id="text87_2"
                    font-size="11.68"
                    style="font-size:11.68000031px;isolation:isolate;fill:#49c4be"
                    x="572.29999"
                    y="115.46"
                  >
                    ЦВЕТ
                  </text>
                </g>
                <g
                  id="motivation_group_2"
                  transform="translate(22.209448,16.44752)"
                >
                  <path
                    id="color-4_2"
                    data-name="color"
                    d="m 632.52,100.12 -14.2,24.5 a 2.31,2.31 0 0 0 2,3.5 h 28.3 a 2.3,2.3 0 0 0 2,-3.5 l -14.2,-24.5 a 2.18,2.18 0 0 0 -3.9,0 z"
                    transform="translate(-46.94,-28.51)"
                    stroke-miterlimit="10"
                    inkscape:connector-curvature="0"
                    class="col-strk-person"
                    style="fill:#49c4be;stroke-width:1;stroke-miterlimit:10"
                  />
                  <path
                    id="tone-4_2"
                    data-name="tone"
                    d="m 678.52,98.32 h -14.4 a 2.2,2.2 0 0 0 -2,1.2 l -7.2,12.5 a 2.35,2.35 0 0 0 0,2.3 l 7.2,12.5 a 2.4,2.4 0 0 0 2,1.2 h 14.4 a 2.2,2.2 0 0 0 2,-1.2 l 7.2,-12.5 a 2.35,2.35 0 0 0 0,-2.3 l -7.2,-12.5 a 2.4,2.4 0 0 0 -2,-1.2 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    class="col-strk-person"
                    style="fill:#6cd844;stroke-width:1;stroke-linejoin:round"
                  />
                  <text
                    id="motivation_number_tone_2"
                    font-size="15.22"
                    font-weight="700"
                    style="font-weight:700;font-size:15.22000027px;isolation:isolate;fill:#ffffff"
                    x="619.87"
                    y="91.169998"
                  >
                    {{ chartBodygraphChart2.mandalas[0][0].tone }}
                  </text>
                  <text
                    id="motivation_number_color_2"
                    transform="translate(583.66,91.17)"
                    font-size="15.22"
                    font-weight="700"
                    style="font-weight:700;font-size:15.22000027px;isolation:isolate;fill:#ffffff"
                  >
                    {{ chartBodygraphChart2.mandalas[0][0].color }}
                  </text>
                  <path
                    id="motivation_arrow_right_2"
                    v-bind:class="{
                      opacity1: chartBodygraphChart2.variablesArray[0] === 2
                    }"
                    d="M 694.19,122.32 H 698 v 5.2 c 0,1.1 1.7,1.7 2.8,0.9 l 19.8,-14.3 a 1,1 0 0 0 0,-1.8 L 700.79,98 c -1.1,-0.8 -2.8,-0.2 -2.8,0.9 v 5.2 h -3.8 z"
                    transform="translate(-46.94,-28.51)"
                    stroke-miterlimit="10"
                    inkscape:connector-curvature="0"
                    class="col-strk-person"
                    style="fill:none;stroke-width:1;stroke-miterlimit:10;opacity:0"
                  />
                  <path
                    id="motivation_arrow_left_2"
                    v-bind:class="{
                      opacity1: chartBodygraphChart2.variablesArray[0] === 1
                    }"
                    d="m 613.12,104.12 h -3.8 v -5.2 c 0,-1.1 -1.7,-1.7 -2.8,-0.9 l -19.8,14.3 a 1,1 0 0 0 0,1.8 l 19.8,14.3 c 1.1,0.8 2.8,0.2 2.8,-0.9 v -5.2 h 3.8 z"
                    transform="translate(-46.94,-28.51)"
                    stroke-miterlimit="10"
                    inkscape:connector-curvature="0"
                    class="col-strk-person"
                    style="fill:none;stroke-width:1;stroke-miterlimit:10;opacity:0"
                  />
                  <g id="motivation_tone_2" />
                </g>
              </g>
              <g id="g8332" transform="translate(0,2.9624634)">
                <g
                  id="g7327"
                  :class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                >
                  <g id="g6958" transform="translate(23.809448,18.44752)">
                    <g
                      id="chart_sign_pluto"
                      v-if="chartBodygraphChart.planets.pluto"
                      class="planet-chart1"
                    >
                      <path
                        d="m 26.96,711.26 h -16.7 a 1,1 0 0 1 -1.1,-1.1 l 0.1,-27.7 a 1.14,1.14 0 0 1 0.3,-0.8 0.83,0.83 0 0 1 0.8,-0.3 l 11.3,0.1 a 9.9,9.9 0 0 1 0,19.8 l -10.2,0.1 v 7.7 h 15.5 a 1.22,1.22 0 0 1 1.2,1.2 1.13,1.13 0 0 1 -1.2,1 z m -15.4,-27.7 v 15.4 l 10.2,-0.1 a 7.6,7.6 0 0 0 0,-15.2 z"
                        inkscape:connector-curvature="0"
                        transform="translate(48,-0.71)"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart.planets.pluto)
                        "
                        class="planet-substrate-rect pluto-chart-comp"
                        x="52"
                        y="683"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes[12]
                      }"
                      class="planet-number-chart pluto-num-chart"
                      x="3"
                      y="683"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_pluto"
                      v-if="chartBodygraphChart.gatesDesignExt.pluto"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesDesignExt.pluto
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesDesignExt.pluto.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="703.03998"
                      x="5"
                      font-size="20.72"
                      class="col-bg-chart"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate;"
                    >
                      {{
                        chartBodygraphChart.gatesDesignExt.pluto.gate || 0
                      }}.{{
                        chartBodygraphChart.gatesDesignExt.pluto.line || 0
                      }}
                    </text>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer[12]
                      }"
                      class="planet-number-chart pluto-num-chart"
                      x="88"
                      y="683"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_pluto"
                      v-if="chartBodygraphChart.gatesPersonalityExt.pluto"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesPersonalityExt.pluto
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesPersonalityExt.pluto.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="703.03998"
                      x="90"
                      font-size="20.72"
                      class="col-bg-chart"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate;"
                    >
                      {{
                        chartBodygraphChart.gatesPersonalityExt.pluto.gate || 0
                      }}.{{
                        chartBodygraphChart.gatesPersonalityExt.pluto.line || 0
                      }}
                    </text>
                    <text
                      v-if="chartBodygraphChart.gatesDesignExt.pluto.isExalted"
                      y="695"
                      x="-12.5"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesDesignExt.pluto.isDetriment
                      "
                      y="708.5"
                      x="-12.5"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9661;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.pluto.isExalted
                      "
                      y="695"
                      x="135"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.pluto
                          .isDetriment
                      "
                      y="708.5"
                      x="135"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g6954" transform="translate(23.809448,18.44752)">
                    <g
                      id="chart_sign_neptune"
                      v-if="chartBodygraphChart.planets.neptune"
                      class="planet-chart1"
                      transform="translate(-0.05279548,-0.34454346)"
                    >
                      <path
                        id="path114"
                        d="m 67.3,676.65 a 13.51,13.51 0 0 1 -13.5,-13.5 1.2,1.2 0 0 1 2.4,0 11.2,11.2 0 0 0 22.4,0 1.2,1.2 0 0 1 2.4,0 13.88,13.88 0 0 1 -13.7,13.5 z"
                        transform="translate(1,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path116"
                        d="m 67.3,689.75 a 1.22,1.22 0 0 1 -1.2,-1.2 v -25.9 a 1.2,1.2 0 0 1 2.4,0 v 25.9 a 1.38,1.38 0 0 1 -1.2,1.2 z"
                        transform="translate(1,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path118"
                        d="M 75.1,683.55 H 59.6 a 1.2,1.2 0 1 1 0,-2.4 h 15.5 a 1.22,1.22 0 0 1 1.2,1.2 1.29,1.29 0 0 1 -1.2,1.2 z"
                        transform="translate(1,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <polygon
                        id="polygon120"
                        points="8.06,630.23 4.66,636.13 11.56,636.13 "
                        transform="translate(48,-0.71)"
                      />
                      <polygon
                        id="polygon122"
                        points="20.36,630.23 16.86,636.13 23.76,636.13 "
                        transform="translate(48,-0.71)"
                      />
                      <polygon
                        id="polygon124"
                        points="32.66,630.23 29.26,636.13 36.16,636.13 "
                        transform="translate(48,-0.71)"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart.planets.neptune)
                        "
                        class="planet-substrate-rect neptune-chart-comp"
                        x="52"
                        y="630"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes[11]
                      }"
                      class="planet-number-chart neptune-num-chart"
                      x="3"
                      y="630"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_neptune"
                      v-if="chartBodygraphChart.gatesDesignExt.neptune"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesDesignExt.neptune
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesDesignExt.neptune.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="652.12445"
                      x="5"
                      font-size="20.72"
                      class="col-bg-chart"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{
                        chartBodygraphChart.gatesDesignExt.neptune.gate || 0
                      }}.{{
                        chartBodygraphChart.gatesDesignExt.neptune.line || 0
                      }}
                    </text>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer[11]
                      }"
                      class="planet-number-chart neptune-num-chart"
                      x="88"
                      y="630"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_neptune"
                      v-if="chartBodygraphChart.gatesPersonalityExt.neptune"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesPersonalityExt.neptune
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesPersonalityExt.neptune.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="652.12445"
                      x="90"
                      font-size="20.72"
                      class="col-bg-chart"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{
                        chartBodygraphChart.gatesPersonalityExt.neptune.gate ||
                          0
                      }}.{{
                        chartBodygraphChart.gatesPersonalityExt.neptune.line ||
                          0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesDesignExt.neptune.isExalted
                      "
                      y="644"
                      x="-12.5"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesDesignExt.neptune.isDetriment
                      "
                      y="657.5"
                      x="-12.5"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9661;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.neptune
                          .isExalted
                      "
                      y="644"
                      x="135"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.neptune
                          .isDetriment
                      "
                      y="657.5"
                      x="135"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g6944" transform="translate(23.809448,18.44752)">
                    <g
                      id="chart_sign_uranus"
                      v-if="chartBodygraphChart.planets.uranus"
                      class="planet-chart1"
                      transform="translate(-0.00279548,-0.99504089)"
                    >
                      <path
                        id="path127"
                        d="m 56.3,634.08 a 1.08,1.08 0 0 1 -0.7,-0.3 1,1 0 0 1 0,-1.4 15,15 0 0 0 4.1,-10.2 14.66,14.66 0 0 0 -4.3,-10.5 1,1 0 0 1 1.4,-1.4 16.78,16.78 0 0 1 0.2,23.5 2.12,2.12 0 0 1 -0.7,0.3 z"
                        transform="translate(1,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path129"
                        d="m 78.3,634.58 a 0.91,0.91 0 0 1 -0.7,-0.3 16.77,16.77 0 0 1 -4.7,-11.6 17.05,17.05 0 0 1 4.9,-11.9 1,1 0 0 1 1.4,1.4 14.86,14.86 0 0 0 -0.2,20.7 1,1 0 0 1 0,1.4 1.08,1.08 0 0 1 -0.7,0.3 z"
                        transform="translate(1,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path131"
                        d="m 67.3,628.58 a 0.94,0.94 0 0 1 -1,-1 v -16.2 a 1,1 0 0 1 2,0 v 16.2 a 1.08,1.08 0 0 1 -1,1 z"
                        transform="translate(1,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path133"
                        d="m 67.3,637.88 a 5.5,5.5 0 1 1 5.5,-5.5 5.55,5.55 0 0 1 -5.5,5.5 z m 0,-8.9 a 3.5,3.5 0 1 0 3.5,3.5 3.48,3.48 0 0 0 -3.5,-3.48 z"
                        transform="translate(1,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path135"
                        d="M 73.8,623.08 H 60.7 a 1,1 0 1 1 0,-2 h 13.1 a 1,1 0 1 1 0,2 z"
                        transform="translate(1,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart.planets.uranus)
                        "
                        class="planet-substrate-rect uranus-chart-comp"
                        x="52"
                        y="580"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes[10]
                      }"
                      class="planet-number-chart uranus-num-chart"
                      x="3"
                      y="580"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_uranus"
                      v-if="chartBodygraphChart.gatesDesignExt.uranus"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesDesignExt.uranus
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesDesignExt.uranus.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="601.20892"
                      x="5"
                      font-size="20.72"
                      class="col-bg-chart"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{
                        chartBodygraphChart.gatesDesignExt.uranus.gate || 0
                      }}.{{
                        chartBodygraphChart.gatesDesignExt.uranus.line || 0
                      }}
                    </text>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer[10]
                      }"
                      class="planet-number-chart uranus-num-chart"
                      x="90"
                      y="580"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_uranus"
                      v-if="chartBodygraphChart.gatesPersonalityExt.uranus"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesPersonalityExt.uranus
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesPersonalityExt.uranus.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="601.20892"
                      x="93"
                      font-size="20.72"
                      class="col-bg-chart"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{
                        chartBodygraphChart.gatesPersonalityExt.uranus.gate ||
                          0
                      }}.{{
                        chartBodygraphChart.gatesPersonalityExt.uranus.line || 0
                      }}
                    </text>
                    <text
                      v-if="chartBodygraphChart.gatesDesignExt.uranus.isExalted"
                      y="593"
                      x="-12.5"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesDesignExt.uranus.isDetriment
                      "
                      y="606.5"
                      x="-12.5"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9661;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.uranus.isExalted
                      "
                      y="593"
                      x="135"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.uranus
                          .isDetriment
                      "
                      y="606.5"
                      x="135"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g6935" transform="translate(23.809448,18.44752)">
                    <g
                      id="chart_sign_saturn"
                      v-if="chartBodygraphChart.planets.saturn"
                      class="planet-chart1"
                      transform="translate(0.04756189,0.1998291)"
                    >
                      <path
                        id="path138"
                        d="m 71.2,586.87 a 0.9,0.9 0 0 1 -0.5,-0.1 c -0.1,-0.1 -3.3,-1.7 -3.3,-4.7 0,-1.5 1.4,-2.9 3.2,-4.6 1.8,-1.7 3.9,-3.9 3.9,-5.6 a 5.7,5.7 0 0 0 -11.4,0 1.2,1.2 0 0 1 -2.4,0 8,8 0 0 1 16,0 c 0,2.7 -2.4,5.1 -4.6,7.3 -1,1 -2.5,2.5 -2.5,3 0,1.5 2,2.6 2.1,2.6 a 1.08,1.08 0 0 1 0.5,1.5 1.1,1.1 0 0 1 -1,0.6 z"
                        transform="translate(1,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path140"
                        d="m 61.8,587.47 a 1.22,1.22 0 0 1 -1.2,-1.2 v -28.4 a 1.2,1.2 0 0 1 2.4,0 v 28.4 a 1.22,1.22 0 0 1 -1.2,1.2 z"
                        transform="translate(1,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path142"
                        d="M 64.5,558.67 H 59 a 1.22,1.22 0 0 1 -1.2,-1.2 1.16,1.16 0 0 1 1.2,-1.2 h 5.5 a 1.2,1.2 0 0 1 0,2.4 z"
                        transform="translate(1,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart.planets.saturn)
                        "
                        class="planet-substrate-rect saturn-chart-comp"
                        x="52"
                        y="529"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes[9]
                      }"
                      class="planet-number-chart saturn-num-chart"
                      x="3"
                      y="529"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_saturn"
                      v-if="chartBodygraphChart.gatesDesignExt.saturn"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesDesignExt.saturn
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesDesignExt.saturn.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="550.1795"
                      x="5"
                      font-size="20.72"
                      class="col-bg-chart"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{
                        chartBodygraphChart.gatesDesignExt.saturn.gate || 0
                      }}.{{
                        chartBodygraphChart.gatesDesignExt.saturn.line || 0
                      }}
                    </text>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer[9]
                      }"
                      class="planet-number-chart saturn-num-chart"
                      x="88"
                      y="529"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_saturn"
                      v-if="chartBodygraphChart.gatesPersonalityExt.saturn"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesPersonalityExt.saturn
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesPersonalityExt.saturn.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="550.1795"
                      x="90"
                      font-size="20.72"
                      class="col-bg-chart"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{
                        chartBodygraphChart.gatesPersonalityExt.saturn.gate ||
                          0
                      }}.{{
                        chartBodygraphChart.gatesPersonalityExt.saturn.line || 0
                      }}
                    </text>
                    <text
                      v-if="chartBodygraphChart.gatesDesignExt.saturn.isExalted"
                      y="542"
                      x="-12.5"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesDesignExt.saturn.isDetriment
                      "
                      y="555.5"
                      x="-12.5"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9661;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.saturn.isExalted
                      "
                      y="542"
                      x="135"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.saturn
                          .isDetriment
                      "
                      y="555.5"
                      x="135"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g6928" transform="translate(23.809448,18.44752)">
                    <g
                      id="chart_sign_jupiter"
                      v-if="chartBodygraphChart.planets.jupiter"
                      class="planet-chart1"
                      transform="translate(0.19720089,-0.14602661)"
                    >
                      <path
                        id="path145"
                        d="m 60.6,530.05 a 1.17,1.17 0 0 1 -1.1,-0.8 1.09,1.09 0 0 1 0.4,-1.3 c 8.5,-5.9 8.9,-12 8.9,-12.1 a 5.8002155,5.8002155 0 0 0 -11.6,0.1 1.2,1.2 0 0 1 -2.4,0 A 8.1000386,8.1000386 0 1 1 71,516 c 0,0.3 -0.4,5.9 -7.2,11.8 l 14.4,-0.2 a 1.13,1.13 0 0 1 1.2,1.1 1.2,1.2 0 0 1 -1.1,1.2 l -17.7,0.2 z"
                        transform="translate(1,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path147"
                        d="m 74.3,536.05 a 1.22,1.22 0 0 1 -1.2,-1.2 v -27.1 a 1.2,1.2 0 0 1 2.4,0 v 27.1 a 1.22,1.22 0 0 1 -1.2,1.2 z"
                        transform="translate(1,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart.planets.jupiter)
                        "
                        class="planet-substrate-rect jupiter-chart-comp"
                        x="52"
                        y="478"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes[8]
                      }"
                      class="planet-number-chart jupiter-num-chart"
                      x="3"
                      y="478"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_jupiter"
                      v-if="chartBodygraphChart.gatesDesignExt.jupiter"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesDesignExt.jupiter
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesDesignExt.jupiter.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="499.36761"
                      x="5"
                      font-size="20.72"
                      class="col-bg-chart"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{
                        chartBodygraphChart.gatesDesignExt.jupiter.gate || 0
                      }}.{{
                        chartBodygraphChart.gatesDesignExt.jupiter.line || 0
                      }}
                    </text>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer[8]
                      }"
                      class="planet-number-chart jupiter-num-chart"
                      x="88"
                      y="478"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_jupiter"
                      v-if="chartBodygraphChart.gatesPersonalityExt.jupiter"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesPersonalityExt.jupiter
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesPersonalityExt.jupiter.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="499.36761"
                      x="90"
                      font-size="20.72"
                      class="col-bg-chart"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{
                        chartBodygraphChart.gatesPersonalityExt.jupiter.gate ||
                          0
                      }}.{{
                        chartBodygraphChart.gatesPersonalityExt.jupiter.line ||
                          0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesDesignExt.jupiter.isExalted
                      "
                      y="490"
                      x="-12.5"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesDesignExt.jupiter.isDetriment
                      "
                      y="503.5"
                      x="-12.5"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9661;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.jupiter
                          .isExalted
                      "
                      y="490"
                      x="135"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.jupiter
                          .isDetriment
                      "
                      y="503.5"
                      x="135"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g6922" transform="translate(23.809448,18.44752)">
                    <g
                      id="chart_sign_mars"
                      v-if="chartBodygraphChart.planets.mars"
                      class="planet-chart1"
                      transform="translate(-0.06931317,0.0256958)"
                    >
                      <path
                        id="path150"
                        d="m 66,484.14 a 12.2,12.2 0 1 1 12.2,-12.2 12.25,12.25 0 0 1 -12.2,12.2 z m 0,-22.2 a 9.9,9.9 0 1 0 9.9,9.9 9.92,9.92 0 0 0 -9.9,-9.9 z"
                        transform="translate(1,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path152"
                        d="m 73.2,465.14 a 0.91,0.91 0 0 1 -0.8,-0.4 1.21,1.21 0 0 1 0,-1.6 l 6.3,-6 a 1.1313708,1.1313708 0 0 1 1.6,1.6 l -6.3,6 a 1.23,1.23 0 0 1 -0.8,0.4 z"
                        transform="translate(1,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path154"
                        d="m 78.9,464 h -0.2 a 1.2,1.2 0 0 1 -1,-1.3 l 0.6,-3.8 -3.8,0.6 a 1.16,1.16 0 0 1 -0.3,-2.3 l 5.4,-0.9 a 1.21,1.21 0 0 1 1,0.3 1,1 0 0 1 0.3,1 L 80,463 a 1.16,1.16 0 0 1 -1.1,1 z"
                        transform="translate(1,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart.planets.mars)
                        "
                        class="planet-substrate-rect mars-chart-comp"
                        x="52"
                        y="426"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes[7]
                      }"
                      class="planet-number-chart mars-num-chart"
                      x="3"
                      y="426"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_mars"
                      v-if="chartBodygraphChart.gatesDesignExt.mars"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesDesignExt.mars
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesDesignExt.mars.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="448.45212"
                      x="5"
                      font-size="20.72"
                      class="col-bg-chart"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{ chartBodygraphChart.gatesDesignExt.mars.gate || 0 }}.{{
                        chartBodygraphChart.gatesDesignExt.mars.line || 0
                      }}
                    </text>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer[7]
                      }"
                      class="planet-number-chart mars-num-chart"
                      x="88"
                      y="426"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_mars"
                      v-if="chartBodygraphChart.gatesPersonalityExt.mars"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesPersonalityExt.mars
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesPersonalityExt.mars.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="448.45212"
                      x="90"
                      font-size="20.72"
                      class="col-bg-chart"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{
                        chartBodygraphChart.gatesPersonalityExt.mars.gate || 0
                      }}.{{
                        chartBodygraphChart.gatesPersonalityExt.mars.line || 0
                      }}
                    </text>
                    <text
                      v-if="chartBodygraphChart.gatesDesignExt.mars.isExalted"
                      y="440"
                      x="-12.5"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="chartBodygraphChart.gatesDesignExt.mars.isDetriment"
                      y="453.5"
                      x="-12.5"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9661;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.mars.isExalted
                      "
                      y="440"
                      x="135"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.mars.isDetriment
                      "
                      y="453.5"
                      x="135"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g6915" transform="translate(23.809448,18.44752)">
                    <g
                      id="chart_sign_venus"
                      v-if="chartBodygraphChart.planets.venus"
                      class="planet-chart1"
                      transform="translate(-0.00279789,0.29763794)"
                    >
                      <path
                        id="path157"
                        d="M 67.3,425 A 10.4,10.4 0 1 1 77.7,414.6 10.39,10.39 0 0 1 67.3,425 Z m 0,-18.6 a 8.1,8.1 0 1 0 8.1,8.1 8.07,8.07 0 0 0 -8.1,-8.05 z"
                        transform="translate(1,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path159"
                        d="m 67.3,433.85 a 1.22,1.22 0 0 1 -1.2,-1.2 v -8.1 a 1.2,1.2 0 0 1 2.4,0 v 8.1 a 1.16,1.16 0 0 1 -1.2,1.2 z"
                        transform="translate(1,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path161"
                        d="M 72.6,429.75 H 62.1 a 1.22,1.22 0 0 1 -1.2,-1.2 1.16,1.16 0 0 1 1.2,-1.2 h 10.5 a 1.2,1.2 0 1 1 0,2.4 z"
                        transform="translate(1,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart.planets.venus)
                        "
                        class="planet-substrate-rect venus-chart-comp"
                        x="52"
                        y="376"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes[6]
                      }"
                      class="planet-number-chart venus-num-chart"
                      x="3"
                      y="376"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_venus"
                      v-if="chartBodygraphChart.gatesDesignExt.venus"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesDesignExt.venus
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesDesignExt.venus.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="397.53662"
                      x="5"
                      font-size="20.72"
                      class="col-bg-chart"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{
                        chartBodygraphChart.gatesDesignExt.venus.gate || 0
                      }}.{{
                        chartBodygraphChart.gatesDesignExt.venus.line || 0
                      }}
                    </text>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer[6]
                      }"
                      class="planet-number-chart venus-num-chart"
                      x="88"
                      y="376"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_venus"
                      v-if="chartBodygraphChart.gatesPersonalityExt.venus"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesPersonalityExt.venus
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesPersonalityExt.venus.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="397.53662"
                      x="90"
                      font-size="20.72"
                      class="col-bg-chart"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{
                        chartBodygraphChart.gatesPersonalityExt.venus.gate || 0
                      }}.{{
                        chartBodygraphChart.gatesPersonalityExt.venus.line || 0
                      }}
                    </text>
                    <text
                      v-if="chartBodygraphChart.gatesDesignExt.venus.isExalted"
                      y="389"
                      x="-12.5"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesDesignExt.venus.isDetriment
                      "
                      y="402.5"
                      x="-12.5"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9661;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.venus.isExalted
                      "
                      y="389"
                      x="135"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.venus
                          .isDetriment
                      "
                      y="402.5"
                      x="135"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g6908" transform="translate(23.809448,18.44752)">
                    <g
                      id="chart_sign_mercury"
                      v-if="chartBodygraphChart.planets.mercury"
                      class="planet-chart1"
                      transform="translate(-0.0420642,0.36169434)"
                    >
                      <path
                        id="path164"
                        d="m 67.4,374.89 a 10,10 0 1 1 10,-10 10,10 0 0 1 -10,10 z m 0,-17.7 a 7.7,7.7 0 1 0 7.7,7.7 7.7,7.7 0 0 0 -7.7,-7.7 z"
                        transform="translate(1,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path166"
                        d="m 67.4,383.19 a 1.22,1.22 0 0 1 -1.2,-1.2 v -7.7 a 1.2,1.2 0 1 1 2.4,0 V 382 a 1.16,1.16 0 0 1 -1.2,1.19 z"
                        transform="translate(1,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path168"
                        d="m 72.4,379.39 h -10 a 1.22,1.22 0 0 1 -1.2,-1.2 1.16,1.16 0 0 1 1.2,-1.2 h 10 a 1.2,1.2 0 1 1 0,2.4 z"
                        transform="translate(1,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path170"
                        d="m 60.9,359.79 a 1.1,1.1 0 0 1 -1,-0.6 l -2.5,-4.4 a 1.17,1.17 0 0 1 2,-1.2 l 2.5,4.4 a 1.23,1.23 0 0 1 -0.4,1.6 0.73,0.73 0 0 1 -0.6,0.2 z"
                        transform="translate(1,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path172"
                        d="m 73.5,358.89 a 1.42,1.42 0 0 1 -0.6,-0.2 1.23,1.23 0 0 1 -0.4,-1.6 l 2.3,-3.7 a 1.17,1.17 0 0 1 2,1.2 l -2.3,3.7 a 1.22,1.22 0 0 1 -1,0.6 z"
                        transform="translate(1,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart.planets.mercury)
                        "
                        class="planet-substrate-rect mercury-chart-com"
                        x="52"
                        y="325"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes[5]
                      }"
                      class="planet-number-chart mercury-num-chart"
                      x="3"
                      y="325"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_mercury"
                      v-if="chartBodygraphChart.gatesDesignExt.mercury"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesDesignExt.mercury
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesDesignExt.mercury.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="346.6315"
                      x="5"
                      font-size="20.72"
                      class="col-bg-chart"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{
                        chartBodygraphChart.gatesDesignExt.mercury.gate || 0
                      }}.{{
                        chartBodygraphChart.gatesDesignExt.mercury.line || 0
                      }}
                    </text>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer[5]
                      }"
                      class="planet-number-chart mercury-num-chart"
                      x="88"
                      y="325"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_mercury"
                      v-if="chartBodygraphChart.gatesPersonalityExt.mercury"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesPersonalityExt.mercury
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesPersonalityExt.mercury.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="346.6315"
                      x="90"
                      font-size="20.72"
                      class="col-bg-chart"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{
                        chartBodygraphChart.gatesPersonalityExt.mercury.gate ||
                          0
                      }}.{{
                        chartBodygraphChart.gatesPersonalityExt.mercury.line ||
                          0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesDesignExt.mercury.isExalted
                      "
                      y="338"
                      x="-12.5"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesDesignExt.mercury.isDetriment
                      "
                      y="351.5"
                      x="-12.5"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9661;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.mercury
                          .isExalted
                      "
                      y="338"
                      x="135"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.mercury
                          .isDetriment
                      "
                      y="351.5"
                      x="135"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g6899" transform="translate(23.809448,18.44752)">
                    <g
                      id="chart_sign_south_node"
                      v-if="chartBodygraphChart.planets.south_node"
                      class="planet-chart1"
                    >
                      <path
                        d="m 20.457205,302.22933 a 10.75,10.75 0 0 1 -7.9,-3.5 8.39,8.39 0 0 1 -2.3,-6.8 c 0.3,-2.8 4,-5.3 6.6,-7.1 l 0.8,-0.6 a 3.26,3.26 0 0 0 1.4,-2.6 3.1,3.1 0 0 0 -1,-2.4 5.23,5.23 0 0 0 -3.4,-1.2 5.41,5.41 0 0 0 -4.6,2.6 1.17,1.17 0 0 1 -2.0000002,-1.2 7.55,7.55 0 0 1 6.5000002,-3.7 7.88,7.88 0 0 1 4.9,1.7 9,9 0 0 1 0.9,1 9,9 0 0 1 0.9,-1 7.88,7.88 0 0 1 4.9,-1.7 7.55,7.55 0 0 1 6.5,3.7 1.17,1.17 0 0 1 -2,1.2 5.39,5.39 0 0 0 -8,-1.4 2.69,2.69 0 0 0 -1,2.4 3.42,3.42 0 0 0 1.4,2.6 l 0.8,0.6 c 2.7,1.8 6.3,4.3 6.6,7.1 a 8.59,8.59 0 0 1 -2.3,7 9.88,9.88 0 0 1 -7.7,3.3 z m 0,-17.6 a 6.73,6.73 0 0 1 -1.4,1.5 l -0.8,0.6 c -2,1.4 -5.5,3.7 -5.6,5.5 a 6.63,6.63 0 0 0 1.7,5.1 8.47,8.47 0 0 0 6.2,2.7 8.07,8.07 0 0 0 6.2,-2.6 6.61,6.61 0 0 0 1.7,-5.2 c -0.2,-1.7 -3.6,-4.1 -5.6,-5.5 l -0.8,-0.6 a 4.63,4.63 0 0 1 -1.6,-1.5 z"
                        inkscape:connector-curvature="0"
                        transform="translate(48,-0.71)"
                      />
                      <rect
                        @click="
                          openTooltipDialog(
                            chartBodygraphChart.planets.south_node
                          )
                        "
                        class="planet-substrate-rect south-node-chart-comp"
                        x="52"
                        y="274"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes[3]
                      }"
                      class="planet-number-chart south-node-num-chart"
                      x="3"
                      y="274"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_south_node"
                      v-if="chartBodygraphChart.gatesDesignExt.south_node"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesDesignExt.south_node
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesDesignExt.south_node.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="295.70563"
                      x="5"
                      font-size="20.72"
                      class="col-bg-chart"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{
                        chartBodygraphChart.gatesDesignExt.south_node.gate || 0
                      }}.{{
                        chartBodygraphChart.gatesDesignExt.south_node.line || 0
                      }}
                    </text>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer[3]
                      }"
                      class="planet-number-chart south-node-num-chart"
                      x="88"
                      y="274"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_south_node"
                      v-if="chartBodygraphChart.gatesPersonalityExt.south_node"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesPersonalityExt.south_node
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesPersonalityExt.south_node
                            .gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="295.70563"
                      x="90"
                      font-size="20.72"
                      class="col-bg-chart"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{
                        chartBodygraphChart.gatesPersonalityExt.south_node
                          .gate || 0
                      }}.{{
                        chartBodygraphChart.gatesPersonalityExt.south_node
                          .line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesDesignExt.south_node.isExalted
                      "
                      y="288"
                      x="-12.5"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesDesignExt.south_node
                          .isDetriment
                      "
                      y="301.5"
                      x="-12.5"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9661;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.south_node
                          .isExalted
                      "
                      y="288"
                      x="135"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.south_node
                          .isDetriment
                      "
                      y="301.5"
                      x="135"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g6895" transform="translate(23.809448,18.44752)">
                    <g
                      id="chart_sign_north_node"
                      v-if="chartBodygraphChart.planets.north_node"
                      class="planet-chart1"
                    >
                      <path
                        data-name="chart_sign_north_node"
                        d="m 26.057205,251.3175 a 7.88,7.88 0 0 1 -4.9,-1.7 9,9 0 0 1 -0.9,-1 9,9 0 0 1 -0.9,1 7.88,7.88 0 0 1 -4.9,1.7 7.55,7.55 0 0 1 -6.5000007,-3.7 1.17,1.17 0 0 1 2,-1.2 5.39,5.39 0 0 0 8.0000007,1.4 2.69,2.69 0 0 0 1,-2.4 3.42,3.42 0 0 0 -1.4,-2.6 l -0.8,-0.6 c -2.7,-1.8 -6.3,-4.3 -6.6,-7.1 a 8.62,8.62 0 0 1 2.3,-6.8 10.75,10.75 0 0 1 7.9,-3.5 10.62,10.62 0 0 1 7.9,3.3 9.32,9.32 0 0 1 2.3,7 c -0.3,2.8 -4,5.3 -6.6,7.1 l -0.8,0.6 a 3.26,3.26 0 0 0 -1.4,2.6 3.1,3.1 0 0 0 1,2.4 5.23,5.23 0 0 0 3.4,1.2 5.41,5.41 0 0 0 4.6,-2.6 1.17,1.17 0 0 1 2,1.2 7.91,7.91 0 0 1 -6.7,3.7 z m -5.7,-24.1 a 8.64,8.64 0 0 0 -6.2,2.7 6.51,6.51 0 0 0 -1.7,5.1 c 0.2,1.7 3.6,4.1 5.6,5.5 l 0.8,0.6 a 4,4 0 0 1 1.4,1.5 6.73,6.73 0 0 1 1.4,-1.5 l 0.8,-0.6 c 2,-1.4 5.5,-3.7 5.6,-5.5 a 6.61,6.61 0 0 0 -1.7,-5.2 7.36,7.36 0 0 0 -6,-2.6 z"
                        inkscape:connector-curvature="0"
                        transform="translate(48,-0.71)"
                      />
                      <rect
                        @click="
                          openTooltipDialog(
                            chartBodygraphChart.planets.north_node
                          )
                        "
                        class="planet-substrate-rect north-node-chart-comp"
                        x="52"
                        y="223"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes[2]
                      }"
                      class="planet-number-chart north-node-num-chart"
                      x="3"
                      y="223"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_north_node"
                      v-if="chartBodygraphChart.gatesDesignExt.north_node"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesDesignExt.north_node
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesDesignExt.north_node.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="244.80052"
                      x="5"
                      font-size="20.72"
                      class="col-bg-chart"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{
                        chartBodygraphChart.gatesDesignExt.north_node.gate || 0
                      }}.{{
                        chartBodygraphChart.gatesDesignExt.north_node.line || 0
                      }}
                    </text>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer[2]
                      }"
                      class="planet-number-chart north-node-num-chart"
                      x="88"
                      y="223"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_north_node"
                      v-if="chartBodygraphChart.gatesPersonalityExt.north_node"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesPersonalityExt.north_node
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesPersonalityExt.north_node
                            .gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="244.80052"
                      x="90"
                      font-size="20.72"
                      class="col-bg-chart"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{
                        chartBodygraphChart.gatesPersonalityExt.north_node
                          .gate || 0
                      }}.{{
                        chartBodygraphChart.gatesPersonalityExt.north_node
                          .line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesDesignExt.north_node.isExalted
                      "
                      y="237"
                      x="-12.5"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesDesignExt.north_node
                          .isDetriment
                      "
                      y="250.5"
                      x="-12.5"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9661;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.north_node
                          .isExalted
                      "
                      y="237"
                      x="135"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.north_node
                          .isDetriment
                      "
                      y="250.5"
                      x="135"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g6891" transform="translate(23.809448,18.44752)">
                    <g
                      id="chart_sign_moon"
                      v-if="chartBodygraphChart.planets.moon"
                      class="planet-chart1"
                    >
                      <path
                        d="m 22.387774,202.05097 a 14.9,14.9 0 1 1 8.7,-27 1.09,1.09 0 0 1 0.4,1.3 1.21,1.21 0 0 1 -1.2,0.8 h -0.6 a 9.42,9.42 0 1 0 2.1,18.6 1.2,1.2 0 0 1 1.3,0.6 1.23,1.23 0 0 1 -0.2,1.4 15.08,15.08 0 0 1 -10.5,4.3 z m 0,-27.5 a 12.6,12.6 0 0 0 0,25.2 12.42,12.42 0 0 0 6,-1.5 11.75,11.75 0 0 1 -1.8,-23 12.34,12.34 0 0 0 -4.2,-0.7 z"
                        inkscape:connector-curvature="0"
                        transform="translate(48,-0.71)"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart.planets.moon)
                        "
                        class="planet-substrate-rect moon-chart-comp"
                        x="52"
                        y="171"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes[4]
                      }"
                      class="planet-number-chart moon-num-chart"
                      x="3"
                      y="172"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_moon"
                      v-if="chartBodygraphChart.gatesDesignExt.moon"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesDesignExt.moon
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesDesignExt.moon.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="193.88498"
                      x="5"
                      font-size="20.72"
                      class="col-bg-chart"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{ chartBodygraphChart.gatesDesignExt.moon.gate || 0 }}.{{
                        chartBodygraphChart.gatesDesignExt.moon.line || 0
                      }}
                    </text>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer[4]
                      }"
                      class="planet-number-chart moon-num-chart"
                      x="88"
                      y="172"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_moon"
                      v-if="chartBodygraphChart.gatesPersonalityExt.moon"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesPersonalityExt.moon
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesPersonalityExt.moon.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="193.88498"
                      x="90"
                      font-size="20.72"
                      class="col-bg-chart"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{
                        chartBodygraphChart.gatesPersonalityExt.moon.gate || 0
                      }}.{{
                        chartBodygraphChart.gatesPersonalityExt.moon.line || 0
                      }}
                    </text>
                    <text
                      v-if="chartBodygraphChart.gatesDesignExt.moon.isExalted"
                      y="187"
                      x="-12.5"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="chartBodygraphChart.gatesDesignExt.moon.isDetriment"
                      y="200.5"
                      x="-12.5"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9661;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.moon.isExalted
                      "
                      y="187"
                      x="135"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.moon.isDetriment
                      "
                      y="200.5"
                      x="135"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g6887" transform="translate(23.809448,18.44752)">
                    <g
                      id="chart_sign_earth"
                      v-if="chartBodygraphChart.planets.earth"
                      class="planet-chart1"
                      transform="translate(-0.00279548,0.74554443)"
                    >
                      <path
                        id="path178"
                        d="M 67.3,176.7 A 12.7,12.7 0 1 1 80,164 12.72,12.72 0 0 1 67.3,176.7 Z m 0,-23.1 A 10.4,10.4 0 1 0 77.7,164 10.39,10.39 0 0 0 67.3,153.6 Z"
                        transform="translate(1,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path180"
                        d="m 67.3,176.5 a 1.22,1.22 0 0 1 -1.2,-1.2 v -22.8 a 1.2,1.2 0 0 1 2.4,0 v 22.8 a 1.22,1.22 0 0 1 -1.2,1.2 z"
                        transform="translate(1,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path182"
                        d="M 78.7,165 H 55.9 a 1.22,1.22 0 0 1 -1.2,-1.2 1.16,1.16 0 0 1 1.2,-1.2 h 22.8 a 1.22,1.22 0 0 1 1.2,1.2 1.16,1.16 0 0 1 -1.2,1.2 z"
                        transform="translate(1,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart.planets.earth)
                        "
                        class="planet-substrate-rect earth-chart-comp"
                        x="52"
                        y="121"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes[1]
                      }"
                      class="planet-number-chart earth-num-chart"
                      x="3"
                      y="121"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_earth"
                      v-if="chartBodygraphChart.gatesDesignExt.earth"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesDesignExt.earth
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesDesignExt.earth.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="142.96954"
                      x="5"
                      font-size="20.72"
                      class="col-bg-chart"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{
                        chartBodygraphChart.gatesDesignExt.earth.gate || 0
                      }}.{{
                        chartBodygraphChart.gatesDesignExt.earth.line || 0
                      }}
                    </text>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer[1]
                      }"
                      class="planet-number-chart earth-num-chart"
                      x="88"
                      y="121"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_earth"
                      v-if="chartBodygraphChart.gatesPersonalityExt.earth"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesPersonalityExt.earth
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesPersonalityExt.earth.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="142.96954"
                      x="90"
                      font-size="20.72"
                      class="col-bg-chart"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{
                        chartBodygraphChart.gatesPersonalityExt.earth.gate || 0
                      }}.{{
                        chartBodygraphChart.gatesPersonalityExt.earth.line || 0
                      }}
                    </text>
                    <text
                      v-if="chartBodygraphChart.gatesDesignExt.earth.isExalted"
                      y="134"
                      x="-12.5"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesDesignExt.earth.isDetriment
                      "
                      y="147.5"
                      x="-12.5"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9661;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.earth.isExalted
                      "
                      y="134"
                      x="135"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.earth
                          .isDetriment
                      "
                      y="147.5"
                      x="135"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g6880" transform="translate(23.809448,18.44752)">
                    <g
                      id="chart_sign_sun"
                      v-if="chartBodygraphChart.planets.sun"
                      class="planet-chart1"
                      transform="translate(0.2471061)"
                    >
                      <path
                        id="path185"
                        d="m 67,126.48 a 12.65,12.65 0 1 1 12.7,-12.6 12.72,12.72 0 0 1 -12.7,12.6 z m 0,-23 a 10.4,10.4 0 1 0 10.4,10.4 10.39,10.39 0 0 0 -10.4,-10.4 z"
                        transform="translate(1,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <circle
                        id="circle187"
                        cx="68"
                        cy="85.169998"
                        r="5.5999999"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart.planets.sun)
                        "
                        class="planet-substrate-rect sun-chart-comp"
                        x="52"
                        y="70"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes[0]
                      }"
                      class="planet-number-chart sun-num-chart"
                      x="3"
                      y="70"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_sun"
                      v-if="chartBodygraphChart.gatesDesignExt.sun"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesDesignExt.sun
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesDesignExt.sun.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="92.054047"
                      x="5"
                      font-size="20.72"
                      class="col-bg-chart"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                      inkscape:label=""
                    >
                      {{ chartBodygraphChart.gatesDesignExt.sun.gate || 0 }}.{{
                        chartBodygraphChart.gatesDesignExt.sun.line || 0
                      }}
                    </text>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer[0]
                      }"
                      class="planet-number-chart sun-num-chart"
                      x="88"
                      y="70"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_sun"
                      v-if="chartBodygraphChart.gatesPersonalityExt.sun"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesPersonalityExt.sun
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesPersonalityExt.sun.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="92.054047"
                      x="90"
                      font-size="20.72"
                      class="col-bg-chart"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                      inkscape:label=""
                    >
                      {{
                        chartBodygraphChart.gatesPersonalityExt.sun.gate || 0
                      }}.{{
                        chartBodygraphChart.gatesPersonalityExt.sun.line || 0
                      }}
                    </text>
                    <text
                      v-if="chartBodygraphChart.gatesDesignExt.sun.isExalted"
                      y="84"
                      x="-12.5"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="chartBodygraphChart.gatesDesignExt.sun.isDetriment"
                      y="97"
                      x="-12.5"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9661;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.sun.isExalted
                      "
                      y="84"
                      x="135"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.sun.isDetriment
                      "
                      y="97"
                      x="135"
                      font-size="16"
                    >
                      <tspan fill="#4caf50" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                </g>
                <g id="g7409" :class="{ opacity4: !isChart2Toggle }">
                  <text
                    id="text226"
                    class="col-bg-chart uppercase"
                    style="text-anchor:start;font-size:20.71999931px;isolation:isolate"
                    font-size="20.72"
                    x="28"
                    y="40.90752"
                  >
                    {{ chartBodygraphChartName }}
                  </text>
                  <path
                    :class="{ not_active_toggle: isChartToggle }"
                    class="chart_toggle"
                    fill="none"
                    stroke-miterlimit="10"
                    d="M 75.967 15.864 c 0 8.461 -6.858 15.319 -15.319 15.319 H 15.819 c -8.46 0 -15.319 -6.858 -15.319 -15.319 l 0 0 C 0.5 7.358 7.358 0.5 15.819 0.5 h 44.975 C 69.129 0.511 75.973 7.336 75.967 15.844 L 75.973 15.86 z"
                    transform="translate(30,45)"
                  />
                  <circle
                    :class="{ not_active_chart: isChartToggle }"
                    class="chart_toggle_cir"
                    cx="46.5"
                    cy="61"
                    r="12.836"
                  />
                  <rect
                    @click="showChart()"
                    class="chart1-rect"
                    x="32"
                    y="45"
                  />
                  <circle class="des_toggle_quest" cx="121.5" cy="55" r="8" />
                  <text
                    class="des_quest_quest"
                    style="font-size:14px;isolation:isolate"
                    font-size="14"
                    x="118"
                    y="60"
                  >
                    ?
                  </text>
                  <rect
                    @click="
                      openTooltipDialog(
                        convertToTooltipDialogObj(
                          Messages['title_help_composite_chart1'],
                          Messages['help_composite_chart1']
                        )
                      )
                    "
                    class="design-rect_quest"
                    x="112"
                    y="45"
                  />
                </g>
              </g>
              <g id="g8332_d" transform="translate(0,2.9624634)">
                <g id="g7327_d" :class="{ opacity4: isChartToggle }">
                  <g id="g6958" transform="translate(23.809448,18.44752)">
                    <g
                      id="design_sign_pluto_d"
                      v-if="chartBodygraphChart.planets.pluto"
                      class="planet-des"
                    >
                      <path
                        d="m 26.96,711.26 h -16.7 a 1,1 0 0 1 -1.1,-1.1 l 0.1,-27.7 a 1.14,1.14 0 0 1 0.3,-0.8 0.83,0.83 0 0 1 0.8,-0.3 l 11.3,0.1 a 9.9,9.9 0 0 1 0,19.8 l -10.2,0.1 v 7.7 h 15.5 a 1.22,1.22 0 0 1 1.2,1.2 1.13,1.13 0 0 1 -1.2,1 z m -15.4,-27.7 v 15.4 l 10.2,-0.1 a 7.6,7.6 0 0 0 0,-15.2 z"
                        inkscape:connector-curvature="0"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart.planets.pluto)
                        "
                        class="planet-substrate-rect pluto-des"
                        x="5"
                        y="683"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes[12]
                      }"
                      class="planet-number-des pluto-num-des"
                      x="38"
                      y="683"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_pluto_d"
                      v-if="chartBodygraphChart.gatesDesignExt.pluto"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesDesignExt.pluto
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesDesignExt.pluto.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="703.03998"
                      x="42.369999"
                      font-size="20.72"
                      class="col-bg-des"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate;"
                    >
                      {{
                        chartBodygraphChart.gatesDesignExt.pluto.gate || 0
                      }}.{{
                        chartBodygraphChart.gatesDesignExt.pluto.line || 0
                      }}
                    </text>
                    <text
                      v-if="chartBodygraphChart.gatesDesignExt.pluto.isExalted"
                      y="695"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesDesignExt.pluto.isDetriment
                      "
                      y="708.5"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g6954_d" transform="translate(23.809448,18.44752)">
                    <g
                      id="design_sign_neptune_d"
                      v-if="chartBodygraphChart.planets.neptune"
                      class="planet-des"
                      transform="translate(-0.05279548,-0.34454346)"
                    >
                      <path
                        id="path114_d"
                        d="m 67.3,676.65 a 13.51,13.51 0 0 1 -13.5,-13.5 1.2,1.2 0 0 1 2.4,0 11.2,11.2 0 0 0 22.4,0 1.2,1.2 0 0 1 2.4,0 13.88,13.88 0 0 1 -13.7,13.5 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path116_d"
                        d="m 67.3,689.75 a 1.22,1.22 0 0 1 -1.2,-1.2 v -25.9 a 1.2,1.2 0 0 1 2.4,0 v 25.9 a 1.38,1.38 0 0 1 -1.2,1.2 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path118_d"
                        d="M 75.1,683.55 H 59.6 a 1.2,1.2 0 1 1 0,-2.4 h 15.5 a 1.22,1.22 0 0 1 1.2,1.2 1.29,1.29 0 0 1 -1.2,1.2 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <polygon
                        id="polygon120_d"
                        points="8.06,630.23 4.66,636.13 11.56,636.13 "
                      />
                      <polygon
                        id="polygon122_d"
                        points="20.36,630.23 16.86,636.13 23.76,636.13 "
                      />
                      <polygon
                        id="polygon124_d"
                        points="32.66,630.23 29.26,636.13 36.16,636.13 "
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart.planets.neptune)
                        "
                        class="planet-substrate-rect neptune-des"
                        x="5"
                        y="630"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes[11]
                      }"
                      class="planet-number-des neptune-num-des"
                      x="38"
                      y="630"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_neptune_d"
                      v-if="chartBodygraphChart.gatesDesignExt.neptune"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesDesignExt.neptune
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesDesignExt.neptune.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="652.12445"
                      x="42.369999"
                      font-size="20.72"
                      class="col-bg-des"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{
                        chartBodygraphChart.gatesDesignExt.neptune.gate || 0
                      }}.{{
                        chartBodygraphChart.gatesDesignExt.neptune.line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesDesignExt.neptune.isExalted
                      "
                      y="644"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesDesignExt.neptune.isDetriment
                      "
                      y="657.5"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g6944_d" transform="translate(23.809448,18.44752)">
                    <g
                      id="design_sign_uranus_d"
                      v-if="chartBodygraphChart.planets.uranus"
                      class="planet-des"
                      transform="translate(-0.00279548,-0.99504089)"
                    >
                      <path
                        id="path127_d"
                        d="m 56.3,634.08 a 1.08,1.08 0 0 1 -0.7,-0.3 1,1 0 0 1 0,-1.4 15,15 0 0 0 4.1,-10.2 14.66,14.66 0 0 0 -4.3,-10.5 1,1 0 0 1 1.4,-1.4 16.78,16.78 0 0 1 0.2,23.5 2.12,2.12 0 0 1 -0.7,0.3 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path129_d"
                        d="m 78.3,634.58 a 0.91,0.91 0 0 1 -0.7,-0.3 16.77,16.77 0 0 1 -4.7,-11.6 17.05,17.05 0 0 1 4.9,-11.9 1,1 0 0 1 1.4,1.4 14.86,14.86 0 0 0 -0.2,20.7 1,1 0 0 1 0,1.4 1.08,1.08 0 0 1 -0.7,0.3 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path131_d"
                        d="m 67.3,628.58 a 0.94,0.94 0 0 1 -1,-1 v -16.2 a 1,1 0 0 1 2,0 v 16.2 a 1.08,1.08 0 0 1 -1,1 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path133_d"
                        d="m 67.3,637.88 a 5.5,5.5 0 1 1 5.5,-5.5 5.55,5.55 0 0 1 -5.5,5.5 z m 0,-8.9 a 3.5,3.5 0 1 0 3.5,3.5 3.48,3.48 0 0 0 -3.5,-3.48 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path135_d"
                        d="M 73.8,623.08 H 60.7 a 1,1 0 1 1 0,-2 h 13.1 a 1,1 0 1 1 0,2 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart.planets.uranus)
                        "
                        class="planet-substrate-rect uranus-des"
                        x="5"
                        y="580"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes[10]
                      }"
                      class="planet-number-des uranus-num-des"
                      x="38"
                      y="580"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_uranus_d"
                      v-if="chartBodygraphChart.gatesDesignExt.uranus"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesDesignExt.uranus
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesDesignExt.uranus.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="601.20892"
                      x="42.369999"
                      font-size="20.72"
                      class="col-bg-des"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{
                        chartBodygraphChart.gatesDesignExt.uranus.gate || 0
                      }}.{{
                        chartBodygraphChart.gatesDesignExt.uranus.line || 0
                      }}
                    </text>
                    <text
                      v-if="chartBodygraphChart.gatesDesignExt.uranus.isExalted"
                      y="593"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesDesignExt.uranus.isDetriment
                      "
                      y="606.5"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g6935_d" transform="translate(23.809448,18.44752)">
                    <g
                      id="design_sign_saturn_d"
                      v-if="chartBodygraphChart.planets.saturn"
                      class="planet-des"
                      transform="translate(0.04756189,0.1998291)"
                    >
                      <path
                        id="path138_d"
                        d="m 71.2,586.87 a 0.9,0.9 0 0 1 -0.5,-0.1 c -0.1,-0.1 -3.3,-1.7 -3.3,-4.7 0,-1.5 1.4,-2.9 3.2,-4.6 1.8,-1.7 3.9,-3.9 3.9,-5.6 a 5.7,5.7 0 0 0 -11.4,0 1.2,1.2 0 0 1 -2.4,0 8,8 0 0 1 16,0 c 0,2.7 -2.4,5.1 -4.6,7.3 -1,1 -2.5,2.5 -2.5,3 0,1.5 2,2.6 2.1,2.6 a 1.08,1.08 0 0 1 0.5,1.5 1.1,1.1 0 0 1 -1,0.6 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path140_d"
                        d="m 61.8,587.47 a 1.22,1.22 0 0 1 -1.2,-1.2 v -28.4 a 1.2,1.2 0 0 1 2.4,0 v 28.4 a 1.22,1.22 0 0 1 -1.2,1.2 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path142_d"
                        d="M 64.5,558.67 H 59 a 1.22,1.22 0 0 1 -1.2,-1.2 1.16,1.16 0 0 1 1.2,-1.2 h 5.5 a 1.2,1.2 0 0 1 0,2.4 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart.planets.saturn)
                        "
                        class="planet-substrate-rect saturn-des"
                        x="5"
                        y="529"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes[9]
                      }"
                      class="planet-number-des saturn-num-des"
                      x="38"
                      y="529"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_saturn_d"
                      v-if="chartBodygraphChart.gatesDesignExt.saturn"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesDesignExt.saturn
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesDesignExt.saturn.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="550.1795"
                      x="42.369999"
                      font-size="20.72"
                      class="col-bg-des"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{
                        chartBodygraphChart.gatesDesignExt.saturn.gate || 0
                      }}.{{
                        chartBodygraphChart.gatesDesignExt.saturn.line || 0
                      }}
                    </text>
                    <text
                      v-if="chartBodygraphChart.gatesDesignExt.saturn.isExalted"
                      y="542"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesDesignExt.saturn.isDetriment
                      "
                      y="555.5"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g6928_d" transform="translate(23.809448,18.44752)">
                    <g
                      id="design_sign_jupiter_d"
                      v-if="chartBodygraphChart.planets.jupiter"
                      class="planet-des"
                      transform="translate(0.19720089,-0.14602661)"
                    >
                      <path
                        id="path145_d"
                        d="m 60.6,530.05 a 1.17,1.17 0 0 1 -1.1,-0.8 1.09,1.09 0 0 1 0.4,-1.3 c 8.5,-5.9 8.9,-12 8.9,-12.1 a 5.8002155,5.8002155 0 0 0 -11.6,0.1 1.2,1.2 0 0 1 -2.4,0 A 8.1000386,8.1000386 0 1 1 71,516 c 0,0.3 -0.4,5.9 -7.2,11.8 l 14.4,-0.2 a 1.13,1.13 0 0 1 1.2,1.1 1.2,1.2 0 0 1 -1.1,1.2 l -17.7,0.2 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path147_d"
                        d="m 74.3,536.05 a 1.22,1.22 0 0 1 -1.2,-1.2 v -27.1 a 1.2,1.2 0 0 1 2.4,0 v 27.1 a 1.22,1.22 0 0 1 -1.2,1.2 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart.planets.jupiter)
                        "
                        class="planet-substrate-rect jupiter-des"
                        x="5"
                        y="478"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes[8]
                      }"
                      class="planet-number-des jupiter-num-des"
                      x="38"
                      y="478"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_jupiter_d"
                      v-if="chartBodygraphChart.gatesDesignExt.jupiter"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesDesignExt.jupiter
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesDesignExt.jupiter.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="499.36761"
                      x="42.369999"
                      font-size="20.72"
                      class="col-bg-des"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{
                        chartBodygraphChart.gatesDesignExt.jupiter.gate || 0
                      }}.{{
                        chartBodygraphChart.gatesDesignExt.jupiter.line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesDesignExt.jupiter.isExalted
                      "
                      y="490"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesDesignExt.jupiter.isDetriment
                      "
                      y="503.5"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g6922_d" transform="translate(23.809448,18.44752)">
                    <g
                      id="design_sign_mars_d"
                      v-if="chartBodygraphChart.planets.mars"
                      class="planet-des"
                      transform="translate(-0.06931317,0.0256958)"
                    >
                      <path
                        id="path150_d"
                        d="m 66,484.14 a 12.2,12.2 0 1 1 12.2,-12.2 12.25,12.25 0 0 1 -12.2,12.2 z m 0,-22.2 a 9.9,9.9 0 1 0 9.9,9.9 9.92,9.92 0 0 0 -9.9,-9.9 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path152_d"
                        d="m 73.2,465.14 a 0.91,0.91 0 0 1 -0.8,-0.4 1.21,1.21 0 0 1 0,-1.6 l 6.3,-6 a 1.1313708,1.1313708 0 0 1 1.6,1.6 l -6.3,6 a 1.23,1.23 0 0 1 -0.8,0.4 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path154_d"
                        d="m 78.9,464 h -0.2 a 1.2,1.2 0 0 1 -1,-1.3 l 0.6,-3.8 -3.8,0.6 a 1.16,1.16 0 0 1 -0.3,-2.3 l 5.4,-0.9 a 1.21,1.21 0 0 1 1,0.3 1,1 0 0 1 0.3,1 L 80,463 a 1.16,1.16 0 0 1 -1.1,1 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart.planets.mars)
                        "
                        class="planet-substrate-rect mars-des"
                        x="5"
                        y="426"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes[7]
                      }"
                      class="planet-number-des mars-num-des"
                      x="38"
                      y="426"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_mars_d"
                      v-if="chartBodygraphChart.gatesDesignExt.mars"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesDesignExt.mars
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesDesignExt.mars.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="448.45212"
                      x="42.369999"
                      font-size="20.72"
                      class="col-bg-des"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{ chartBodygraphChart.gatesDesignExt.mars.gate || 0 }}.{{
                        chartBodygraphChart.gatesDesignExt.mars.line || 0
                      }}
                    </text>
                    <text
                      v-if="chartBodygraphChart.gatesDesignExt.mars.isExalted"
                      y="440"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="chartBodygraphChart.gatesDesignExt.mars.isDetriment"
                      y="453.5"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g6915_d" transform="translate(23.809448,18.44752)">
                    <g
                      id="design_sign_venus_d"
                      v-if="chartBodygraphChart.planets.venus"
                      class="planet-des"
                      transform="translate(-0.00279789,0.29763794)"
                    >
                      <path
                        id="path157_d"
                        d="M 67.3,425 A 10.4,10.4 0 1 1 77.7,414.6 10.39,10.39 0 0 1 67.3,425 Z m 0,-18.6 a 8.1,8.1 0 1 0 8.1,8.1 8.07,8.07 0 0 0 -8.1,-8.05 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path159_d"
                        d="m 67.3,433.85 a 1.22,1.22 0 0 1 -1.2,-1.2 v -8.1 a 1.2,1.2 0 0 1 2.4,0 v 8.1 a 1.16,1.16 0 0 1 -1.2,1.2 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path161_d"
                        d="M 72.6,429.75 H 62.1 a 1.22,1.22 0 0 1 -1.2,-1.2 1.16,1.16 0 0 1 1.2,-1.2 h 10.5 a 1.2,1.2 0 1 1 0,2.4 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart.planets.venus)
                        "
                        class="planet-substrate-rect venus-des"
                        x="5"
                        y="376"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes[6]
                      }"
                      class="planet-number-des venus-num-des"
                      x="38"
                      y="376"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_venus_d"
                      v-if="chartBodygraphChart.gatesDesignExt.venus"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesDesignExt.venus
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesDesignExt.venus.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="397.53662"
                      x="42.369999"
                      font-size="20.72"
                      class="col-bg-des"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{
                        chartBodygraphChart.gatesDesignExt.venus.gate || 0
                      }}.{{
                        chartBodygraphChart.gatesDesignExt.venus.line || 0
                      }}
                    </text>
                    <text
                      v-if="chartBodygraphChart.gatesDesignExt.venus.isExalted"
                      y="389"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesDesignExt.venus.isDetriment
                      "
                      y="402.5"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g6908_d" transform="translate(23.809448,18.44752)">
                    <g
                      id="design_sign_mercury_d"
                      v-if="chartBodygraphChart.planets.mercury"
                      class="planet-des"
                      transform="translate(-0.0420642,0.36169434)"
                    >
                      <path
                        id="path164_d"
                        d="m 67.4,374.89 a 10,10 0 1 1 10,-10 10,10 0 0 1 -10,10 z m 0,-17.7 a 7.7,7.7 0 1 0 7.7,7.7 7.7,7.7 0 0 0 -7.7,-7.7 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path166_d"
                        d="m 67.4,383.19 a 1.22,1.22 0 0 1 -1.2,-1.2 v -7.7 a 1.2,1.2 0 1 1 2.4,0 V 382 a 1.16,1.16 0 0 1 -1.2,1.19 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path168_d"
                        d="m 72.4,379.39 h -10 a 1.22,1.22 0 0 1 -1.2,-1.2 1.16,1.16 0 0 1 1.2,-1.2 h 10 a 1.2,1.2 0 1 1 0,2.4 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path170_d"
                        d="m 60.9,359.79 a 1.1,1.1 0 0 1 -1,-0.6 l -2.5,-4.4 a 1.17,1.17 0 0 1 2,-1.2 l 2.5,4.4 a 1.23,1.23 0 0 1 -0.4,1.6 0.73,0.73 0 0 1 -0.6,0.2 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path172_d"
                        d="m 73.5,358.89 a 1.42,1.42 0 0 1 -0.6,-0.2 1.23,1.23 0 0 1 -0.4,-1.6 l 2.3,-3.7 a 1.17,1.17 0 0 1 2,1.2 l -2.3,3.7 a 1.22,1.22 0 0 1 -1,0.6 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart.planets.mercury)
                        "
                        class="planet-substrate-rect mercury-des"
                        x="5"
                        y="325"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes[5]
                      }"
                      class="planet-number-des mercury-num-des"
                      x="38"
                      y="325"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_mercury_d"
                      v-if="chartBodygraphChart.gatesDesignExt.mercury"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesDesignExt.mercury
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesDesignExt.mercury.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="346.6315"
                      x="42.369999"
                      font-size="20.72"
                      class="col-bg-des"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{
                        chartBodygraphChart.gatesDesignExt.mercury.gate || 0
                      }}.{{
                        chartBodygraphChart.gatesDesignExt.mercury.line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesDesignExt.mercury.isExalted
                      "
                      y="338"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesDesignExt.mercury.isDetriment
                      "
                      y="351.5"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g6899_d" transform="translate(23.809448,18.44752)">
                    <g
                      id="design_sign_south_node_d"
                      v-if="chartBodygraphChart.planets.south_node"
                      class="planet-des"
                    >
                      <path
                        d="m 20.457205,302.22933 a 10.75,10.75 0 0 1 -7.9,-3.5 8.39,8.39 0 0 1 -2.3,-6.8 c 0.3,-2.8 4,-5.3 6.6,-7.1 l 0.8,-0.6 a 3.26,3.26 0 0 0 1.4,-2.6 3.1,3.1 0 0 0 -1,-2.4 5.23,5.23 0 0 0 -3.4,-1.2 5.41,5.41 0 0 0 -4.6,2.6 1.17,1.17 0 0 1 -2.0000002,-1.2 7.55,7.55 0 0 1 6.5000002,-3.7 7.88,7.88 0 0 1 4.9,1.7 9,9 0 0 1 0.9,1 9,9 0 0 1 0.9,-1 7.88,7.88 0 0 1 4.9,-1.7 7.55,7.55 0 0 1 6.5,3.7 1.17,1.17 0 0 1 -2,1.2 5.39,5.39 0 0 0 -8,-1.4 2.69,2.69 0 0 0 -1,2.4 3.42,3.42 0 0 0 1.4,2.6 l 0.8,0.6 c 2.7,1.8 6.3,4.3 6.6,7.1 a 8.59,8.59 0 0 1 -2.3,7 9.88,9.88 0 0 1 -7.7,3.3 z m 0,-17.6 a 6.73,6.73 0 0 1 -1.4,1.5 l -0.8,0.6 c -2,1.4 -5.5,3.7 -5.6,5.5 a 6.63,6.63 0 0 0 1.7,5.1 8.47,8.47 0 0 0 6.2,2.7 8.07,8.07 0 0 0 6.2,-2.6 6.61,6.61 0 0 0 1.7,-5.2 c -0.2,-1.7 -3.6,-4.1 -5.6,-5.5 l -0.8,-0.6 a 4.63,4.63 0 0 1 -1.6,-1.5 z"
                        inkscape:connector-curvature="0"
                      />
                      <rect
                        @click="
                          openTooltipDialog(
                            chartBodygraphChart.planets.south_node
                          )
                        "
                        class="planet-substrate-rect south-node-des"
                        x="5"
                        y="274"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes[3]
                      }"
                      class="planet-number-des south-node-num-des"
                      x="38"
                      y="274"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_south_node_d"
                      v-if="chartBodygraphChart.gatesDesignExt.south_node"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesDesignExt.south_node
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesDesignExt.south_node.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="295.70563"
                      x="42.369999"
                      font-size="20.72"
                      class="col-bg-des"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{
                        chartBodygraphChart.gatesDesignExt.south_node.gate || 0
                      }}.{{
                        chartBodygraphChart.gatesDesignExt.south_node.line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesDesignExt.south_node.isExalted
                      "
                      y="288"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesDesignExt.south_node
                          .isDetriment
                      "
                      y="301.5"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g6895_d" transform="translate(23.809448,18.44752)">
                    <g
                      id="design_sign_north_node_d"
                      v-if="chartBodygraphChart.planets.north_node"
                      class="planet-des"
                    >
                      <path
                        data-name="design_sign_north_node"
                        d="m 26.057205,251.3175 a 7.88,7.88 0 0 1 -4.9,-1.7 9,9 0 0 1 -0.9,-1 9,9 0 0 1 -0.9,1 7.88,7.88 0 0 1 -4.9,1.7 7.55,7.55 0 0 1 -6.5000007,-3.7 1.17,1.17 0 0 1 2,-1.2 5.39,5.39 0 0 0 8.0000007,1.4 2.69,2.69 0 0 0 1,-2.4 3.42,3.42 0 0 0 -1.4,-2.6 l -0.8,-0.6 c -2.7,-1.8 -6.3,-4.3 -6.6,-7.1 a 8.62,8.62 0 0 1 2.3,-6.8 10.75,10.75 0 0 1 7.9,-3.5 10.62,10.62 0 0 1 7.9,3.3 9.32,9.32 0 0 1 2.3,7 c -0.3,2.8 -4,5.3 -6.6,7.1 l -0.8,0.6 a 3.26,3.26 0 0 0 -1.4,2.6 3.1,3.1 0 0 0 1,2.4 5.23,5.23 0 0 0 3.4,1.2 5.41,5.41 0 0 0 4.6,-2.6 1.17,1.17 0 0 1 2,1.2 7.91,7.91 0 0 1 -6.7,3.7 z m -5.7,-24.1 a 8.64,8.64 0 0 0 -6.2,2.7 6.51,6.51 0 0 0 -1.7,5.1 c 0.2,1.7 3.6,4.1 5.6,5.5 l 0.8,0.6 a 4,4 0 0 1 1.4,1.5 6.73,6.73 0 0 1 1.4,-1.5 l 0.8,-0.6 c 2,-1.4 5.5,-3.7 5.6,-5.5 a 6.61,6.61 0 0 0 -1.7,-5.2 7.36,7.36 0 0 0 -6,-2.6 z"
                        inkscape:connector-curvature="0"
                      />
                      <rect
                        @click="
                          openTooltipDialog(
                            chartBodygraphChart.planets.north_node
                          )
                        "
                        class="planet-substrate-rect north-node-des"
                        x="5"
                        y="223"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes[2]
                      }"
                      class="planet-number-des north-node-num-des"
                      x="38"
                      y="223"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_north_node_d"
                      v-if="chartBodygraphChart.gatesDesignExt.north_node"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesDesignExt.north_node
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesDesignExt.north_node.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="244.80052"
                      x="42.369999"
                      font-size="20.72"
                      class="col-bg-des"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{
                        chartBodygraphChart.gatesDesignExt.north_node.gate || 0
                      }}.{{
                        chartBodygraphChart.gatesDesignExt.north_node.line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesDesignExt.north_node.isExalted
                      "
                      y="237"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesDesignExt.north_node
                          .isDetriment
                      "
                      y="250.5"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g6891_d" transform="translate(23.809448,18.44752)">
                    <g
                      id="design_sign_moon_d"
                      v-if="chartBodygraphChart.planets.moon"
                      class="planet-des"
                    >
                      <path
                        d="m 22.387774,202.05097 a 14.9,14.9 0 1 1 8.7,-27 1.09,1.09 0 0 1 0.4,1.3 1.21,1.21 0 0 1 -1.2,0.8 h -0.6 a 9.42,9.42 0 1 0 2.1,18.6 1.2,1.2 0 0 1 1.3,0.6 1.23,1.23 0 0 1 -0.2,1.4 15.08,15.08 0 0 1 -10.5,4.3 z m 0,-27.5 a 12.6,12.6 0 0 0 0,25.2 12.42,12.42 0 0 0 6,-1.5 11.75,11.75 0 0 1 -1.8,-23 12.34,12.34 0 0 0 -4.2,-0.7 z"
                        inkscape:connector-curvature="0"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart.planets.moon)
                        "
                        class="planet-substrate-rect moon-des"
                        x="5"
                        y="171"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes[4]
                      }"
                      class="planet-number-des moon-num-des"
                      x="38"
                      y="172"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_moon_d"
                      v-if="chartBodygraphChart.gatesDesignExt.moon"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesDesignExt.moon
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesDesignExt.moon.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="193.88498"
                      x="42.369999"
                      font-size="20.72"
                      class="col-bg-des"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{ chartBodygraphChart.gatesDesignExt.moon.gate || 0 }}.{{
                        chartBodygraphChart.gatesDesignExt.moon.line || 0
                      }}
                    </text>
                    <text
                      v-if="chartBodygraphChart.gatesDesignExt.moon.isExalted"
                      y="187"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="chartBodygraphChart.gatesDesignExt.moon.isDetriment"
                      y="200.5"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g6887_d" transform="translate(23.809448,18.44752)">
                    <g
                      id="design_sign_earth_d"
                      v-if="chartBodygraphChart.planets.earth"
                      class="planet-des"
                      transform="translate(-0.00279548,0.74554443)"
                    >
                      <path
                        id="path178_d"
                        d="M 67.3,176.7 A 12.7,12.7 0 1 1 80,164 12.72,12.72 0 0 1 67.3,176.7 Z m 0,-23.1 A 10.4,10.4 0 1 0 77.7,164 10.39,10.39 0 0 0 67.3,153.6 Z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path180_d"
                        d="m 67.3,176.5 a 1.22,1.22 0 0 1 -1.2,-1.2 v -22.8 a 1.2,1.2 0 0 1 2.4,0 v 22.8 a 1.22,1.22 0 0 1 -1.2,1.2 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path182_d"
                        d="M 78.7,165 H 55.9 a 1.22,1.22 0 0 1 -1.2,-1.2 1.16,1.16 0 0 1 1.2,-1.2 h 22.8 a 1.22,1.22 0 0 1 1.2,1.2 1.16,1.16 0 0 1 -1.2,1.2 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart.planets.earth)
                        "
                        class="planet-substrate-rect earth-des"
                        x="5"
                        y="121"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes[1]
                      }"
                      class="planet-number-des earth-num-des"
                      x="38"
                      y="121"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_earth_d"
                      v-if="chartBodygraphChart.gatesDesignExt.earth"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesDesignExt.earth
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesDesignExt.earth.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="142.96954"
                      x="42.369999"
                      font-size="20.72"
                      class="col-bg-des"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{
                        chartBodygraphChart.gatesDesignExt.earth.gate || 0
                      }}.{{
                        chartBodygraphChart.gatesDesignExt.earth.line || 0
                      }}
                    </text>
                    <text
                      v-if="chartBodygraphChart.gatesDesignExt.earth.isExalted"
                      y="134"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesDesignExt.earth.isDetriment
                      "
                      y="147.5"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g6880_d" transform="translate(23.809448,18.44752)">
                    <g
                      id="design_sign_sun_d"
                      v-if="chartBodygraphChart.planets.sun"
                      class="planet-des"
                      transform="translate(0.2471061)"
                    >
                      <path
                        id="path185_d"
                        d="m 67,126.48 a 12.65,12.65 0 1 1 12.7,-12.6 12.72,12.72 0 0 1 -12.7,12.6 z m 0,-23 a 10.4,10.4 0 1 0 10.4,10.4 10.39,10.39 0 0 0 -10.4,-10.4 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <circle
                        id="circle187_d"
                        cx="20.059999"
                        cy="85.169998"
                        r="5.5999999"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart.planets.sun)
                        "
                        class="planet-substrate-rect sun-des"
                        x="5"
                        y="70"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes[0]
                      }"
                      class="planet-number-des sun-num-des"
                      x="38"
                      y="70"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_sun_d"
                      v-if="chartBodygraphChart.gatesDesignExt.sun"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesDesignExt.sun
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesDesignExt.sun.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="92.054047"
                      x="42.369999"
                      font-size="20.72"
                      class="col-bg-des"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                      inkscape:label=""
                    >
                      {{ chartBodygraphChart.gatesDesignExt.sun.gate || 0 }}.{{
                        chartBodygraphChart.gatesDesignExt.sun.line || 0
                      }}
                    </text>
                    <text
                      v-if="chartBodygraphChart.gatesDesignExt.sun.isExalted"
                      y="84"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="chartBodygraphChart.gatesDesignExt.sun.isDetriment"
                      y="97"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                </g>
              </g>
              <g id="g8332_d2" transform="translate(0,2.9624634)">
                <g id="g7327_d2" :class="{ opacity4: isChart2Toggle }">
                  <g id="g6958_p" transform="translate(23.809448,18.44752)">
                    <g
                      id="design_sign_pluto_d2"
                      v-if="chartBodygraphChart2.planets.pluto"
                      class="planet-des"
                    >
                      <path
                        d="m 26.96,711.26 h -16.7 a 1,1 0 0 1 -1.1,-1.1 l 0.1,-27.7 a 1.14,1.14 0 0 1 0.3,-0.8 0.83,0.83 0 0 1 0.8,-0.3 l 11.3,0.1 a 9.9,9.9 0 0 1 0,19.8 l -10.2,0.1 v 7.7 h 15.5 a 1.22,1.22 0 0 1 1.2,1.2 1.13,1.13 0 0 1 -1.2,1 z m -15.4,-27.7 v 15.4 l 10.2,-0.1 a 7.6,7.6 0 0 0 0,-15.2 z"
                        inkscape:connector-curvature="0"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart2.planets.pluto)
                        "
                        class="planet-substrate-rect pluto-des"
                        x="5"
                        y="683"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes2[12]
                      }"
                      class="planet-number-des pluto-num-des"
                      x="38"
                      y="683"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_pluto_d2"
                      v-if="chartBodygraphChart2.gatesDesignExt.pluto"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesDesignExt.pluto
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesDesignExt.pluto.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="703.03998"
                      x="42.369999"
                      font-size="20.72"
                      class="col-bg-des"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate;"
                    >
                      {{
                        chartBodygraphChart2.gatesDesignExt.pluto.gate || 0
                      }}.{{
                        chartBodygraphChart2.gatesDesignExt.pluto.line || 0
                      }}
                    </text>
                    <text
                      v-if="chartBodygraphChart2.gatesDesignExt.pluto.isExalted"
                      y="695"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesDesignExt.pluto.isDetriment
                      "
                      y="708.5"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g6954_d2" transform="translate(23.809448,18.44752)">
                    <g
                      id="design_sign_neptune_d2"
                      v-if="chartBodygraphChart2.planets.neptune"
                      class="planet-des"
                      transform="translate(-0.05279548,-0.34454346)"
                    >
                      <path
                        id="path114_d2"
                        d="m 67.3,676.65 a 13.51,13.51 0 0 1 -13.5,-13.5 1.2,1.2 0 0 1 2.4,0 11.2,11.2 0 0 0 22.4,0 1.2,1.2 0 0 1 2.4,0 13.88,13.88 0 0 1 -13.7,13.5 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path116_d2"
                        d="m 67.3,689.75 a 1.22,1.22 0 0 1 -1.2,-1.2 v -25.9 a 1.2,1.2 0 0 1 2.4,0 v 25.9 a 1.38,1.38 0 0 1 -1.2,1.2 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path118_d2"
                        d="M 75.1,683.55 H 59.6 a 1.2,1.2 0 1 1 0,-2.4 h 15.5 a 1.22,1.22 0 0 1 1.2,1.2 1.29,1.29 0 0 1 -1.2,1.2 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <polygon
                        id="polygon120_d2"
                        points="8.06,630.23 4.66,636.13 11.56,636.13 "
                      />
                      <polygon
                        id="polygon122_d2"
                        points="20.36,630.23 16.86,636.13 23.76,636.13 "
                      />
                      <polygon
                        id="polygon124_d2"
                        points="32.66,630.23 29.26,636.13 36.16,636.13 "
                      />
                      <rect
                        @click="
                          openTooltipDialog(
                            chartBodygraphChart2.planets.neptune
                          )
                        "
                        class="planet-substrate-rect neptune-des"
                        x="5"
                        y="630"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes2[11]
                      }"
                      class="planet-number-des neptune-num-des"
                      x="38"
                      y="630"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_neptune_d2"
                      v-if="chartBodygraphChart2.gatesDesignExt.neptune"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesDesignExt.neptune
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesDesignExt.neptune.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="652.12445"
                      x="42.369999"
                      font-size="20.72"
                      class="col-bg-des"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{
                        chartBodygraphChart2.gatesDesignExt.neptune.gate || 0
                      }}.{{
                        chartBodygraphChart2.gatesDesignExt.neptune.line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesDesignExt.neptune.isExalted
                      "
                      y="644"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesDesignExt.neptune.isDetriment
                      "
                      y="657.5"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g6944_d2" transform="translate(23.809448,18.44752)">
                    <g
                      id="design_sign_uranus_d2"
                      v-if="chartBodygraphChart2.planets.uranus"
                      class="planet-des"
                      transform="translate(-0.00279548,-0.99504089)"
                    >
                      <path
                        id="path127_d2"
                        d="m 56.3,634.08 a 1.08,1.08 0 0 1 -0.7,-0.3 1,1 0 0 1 0,-1.4 15,15 0 0 0 4.1,-10.2 14.66,14.66 0 0 0 -4.3,-10.5 1,1 0 0 1 1.4,-1.4 16.78,16.78 0 0 1 0.2,23.5 2.12,2.12 0 0 1 -0.7,0.3 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path129_d2"
                        d="m 78.3,634.58 a 0.91,0.91 0 0 1 -0.7,-0.3 16.77,16.77 0 0 1 -4.7,-11.6 17.05,17.05 0 0 1 4.9,-11.9 1,1 0 0 1 1.4,1.4 14.86,14.86 0 0 0 -0.2,20.7 1,1 0 0 1 0,1.4 1.08,1.08 0 0 1 -0.7,0.3 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path131_d2"
                        d="m 67.3,628.58 a 0.94,0.94 0 0 1 -1,-1 v -16.2 a 1,1 0 0 1 2,0 v 16.2 a 1.08,1.08 0 0 1 -1,1 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path133_d2"
                        d="m 67.3,637.88 a 5.5,5.5 0 1 1 5.5,-5.5 5.55,5.55 0 0 1 -5.5,5.5 z m 0,-8.9 a 3.5,3.5 0 1 0 3.5,3.5 3.48,3.48 0 0 0 -3.5,-3.48 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path135_d2"
                        d="M 73.8,623.08 H 60.7 a 1,1 0 1 1 0,-2 h 13.1 a 1,1 0 1 1 0,2 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart2.planets.uranus)
                        "
                        class="planet-substrate-rect uranus-des"
                        x="5"
                        y="580"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes2[10]
                      }"
                      class="planet-number-des uranus-num-des"
                      x="38"
                      y="580"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_uranus_d2"
                      v-if="chartBodygraphChart2.gatesDesignExt.uranus"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesDesignExt.uranus
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesDesignExt.uranus.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="601.20892"
                      x="42.369999"
                      font-size="20.72"
                      class="col-bg-des"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{
                        chartBodygraphChart2.gatesDesignExt.uranus.gate || 0
                      }}.{{
                        chartBodygraphChart2.gatesDesignExt.uranus.line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesDesignExt.uranus.isExalted
                      "
                      y="593"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesDesignExt.uranus.isDetriment
                      "
                      y="606.5"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g6935_d2" transform="translate(23.809448,18.44752)">
                    <g
                      id="design_sign_saturn_d2"
                      v-if="chartBodygraphChart2.planets.saturn"
                      class="planet-des"
                      transform="translate(0.04756189,0.1998291)"
                    >
                      <path
                        id="path138_d2"
                        d="m 71.2,586.87 a 0.9,0.9 0 0 1 -0.5,-0.1 c -0.1,-0.1 -3.3,-1.7 -3.3,-4.7 0,-1.5 1.4,-2.9 3.2,-4.6 1.8,-1.7 3.9,-3.9 3.9,-5.6 a 5.7,5.7 0 0 0 -11.4,0 1.2,1.2 0 0 1 -2.4,0 8,8 0 0 1 16,0 c 0,2.7 -2.4,5.1 -4.6,7.3 -1,1 -2.5,2.5 -2.5,3 0,1.5 2,2.6 2.1,2.6 a 1.08,1.08 0 0 1 0.5,1.5 1.1,1.1 0 0 1 -1,0.6 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path140_d2"
                        d="m 61.8,587.47 a 1.22,1.22 0 0 1 -1.2,-1.2 v -28.4 a 1.2,1.2 0 0 1 2.4,0 v 28.4 a 1.22,1.22 0 0 1 -1.2,1.2 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path142_d2"
                        d="M 64.5,558.67 H 59 a 1.22,1.22 0 0 1 -1.2,-1.2 1.16,1.16 0 0 1 1.2,-1.2 h 5.5 a 1.2,1.2 0 0 1 0,2.4 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart2.planets.saturn)
                        "
                        class="planet-substrate-rect saturn-des"
                        x="5"
                        y="529"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes2[9]
                      }"
                      class="planet-number-des saturn-num-des"
                      x="38"
                      y="529"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_saturn_d2"
                      v-if="chartBodygraphChart2.gatesDesignExt.saturn"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesDesignExt.saturn
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesDesignExt.saturn.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="550.1795"
                      x="42.369999"
                      font-size="20.72"
                      class="col-bg-des"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{
                        chartBodygraphChart2.gatesDesignExt.saturn.gate || 0
                      }}.{{
                        chartBodygraphChart2.gatesDesignExt.saturn.line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesDesignExt.saturn.isExalted
                      "
                      y="542"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesDesignExt.saturn.isDetriment
                      "
                      y="555.5"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g6928_d2" transform="translate(23.809448,18.44752)">
                    <g
                      id="design_sign_jupiter_d2"
                      v-if="chartBodygraphChart2.planets.jupiter"
                      class="planet-des"
                      transform="translate(0.19720089,-0.14602661)"
                    >
                      <path
                        id="path145_d2"
                        d="m 60.6,530.05 a 1.17,1.17 0 0 1 -1.1,-0.8 1.09,1.09 0 0 1 0.4,-1.3 c 8.5,-5.9 8.9,-12 8.9,-12.1 a 5.8002155,5.8002155 0 0 0 -11.6,0.1 1.2,1.2 0 0 1 -2.4,0 A 8.1000386,8.1000386 0 1 1 71,516 c 0,0.3 -0.4,5.9 -7.2,11.8 l 14.4,-0.2 a 1.13,1.13 0 0 1 1.2,1.1 1.2,1.2 0 0 1 -1.1,1.2 l -17.7,0.2 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path147_d2"
                        d="m 74.3,536.05 a 1.22,1.22 0 0 1 -1.2,-1.2 v -27.1 a 1.2,1.2 0 0 1 2.4,0 v 27.1 a 1.22,1.22 0 0 1 -1.2,1.2 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <rect
                        @click="
                          openTooltipDialog(
                            chartBodygraphChart2.planets.jupiter
                          )
                        "
                        class="planet-substrate-rect jupiter-des"
                        x="5"
                        y="478"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes2[8]
                      }"
                      class="planet-number-des jupiter-num-des"
                      x="38"
                      y="478"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_jupiter_d2"
                      v-if="chartBodygraphChart2.gatesDesignExt.jupiter"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesDesignExt.jupiter
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesDesignExt.jupiter.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="499.36761"
                      x="42.369999"
                      font-size="20.72"
                      class="col-bg-des"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{
                        chartBodygraphChart2.gatesDesignExt.jupiter.gate || 0
                      }}.{{
                        chartBodygraphChart2.gatesDesignExt.jupiter.line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesDesignExt.jupiter.isExalted
                      "
                      y="490"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesDesignExt.jupiter.isDetriment
                      "
                      y="503.5"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g6922_d2" transform="translate(23.809448,18.44752)">
                    <g
                      id="design_sign_mars_d2"
                      v-if="chartBodygraphChart2.planets.mars"
                      class="planet-des"
                      transform="translate(-0.06931317,0.0256958)"
                    >
                      <path
                        id="path150_d2"
                        d="m 66,484.14 a 12.2,12.2 0 1 1 12.2,-12.2 12.25,12.25 0 0 1 -12.2,12.2 z m 0,-22.2 a 9.9,9.9 0 1 0 9.9,9.9 9.92,9.92 0 0 0 -9.9,-9.9 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path152_d2"
                        d="m 73.2,465.14 a 0.91,0.91 0 0 1 -0.8,-0.4 1.21,1.21 0 0 1 0,-1.6 l 6.3,-6 a 1.1313708,1.1313708 0 0 1 1.6,1.6 l -6.3,6 a 1.23,1.23 0 0 1 -0.8,0.4 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path154_d2"
                        d="m 78.9,464 h -0.2 a 1.2,1.2 0 0 1 -1,-1.3 l 0.6,-3.8 -3.8,0.6 a 1.16,1.16 0 0 1 -0.3,-2.3 l 5.4,-0.9 a 1.21,1.21 0 0 1 1,0.3 1,1 0 0 1 0.3,1 L 80,463 a 1.16,1.16 0 0 1 -1.1,1 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart2.planets.mars)
                        "
                        class="planet-substrate-rect mars-des"
                        x="5"
                        y="426"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes2[7]
                      }"
                      class="planet-number-des mars-num-des"
                      x="38"
                      y="426"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_mars_d2"
                      v-if="chartBodygraphChart2.gatesDesignExt.mars"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesDesignExt.mars
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesDesignExt.mars.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="448.45212"
                      x="42.369999"
                      font-size="20.72"
                      class="col-bg-des"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{
                        chartBodygraphChart2.gatesDesignExt.mars.gate || 0
                      }}.{{
                        chartBodygraphChart2.gatesDesignExt.mars.line || 0
                      }}
                    </text>
                    <text
                      v-if="chartBodygraphChart2.gatesDesignExt.mars.isExalted"
                      y="440"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesDesignExt.mars.isDetriment
                      "
                      y="453.5"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g6915_d2" transform="translate(23.809448,18.44752)">
                    <g
                      id="design_sign_venus_d2"
                      v-if="chartBodygraphChart2.planets.venus"
                      class="planet-des"
                      transform="translate(-0.00279789,0.29763794)"
                    >
                      <path
                        id="path157_d2"
                        d="M 67.3,425 A 10.4,10.4 0 1 1 77.7,414.6 10.39,10.39 0 0 1 67.3,425 Z m 0,-18.6 a 8.1,8.1 0 1 0 8.1,8.1 8.07,8.07 0 0 0 -8.1,-8.05 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path159_d2"
                        d="m 67.3,433.85 a 1.22,1.22 0 0 1 -1.2,-1.2 v -8.1 a 1.2,1.2 0 0 1 2.4,0 v 8.1 a 1.16,1.16 0 0 1 -1.2,1.2 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path161_d2"
                        d="M 72.6,429.75 H 62.1 a 1.22,1.22 0 0 1 -1.2,-1.2 1.16,1.16 0 0 1 1.2,-1.2 h 10.5 a 1.2,1.2 0 1 1 0,2.4 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart2.planets.venus)
                        "
                        class="planet-substrate-rect venus-des"
                        x="5"
                        y="376"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes2[6]
                      }"
                      class="planet-number-des venus-num-des"
                      x="38"
                      y="376"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_venus_d2"
                      v-if="chartBodygraphChart2.gatesDesignExt.venus"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesDesignExt.venus
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesDesignExt.venus.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="397.53662"
                      x="42.369999"
                      font-size="20.72"
                      class="col-bg-des"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{
                        chartBodygraphChart2.gatesDesignExt.venus.gate || 0
                      }}.{{
                        chartBodygraphChart2.gatesDesignExt.venus.line || 0
                      }}
                    </text>
                    <text
                      v-if="chartBodygraphChart2.gatesDesignExt.venus.isExalted"
                      y="389"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesDesignExt.venus.isDetriment
                      "
                      y="402.5"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g6908_d2" transform="translate(23.809448,18.44752)">
                    <g
                      id="design_sign_mercury_d2"
                      v-if="chartBodygraphChart2.planets.mercury"
                      class="planet-des"
                      transform="translate(-0.0420642,0.36169434)"
                    >
                      <path
                        id="path164_d2"
                        d="m 67.4,374.89 a 10,10 0 1 1 10,-10 10,10 0 0 1 -10,10 z m 0,-17.7 a 7.7,7.7 0 1 0 7.7,7.7 7.7,7.7 0 0 0 -7.7,-7.7 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path166_d2"
                        d="m 67.4,383.19 a 1.22,1.22 0 0 1 -1.2,-1.2 v -7.7 a 1.2,1.2 0 1 1 2.4,0 V 382 a 1.16,1.16 0 0 1 -1.2,1.19 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path168_d2"
                        d="m 72.4,379.39 h -10 a 1.22,1.22 0 0 1 -1.2,-1.2 1.16,1.16 0 0 1 1.2,-1.2 h 10 a 1.2,1.2 0 1 1 0,2.4 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path170_d2"
                        d="m 60.9,359.79 a 1.1,1.1 0 0 1 -1,-0.6 l -2.5,-4.4 a 1.17,1.17 0 0 1 2,-1.2 l 2.5,4.4 a 1.23,1.23 0 0 1 -0.4,1.6 0.73,0.73 0 0 1 -0.6,0.2 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path172_d2"
                        d="m 73.5,358.89 a 1.42,1.42 0 0 1 -0.6,-0.2 1.23,1.23 0 0 1 -0.4,-1.6 l 2.3,-3.7 a 1.17,1.17 0 0 1 2,1.2 l -2.3,3.7 a 1.22,1.22 0 0 1 -1,0.6 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <rect
                        @click="
                          openTooltipDialog(
                            chartBodygraphChart2.planets.mercury
                          )
                        "
                        class="planet-substrate-rect mercury-des"
                        x="5"
                        y="325"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes2[5]
                      }"
                      class="planet-number-des mercury-num-des"
                      x="38"
                      y="325"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_mercury_d2"
                      v-if="chartBodygraphChart2.gatesDesignExt.mercury"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesDesignExt.mercury
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesDesignExt.mercury.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="346.6315"
                      x="42.369999"
                      font-size="20.72"
                      class="col-bg-des"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{
                        chartBodygraphChart2.gatesDesignExt.mercury.gate || 0
                      }}.{{
                        chartBodygraphChart2.gatesDesignExt.mercury.line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesDesignExt.mercury.isExalted
                      "
                      y="338"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesDesignExt.mercury.isDetriment
                      "
                      y="351.5"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g6899_d2" transform="translate(23.809448,18.44752)">
                    <g
                      id="design_sign_south_node_d2"
                      v-if="chartBodygraphChart2.planets.south_node"
                      class="planet-des"
                    >
                      <path
                        d="m 20.457205,302.22933 a 10.75,10.75 0 0 1 -7.9,-3.5 8.39,8.39 0 0 1 -2.3,-6.8 c 0.3,-2.8 4,-5.3 6.6,-7.1 l 0.8,-0.6 a 3.26,3.26 0 0 0 1.4,-2.6 3.1,3.1 0 0 0 -1,-2.4 5.23,5.23 0 0 0 -3.4,-1.2 5.41,5.41 0 0 0 -4.6,2.6 1.17,1.17 0 0 1 -2.0000002,-1.2 7.55,7.55 0 0 1 6.5000002,-3.7 7.88,7.88 0 0 1 4.9,1.7 9,9 0 0 1 0.9,1 9,9 0 0 1 0.9,-1 7.88,7.88 0 0 1 4.9,-1.7 7.55,7.55 0 0 1 6.5,3.7 1.17,1.17 0 0 1 -2,1.2 5.39,5.39 0 0 0 -8,-1.4 2.69,2.69 0 0 0 -1,2.4 3.42,3.42 0 0 0 1.4,2.6 l 0.8,0.6 c 2.7,1.8 6.3,4.3 6.6,7.1 a 8.59,8.59 0 0 1 -2.3,7 9.88,9.88 0 0 1 -7.7,3.3 z m 0,-17.6 a 6.73,6.73 0 0 1 -1.4,1.5 l -0.8,0.6 c -2,1.4 -5.5,3.7 -5.6,5.5 a 6.63,6.63 0 0 0 1.7,5.1 8.47,8.47 0 0 0 6.2,2.7 8.07,8.07 0 0 0 6.2,-2.6 6.61,6.61 0 0 0 1.7,-5.2 c -0.2,-1.7 -3.6,-4.1 -5.6,-5.5 l -0.8,-0.6 a 4.63,4.63 0 0 1 -1.6,-1.5 z"
                        inkscape:connector-curvature="0"
                      />
                      <rect
                        @click="
                          openTooltipDialog(
                            chartBodygraphChart2.planets.south_node
                          )
                        "
                        class="planet-substrate-rect south-node-des"
                        x="5"
                        y="274"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes2[3]
                      }"
                      class="planet-number-des south-node-num-des"
                      x="38"
                      y="274"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_south_node_d2"
                      v-if="chartBodygraphChart2.gatesDesignExt.south_node"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesDesignExt.south_node
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesDesignExt.south_node.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="295.70563"
                      x="42.369999"
                      font-size="20.72"
                      class="col-bg-des"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{
                        chartBodygraphChart2.gatesDesignExt.south_node.gate ||
                          0
                      }}.{{
                        chartBodygraphChart2.gatesDesignExt.south_node.line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesDesignExt.south_node.isExalted
                      "
                      y="288"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesDesignExt.south_node
                          .isDetriment
                      "
                      y="301.5"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g6895_d2" transform="translate(23.809448,18.44752)">
                    <g
                      id="design_sign_north_node_d2"
                      v-if="chartBodygraphChart2.planets.north_node"
                      class="planet-des"
                    >
                      <path
                        data-name="design_sign_north_node"
                        d="m 26.057205,251.3175 a 7.88,7.88 0 0 1 -4.9,-1.7 9,9 0 0 1 -0.9,-1 9,9 0 0 1 -0.9,1 7.88,7.88 0 0 1 -4.9,1.7 7.55,7.55 0 0 1 -6.5000007,-3.7 1.17,1.17 0 0 1 2,-1.2 5.39,5.39 0 0 0 8.0000007,1.4 2.69,2.69 0 0 0 1,-2.4 3.42,3.42 0 0 0 -1.4,-2.6 l -0.8,-0.6 c -2.7,-1.8 -6.3,-4.3 -6.6,-7.1 a 8.62,8.62 0 0 1 2.3,-6.8 10.75,10.75 0 0 1 7.9,-3.5 10.62,10.62 0 0 1 7.9,3.3 9.32,9.32 0 0 1 2.3,7 c -0.3,2.8 -4,5.3 -6.6,7.1 l -0.8,0.6 a 3.26,3.26 0 0 0 -1.4,2.6 3.1,3.1 0 0 0 1,2.4 5.23,5.23 0 0 0 3.4,1.2 5.41,5.41 0 0 0 4.6,-2.6 1.17,1.17 0 0 1 2,1.2 7.91,7.91 0 0 1 -6.7,3.7 z m -5.7,-24.1 a 8.64,8.64 0 0 0 -6.2,2.7 6.51,6.51 0 0 0 -1.7,5.1 c 0.2,1.7 3.6,4.1 5.6,5.5 l 0.8,0.6 a 4,4 0 0 1 1.4,1.5 6.73,6.73 0 0 1 1.4,-1.5 l 0.8,-0.6 c 2,-1.4 5.5,-3.7 5.6,-5.5 a 6.61,6.61 0 0 0 -1.7,-5.2 7.36,7.36 0 0 0 -6,-2.6 z"
                        inkscape:connector-curvature="0"
                      />
                      <rect
                        @click="
                          openTooltipDialog(
                            chartBodygraphChart2.planets.north_node
                          )
                        "
                        class="planet-substrate-rect north-node-des"
                        x="5"
                        y="223"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes2[2]
                      }"
                      class="planet-number-des north-node-num-des"
                      x="38"
                      y="223"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_north_node_d2"
                      v-if="chartBodygraphChart2.gatesDesignExt.north_node"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesDesignExt.north_node
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesDesignExt.north_node.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="244.80052"
                      x="42.369999"
                      font-size="20.72"
                      class="col-bg-des"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{
                        chartBodygraphChart2.gatesDesignExt.north_node.gate ||
                          0
                      }}.{{
                        chartBodygraphChart2.gatesDesignExt.north_node.line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesDesignExt.north_node.isExalted
                      "
                      y="237"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesDesignExt.north_node
                          .isDetriment
                      "
                      y="250.5"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g6891_d2" transform="translate(23.809448,18.44752)">
                    <g
                      id="design_sign_moon_d2"
                      v-if="chartBodygraphChart2.planets.moon"
                      class="planet-des"
                    >
                      <path
                        d="m 22.387774,202.05097 a 14.9,14.9 0 1 1 8.7,-27 1.09,1.09 0 0 1 0.4,1.3 1.21,1.21 0 0 1 -1.2,0.8 h -0.6 a 9.42,9.42 0 1 0 2.1,18.6 1.2,1.2 0 0 1 1.3,0.6 1.23,1.23 0 0 1 -0.2,1.4 15.08,15.08 0 0 1 -10.5,4.3 z m 0,-27.5 a 12.6,12.6 0 0 0 0,25.2 12.42,12.42 0 0 0 6,-1.5 11.75,11.75 0 0 1 -1.8,-23 12.34,12.34 0 0 0 -4.2,-0.7 z"
                        inkscape:connector-curvature="0"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart2.planets.moon)
                        "
                        class="planet-substrate-rect moon-des"
                        x="5"
                        y="171"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes2[4]
                      }"
                      class="planet-number-des moon-num-des"
                      x="38"
                      y="172"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_moon_d2"
                      v-if="chartBodygraphChart2.gatesDesignExt.moon"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesDesignExt.moon
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesDesignExt.moon.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="193.88498"
                      x="42.369999"
                      font-size="20.72"
                      class="col-bg-des"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{
                        chartBodygraphChart2.gatesDesignExt.moon.gate || 0
                      }}.{{
                        chartBodygraphChart2.gatesDesignExt.moon.line || 0
                      }}
                    </text>
                    <text
                      v-if="chartBodygraphChart2.gatesDesignExt.moon.isExalted"
                      y="187"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesDesignExt.moon.isDetriment
                      "
                      y="200.5"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g6887_d2" transform="translate(23.809448,18.44752)">
                    <g
                      id="design_sign_earth_d2"
                      v-if="chartBodygraphChart2.planets.earth"
                      class="planet-des"
                      transform="translate(-0.00279548,0.74554443)"
                    >
                      <path
                        id="path178_d2"
                        d="M 67.3,176.7 A 12.7,12.7 0 1 1 80,164 12.72,12.72 0 0 1 67.3,176.7 Z m 0,-23.1 A 10.4,10.4 0 1 0 77.7,164 10.39,10.39 0 0 0 67.3,153.6 Z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path180_d2"
                        d="m 67.3,176.5 a 1.22,1.22 0 0 1 -1.2,-1.2 v -22.8 a 1.2,1.2 0 0 1 2.4,0 v 22.8 a 1.22,1.22 0 0 1 -1.2,1.2 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <path
                        id="path182_d2"
                        d="M 78.7,165 H 55.9 a 1.22,1.22 0 0 1 -1.2,-1.2 1.16,1.16 0 0 1 1.2,-1.2 h 22.8 a 1.22,1.22 0 0 1 1.2,1.2 1.16,1.16 0 0 1 -1.2,1.2 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart2.planets.earth)
                        "
                        class="planet-substrate-rect earth-des"
                        x="5"
                        y="121"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes2[1]
                      }"
                      class="planet-number-des earth-num-des"
                      x="38"
                      y="121"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_earth_d2"
                      v-if="chartBodygraphChart2.gatesDesignExt.earth"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesDesignExt.earth
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesDesignExt.earth.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="142.96954"
                      x="42.369999"
                      font-size="20.72"
                      class="col-bg-des"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                    >
                      {{
                        chartBodygraphChart2.gatesDesignExt.earth.gate || 0
                      }}.{{
                        chartBodygraphChart2.gatesDesignExt.earth.line || 0
                      }}
                    </text>
                    <text
                      v-if="chartBodygraphChart2.gatesDesignExt.earth.isExalted"
                      y="134"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesDesignExt.earth.isDetriment
                      "
                      y="147.5"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g6880_d2" transform="translate(23.809448,18.44752)">
                    <g
                      id="design_sign_sun_d2"
                      v-if="chartBodygraphChart2.planets.sun"
                      class="planet-des"
                      transform="translate(0.2471061)"
                    >
                      <path
                        id="path185_d2"
                        d="m 67,126.48 a 12.65,12.65 0 1 1 12.7,-12.6 12.72,12.72 0 0 1 -12.7,12.6 z m 0,-23 a 10.4,10.4 0 1 0 10.4,10.4 10.39,10.39 0 0 0 -10.4,-10.4 z"
                        transform="translate(-46.94,-28.51)"
                        inkscape:connector-curvature="0"
                      />
                      <circle
                        id="circle187_d2"
                        cx="20.059999"
                        cy="85.169998"
                        r="5.5999999"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart2.planets.sun)
                        "
                        class="planet-substrate-rect sun-des"
                        x="5"
                        y="70"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes2[0]
                      }"
                      class="planet-number-des sun-num-des"
                      x="38"
                      y="70"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_sun_d2"
                      v-if="chartBodygraphChart2.gatesDesignExt.sun"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesDesignExt.sun
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesDesignExt.sun.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="92.054047"
                      x="42.369999"
                      font-size="20.72"
                      class="col-bg-des"
                      style="cursor:pointer;font-size:20.71999931px;isolation:isolate"
                      inkscape:label=""
                    >
                      {{ chartBodygraphChart2.gatesDesignExt.sun.gate || 0 }}.{{
                        chartBodygraphChart2.gatesDesignExt.sun.line || 0
                      }}
                    </text>
                    <text
                      v-if="chartBodygraphChart2.gatesDesignExt.sun.isExalted"
                      y="84"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="chartBodygraphChart2.gatesDesignExt.sun.isDetriment"
                      y="97"
                      x="87"
                      font-size="16"
                    >
                      <tspan fill="#ff001d" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                </g>
              </g>
              <g id="g8416" transform="translate(10,2.9624634)">
                <g
                  id="g7404"
                  :class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                >
                  <g id="g7331">
                    <g
                      id="chart2_sign_pluto"
                      v-if="chartBodygraphChart2.planets.pluto"
                      class="planet-chart2"
                      transform="translate(-50,0)"
                    >
                      <path
                        inkscape:connector-curvature="0"
                        d="m 834.76945,729.70752 h -16.7 a 1,1 0 0 1 -1.1,-1.1 l 0.1,-27.7 a 1.14,1.14 0 0 1 0.3,-0.8 0.83,0.83 0 0 1 0.8,-0.3 l 11.3,0.1 a 9.9,9.9 0 0 1 0,19.8 l -10.2,0.1 v 7.7 h 15.5 a 1.22,1.22 0 0 1 1.2,1.2 1.13,1.13 0 0 1 -1.2,1 z m -15.4,-27.7 v 15.4 l 10.2,-0.1 a 7.6,7.6 0 1 0 0,-15.2 z"
                        data-name="chart_sign_pluto"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart2.planets.pluto)
                        "
                        class="planet-substrate-rect pluto-per"
                        x="815"
                        y="700"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes2[12]
                      }"
                      class="planet-number-chart2 pluto-num-per"
                      x="712"
                      y="701"
                      rx="7"
                      ry="7"
                    />
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer2[12]
                      }"
                      class="planet-number-chart2 pluto-num-per"
                      x="795"
                      y="701"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_chart2_pluto"
                      v-if="chartBodygraphChart2.gatesDesignExt.pluto"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesDesignExt.pluto
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesDesignExt.pluto.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="721.48749"
                      x="757"
                      class="col-bg-chart2"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraphChart2.gatesDesignExt.pluto.gate || 0
                      }}.{{
                        chartBodygraphChart2.gatesDesignExt.pluto.line || 0
                      }}
                    </text>
                    <text
                      v-if="chartBodygraphChart2.gatesDesignExt.pluto.isExalted"
                      y="713"
                      x="700"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9651;
                      </tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.pluto
                          .isDetriment
                      "
                      y="726.5"
                      x="700"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9661;
                      </tspan>
                    </text>
                    <text
                      id="person_number_chart2_pluto"
                      v-if="chartBodygraphChart2.gatesPersonalityExt.pluto"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesPersonalityExt.pluto
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesPersonalityExt.pluto.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="721.48749"
                      x="839"
                      class="col-bg-chart2"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraphChart2.gatesPersonalityExt.pluto.gate ||
                          0
                      }}.{{
                        chartBodygraphChart2.gatesPersonalityExt.pluto.line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.pluto.isExalted
                      "
                      y="713"
                      x="840"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9651;
                      </tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.pluto
                          .isDetriment
                      "
                      y="726.5"
                      x="840"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9661;
                      </tspan>
                    </text>
                  </g>
                  <g id="g7167">
                    <g
                      id="chart2_sign_neptune"
                      v-if="chartBodygraphChart2.planets.neptune"
                      class="planet-chart2"
                      transform="translate(-26,22.292976)"
                    >
                      <path
                        id="path233"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="M 851.3,672.46 A 13.51,13.51 0 0 1 837.8,659 a 1.2,1.2 0 1 1 2.4,0 11.2,11.2 0 1 0 22.4,0 1.2,1.2 0 1 1 2.4,0 13.88,13.88 0 0 1 -13.7,13.46 z"
                      />
                      <path
                        id="path235"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 851.3,685.56 a 1.22,1.22 0 0 1 -1.2,-1.2 v -25.9 a 1.2,1.2 0 1 1 2.4,0 v 25.9 a 1.38,1.38 0 0 1 -1.2,1.2 z"
                      />
                      <path
                        id="path237"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 859.1,679.36 h -15.5 a 1.2,1.2 0 0 1 0,-2.4 h 15.5 a 1.22,1.22 0 0 1 1.2,1.2 1.29,1.29 0 0 1 -1.2,1.2 z"
                      />
                      <polygon
                        id="polygon239"
                        points="795.56,631.94 792.06,626.04 788.66,631.94 "
                      />
                      <polygon
                        id="polygon241"
                        points="807.76,631.94 804.36,626.04 800.86,631.94 "
                      />
                      <polygon
                        id="polygon243"
                        points="820.16,631.94 816.66,626.04 813.26,631.94 "
                      />
                      <rect
                        @click="
                          openTooltipDialog(
                            chartBodygraphChart2.planets.neptune
                          )
                        "
                        class="planet-substrate-rect neptune-per"
                        x="790"
                        y="625"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes2[11]
                      }"
                      class="planet-number-chart2 neptune-num-per"
                      x="712"
                      y="650"
                      rx="7"
                      ry="7"
                    />
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer2[11]
                      }"
                      class="planet-number-chart2 neptune-num-per"
                      x="795"
                      y="650"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_chart2_neptune"
                      v-if="chartBodygraphChart2.gatesDesignExt.neptune"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesDesignExt.neptune
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesDesignExt.neptune.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="670.57196"
                      x="757"
                      class="col-bg-chart2"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraphChart2.gatesDesignExt.neptune.gate || 0
                      }}.{{
                        chartBodygraphChart2.gatesDesignExt.neptune.line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesDesignExt.neptune.isExalted
                      "
                      y="663"
                      x="700"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9651;
                      </tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesDesignExt.neptune.isDetriment
                      "
                      y="676.5"
                      x="700"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9661;
                      </tspan>
                    </text>
                    <text
                      id="person_number_chart2_neptune"
                      v-if="chartBodygraphChart2.gatesPersonalityExt.neptune"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesPersonalityExt.neptune
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesPersonalityExt.neptune.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="670.57196"
                      x="839"
                      class="col-bg-chart2"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraphChart2.gatesPersonalityExt.neptune.gate ||
                          0
                      }}.{{
                        chartBodygraphChart2.gatesPersonalityExt.neptune.line ||
                          0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.neptune
                          .isExalted
                      "
                      y="663"
                      x="840"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9651;
                      </tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.neptune
                          .isDetriment
                      "
                      y="676.5"
                      x="840"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9661;
                      </tspan>
                    </text>
                  </g>
                  <g id="g7157">
                    <g
                      id="chart2_sign_uranus"
                      v-if="chartBodygraphChart2.planets.uranus"
                      class="planet-chart2"
                      transform="translate(-26,21.642479)"
                    >
                      <path
                        id="path246"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 840.3,629.89 a 1.08,1.08 0 0 1 -0.7,-0.3 1,1 0 0 1 0,-1.4 15,15 0 0 0 4.1,-10.2 14.66,14.66 0 0 0 -4.3,-10.5 1,1 0 0 1 1.4,-1.4 16.78,16.78 0 0 1 0.2,23.5 2.12,2.12 0 0 1 -0.7,0.3 z"
                      />
                      <path
                        id="path248"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 862.3,630.39 a 0.91,0.91 0 0 1 -0.7,-0.3 16.77,16.77 0 0 1 -4.7,-11.6 17.05,17.05 0 0 1 4.9,-11.9 1,1 0 0 1 1.4,1.4 14.86,14.86 0 0 0 -0.2,20.7 1,1 0 0 1 0,1.4 1.08,1.08 0 0 1 -0.7,0.3 z"
                      />
                      <path
                        id="path250"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 851.3,624.39 a 0.94,0.94 0 0 1 -1,-1 v -16.2 a 1,1 0 0 1 2,0 v 16.2 a 1.08,1.08 0 0 1 -1,1 z"
                      />
                      <path
                        id="path252"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 851.3,633.69 a 5.5,5.5 0 1 1 5.5,-5.5 5.55,5.55 0 0 1 -5.5,5.5 z m 0,-8.9 a 3.5,3.5 0 1 0 3.5,3.5 3.48,3.48 0 0 0 -3.5,-3.5 z"
                      />
                      <path
                        id="path254"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 857.8,618.89 h -13.1 a 1,1 0 0 1 0,-2 h 13.1 a 1,1 0 0 1 0,2 z"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart2.planets.uranus)
                        "
                        class="planet-substrate-rect uranus-per"
                        x="790"
                        y="576"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes2[10]
                      }"
                      class="planet-number-chart2 uranus-num-per"
                      x="712"
                      y="600"
                      rx="7"
                      ry="7"
                    />
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer2[10]
                      }"
                      class="planet-number-chart2 uranus-num-per"
                      x="795"
                      y="600"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_chart2_uranus"
                      v-if="chartBodygraphChart2.gatesDesignExt.uranus"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesDesignExt.uranus
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesDesignExt.uranus.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="619.65643"
                      x="757"
                      class="col-bg-chart2"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraphChart2.gatesDesignExt.uranus.gate || 0
                      }}.{{
                        chartBodygraphChart2.gatesDesignExt.uranus.line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesDesignExt.uranus.isExalted
                      "
                      y="614"
                      x="700"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9651;
                      </tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesDesignExt.uranus.isDetriment
                      "
                      y="627.5"
                      x="700"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9661;
                      </tspan>
                    </text>
                    <text
                      id="person_number_chart2_uranus"
                      v-if="chartBodygraphChart2.gatesPersonalityExt.uranus"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesPersonalityExt.uranus
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesPersonalityExt.uranus.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="619.65643"
                      x="839"
                      class="col-bg-chart2"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraphChart2.gatesPersonalityExt.uranus.gate ||
                          0
                      }}.{{
                        chartBodygraphChart2.gatesPersonalityExt.uranus.line ||
                          0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.uranus
                          .isExalted
                      "
                      y="614"
                      x="840"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9651;
                      </tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.uranus
                          .isDetriment
                      "
                      y="627.5"
                      x="840"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9661;
                      </tspan>
                    </text>
                  </g>
                  <g id="g7148">
                    <g
                      id="chart2_sign_saturn"
                      v-if="chartBodygraphChart2.planets.saturn"
                      class="planet-chart2"
                      transform="translate(-26,22.837349)"
                    >
                      <path
                        id="path257"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 855.2,582.68 a 0.9,0.9 0 0 1 -0.5,-0.1 c -0.1,-0.1 -3.3,-1.7 -3.3,-4.7 0,-1.5 1.4,-2.9 3.2,-4.6 1.8,-1.7 3.9,-3.9 3.9,-5.6 a 5.7,5.7 0 1 0 -11.4,0 1.2,1.2 0 1 1 -2.4,0 8,8 0 0 1 16,0 c 0,2.7 -2.4,5.1 -4.6,7.3 -1,1 -2.5,2.5 -2.5,3 0,1.5 2,2.6 2.1,2.6 a 1.08,1.08 0 0 1 0.5,1.5 1.1,1.1 0 0 1 -1,0.6 z"
                      />
                      <path
                        id="path259"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 845.8,583.28 a 1.22,1.22 0 0 1 -1.2,-1.2 v -28.4 a 1.2,1.2 0 1 1 2.4,0 v 28.4 a 1.22,1.22 0 0 1 -1.2,1.2 z"
                      />
                      <path
                        id="path261"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="M 848.5,554.48 H 843 a 1.22,1.22 0 0 1 -1.2,-1.2 1.16,1.16 0 0 1 1.2,-1.2 h 5.5 a 1.2,1.2 0 1 1 0,2.4 z"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart2.planets.saturn)
                        "
                        class="planet-substrate-rect saturn-per"
                        x="790"
                        y="523"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes2[9]
                      }"
                      class="planet-number-chart2 saturn-num-per"
                      x="712"
                      y="549"
                      rx="7"
                      ry="7"
                    />
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer2[9]
                      }"
                      class="planet-number-chart2 saturn-num-per"
                      x="795"
                      y="549"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_chart2_saturn"
                      v-if="chartBodygraphChart2.gatesDesignExt.saturn"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesDesignExt.saturn
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesDesignExt.saturn.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="568.62701"
                      x="757"
                      class="col-bg-chart2"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraphChart2.gatesDesignExt.saturn.gate || 0
                      }}.{{
                        chartBodygraphChart2.gatesDesignExt.saturn.line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesDesignExt.saturn.isExalted
                      "
                      y="562"
                      x="700"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9651;
                      </tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesDesignExt.saturn.isDetriment
                      "
                      y="575.5"
                      x="700"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9661;
                      </tspan>
                    </text>
                    <text
                      id="person_number_chart2_saturn"
                      v-if="chartBodygraphChart2.gatesPersonalityExt.saturn"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesPersonalityExt.saturn
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesPersonalityExt.saturn.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="568.62701"
                      x="839"
                      class="col-bg-chart2"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraphChart2.gatesPersonalityExt.saturn.gate ||
                          0
                      }}.{{
                        chartBodygraphChart2.gatesPersonalityExt.saturn.line ||
                          0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.saturn
                          .isExalted
                      "
                      y="562"
                      x="840"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9651;
                      </tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.saturn
                          .isDetriment
                      "
                      y="575.5"
                      x="840"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9661;
                      </tspan>
                    </text>
                  </g>
                  <g id="g7141">
                    <g
                      id="chart2_sign_jupiter"
                      v-if="chartBodygraphChart2.planets.jupiter"
                      class="planet-chart2"
                      transform="translate(-26,22.491493)"
                    >
                      <path
                        id="path264"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 844.6,525.86 a 1.17,1.17 0 0 1 -1.1,-0.8 1.09,1.09 0 0 1 0.4,-1.3 c 8.5,-5.9 8.9,-12 8.9,-12.1 a 5.8002155,5.8002155 0 0 0 -11.6,0.1 1.2,1.2 0 1 1 -2.4,0 8.1,8.1 0 1 1 16.2,0 c 0,0.3 -0.4,5.9 -7.2,11.8 l 14.4,-0.2 a 1.13,1.13 0 0 1 1.2,1.1 1.2,1.2 0 0 1 -1.1,1.2 z"
                      />
                      <path
                        id="path266"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 858.3,531.86 a 1.22,1.22 0 0 1 -1.2,-1.2 v -27.1 a 1.2,1.2 0 1 1 2.4,0 v 27.1 a 1.22,1.22 0 0 1 -1.2,1.2 z"
                      />
                      <rect
                        @click="
                          openTooltipDialog(
                            chartBodygraphChart2.planets.jupiter
                          )
                        "
                        class="planet-substrate-rect jupiter-per"
                        x="790"
                        y="472"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes2[8]
                      }"
                      class="planet-number-chart2 jupiter-num-per"
                      x="712"
                      y="496"
                      rx="7"
                      ry="7"
                    />
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer2[8]
                      }"
                      class="planet-number-chart2 jupiter-num-per"
                      x="795"
                      y="496"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_chart2_jupiter"
                      v-if="chartBodygraphChart2.gatesDesignExt.jupiter"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesDesignExt.jupiter
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesDesignExt.jupiter.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="517.81512"
                      x="757"
                      class="col-bg-chart2"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraphChart2.gatesDesignExt.jupiter.gate || 0
                      }}.{{
                        chartBodygraphChart2.gatesDesignExt.jupiter.line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesDesignExt.jupiter.isExalted
                      "
                      y="511"
                      x="700"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9651;
                      </tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesDesignExt.jupiter.isDetriment
                      "
                      y="524.5"
                      x="700"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9661;
                      </tspan>
                    </text>
                    <text
                      id="person_number_chart2_jupiter"
                      v-if="chartBodygraphChart2.gatesPersonalityExt.jupiter"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesPersonalityExt.jupiter
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesPersonalityExt.jupiter.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="517.81512"
                      x="839"
                      class="col-bg-chart2"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraphChart2.gatesPersonalityExt.jupiter.gate ||
                          0
                      }}.{{
                        chartBodygraphChart2.gatesPersonalityExt.jupiter.line ||
                          0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.jupiter
                          .isExalted
                      "
                      y="511"
                      x="840"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9651;
                      </tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.jupiter
                          .isDetriment
                      "
                      y="524.5"
                      x="840"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9661;
                      </tspan>
                    </text>
                  </g>
                  <g id="g7135">
                    <g
                      id="chart2_sign_mars"
                      v-if="chartBodygraphChart2.planets.mars"
                      class="planet-chart2"
                      transform="translate(-26,22.618215)"
                    >
                      <path
                        id="path269"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="M 850,480 A 12.2,12.2 0 1 1 862.2,467.8 12.25,12.25 0 0 1 850,480 Z m 0,-22.2 a 9.9,9.9 0 1 0 9.9,9.9 9.92,9.92 0 0 0 -9.9,-9.95 z"
                      />
                      <path
                        id="path271"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 857.2,461 a 0.91,0.91 0 0 1 -0.8,-0.4 1.21,1.21 0 0 1 0,-1.6 l 6.3,-6 a 1.1313708,1.1313708 0 1 1 1.6,1.6 l -6.3,6 a 1.23,1.23 0 0 1 -0.8,0.4 z"
                      />
                      <path
                        id="path273"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 862.9,459.85 h -0.2 a 1.2,1.2 0 0 1 -1,-1.3 l 0.6,-3.8 -3.8,0.6 a 1.16,1.16 0 1 1 -0.3,-2.3 l 5.4,-0.9 a 1.21,1.21 0 0 1 1,0.3 1,1 0 0 1 0.3,1 l -0.9,5.4 a 1.16,1.16 0 0 1 -1.1,1 z"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart2.planets.mars)
                        "
                        class="planet-substrate-rect mars-per"
                        x="790"
                        y="423"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes2[7]
                      }"
                      class="planet-number-chart2 mars-num-per"
                      x="712"
                      y="446"
                      rx="7"
                      ry="7"
                    />
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer2[7]
                      }"
                      class="planet-number-chart2 mars-num-per"
                      x="795"
                      y="446"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_chart2_mars"
                      v-if="chartBodygraphChart2.gatesDesignExt.mars"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesDesignExt.mars
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesDesignExt.mars.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="466.89963"
                      x="757"
                      class="col-bg-chart2"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraphChart2.gatesDesignExt.mars.gate || 0
                      }}.{{
                        chartBodygraphChart2.gatesDesignExt.mars.line || 0
                      }}
                    </text>
                    <text
                      v-if="chartBodygraphChart2.gatesDesignExt.mars.isExalted"
                      y="459"
                      x="700"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9651;
                      </tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesDesignExt.mars.isDetriment
                      "
                      y="472.5"
                      x="700"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9661;
                      </tspan>
                    </text>
                    <text
                      id="person_number_chart2_mars"
                      v-if="chartBodygraphChart2.gatesPersonalityExt.mars"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesPersonalityExt.mars
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesPersonalityExt.mars.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="466.89963"
                      x="839"
                      class="col-bg-chart2"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraphChart2.gatesPersonalityExt.mars.gate || 0
                      }}.{{
                        chartBodygraphChart2.gatesPersonalityExt.mars.line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.mars.isExalted
                      "
                      y="459"
                      x="840"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9651;
                      </tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.mars
                          .isDetriment
                      "
                      y="472.5"
                      x="840"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9661;
                      </tspan>
                    </text>
                  </g>
                  <g id="g7128">
                    <g
                      id="chart2_sign_venus"
                      v-if="chartBodygraphChart2.planets.venus"
                      class="planet-chart2"
                      transform="translate(-26,22.910158)"
                    >
                      <path
                        id="path276"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 851.3,420.86 a 10.4,10.4 0 1 1 10.4,-10.4 10.39,10.39 0 0 1 -10.4,10.4 z m 0,-18.6 a 8.1,8.1 0 1 0 8.1,8.1 8.07,8.07 0 0 0 -8.1,-8.1 z"
                      />
                      <path
                        id="path278"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 851.3,429.66 a 1.22,1.22 0 0 1 -1.2,-1.2 v -8.1 a 1.2,1.2 0 1 1 2.4,0 v 8.1 a 1.16,1.16 0 0 1 -1.2,1.2 z"
                      />
                      <path
                        id="path280"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 856.6,425.56 h -10.5 a 1.22,1.22 0 0 1 -1.2,-1.2 1.16,1.16 0 0 1 1.2,-1.2 h 10.5 a 1.2,1.2 0 0 1 0,2.4 z"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart2.planets.venus)
                        "
                        class="planet-substrate-rect venus-per"
                        x="790"
                        y="370"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes2[6]
                      }"
                      class="planet-number-chart2 venus-num-per"
                      x="712"
                      y="396"
                      rx="7"
                      ry="7"
                    />
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer2[6]
                      }"
                      class="planet-number-chart2 venus-num-per"
                      x="795"
                      y="396"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_chart2_venus"
                      v-if="chartBodygraphChart2.gatesDesignExt.venus"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesDesignExt.venus
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesDesignExt.venus.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="415.98413"
                      x="757"
                      class="col-bg-chart2"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraphChart2.gatesDesignExt.venus.gate || 0
                      }}.{{
                        chartBodygraphChart2.gatesDesignExt.venus.line || 0
                      }}
                    </text>
                    <text
                      v-if="chartBodygraphChart2.gatesDesignExt.venus.isExalted"
                      y="409"
                      x="700"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9651;
                      </tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesDesignExt.venus.isDetriment
                      "
                      y="422.5"
                      x="700"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9661;
                      </tspan>
                    </text>
                    <text
                      id="person_number_chart2_venus"
                      v-if="chartBodygraphChart2.gatesPersonalityExt.venus"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesPersonalityExt.venus
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesPersonalityExt.venus.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="415.98413"
                      x="839"
                      class="col-bg-chart2"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraphChart2.gatesPersonalityExt.venus.gate ||
                          0
                      }}.{{
                        chartBodygraphChart2.gatesPersonalityExt.venus.line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.venus.isExalted
                      "
                      y="409"
                      x="840"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9651;
                      </tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.venus
                          .isDetriment
                      "
                      y="422.5"
                      x="840"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9661;
                      </tspan>
                    </text>
                  </g>
                  <g id="g7121">
                    <g
                      id="chart2_sign_mercury"
                      v-if="chartBodygraphChart2.planets.mercury"
                      class="planet-chart2"
                      transform="translate(-26,22.999209)"
                    >
                      <path
                        id="path283"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 851.4,370.7 a 10,10 0 1 1 10,-10 10,10 0 0 1 -10,10 z m 0,-17.7 a 7.7,7.7 0 1 0 7.7,7.7 7.7,7.7 0 0 0 -7.7,-7.7 z"
                      />
                      <path
                        id="path285"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 851.4,379 a 1.22,1.22 0 0 1 -1.2,-1.2 v -7.7 a 1.2,1.2 0 1 1 2.4,0 v 7.7 a 1.16,1.16 0 0 1 -1.2,1.2 z"
                      />
                      <path
                        id="path287"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 856.4,375.2 h -10 a 1.22,1.22 0 0 1 -1.2,-1.2 1.16,1.16 0 0 1 1.2,-1.2 h 10 a 1.2,1.2 0 0 1 0,2.4 z"
                      />
                      <path
                        id="path289"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 844.9,355.6 a 1.1,1.1 0 0 1 -1,-0.6 l -2.5,-4.4 a 1.17,1.17 0 0 1 2,-1.2 l 2.5,4.4 a 1.23,1.23 0 0 1 -0.4,1.6 0.73,0.73 0 0 1 -0.6,0.2 z"
                      />
                      <path
                        id="path291"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 857.5,354.7 a 1.42,1.42 0 0 1 -0.6,-0.2 1.23,1.23 0 0 1 -0.4,-1.6 l 2.3,-3.7 a 1.17,1.17 0 0 1 2,1.2 l -2.3,3.7 a 1.22,1.22 0 0 1 -1,0.6 z"
                      />
                      <rect
                        @click="
                          openTooltipDialog(
                            chartBodygraphChart2.planets.mercury
                          )
                        "
                        class="planet-substrate-rect mercury-per"
                        x="790"
                        y="319"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes2[5]
                      }"
                      class="planet-number-chart2 mercury-num-per"
                      x="712"
                      y="343"
                      rx="7"
                      ry="7"
                    />
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer2[5]
                      }"
                      class="planet-number-chart2 mercury-num-per"
                      x="795"
                      y="343"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_chart2_mercury"
                      v-if="chartBodygraphChart2.gatesDesignExt.mercury"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesDesignExt.mercury
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesDesignExt.mercury.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="365.07901"
                      x="757"
                      class="col-bg-chart2"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraphChart2.gatesDesignExt.mercury.gate || 0
                      }}.{{
                        chartBodygraphChart2.gatesDesignExt.mercury.line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesDesignExt.mercury.isExalted
                      "
                      y="357"
                      x="700"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9651;
                      </tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesDesignExt.mercury.isDetriment
                      "
                      y="370.5"
                      x="700"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9661;
                      </tspan>
                    </text>
                    <text
                      id="person_number_chart2_mercury"
                      v-if="chartBodygraphChart2.gatesPersonalityExt.mercury"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesPersonalityExt.mercury
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesPersonalityExt.mercury.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="365.07901"
                      x="839"
                      class="col-bg-chart2"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraphChart2.gatesPersonalityExt.mercury.gate ||
                          0
                      }}.{{
                        chartBodygraphChart2.gatesPersonalityExt.mercury.line ||
                          0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.mercury
                          .isExalted
                      "
                      y="357"
                      x="840"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9651;
                      </tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.mercury
                          .isDetriment
                      "
                      y="370.5"
                      x="840"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9661;
                      </tspan>
                    </text>
                  </g>
                  <g id="g7112">
                    <g
                      id="chart2_sign_south_node"
                      v-if="chartBodygraphChart2.planets.south_node"
                      class="planet-chart2"
                      transform="translate(-50,0)"
                    >
                      <path
                        style="cursor:pointer"
                        inkscape:connector-curvature="0"
                        d="m 828.26665,320.67685 a 10.75,10.75 0 0 1 -7.9,-3.5 8.39,8.39 0 0 1 -2.3,-6.8 c 0.3,-2.8 4,-5.3 6.6,-7.1 l 0.8,-0.6 a 3.26,3.26 0 0 0 1.4,-2.6 3.1,3.1 0 0 0 -1,-2.4 5.23,5.23 0 0 0 -3.4,-1.2 5.41,5.41 0 0 0 -4.6,2.6 1.17,1.17 0 0 1 -2,-1.2 7.55,7.55 0 0 1 6.5,-3.7 7.88,7.88 0 0 1 4.9,1.7 9,9 0 0 1 0.9,1 9,9 0 0 1 0.9,-1 7.88,7.88 0 0 1 4.9,-1.7 7.55,7.55 0 0 1 6.5,3.7 1.17,1.17 0 0 1 -2,1.2 5.39,5.39 0 0 0 -8,-1.4 2.69,2.69 0 0 0 -1,2.4 3.42,3.42 0 0 0 1.4,2.6 l 0.8,0.6 c 2.7,1.8 6.3,4.3 6.6,7.1 a 8.59,8.59 0 0 1 -2.3,7 9.88,9.88 0 0 1 -7.7,3.3 z m 0,-17.6 a 6.73,6.73 0 0 1 -1.4,1.5 l -0.8,0.6 c -2,1.4 -5.5,3.7 -5.6,5.5 a 6.63,6.63 0 0 0 1.7,5.1 8.47,8.47 0 0 0 6.2,2.7 8.07,8.07 0 0 0 6.2,-2.6 6.61,6.61 0 0 0 1.7,-5.2 c -0.2,-1.7 -3.6,-4.1 -5.6,-5.5 l -0.8,-0.6 a 4.63,4.63 0 0 1 -1.6,-1.45 z"
                      />
                      <rect
                        @click="
                          openTooltipDialog(
                            chartBodygraphChart2.planets.south_node
                          )
                        "
                        class="planet-substrate-rect south-node-per"
                        x="815"
                        y="293"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes2[3]
                      }"
                      class="planet-number-chart2 south-node-num-per"
                      x="712"
                      y="293"
                      rx="7"
                      ry="7"
                    />
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer2[3]
                      }"
                      class="planet-number-chart2 south-node-num-per"
                      x="795"
                      y="293"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_chart2_south_node"
                      v-if="chartBodygraphChart2.gatesDesignExt.south_node"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesDesignExt.south_node
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesDesignExt.south_node.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="314.15314"
                      x="757"
                      class="col-bg-chart2"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraphChart2.gatesDesignExt.south_node.gate ||
                          0
                      }}.{{
                        chartBodygraphChart2.gatesDesignExt.south_node.line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesDesignExt.south_node.isExalted
                      "
                      y="307"
                      x="700"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9651;
                      </tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesDesignExt.south_node
                          .isDetriment
                      "
                      y="320.5"
                      x="700"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9661;
                      </tspan>
                    </text>
                    <text
                      id="person_number_chart2_south_node"
                      v-if="chartBodygraphChart2.gatesPersonalityExt.south_node"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesPersonalityExt.south_node
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesPersonalityExt.south_node
                            .gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="314.15314"
                      x="839"
                      class="col-bg-chart2"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraphChart2.gatesPersonalityExt.south_node
                          .gate || 0
                      }}.{{
                        chartBodygraphChart2.gatesPersonalityExt.south_node
                          .line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.south_node
                          .isExalted
                      "
                      y="307"
                      x="840"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9651;
                      </tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.south_node
                          .isDetriment
                      "
                      y="320.5"
                      x="840"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9661;
                      </tspan>
                    </text>
                  </g>
                  <g id="g7108">
                    <g
                      id="chart2_sign_north_node"
                      v-if="chartBodygraphChart2.planets.north_node"
                      class="planet-chart2"
                      transform="translate(-50,0)"
                    >
                      <path
                        style="cursor:pointer"
                        inkscape:connector-curvature="0"
                        d="m 833.86665,269.76502 a 7.88,7.88 0 0 1 -4.9,-1.7 9,9 0 0 1 -0.9,-1 9,9 0 0 1 -0.9,1 7.88,7.88 0 0 1 -4.9,1.7 7.55,7.55 0 0 1 -6.5,-3.7 1.17,1.17 0 0 1 2,-1.2 5.39,5.39 0 0 0 8,1.4 2.69,2.69 0 0 0 1,-2.4 3.42,3.42 0 0 0 -1.4,-2.6 l -0.8,-0.6 c -2.7,-1.8 -6.3,-4.3 -6.6,-7.1 a 8.62,8.62 0 0 1 2.3,-6.8 10.75,10.75 0 0 1 7.9,-3.5 10.62,10.62 0 0 1 7.9,3.3 9.32,9.32 0 0 1 2.3,7 c -0.3,2.8 -4,5.3 -6.6,7.1 l -0.8,0.6 a 3.26,3.26 0 0 0 -1.4,2.6 3.1,3.1 0 0 0 1,2.4 5.23,5.23 0 0 0 3.4,1.2 5.41,5.41 0 0 0 4.6,-2.6 1.17,1.17 0 0 1 2,1.2 7.91,7.91 0 0 1 -6.7,3.7 z m -5.7,-24.1 a 8.64,8.64 0 0 0 -6.2,2.7 6.51,6.51 0 0 0 -1.7,5.1 c 0.2,1.7 3.6,4.1 5.6,5.5 l 0.8,0.6 a 4,4 0 0 1 1.4,1.5 6.73,6.73 0 0 1 1.4,-1.5 l 0.8,-0.6 c 2,-1.4 5.5,-3.7 5.6,-5.5 a 6.61,6.61 0 0 0 -1.7,-5.2 7.36,7.36 0 0 0 -6,-2.6 z"
                      />
                      <rect
                        @click="
                          openTooltipDialog(
                            chartBodygraphChart2.planets.north_node
                          )
                        "
                        class="planet-substrate-rect north-node-per"
                        x="815"
                        y="242"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes2[2]
                      }"
                      class="planet-number-chart2 north-node-num-per"
                      x="712"
                      y="242"
                      rx="7"
                      ry="7"
                    />
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer2[2]
                      }"
                      class="planet-number-chart2 north-node-num-per"
                      x="795"
                      y="242"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_chart2_north_node"
                      v-if="chartBodygraphChart2.gatesDesignExt.north_node"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesDesignExt.north_node
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesDesignExt.north_node.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="263.24805"
                      x="757"
                      class="col-bg-chart2"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraphChart2.gatesDesignExt.north_node.gate ||
                          0
                      }}.{{
                        chartBodygraphChart2.gatesDesignExt.north_node.line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesDesignExt.north_node.isExalted
                      "
                      y="257"
                      x="700"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9651;
                      </tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesDesignExt.north_node
                          .isDetriment
                      "
                      y="270.5"
                      x="700"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9661;
                      </tspan>
                    </text>
                    <text
                      id="person_number_chart2_north_node"
                      v-if="chartBodygraphChart2.gatesPersonalityExt.north_node"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesPersonalityExt.north_node
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesPersonalityExt.north_node
                            .gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="263.24805"
                      x="839"
                      class="col-bg-chart2"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraphChart2.gatesPersonalityExt.north_node
                          .gate || 0
                      }}.{{
                        chartBodygraphChart2.gatesPersonalityExt.north_node
                          .line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.north_node
                          .isExalted
                      "
                      y="257"
                      x="840"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9651;
                      </tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.north_node
                          .isDetriment
                      "
                      y="270.5"
                      x="840"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9661;
                      </tspan>
                    </text>
                  </g>
                  <g id="g7104">
                    <g
                      id="chart2_sign_moon"
                      v-if="chartBodygraphChart2.planets.moon"
                      class="planet-chart2"
                      transform="translate(-50,0)"
                    >
                      <path
                        style="cursor:pointer"
                        inkscape:connector-curvature="0"
                        d="m 830.19722,220.49849 a 14.9,14.9 0 1 1 8.7,-27 1.09,1.09 0 0 1 0.4,1.3 1.21,1.21 0 0 1 -1.2,0.8 h -0.6 a 9.42,9.42 0 1 0 2.1,18.6 1.2,1.2 0 0 1 1.3,0.6 1.23,1.23 0 0 1 -0.2,1.4 15.08,15.08 0 0 1 -10.5,4.3 z m 0,-27.5 a 12.6,12.6 0 0 0 0,25.2 12.42,12.42 0 0 0 6,-1.5 11.75,11.75 0 0 1 -1.8,-23 12.34,12.34 0 0 0 -4.2,-0.7 z"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart2.planets.moon)
                        "
                        class="planet-substrate-rect moon-per"
                        x="815"
                        y="192"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes2[4]
                      }"
                      class="planet-number-chart2 moon-num-per"
                      x="712"
                      y="192"
                      rx="7"
                      ry="7"
                    />
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer2[4]
                      }"
                      class="planet-number-chart2 moon-num-per"
                      x="795"
                      y="192"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_chart2_moon"
                      v-if="chartBodygraphChart2.gatesDesignExt.moon"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesDesignExt.moon
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesDesignExt.moon.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="212.3325"
                      x="757"
                      class="col-bg-chart2"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraphChart2.gatesDesignExt.moon.gate || 0
                      }}.{{
                        chartBodygraphChart2.gatesDesignExt.moon.line || 0
                      }}
                    </text>
                    <text
                      v-if="chartBodygraphChart2.gatesDesignExt.moon.isExalted"
                      y="207"
                      x="700"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9651;
                      </tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesDesignExt.moon.isDetriment
                      "
                      y="220.5"
                      x="700"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9661;
                      </tspan>
                    </text>
                    <text
                      id="person_number_chart2_moon"
                      v-if="chartBodygraphChart2.gatesPersonalityExt.moon"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesPersonalityExt.moon
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesPersonalityExt.moon.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="212.3325"
                      x="839"
                      class="col-bg-chart2"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraphChart2.gatesPersonalityExt.moon.gate || 0
                      }}.{{
                        chartBodygraphChart2.gatesPersonalityExt.moon.line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.moon.isExalted
                      "
                      y="207"
                      x="840"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9651;
                      </tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.moon
                          .isDetriment
                      "
                      y="220.5"
                      x="840"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9661;
                      </tspan>
                    </text>
                  </g>
                  <g id="g7100">
                    <g
                      id="chart2_sign_earth"
                      v-if="chartBodygraphChart2.planets.earth"
                      transform="translate(-26,23.383064)"
                      class="planet-chart2"
                    >
                      <path
                        id="path297"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 851.3,172.51 a 12.7,12.7 0 1 1 12.7,-12.7 12.72,12.72 0 0 1 -12.7,12.7 z m 0,-23.1 a 10.4,10.4 0 1 0 10.4,10.4 10.39,10.39 0 0 0 -10.4,-10.4 z"
                      />
                      <path
                        id="path299"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 851.3,172.31 a 1.22,1.22 0 0 1 -1.2,-1.2 v -22.8 a 1.2,1.2 0 0 1 2.4,0 v 22.8 a 1.22,1.22 0 0 1 -1.2,1.2 z"
                      />
                      <path
                        id="path301"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 862.7,160.81 h -22.8 a 1.22,1.22 0 0 1 -1.2,-1.2 1.16,1.16 0 0 1 1.2,-1.2 h 22.8 a 1.22,1.22 0 0 1 1.2,1.2 1.16,1.16 0 0 1 -1.2,1.2 z"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart2.planets.earth)
                        "
                        class="planet-substrate-rect earth-per"
                        x="790"
                        y="116"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes2[1]
                      }"
                      class="planet-number-chart2 earth-num-per"
                      x="712"
                      y="141"
                      rx="7"
                      ry="7"
                    />
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer2[1]
                      }"
                      class="planet-number-chart2 earth-num-per"
                      x="795"
                      y="141"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_chart2_earth"
                      v-if="chartBodygraphChart2.gatesDesignExt.earth"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesDesignExt.earth
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesDesignExt.earth.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="161.41707"
                      x="757"
                      class="col-bg-chart2"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraphChart2.gatesDesignExt.earth.gate || 0
                      }}.{{
                        chartBodygraphChart2.gatesDesignExt.earth.line || 0
                      }}
                    </text>
                    <text
                      v-if="chartBodygraphChart2.gatesDesignExt.earth.isExalted"
                      y="155"
                      x="700"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9651;
                      </tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesDesignExt.earth.isDetriment
                      "
                      y="168.5"
                      x="700"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9661;
                      </tspan>
                    </text>
                    <text
                      id="person_number_chart2_earth"
                      v-if="chartBodygraphChart2.gatesPersonalityExt.earth"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesPersonalityExt.earth
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesPersonalityExt.earth.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="161.41707"
                      x="839"
                      class="col-bg-chart2"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraphChart2.gatesPersonalityExt.earth.gate ||
                          0
                      }}.{{
                        chartBodygraphChart2.gatesPersonalityExt.earth.line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.earth.isExalted
                      "
                      y="155"
                      x="840"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9651;
                      </tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.earth
                          .isDetriment
                      "
                      y="168.5"
                      x="8400"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9661;
                      </tspan>
                    </text>
                  </g>
                  <g id="g7093">
                    <g
                      id="chart2_sign_sun"
                      v-if="chartBodygraphChart2.planets.sun"
                      class="planet-chart2"
                      transform="translate(-26,23.28752)"
                    >
                      <path
                        id="path304"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="M 851,121.64 A 12.65,12.65 0 1 1 863.7,109 12.72,12.72 0 0 1 851,121.64 Z m 0,-23 A 10.4,10.4 0 1 0 861.4,109 10.39,10.39 0 0 0 851,98.64 Z"
                      />
                      <circle
                        id="circle306"
                        r="5.5999999"
                        cy="80.330002"
                        cx="804.06"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart2.planets.sun)
                        "
                        class="planet-substrate-rect sun-per"
                        x="790"
                        y="66"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetDes2[0]
                      }"
                      class="planet-number-chart2 sun-num-per"
                      x="712"
                      y="90"
                      rx="7"
                      ry="7"
                    />
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer2[0]
                      }"
                      class="planet-number-chart2 sun-num-per"
                      x="795"
                      y="90"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="design_number_chart2_sun"
                      v-if="chartBodygraphChart2.gatesDesignExt.sun"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesDesignExt.sun
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesDesignExt.sun.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="110.50156"
                      x="757"
                      class="col-bg-chart2"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{ chartBodygraphChart2.gatesDesignExt.sun.gate || 0 }}.{{
                        chartBodygraphChart2.gatesDesignExt.sun.line || 0
                      }}
                    </text>
                    <text
                      v-if="chartBodygraphChart2.gatesDesignExt.sun.isExalted"
                      y="104"
                      x="700"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9651;
                      </tspan>
                    </text>
                    <text
                      v-if="chartBodygraphChart2.gatesDesignExt.sun.isDetriment"
                      y="117"
                      x="700"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9661;
                      </tspan>
                    </text>
                    <text
                      id="person_number_chart2_sun"
                      v-if="chartBodygraphChart2.gatesPersonalityExt.sun"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesPersonalityExt.sun
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesPersonalityExt.sun.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="110.50156"
                      x="839"
                      class="col-bg-chart2"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraphChart2.gatesPersonalityExt.sun.gate || 0
                      }}.{{
                        chartBodygraphChart2.gatesPersonalityExt.sun.line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.sun.isExalted
                      "
                      y="104"
                      x="840"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9651;
                      </tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.sun.isDetriment
                      "
                      y="117"
                      x="840"
                      font-size="16"
                    >
                      <tspan class="line-arrow-chart2" font-size="16">
                        &#9661;
                      </tspan>
                    </text>
                  </g>
                </g>
                <g id="g7420" :class="{ opacity4: !isChartToggle }">
                  <text
                    id="text351"
                    class="col-bg-chart2 uppercase"
                    style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:start;writing-mode:lr-tb;text-anchor:end;isolation:isolate;"
                    font-size="21.34"
                    x="837"
                    y="41.323662"
                  >
                    {{ chartBodygraphChartName2 }}
                  </text>
                  <path
                    :class="{ not_active_toggle: isChart2Toggle }"
                    class="chart2_toggle"
                    fill="none"
                    stroke-miterlimit="10"
                    d="M 75.967 15.864 c 0 8.461 -6.858 15.319 -15.319 15.319 H 15.819 c -8.46 0 -15.319 -6.858 -15.319 -15.319 l 0 0 C 0.5 7.358 7.358 0.5 15.819 0.5 h 44.975 C 69.129 0.511 75.973 7.336 75.967 15.844 L 75.973 15.86 z"
                    transform="translate(765,45)"
                  />
                  <circle
                    :class="{ not_active_chart2: isChart2Toggle }"
                    class="chart2_toggle_cir"
                    cx="825"
                    cy="61"
                    r="12.836"
                  />
                  <rect
                    @click="showChart2()"
                    class="chart2-rect"
                    x="765"
                    y="44"
                  />
                  <circle class="per_toggle_quest" cx="750.5" cy="53" r="8" />
                  <text
                    class="per_quest_quest"
                    style="font-size:14px;isolation:isolate"
                    font-size="14"
                    x="747"
                    y="58"
                  >
                    ?
                  </text>
                  <rect
                    @click="
                      openTooltipDialog(
                        convertToTooltipDialogObj(
                          Messages['title_help_composite_chart2'],
                          Messages['help_composite_chart2']
                        )
                      )
                    "
                    class="design-rect_quest"
                    x="740"
                    y="43"
                  />
                </g>
              </g>
              <g id="g8416_p" transform="translate(10,2.9624634)">
                <g id="g7404_p" :class="{ opacity4: isChartToggle }">
                  <g id="g7331">
                    <g
                      id="person_sign_pluto_p"
                      v-if="chartBodygraphChart.planets.pluto"
                      class="planet-per"
                    >
                      <path
                        inkscape:connector-curvature="0"
                        d="m 834.76945,729.70752 h -16.7 a 1,1 0 0 1 -1.1,-1.1 l 0.1,-27.7 a 1.14,1.14 0 0 1 0.3,-0.8 0.83,0.83 0 0 1 0.8,-0.3 l 11.3,0.1 a 9.9,9.9 0 0 1 0,19.8 l -10.2,0.1 v 7.7 h 15.5 a 1.22,1.22 0 0 1 1.2,1.2 1.13,1.13 0 0 1 -1.2,1 z m -15.4,-27.7 v 15.4 l 10.2,-0.1 a 7.6,7.6 0 1 0 0,-15.2 z"
                        data-name="design_sign_pluto"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart.planets.pluto)
                        "
                        class="planet-substrate-rect pluto-per"
                        x="815"
                        y="700"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer[12]
                      }"
                      class="planet-number-per pluto-num-per"
                      x="762"
                      y="701"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_pluto_p"
                      v-if="chartBodygraphChart.gatesPersonalityExt.pluto"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesPersonalityExt.pluto
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesPersonalityExt.pluto.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="721.48749"
                      x="804.28656"
                      class="col-bg-person"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraphChart.gatesPersonalityExt.pluto.gate || 0
                      }}.{{
                        chartBodygraphChart.gatesPersonalityExt.pluto.line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.pluto.isExalted
                      "
                      y="713"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.pluto
                          .isDetriment
                      "
                      y="726.5"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g7167_p">
                    <g
                      id="person_sign_neptune_p"
                      v-if="chartBodygraphChart.planets.neptune"
                      class="planet-per"
                      transform="translate(23.756655,22.292976)"
                    >
                      <path
                        id="path233_p"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="M 851.3,672.46 A 13.51,13.51 0 0 1 837.8,659 a 1.2,1.2 0 1 1 2.4,0 11.2,11.2 0 1 0 22.4,0 1.2,1.2 0 1 1 2.4,0 13.88,13.88 0 0 1 -13.7,13.46 z"
                      />
                      <path
                        id="path235_p"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 851.3,685.56 a 1.22,1.22 0 0 1 -1.2,-1.2 v -25.9 a 1.2,1.2 0 1 1 2.4,0 v 25.9 a 1.38,1.38 0 0 1 -1.2,1.2 z"
                      />
                      <path
                        id="path237_p"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 859.1,679.36 h -15.5 a 1.2,1.2 0 0 1 0,-2.4 h 15.5 a 1.22,1.22 0 0 1 1.2,1.2 1.29,1.29 0 0 1 -1.2,1.2 z"
                      />
                      <polygon
                        id="polygon239_p"
                        points="795.56,631.94 792.06,626.04 788.66,631.94 "
                      />
                      <polygon
                        id="polygon241_p"
                        points="807.76,631.94 804.36,626.04 800.86,631.94 "
                      />
                      <polygon
                        id="polygon243_p"
                        points="820.16,631.94 816.66,626.04 813.26,631.94 "
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart.planets.neptune)
                        "
                        class="planet-substrate-rect neptune-per"
                        x="790"
                        y="625"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer[11]
                      }"
                      class="planet-number-per neptune-num-per"
                      x="762"
                      y="650"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_neptune_p"
                      v-if="chartBodygraphChart.gatesPersonalityExt.neptune"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesPersonalityExt.neptune
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesPersonalityExt.neptune.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="670.57196"
                      x="804.28656"
                      class="col-bg-person"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraphChart.gatesPersonalityExt.neptune.gate ||
                          0
                      }}.{{
                        chartBodygraphChart.gatesPersonalityExt.neptune.line ||
                          0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.neptune
                          .isExalted
                      "
                      y="663"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.neptune
                          .isDetriment
                      "
                      y="676.5"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g7157_p">
                    <g
                      id="person_sign_uranus_p"
                      v-if="chartBodygraphChart.planets.uranus"
                      class="planet-per"
                      transform="translate(23.806655,21.642479)"
                    >
                      <path
                        id="path246_p"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 840.3,629.89 a 1.08,1.08 0 0 1 -0.7,-0.3 1,1 0 0 1 0,-1.4 15,15 0 0 0 4.1,-10.2 14.66,14.66 0 0 0 -4.3,-10.5 1,1 0 0 1 1.4,-1.4 16.78,16.78 0 0 1 0.2,23.5 2.12,2.12 0 0 1 -0.7,0.3 z"
                      />
                      <path
                        id="path248_p"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 862.3,630.39 a 0.91,0.91 0 0 1 -0.7,-0.3 16.77,16.77 0 0 1 -4.7,-11.6 17.05,17.05 0 0 1 4.9,-11.9 1,1 0 0 1 1.4,1.4 14.86,14.86 0 0 0 -0.2,20.7 1,1 0 0 1 0,1.4 1.08,1.08 0 0 1 -0.7,0.3 z"
                      />
                      <path
                        id="path250_p"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 851.3,624.39 a 0.94,0.94 0 0 1 -1,-1 v -16.2 a 1,1 0 0 1 2,0 v 16.2 a 1.08,1.08 0 0 1 -1,1 z"
                      />
                      <path
                        id="path252_p"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 851.3,633.69 a 5.5,5.5 0 1 1 5.5,-5.5 5.55,5.55 0 0 1 -5.5,5.5 z m 0,-8.9 a 3.5,3.5 0 1 0 3.5,3.5 3.48,3.48 0 0 0 -3.5,-3.5 z"
                      />
                      <path
                        id="path254_p"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 857.8,618.89 h -13.1 a 1,1 0 0 1 0,-2 h 13.1 a 1,1 0 0 1 0,2 z"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart.planets.uranus)
                        "
                        class="planet-substrate-rect uranus-per"
                        x="790"
                        y="576"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer[10]
                      }"
                      class="planet-number-per uranus-num-per"
                      x="762"
                      y="600"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_uranus_p"
                      v-if="chartBodygraphChart.gatesPersonalityExt.uranus"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesPersonalityExt.uranus
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesPersonalityExt.uranus.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="619.65643"
                      x="804.28656"
                      class="col-bg-person"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraphChart.gatesPersonalityExt.uranus.gate ||
                          0
                      }}.{{
                        chartBodygraphChart.gatesPersonalityExt.uranus.line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.uranus.isExalted
                      "
                      y="614"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.uranus
                          .isDetriment
                      "
                      y="627.5"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g7148_p">
                    <g
                      id="person_sign_saturn_p"
                      v-if="chartBodygraphChart.planets.saturn"
                      class="planet-per"
                      transform="translate(23.857012,22.837349)"
                    >
                      <path
                        id="path257_p"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 855.2,582.68 a 0.9,0.9 0 0 1 -0.5,-0.1 c -0.1,-0.1 -3.3,-1.7 -3.3,-4.7 0,-1.5 1.4,-2.9 3.2,-4.6 1.8,-1.7 3.9,-3.9 3.9,-5.6 a 5.7,5.7 0 1 0 -11.4,0 1.2,1.2 0 1 1 -2.4,0 8,8 0 0 1 16,0 c 0,2.7 -2.4,5.1 -4.6,7.3 -1,1 -2.5,2.5 -2.5,3 0,1.5 2,2.6 2.1,2.6 a 1.08,1.08 0 0 1 0.5,1.5 1.1,1.1 0 0 1 -1,0.6 z"
                      />
                      <path
                        id="path259_p"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 845.8,583.28 a 1.22,1.22 0 0 1 -1.2,-1.2 v -28.4 a 1.2,1.2 0 1 1 2.4,0 v 28.4 a 1.22,1.22 0 0 1 -1.2,1.2 z"
                      />
                      <path
                        id="path261_p"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="M 848.5,554.48 H 843 a 1.22,1.22 0 0 1 -1.2,-1.2 1.16,1.16 0 0 1 1.2,-1.2 h 5.5 a 1.2,1.2 0 1 1 0,2.4 z"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart.planets.saturn)
                        "
                        class="planet-substrate-rect saturn-per"
                        x="790"
                        y="523"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer[9]
                      }"
                      class="planet-number-per saturn-num-per"
                      x="762"
                      y="549"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_saturn_p"
                      v-if="chartBodygraphChart.gatesPersonalityExt.saturn"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesPersonalityExt.saturn
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesPersonalityExt.saturn.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="568.62701"
                      x="804.28656"
                      class="col-bg-person"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraphChart.gatesPersonalityExt.saturn.gate ||
                          0
                      }}.{{
                        chartBodygraphChart.gatesPersonalityExt.saturn.line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.saturn.isExalted
                      "
                      y="562"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.saturn
                          .isDetriment
                      "
                      y="575.5"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g7141_p">
                    <g
                      id="person_sign_jupiter_p"
                      v-if="chartBodygraphChart.planets.jupiter"
                      class="planet-per"
                      transform="translate(24.006651,22.491493)"
                    >
                      <path
                        id="path264_p"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 844.6,525.86 a 1.17,1.17 0 0 1 -1.1,-0.8 1.09,1.09 0 0 1 0.4,-1.3 c 8.5,-5.9 8.9,-12 8.9,-12.1 a 5.8002155,5.8002155 0 0 0 -11.6,0.1 1.2,1.2 0 1 1 -2.4,0 8.1,8.1 0 1 1 16.2,0 c 0,0.3 -0.4,5.9 -7.2,11.8 l 14.4,-0.2 a 1.13,1.13 0 0 1 1.2,1.1 1.2,1.2 0 0 1 -1.1,1.2 z"
                      />
                      <path
                        id="path266_p"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 858.3,531.86 a 1.22,1.22 0 0 1 -1.2,-1.2 v -27.1 a 1.2,1.2 0 1 1 2.4,0 v 27.1 a 1.22,1.22 0 0 1 -1.2,1.2 z"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart.planets.jupiter)
                        "
                        class="planet-substrate-rect jupiter-per"
                        x="790"
                        y="472"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer[8]
                      }"
                      class="planet-number-per jupiter-num-per"
                      x="762"
                      y="496"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_jupiter_p"
                      v-if="chartBodygraphChart.gatesPersonalityExt.jupiter"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesPersonalityExt.jupiter
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesPersonalityExt.jupiter.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="517.81512"
                      x="804.28656"
                      class="col-bg-person"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraphChart.gatesPersonalityExt.jupiter.gate ||
                          0
                      }}.{{
                        chartBodygraphChart.gatesPersonalityExt.jupiter.line ||
                          0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.jupiter
                          .isExalted
                      "
                      y="511"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.jupiter
                          .isDetriment
                      "
                      y="524.5"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g7135_p">
                    <g
                      id="person_sign_mars_p"
                      v-if="chartBodygraphChart.planets.mars"
                      class="planet-per"
                      transform="translate(23.740137,22.618215)"
                    >
                      <path
                        id="path269_p"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="M 850,480 A 12.2,12.2 0 1 1 862.2,467.8 12.25,12.25 0 0 1 850,480 Z m 0,-22.2 a 9.9,9.9 0 1 0 9.9,9.9 9.92,9.92 0 0 0 -9.9,-9.95 z"
                      />
                      <path
                        id="path271_p"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 857.2,461 a 0.91,0.91 0 0 1 -0.8,-0.4 1.21,1.21 0 0 1 0,-1.6 l 6.3,-6 a 1.1313708,1.1313708 0 1 1 1.6,1.6 l -6.3,6 a 1.23,1.23 0 0 1 -0.8,0.4 z"
                      />
                      <path
                        id="path273_p"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 862.9,459.85 h -0.2 a 1.2,1.2 0 0 1 -1,-1.3 l 0.6,-3.8 -3.8,0.6 a 1.16,1.16 0 1 1 -0.3,-2.3 l 5.4,-0.9 a 1.21,1.21 0 0 1 1,0.3 1,1 0 0 1 0.3,1 l -0.9,5.4 a 1.16,1.16 0 0 1 -1.1,1 z"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart.planets.mars)
                        "
                        class="planet-substrate-rect mars-per"
                        x="790"
                        y="423"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer[7]
                      }"
                      class="planet-number-per mars-num-per"
                      x="762"
                      y="446"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_mars_p"
                      v-if="chartBodygraphChart.gatesPersonalityExt.mars"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesPersonalityExt.mars
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesPersonalityExt.mars.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="466.89963"
                      x="804.28656"
                      class="col-bg-person"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraphChart.gatesPersonalityExt.mars.gate || 0
                      }}.{{
                        chartBodygraphChart.gatesPersonalityExt.mars.line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.mars.isExalted
                      "
                      y="459"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.mars.isDetriment
                      "
                      y="472.5"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g7128_p">
                    <g
                      id="person_sign_venus_p"
                      v-if="chartBodygraphChart.planets.venus"
                      class="planet-per"
                      transform="translate(23.806652,22.910158)"
                    >
                      <path
                        id="path276_p"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 851.3,420.86 a 10.4,10.4 0 1 1 10.4,-10.4 10.39,10.39 0 0 1 -10.4,10.4 z m 0,-18.6 a 8.1,8.1 0 1 0 8.1,8.1 8.07,8.07 0 0 0 -8.1,-8.1 z"
                      />
                      <path
                        id="path278_p"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 851.3,429.66 a 1.22,1.22 0 0 1 -1.2,-1.2 v -8.1 a 1.2,1.2 0 1 1 2.4,0 v 8.1 a 1.16,1.16 0 0 1 -1.2,1.2 z"
                      />
                      <path
                        id="path280_p"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 856.6,425.56 h -10.5 a 1.22,1.22 0 0 1 -1.2,-1.2 1.16,1.16 0 0 1 1.2,-1.2 h 10.5 a 1.2,1.2 0 0 1 0,2.4 z"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart.planets.venus)
                        "
                        class="planet-substrate-rect venus-per"
                        x="790"
                        y="370"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer[6]
                      }"
                      class="planet-number-per venus-num-per"
                      x="762"
                      y="396"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_venus_p"
                      v-if="chartBodygraphChart.gatesPersonalityExt.venus"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesPersonalityExt.venus
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesPersonalityExt.venus.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="415.98413"
                      x="804.28656"
                      class="col-bg-person"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraphChart.gatesPersonalityExt.venus.gate || 0
                      }}.{{
                        chartBodygraphChart.gatesPersonalityExt.venus.line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.venus.isExalted
                      "
                      y="409"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.venus
                          .isDetriment
                      "
                      y="422.5"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g7121_p">
                    <g
                      id="person_sign_mercury_p"
                      v-if="chartBodygraphChart.planets.mercury"
                      class="planet-per"
                      transform="translate(23.767386,22.999209)"
                    >
                      <path
                        id="path283_p"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 851.4,370.7 a 10,10 0 1 1 10,-10 10,10 0 0 1 -10,10 z m 0,-17.7 a 7.7,7.7 0 1 0 7.7,7.7 7.7,7.7 0 0 0 -7.7,-7.7 z"
                      />
                      <path
                        id="path285_p"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 851.4,379 a 1.22,1.22 0 0 1 -1.2,-1.2 v -7.7 a 1.2,1.2 0 1 1 2.4,0 v 7.7 a 1.16,1.16 0 0 1 -1.2,1.2 z"
                      />
                      <path
                        id="path287_p"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 856.4,375.2 h -10 a 1.22,1.22 0 0 1 -1.2,-1.2 1.16,1.16 0 0 1 1.2,-1.2 h 10 a 1.2,1.2 0 0 1 0,2.4 z"
                      />
                      <path
                        id="path289_p"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 844.9,355.6 a 1.1,1.1 0 0 1 -1,-0.6 l -2.5,-4.4 a 1.17,1.17 0 0 1 2,-1.2 l 2.5,4.4 a 1.23,1.23 0 0 1 -0.4,1.6 0.73,0.73 0 0 1 -0.6,0.2 z"
                      />
                      <path
                        id="path291_p"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 857.5,354.7 a 1.42,1.42 0 0 1 -0.6,-0.2 1.23,1.23 0 0 1 -0.4,-1.6 l 2.3,-3.7 a 1.17,1.17 0 0 1 2,1.2 l -2.3,3.7 a 1.22,1.22 0 0 1 -1,0.6 z"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart.planets.mercury)
                        "
                        class="planet-substrate-rect mercury-per"
                        x="790"
                        y="319"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer[5]
                      }"
                      class="planet-number-per mercury-num-per"
                      x="762"
                      y="343"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_mercury_p"
                      v-if="chartBodygraphChart.gatesPersonalityExt.mercury"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesPersonalityExt.mercury
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesPersonalityExt.mercury.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="365.07901"
                      x="804.28656"
                      class="col-bg-person"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraphChart.gatesPersonalityExt.mercury.gate ||
                          0
                      }}.{{
                        chartBodygraphChart.gatesPersonalityExt.mercury.line ||
                          0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.mercury
                          .isExalted
                      "
                      y="357"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.mercury
                          .isDetriment
                      "
                      y="370.5"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g7112_p">
                    <g
                      id="person_sign_south_node_p"
                      v-if="chartBodygraphChart.planets.south_node"
                      class="planet-per"
                    >
                      <path
                        style="cursor:pointer"
                        inkscape:connector-curvature="0"
                        d="m 828.26665,320.67685 a 10.75,10.75 0 0 1 -7.9,-3.5 8.39,8.39 0 0 1 -2.3,-6.8 c 0.3,-2.8 4,-5.3 6.6,-7.1 l 0.8,-0.6 a 3.26,3.26 0 0 0 1.4,-2.6 3.1,3.1 0 0 0 -1,-2.4 5.23,5.23 0 0 0 -3.4,-1.2 5.41,5.41 0 0 0 -4.6,2.6 1.17,1.17 0 0 1 -2,-1.2 7.55,7.55 0 0 1 6.5,-3.7 7.88,7.88 0 0 1 4.9,1.7 9,9 0 0 1 0.9,1 9,9 0 0 1 0.9,-1 7.88,7.88 0 0 1 4.9,-1.7 7.55,7.55 0 0 1 6.5,3.7 1.17,1.17 0 0 1 -2,1.2 5.39,5.39 0 0 0 -8,-1.4 2.69,2.69 0 0 0 -1,2.4 3.42,3.42 0 0 0 1.4,2.6 l 0.8,0.6 c 2.7,1.8 6.3,4.3 6.6,7.1 a 8.59,8.59 0 0 1 -2.3,7 9.88,9.88 0 0 1 -7.7,3.3 z m 0,-17.6 a 6.73,6.73 0 0 1 -1.4,1.5 l -0.8,0.6 c -2,1.4 -5.5,3.7 -5.6,5.5 a 6.63,6.63 0 0 0 1.7,5.1 8.47,8.47 0 0 0 6.2,2.7 8.07,8.07 0 0 0 6.2,-2.6 6.61,6.61 0 0 0 1.7,-5.2 c -0.2,-1.7 -3.6,-4.1 -5.6,-5.5 l -0.8,-0.6 a 4.63,4.63 0 0 1 -1.6,-1.45 z"
                      />
                      <rect
                        @click="
                          openTooltipDialog(
                            chartBodygraphChart.planets.south_node
                          )
                        "
                        class="planet-substrate-rect south-node-per"
                        x="815"
                        y="293"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer[3]
                      }"
                      class="planet-number-per south-node-num-per"
                      x="762"
                      y="293"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_south_node_p"
                      v-if="chartBodygraphChart.gatesPersonalityExt.south_node"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesPersonalityExt.south_node
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesPersonalityExt.south_node
                            .gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="314.15314"
                      x="804.28656"
                      class="col-bg-person"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraphChart.gatesPersonalityExt.south_node
                          .gate || 0
                      }}.{{
                        chartBodygraphChart.gatesPersonalityExt.south_node
                          .line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.south_node
                          .isExalted
                      "
                      y="307"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.south_node
                          .isDetriment
                      "
                      y="320.5"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g7108_p">
                    <g
                      id="person_sign_north_node_p"
                      v-if="chartBodygraphChart.planets.north_node"
                      class="planet-per"
                    >
                      <path
                        style="cursor:pointer"
                        inkscape:connector-curvature="0"
                        d="m 833.86665,269.76502 a 7.88,7.88 0 0 1 -4.9,-1.7 9,9 0 0 1 -0.9,-1 9,9 0 0 1 -0.9,1 7.88,7.88 0 0 1 -4.9,1.7 7.55,7.55 0 0 1 -6.5,-3.7 1.17,1.17 0 0 1 2,-1.2 5.39,5.39 0 0 0 8,1.4 2.69,2.69 0 0 0 1,-2.4 3.42,3.42 0 0 0 -1.4,-2.6 l -0.8,-0.6 c -2.7,-1.8 -6.3,-4.3 -6.6,-7.1 a 8.62,8.62 0 0 1 2.3,-6.8 10.75,10.75 0 0 1 7.9,-3.5 10.62,10.62 0 0 1 7.9,3.3 9.32,9.32 0 0 1 2.3,7 c -0.3,2.8 -4,5.3 -6.6,7.1 l -0.8,0.6 a 3.26,3.26 0 0 0 -1.4,2.6 3.1,3.1 0 0 0 1,2.4 5.23,5.23 0 0 0 3.4,1.2 5.41,5.41 0 0 0 4.6,-2.6 1.17,1.17 0 0 1 2,1.2 7.91,7.91 0 0 1 -6.7,3.7 z m -5.7,-24.1 a 8.64,8.64 0 0 0 -6.2,2.7 6.51,6.51 0 0 0 -1.7,5.1 c 0.2,1.7 3.6,4.1 5.6,5.5 l 0.8,0.6 a 4,4 0 0 1 1.4,1.5 6.73,6.73 0 0 1 1.4,-1.5 l 0.8,-0.6 c 2,-1.4 5.5,-3.7 5.6,-5.5 a 6.61,6.61 0 0 0 -1.7,-5.2 7.36,7.36 0 0 0 -6,-2.6 z"
                      />
                      <rect
                        @click="
                          openTooltipDialog(
                            chartBodygraphChart.planets.north_node
                          )
                        "
                        class="planet-substrate-rect north-node-per"
                        x="815"
                        y="242"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer[2]
                      }"
                      class="planet-number-per north-node-num-per"
                      x="762"
                      y="242"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_north_node_p"
                      v-if="chartBodygraphChart.gatesPersonalityExt.north_node"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesPersonalityExt.north_node
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesPersonalityExt.north_node
                            .gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="263.24805"
                      x="804.28656"
                      class="col-bg-person"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraphChart.gatesPersonalityExt.north_node
                          .gate || 0
                      }}.{{
                        chartBodygraphChart.gatesPersonalityExt.north_node
                          .line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.north_node
                          .isExalted
                      "
                      y="257"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.north_node
                          .isDetriment
                      "
                      y="270.5"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g7104_p">
                    <g
                      id="person_sign_moon_p"
                      v-if="chartBodygraphChart.planets.moon"
                      class="planet-per"
                    >
                      <path
                        style="cursor:pointer"
                        inkscape:connector-curvature="0"
                        d="m 830.19722,220.49849 a 14.9,14.9 0 1 1 8.7,-27 1.09,1.09 0 0 1 0.4,1.3 1.21,1.21 0 0 1 -1.2,0.8 h -0.6 a 9.42,9.42 0 1 0 2.1,18.6 1.2,1.2 0 0 1 1.3,0.6 1.23,1.23 0 0 1 -0.2,1.4 15.08,15.08 0 0 1 -10.5,4.3 z m 0,-27.5 a 12.6,12.6 0 0 0 0,25.2 12.42,12.42 0 0 0 6,-1.5 11.75,11.75 0 0 1 -1.8,-23 12.34,12.34 0 0 0 -4.2,-0.7 z"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart.planets.moon)
                        "
                        class="planet-substrate-rect moon-per"
                        x="815"
                        y="192"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer[4]
                      }"
                      class="planet-number-per moon-num-per"
                      x="762"
                      y="192"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_moon_p"
                      v-if="chartBodygraphChart.gatesPersonalityExt.moon"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesPersonalityExt.moon
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesPersonalityExt.moon.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="212.3325"
                      x="804.28656"
                      class="col-bg-person"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraphChart.gatesPersonalityExt.moon.gate || 0
                      }}.{{
                        chartBodygraphChart.gatesPersonalityExt.moon.line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.moon.isExalte
                      "
                      y="207"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.moon.isDetriment
                      "
                      y="220.5"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g7100_p">
                    <g
                      id="person_sign_earth_p"
                      v-if="chartBodygraphChart.planets.earth"
                      transform="translate(23.806655,23.383064)"
                      class="planet-per"
                    >
                      <path
                        id="path297_p"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 851.3,172.51 a 12.7,12.7 0 1 1 12.7,-12.7 12.72,12.72 0 0 1 -12.7,12.7 z m 0,-23.1 a 10.4,10.4 0 1 0 10.4,10.4 10.39,10.39 0 0 0 -10.4,-10.4 z"
                      />
                      <path
                        id="path299_p"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 851.3,172.31 a 1.22,1.22 0 0 1 -1.2,-1.2 v -22.8 a 1.2,1.2 0 0 1 2.4,0 v 22.8 a 1.22,1.22 0 0 1 -1.2,1.2 z"
                      />
                      <path
                        id="path301_p"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 862.7,160.81 h -22.8 a 1.22,1.22 0 0 1 -1.2,-1.2 1.16,1.16 0 0 1 1.2,-1.2 h 22.8 a 1.22,1.22 0 0 1 1.2,1.2 1.16,1.16 0 0 1 -1.2,1.2 z"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart.planets.earth)
                        "
                        class="planet-substrate-rect earth-per"
                        x="790"
                        y="116"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer[1]
                      }"
                      class="planet-number-per earth-num-per"
                      x="762"
                      y="141"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_earth_p"
                      v-if="chartBodygraphChart.gatesPersonalityExt.earth"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesPersonalityExt.earth
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesPersonalityExt.earth.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="161.41707"
                      x="804.28656"
                      class="col-bg-person"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraphChart.gatesPersonalityExt.earth.gate || 0
                      }}.{{
                        chartBodygraphChart.gatesPersonalityExt.earth.line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.earth.isExalted
                      "
                      y="155"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.earth
                          .isDetriment
                      "
                      y="168.5"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g7093_p">
                    <g
                      id="person_sign_sun_p"
                      v-if="chartBodygraphChart.planets.sun"
                      transform="translate(24.056651,23.28752)"
                      class="planet-per"
                    >
                      <path
                        id="path304_p"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="M 851,121.64 A 12.65,12.65 0 1 1 863.7,109 12.72,12.72 0 0 1 851,121.64 Z m 0,-23 A 10.4,10.4 0 1 0 861.4,109 10.39,10.39 0 0 0 851,98.64 Z"
                      />
                      <circle
                        id="circle306_p"
                        r="5.5999999"
                        cy="80.330002"
                        cx="804.06"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart.planets.sun)
                        "
                        class="planet-substrate-rect sun-per"
                        x="790"
                        y="66"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer[0]
                      }"
                      class="planet-number-per sun-num-per"
                      x="762"
                      y="90"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_sun_p"
                      v-if="chartBodygraphChart.gatesPersonalityExt.sun"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart.gatesPersonalityExt.sun
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart.gatesPersonalityExt.sun.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="110.50156"
                      x="804.28656"
                      class="col-bg-person"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraphChart.gatesPersonalityExt.sun.gate || 0
                      }}.{{
                        chartBodygraphChart.gatesPersonalityExt.sun.line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.sun.isExalted
                      "
                      y="104"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart.gatesPersonalityExt.sun.isDetriment
                      "
                      y="117"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                </g>
              </g>
              <g id="g8416_p2" transform="translate(10,2.9624634)">
                <g id="g7404_p2" :class="{ opacity4: isChart2Toggle }">
                  <g id="g7331">
                    <g
                      id="person_sign_pluto_p2"
                      v-if="chartBodygraphChart2.planets.pluto"
                      class="planet-per"
                    >
                      <path
                        inkscape:connector-curvature="0"
                        d="m 834.76945,729.70752 h -16.7 a 1,1 0 0 1 -1.1,-1.1 l 0.1,-27.7 a 1.14,1.14 0 0 1 0.3,-0.8 0.83,0.83 0 0 1 0.8,-0.3 l 11.3,0.1 a 9.9,9.9 0 0 1 0,19.8 l -10.2,0.1 v 7.7 h 15.5 a 1.22,1.22 0 0 1 1.2,1.2 1.13,1.13 0 0 1 -1.2,1 z m -15.4,-27.7 v 15.4 l 10.2,-0.1 a 7.6,7.6 0 1 0 0,-15.2 z"
                        data-name="design_sign_pluto"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart2.planets.pluto)
                        "
                        class="planet-substrate-rect pluto-per"
                        x="815"
                        y="700"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer2[12]
                      }"
                      class="planet-number-per pluto-num-per"
                      x="762"
                      y="701"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_pluto_p2"
                      v-if="chartBodygraphChart2.gatesPersonalityExt.pluto"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesPersonalityExt.pluto
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesPersonalityExt.pluto.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="721.48749"
                      x="804.28656"
                      class="col-bg-person"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraphChart2.gatesPersonalityExt.pluto.gate ||
                          0
                      }}.{{
                        chartBodygraphChart2.gatesPersonalityExt.pluto.line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.pluto.isExalted
                      "
                      y="713"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.pluto
                          .isDetriment
                      "
                      y="726.5"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g7167_p2">
                    <g
                      id="person_sign_neptune_p2"
                      v-if="chartBodygraphChart2.planets.neptune"
                      class="planet-per"
                      transform="translate(23.756655,22.292976)"
                    >
                      <path
                        id="path233_p2"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="M 851.3,672.46 A 13.51,13.51 0 0 1 837.8,659 a 1.2,1.2 0 1 1 2.4,0 11.2,11.2 0 1 0 22.4,0 1.2,1.2 0 1 1 2.4,0 13.88,13.88 0 0 1 -13.7,13.46 z"
                      />
                      <path
                        id="path235_p2"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 851.3,685.56 a 1.22,1.22 0 0 1 -1.2,-1.2 v -25.9 a 1.2,1.2 0 1 1 2.4,0 v 25.9 a 1.38,1.38 0 0 1 -1.2,1.2 z"
                      />
                      <path
                        id="path237_p2"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 859.1,679.36 h -15.5 a 1.2,1.2 0 0 1 0,-2.4 h 15.5 a 1.22,1.22 0 0 1 1.2,1.2 1.29,1.29 0 0 1 -1.2,1.2 z"
                      />
                      <polygon
                        id="polygon239_p2"
                        points="795.56,631.94 792.06,626.04 788.66,631.94 "
                      />
                      <polygon
                        id="polygon241_p2"
                        points="807.76,631.94 804.36,626.04 800.86,631.94 "
                      />
                      <polygon
                        id="polygon243_p2"
                        points="820.16,631.94 816.66,626.04 813.26,631.94 "
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart.planets.neptune)
                        "
                        class="planet-substrate-rect neptune-per"
                        x="790"
                        y="625"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer2[11]
                      }"
                      class="planet-number-per neptune-num-per"
                      x="762"
                      y="650"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_neptune_p2"
                      v-if="chartBodygraphChart2.gatesPersonalityExt.neptune"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesPersonalityExt.neptune
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesPersonalityExt.neptune.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="670.57196"
                      x="804.28656"
                      class="col-bg-person"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraphChart2.gatesPersonalityExt.neptune.gate ||
                          0
                      }}.{{
                        chartBodygraphChart2.gatesPersonalityExt.neptune.line ||
                          0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.neptune
                          .isExalted
                      "
                      y="663"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.neptune
                          .isDetriment
                      "
                      y="676.5"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g7157_p2">
                    <g
                      id="person_sign_uranus_p2"
                      v-if="chartBodygraphChart2.planets.uranus"
                      class="planet-per"
                      transform="translate(23.806655,21.642479)"
                    >
                      <path
                        id="path246_p2"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 840.3,629.89 a 1.08,1.08 0 0 1 -0.7,-0.3 1,1 0 0 1 0,-1.4 15,15 0 0 0 4.1,-10.2 14.66,14.66 0 0 0 -4.3,-10.5 1,1 0 0 1 1.4,-1.4 16.78,16.78 0 0 1 0.2,23.5 2.12,2.12 0 0 1 -0.7,0.3 z"
                      />
                      <path
                        id="path248_p2"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 862.3,630.39 a 0.91,0.91 0 0 1 -0.7,-0.3 16.77,16.77 0 0 1 -4.7,-11.6 17.05,17.05 0 0 1 4.9,-11.9 1,1 0 0 1 1.4,1.4 14.86,14.86 0 0 0 -0.2,20.7 1,1 0 0 1 0,1.4 1.08,1.08 0 0 1 -0.7,0.3 z"
                      />
                      <path
                        id="path250_p2"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 851.3,624.39 a 0.94,0.94 0 0 1 -1,-1 v -16.2 a 1,1 0 0 1 2,0 v 16.2 a 1.08,1.08 0 0 1 -1,1 z"
                      />
                      <path
                        id="path252_p2"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 851.3,633.69 a 5.5,5.5 0 1 1 5.5,-5.5 5.55,5.55 0 0 1 -5.5,5.5 z m 0,-8.9 a 3.5,3.5 0 1 0 3.5,3.5 3.48,3.48 0 0 0 -3.5,-3.5 z"
                      />
                      <path
                        id="path254_p2"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 857.8,618.89 h -13.1 a 1,1 0 0 1 0,-2 h 13.1 a 1,1 0 0 1 0,2 z"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart.planets.uranus)
                        "
                        class="planet-substrate-rect uranus-per"
                        x="790"
                        y="576"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer2[10]
                      }"
                      class="planet-number-per uranus-num-per"
                      x="762"
                      y="600"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_uranus_p2"
                      v-if="chartBodygraphChart2.gatesPersonalityExt.uranus"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesPersonalityExt.uranus
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesPersonalityExt.uranus.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="619.65643"
                      x="804.28656"
                      class="col-bg-person"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraphChart2.gatesPersonalityExt.uranus.gate ||
                          0
                      }}.{{
                        chartBodygraphChart2.gatesPersonalityExt.uranus.line ||
                          0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.uranus
                          .isExalted
                      "
                      y="614"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.uranus
                          .isDetriment
                      "
                      y="627.5"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g7148_p2">
                    <g
                      id="person_sign_saturn_p2"
                      v-if="chartBodygraphChart2.planets.saturn"
                      class="planet-per"
                      transform="translate(23.857012,22.837349)"
                    >
                      <path
                        id="path257_p2"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 855.2,582.68 a 0.9,0.9 0 0 1 -0.5,-0.1 c -0.1,-0.1 -3.3,-1.7 -3.3,-4.7 0,-1.5 1.4,-2.9 3.2,-4.6 1.8,-1.7 3.9,-3.9 3.9,-5.6 a 5.7,5.7 0 1 0 -11.4,0 1.2,1.2 0 1 1 -2.4,0 8,8 0 0 1 16,0 c 0,2.7 -2.4,5.1 -4.6,7.3 -1,1 -2.5,2.5 -2.5,3 0,1.5 2,2.6 2.1,2.6 a 1.08,1.08 0 0 1 0.5,1.5 1.1,1.1 0 0 1 -1,0.6 z"
                      />
                      <path
                        id="path259_p2"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 845.8,583.28 a 1.22,1.22 0 0 1 -1.2,-1.2 v -28.4 a 1.2,1.2 0 1 1 2.4,0 v 28.4 a 1.22,1.22 0 0 1 -1.2,1.2 z"
                      />
                      <path
                        id="path261_p2"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="M 848.5,554.48 H 843 a 1.22,1.22 0 0 1 -1.2,-1.2 1.16,1.16 0 0 1 1.2,-1.2 h 5.5 a 1.2,1.2 0 1 1 0,2.4 z"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart.planets.saturn)
                        "
                        class="planet-substrate-rect saturn-per"
                        x="790"
                        y="523"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer2[9]
                      }"
                      class="planet-number-per saturn-num-per"
                      x="762"
                      y="549"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_saturn_p2"
                      v-if="chartBodygraphChart2.gatesPersonalityExt.saturn"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesPersonalityExt.saturn
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesPersonalityExt.saturn.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="568.62701"
                      x="804.28656"
                      class="col-bg-person"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraphChart2.gatesPersonalityExt.saturn.gate ||
                          0
                      }}.{{
                        chartBodygraphChart2.gatesPersonalityExt.saturn.line ||
                          0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.saturn
                          .isExalted
                      "
                      y="562"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.saturn
                          .isDetriment
                      "
                      y="575.5"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g7141_p2">
                    <g
                      id="person_sign_jupiter_p2"
                      v-if="chartBodygraphChart2.planets.jupiter"
                      class="planet-per"
                      transform="translate(24.006651,22.491493)"
                    >
                      <path
                        id="path264_p2"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 844.6,525.86 a 1.17,1.17 0 0 1 -1.1,-0.8 1.09,1.09 0 0 1 0.4,-1.3 c 8.5,-5.9 8.9,-12 8.9,-12.1 a 5.8002155,5.8002155 0 0 0 -11.6,0.1 1.2,1.2 0 1 1 -2.4,0 8.1,8.1 0 1 1 16.2,0 c 0,0.3 -0.4,5.9 -7.2,11.8 l 14.4,-0.2 a 1.13,1.13 0 0 1 1.2,1.1 1.2,1.2 0 0 1 -1.1,1.2 z"
                      />
                      <path
                        id="path266_p2"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 858.3,531.86 a 1.22,1.22 0 0 1 -1.2,-1.2 v -27.1 a 1.2,1.2 0 1 1 2.4,0 v 27.1 a 1.22,1.22 0 0 1 -1.2,1.2 z"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart.planets.jupiter)
                        "
                        class="planet-substrate-rect jupiter-per"
                        x="790"
                        y="472"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer2[8]
                      }"
                      class="planet-number-per jupiter-num-per"
                      x="762"
                      y="496"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_jupiter_p2"
                      v-if="chartBodygraphChart2.gatesPersonalityExt.jupiter"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesPersonalityExt.jupiter
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesPersonalityExt.jupiter.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="517.81512"
                      x="804.28656"
                      class="col-bg-person"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraphChart2.gatesPersonalityExt.jupiter.gate ||
                          0
                      }}.{{
                        chartBodygraphChart2.gatesPersonalityExt.jupiter.line ||
                          0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.jupiter
                          .isExalted
                      "
                      y="511"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.jupiter
                          .isDetriment
                      "
                      y="524.5"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g7135_p2">
                    <g
                      id="person_sign_mars_p2"
                      v-if="chartBodygraphChart2.planets.mars"
                      class="planet-per"
                      transform="translate(23.740137,22.618215)"
                    >
                      <path
                        id="path269_p2"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="M 850,480 A 12.2,12.2 0 1 1 862.2,467.8 12.25,12.25 0 0 1 850,480 Z m 0,-22.2 a 9.9,9.9 0 1 0 9.9,9.9 9.92,9.92 0 0 0 -9.9,-9.95 z"
                      />
                      <path
                        id="path271_p2"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 857.2,461 a 0.91,0.91 0 0 1 -0.8,-0.4 1.21,1.21 0 0 1 0,-1.6 l 6.3,-6 a 1.1313708,1.1313708 0 1 1 1.6,1.6 l -6.3,6 a 1.23,1.23 0 0 1 -0.8,0.4 z"
                      />
                      <path
                        id="path273_p2"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 862.9,459.85 h -0.2 a 1.2,1.2 0 0 1 -1,-1.3 l 0.6,-3.8 -3.8,0.6 a 1.16,1.16 0 1 1 -0.3,-2.3 l 5.4,-0.9 a 1.21,1.21 0 0 1 1,0.3 1,1 0 0 1 0.3,1 l -0.9,5.4 a 1.16,1.16 0 0 1 -1.1,1 z"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart.planets.mars)
                        "
                        class="planet-substrate-rect mars-per"
                        x="790"
                        y="423"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer2[7]
                      }"
                      class="planet-number-per mars-num-per"
                      x="762"
                      y="446"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_mars_p2"
                      v-if="chartBodygraphChart2.gatesPersonalityExt.mars"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesPersonalityExt.mars
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesPersonalityExt.mars.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="466.89963"
                      x="804.28656"
                      class="col-bg-person"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraphChart2.gatesPersonalityExt.mars.gate || 0
                      }}.{{
                        chartBodygraphChart2.gatesPersonalityExt.mars.line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.mars.isExalted
                      "
                      y="459"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.mars
                          .isDetriment
                      "
                      y="472.5"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g7128_p2">
                    <g
                      id="person_sign_venus_p2"
                      v-if="chartBodygraphChart2.planets.venus"
                      class="planet-per"
                      transform="translate(23.806652,22.910158)"
                    >
                      <path
                        id="path276_p2"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 851.3,420.86 a 10.4,10.4 0 1 1 10.4,-10.4 10.39,10.39 0 0 1 -10.4,10.4 z m 0,-18.6 a 8.1,8.1 0 1 0 8.1,8.1 8.07,8.07 0 0 0 -8.1,-8.1 z"
                      />
                      <path
                        id="path278_p2"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 851.3,429.66 a 1.22,1.22 0 0 1 -1.2,-1.2 v -8.1 a 1.2,1.2 0 1 1 2.4,0 v 8.1 a 1.16,1.16 0 0 1 -1.2,1.2 z"
                      />
                      <path
                        id="path280_p2"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 856.6,425.56 h -10.5 a 1.22,1.22 0 0 1 -1.2,-1.2 1.16,1.16 0 0 1 1.2,-1.2 h 10.5 a 1.2,1.2 0 0 1 0,2.4 z"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart.planets.venus)
                        "
                        class="planet-substrate-rect venus-per"
                        x="790"
                        y="370"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer2[6]
                      }"
                      class="planet-number-per venus-num-per"
                      x="762"
                      y="396"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_venus_p2"
                      v-if="chartBodygraphChart2.gatesPersonalityExt.venus"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesPersonalityExt.venus
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesPersonalityExt.venus.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="415.98413"
                      x="804.28656"
                      class="col-bg-person"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraphChart2.gatesPersonalityExt.venus.gate ||
                          0
                      }}.{{
                        chartBodygraphChart2.gatesPersonalityExt.venus.line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.venus.isExalted
                      "
                      y="409"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.venus
                          .isDetriment
                      "
                      y="422.5"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g7121_p2">
                    <g
                      id="person_sign_mercury_p2"
                      v-if="chartBodygraphChart2.planets.mercury"
                      class="planet-per"
                      transform="translate(23.767386,22.999209)"
                    >
                      <path
                        id="path283_p2"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 851.4,370.7 a 10,10 0 1 1 10,-10 10,10 0 0 1 -10,10 z m 0,-17.7 a 7.7,7.7 0 1 0 7.7,7.7 7.7,7.7 0 0 0 -7.7,-7.7 z"
                      />
                      <path
                        id="path285_p2"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 851.4,379 a 1.22,1.22 0 0 1 -1.2,-1.2 v -7.7 a 1.2,1.2 0 1 1 2.4,0 v 7.7 a 1.16,1.16 0 0 1 -1.2,1.2 z"
                      />
                      <path
                        id="path287_p2"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 856.4,375.2 h -10 a 1.22,1.22 0 0 1 -1.2,-1.2 1.16,1.16 0 0 1 1.2,-1.2 h 10 a 1.2,1.2 0 0 1 0,2.4 z"
                      />
                      <path
                        id="path289_p2"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 844.9,355.6 a 1.1,1.1 0 0 1 -1,-0.6 l -2.5,-4.4 a 1.17,1.17 0 0 1 2,-1.2 l 2.5,4.4 a 1.23,1.23 0 0 1 -0.4,1.6 0.73,0.73 0 0 1 -0.6,0.2 z"
                      />
                      <path
                        id="path291_p2"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 857.5,354.7 a 1.42,1.42 0 0 1 -0.6,-0.2 1.23,1.23 0 0 1 -0.4,-1.6 l 2.3,-3.7 a 1.17,1.17 0 0 1 2,1.2 l -2.3,3.7 a 1.22,1.22 0 0 1 -1,0.6 z"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart.planets.mercury)
                        "
                        class="planet-substrate-rect mercury-per"
                        x="790"
                        y="319"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer2[5]
                      }"
                      class="planet-number-per mercury-num-per"
                      x="762"
                      y="343"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_mercury_p2"
                      v-if="chartBodygraphChart2.gatesPersonalityExt.mercury"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesPersonalityExt.mercury
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesPersonalityExt.mercury.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="365.07901"
                      x="804.28656"
                      class="col-bg-person"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraphChart2.gatesPersonalityExt.mercury.gate ||
                          0
                      }}.{{
                        chartBodygraphChart2.gatesPersonalityExt.mercury.line ||
                          0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.mercury
                          .isExalted
                      "
                      y="357"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.mercury
                          .isDetriment
                      "
                      y="370.5"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g7112_p2">
                    <g
                      id="person_sign_south_node_p2"
                      v-if="chartBodygraphChart2.planets.south_node"
                      class="planet-per"
                    >
                      <path
                        style="cursor:pointer"
                        inkscape:connector-curvature="0"
                        d="m 828.26665,320.67685 a 10.75,10.75 0 0 1 -7.9,-3.5 8.39,8.39 0 0 1 -2.3,-6.8 c 0.3,-2.8 4,-5.3 6.6,-7.1 l 0.8,-0.6 a 3.26,3.26 0 0 0 1.4,-2.6 3.1,3.1 0 0 0 -1,-2.4 5.23,5.23 0 0 0 -3.4,-1.2 5.41,5.41 0 0 0 -4.6,2.6 1.17,1.17 0 0 1 -2,-1.2 7.55,7.55 0 0 1 6.5,-3.7 7.88,7.88 0 0 1 4.9,1.7 9,9 0 0 1 0.9,1 9,9 0 0 1 0.9,-1 7.88,7.88 0 0 1 4.9,-1.7 7.55,7.55 0 0 1 6.5,3.7 1.17,1.17 0 0 1 -2,1.2 5.39,5.39 0 0 0 -8,-1.4 2.69,2.69 0 0 0 -1,2.4 3.42,3.42 0 0 0 1.4,2.6 l 0.8,0.6 c 2.7,1.8 6.3,4.3 6.6,7.1 a 8.59,8.59 0 0 1 -2.3,7 9.88,9.88 0 0 1 -7.7,3.3 z m 0,-17.6 a 6.73,6.73 0 0 1 -1.4,1.5 l -0.8,0.6 c -2,1.4 -5.5,3.7 -5.6,5.5 a 6.63,6.63 0 0 0 1.7,5.1 8.47,8.47 0 0 0 6.2,2.7 8.07,8.07 0 0 0 6.2,-2.6 6.61,6.61 0 0 0 1.7,-5.2 c -0.2,-1.7 -3.6,-4.1 -5.6,-5.5 l -0.8,-0.6 a 4.63,4.63 0 0 1 -1.6,-1.45 z"
                      />
                      <rect
                        @click="
                          openTooltipDialog(
                            chartBodygraphChart.planets.south_node
                          )
                        "
                        class="planet-substrate-rect south-node-per"
                        x="815"
                        y="293"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer2[3]
                      }"
                      class="planet-number-per south-node-num-per"
                      x="762"
                      y="293"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_south_node_p2"
                      v-if="chartBodygraphChart2.gatesPersonalityExt.south_node"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesPersonalityExt.south_node
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesPersonalityExt.south_node
                            .gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="314.15314"
                      x="804.28656"
                      class="col-bg-person"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraphChart2.gatesPersonalityExt.south_node
                          .gate || 0
                      }}.{{
                        chartBodygraphChart2.gatesPersonalityExt.south_node
                          .line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.south_node
                          .isExalted
                      "
                      y="307"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.south_node
                          .isDetriment
                      "
                      y="320.5"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g7108_p2">
                    <g
                      id="person_sign_north_node_p2"
                      v-if="chartBodygraphChart2.planets.north_node"
                      class="planet-per"
                    >
                      <path
                        style="cursor:pointer"
                        inkscape:connector-curvature="0"
                        d="m 833.86665,269.76502 a 7.88,7.88 0 0 1 -4.9,-1.7 9,9 0 0 1 -0.9,-1 9,9 0 0 1 -0.9,1 7.88,7.88 0 0 1 -4.9,1.7 7.55,7.55 0 0 1 -6.5,-3.7 1.17,1.17 0 0 1 2,-1.2 5.39,5.39 0 0 0 8,1.4 2.69,2.69 0 0 0 1,-2.4 3.42,3.42 0 0 0 -1.4,-2.6 l -0.8,-0.6 c -2.7,-1.8 -6.3,-4.3 -6.6,-7.1 a 8.62,8.62 0 0 1 2.3,-6.8 10.75,10.75 0 0 1 7.9,-3.5 10.62,10.62 0 0 1 7.9,3.3 9.32,9.32 0 0 1 2.3,7 c -0.3,2.8 -4,5.3 -6.6,7.1 l -0.8,0.6 a 3.26,3.26 0 0 0 -1.4,2.6 3.1,3.1 0 0 0 1,2.4 5.23,5.23 0 0 0 3.4,1.2 5.41,5.41 0 0 0 4.6,-2.6 1.17,1.17 0 0 1 2,1.2 7.91,7.91 0 0 1 -6.7,3.7 z m -5.7,-24.1 a 8.64,8.64 0 0 0 -6.2,2.7 6.51,6.51 0 0 0 -1.7,5.1 c 0.2,1.7 3.6,4.1 5.6,5.5 l 0.8,0.6 a 4,4 0 0 1 1.4,1.5 6.73,6.73 0 0 1 1.4,-1.5 l 0.8,-0.6 c 2,-1.4 5.5,-3.7 5.6,-5.5 a 6.61,6.61 0 0 0 -1.7,-5.2 7.36,7.36 0 0 0 -6,-2.6 z"
                      />
                      <rect
                        @click="
                          openTooltipDialog(
                            chartBodygraphChart.planets.north_node
                          )
                        "
                        class="planet-substrate-rect north-node-per"
                        x="815"
                        y="242"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer2[2]
                      }"
                      class="planet-number-per north-node-num-per"
                      x="762"
                      y="242"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_north_node_p2"
                      v-if="chartBodygraphChart2.gatesPersonalityExt.north_node"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesPersonalityExt.north_node
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesPersonalityExt.north_node
                            .gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="263.24805"
                      x="804.28656"
                      class="col-bg-person"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraphChart2.gatesPersonalityExt.north_node
                          .gate || 0
                      }}.{{
                        chartBodygraphChart2.gatesPersonalityExt.north_node
                          .line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.north_node
                          .isExalted
                      "
                      y="257"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.north_node
                          .isDetriment
                      "
                      y="270.5"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g7104_p2">
                    <g
                      id="person_sign_moon_p2"
                      v-if="chartBodygraphChart2.planets.moon"
                      class="planet-per"
                    >
                      <path
                        style="cursor:pointer"
                        inkscape:connector-curvature="0"
                        d="m 830.19722,220.49849 a 14.9,14.9 0 1 1 8.7,-27 1.09,1.09 0 0 1 0.4,1.3 1.21,1.21 0 0 1 -1.2,0.8 h -0.6 a 9.42,9.42 0 1 0 2.1,18.6 1.2,1.2 0 0 1 1.3,0.6 1.23,1.23 0 0 1 -0.2,1.4 15.08,15.08 0 0 1 -10.5,4.3 z m 0,-27.5 a 12.6,12.6 0 0 0 0,25.2 12.42,12.42 0 0 0 6,-1.5 11.75,11.75 0 0 1 -1.8,-23 12.34,12.34 0 0 0 -4.2,-0.7 z"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart.planets.moon)
                        "
                        class="planet-substrate-rect moon-per"
                        x="815"
                        y="192"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer2[4]
                      }"
                      class="planet-number-per moon-num-per"
                      x="762"
                      y="192"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_moon_p2"
                      v-if="chartBodygraphChart2.gatesPersonalityExt.moon"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesPersonalityExt.moon
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesPersonalityExt.moon.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="212.3325"
                      x="804.28656"
                      class="col-bg-person"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraphChart2.gatesPersonalityExt.moon.gate || 0
                      }}.{{
                        chartBodygraphChart2.gatesPersonalityExt.moon.line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.moon.isExalte
                      "
                      y="207"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.moon
                          .isDetriment
                      "
                      y="220.5"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g7100_p2">
                    <g
                      id="person_sign_earth_p2"
                      v-if="chartBodygraphChart2.planets.earth"
                      transform="translate(23.806655,23.383064)"
                      class="planet-per"
                    >
                      <path
                        id="path297_p2"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 851.3,172.51 a 12.7,12.7 0 1 1 12.7,-12.7 12.72,12.72 0 0 1 -12.7,12.7 z m 0,-23.1 a 10.4,10.4 0 1 0 10.4,10.4 10.39,10.39 0 0 0 -10.4,-10.4 z"
                      />
                      <path
                        id="path299_p2"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 851.3,172.31 a 1.22,1.22 0 0 1 -1.2,-1.2 v -22.8 a 1.2,1.2 0 0 1 2.4,0 v 22.8 a 1.22,1.22 0 0 1 -1.2,1.2 z"
                      />
                      <path
                        id="path301_p2"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="m 862.7,160.81 h -22.8 a 1.22,1.22 0 0 1 -1.2,-1.2 1.16,1.16 0 0 1 1.2,-1.2 h 22.8 a 1.22,1.22 0 0 1 1.2,1.2 1.16,1.16 0 0 1 -1.2,1.2 z"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart.planets.earth)
                        "
                        class="planet-substrate-rect earth-per"
                        x="790"
                        y="116"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer2[1]
                      }"
                      class="planet-number-per earth-num-per"
                      x="762"
                      y="141"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_earth_p2"
                      v-if="chartBodygraphChart2.gatesPersonalityExt.earth"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesPersonalityExt.earth
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesPersonalityExt.earth.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="161.41707"
                      x="804.28656"
                      class="col-bg-person"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraphChart2.gatesPersonalityExt.earth.gate ||
                          0
                      }}.{{
                        chartBodygraphChart2.gatesPersonalityExt.earth.line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.earth.isExalted
                      "
                      y="155"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.earth
                          .isDetriment
                      "
                      y="168.5"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                  <g id="g7093_p2">
                    <g
                      id="person_sign_sun_p2"
                      v-if="chartBodygraphChart2.planets.sun"
                      transform="translate(24.056651,23.28752)"
                      class="planet-per"
                    >
                      <path
                        id="path304_p2"
                        inkscape:connector-curvature="0"
                        transform="translate(-46.94,-28.51)"
                        d="M 851,121.64 A 12.65,12.65 0 1 1 863.7,109 12.72,12.72 0 0 1 851,121.64 Z m 0,-23 A 10.4,10.4 0 1 0 861.4,109 10.39,10.39 0 0 0 851,98.64 Z"
                      />
                      <circle
                        id="circle306_p2"
                        r="5.5999999"
                        cy="80.330002"
                        cx="804.06"
                      />
                      <rect
                        @click="
                          openTooltipDialog(chartBodygraphChart.planets.sun)
                        "
                        class="planet-substrate-rect sun-per"
                        x="790"
                        y="66"
                      />
                    </g>
                    <rect
                      v-bind:class="{
                        opacity2: $store.state.account.isPlanetPer2[0]
                      }"
                      class="planet-number-per sun-num-per"
                      x="762"
                      y="90"
                      rx="7"
                      ry="7"
                    />
                    <text
                      id="person_number_sun_p2"
                      v-if="chartBodygraphChart2.gatesPersonalityExt.sun"
                      @click="
                        openTooltipDialog(
                          chartBodygraphChart2.gatesPersonalityExt.sun
                        )
                      "
                      @mouseover="
                        searchAllAcPlanets(
                          chartBodygraphChart2.gatesPersonalityExt.sun.gate
                        )
                      "
                      @mouseleave="delayedСlearAcPlanets()"
                      y="110.50156"
                      x="804.28656"
                      class="col-bg-person"
                      style="cursor:pointer;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:20.71999931px;-inkscape-font-specification:'MyriadPro-Regular, Myriad Pro, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-feature-settings:normal;text-align:end;writing-mode:lr-tb;text-anchor:end;isolation:isolate"
                      font-size="20.72"
                    >
                      {{
                        chartBodygraphChart2.gatesPersonalityExt.sun.gate || 0
                      }}.{{
                        chartBodygraphChart2.gatesPersonalityExt.sun.line || 0
                      }}
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.sun.isExalted
                      "
                      y="104"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9651;</tspan>
                    </text>
                    <text
                      v-if="
                        chartBodygraphChart2.gatesPersonalityExt.sun.isDetriment
                      "
                      y="117"
                      x="745"
                      font-size="16"
                    >
                      <tspan class="line-arrow" font-size="16">&#9661;</tspan>
                    </text>
                  </g>
                </g>
              </g>
              <g
                id="Слой_12"
                data-name="Слой 12"
                transform="translate(31.134997,17.409983)"
              >
                <g id="Слой_9" data-name="Слой 9">
                  <g id="g394">
                    <path
                      id="path380"
                      d="m 455.8,740.4 c -51.8,0 -101.4,-14.7 -139.7,-41.3 -37.4,-26 -58.8,-60.5 -58.8,-94.7 0,-78.6 20.9,-159.8 57.3,-222.6 18.4,-31.7 39.5,-56.8 62.8,-74.5 25.1,-19.1 51.4,-28.8 78.4,-28.8 53.5,0 103.2,33.6 140,94.7 36.3,60.2 57.1,142.3 58.5,231.3 v 0 c 0.4,25.6 -11.2,51.2 -33.6,74 -18.1,18.4 -42.4,33.9 -70.3,44.7 a 267.18,267.18 0 0 1 -94.6,17.2 z m 0,-452 c -48.6,0 -95.7,34.9 -132.5,98.4 -35.6,61.4 -56,140.7 -56,217.6 0,30.8 19.9,62.3 54.5,86.5 36.6,25.5 84.2,39.5 134,39.5 61.5,0 121.9,-22.7 157.8,-59.1 20.5,-20.8 31.1,-43.9 30.7,-66.8 v 0 C 642.9,517.3 622.6,437 587.2,378.2 552.3,320.3 505.7,288.4 455.8,288.4 Z"
                      transform="translate(-46.94,-28.51)"
                      inkscape:connector-curvature="0"
                      style="fill:#ffffff"
                    />
                    <path
                      id="path382"
                      d="m 455.8,719.4 c -47,0 -92,-13.3 -126.7,-37.5 -34,-23.7 -53.4,-55 -53.4,-86 0,-71.2 18.9,-144.8 52,-201.7 16.7,-28.7 35.8,-51.5 56.9,-67.6 22.8,-17.4 46.7,-26.2 71.2,-26.2 48.6,0 93.7,30.5 127.1,85.9 32.9,54.5 51.7,128.9 53,209.5 v 0 c 0.4,23.3 -10.2,46.6 -30.5,67.3 -16.4,16.7 -38.5,30.7 -63.8,40.6 a 241.4,241.4 0 0 1 -85.8,15.7 z m 0,-409 c -43.8,0 -86.3,31.5 -119.5,88.8 -32.2,55.5 -50.6,127.1 -50.6,196.7 0,27.7 17.9,56.1 49.1,77.8 33,23 76,35.7 120.9,35.7 55.5,0 110.1,-20.4 142.4,-53.4 18.4,-18.7 28,-39.5 27.6,-60.1 v 0 c -1.3,-78.8 -19.6,-151.4 -51.6,-204.5 -31.3,-52.2 -73.4,-81 -118.3,-81 z"
                      transform="translate(-46.94,-28.51)"
                      inkscape:connector-curvature="0"
                      style="fill:#ffffff"
                    />
                    <path
                      id="path384"
                      d="m 455.8,697.4 c -41.6,0 -81.4,-11.8 -112.1,-33.2 -30.1,-21 -47.4,-48.8 -47.4,-76.4 h 10 c 0,24.3 15.7,49.1 43.1,68.2 29.1,20.2 66.9,31.4 106.4,31.4 48.8,0 96.8,-18 125.3,-46.9 16.1,-16.4 24.5,-34.6 24.2,-52.6 l 10,-0.2 c 0.3,20.7 -9,41.4 -27.1,59.8 -30.7,31.2 -80.2,49.9 -132.4,49.9 z"
                      transform="translate(-46.94,-28.51)"
                      inkscape:connector-curvature="0"
                      style="fill:#ffffff"
                    />
                    <path
                      id="path386"
                      d="M 457.5,627.4 C 369.7,627.4 301,596 301,555.9 c 0,-40.1 68.7,-71.5 156.5,-71.5 87.8,0 156.5,31.4 156.5,71.5 0,40.1 -68.8,71.5 -156.5,71.5 z m 0,-133 c -79.4,0 -146.5,28.2 -146.5,61.5 0,33.3 67.1,61.5 146.5,61.5 79.4,0 146.5,-28.2 146.5,-61.5 0,-33.3 -67.1,-61.5 -146.5,-61.5 z"
                      transform="translate(-46.94,-28.51)"
                      inkscape:connector-curvature="0"
                      style="fill:#ffffff"
                    />
                    <path
                      id="path388"
                      d="m 395.56,564.19 c -41.2,0 -80,-8.4 -109.3,-23.6 -30.4,-15.8 -47.2,-37.3 -47.2,-60.5 0,-23.2 16.8,-44.7 47.2,-60.5 29.3,-15.2 68.1,-23.6 109.3,-23.6 v 10.5 c -39.7,0 -77,8 -104.9,22.6 -26.8,13.9 -41.6,32 -41.6,51 0,19 14.8,37 41.6,51 27.9,14.5 65.2,22.6 104.9,22.6 z"
                      inkscape:connector-curvature="0"
                      style="fill:#ffffff"
                    />
                    <path
                      id="path390"
                      d="m 559,506.4 h -10 c 0,-40.8 -37.2,-74 -83,-74 v -10 c 51.3,0 93,37.7 93,84 z"
                      transform="translate(-46.94,-28.51)"
                      inkscape:connector-curvature="0"
                      style="fill:#ffffff"
                    />
                    <path
                      id="path392"
                      d="m 565,478.4 h -10 c 0,-81.6 -44.2,-148 -98.5,-148 v -10 c 29.4,0 56.8,16.7 77.3,47 20.1,29.7 31.2,69.1 31.2,111 z"
                      transform="translate(-46.94,-28.51)"
                      inkscape:connector-curvature="0"
                      style="fill:#ffffff"
                    />
                  </g>
                </g>
              </g>
              <g
                id="Слой_8"
                data-name="Слой 8"
                transform="translate(31.140015,17.409983)"
              >
                <g id="g865">
                  <rect
                    id="rect859"
                    x="383.35999"
                    y="73.889999"
                    width="10"
                    height="633"
                    style="fill:#ffffff"
                  />
                  <rect
                    id="rect861"
                    x="403.85999"
                    y="73.889999"
                    width="10"
                    height="633"
                    style="fill:#ffffff"
                  />
                  <rect
                    id="rect863"
                    x="424.35999"
                    y="73.889999"
                    width="10"
                    height="633"
                    style="fill:#ffffff"
                  />
                </g>
              </g>
              <g
                id="Слой_9-2"
                data-name="Слой 9"
                transform="translate(31.140015,17.409983)"
              >
                <g id="Слой_13-2" data-name="Слой 13">
                  <g id="g874">
                    <rect
                      id="rect868"
                      x="383.35999"
                      y="73.889999"
                      width="10"
                      height="633"
                      style="fill:#ffffff"
                    />
                    <rect
                      id="rect870"
                      x="403.85999"
                      y="73.889999"
                      width="10"
                      height="633"
                      style="fill:#ffffff"
                    />
                    <rect
                      id="rect872"
                      x="424.35999"
                      y="73.889999"
                      width="10"
                      height="633"
                      style="fill:#ffffff"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g
              id="layer8"
              inkscape:groupmode="layer"
              inkscape:label="Gates Person"
              style="display:inline"
              sodipodi:insensitive="true"
            >
              <g
                id="Слой_14"
                style="display:inline"
                data-name="Слой 14"
                transform="translate(31.134997,17.409983)"
              >
                <path
                  id="gate_41_person"
                  v-if="chartBodygraphChart.gatesExt[41]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[41])"
                  @mouseover="searchAcPlanets(41)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        41
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 586.9,704.9 -5.7,-8.3 c -33.9,20.7 -77.5,33.1 -121.8,33.7 l 0.5,10 a 266.48,266.48 0 0 0 90.5,-17.3 225.62,225.62 0 0 0 36.5,-18.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_41_both_chart"
                  v-if="chartBodygraphChart.gatesExt[41]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[41])"
                  @mouseover="searchAcPlanets(41)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        41
                      ) && isChart2Toggle,
                    red_black__25: !isChartToggle
                  }"
                  d="m 586.9,704.9 -5.7,-8.3 c -33.9,20.7 -77.5,33.1 -121.8,33.7 l 0.5,10 a 266.48,266.48 0 0 0 90.5,-17.3 225.62,225.62 0 0 0 36.5,-18.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_30_person"
                  v-if="chartBodygraphChart.gatesExt[30]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[30])"
                  @mouseover="searchAcPlanets(30)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        30
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 653.6,582.8 -10,1.1 c 0.3,6.8 0.6,13.7 0.7,20.6 0.4,22.9 -10.2,46 -30.7,66.8 a 172.56,172.56 0 0 1 -32.4,25.4 l 5.7,8.3 a 178.16,178.16 0 0 0 33.8,-26.6 c 22.4,-22.8 34,-48.3 33.6,-74 -0.1,-7.3 -0.4,-14.5 -0.7,-21.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_30_both_chart"
                  v-if="chartBodygraphChart.gatesExt[30]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[30])"
                  @mouseover="searchAcPlanets(30)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        30
                      ) && isChart2Toggle,
                    red_black__35: !isChartToggle
                  }"
                  d="m 653.6,582.8 -10,1.1 c 0.3,6.8 0.6,13.7 0.7,20.6 0.4,22.9 -10.2,46 -30.7,66.8 a 172.56,172.56 0 0 1 -32.4,25.4 l 5.7,8.3 a 178.16,178.16 0 0 0 33.8,-26.6 c 22.4,-22.8 34,-48.3 33.6,-74 -0.1,-7.3 -0.4,-14.5 -0.7,-21.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_55_person"
                  v-if="chartBodygraphChart.gatesExt[55]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[55])"
                  @mouseover="searchAcPlanets(55)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        55
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 635.9,595.8 c -0.1,-3.1 -0.1,-6.2 -0.2,-9.3 l -9.9,3 c 0.1,2.1 0.1,4.3 0.1,6.4 0.3,20.6 -9.2,41.4 -27.6,60.1 a 152.5,152.5 0 0 1 -28.5,22.4 l 5.3,8.5 A 165.59,165.59 0 0 0 605.4,663 c 20.3,-20.6 30.9,-43.9 30.5,-67.2 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_55_both_chart"
                  v-if="chartBodygraphChart.gatesExt[55]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[55])"
                  @mouseover="searchAcPlanets(55)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        55
                      ) && isChart2Toggle,
                    red_black__35: !isChartToggle
                  }"
                  d="m 635.9,595.8 c -0.1,-3.1 -0.1,-6.2 -0.2,-9.3 l -9.9,3 c 0.1,2.1 0.1,4.3 0.1,6.4 0.3,20.6 -9.2,41.4 -27.6,60.1 a 152.5,152.5 0 0 1 -28.5,22.4 l 5.3,8.5 A 165.59,165.59 0 0 0 605.4,663 c 20.3,-20.6 30.9,-43.9 30.5,-67.2 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_49_person"
                  v-if="chartBodygraphChart.gatesExt[49]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[49])"
                  @mouseover="searchAcPlanets(49)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        49
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 615.4,587.7 -10,0.2 c 0.3,18 -8.1,36.2 -24.2,52.6 a 136.22,136.22 0 0 1 -23.4,18.7 l 5.4,8.6 a 147.83,147.83 0 0 0 25.2,-20.2 c 17.9,-18.5 27.3,-39.2 27,-59.9 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_49_both_chart"
                  v-if="chartBodygraphChart.gatesExt[49]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[49])"
                  @mouseover="searchAcPlanets(49)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        49
                      ) && isChart2Toggle,
                    red_black__35: !isChartToggle
                  }"
                  d="m 615.4,587.7 -10,0.2 c 0.3,18 -8.1,36.2 -24.2,52.6 a 136.22,136.22 0 0 1 -23.4,18.7 l 5.4,8.6 a 147.83,147.83 0 0 0 25.2,-20.2 c 17.9,-18.5 27.3,-39.2 27,-59.9 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_39_person"
                  v-if="chartBodygraphChart.gatesExt[39]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[39])"
                  @mouseover="searchAcPlanets(39)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        39
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 575.1,686.9 -5.3,-8.5 c -31.6,19.5 -72.5,30.9 -114,30.9 v 10 a 239.69,239.69 0 0 0 85.8,-15.8 190,190 0 0 0 33.5,-16.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_39_both_chart"
                  v-if="chartBodygraphChart.gatesExt[39]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[39])"
                  @mouseover="searchAcPlanets(39)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        39
                      ) && isChart2Toggle,
                    red_black__25: !isChartToggle
                  }"
                  d="m 575.1,686.9 -5.3,-8.5 c -31.6,19.5 -72.5,30.9 -114,30.9 v 10 a 239.69,239.69 0 0 0 85.8,-15.8 190,190 0 0 0 33.5,-16.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_19_person"
                  v-if="chartBodygraphChart.gatesExt[19]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[19])"
                  @mouseover="searchAcPlanets(19)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        19
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 557.3,659.4 c -27.9,17.6 -64.4,27.9 -101.4,27.9 v 10 c 39.5,0 77.5,-10.7 107,-29.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_19_both_chart"
                  v-if="chartBodygraphChart.gatesExt[19]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[19])"
                  @mouseover="searchAcPlanets(19)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        19
                      ) && isChart2Toggle,
                    red_black__25: !isChartToggle
                  }"
                  d="m 557.3,659.4 c -27.9,17.6 -64.4,27.9 -101.4,27.9 v 10 c 39.5,0 77.5,-10.7 107,-29.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_58_person"
                  v-if="chartBodygraphChart.gatesExt[58]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[58])"
                  @mouseover="searchAcPlanets(58)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        58
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 319.7,701.5 c 37.6,25.1 85.6,38.8 135.7,38.9 v -10 c -48.1,-0.1 -94.1,-13.3 -130.1,-37.3 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_58_both_chart"
                  v-if="chartBodygraphChart.gatesExt[58]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[58])"
                  @mouseover="searchAcPlanets(58)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        58
                      ) && isChart2Toggle,
                    red_black_25: !isChartToggle
                  }"
                  d="m 319.7,701.5 c 37.6,25.1 85.6,38.8 135.7,38.9 v -10 c -48.1,-0.1 -94.1,-13.3 -130.1,-37.3 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_38_person"
                  v-if="chartBodygraphChart.gatesExt[38]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[38])"
                  @mouseover="searchAcPlanets(38)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        38
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 455.3,709.4 c -43.7,-0.1 -85.6,-12.2 -118.2,-34.2 l -5.5,8.3 c 34.2,23 78,35.7 123.7,35.8 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_38_both_chart"
                  v-if="chartBodygraphChart.gatesExt[38]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[38])"
                  @mouseover="searchAcPlanets(38)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        38
                      ) && isChart2Toggle,
                    red_black_25: !isChartToggle
                  }"
                  d="m 455.3,709.4 c -43.7,-0.1 -85.6,-12.2 -118.2,-34.2 l -5.5,8.3 c 34.2,23 78,35.7 123.7,35.8 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_54_person"
                  v-if="chartBodygraphChart.gatesExt[54]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[54])"
                  @mouseover="searchAcPlanets(54)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        54
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  d="M 455.2,687.4 C 416,687.3 378.6,676.2 349.7,656.2 l -5.5,8.3 c 30.5,21.1 69.9,32.7 111,32.8 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_54_both_chart"
                  v-if="chartBodygraphChart.gatesExt[54]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[54])"
                  @mouseover="searchAcPlanets(54)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        54
                      ) && isChart2Toggle,
                    red_black_25: !isChartToggle
                  }"
                  d="M 455.2,687.4 C 416,687.3 378.6,676.2 349.7,656.2 l -5.5,8.3 c 30.5,21.1 69.9,32.7 111,32.8 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_36_person"
                  v-if="chartBodygraphChart.gatesExt[36]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[36])"
                  @mouseover="searchAcPlanets(36)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        36
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 582,369.9 c 1.8,2.7 3.5,5.5 5.2,8.3 32.6,54.1 52.3,126.5 56.4,205.7 l 10,-1.1 c -4.2,-80.6 -24.4,-154.4 -57.8,-209.7 -1.6,-2.7 -3.2,-5.2 -4.9,-7.8 l -9.2,4.3 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_36_both_chart"
                  v-if="chartBodygraphChart.gatesExt[36]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[36])"
                  @mouseover="searchAcPlanets(36)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        36
                      ) && isChart2Toggle,
                    red_black_70: !isChartToggle
                  }"
                  d="m 582,369.9 c 1.8,2.7 3.5,5.5 5.2,8.3 32.6,54.1 52.3,126.5 56.4,205.7 l 10,-1.1 c -4.2,-80.6 -24.4,-154.4 -57.8,-209.7 -1.6,-2.7 -3.2,-5.2 -4.9,-7.8 l -9.2,4.3 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_48_person"
                  v-if="chartBodygraphChart.gatesExt[48]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[48])"
                  @mouseover="searchAcPlanets(48)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        48
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 314.7,381.8 c -33.2,57.3 -53.5,129.8 -56.9,201.8 l 10,1 c 3.2,-70.6 23,-141.7 55.5,-197.8 3.5,-6 7,-11.7 10.6,-17.1 l -9.2,-4.3 c -3.4,5.2 -6.7,10.7 -10,16.4 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_48_both_chart"
                  v-if="chartBodygraphChart.gatesExt[48]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[48])"
                  @mouseover="searchAcPlanets(48)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        48
                      ) && isChart2Toggle,
                    red_black_120: !isChartToggle
                  }"
                  d="m 314.7,381.8 c -33.2,57.3 -53.5,129.8 -56.9,201.8 l 10,1 c 3.2,-70.6 23,-141.7 55.5,-197.8 3.5,-6 7,-11.7 10.6,-17.1 l -9.2,-4.3 c -3.4,5.2 -6.7,10.7 -10,16.4 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_45_person"
                  v-if="chartBodygraphChart.gatesExt[45]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[45])"
                  @mouseover="searchAcPlanets(45)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        45
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 456.5,320.4 v 10 c 32.5,0 61.4,23.8 79.4,60.5 l 9.1,-4.2 a 158.69,158.69 0 0 0 -11.1,-19.3 c -20.6,-30.3 -48.1,-47 -77.4,-47 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_45_both_chart"
                  v-if="chartBodygraphChart.gatesExt[45]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[45])"
                  @mouseover="searchAcPlanets(45)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        45
                      ) && isChart2Toggle,
                    red_black_70: !isChartToggle
                  }"
                  d="m 456.5,320.4 v 10 c 32.5,0 61.4,23.8 79.4,60.5 l 9.1,-4.2 a 158.69,158.69 0 0 0 -11.1,-19.3 c -20.6,-30.3 -48.1,-47 -77.4,-47 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_21_person"
                  v-if="chartBodygraphChart.gatesExt[21]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[21])"
                  @mouseover="searchAcPlanets(21)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        21
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 555,478.4 h 10 c 0,-33.3 -7,-65.1 -20.1,-91.7 l -9.1,4.2 c 12.1,24.5 19.2,54.8 19.2,87.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_21_both_chart"
                  v-if="chartBodygraphChart.gatesExt[21]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[21])"
                  @mouseover="searchAcPlanets(21)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        21
                      ) && isChart2Toggle,
                    red_black_70: !isChartToggle
                  }"
                  d="m 555,478.4 h 10 c 0,-33.3 -7,-65.1 -20.1,-91.7 l -9.1,4.2 c 12.1,24.5 19.2,54.8 19.2,87.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_22_person"
                  v-if="chartBodygraphChart.gatesExt[22]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[22])"
                  @mouseover="searchAcPlanets(22)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        22
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 574.3,391.4 c 31.1,51.6 49.3,121.8 51.4,198.1 l 9.9,-3 c -2.6,-77 -21.2,-147.8 -52.8,-200.2 -2.8,-4.6 -5.6,-9 -8.6,-13.3 l -9.2,4.3 c 3.3,4.5 6.3,9.2 9.3,14.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_22_both_chart"
                  v-if="chartBodygraphChart.gatesExt[22]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[22])"
                  @mouseover="searchAcPlanets(22)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        22
                      ) && isChart2Toggle,
                    red_black_70: !isChartToggle
                  }"
                  d="m 574.3,391.4 c 31.1,51.6 49.3,121.8 51.4,198.1 l 9.9,-3 c -2.6,-77 -21.2,-147.8 -52.8,-200.2 -2.8,-4.6 -5.6,-9 -8.6,-13.3 l -9.2,4.3 c 3.3,4.5 6.3,9.2 9.3,14.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_57_person"
                  v-if="chartBodygraphChart.gatesExt[57]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[57])"
                  @mouseover="searchAcPlanets(57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        57
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  style="fill:#4caf50;opacity:0;display:none"
                  d="m 270.79102,490.54102 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                  transform="translate(-31.134997,-17.409983)"
                  inkscape:connector-curvature="0"
                />
                <path
                  id="gate_57_both_chart"
                  v-if="chartBodygraphChart.gatesExt[57]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[57])"
                  @mouseover="searchAcPlanets(57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        57
                      ) && isChart2Toggle,
                    red_black_90: !isChartToggle
                  }"
                  d="m 239.65602,473.13104 c -6.22339,27.38635 -9.99238,55.55515 -10.69601,83.65896 l 10,1 c 0.68476,-28.46 4.57991,-56.99261 11.02413,-84.65896 z"
                  style="fill:#4caf50;opacity:0;display:none"
                  inkscape:connector-curvature="0"
                  sodipodi:nodetypes="ccccc"
                />
                <path
                  id="gate_20_person"
                  v-if="chartBodygraphChart.gatesExt[20]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[20])"
                  @mouseover="searchAcPlanets(20)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        20
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  style="fill:#4caf50;opacity:0;display:none"
                  d="m 439.0957,289.30078 c -24.2,0.2 -47.80078,8.99922 -70.30078,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 67,-66.49844 104.5,-66.89844 z"
                  transform="translate(-31.134997,-17.409983)"
                  inkscape:connector-curvature="0"
                />
                <path
                  id="gate_20_both_chart"
                  v-if="chartBodygraphChart.gatesExt[20]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[20])"
                  @mouseover="searchAcPlanets(20)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        20
                      ) && isChart2Toggle,
                    red_black_120: !isChartToggle
                  }"
                  style="fill:#4caf50;opacity:0;display:none"
                  d="m 439.0957,289.30078 c -24.2,0.2 -47.80078,8.99922 -70.30078,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 67,-66.49844 104.5,-66.89844 z"
                  transform="translate(-31.134997,-17.409983)"
                  inkscape:connector-curvature="0"
                />
                <path
                  id="gate_18_person"
                  v-if="chartBodygraphChart.gatesExt[18]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[18])"
                  @mouseover="searchAcPlanets(18)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        18
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 257.3,604.4 c 0,34.1 21.4,68.6 58.8,94.7 1.2,0.8 2.3,1.6 3.5,2.4 l 5.5,-8.3 c -1.1,-0.8 -2.2,-1.5 -3.4,-2.3 -34.7,-24.1 -54.5,-55.7 -54.5,-86.5 0,-6.6 0.2,-13.2 0.5,-19.9 l -10,-1 c -0.2,7 -0.4,13.9 -0.4,20.9 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_18_both_chart"
                  v-if="chartBodygraphChart.gatesExt[18]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[18])"
                  @mouseover="searchAcPlanets(18)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        18
                      ) && isChart2Toggle,
                    red_black_35: !isChartToggle
                  }"
                  d="m 257.3,604.4 c 0,34.1 21.4,68.6 58.8,94.7 1.2,0.8 2.3,1.6 3.5,2.4 l 5.5,-8.3 c -1.1,-0.8 -2.2,-1.5 -3.4,-2.3 -34.7,-24.1 -54.5,-55.7 -54.5,-86.5 0,-6.6 0.2,-13.2 0.5,-19.9 l -10,-1 c -0.2,7 -0.4,13.9 -0.4,20.9 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_28_person"
                  v-if="chartBodygraphChart.gatesExt[28]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[28])"
                  @mouseover="searchAcPlanets(28)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        28
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 275.7,595.9 c 0,31 19.5,62.4 53.4,86 0.8,0.6 1.6,1.1 2.4,1.6 l 5.5,-8.3 c -0.8,-0.5 -1.5,-1 -2.2,-1.5 -31.2,-21.8 -49.1,-50.1 -49.1,-77.8 0,-3.2 0.1,-6.3 0.1,-9.5 l -10,-1 c 0,3.5 -0.1,7 -0.1,10.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_28_both_chart"
                  v-if="chartBodygraphChart.gatesExt[28]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[28])"
                  @mouseover="searchAcPlanets(28)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        28
                      ) && isChart2Toggle,
                    red_black_35: !isChartToggle
                  }"
                  d="m 275.7,595.9 c 0,31 19.5,62.4 53.4,86 0.8,0.6 1.6,1.1 2.4,1.6 l 5.5,-8.3 c -0.8,-0.5 -1.5,-1 -2.2,-1.5 -31.2,-21.8 -49.1,-50.1 -49.1,-77.8 0,-3.2 0.1,-6.3 0.1,-9.5 l -10,-1 c 0,3.5 -0.1,7 -0.1,10.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_32_person"
                  v-if="chartBodygraphChart.gatesExt[32]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[32])"
                  @mouseover="searchAcPlanets(32)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        32
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 306.3,588.5 -6.9,-0.7 h -3.1 c 0,27.6 17.3,55.4 47.4,76.4 a 1,1 0 0 1 0.5,0.4 l 5.5,-8.3 a 0.52,0.52 0 0 0 -0.4,-0.2 c -27,-19 -42.7,-43.6 -43,-67.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_32_both_chart"
                  v-if="chartBodygraphChart.gatesExt[32]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[32])"
                  @mouseover="searchAcPlanets(32)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        32
                      ) && isChart2Toggle,
                    red_black_35: !isChartToggle
                  }"
                  d="m 306.3,588.5 -6.9,-0.7 h -3.1 c 0,27.6 17.3,55.4 47.4,76.4 a 1,1 0 0 1 0.5,0.4 l 5.5,-8.3 a 0.52,0.52 0 0 0 -0.4,-0.2 c -27,-19 -42.7,-43.6 -43,-67.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_35_person"
                  v-if="chartBodygraphChart.gatesExt[35]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[35])"
                  @mouseover="searchAcPlanets(35)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        35
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 455.8,288.4 c 47.3,0 91.7,28.7 125.9,81.2 l 9.2,-4.3 c -0.1,-0.2 -0.3,-0.4 -0.4,-0.6 C 554.3,309 506.8,278.4 455.8,278.4 h -0.9 v 10 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_35_both_chart"
                  v-if="chartBodygraphChart.gatesExt[35]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[35])"
                  @mouseover="searchAcPlanets(35)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        35
                      ) && isChart2Toggle,
                    red_black_70: !isChartToggle
                  }"
                  d="m 455.8,288.4 c 47.3,0 91.7,28.7 125.9,81.2 l 9.2,-4.3 c -0.1,-0.2 -0.3,-0.4 -0.4,-0.6 C 554.3,309 506.8,278.4 455.8,278.4 h -0.9 v 10 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_12_person"
                  v-if="chartBodygraphChart.gatesExt[12]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[12])"
                  @mouseover="searchAcPlanets(12)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        12
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 455.8,310.4 c 39.9,0 77.6,22.7 107.6,64.5 0.6,0.8 1.1,1.6 1.7,2.4 l 9.2,-4.3 c -0.5,-0.7 -0.9,-1.4 -1.4,-2.1 -32.1,-45.6 -73.2,-70.6 -117.1,-70.6 h -0.9 v 10 a 2.77,2.77 0 0 0 0.9,0.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_12_both_chart"
                  v-if="chartBodygraphChart.gatesExt[12]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[12])"
                  @mouseover="searchAcPlanets(12)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        12
                      ) && isChart2Toggle,
                    red_black_70: !isChartToggle
                  }"
                  d="m 455.8,310.4 c 39.9,0 77.6,22.7 107.6,64.5 0.6,0.8 1.1,1.6 1.7,2.4 l 9.2,-4.3 c -0.5,-0.7 -0.9,-1.4 -1.4,-2.1 -32.1,-45.6 -73.2,-70.6 -117.1,-70.6 h -0.9 v 10 a 2.77,2.77 0 0 0 0.9,0.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_16_person"
                  v-if="chartBodygraphChart.gatesExt[16]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[16])"
                  @mouseover="searchAcPlanets(16)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        16
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 324.8,365.4 9.2,4.3 c 34.8,-52.2 77.2,-80.8 120.9,-81.2 v -10 c -26.6,0.2 -52.7,9.9 -77.4,28.8 -19.2,14.5 -36.8,34 -52.7,58.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_16_both_chart"
                  v-if="chartBodygraphChart.gatesExt[16]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[16])"
                  @mouseover="searchAcPlanets(16)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        16
                      ) && isChart2Toggle,
                    red_black_120: !isChartToggle
                  }"
                  d="m 324.8,365.4 9.2,4.3 c 34.8,-52.2 77.2,-80.8 120.9,-81.2 v -10 c -26.6,0.2 -52.7,9.9 -77.4,28.8 -19.2,14.5 -36.8,34 -52.7,58.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_10_person"
                  v-if="chartBodygraphChart.gatesExt[10]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[10])"
                  @mouseover="searchAcPlanets(10)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        10
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 290.66,428.99 c 27.9,-14.5 65.2,-22.5 104.9,-22.5 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -44.16654,35.16496 -46.60398,53.64104 h 10.32812 C 252.25641,458.41302 265.06,442.29 290.66,428.99 Z"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                  sodipodi:nodetypes="cccsccc"
                />
                <path
                  id="gate_10_both_chart"
                  v-if="chartBodygraphChart.gatesExt[10]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[10])"
                  @mouseover="searchAcPlanets(10)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        10
                      ) && isChart2Toggle,
                    red_black_128: !isChartToggle
                  }"
                  d="m 290.66,428.99 c 27.9,-14.5 65.2,-22.5 104.9,-22.5 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -44.16654,35.16496 -46.60398,53.64104 h 10.32812 C 252.25641,458.41302 265.06,442.29 290.66,428.99 Z"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                  sodipodi:nodetypes="cccsccc"
                />
                <path
                  id="gate_34_person"
                  v-if="chartBodygraphChart.gatesExt[34]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[34])"
                  @mouseover="searchAcPlanets(34)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        34
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 286.46,540.89 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22219,-3.5076 0.92414,-6.65896 h -10.32812 c -0.48613,2.4026 -0.59602,5.75896 -0.59602,6.65896 0,23.2 17,45.3 47.4,61.1 z"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                  sodipodi:nodetypes="cccccccsc"
                />
                <path
                  id="gate_34_both_chart"
                  v-if="chartBodygraphChart.gatesExt[34]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[34])"
                  @mouseover="searchAcPlanets(34)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        34
                      ) && isChart2Toggle,
                    red_black_25: !isChartToggle
                  }"
                  d="m 286.46,540.89 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.24428,-3.88325 0.92414,-6.65896 H 239.65602 C 239.25827,475.0917 239.06,478.89 239.06,479.79 c 0,23.2 17,45.3 47.4,61.1 z"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                  sodipodi:nodetypes="cccccccsc"
                />
                <path
                  id="gate_27_person"
                  v-if="chartBodygraphChart.gatesExt[27]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[27])"
                  @mouseover="searchAcPlanets(27)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        27
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 452.6,617.4 c -27.5,-0.4 -53.3,-4.1 -75.2,-10.3 l -5.3,9.1 c 23,6.7 50.3,10.8 80,11.2 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_27_both_chart"
                  v-if="chartBodygraphChart.gatesExt[27]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[27])"
                  @mouseover="searchAcPlanets(27)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        27
                      ) && isChart2Toggle,
                    red_black_50: !isChartToggle
                  }"
                  d="m 452.6,617.4 c -27.5,-0.4 -53.3,-4.1 -75.2,-10.3 l -5.3,9.1 c 23,6.7 50.3,10.8 80,11.2 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_59_person"
                  v-if="chartBodygraphChart.gatesExt[59]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[59])"
                  @mouseover="searchAcPlanets(59)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        59
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 529.1,609.3 a 291.26,291.26 0 0 1 -43.2,6.9 c -9.2,0.8 -18.7,1.2 -28.3,1.2 h -4.8 l -0.5,10 h 5.4 c 11.2,0 22.1,-0.5 32.5,-1.5 a 287.12,287.12 0 0 0 44.4,-7.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_59_both_chart"
                  v-if="chartBodygraphChart.gatesExt[59]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[59])"
                  @mouseover="searchAcPlanets(59)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        59
                      ) && isChart2Toggle,
                    red_black_135: !isChartToggle
                  }"
                  d="m 529.1,609.3 a 291.26,291.26 0 0 1 -43.2,6.9 c -9.2,0.8 -18.7,1.2 -28.3,1.2 h -4.8 l -0.5,10 h 5.4 c 11.2,0 22.1,-0.5 32.5,-1.5 a 287.12,287.12 0 0 0 44.4,-7.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_50_person"
                  v-if="chartBodygraphChart.gatesExt[50]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[50])"
                  @mouseover="searchAcPlanets(50)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        50
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 314.1,585.1 c 10.3,10.8 26.1,20.1 45.9,27.2 q 5.85,2.1 12,3.9 l 5.3,-9.1 c -5,-1.4 -9.9,-3 -14.5,-4.6 -17,-6.1 -30.8,-13.8 -39.9,-22.5 a 42.73,42.73 0 0 1 -3.6,-3.8 l -8,6 a 37.64,37.64 0 0 1 2.8,2.9 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_50_both_chart"
                  v-if="chartBodygraphChart.gatesExt[50]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[50])"
                  @mouseover="searchAcPlanets(50)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        50
                      ) && isChart2Toggle,
                    red_black_50: !isChartToggle
                  }"
                  d="m 314.1,585.1 c 10.3,10.8 26.1,20.1 45.9,27.2 q 5.85,2.1 12,3.9 l 5.3,-9.1 c -5,-1.4 -9.9,-3 -14.5,-4.6 -17,-6.1 -30.8,-13.8 -39.9,-22.5 a 42.73,42.73 0 0 1 -3.6,-3.8 l -8,6 a 37.64,37.64 0 0 1 2.8,2.9 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_06_person"
                  v-if="chartBodygraphChart.gatesExt[6]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[6])"
                  @mouseover="searchAcPlanets(6)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        6
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 582.1,587.7 a 129.57,129.57 0 0 1 -26.1,13.2 204.47,204.47 0 0 1 -27,8.3 l 5.4,9.2 a 228.06,228.06 0 0 0 25.3,-8 c 16,-6.2 29.1,-13.9 38.4,-22.7 0.9,-0.8 1.7,-1.7 2.6,-2.5 l -8,-6.1 a 72.06,72.06 0 0 1 -10.6,8.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_06_both_chart"
                  v-if="chartBodygraphChart.gatesExt[6]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[6])"
                  @mouseover="searchAcPlanets(6)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        6
                      ) && isChart2Toggle,
                    red_black_135: !isChartToggle
                  }"
                  d="m 582.1,587.7 a 129.57,129.57 0 0 1 -26.1,13.2 204.47,204.47 0 0 1 -27,8.3 l 5.4,9.2 a 228.06,228.06 0 0 0 25.3,-8 c 16,-6.2 29.1,-13.9 38.4,-22.7 0.9,-0.8 1.7,-1.7 2.6,-2.5 l -8,-6.1 a 72.06,72.06 0 0 1 -10.6,8.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_37_person"
                  v-if="chartBodygraphChart.gatesExt[37]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[37])"
                  @mouseover="searchAcPlanets(37)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        37
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 609,537.7 -10.5,2 a 29.12,29.12 0 0 1 5.4,16.3 c 0,8.2 -4,16 -11.3,23.2 l 8,6.1 c 8.5,-8.9 13.3,-18.8 13.3,-29.3 A 35.42,35.42 0 0 0 609,537.7 Z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_37_both_chart"
                  v-if="chartBodygraphChart.gatesExt[37]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[37])"
                  @mouseover="searchAcPlanets(37)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        37
                      ) && isChart2Toggle,
                    red_black_150: !isChartToggle
                  }"
                  d="m 609,537.7 -10.5,2 a 29.12,29.12 0 0 1 5.4,16.3 c 0,8.2 -4,16 -11.3,23.2 l 8,6.1 c 8.5,-8.9 13.3,-18.8 13.3,-29.3 A 35.42,35.42 0 0 0 609,537.7 Z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_26_person"
                  v-if="chartBodygraphChart.gatesExt[26]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[26])"
                  @mouseover="searchAcPlanets(26)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        26
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 457.5,484.4 a 337.29,337.29 0 0 0 -51.6,3.8 l 3,9.8 a 327.49,327.49 0 0 1 48.6,-3.6 c 31.9,0 61.9,4.6 86.2,12.2 l 2.9,-9.8 c -25.1,-7.9 -55.8,-12.4 -89.1,-12.4 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_26_both_chart"
                  v-if="chartBodygraphChart.gatesExt[26]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[26])"
                  @mouseover="searchAcPlanets(26)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        26
                      ) && isChart2Toggle,
                    red_black_128: !isChartToggle
                  }"
                  d="m 457.5,484.4 a 337.29,337.29 0 0 0 -51.6,3.8 l 3,9.8 a 327.49,327.49 0 0 1 48.6,-3.6 c 31.9,0 61.9,4.6 86.2,12.2 l 2.9,-9.8 c -25.1,-7.9 -55.8,-12.4 -89.1,-12.4 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_40_person"
                  v-if="chartBodygraphChart.gatesExt[40]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[40])"
                  @mouseover="searchAcPlanets(40)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        40
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 598.5,539.6 10.5,-2 c -9.5,-17 -31.9,-31.4 -62.5,-40.9 l -2.9,9.8 c 26.2,8.2 45.9,19.8 54.9,33.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_40_both_chart"
                  v-if="chartBodygraphChart.gatesExt[40]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[40])"
                  @mouseover="searchAcPlanets(40)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        40
                      ) && isChart2Toggle,
                    red_black_150: !isChartToggle
                  }"
                  d="m 598.5,539.6 10.5,-2 c -9.5,-17 -31.9,-31.4 -62.5,-40.9 l -2.9,9.8 c 26.2,8.2 45.9,19.8 54.9,33.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_25_person"
                  v-if="chartBodygraphChart.gatesExt[25]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[25])"
                  @mouseover="searchAcPlanets(25)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        25
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 466,422.4 v 10 c 21.8,0 41.6,7.5 56.4,19.8 l 6.9,-7.3 A 98.63,98.63 0 0 0 466,422.4 Z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_25_both_chart"
                  v-if="chartBodygraphChart.gatesExt[25]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[25])"
                  @mouseover="searchAcPlanets(25)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        25
                      ) && isChart2Toggle,
                    red_black_150: !isChartToggle
                  }"
                  d="m 466,422.4 v 10 c 21.8,0 41.6,7.5 56.4,19.8 l 6.9,-7.3 A 98.63,98.63 0 0 0 466,422.4 Z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_51_person"
                  v-if="chartBodygraphChart.gatesExt[51]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[51])"
                  @mouseover="searchAcPlanets(51)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        51
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 529.3,444.9 -6.9,7.3 c 16.3,13.5 26.6,32.8 26.6,54.2 h 10 c 0,-24.2 -11.4,-46.1 -29.7,-61.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_51_both_chart"
                  v-if="chartBodygraphChart.gatesExt[51]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[51])"
                  @mouseover="searchAcPlanets(51)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        51
                      ) && isChart2Toggle,
                    red_black_150: !isChartToggle
                  }"
                  d="m 529.3,444.9 -6.9,7.3 c 16.3,13.5 26.6,32.8 26.6,54.2 h 10 c 0,-24.2 -11.4,-46.1 -29.7,-61.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_44_person"
                  v-if="chartBodygraphChart.gatesExt[44]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[44])"
                  @mouseover="searchAcPlanets(44)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        44
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 311,555.9 c 0,-26.3 41.7,-49.3 98.2,-57.9 l -3.6,-9.7 c -61.5,9.6 -104.6,35.9 -104.6,67.6 0,7.9 2.7,15.5 7.7,22.6 l 10,-3.3 c -5,-6.1 -7.7,-12.6 -7.7,-19.3 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_44_both_chart"
                  v-if="chartBodygraphChart.gatesExt[44]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[44])"
                  @mouseover="searchAcPlanets(44)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        44
                      ) && isChart2Toggle,
                    red_black_128: !isChartToggle
                  }"
                  d="m 311,555.9 c 0,-26.3 41.4,-49.3 97.9,-57.9 l -3,-9.8 c -61.5,9.6 -104.9,36 -104.9,67.7 0,7.9 2.7,15.5 7.7,22.6 l 10,-3.3 c -5,-6.1 -7.7,-12.6 -7.7,-19.3 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                  sodipodi:nodetypes="sccsccs"
                />
              </g>
              <g
                id="Слой_10-2"
                style="display:inline"
                data-name="Слой 10"
                transform="translate(31.140015,17.409983)"
              >
                <rect
                  id="gate_42_person"
                  v-if="chartBodygraphChart.gatesExt[42]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[42])"
                  @mouseover="searchAcPlanets(42)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        42
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  x="383.35999"
                  y="570.89001"
                  width="10"
                  height="57"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_42_both_chart"
                  v-if="chartBodygraphChart.gatesExt[42]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[42])"
                  @mouseover="searchAcPlanets(42)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        42
                      ) && isChart2Toggle,
                    red_black_90: !isChartToggle
                  }"
                  x="383.35999"
                  y="570.89001"
                  width="10"
                  height="57"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_05_person"
                  v-if="chartBodygraphChart.gatesExt[5]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[5])"
                  @mouseover="searchAcPlanets(5)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        5
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  x="383.35999"
                  y="483.89001"
                  width="10"
                  height="87"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_05_both_chart"
                  v-if="chartBodygraphChart.gatesExt[5]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[5])"
                  @mouseover="searchAcPlanets(5)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        5
                      ) && isChart2Toggle,
                    red_black_90: !isChartToggle
                  }"
                  x="383.35999"
                  y="483.89001"
                  width="10"
                  height="87"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_15_person"
                  v-if="chartBodygraphChart.gatesExt[15]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[15])"
                  @mouseover="searchAcPlanets(15)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        15
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  x="383.35999"
                  y="401.39001"
                  width="10"
                  height="82.5"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_15_both_chart"
                  v-if="chartBodygraphChart.gatesExt[15]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[15])"
                  @mouseover="searchAcPlanets(15)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        15
                      ) && isChart2Toggle,
                    red_black_90: !isChartToggle
                  }"
                  x="383.35999"
                  y="401.39001"
                  width="10"
                  height="82.5"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_07_person"
                  v-if="chartBodygraphChart.gatesExt[7]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[7])"
                  @mouseover="searchAcPlanets(7)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        7
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  x="383.35999"
                  y="339.89001"
                  width="10"
                  height="61.5"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_07_both_chart"
                  v-if="chartBodygraphChart.gatesExt[7]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[7])"
                  @mouseover="searchAcPlanets(7)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        7
                      ) && isChart2Toggle,
                    red_black_90: !isChartToggle
                  }"
                  x="383.35999"
                  y="339.89001"
                  width="10"
                  height="61.5"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_31_person"
                  v-if="chartBodygraphChart.gatesExt[31]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[31])"
                  @mouseover="searchAcPlanets(31)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        31
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  x="383.35999"
                  y="270.89001"
                  width="10"
                  height="69"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_31_both_chart"
                  v-if="chartBodygraphChart.gatesExt[31]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[31])"
                  @mouseover="searchAcPlanets(31)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        31
                      ) && isChart2Toggle,
                    red_black_90: !isChartToggle
                  }"
                  x="383.35999"
                  y="270.89001"
                  width="10"
                  height="69"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_62_person"
                  v-if="chartBodygraphChart.gatesExt[62]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[62])"
                  @mouseover="searchAcPlanets(62)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        62
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  x="383.35999"
                  y="214.89"
                  width="10"
                  height="56"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_62_both_chart"
                  v-if="chartBodygraphChart2.gatesExt[62]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[62])"
                  @mouseover="searchAcPlanets(62)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        62
                      ) && isChart2Toggle,
                    red_black__25: !isChartToggle
                  }"
                  x="383.35999"
                  y="214.89"
                  width="10"
                  height="56"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_17_person"
                  v-if="chartBodygraphChart.gatesExt[17]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[17])"
                  @mouseover="searchAcPlanets(17)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        17
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  x="383.35999"
                  y="145.39"
                  width="10"
                  height="69.5"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_17_both_chart"
                  v-if="chartBodygraphChart.gatesExt[17]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[17])"
                  @mouseover="searchAcPlanets(17)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        17
                      ) && isChart2Toggle,
                    red_black_90: !isChartToggle
                  }"
                  x="383.35999"
                  y="145.39"
                  width="10"
                  height="69.5"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_64_person"
                  v-if="chartBodygraphChart.gatesExt[64]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[64])"
                  @mouseover="searchAcPlanets(64)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        64
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  x="383.35999"
                  y="73.889999"
                  width="10"
                  height="32"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_64_both_chart"
                  v-if="chartBodygraphChart.gatesExt[64]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[64])"
                  @mouseover="searchAcPlanets(64)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        64
                      ) && isChart2Toggle,
                    red_black_90: !isChartToggle
                  }"
                  x="383.35999"
                  y="73.889999"
                  width="10"
                  height="32"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_60_person"
                  v-if="chartBodygraphChart.gatesExt[60]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[60])"
                  @mouseover="searchAcPlanets(60)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        60
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  x="403.85999"
                  y="627.89001"
                  width="10"
                  height="79"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_60_both_chart"
                  v-if="chartBodygraphChart.gatesExt[60]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[60])"
                  @mouseover="searchAcPlanets(60)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        60
                      ) && isChart2Toggle,
                    red_black_90: !isChartToggle
                  }"
                  x="403.85999"
                  y="627.89001"
                  width="10"
                  height="79"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_03_person"
                  v-if="chartBodygraphChart.gatesExt[3]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[3])"
                  @mouseover="searchAcPlanets(3)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        3
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  x="403.85999"
                  y="570.89001"
                  width="10"
                  height="57"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_03_both_chart"
                  v-if="chartBodygraphChart.gatesExt[3]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[3])"
                  @mouseover="searchAcPlanets(3)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        3
                      ) && isChart2Toggle,
                    red_black_90: !isChartToggle
                  }"
                  x="403.85999"
                  y="570.89001"
                  width="10"
                  height="57"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_14_person"
                  v-if="chartBodygraphChart.gatesExt[14]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[14])"
                  @mouseover="searchAcPlanets(14)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        14
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  x="403.85999"
                  y="483.89001"
                  width="10"
                  height="87"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_14_both_chart"
                  v-if="chartBodygraphChart2.gatesExt[14]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[14])"
                  @mouseover="searchAcPlanets(14)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        14
                      ) && isChart2Toggle,
                    red_black_90: !isChartToggle
                  }"
                  x="403.85999"
                  y="483.89001"
                  width="10"
                  height="87"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_02_person"
                  v-if="chartBodygraphChart.gatesExt[2]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[2])"
                  @mouseover="searchAcPlanets(2)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        2
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  x="403.85999"
                  y="401.39001"
                  width="10"
                  height="82.5"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_02_both_chart"
                  v-if="chartBodygraphChart.gatesExt[2]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[2])"
                  @mouseover="searchAcPlanets(2)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        2
                      ) && isChart2Toggle,
                    red_black_90: !isChartToggle
                  }"
                  x="403.85999"
                  y="401.39001"
                  width="10"
                  height="82.5"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_01_person"
                  v-if="chartBodygraphChart.gatesExt[1]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[1])"
                  @mouseover="searchAcPlanets(1)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        1
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  x="403.85999"
                  y="339.89001"
                  width="10"
                  height="61.5"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_01_both_chart"
                  v-if="chartBodygraphChart.gatesExt[1]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[1])"
                  @mouseover="searchAcPlanets(1)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        1
                      ) && isChart2Toggle,
                    red_black_90: !isChartToggle
                  }"
                  x="403.85999"
                  y="339.89001"
                  width="10"
                  height="61.5"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_08_person"
                  v-if="chartBodygraphChart.gatesExt[8]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[8])"
                  @mouseover="searchAcPlanets(8)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        8
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  x="403.85999"
                  y="270.89001"
                  width="10"
                  height="69"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_08_both_chart"
                  v-if="chartBodygraphChart.gatesExt[8]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[8])"
                  @mouseover="searchAcPlanets(8)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        8
                      ) && isChart2Toggle,
                    red_black__25: !isChartToggle
                  }"
                  x="403.85999"
                  y="270.89001"
                  width="10"
                  height="69"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_08__both_chart"
                  v-if="chartBodygraphChart.gatesExt[8]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[8])"
                  @mouseover="searchAcPlanets(8)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1: this.isDefinedObj(
                      chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                      8
                    )
                  }"
                  x="403.85999"
                  y="270.89001"
                  width="10"
                  height="69"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_23_person"
                  v-if="chartBodygraphChart.gatesExt[23]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[23])"
                  @mouseover="searchAcPlanets(23)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        23
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  x="403.85999"
                  y="214.89"
                  width="10"
                  height="56"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_23_both_chart"
                  v-if="chartBodygraphChart.gatesExt[23]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[23])"
                  @mouseover="searchAcPlanets(23)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        23
                      ) && isChart2Toggle,
                    red_black_90: !isChartToggle
                  }"
                  x="403.85999"
                  y="214.89"
                  width="10"
                  height="56"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_43_person"
                  v-if="chartBodygraphChart.gatesExt[43]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[43])"
                  @mouseover="searchAcPlanets(43)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        43
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  x="403.85999"
                  y="145.39"
                  width="10"
                  height="69.5"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_43_both_chart"
                  v-if="chartBodygraphChart.gatesExt[43]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[43])"
                  @mouseover="searchAcPlanets(43)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        43
                      ) && isChart2Toggle,
                    red_black_90: !isChartToggle
                  }"
                  x="403.85999"
                  y="145.39"
                  width="10"
                  height="69.5"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_61_person"
                  v-if="chartBodygraphChart.gatesExt[61]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[61])"
                  @mouseover="searchAcPlanets(61)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        61
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  x="403.85999"
                  y="73.889999"
                  width="10"
                  height="32"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_61_both_chart"
                  v-if="chartBodygraphChart.gatesExt[61]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[61])"
                  @mouseover="searchAcPlanets(61)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        61
                      ) && isChart2Toggle,
                    red_black_90: !isChartToggle
                  }"
                  x="403.85999"
                  y="73.889999"
                  width="10"
                  height="32"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_52_person"
                  v-if="chartBodygraphChart.gatesExt[52]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[52])"
                  @mouseover="searchAcPlanets(52)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        52
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  x="424.35999"
                  y="627.89001"
                  width="10"
                  height="79"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_52_both_chart"
                  v-if="chartBodygraphChart.gatesExt[52]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[52])"
                  @mouseover="searchAcPlanets(52)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        52
                      ) && isChart2Toggle,
                    red_black_90: !isChartToggle
                  }"
                  x="424.35999"
                  y="627.89001"
                  width="10"
                  height="79"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_09_person"
                  v-if="chartBodygraphChart.gatesExt[9]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[9])"
                  @mouseover="searchAcPlanets(9)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        9
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  x="424.35999"
                  y="570.89001"
                  width="10"
                  height="57"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_09_both_chart"
                  v-if="chartBodygraphChart.gatesExt[9]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[9])"
                  @mouseover="searchAcPlanets(9)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        9
                      ) && isChart2Toggle,
                    red_black_90: !isChartToggle
                  }"
                  x="424.35999"
                  y="570.89001"
                  width="10"
                  height="57"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_29_person"
                  v-if="chartBodygraphChart.gatesExt[29]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[29])"
                  @mouseover="searchAcPlanets(29)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        29
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  x="424.35999"
                  y="483.89001"
                  width="10"
                  height="87"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_29_both_chart"
                  v-if="chartBodygraphChart.gatesExt[29]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[29])"
                  @mouseover="searchAcPlanets(29)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        29
                      ) && isChart2Toggle,
                    red_black_90: !isChartToggle
                  }"
                  x="424.35999"
                  y="483.89001"
                  width="10"
                  height="87"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_46_person"
                  v-if="chartBodygraphChart.gatesExt[46]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[46])"
                  @mouseover="searchAcPlanets(46)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        46
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  x="424.35999"
                  y="401.39001"
                  width="10"
                  height="82.5"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_46_both_chart"
                  v-if="chartBodygraphChart.gatesExt[46]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[46])"
                  @mouseover="searchAcPlanets(46)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        46
                      ) && isChart2Toggle,
                    red_black_90: !isChartToggle
                  }"
                  x="424.35999"
                  y="401.39001"
                  width="10"
                  height="82.5"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_13_person"
                  v-if="chartBodygraphChart.gatesExt[13]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[13])"
                  @mouseover="searchAcPlanets(13)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        13
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  x="424.35999"
                  y="339.89001"
                  width="10"
                  height="61.5"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_13_both_chart"
                  v-if="chartBodygraphChart.gatesExt[13]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[13])"
                  @mouseover="searchAcPlanets(13)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        13
                      ) && isChart2Toggle,
                    red_black_90: !isChartToggle
                  }"
                  x="424.35999"
                  y="339.89001"
                  width="10"
                  height="61.5"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_33_person"
                  v-if="chartBodygraphChart.gatesExt[33]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[33])"
                  @mouseover="searchAcPlanets(33)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        33
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  x="424.35999"
                  y="270.89001"
                  width="10"
                  height="69"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_33_both_chart"
                  v-if="chartBodygraphChart.gatesExt[33]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[33])"
                  @mouseover="searchAcPlanets(33)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        33
                      ) && isChart2Toggle,
                    red_black_90: !isChartToggle
                  }"
                  x="424.35999"
                  y="270.89001"
                  width="10"
                  height="69"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_56_person"
                  v-if="chartBodygraphChart.gatesExt[56]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[56])"
                  @mouseover="searchAcPlanets(56)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        56
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  x="424.35999"
                  y="214.89"
                  width="10"
                  height="56"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_56_both_chart"
                  v-if="chartBodygraphChart.gatesExt[56]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[56])"
                  @mouseover="searchAcPlanets(56)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        56
                      ) && isChart2Toggle,
                    red_black_90: !isChartToggle
                  }"
                  x="424.35999"
                  y="214.89"
                  width="10"
                  height="56"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_11_person"
                  v-if="chartBodygraphChart.gatesExt[11]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[11])"
                  @mouseover="searchAcPlanets(11)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        11
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  x="424.35999"
                  y="145.39"
                  width="10"
                  height="69.5"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_11_both_chart"
                  v-if="chartBodygraphChart.gatesExt[11]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[11])"
                  @mouseover="searchAcPlanets(11)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        11
                      ) && isChart2Toggle,
                    red_black_90: !isChartToggle
                  }"
                  x="424.35999"
                  y="145.39"
                  width="10"
                  height="69.5"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_04_person"
                  v-if="chartBodygraphChart.gatesExt[4]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[4])"
                  @mouseover="searchAcPlanets(4)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        4
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  x="424.35999"
                  y="105.89"
                  width="10"
                  height="39.5"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_04_both_chart"
                  v-if="chartBodygraphChart.gatesExt[4]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[4])"
                  @mouseover="searchAcPlanets(4)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        4
                      ) && isChart2Toggle,
                    red_black_90: !isChartToggle
                  }"
                  x="424.35999"
                  y="105.89"
                  width="10"
                  height="39.5"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_63_person"
                  v-if="chartBodygraphChart.gatesExt[63]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[63])"
                  @mouseover="searchAcPlanets(63)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        63
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  x="424.35999"
                  y="73.889999"
                  width="10"
                  height="32"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_63_both_chart"
                  v-if="chartBodygraphChart.gatesExt[63]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[63])"
                  @mouseover="searchAcPlanets(63)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        63
                      ) && isChart2Toggle,
                    red_black_90: !isChartToggle
                  }"
                  x="424.35999"
                  y="73.889999"
                  width="10"
                  height="32"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_53_person"
                  v-if="chartBodygraphChart.gatesExt[53]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[53])"
                  @mouseover="searchAcPlanets(53)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        53
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  x="383.35999"
                  y="627.89001"
                  width="10"
                  height="79"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_53_both_chart"
                  v-if="chartBodygraphChart.gatesExt[53]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[53])"
                  @mouseover="searchAcPlanets(53)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        53
                      ) && isChart2Toggle,
                    red_black_90: !isChartToggle
                  }"
                  x="383.35999"
                  y="627.89001"
                  width="10"
                  height="79"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_47_person"
                  v-if="chartBodygraphChart.gatesExt[47]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[47])"
                  @mouseover="searchAcPlanets(47)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        47
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  x="383.35999"
                  y="105.89"
                  width="10"
                  height="39.5"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_47_both_chart"
                  v-if="chartBodygraphChart.gatesExt[47]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[47])"
                  @mouseover="searchAcPlanets(47)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        47
                      ) && isChart2Toggle,
                    red_black_90: !isChartToggle
                  }"
                  x="383.35999"
                  y="105.89"
                  width="10"
                  height="39.5"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_24_person"
                  v-if="chartBodygraphChart.gatesExt[24]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[24])"
                  @mouseover="searchAcPlanets(24)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityOnly,
                        24
                      ) && isChart2Toggle,
                    person_color: !isChartToggle && isChart2Toggle
                  }"
                  x="403.85999"
                  y="105.89"
                  width="10"
                  height="39.5"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_24_both_chart"
                  v-if="chartBodygraphChart.gatesExt[24]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[24])"
                  @mouseover="searchAcPlanets(24)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
                        24
                      ) && isChart2Toggle,
                    red_black_90: !isChartToggle
                  }"
                  x="403.85999"
                  y="105.89"
                  width="10"
                  height="39.5"
                  style="fill:#4caf50;opacity:0;display:none"
                />
              </g>
            </g>
            <g
              id="layer7"
              inkscape:groupmode="layer"
              inkscape:label="Gates Design"
              style="display:inline"
              sodipodi:insensitive="true"
            >
              <g
                id="Слой_15"
                style="display:inline"
                data-name="Слой 15"
                transform="translate(31.134997,17.409983)"
              >
                <path
                  id="gate_41_design"
                  v-if="chartBodygraphChart.gatesExt[41]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[41])"
                  @mouseover="searchAcPlanets(41)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        41
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 586.9,704.9 -5.7,-8.3 c -33.9,20.7 -77.5,33.1 -121.8,33.7 l 0.5,10 a 266.48,266.48 0 0 0 90.5,-17.3 225.62,225.62 0 0 0 36.5,-18.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_41_person2"
                  v-if="chartBodygraphChart2.gatesExt[41]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[41])"
                  @mouseover="searchAcPlanets(41)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        41
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 586.9,704.9 -5.7,-8.3 c -33.9,20.7 -77.5,33.1 -121.8,33.7 l 0.5,10 a 266.48,266.48 0 0 0 90.5,-17.3 225.62,225.62 0 0 0 36.5,-18.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_41_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[41]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[41])"
                  @mouseover="searchAcPlanets(41)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        41
                      ) && isChartToggle,
                    red_black__25: !isChart2Toggle
                  }"
                  d="m 586.9,704.9 -5.7,-8.3 c -33.9,20.7 -77.5,33.1 -121.8,33.7 l 0.5,10 a 266.48,266.48 0 0 0 90.5,-17.3 225.62,225.62 0 0 0 36.5,-18.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_30_design"
                  v-if="chartBodygraphChart.gatesExt[30]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[30])"
                  @mouseover="searchAcPlanets(30)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        30
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 653.6,582.8 -10,1.1 c 0.3,6.8 0.6,13.7 0.7,20.6 0.4,22.9 -10.2,46 -30.7,66.8 a 172.56,172.56 0 0 1 -32.4,25.4 l 5.7,8.3 a 178.16,178.16 0 0 0 33.8,-26.6 c 22.4,-22.8 34,-48.3 33.6,-74 -0.1,-7.3 -0.4,-14.5 -0.7,-21.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_30_person2"
                  v-if="chartBodygraphChart2.gatesExt[30]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[30])"
                  @mouseover="searchAcPlanets(30)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        30
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 653.6,582.8 -10,1.1 c 0.3,6.8 0.6,13.7 0.7,20.6 0.4,22.9 -10.2,46 -30.7,66.8 a 172.56,172.56 0 0 1 -32.4,25.4 l 5.7,8.3 a 178.16,178.16 0 0 0 33.8,-26.6 c 22.4,-22.8 34,-48.3 33.6,-74 -0.1,-7.3 -0.4,-14.5 -0.7,-21.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_30_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[30]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[30])"
                  @mouseover="searchAcPlanets(30)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        30
                      ) && isChartToggle,
                    red_black__35: !isChart2Toggle
                  }"
                  d="m 653.6,582.8 -10,1.1 c 0.3,6.8 0.6,13.7 0.7,20.6 0.4,22.9 -10.2,46 -30.7,66.8 a 172.56,172.56 0 0 1 -32.4,25.4 l 5.7,8.3 a 178.16,178.16 0 0 0 33.8,-26.6 c 22.4,-22.8 34,-48.3 33.6,-74 -0.1,-7.3 -0.4,-14.5 -0.7,-21.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_55_design"
                  v-if="chartBodygraphChart.gatesExt[55]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[55])"
                  @mouseover="searchAcPlanets(55)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        55
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 635.9,595.8 c -0.1,-3.1 -0.1,-6.2 -0.2,-9.3 l -9.9,3 c 0.1,2.1 0.1,4.3 0.1,6.4 0.3,20.6 -9.2,41.4 -27.6,60.1 a 152.5,152.5 0 0 1 -28.5,22.4 l 5.3,8.5 A 165.59,165.59 0 0 0 605.4,663 c 20.3,-20.6 30.9,-43.9 30.5,-67.2 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_55_person2"
                  v-if="chartBodygraphChart2.gatesExt[55]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[55])"
                  @mouseover="searchAcPlanets(55)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        55
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 635.9,595.8 c -0.1,-3.1 -0.1,-6.2 -0.2,-9.3 l -9.9,3 c 0.1,2.1 0.1,4.3 0.1,6.4 0.3,20.6 -9.2,41.4 -27.6,60.1 a 152.5,152.5 0 0 1 -28.5,22.4 l 5.3,8.5 A 165.59,165.59 0 0 0 605.4,663 c 20.3,-20.6 30.9,-43.9 30.5,-67.2 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_55_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[55]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[55])"
                  @mouseover="searchAcPlanets(55)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        55
                      ) && isChartToggle,
                    red_black__35: !isChart2Toggle
                  }"
                  d="m 635.9,595.8 c -0.1,-3.1 -0.1,-6.2 -0.2,-9.3 l -9.9,3 c 0.1,2.1 0.1,4.3 0.1,6.4 0.3,20.6 -9.2,41.4 -27.6,60.1 a 152.5,152.5 0 0 1 -28.5,22.4 l 5.3,8.5 A 165.59,165.59 0 0 0 605.4,663 c 20.3,-20.6 30.9,-43.9 30.5,-67.2 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_49_design"
                  v-if="chartBodygraphChart.gatesExt[49]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[49])"
                  @mouseover="searchAcPlanets(49)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        49
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 615.4,587.7 -10,0.2 c 0.3,18 -8.1,36.2 -24.2,52.6 a 136.22,136.22 0 0 1 -23.4,18.7 l 5.4,8.6 a 147.83,147.83 0 0 0 25.2,-20.2 c 17.9,-18.5 27.3,-39.2 27,-59.9 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_49_person2"
                  v-if="chartBodygraphChart2.gatesExt[49]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[49])"
                  @mouseover="searchAcPlanets(49)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        49
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 615.4,587.7 -10,0.2 c 0.3,18 -8.1,36.2 -24.2,52.6 a 136.22,136.22 0 0 1 -23.4,18.7 l 5.4,8.6 a 147.83,147.83 0 0 0 25.2,-20.2 c 17.9,-18.5 27.3,-39.2 27,-59.9 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_49_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[49]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[49])"
                  @mouseover="searchAcPlanets(49)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        49
                      ) && isChartToggle,
                    red_black__35: !isChart2Toggle
                  }"
                  d="m 615.4,587.7 -10,0.2 c 0.3,18 -8.1,36.2 -24.2,52.6 a 136.22,136.22 0 0 1 -23.4,18.7 l 5.4,8.6 a 147.83,147.83 0 0 0 25.2,-20.2 c 17.9,-18.5 27.3,-39.2 27,-59.9 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_39_design"
                  v-if="chartBodygraphChart.gatesExt[39]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[39])"
                  @mouseover="searchAcPlanets(39)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        39
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 575.1,686.9 -5.3,-8.5 c -31.6,19.5 -72.5,30.9 -114,30.9 v 10 a 239.69,239.69 0 0 0 85.8,-15.8 190,190 0 0 0 33.5,-16.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_39_person2"
                  v-if="chartBodygraphChart2.gatesExt[39]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[39])"
                  @mouseover="searchAcPlanets(39)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        39
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 575.1,686.9 -5.3,-8.5 c -31.6,19.5 -72.5,30.9 -114,30.9 v 10 a 239.69,239.69 0 0 0 85.8,-15.8 190,190 0 0 0 33.5,-16.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_39_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[39]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[39])"
                  @mouseover="searchAcPlanets(39)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        39
                      ) && isChartToggle,
                    red_black__25: !isChart2Toggle
                  }"
                  d="m 575.1,686.9 -5.3,-8.5 c -31.6,19.5 -72.5,30.9 -114,30.9 v 10 a 239.69,239.69 0 0 0 85.8,-15.8 190,190 0 0 0 33.5,-16.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_19_design"
                  v-if="chartBodygraphChart.gatesExt[19]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[19])"
                  @mouseover="searchAcPlanets(19)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        19
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 557.3,659.4 c -27.9,17.6 -64.4,27.9 -101.4,27.9 v 10 c 39.5,0 77.5,-10.7 107,-29.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_19_person2"
                  v-if="chartBodygraphChart2.gatesExt[19]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[19])"
                  @mouseover="searchAcPlanets(19)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        19
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 557.3,659.4 c -27.9,17.6 -64.4,27.9 -101.4,27.9 v 10 c 39.5,0 77.5,-10.7 107,-29.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_19_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[19]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[19])"
                  @mouseover="searchAcPlanets(19)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        19
                      ) && isChartToggle,
                    red_black__25: !isChart2Toggle
                  }"
                  d="m 557.3,659.4 c -27.9,17.6 -64.4,27.9 -101.4,27.9 v 10 c 39.5,0 77.5,-10.7 107,-29.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_58_design"
                  v-if="chartBodygraphChart.gatesExt[58]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[58])"
                  @mouseover="searchAcPlanets(58)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        58
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 319.7,701.5 c 37.6,25.1 85.6,38.8 135.7,38.9 v -10 c -48.1,-0.1 -94.1,-13.3 -130.1,-37.3 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_58_person2"
                  v-if="chartBodygraphChart2.gatesExt[58]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[58])"
                  @mouseover="searchAcPlanets(58)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        58
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 319.7,701.5 c 37.6,25.1 85.6,38.8 135.7,38.9 v -10 c -48.1,-0.1 -94.1,-13.3 -130.1,-37.3 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_58_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[58]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[58])"
                  @mouseover="searchAcPlanets(58)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        58
                      ) && isChartToggle,
                    red_black_25: !isChart2Toggle
                  }"
                  d="m 319.7,701.5 c 37.6,25.1 85.6,38.8 135.7,38.9 v -10 c -48.1,-0.1 -94.1,-13.3 -130.1,-37.3 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_38_design"
                  v-if="chartBodygraphChart.gatesExt[38]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[38])"
                  @mouseover="searchAcPlanets(38)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        38
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 455.3,709.4 c -43.7,-0.1 -85.6,-12.2 -118.2,-34.2 l -5.5,8.3 c 34.2,23 78,35.7 123.7,35.8 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_38_person2"
                  v-if="chartBodygraphChart2.gatesExt[38]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[38])"
                  @mouseover="searchAcPlanets(38)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        38
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 455.3,709.4 c -43.7,-0.1 -85.6,-12.2 -118.2,-34.2 l -5.5,8.3 c 34.2,23 78,35.7 123.7,35.8 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_38_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[38]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[38])"
                  @mouseover="searchAcPlanets(38)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        38
                      ) && isChartToggle,
                    red_black_25: !isChart2Toggle
                  }"
                  d="m 455.3,709.4 c -43.7,-0.1 -85.6,-12.2 -118.2,-34.2 l -5.5,8.3 c 34.2,23 78,35.7 123.7,35.8 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_54_design"
                  v-if="chartBodygraphChart.gatesExt[54]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[54])"
                  @mouseover="searchAcPlanets(54)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        54
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  d="M 455.2,687.4 C 416,687.3 378.6,676.2 349.7,656.2 l -5.5,8.3 c 30.5,21.1 69.9,32.7 111,32.8 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_54_person2"
                  v-if="chartBodygraphChart2.gatesExt[54]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[54])"
                  @mouseover="searchAcPlanets(54)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        54
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  d="M 455.2,687.4 C 416,687.3 378.6,676.2 349.7,656.2 l -5.5,8.3 c 30.5,21.1 69.9,32.7 111,32.8 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_54_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[54]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[54])"
                  @mouseover="searchAcPlanets(54)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        54
                      ) && isChartToggle,
                    red_black_25: !isChart2Toggle
                  }"
                  d="M 455.2,687.4 C 416,687.3 378.6,676.2 349.7,656.2 l -5.5,8.3 c 30.5,21.1 69.9,32.7 111,32.8 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_36_design"
                  v-if="chartBodygraphChart.gatesExt[36]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[36])"
                  @mouseover="searchAcPlanets(36)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        36
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 582,369.9 c 1.8,2.7 3.5,5.5 5.2,8.3 32.6,54.1 52.3,126.5 56.4,205.7 l 10,-1.1 c -4.2,-80.6 -24.4,-154.4 -57.8,-209.7 -1.6,-2.7 -3.2,-5.2 -4.9,-7.8 l -9.2,4.3 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_36_person2"
                  v-if="chartBodygraphChart2.gatesExt[36]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[36])"
                  @mouseover="searchAcPlanets(36)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        36
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 582,369.9 c 1.8,2.7 3.5,5.5 5.2,8.3 32.6,54.1 52.3,126.5 56.4,205.7 l 10,-1.1 c -4.2,-80.6 -24.4,-154.4 -57.8,-209.7 -1.6,-2.7 -3.2,-5.2 -4.9,-7.8 l -9.2,4.3 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_36_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[36]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[36])"
                  @mouseover="searchAcPlanets(36)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        36
                      ) && isChartToggle,
                    red_black_70: !isChart2Toggle
                  }"
                  d="m 582,369.9 c 1.8,2.7 3.5,5.5 5.2,8.3 32.6,54.1 52.3,126.5 56.4,205.7 l 10,-1.1 c -4.2,-80.6 -24.4,-154.4 -57.8,-209.7 -1.6,-2.7 -3.2,-5.2 -4.9,-7.8 l -9.2,4.3 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_48_design"
                  v-if="chartBodygraphChart.gatesExt[48]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[48])"
                  @mouseover="searchAcPlanets(48)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        48
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 314.7,381.8 c -33.2,57.3 -53.5,129.8 -56.9,201.8 l 10,1 c 3.2,-70.6 23,-141.7 55.5,-197.8 3.5,-6 7,-11.7 10.6,-17.1 l -9.2,-4.3 c -3.4,5.2 -6.7,10.7 -10,16.4 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_48_person2"
                  v-if="chartBodygraphChart2.gatesExt[48]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[48])"
                  @mouseover="searchAcPlanets(48)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        48
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 314.7,381.8 c -33.2,57.3 -53.5,129.8 -56.9,201.8 l 10,1 c 3.2,-70.6 23,-141.7 55.5,-197.8 3.5,-6 7,-11.7 10.6,-17.1 l -9.2,-4.3 c -3.4,5.2 -6.7,10.7 -10,16.4 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_48_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[48]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[48])"
                  @mouseover="searchAcPlanets(48)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        48
                      ) && isChartToggle,
                    red_black_120: !isChart2Toggle
                  }"
                  d="m 314.7,381.8 c -33.2,57.3 -53.5,129.8 -56.9,201.8 l 10,1 c 3.2,-70.6 23,-141.7 55.5,-197.8 3.5,-6 7,-11.7 10.6,-17.1 l -9.2,-4.3 c -3.4,5.2 -6.7,10.7 -10,16.4 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_45_design"
                  v-if="chartBodygraphChart.gatesExt[45]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[45])"
                  @mouseover="searchAcPlanets(45)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        45
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 456.5,320.4 v 10 c 32.5,0 61.4,23.8 79.4,60.5 l 9.1,-4.2 a 158.69,158.69 0 0 0 -11.1,-19.3 c -20.6,-30.3 -48.1,-47 -77.4,-47 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_45_person2"
                  v-if="chartBodygraphChart2.gatesExt[45]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[45])"
                  @mouseover="searchAcPlanets(45)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        45
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 456.5,320.4 v 10 c 32.5,0 61.4,23.8 79.4,60.5 l 9.1,-4.2 a 158.69,158.69 0 0 0 -11.1,-19.3 c -20.6,-30.3 -48.1,-47 -77.4,-47 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_45_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[45]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[45])"
                  @mouseover="searchAcPlanets(45)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        45
                      ) && isChartToggle,
                    red_black_70: !isChart2Toggle
                  }"
                  d="m 456.5,320.4 v 10 c 32.5,0 61.4,23.8 79.4,60.5 l 9.1,-4.2 a 158.69,158.69 0 0 0 -11.1,-19.3 c -20.6,-30.3 -48.1,-47 -77.4,-47 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_21_design"
                  v-if="chartBodygraphChart.gatesExt[21]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[21])"
                  @mouseover="searchAcPlanets(21)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        21
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 555,478.4 h 10 c 0,-33.3 -7,-65.1 -20.1,-91.7 l -9.1,4.2 c 12.1,24.5 19.2,54.8 19.2,87.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_21_person2"
                  v-if="chartBodygraphChart2.gatesExt[21]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[21])"
                  @mouseover="searchAcPlanets(21)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        21
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 555,478.4 h 10 c 0,-33.3 -7,-65.1 -20.1,-91.7 l -9.1,4.2 c 12.1,24.5 19.2,54.8 19.2,87.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_21_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[21]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[21])"
                  @mouseover="searchAcPlanets(21)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        21
                      ) && isChartToggle,
                    red_black_70: !isChart2Toggle
                  }"
                  d="m 555,478.4 h 10 c 0,-33.3 -7,-65.1 -20.1,-91.7 l -9.1,4.2 c 12.1,24.5 19.2,54.8 19.2,87.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_22_design"
                  v-if="chartBodygraphChart.gatesExt[22]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[22])"
                  @mouseover="searchAcPlanets(22)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        22
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 574.3,391.4 c 31.1,51.6 49.3,121.8 51.4,198.1 l 9.9,-3 c -2.6,-77 -21.2,-147.8 -52.8,-200.2 -2.8,-4.6 -5.6,-9 -8.6,-13.3 l -9.2,4.3 c 3.3,4.5 6.3,9.2 9.3,14.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_22_person2"
                  v-if="chartBodygraphChart2.gatesExt[22]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[22])"
                  @mouseover="searchAcPlanets(22)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        22
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 574.3,391.4 c 31.1,51.6 49.3,121.8 51.4,198.1 l 9.9,-3 c -2.6,-77 -21.2,-147.8 -52.8,-200.2 -2.8,-4.6 -5.6,-9 -8.6,-13.3 l -9.2,4.3 c 3.3,4.5 6.3,9.2 9.3,14.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_22_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[22]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[22])"
                  @mouseover="searchAcPlanets(22)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        22
                      ) && isChartToggle,
                    red_black_70: !isChart2Toggle
                  }"
                  d="m 574.3,391.4 c 31.1,51.6 49.3,121.8 51.4,198.1 l 9.9,-3 c -2.6,-77 -21.2,-147.8 -52.8,-200.2 -2.8,-4.6 -5.6,-9 -8.6,-13.3 l -9.2,4.3 c 3.3,4.5 6.3,9.2 9.3,14.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_57_design"
                  v-if="chartBodygraphChart.gatesExt[57]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[57])"
                  @mouseover="searchAcPlanets(57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        57
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 239.65602,473.13104 c -6.22339,27.38635 -9.99238,55.55515 -10.69601,83.65896 l 10,1 c 0.68476,-28.46 4.57991,-56.99261 11.02413,-84.65896 z"
                  style="fill:#4caf50;opacity:0;display:none"
                  sodipodi:nodetypes="ccccc"
                  inkscape:connector-curvature="0"
                />
                <path
                  id="gate_57_person2"
                  v-if="chartBodygraphChart2.gatesExt[57]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[57])"
                  @mouseover="searchAcPlanets(57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        57
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 239.65602,473.13104 c -6.22339,27.38635 -9.99238,55.55515 -10.69601,83.65896 l 10,1 c 0.68476,-28.46 4.57991,-56.99261 11.02413,-84.65896 z"
                  style="fill:#fb8c00;opacity:0;display:none"
                  sodipodi:nodetypes="ccccc"
                  inkscape:connector-curvature="0"
                />
                <path
                  id="gate_57_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[57]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[57])"
                  @mouseover="searchAcPlanets(57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        57
                      ) && isChartToggle,
                    red_black_120: !isChart2Toggle
                  }"
                  d="m 239.65602,473.13104 c -6.22339,27.38635 -9.99238,55.55515 -10.69601,83.65896 l 10,1 c 0.68476,-28.46 4.57991,-56.99261 11.02413,-84.65896 z"
                  style="fill:#fb8c00;opacity:0;display:none"
                  inkscape:connector-curvature="0"
                  sodipodi:nodetypes="ccccc"
                />
                <path
                  id="gate_18_design"
                  v-if="chartBodygraphChart.gatesExt[18]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[18])"
                  @mouseover="searchAcPlanets(18)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        18
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 257.3,604.4 c 0,34.1 21.4,68.6 58.8,94.7 1.2,0.8 2.3,1.6 3.5,2.4 l 5.5,-8.3 c -1.1,-0.8 -2.2,-1.5 -3.4,-2.3 -34.7,-24.1 -54.5,-55.7 -54.5,-86.5 0,-6.6 0.2,-13.2 0.5,-19.9 l -10,-1 c -0.2,7 -0.4,13.9 -0.4,20.9 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_18_person2"
                  v-if="chartBodygraphChart2.gatesExt[18]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[18])"
                  @mouseover="searchAcPlanets(18)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        18
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 257.3,604.4 c 0,34.1 21.4,68.6 58.8,94.7 1.2,0.8 2.3,1.6 3.5,2.4 l 5.5,-8.3 c -1.1,-0.8 -2.2,-1.5 -3.4,-2.3 -34.7,-24.1 -54.5,-55.7 -54.5,-86.5 0,-6.6 0.2,-13.2 0.5,-19.9 l -10,-1 c -0.2,7 -0.4,13.9 -0.4,20.9 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_18_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[18]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[18])"
                  @mouseover="searchAcPlanets(18)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        18
                      ) && isChartToggle,
                    red_black_35: !isChart2Toggle
                  }"
                  d="m 257.3,604.4 c 0,34.1 21.4,68.6 58.8,94.7 1.2,0.8 2.3,1.6 3.5,2.4 l 5.5,-8.3 c -1.1,-0.8 -2.2,-1.5 -3.4,-2.3 -34.7,-24.1 -54.5,-55.7 -54.5,-86.5 0,-6.6 0.2,-13.2 0.5,-19.9 l -10,-1 c -0.2,7 -0.4,13.9 -0.4,20.9 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_28_design"
                  v-if="chartBodygraphChart.gatesExt[28]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[28])"
                  @mouseover="searchAcPlanets(28)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        28
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 275.7,595.9 c 0,31 19.5,62.4 53.4,86 0.8,0.6 1.6,1.1 2.4,1.6 l 5.5,-8.3 c -0.8,-0.5 -1.5,-1 -2.2,-1.5 -31.2,-21.8 -49.1,-50.1 -49.1,-77.8 0,-3.2 0.1,-6.3 0.1,-9.5 l -10,-1 c 0,3.5 -0.1,7 -0.1,10.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_28_person2"
                  v-if="chartBodygraphChart2.gatesExt[28]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[28])"
                  @mouseover="searchAcPlanets(28)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        28
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 275.7,595.9 c 0,31 19.5,62.4 53.4,86 0.8,0.6 1.6,1.1 2.4,1.6 l 5.5,-8.3 c -0.8,-0.5 -1.5,-1 -2.2,-1.5 -31.2,-21.8 -49.1,-50.1 -49.1,-77.8 0,-3.2 0.1,-6.3 0.1,-9.5 l -10,-1 c 0,3.5 -0.1,7 -0.1,10.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_28_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[28]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[28])"
                  @mouseover="searchAcPlanets(28)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        28
                      ) && isChartToggle,
                    red_black_35: !isChart2Toggle
                  }"
                  d="m 275.7,595.9 c 0,31 19.5,62.4 53.4,86 0.8,0.6 1.6,1.1 2.4,1.6 l 5.5,-8.3 c -0.8,-0.5 -1.5,-1 -2.2,-1.5 -31.2,-21.8 -49.1,-50.1 -49.1,-77.8 0,-3.2 0.1,-6.3 0.1,-9.5 l -10,-1 c 0,3.5 -0.1,7 -0.1,10.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_32_design"
                  v-if="chartBodygraphChart.gatesExt[32]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[32])"
                  @mouseover="searchAcPlanets(32)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        32
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 306.3,588.5 -6.9,-0.7 h -3.1 c 0,27.6 17.3,55.4 47.4,76.4 a 1,1 0 0 1 0.5,0.4 l 5.5,-8.3 a 0.52,0.52 0 0 0 -0.4,-0.2 c -27,-19 -42.7,-43.6 -43,-67.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_32_person2"
                  v-if="chartBodygraphChart2.gatesExt[32]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[32])"
                  @mouseover="searchAcPlanets(32)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        32
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 306.3,588.5 -6.9,-0.7 h -3.1 c 0,27.6 17.3,55.4 47.4,76.4 a 1,1 0 0 1 0.5,0.4 l 5.5,-8.3 a 0.52,0.52 0 0 0 -0.4,-0.2 c -27,-19 -42.7,-43.6 -43,-67.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_32_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[32]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[32])"
                  @mouseover="searchAcPlanets(32)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        32
                      ) && isChartToggle,
                    red_black_35: !isChart2Toggle
                  }"
                  d="m 306.3,588.5 -6.9,-0.7 h -3.1 c 0,27.6 17.3,55.4 47.4,76.4 a 1,1 0 0 1 0.5,0.4 l 5.5,-8.3 a 0.52,0.52 0 0 0 -0.4,-0.2 c -27,-19 -42.7,-43.6 -43,-67.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_35_design"
                  v-if="chartBodygraphChart.gatesExt[35]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[35])"
                  @mouseover="searchAcPlanets(35)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        35
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 455.8,288.4 c 47.3,0 91.7,28.7 125.9,81.2 l 9.2,-4.3 c -0.1,-0.2 -0.3,-0.4 -0.4,-0.6 C 554.3,309 506.8,278.4 455.8,278.4 h -0.9 v 10 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_35_person2"
                  v-if="chartBodygraphChart2.gatesExt[35]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[35])"
                  @mouseover="searchAcPlanets(35)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        35
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 455.8,288.4 c 47.3,0 91.7,28.7 125.9,81.2 l 9.2,-4.3 c -0.1,-0.2 -0.3,-0.4 -0.4,-0.6 C 554.3,309 506.8,278.4 455.8,278.4 h -0.9 v 10 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_35_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[35]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[35])"
                  @mouseover="searchAcPlanets(35)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        35
                      ) && isChartToggle,
                    red_black_70: !isChart2Toggle
                  }"
                  d="m 455.8,288.4 c 47.3,0 91.7,28.7 125.9,81.2 l 9.2,-4.3 c -0.1,-0.2 -0.3,-0.4 -0.4,-0.6 C 554.3,309 506.8,278.4 455.8,278.4 h -0.9 v 10 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_12_design"
                  v-if="chartBodygraphChart.gatesExt[12]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[12])"
                  @mouseover="searchAcPlanets(12)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        12
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 455.8,310.4 c 39.9,0 77.6,22.7 107.6,64.5 0.6,0.8 1.1,1.6 1.7,2.4 l 9.2,-4.3 c -0.5,-0.7 -0.9,-1.4 -1.4,-2.1 -32.1,-45.6 -73.2,-70.6 -117.1,-70.6 h -0.9 v 10 a 2.77,2.77 0 0 0 0.9,0.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_12_person2"
                  v-if="chartBodygraphChart2.gatesExt[12]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[12])"
                  @mouseover="searchAcPlanets(12)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        12
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 455.8,310.4 c 39.9,0 77.6,22.7 107.6,64.5 0.6,0.8 1.1,1.6 1.7,2.4 l 9.2,-4.3 c -0.5,-0.7 -0.9,-1.4 -1.4,-2.1 -32.1,-45.6 -73.2,-70.6 -117.1,-70.6 h -0.9 v 10 a 2.77,2.77 0 0 0 0.9,0.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_12_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[12]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[12])"
                  @mouseover="searchAcPlanets(12)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        12
                      ) && isChartToggle,
                    red_black_70: !isChart2Toggle
                  }"
                  d="m 455.8,310.4 c 39.9,0 77.6,22.7 107.6,64.5 0.6,0.8 1.1,1.6 1.7,2.4 l 9.2,-4.3 c -0.5,-0.7 -0.9,-1.4 -1.4,-2.1 -32.1,-45.6 -73.2,-70.6 -117.1,-70.6 h -0.9 v 10 a 2.77,2.77 0 0 0 0.9,0.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_16_design"
                  v-if="chartBodygraphChart.gatesExt[16]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[16])"
                  @mouseover="searchAcPlanets(16)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        16
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 324.8,365.4 9.2,4.3 c 34.8,-52.2 77.2,-80.8 120.9,-81.2 v -10 c -26.6,0.2 -52.7,9.9 -77.4,28.8 -19.2,14.5 -36.8,34 -52.7,58.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_16_person2"
                  v-if="chartBodygraphChart2.gatesExt[16]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[16])"
                  @mouseover="searchAcPlanets(16)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        16
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 324.8,365.4 9.2,4.3 c 34.8,-52.2 77.2,-80.8 120.9,-81.2 v -10 c -26.6,0.2 -52.7,9.9 -77.4,28.8 -19.2,14.5 -36.8,34 -52.7,58.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_16_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[16]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[16])"
                  @mouseover="searchAcPlanets(16)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        16
                      ) && isChartToggle,
                    red_black_120: !isChart2Toggle
                  }"
                  d="m 324.8,365.4 9.2,4.3 c 34.8,-52.2 77.2,-80.8 120.9,-81.2 v -10 c -26.6,0.2 -52.7,9.9 -77.4,28.8 -19.2,14.5 -36.8,34 -52.7,58.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_20_design"
                  v-if="chartBodygraphChart.gatesExt[20]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[20])"
                  @mouseover="searchAcPlanets(20)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        20
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  inkscape:connector-curvature="0"
                  d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29025,68.76196 -41.10282,107.54221 h 10.32812 c 8.61343,-36.97921 21.91455,-72.28182 39.47587,-102.54221 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                  style="fill:#4caf50;opacity:0;display:none"
                  sodipodi:nodetypes="cccccccccc"
                />
                <path
                  id="gate_20_person2"
                  v-if="chartBodygraphChart2.gatesExt[20]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[20])"
                  @mouseover="searchAcPlanets(20)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        20
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  inkscape:connector-curvature="0"
                  d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29025,68.76196 -41.10282,107.54221 h 10.32812 c 8.61343,-36.97921 21.91455,-72.28182 39.47587,-102.54221 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                  style="fill:#fb8c00;opacity:0;display:none"
                  sodipodi:nodetypes="cccccccccc"
                />
                <path
                  id="gate_20_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[20]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[20])"
                  @mouseover="searchAcPlanets(20)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        20
                      ) && isChartToggle,
                    red_black_120: !isChart2Toggle
                  }"
                  inkscape:connector-curvature="0"
                  d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29025,68.76196 -41.10282,107.54221 h 10.32812 c 8.61343,-36.97921 21.91455,-72.28182 39.47587,-102.54221 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                  style="fill:#fb8c00;opacity:0;display:none"
                  sodipodi:nodetypes="cccccccccc"
                />
                <path
                  id="gate_10_design"
                  v-if="chartBodygraphChart.gatesExt[10]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[10])"
                  @mouseover="searchAcPlanets(10)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        10
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 290.66,428.99 c 27.9,-14.5 65.2,-22.5 104.9,-22.5 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.63621,34.1043 -46.60398,53.64104 h 10.32812 C 252.60997,457.7943 265.06,442.29 290.66,428.99 Z"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                  sodipodi:nodetypes="cccsccc"
                />
                <path
                  id="gate_10_person2"
                  v-if="chartBodygraphChart2.gatesExt[10]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[10])"
                  @mouseover="searchAcPlanets(10)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        10
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 290.66,428.99 c 27.9,-14.5 65.2,-22.5 104.9,-22.5 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.63621,34.1043 -46.60398,53.64104 h 10.32812 C 252.60997,457.7943 265.06,442.29 290.66,428.99 Z"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                  sodipodi:nodetypes="cccsccc"
                />
                <path
                  id="gate_10_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[10]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[10])"
                  @mouseover="searchAcPlanets(10)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        10
                      ) && isChartToggle,
                    red_black_128: !isChart2Toggle
                  }"
                  d="m 290.66,428.99 c 27.9,-14.5 65.2,-22.5 104.9,-22.5 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.63621,34.1043 -46.60398,53.64104 h 10.32812 C 252.60997,457.7943 265.06,442.29 290.66,428.99 Z"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                  sodipodi:nodetypes="cccsccc"
                />
                <path
                  id="gate_34_design"
                  v-if="chartBodygraphChart.gatesExt[34]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[34])"
                  @mouseover="searchAcPlanets(34)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        34
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 286.46,540.89 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.24428,-3.88325 0.92414,-6.65896 H 239.65602 C 239.25827,475.0917 239.06,478.89 239.06,479.79 c 0,23.2 17,45.3 47.4,61.1 z"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                  sodipodi:nodetypes="cccccccsc"
                />
                <path
                  id="gate_34_person2"
                  v-if="chartBodygraphChart2.gatesExt[34]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[34])"
                  @mouseover="searchAcPlanets(34)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        34
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 286.46,540.89 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.24428,-3.88325 0.92414,-6.65896 H 239.65602 C 239.25827,475.0917 239.06,478.89 239.06,479.79 c 0,23.2 17,45.3 47.4,61.1 z"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                  sodipodi:nodetypes="cccccccsc"
                />
                <path
                  id="gate_34_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[34]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[34])"
                  @mouseover="searchAcPlanets(34)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        34
                      ) && isChartToggle,
                    red_black_25: !isChart2Toggle
                  }"
                  d="m 286.46,540.89 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.24428,-3.88325 0.92414,-6.65896 H 239.65602 C 239.25827,475.0917 239.06,478.89 239.06,479.79 c 0,23.2 17,45.3 47.4,61.1 z"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                  sodipodi:nodetypes="cccccccsc"
                />
                <path
                  id="gate_27_design"
                  v-if="chartBodygraphChart.gatesExt[27]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[27])"
                  @mouseover="searchAcPlanets(27)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        27
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 452.6,617.4 c -27.5,-0.4 -53.3,-4.1 -75.2,-10.3 l -5.3,9.1 c 23,6.7 50.3,10.8 80,11.2 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_27_person2"
                  v-if="chartBodygraphChart2.gatesExt[27]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[27])"
                  @mouseover="searchAcPlanets(27)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        27
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 452.6,617.4 c -27.5,-0.4 -53.3,-4.1 -75.2,-10.3 l -5.3,9.1 c 23,6.7 50.3,10.8 80,11.2 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_27_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[27]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[27])"
                  @mouseover="searchAcPlanets(27)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        27
                      ) && isChartToggle,
                    red_black_50: !isChart2Toggle
                  }"
                  d="m 452.6,617.4 c -27.5,-0.4 -53.3,-4.1 -75.2,-10.3 l -5.3,9.1 c 23,6.7 50.3,10.8 80,11.2 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_59_design"
                  v-if="chartBodygraphChart.gatesExt[59]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[59])"
                  @mouseover="searchAcPlanets(59)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        59
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 529.1,609.3 a 291.26,291.26 0 0 1 -43.2,6.9 c -9.2,0.8 -18.7,1.2 -28.3,1.2 h -4.8 l -0.5,10 h 5.4 c 11.2,0 22.1,-0.5 32.5,-1.5 a 287.12,287.12 0 0 0 44.4,-7.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_59_person2"
                  v-if="chartBodygraphChart2.gatesExt[59]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[59])"
                  @mouseover="searchAcPlanets(59)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        59
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 529.1,609.3 a 291.26,291.26 0 0 1 -43.2,6.9 c -9.2,0.8 -18.7,1.2 -28.3,1.2 h -4.8 l -0.5,10 h 5.4 c 11.2,0 22.1,-0.5 32.5,-1.5 a 287.12,287.12 0 0 0 44.4,-7.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_59_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[59]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[59])"
                  @mouseover="searchAcPlanets(59)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        59
                      ) && isChartToggle,
                    red_black_135: !isChart2Toggle
                  }"
                  d="m 529.1,609.3 a 291.26,291.26 0 0 1 -43.2,6.9 c -9.2,0.8 -18.7,1.2 -28.3,1.2 h -4.8 l -0.5,10 h 5.4 c 11.2,0 22.1,-0.5 32.5,-1.5 a 287.12,287.12 0 0 0 44.4,-7.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_50_design"
                  v-if="chartBodygraphChart.gatesExt[50]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[50])"
                  @mouseover="searchAcPlanets(50)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        50
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 314.1,585.1 c 10.3,10.8 26.1,20.1 45.9,27.2 q 5.85,2.1 12,3.9 l 5.3,-9.1 c -5,-1.4 -9.9,-3 -14.5,-4.6 -17,-6.1 -30.8,-13.8 -39.9,-22.5 a 42.73,42.73 0 0 1 -3.6,-3.8 l -8,6 a 37.64,37.64 0 0 1 2.8,2.9 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_50_person2"
                  v-if="chartBodygraphChart2.gatesExt[50]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[50])"
                  @mouseover="searchAcPlanets(50)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        50
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 314.1,585.1 c 10.3,10.8 26.1,20.1 45.9,27.2 q 5.85,2.1 12,3.9 l 5.3,-9.1 c -5,-1.4 -9.9,-3 -14.5,-4.6 -17,-6.1 -30.8,-13.8 -39.9,-22.5 a 42.73,42.73 0 0 1 -3.6,-3.8 l -8,6 a 37.64,37.64 0 0 1 2.8,2.9 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_50_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[50]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[50])"
                  @mouseover="searchAcPlanets(50)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        50
                      ) && isChartToggle,
                    red_black_50: !isChart2Toggle
                  }"
                  d="m 314.1,585.1 c 10.3,10.8 26.1,20.1 45.9,27.2 q 5.85,2.1 12,3.9 l 5.3,-9.1 c -5,-1.4 -9.9,-3 -14.5,-4.6 -17,-6.1 -30.8,-13.8 -39.9,-22.5 a 42.73,42.73 0 0 1 -3.6,-3.8 l -8,6 a 37.64,37.64 0 0 1 2.8,2.9 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_06_design"
                  v-if="chartBodygraphChart.gatesExt[6]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[6])"
                  @mouseover="searchAcPlanets(6)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        6
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 582.1,587.7 a 129.57,129.57 0 0 1 -26.1,13.2 204.47,204.47 0 0 1 -27,8.3 l 5.4,9.2 a 228.06,228.06 0 0 0 25.3,-8 c 16,-6.2 29.1,-13.9 38.4,-22.7 0.9,-0.8 1.7,-1.7 2.6,-2.5 l -8,-6.1 a 72.06,72.06 0 0 1 -10.6,8.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_06_person2"
                  v-if="chartBodygraphChart2.gatesExt[6]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[6])"
                  @mouseover="searchAcPlanets(6)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        6
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 582.1,587.7 a 129.57,129.57 0 0 1 -26.1,13.2 204.47,204.47 0 0 1 -27,8.3 l 5.4,9.2 a 228.06,228.06 0 0 0 25.3,-8 c 16,-6.2 29.1,-13.9 38.4,-22.7 0.9,-0.8 1.7,-1.7 2.6,-2.5 l -8,-6.1 a 72.06,72.06 0 0 1 -10.6,8.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_06_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[6]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[6])"
                  @mouseover="searchAcPlanets(6)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        6
                      ) && isChartToggle,
                    red_black_135: !isChart2Toggle
                  }"
                  d="m 582.1,587.7 a 129.57,129.57 0 0 1 -26.1,13.2 204.47,204.47 0 0 1 -27,8.3 l 5.4,9.2 a 228.06,228.06 0 0 0 25.3,-8 c 16,-6.2 29.1,-13.9 38.4,-22.7 0.9,-0.8 1.7,-1.7 2.6,-2.5 l -8,-6.1 a 72.06,72.06 0 0 1 -10.6,8.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_37_design"
                  v-if="chartBodygraphChart.gatesExt[37]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[37])"
                  @mouseover="searchAcPlanets(37)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        37
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 609,537.7 -10.5,2 a 29.12,29.12 0 0 1 5.4,16.3 c 0,8.2 -4,16 -11.3,23.2 l 8,6.1 c 8.5,-8.9 13.3,-18.8 13.3,-29.3 A 35.42,35.42 0 0 0 609,537.7 Z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_37_person2"
                  v-if="chartBodygraphChart2.gatesExt[37]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[37])"
                  @mouseover="searchAcPlanets(37)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        37
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 609,537.7 -10.5,2 a 29.12,29.12 0 0 1 5.4,16.3 c 0,8.2 -4,16 -11.3,23.2 l 8,6.1 c 8.5,-8.9 13.3,-18.8 13.3,-29.3 A 35.42,35.42 0 0 0 609,537.7 Z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_37_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[37]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[37])"
                  @mouseover="searchAcPlanets(37)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        37
                      ) && isChartToggle,
                    red_black__25: !isChart2Toggle
                  }"
                  d="m 609,537.7 -10.5,2 a 29.12,29.12 0 0 1 5.4,16.3 c 0,8.2 -4,16 -11.3,23.2 l 8,6.1 c 8.5,-8.9 13.3,-18.8 13.3,-29.3 A 35.42,35.42 0 0 0 609,537.7 Z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_26_design"
                  v-if="chartBodygraphChart.gatesExt[26]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[26])"
                  @mouseover="searchAcPlanets(26)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        26
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 457.5,484.4 a 337.29,337.29 0 0 0 -51.6,3.8 l 3,9.8 a 327.49,327.49 0 0 1 48.6,-3.6 c 31.9,0 61.9,4.6 86.2,12.2 l 2.9,-9.8 c -25.1,-7.9 -55.8,-12.4 -89.1,-12.4 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_26_person2"
                  v-if="chartBodygraphChart2.gatesExt[26]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[26])"
                  @mouseover="searchAcPlanets(26)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        26
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 457.5,484.4 a 337.29,337.29 0 0 0 -51.6,3.8 l 3,9.8 a 327.49,327.49 0 0 1 48.6,-3.6 c 31.9,0 61.9,4.6 86.2,12.2 l 2.9,-9.8 c -25.1,-7.9 -55.8,-12.4 -89.1,-12.4 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_26_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[26]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[26])"
                  @mouseover="searchAcPlanets(26)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        26
                      ) && isChartToggle,
                    red_black_128: !isChart2Toggle
                  }"
                  d="m 457.5,484.4 a 337.29,337.29 0 0 0 -51.6,3.8 l 3,9.8 a 327.49,327.49 0 0 1 48.6,-3.6 c 31.9,0 61.9,4.6 86.2,12.2 l 2.9,-9.8 c -25.1,-7.9 -55.8,-12.4 -89.1,-12.4 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_40_design"
                  v-if="chartBodygraphChart.gatesExt[40]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[40])"
                  @mouseover="searchAcPlanets(40)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        40
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 598.5,539.6 10.5,-2 c -9.5,-17 -31.9,-31.4 -62.5,-40.9 l -2.9,9.8 c 26.2,8.2 45.9,19.8 54.9,33.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_40_person2"
                  v-if="chartBodygraphChart2.gatesExt[40]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[40])"
                  @mouseover="searchAcPlanets(40)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        40
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 598.5,539.6 10.5,-2 c -9.5,-17 -31.9,-31.4 -62.5,-40.9 l -2.9,9.8 c 26.2,8.2 45.9,19.8 54.9,33.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_40_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[40]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[40])"
                  @mouseover="searchAcPlanets(40)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        40
                      ) && isChartToggle,
                    red_black_150: !isChart2Toggle
                  }"
                  d="m 598.5,539.6 10.5,-2 c -9.5,-17 -31.9,-31.4 -62.5,-40.9 l -2.9,9.8 c 26.2,8.2 45.9,19.8 54.9,33.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_25_design"
                  v-if="chartBodygraphChart.gatesExt[25]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[25])"
                  @mouseover="searchAcPlanets(25)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        25
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 466,422.4 v 10 c 21.8,0 41.6,7.5 56.4,19.8 l 6.9,-7.3 A 98.63,98.63 0 0 0 466,422.4 Z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_25_person2"
                  v-if="chartBodygraphChart2.gatesExt[25]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[25])"
                  @mouseover="searchAcPlanets(25)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        25
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 466,422.4 v 10 c 21.8,0 41.6,7.5 56.4,19.8 l 6.9,-7.3 A 98.63,98.63 0 0 0 466,422.4 Z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_25_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[25]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[25])"
                  @mouseover="searchAcPlanets(25)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        25
                      ) && isChartToggle,
                    red_black_150: !isChart2Toggle
                  }"
                  d="m 466,422.4 v 10 c 21.8,0 41.6,7.5 56.4,19.8 l 6.9,-7.3 A 98.63,98.63 0 0 0 466,422.4 Z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_51_design"
                  v-if="chartBodygraphChart.gatesExt[51]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[51])"
                  @mouseover="searchAcPlanets(51)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        51
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 529.3,444.9 -6.9,7.3 c 16.3,13.5 26.6,32.8 26.6,54.2 h 10 c 0,-24.2 -11.4,-46.1 -29.7,-61.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <path
                  id="gate_51_person2"
                  v-if="chartBodygraphChart2.gatesExt[51]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[51])"
                  @mouseover="searchAcPlanets(51)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        51
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 529.3,444.9 -6.9,7.3 c 16.3,13.5 26.6,32.8 26.6,54.2 h 10 c 0,-24.2 -11.4,-46.1 -29.7,-61.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_51_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[51]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[51])"
                  @mouseover="searchAcPlanets(51)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        51
                      ) && isChartToggle,
                    red_black_150: !isChart2Toggle
                  }"
                  d="m 529.3,444.9 -6.9,7.3 c 16.3,13.5 26.6,32.8 26.6,54.2 h 10 c 0,-24.2 -11.4,-46.1 -29.7,-61.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_44_design"
                  v-if="chartBodygraphChart.gatesExt[44]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[44])"
                  @mouseover="searchAcPlanets(44)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        44
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  d="m 311,555.9 c 0,-26.3 41.4,-49.3 97.9,-57.9 l -3,-9.8 c -61.5,9.6 -104.9,36 -104.9,67.7 0,7.9 2.7,15.5 7.7,22.6 l 10,-3.3 c -5,-6.1 -7.7,-12.6 -7.7,-19.3 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#4caf50;opacity:0;display:none"
                  sodipodi:nodetypes="sccsccs"
                />
                <path
                  id="gate_44_person2"
                  v-if="chartBodygraphChart2.gatesExt[44]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[44])"
                  @mouseover="searchAcPlanets(44)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        44
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 311,555.9 c 0,-26.3 41.4,-49.3 97.9,-57.9 l -3,-9.8 c -61.5,9.6 -104.9,36 -104.9,67.7 0,7.9 2.7,15.5 7.7,22.6 l 10,-3.3 c -5,-6.1 -7.7,-12.6 -7.7,-19.3 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                  sodipodi:nodetypes="sccsccs"
                />
                <path
                  id="gate_44_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[44]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[44])"
                  @mouseover="searchAcPlanets(44)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        44
                      ) && isChartToggle,
                    red_black_128: !isChart2Toggle
                  }"
                  d="m 311,555.9 c 0,-26.3 41.4,-49.3 97.9,-57.9 l -3,-9.8 c -61.5,9.6 -104.9,36 -104.9,67.7 0,7.9 2.7,15.5 7.7,22.6 l 10,-3.3 c -5,-6.1 -7.7,-12.6 -7.7,-19.3 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                  sodipodi:nodetypes="sccsccs"
                />
              </g>
              <g
                id="Слой_11-2"
                style="display:inline"
                data-name="Слой 11"
                transform="translate(31.140015,17.409983)"
              >
                <rect
                  id="gate_42_design"
                  v-if="chartBodygraphChart.gatesExt[42]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[42])"
                  @mouseover="searchAcPlanets(42)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        42
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  x="383.35999"
                  y="570.89001"
                  width="10"
                  height="57"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_42_person2"
                  v-if="chartBodygraphChart2.gatesExt[42]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[42])"
                  @mouseover="searchAcPlanets(42)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        42
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  x="383.35999"
                  y="570.89001"
                  width="10"
                  height="57"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_42_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[42]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[42])"
                  @mouseover="searchAcPlanets(42)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        42
                      ) && isChartToggle,
                    red_black_90: !isChart2Toggle
                  }"
                  x="383.35999"
                  y="570.89001"
                  width="10"
                  height="57"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_05_design"
                  v-if="chartBodygraphChart.gatesExt[5]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[5])"
                  @mouseover="searchAcPlanets(5)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        5
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  x="383.35999"
                  y="483.89001"
                  width="10"
                  height="87"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_05_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[5]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[5])"
                  @mouseover="searchAcPlanets(5)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        5
                      ) && isChartToggle,
                    red_black_90: !isChart2Toggle
                  }"
                  x="383.35999"
                  y="483.89001"
                  width="10"
                  height="87"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_15_design"
                  v-if="chartBodygraphChart.gatesExt[15]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[15])"
                  @mouseover="searchAcPlanets(15)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        15
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  x="383.35999"
                  y="401.39001"
                  width="10"
                  height="82.5"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_15_person2"
                  v-if="chartBodygraphChart2.gatesExt[15]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[15])"
                  @mouseover="searchAcPlanets(15)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        15
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  x="383.35999"
                  y="401.39001"
                  width="10"
                  height="82.5"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_15_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[15]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[15])"
                  @mouseover="searchAcPlanets(15)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        15
                      ) && isChartToggle,
                    red_black_90: !isChart2Toggle
                  }"
                  x="383.35999"
                  y="401.39001"
                  width="10"
                  height="82.5"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_07_design"
                  v-if="chartBodygraphChart.gatesExt[7]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[17])"
                  @mouseover="searchAcPlanets(7)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        7
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  x="383.35999"
                  y="339.89001"
                  width="10"
                  height="61.5"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_07_person2"
                  v-if="chartBodygraphChart2.gatesExt[7]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[17])"
                  @mouseover="searchAcPlanets(7)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        7
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  x="383.35999"
                  y="339.89001"
                  width="10"
                  height="61.5"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_07_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[7]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[7])"
                  @mouseover="searchAcPlanets(7)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        7
                      ) && isChartToggle,
                    red_black_90: !isChart2Toggle
                  }"
                  x="383.35999"
                  y="339.89001"
                  width="10"
                  height="61.5"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_31_design"
                  v-if="chartBodygraphChart.gatesExt[31]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[31])"
                  @mouseover="searchAcPlanets(31)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        31
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  x="383.35999"
                  y="270.89001"
                  width="10"
                  height="69"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_31_person2"
                  v-if="chartBodygraphChart2.gatesExt[31]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[31])"
                  @mouseover="searchAcPlanets(31)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        31
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  x="383.35999"
                  y="270.89001"
                  width="10"
                  height="69"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_31_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[31]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[31])"
                  @mouseover="searchAcPlanets(31)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        31
                      ) && isChartToggle,
                    red_black_90: !isChart2Toggle
                  }"
                  x="383.35999"
                  y="270.89001"
                  width="10"
                  height="69"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_62_design"
                  v-if="chartBodygraphChart.gatesExt[62]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[62])"
                  @mouseover="searchAcPlanets(62)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        62
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  x="383.35999"
                  y="214.89"
                  width="10"
                  height="56"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_62_person2"
                  v-if="chartBodygraphChart2.gatesExt[62]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[62])"
                  @mouseover="searchAcPlanets(62)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        62
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  x="383.35999"
                  y="214.89"
                  width="10"
                  height="56"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_62_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[62]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[62])"
                  @mouseover="searchAcPlanets(62)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        62
                      ) && isChartToggle,
                    red_black_90: !isChart2Toggle
                  }"
                  x="383.35999"
                  y="214.89"
                  width="10"
                  height="56"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_17_design"
                  v-if="chartBodygraphChart.gatesExt[17]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[17])"
                  @mouseover="searchAcPlanets(17)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        17
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  x="383.35999"
                  y="145.39"
                  width="10"
                  height="69.5"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_17_person2"
                  v-if="chartBodygraphChart2.gatesExt[17]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[17])"
                  @mouseover="searchAcPlanets(17)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        17
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  x="383.35999"
                  y="145.39"
                  width="10"
                  height="69.5"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_17_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[17]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[17])"
                  @mouseover="searchAcPlanets(17)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        17
                      ) && isChartToggle,
                    red_black_90: !isChart2Toggle
                  }"
                  x="383.35999"
                  y="145.39"
                  width="10"
                  height="69.5"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_64_design"
                  v-if="chartBodygraphChart.gatesExt[64]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[64])"
                  @mouseover="searchAcPlanets(64)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        64
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  x="383.35999"
                  y="73.889999"
                  width="10"
                  height="32"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_64_person2"
                  v-if="chartBodygraphChart2.gatesExt[64]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[64])"
                  @mouseover="searchAcPlanets(64)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        64
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  x="383.35999"
                  y="73.889999"
                  width="10"
                  height="32"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_64_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[64]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[64])"
                  @mouseover="searchAcPlanets(64)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        64
                      ) && isChartToggle,
                    red_black_90: !isChart2Toggle
                  }"
                  x="383.35999"
                  y="73.889999"
                  width="10"
                  height="32"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_60_design"
                  v-if="chartBodygraphChart.gatesExt[60]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[60])"
                  @mouseover="searchAcPlanets(60)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        60
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  x="403.85999"
                  y="627.89001"
                  width="10"
                  height="79"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_60_person2"
                  v-if="chartBodygraphChart2.gatesExt[60]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[60])"
                  @mouseover="searchAcPlanets(60)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        60
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  x="403.85999"
                  y="627.89001"
                  width="10"
                  height="79"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_60_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[60]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[60])"
                  @mouseover="searchAcPlanets(60)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        60
                      ) && isChartToggle,
                    red_black_90: !isChart2Toggle
                  }"
                  x="403.85999"
                  y="627.89001"
                  width="10"
                  height="79"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_03_design"
                  v-if="chartBodygraphChart.gatesExt[3]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[3])"
                  @mouseover="searchAcPlanets(3)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        3
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  x="403.85999"
                  y="570.89001"
                  width="10"
                  height="57"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_03_person2"
                  v-if="chartBodygraphChart2.gatesExt[3]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[3])"
                  @mouseover="searchAcPlanets(3)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        3
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  x="403.85999"
                  y="570.89001"
                  width="10"
                  height="57"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_03_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[3]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[3])"
                  @mouseover="searchAcPlanets(3)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        3
                      ) && isChartToggle,
                    red_black_90: !isChart2Toggle
                  }"
                  x="403.85999"
                  y="570.89001"
                  width="10"
                  height="57"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_14_design"
                  v-if="chartBodygraphChart.gatesExt[14]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[14])"
                  @mouseover="searchAcPlanets(14)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        14
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  x="403.85999"
                  y="483.89001"
                  width="10"
                  height="87"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_14_person2"
                  v-if="chartBodygraphChart2.gatesExt[14]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[14])"
                  @mouseover="searchAcPlanets(14)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        14
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  x="403.85999"
                  y="483.89001"
                  width="10"
                  height="87"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_14_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[14]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[14])"
                  @mouseover="searchAcPlanets(14)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        14
                      ) && isChartToggle,
                    red_black_90: !isChart2Toggle
                  }"
                  x="403.85999"
                  y="483.89001"
                  width="10"
                  height="87"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_02_design"
                  v-if="chartBodygraphChart.gatesExt[2]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[2])"
                  @mouseover="searchAcPlanets(2)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        2
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  x="403.85999"
                  y="401.39001"
                  width="10"
                  height="82.5"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_02_person2"
                  v-if="chartBodygraphChart2.gatesExt[2]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[2])"
                  @mouseover="searchAcPlanets(2)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        2
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  x="403.85999"
                  y="401.39001"
                  width="10"
                  height="82.5"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_02_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[2]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[2])"
                  @mouseover="searchAcPlanets(2)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        2
                      ) && isChartToggle,
                    red_black_90: !isChart2Toggle
                  }"
                  x="403.85999"
                  y="401.39001"
                  width="10"
                  height="82.5"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_01_design"
                  v-if="chartBodygraphChart.gatesExt[1]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[1])"
                  @mouseover="searchAcPlanets(1)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        1
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  x="403.85999"
                  y="339.89001"
                  width="10"
                  height="61.5"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_01_person2"
                  v-if="chartBodygraphChart2.gatesExt[1]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[1])"
                  @mouseover="searchAcPlanets(1)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        1
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  x="403.85999"
                  y="339.89001"
                  width="10"
                  height="61.5"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_01_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[1]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[1])"
                  @mouseover="searchAcPlanets(1)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        1
                      ) && isChartToggle,
                    red_black_90: !isChart2Toggle
                  }"
                  x="403.85999"
                  y="339.89001"
                  width="10"
                  height="61.5"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_08_design"
                  v-if="chartBodygraphChart.gatesExt[8]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[8])"
                  @mouseover="searchAcPlanets(8)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        8
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  x="403.85999"
                  y="270.89001"
                  width="10"
                  height="69"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_08_person2"
                  v-if="chartBodygraphChart2.gatesExt[8]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[8])"
                  @mouseover="searchAcPlanets(8)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        8
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  x="403.85999"
                  y="270.89001"
                  width="10"
                  height="69"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_08_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[8]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[8])"
                  @mouseover="searchAcPlanets(8)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        8
                      ) && isChartToggle,
                    red_black_90: !isChart2Toggle
                  }"
                  x="403.85999"
                  y="270.89001"
                  width="10"
                  height="69"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_23_design"
                  v-if="chartBodygraphChart.gatesExt[23]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[23])"
                  @mouseover="searchAcPlanets(23)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        23
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  x="403.85999"
                  y="214.89"
                  width="10"
                  height="56"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_23_person2"
                  v-if="chartBodygraphChart2.gatesExt[23]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[23])"
                  @mouseover="searchAcPlanets(23)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        23
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  x="403.85999"
                  y="214.89"
                  width="10"
                  height="56"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_23_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[23]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[23])"
                  @mouseover="searchAcPlanets(23)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        23
                      ) && isChartToggle,
                    red_black_90: !isChart2Toggle
                  }"
                  x="403.85999"
                  y="214.89"
                  width="10"
                  height="56"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_43_design"
                  v-if="chartBodygraphChart.gatesExt[43]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[43])"
                  @mouseover="searchAcPlanets(43)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        43
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  x="403.85999"
                  y="145.39"
                  width="10"
                  height="69.5"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_43_person2"
                  v-if="chartBodygraphChart2.gatesExt[43]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[43])"
                  @mouseover="searchAcPlanets(43)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        43
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  x="403.85999"
                  y="145.39"
                  width="10"
                  height="69.5"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_43_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[43]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[43])"
                  @mouseover="searchAcPlanets(43)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        43
                      ) && isChartToggle,
                    red_black_90: !isChart2Toggle
                  }"
                  x="403.85999"
                  y="145.39"
                  width="10"
                  height="69.5"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_61_design"
                  v-if="chartBodygraphChart.gatesExt[61]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[61])"
                  @mouseover="searchAcPlanets(61)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        61
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  x="403.85999"
                  y="73.889999"
                  width="10"
                  height="32"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_61_person2"
                  v-if="chartBodygraphChart2.gatesExt[61]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[61])"
                  @mouseover="searchAcPlanets(61)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        61
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  x="403.85999"
                  y="73.889999"
                  width="10"
                  height="32"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_61_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[61]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[61])"
                  @mouseover="searchAcPlanets(61)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        61
                      ) && isChartToggle,
                    red_black_90: !isChart2Toggle
                  }"
                  x="403.85999"
                  y="73.889999"
                  width="10"
                  height="32"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_52_design"
                  v-if="chartBodygraphChart.gatesExt[52]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[52])"
                  @mouseover="searchAcPlanets(52)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        52
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  x="424.35999"
                  y="627.89001"
                  width="10"
                  height="79"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_52_person2"
                  v-if="chartBodygraphChart2.gatesExt[52]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[52])"
                  @mouseover="searchAcPlanets(52)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        52
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  x="424.35999"
                  y="627.89001"
                  width="10"
                  height="79"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_52_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[52]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[52])"
                  @mouseover="searchAcPlanets(52)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        52
                      ) && isChartToggle,
                    red_black_90: !isChart2Toggle
                  }"
                  x="424.35999"
                  y="627.89001"
                  width="10"
                  height="79"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_09_design"
                  v-if="chartBodygraphChart.gatesExt[9]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[9])"
                  @mouseover="searchAcPlanets(9)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        9
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  x="424.35999"
                  y="570.89001"
                  width="10"
                  height="57"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_09_person2"
                  v-if="chartBodygraphChart2.gatesExt[9]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[9])"
                  @mouseover="searchAcPlanets(9)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        9
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  x="424.35999"
                  y="570.89001"
                  width="10"
                  height="57"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_09_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[9]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[9])"
                  @mouseover="searchAcPlanets(9)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        9
                      ) && isChartToggle,
                    red_black_90: !isChart2Toggle
                  }"
                  x="424.35999"
                  y="570.89001"
                  width="10"
                  height="57"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_29_design"
                  v-if="chartBodygraphChart.gatesExt[29]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[29])"
                  @mouseover="searchAcPlanets(29)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        29
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  x="424.35999"
                  y="483.89001"
                  width="10"
                  height="87"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_29_person2"
                  v-if="chartBodygraphChart2.gatesExt[29]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[29])"
                  @mouseover="searchAcPlanets(29)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        29
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  x="424.35999"
                  y="483.89001"
                  width="10"
                  height="87"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_29_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[29]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[29])"
                  @mouseover="searchAcPlanets(29)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        29
                      ) && isChartToggle,
                    red_black_90: !isChart2Toggle
                  }"
                  x="424.35999"
                  y="483.89001"
                  width="10"
                  height="87"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_46_design"
                  v-if="chartBodygraphChart.gatesExt[46]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[46])"
                  @mouseover="searchAcPlanets(46)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        46
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  x="424.35999"
                  y="401.39001"
                  width="10"
                  height="82.5"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_46_person2"
                  v-if="chartBodygraphChart2.gatesExt[46]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[46])"
                  @mouseover="searchAcPlanets(46)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        46
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  x="424.35999"
                  y="401.39001"
                  width="10"
                  height="82.5"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_46_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[46]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[46])"
                  @mouseover="searchAcPlanets(46)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        46
                      ) && isChartToggle,
                    red_black_90: !isChart2Toggle
                  }"
                  x="424.35999"
                  y="401.39001"
                  width="10"
                  height="82.5"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_13_design"
                  v-if="chartBodygraphChart.gatesExt[13]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[13])"
                  @mouseover="searchAcPlanets(13)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        13
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  x="424.35999"
                  y="339.89001"
                  width="10"
                  height="61.5"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_13_person2"
                  v-if="chartBodygraphChart2.gatesExt[13]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[13])"
                  @mouseover="searchAcPlanets(13)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        13
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  x="424.35999"
                  y="339.89001"
                  width="10"
                  height="61.5"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_13_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[13]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[13])"
                  @mouseover="searchAcPlanets(13)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        13
                      ) && isChartToggle,
                    red_black_90: !isChart2Toggle
                  }"
                  x="424.35999"
                  y="339.89001"
                  width="10"
                  height="61.5"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_33_design"
                  v-if="chartBodygraphChart.gatesExt[33]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[33])"
                  @mouseover="searchAcPlanets(33)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        33
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  x="424.35999"
                  y="270.89001"
                  width="10"
                  height="69"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_33_person2"
                  v-if="chartBodygraphChart2.gatesExt[33]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[33])"
                  @mouseover="searchAcPlanets(33)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        33
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  x="424.35999"
                  y="270.89001"
                  width="10"
                  height="69"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_33_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[33]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[33])"
                  @mouseover="searchAcPlanets(33)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        33
                      ) && isChartToggle,
                    red_black_90: !isChart2Toggle
                  }"
                  x="424.35999"
                  y="270.89001"
                  width="10"
                  height="69"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_56_design"
                  v-if="chartBodygraphChart.gatesExt[56]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[56])"
                  @mouseover="searchAcPlanets(56)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        56
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  x="424.35999"
                  y="214.89"
                  width="10"
                  height="56"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_56_person2"
                  v-if="chartBodygraphChart2.gatesExt[56]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[56])"
                  @mouseover="searchAcPlanets(56)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        56
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  x="424.35999"
                  y="214.89"
                  width="10"
                  height="56"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_56_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[56]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[56])"
                  @mouseover="searchAcPlanets(56)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        56
                      ) && isChartToggle,
                    red_black_90: !isChart2Toggle
                  }"
                  x="424.35999"
                  y="214.89"
                  width="10"
                  height="56"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_11_design"
                  v-if="chartBodygraphChart.gatesExt[11]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[11])"
                  @mouseover="searchAcPlanets(11)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        11
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  x="424.35999"
                  y="145.39"
                  width="10"
                  height="69.5"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_11_person2"
                  v-if="chartBodygraphChart2.gatesExt[11]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[11])"
                  @mouseover="searchAcPlanets(11)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        11
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  x="424.35999"
                  y="145.39"
                  width="10"
                  height="69.5"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_11_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[11]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[11])"
                  @mouseover="searchAcPlanets(11)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        11
                      ) && isChartToggle,
                    red_black_90: !isChart2Toggle
                  }"
                  x="424.35999"
                  y="145.39"
                  width="10"
                  height="69.5"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_04_design"
                  v-if="chartBodygraphChart.gatesExt[4]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[4])"
                  @mouseover="searchAcPlanets(4)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        4
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  x="424.35999"
                  y="105.89"
                  width="10"
                  height="39.5"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_04_person2"
                  v-if="chartBodygraphChart2.gatesExt[4]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[4])"
                  @mouseover="searchAcPlanets(4)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        4
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  x="424.35999"
                  y="105.89"
                  width="10"
                  height="39.5"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_04_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[4]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[4])"
                  @mouseover="searchAcPlanets(4)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        4
                      ) && isChartToggle,
                    red_black_90: !isChart2Toggle
                  }"
                  x="424.35999"
                  y="105.89"
                  width="10"
                  height="39.5"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_63_design"
                  v-if="chartBodygraphChart.gatesExt[63]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[63])"
                  @mouseover="searchAcPlanets(63)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        63
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  x="424.35999"
                  y="73.889999"
                  width="10"
                  height="32"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_63_person2"
                  v-if="chartBodygraphChart2.gatesExt[63]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[63])"
                  @mouseover="searchAcPlanets(63)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        63
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  x="424.35999"
                  y="73.889999"
                  width="10"
                  height="32"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_63_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[63]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[63])"
                  @mouseover="searchAcPlanets(63)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        63
                      ) && isChartToggle,
                    red_black_90: !isChart2Toggle
                  }"
                  x="424.35999"
                  y="73.889999"
                  width="10"
                  height="32"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_53_design"
                  v-if="chartBodygraphChart.gatesExt[53]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[53])"
                  @mouseover="searchAcPlanets(53)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        53
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  x="383.35999"
                  y="627.89001"
                  width="10"
                  height="79"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_53_person2"
                  v-if="chartBodygraphChart2.gatesExt[53]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[53])"
                  @mouseover="searchAcPlanets(53)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        53
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  x="383.35999"
                  y="627.89001"
                  width="10"
                  height="79"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_53_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[53]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[53])"
                  @mouseover="searchAcPlanets(53)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        53
                      ) && isChartToggle,
                    red_black_90: !isChart2Toggle
                  }"
                  x="383.35999"
                  y="627.89001"
                  width="10"
                  height="79"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_47_design"
                  v-if="chartBodygraphChart.gatesExt[47]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[47])"
                  @mouseover="searchAcPlanets(47)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        47
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  x="383.35999"
                  y="105.89"
                  width="10"
                  height="39.5"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_47_person2"
                  v-if="chartBodygraphChart2.gatesExt[47]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[47])"
                  @mouseover="searchAcPlanets(47)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        47
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  x="383.35999"
                  y="105.89"
                  width="10"
                  height="39.5"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_47_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[47]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[47])"
                  @mouseover="searchAcPlanets(47)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        47
                      ) && isChartToggle,
                    red_black_90: !isChart2Toggle
                  }"
                  x="383.35999"
                  y="105.89"
                  width="10"
                  height="39.5"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_24_design"
                  v-if="chartBodygraphChart.gatesExt[24]"
                  @click="openTooltipDialog(chartBodygraphChart.gatesExt[24])"
                  @mouseover="searchAcPlanets(24)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart.gatesActivatedAtDesignOnly,
                        24
                      ) && isChart2Toggle,
                    design_color: !isChartToggle && isChart2Toggle
                  }"
                  x="403.85999"
                  y="105.89"
                  width="10"
                  height="39.5"
                  style="fill:#4caf50;opacity:0;display:none"
                />
                <rect
                  id="gate_24_person2"
                  v-if="chartBodygraphChart2.gatesExt[24]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[24])"
                  @mouseover="searchAcPlanets(24)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        24
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  x="403.85999"
                  y="105.89"
                  width="10"
                  height="39.5"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_24_both_chart2"
                  v-if="chartBodygraphChart2.gatesExt[24]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[24])"
                  @mouseover="searchAcPlanets(24)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
                        24
                      ) && isChartToggle,
                    red_black_90: !isChart2Toggle
                  }"
                  x="403.85999"
                  y="105.89"
                  width="10"
                  height="39.5"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
              </g>
            </g>
            <g
              id="layer7.2"
              inkscape:groupmode="layer"
              inkscape:label="Gates Transit"
              style="display:inline"
              sodipodi:insensitive="true"
            >
              <g
                id="Слой_15"
                style="display:inline"
                data-name="Слой 15"
                transform="translate(31.134997,17.409983)"
              >
                <path
                  id="gate_41_design2"
                  v-if="chartBodygraphChart2.gatesExt[41]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[41])"
                  @mouseover="searchAcPlanets(41)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        41
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 586.9,704.9 -5.7,-8.3 c -33.9,20.7 -77.5,33.1 -121.8,33.7 l 0.5,10 a 266.48,266.48 0 0 0 90.5,-17.3 225.62,225.62 0 0 0 36.5,-18.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_30_design2"
                  v-if="chartBodygraphChart2.gatesExt[30]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[30])"
                  @mouseover="searchAcPlanets(30)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        30
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 653.6,582.8 -10,1.1 c 0.3,6.8 0.6,13.7 0.7,20.6 0.4,22.9 -10.2,46 -30.7,66.8 a 172.56,172.56 0 0 1 -32.4,25.4 l 5.7,8.3 a 178.16,178.16 0 0 0 33.8,-26.6 c 22.4,-22.8 34,-48.3 33.6,-74 -0.1,-7.3 -0.4,-14.5 -0.7,-21.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_55_design2"
                  v-if="chartBodygraphChart2.gatesExt[55]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[55])"
                  @mouseover="searchAcPlanets(55)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        55
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 635.9,595.8 c -0.1,-3.1 -0.1,-6.2 -0.2,-9.3 l -9.9,3 c 0.1,2.1 0.1,4.3 0.1,6.4 0.3,20.6 -9.2,41.4 -27.6,60.1 a 152.5,152.5 0 0 1 -28.5,22.4 l 5.3,8.5 A 165.59,165.59 0 0 0 605.4,663 c 20.3,-20.6 30.9,-43.9 30.5,-67.2 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_49_design2"
                  v-if="chartBodygraphChart2.gatesExt[49]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[49])"
                  @mouseover="searchAcPlanets(49)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        49
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 615.4,587.7 -10,0.2 c 0.3,18 -8.1,36.2 -24.2,52.6 a 136.22,136.22 0 0 1 -23.4,18.7 l 5.4,8.6 a 147.83,147.83 0 0 0 25.2,-20.2 c 17.9,-18.5 27.3,-39.2 27,-59.9 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_39_design2"
                  v-if="chartBodygraphChart2.gatesExt[39]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[39])"
                  @mouseover="searchAcPlanets(39)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        39
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 575.1,686.9 -5.3,-8.5 c -31.6,19.5 -72.5,30.9 -114,30.9 v 10 a 239.69,239.69 0 0 0 85.8,-15.8 190,190 0 0 0 33.5,-16.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_19_design2"
                  v-if="chartBodygraphChart2.gatesExt[19]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[19])"
                  @mouseover="searchAcPlanets(19)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        19
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 557.3,659.4 c -27.9,17.6 -64.4,27.9 -101.4,27.9 v 10 c 39.5,0 77.5,-10.7 107,-29.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_58_design2"
                  v-if="chartBodygraphChart2.gatesExt[58]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[58])"
                  @mouseover="searchAcPlanets(58)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        58
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 319.7,701.5 c 37.6,25.1 85.6,38.8 135.7,38.9 v -10 c -48.1,-0.1 -94.1,-13.3 -130.1,-37.3 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_38_design2"
                  v-if="chartBodygraphChart2.gatesExt[38]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[38])"
                  @mouseover="searchAcPlanets(38)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        38
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 455.3,709.4 c -43.7,-0.1 -85.6,-12.2 -118.2,-34.2 l -5.5,8.3 c 34.2,23 78,35.7 123.7,35.8 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_54_design2"
                  v-if="chartBodygraphChart2.gatesExt[54]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[54])"
                  @mouseover="searchAcPlanets(54)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        54
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  d="M 455.2,687.4 C 416,687.3 378.6,676.2 349.7,656.2 l -5.5,8.3 c 30.5,21.1 69.9,32.7 111,32.8 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_36_design2"
                  v-if="chartBodygraphChart2.gatesExt[36]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[36])"
                  @mouseover="searchAcPlanets(36)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        36
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 582,369.9 c 1.8,2.7 3.5,5.5 5.2,8.3 32.6,54.1 52.3,126.5 56.4,205.7 l 10,-1.1 c -4.2,-80.6 -24.4,-154.4 -57.8,-209.7 -1.6,-2.7 -3.2,-5.2 -4.9,-7.8 l -9.2,4.3 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_48_design2"
                  v-if="chartBodygraphChart2.gatesExt[48]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[48])"
                  @mouseover="searchAcPlanets(48)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        48
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 314.7,381.8 c -33.2,57.3 -53.5,129.8 -56.9,201.8 l 10,1 c 3.2,-70.6 23,-141.7 55.5,-197.8 3.5,-6 7,-11.7 10.6,-17.1 l -9.2,-4.3 c -3.4,5.2 -6.7,10.7 -10,16.4 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_45_design2"
                  v-if="chartBodygraphChart2.gatesExt[45]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[45])"
                  @mouseover="searchAcPlanets(45)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        45
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 456.5,320.4 v 10 c 32.5,0 61.4,23.8 79.4,60.5 l 9.1,-4.2 a 158.69,158.69 0 0 0 -11.1,-19.3 c -20.6,-30.3 -48.1,-47 -77.4,-47 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_21_design2"
                  v-if="chartBodygraphChart2.gatesExt[21]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[21])"
                  @mouseover="searchAcPlanets(21)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        21
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 555,478.4 h 10 c 0,-33.3 -7,-65.1 -20.1,-91.7 l -9.1,4.2 c 12.1,24.5 19.2,54.8 19.2,87.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_22_design2"
                  v-if="chartBodygraphChart2.gatesExt[22]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[22])"
                  @mouseover="searchAcPlanets(22)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        22
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 574.3,391.4 c 31.1,51.6 49.3,121.8 51.4,198.1 l 9.9,-3 c -2.6,-77 -21.2,-147.8 -52.8,-200.2 -2.8,-4.6 -5.6,-9 -8.6,-13.3 l -9.2,4.3 c 3.3,4.5 6.3,9.2 9.3,14.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_57_design2"
                  v-if="chartBodygraphChart2.gatesExt[57]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[57])"
                  @mouseover="searchAcPlanets(57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        57
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 239.65602,473.13104 c -6.22339,27.38635 -9.99238,55.55515 -10.69601,83.65896 l 10,1 c 0.68476,-28.46 4.57991,-56.99261 11.02413,-84.65896 z"
                  style="fill:#fb8c00;opacity:0;display:none"
                  sodipodi:nodetypes="ccccc"
                  inkscape:connector-curvature="0"
                />
                <path
                  id="gate_18_design2"
                  v-if="chartBodygraphChart2.gatesExt[18]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[18])"
                  @mouseover="searchAcPlanets(18)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        18
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 257.3,604.4 c 0,34.1 21.4,68.6 58.8,94.7 1.2,0.8 2.3,1.6 3.5,2.4 l 5.5,-8.3 c -1.1,-0.8 -2.2,-1.5 -3.4,-2.3 -34.7,-24.1 -54.5,-55.7 -54.5,-86.5 0,-6.6 0.2,-13.2 0.5,-19.9 l -10,-1 c -0.2,7 -0.4,13.9 -0.4,20.9 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_28_design2"
                  v-if="chartBodygraphChart2.gatesExt[28]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[28])"
                  @mouseover="searchAcPlanets(28)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        28
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 275.7,595.9 c 0,31 19.5,62.4 53.4,86 0.8,0.6 1.6,1.1 2.4,1.6 l 5.5,-8.3 c -0.8,-0.5 -1.5,-1 -2.2,-1.5 -31.2,-21.8 -49.1,-50.1 -49.1,-77.8 0,-3.2 0.1,-6.3 0.1,-9.5 l -10,-1 c 0,3.5 -0.1,7 -0.1,10.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_32_design2"
                  v-if="chartBodygraphChart2.gatesExt[32]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[32])"
                  @mouseover="searchAcPlanets(32)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        32
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 306.3,588.5 -6.9,-0.7 h -3.1 c 0,27.6 17.3,55.4 47.4,76.4 a 1,1 0 0 1 0.5,0.4 l 5.5,-8.3 a 0.52,0.52 0 0 0 -0.4,-0.2 c -27,-19 -42.7,-43.6 -43,-67.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_35_design2"
                  v-if="chartBodygraphChart2.gatesExt[35]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[35])"
                  @mouseover="searchAcPlanets(35)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        35
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 455.8,288.4 c 47.3,0 91.7,28.7 125.9,81.2 l 9.2,-4.3 c -0.1,-0.2 -0.3,-0.4 -0.4,-0.6 C 554.3,309 506.8,278.4 455.8,278.4 h -0.9 v 10 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_12_design2"
                  v-if="chartBodygraphChart2.gatesExt[12]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[12])"
                  @mouseover="searchAcPlanets(12)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        12
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 455.8,310.4 c 39.9,0 77.6,22.7 107.6,64.5 0.6,0.8 1.1,1.6 1.7,2.4 l 9.2,-4.3 c -0.5,-0.7 -0.9,-1.4 -1.4,-2.1 -32.1,-45.6 -73.2,-70.6 -117.1,-70.6 h -0.9 v 10 a 2.77,2.77 0 0 0 0.9,0.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_16_design2"
                  v-if="chartBodygraphChart2.gatesExt[16]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[16])"
                  @mouseover="searchAcPlanets(16)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        16
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 324.8,365.4 9.2,4.3 c 34.8,-52.2 77.2,-80.8 120.9,-81.2 v -10 c -26.6,0.2 -52.7,9.9 -77.4,28.8 -19.2,14.5 -36.8,34 -52.7,58.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_20_design2"
                  v-if="chartBodygraphChart2.gatesExt[20]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[20])"
                  @mouseover="searchAcPlanets(20)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        20
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  inkscape:connector-curvature="0"
                  d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29025,68.76196 -41.10282,107.54221 h 10.32812 c 8.61343,-36.97921 21.91455,-72.28182 39.47587,-102.54221 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                  style="fill:#fb8c00;opacity:0;display:none"
                  sodipodi:nodetypes="cccccccccc"
                />
                <path
                  id="gate_10_design2"
                  v-if="chartBodygraphChart2.gatesExt[10]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[10])"
                  @mouseover="searchAcPlanets(10)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        10
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 290.66,428.99 c 27.9,-14.5 65.2,-22.5 104.9,-22.5 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.63621,34.1043 -46.60398,53.64104 h 10.32812 C 252.60997,457.7943 265.06,442.29 290.66,428.99 Z"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                  sodipodi:nodetypes="cccsccc"
                />
                <path
                  id="gate_34_design2"
                  v-if="chartBodygraphChart2.gatesExt[34]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[34])"
                  @mouseover="searchAcPlanets(34)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        34
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 286.46,540.89 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.24428,-3.88325 0.92414,-6.65896 H 239.65602 C 239.25827,475.0917 239.06,478.89 239.06,479.79 c 0,23.2 17,45.3 47.4,61.1 z"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                  sodipodi:nodetypes="cccccccsc"
                />
                <path
                  id="gate_27_design2"
                  v-if="chartBodygraphChart2.gatesExt[27]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[27])"
                  @mouseover="searchAcPlanets(27)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        27
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 452.6,617.4 c -27.5,-0.4 -53.3,-4.1 -75.2,-10.3 l -5.3,9.1 c 23,6.7 50.3,10.8 80,11.2 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_59_design2"
                  v-if="chartBodygraphChart2.gatesExt[59]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[59])"
                  @mouseover="searchAcPlanets(59)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        59
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 529.1,609.3 a 291.26,291.26 0 0 1 -43.2,6.9 c -9.2,0.8 -18.7,1.2 -28.3,1.2 h -4.8 l -0.5,10 h 5.4 c 11.2,0 22.1,-0.5 32.5,-1.5 a 287.12,287.12 0 0 0 44.4,-7.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_50_design2"
                  v-if="chartBodygraphChart2.gatesExt[50]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[50])"
                  @mouseover="searchAcPlanets(50)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        50
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 314.1,585.1 c 10.3,10.8 26.1,20.1 45.9,27.2 q 5.85,2.1 12,3.9 l 5.3,-9.1 c -5,-1.4 -9.9,-3 -14.5,-4.6 -17,-6.1 -30.8,-13.8 -39.9,-22.5 a 42.73,42.73 0 0 1 -3.6,-3.8 l -8,6 a 37.64,37.64 0 0 1 2.8,2.9 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_06_design2"
                  v-if="chartBodygraphChart2.gatesExt[6]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[6])"
                  @mouseover="searchAcPlanets(6)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        6
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 582.1,587.7 a 129.57,129.57 0 0 1 -26.1,13.2 204.47,204.47 0 0 1 -27,8.3 l 5.4,9.2 a 228.06,228.06 0 0 0 25.3,-8 c 16,-6.2 29.1,-13.9 38.4,-22.7 0.9,-0.8 1.7,-1.7 2.6,-2.5 l -8,-6.1 a 72.06,72.06 0 0 1 -10.6,8.6 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_37_design2"
                  v-if="chartBodygraphChart2.gatesExt[37]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[37])"
                  @mouseover="searchAcPlanets(37)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        37
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 609,537.7 -10.5,2 a 29.12,29.12 0 0 1 5.4,16.3 c 0,8.2 -4,16 -11.3,23.2 l 8,6.1 c 8.5,-8.9 13.3,-18.8 13.3,-29.3 A 35.42,35.42 0 0 0 609,537.7 Z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_26_design2"
                  v-if="chartBodygraphChart2.gatesExt[26]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[26])"
                  @mouseover="searchAcPlanets(26)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        26
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 457.5,484.4 a 337.29,337.29 0 0 0 -51.6,3.8 l 3,9.8 a 327.49,327.49 0 0 1 48.6,-3.6 c 31.9,0 61.9,4.6 86.2,12.2 l 2.9,-9.8 c -25.1,-7.9 -55.8,-12.4 -89.1,-12.4 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_40_design2"
                  v-if="chartBodygraphChart2.gatesExt[40]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[40])"
                  @mouseover="searchAcPlanets(40)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        40
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 598.5,539.6 10.5,-2 c -9.5,-17 -31.9,-31.4 -62.5,-40.9 l -2.9,9.8 c 26.2,8.2 45.9,19.8 54.9,33.1 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_25_design2"
                  v-if="chartBodygraphChart2.gatesExt[25]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[25])"
                  @mouseover="searchAcPlanets(25)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        25
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 466,422.4 v 10 c 21.8,0 41.6,7.5 56.4,19.8 l 6.9,-7.3 A 98.63,98.63 0 0 0 466,422.4 Z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_51_design2"
                  v-if="chartBodygraphChart2.gatesExt[51]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[51])"
                  @mouseover="searchAcPlanets(51)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        51
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 529.3,444.9 -6.9,7.3 c 16.3,13.5 26.6,32.8 26.6,54.2 h 10 c 0,-24.2 -11.4,-46.1 -29.7,-61.5 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <path
                  id="gate_44_design2"
                  v-if="chartBodygraphChart2.gatesExt[44]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[44])"
                  @mouseover="searchAcPlanets(44)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        44
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  d="m 311,555.9 c 0,-26.3 41.4,-49.3 97.9,-57.9 l -3,-9.8 c -61.5,9.6 -104.9,36 -104.9,67.7 0,7.9 2.7,15.5 7.7,22.6 l 10,-3.3 c -5,-6.1 -7.7,-12.6 -7.7,-19.3 z"
                  transform="translate(-46.94,-28.51)"
                  inkscape:connector-curvature="0"
                  style="fill:#fb8c00;opacity:0;display:none"
                  sodipodi:nodetypes="sccsccs"
                />
              </g>
              <g
                id="Слой_11-2"
                style="display:inline"
                data-name="Слой 11"
                transform="translate(31.140015,17.409983)"
              >
                <rect
                  id="gate_42_design2"
                  v-if="chartBodygraphChart2.gatesExt[42]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[42])"
                  @mouseover="searchAcPlanets(42)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        42
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  x="383.35999"
                  y="570.89001"
                  width="10"
                  height="57"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_05_design2"
                  v-if="chartBodygraphChart2.gatesExt[5]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[5])"
                  @mouseover="searchAcPlanets(5)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        5
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  x="383.35999"
                  y="483.89001"
                  width="10"
                  height="87"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_05_person2"
                  v-if="chartBodygraphChart2.gatesExt[5]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[5])"
                  @mouseover="searchAcPlanets(5)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
                        5
                      ) && isChartToggle,
                    person_color: isChartToggle && !isChart2Toggle
                  }"
                  x="383.35999"
                  y="483.89001"
                  width="10"
                  height="87"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_15_design2"
                  v-if="chartBodygraphChart2.gatesExt[15]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[15])"
                  @mouseover="searchAcPlanets(15)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        15
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  x="383.35999"
                  y="401.39001"
                  width="10"
                  height="82.5"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_07_design2"
                  v-if="chartBodygraphChart2.gatesExt[7]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[7])"
                  @mouseover="searchAcPlanets(7)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        7
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  x="383.35999"
                  y="339.89001"
                  width="10"
                  height="61.5"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_31_design2"
                  v-if="chartBodygraphChart2.gatesExt[31]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[31])"
                  @mouseover="searchAcPlanets(31)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        31
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  x="383.35999"
                  y="270.89001"
                  width="10"
                  height="69"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_62_design2"
                  v-if="chartBodygraphChart2.gatesExt[62]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[62])"
                  @mouseover="searchAcPlanets(62)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        62
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  x="383.35999"
                  y="214.89"
                  width="10"
                  height="56"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_17_design2"
                  v-if="chartBodygraphChart2.gatesExt[17]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[17])"
                  @mouseover="searchAcPlanets(17)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        17
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  x="383.35999"
                  y="145.39"
                  width="10"
                  height="69.5"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_64_design2"
                  v-if="chartBodygraphChart2.gatesExt[64]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[64])"
                  @mouseover="searchAcPlanets(64)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        64
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  x="383.35999"
                  y="73.889999"
                  width="10"
                  height="32"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_60_design2"
                  v-if="chartBodygraphChart2.gatesExt[60]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[60])"
                  @mouseover="searchAcPlanets(60)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        60
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  x="403.85999"
                  y="627.89001"
                  width="10"
                  height="79"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_03_design2"
                  v-if="chartBodygraphChart2.gatesExt[3]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[3])"
                  @mouseover="searchAcPlanets(3)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        3
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  x="403.85999"
                  y="570.89001"
                  width="10"
                  height="57"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_14_design2"
                  v-if="chartBodygraphChart2.gatesExt[14]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[14])"
                  @mouseover="searchAcPlanets(14)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        14
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  x="403.85999"
                  y="483.89001"
                  width="10"
                  height="87"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_02_design2"
                  v-if="chartBodygraphChart2.gatesExt[2]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[2])"
                  @mouseover="searchAcPlanets(2)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        2
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  x="403.85999"
                  y="401.39001"
                  width="10"
                  height="82.5"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_01_design2"
                  v-if="chartBodygraphChart2.gatesExt[1]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[1])"
                  @mouseover="searchAcPlanets(1)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        1
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  x="403.85999"
                  y="339.89001"
                  width="10"
                  height="61.5"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_08_design2"
                  v-if="chartBodygraphChart2.gatesExt[8]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[8])"
                  @mouseover="searchAcPlanets(8)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        8
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  x="403.85999"
                  y="270.89001"
                  width="10"
                  height="69"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_23_design2"
                  v-if="chartBodygraphChart2.gatesExt[23]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[23])"
                  @mouseover="searchAcPlanets(23)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        23
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  x="403.85999"
                  y="214.89"
                  width="10"
                  height="56"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_43_design2"
                  v-if="chartBodygraphChart2.gatesExt[43]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[43])"
                  @mouseover="searchAcPlanets(43)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        43
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  x="403.85999"
                  y="145.39"
                  width="10"
                  height="69.5"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_61_design2"
                  v-if="chartBodygraphChart2.gatesExt[61]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[61])"
                  @mouseover="searchAcPlanets(61)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        61
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  x="403.85999"
                  y="73.889999"
                  width="10"
                  height="32"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_52_design2"
                  v-if="chartBodygraphChart2.gatesExt[52]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[52])"
                  @mouseover="searchAcPlanets(52)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        52
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  x="424.35999"
                  y="627.89001"
                  width="10"
                  height="79"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_09_design2"
                  v-if="chartBodygraphChart2.gatesExt[9]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[9])"
                  @mouseover="searchAcPlanets(9)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        9
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  x="424.35999"
                  y="570.89001"
                  width="10"
                  height="57"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_29_design2"
                  v-if="chartBodygraphChart2.gatesExt[29]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[29])"
                  @mouseover="searchAcPlanets(29)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        29
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  x="424.35999"
                  y="483.89001"
                  width="10"
                  height="87"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_46_design2"
                  v-if="chartBodygraphChart2.gatesExt[46]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[46])"
                  @mouseover="searchAcPlanets(46)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        46
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  x="424.35999"
                  y="401.39001"
                  width="10"
                  height="82.5"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_13_design2"
                  v-if="chartBodygraphChart2.gatesExt[13]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[13])"
                  @mouseover="searchAcPlanets(13)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        13
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  x="424.35999"
                  y="339.89001"
                  width="10"
                  height="61.5"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_33_design2"
                  v-if="chartBodygraphChart2.gatesExt[33]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[33])"
                  @mouseover="searchAcPlanets(33)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        33
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  x="424.35999"
                  y="270.89001"
                  width="10"
                  height="69"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_56_design2"
                  v-if="chartBodygraphChart2.gatesExt[56]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[56])"
                  @mouseover="searchAcPlanets(56)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        56
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  x="424.35999"
                  y="214.89"
                  width="10"
                  height="56"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_11_design2"
                  v-if="chartBodygraphChart2.gatesExt[11]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[11])"
                  @mouseover="searchAcPlanets(11)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        11
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  x="424.35999"
                  y="145.39"
                  width="10"
                  height="69.5"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_04_design2"
                  v-if="chartBodygraphChart2.gatesExt[4]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[4])"
                  @mouseover="searchAcPlanets(4)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        4
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  x="424.35999"
                  y="105.89"
                  width="10"
                  height="39.5"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_63_design2"
                  v-if="chartBodygraphChart2.gatesExt[63]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[63])"
                  @mouseover="searchAcPlanets(63)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        63
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  x="424.35999"
                  y="73.889999"
                  width="10"
                  height="32"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_53_design2"
                  v-if="chartBodygraphChart2.gatesExt[53]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[53])"
                  @mouseover="searchAcPlanets(53)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        53
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  x="383.35999"
                  y="627.89001"
                  width="10"
                  height="79"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_47_design2"
                  v-if="chartBodygraphChart2.gatesExt[47]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[47])"
                  @mouseover="searchAcPlanets(47)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        47
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  x="383.35999"
                  y="105.89"
                  width="10"
                  height="39.5"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
                <rect
                  id="gate_24_design2"
                  v-if="chartBodygraphChart2.gatesExt[24]"
                  @click="openTooltipDialog(chartBodygraphChart2.gatesExt[24])"
                  @mouseover="searchAcPlanets(24)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphChart2.gatesActivatedAtDesignOnly,
                        24
                      ) && isChartToggle,
                    design_color: isChartToggle && !isChart2Toggle
                  }"
                  x="403.85999"
                  y="105.89"
                  width="10"
                  height="39.5"
                  style="fill:#fb8c00;opacity:0;display:none"
                />
              </g>
            </g>
            <g
              id="layer6"
              inkscape:groupmode="layer"
              inkscape:label="Gates Both"
              style="display:inline"
              sodipodi:insensitive="true"
            >
              <g
                id="Слой_16"
                style="display:inline"
                data-name="Слой 16"
                transform="translate(31.134997,17.409983)"
              >
                <g
                  id="gate_44_connection"
                  v-if="chartBodygraphConnection.gatesExt[44]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[44])
                  "
                  @mouseover="searchAcPlanets(44)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 44) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path484"
                    d="m 311,555.9 c 0,-26.3 41.4,-49.3 97.9,-57.9 l -3,-9.8 c -61.5,9.6 -104.9,36 -104.9,67.7 0,7.9 2.7,15.5 7.7,22.6 l 10,-3.3 c -5,-6.1 -7.7,-12.6 -7.7,-19.3 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#4caf50"
                    sodipodi:nodetypes="sccsccs"
                  />
                  <path
                    id="path486"
                    d="m 312.2,563.6 -9.7,2.3 a 46.94,46.94 0 0 1 -1.3,-13.6 l 10,0.4 a 38.86,38.86 0 0 0 1,10.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path488"
                    d="m 313.6,544.4 -8.9,-4.6 c 2.01937,-3.91099 4.77116,-7.62682 7.475,-11.1 l 7.5,6.53125 c -2.31213,3.06971 -4.2599,5.78135 -6.075,9.16875 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                    sodipodi:nodetypes="ccccc"
                  />
                  <path
                    id="path490"
                    d="m 328.6,527 -6.1,-7.9 c 3.3,-2.5 6.8,-5 10.6,-7.4 l 5.3,8.5 c -3.5,2.2 -6.8,4.5 -9.8,6.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path492"
                    d="m 348.9,514.9 -4.3,-9 c 3.7,-1.8 7.7,-3.5 11.7,-5.1 l 3.6,9.3 c -3.8,1.5 -7.5,3.1 -11,4.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path494"
                    d="m 371.8,506.4 -3.1,-9.5 c 3.9,-1.3 8,-2.4 12.2,-3.5 l 2.5,9.7 c -4,1 -7.9,2.1 -11.6,3.3 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path496"
                    d="m 394.91563,500.49687 -2.07813,-9.90937 c 4.1,-0.8 8.8625,-1.7875 13.0625,-2.3875 l 3,9.8 c -4,0.6 -10.08437,1.69687 -13.98437,2.49687 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                    sodipodi:nodetypes="ccccc"
                  />
                  <path
                    id="gate_44_both_con"
                    d="m 311,555.9 c 0,-26.3 41.4,-49.3 97.9,-57.9 l -3,-9.8 c -61.5,9.6 -104.9,36 -104.9,67.7 0,7.9 2.7,15.5 7.7,22.6 l 10,-3.3 c -5,-6.1 -7.7,-12.6 -7.7,-19.3 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                    sodipodi:nodetypes="sccsccs"
                  />
                </g>
                <g
                  id="gate_41_connection"
                  v-if="chartBodygraphConnection.gatesExt[41]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[41])
                  "
                  @mouseover="searchAcPlanets(41)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 41) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path499"
                    d="m 586.9,704.9 -5.7,-8.3 c -33.9,20.7 -77.5,33.1 -121.8,33.7 l 0.5,10 a 266.48,266.48 0 0 0 90.5,-17.3 225.62,225.62 0 0 0 36.5,-18.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#4caf50"
                  />
                  <path
                    id="path501"
                    d="m 509.2,735.3 -2,-9.8 c 3.9,-0.8 7.7,-1.6 11.5,-2.6 l 2.4,9.7 c -3.9,1 -7.9,1.9 -11.9,2.7 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path503"
                    d="m 532.9,729.3 -2.9,-9.6 c 3.7,-1.1 7.5,-2.4 11.2,-3.7 l 3.4,9.4 q -5.85,2.1 -11.7,3.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path505"
                    d="m 555.4,721.2 -3.8,-9.2 c 3.6,-1.5 7.2,-3.1 10.7,-4.8 l 4.3,9 c -3.7,1.7 -7.5,3.4 -11.2,5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path507"
                    d="m 577.7,710.2 -4.8,-8.8 c 3.4,-1.9 4.4,-2.4 7.6,-4.4 l 5.6,8.1 c -3.4,2.2 -4.7,3.1 -8.4,5.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="gate_41_both_con"
                    d="m 586.9,704.9 -5.7,-8.3 c -33.9,20.7 -77.5,33.1 -121.8,33.7 l 0.5,10 a 266.48,266.48 0 0 0 90.5,-17.3 225.62,225.62 0 0 0 36.5,-18.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_58_connection"
                  v-if="chartBodygraphConnection.gatesExt[58]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[58])
                  "
                  @mouseover="searchAcPlanets(58)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 58) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path510"
                    d="m 319.7,701.5 c 37.6,25.1 85.6,38.8 135.7,38.9 v -10 c -48.1,-0.1 -94.1,-13.3 -130.1,-37.3 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#4caf50"
                  />
                  <path
                    id="path512"
                    d="m 399.9,734.5 c -4,-0.9 -8,-1.8 -11.9,-2.9 l 2.6,-9.7 c 3.8,1 7.6,1.9 11.4,2.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path514"
                    d="m 376.3,728.2 c -3.9,-1.2 -7.8,-2.6 -11.6,-4 l 3.5,-9.4 c 3.6,1.4 7.4,2.7 11.1,3.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path516"
                    d="m 353.3,719.5 c -3.8,-1.6 -7.5,-3.4 -11.1,-5.2 l 4.5,-8.9 c 3.5,1.7 7,3.4 10.6,5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path518"
                    d="m 331.4,708.5 c -3.6,-2.1 -7.1,-4.2 -10.5,-6.4 l 5.5,-8.4 c 3.2,2.1 6.6,4.2 10,6.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="gate_58_both_con"
                    d="m 319.7,701.5 c 37.6,25.1 85.6,38.8 135.7,38.9 v -10 c -48.1,-0.1 -94.1,-13.3 -130.1,-37.3 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_30_connection"
                  v-if="chartBodygraphConnection.gatesExt[30]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[30])
                  "
                  @mouseover="searchAcPlanets(30)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 30) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path521"
                    d="m 653.6,582.8 -10,1.1 c 0.3,6.8 0.6,13.7 0.7,20.6 0.4,22.9 -10.2,46 -30.7,66.8 a 172.56,172.56 0 0 1 -32.4,25.4 l 5.7,8.3 a 178.16,178.16 0 0 0 33.8,-26.6 c 22.4,-22.8 34,-48.3 33.6,-74 -0.1,-7.3 -0.4,-14.5 -0.7,-21.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#4caf50"
                  />
                  <path
                    id="path523"
                    d="m 598.6,697.1 -5.8,-8.1 c 3.2,-2.3 6.3,-4.7 9.2,-7.1 l 6.4,7.7 c -3.2,2.6 -6.5,5.1 -9.8,7.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path525"
                    d="m 617.7,681.5 -6.9,-7.2 c 2.8,-2.7 5.5,-5.5 8,-8.3 l 7.5,6.6 c -2.7,3 -5.6,6 -8.6,8.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path527"
                    d="m 633.9,663 -8.1,-5.9 a 113,113 0 0 0 6.3,-9.6 l 8.6,5.1 a 108.84,108.84 0 0 1 -6.8,10.4 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path529"
                    d="m 646.9,641.2 -9.2,-4 a 90.7,90.7 0 0 0 3.9,-10.6 l 9.6,2.8 a 93.37,93.37 0 0 1 -4.3,11.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="gate_30_both_con"
                    d="m 653.6,582.8 -10,1.1 c 0.3,6.8 0.6,13.7 0.7,20.6 0.4,22.9 -10.2,46 -30.7,66.8 a 172.56,172.56 0 0 1 -32.4,25.4 l 5.7,8.3 a 178.16,178.16 0 0 0 33.8,-26.6 c 22.4,-22.8 34,-48.3 33.6,-74 -0.1,-7.3 -0.4,-14.5 -0.7,-21.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_18_connection"
                  v-if="chartBodygraphConnection.gatesExt[18]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[18])
                  "
                  @mouseover="searchAcPlanets(18)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 18) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path532"
                    d="m 257.3,604.4 c 0,34.1 21.4,68.6 58.8,94.7 1.2,0.8 2.3,1.6 3.5,2.4 l 5.5,-8.3 c -1.1,-0.8 -2.2,-1.5 -3.4,-2.3 -34.7,-24.1 -54.5,-55.7 -54.5,-86.5 0,-6.6 0.2,-13.2 0.5,-19.9 l -10,-1 c -0.2,7 -0.4,13.9 -0.4,20.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#4caf50"
                  />
                  <path
                    id="path534"
                    d="m 310.7,695.3 q -4.95,-3.75 -9.6,-7.8 l 6.6,-7.5 c 2.9,2.5 5.9,5 9.1,7.3 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path536"
                    d="m 291.9,678.9 c -2.9,-3 -5.8,-6.1 -8.4,-9.2 l 7.7,-6.4 c 2.4,2.9 5,5.8 7.8,8.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path538"
                    d="m 276,659.8 c -2.4,-3.5 -4.6,-7.1 -6.6,-10.7 l 8.8,-4.8 a 112,112 0 0 0 6,9.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path540"
                    d="m 263.9,637.7 a 93.1,93.1 0 0 1 -3.9,-12 l 9.7,-2.5 a 90.41,90.41 0 0 0 3.5,10.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="gate_18_both_con"
                    d="m 257.3,604.4 c 0,34.1 21.4,68.6 58.8,94.7 1.2,0.8 2.3,1.6 3.5,2.4 l 5.5,-8.3 c -1.1,-0.8 -2.2,-1.5 -3.4,-2.3 -34.7,-24.1 -54.5,-55.7 -54.5,-86.5 0,-6.6 0.2,-13.2 0.5,-19.9 l -10,-1 c -0.2,7 -0.4,13.9 -0.4,20.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_48_connection"
                  v-if="chartBodygraphConnection.gatesExt[48]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[48])
                  "
                  @mouseover="searchAcPlanets(48)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 48) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path543"
                    d="m 314.7,381.8 c -33.2,57.3 -53.5,129.8 -56.9,201.8 l 10,1 c 3.2,-70.6 23,-141.7 55.5,-197.8 3.5,-6 7,-11.7 10.6,-17.1 l -9.2,-4.3 c -3.4,5.2 -6.7,10.7 -10,16.4 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#4caf50"
                  />
                  <path
                    id="path545"
                    d="m 271.6,542.1 -9.9,-1.4 1.8,-12 9.9,1.6 c -0.6,3.9 -1.2,7.9 -1.8,11.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path547"
                    d="m 275.7,518.5 -9.8,-1.9 c 0.8,-4 1.6,-8 2.4,-11.9 l 9.8,2.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path549"
                    d="m 280.1,496.3 -9.7,-2.4 c 1,-3.9 2,-7.9 3,-11.7 l 9.6,2.6 c -0.9,3.8 -1.9,7.6 -2.9,11.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path551"
                    d="m 286.4,473.4 -9.6,-2.9 c 1.2,-3.9 2.4,-7.8 3.7,-11.6 l 9.5,3.1 q -1.95,5.7 -3.6,11.4 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path553"
                    d="m 293.8,450.8 -9.4,-3.4 c 1.4,-3.8 2.8,-7.6 4.3,-11.4 l 9.3,3.7 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path555"
                    d="m 302.5,428.8 -9.2,-3.9 c 1.6,-3.7 3.3,-7.5 4.9,-11.1 l 9.1,4.2 q -2.4,5.25 -4.8,10.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path557"
                    d="m 312.5,407.3 -8.9,-4.5 c 1.8,-3.6 3.7,-7.3 5.6,-10.8 l 8.8,4.8 c -1.9,3.4 -3.7,6.9 -5.5,10.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path559"
                    d="m 323.8,386.5 -8.6,-5.1 c 2.1,-3.5 4.2,-7 6.4,-10.4 l 8.4,5.4 c -2.1,3.3 -4.2,6.6 -6.2,10.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="gate_48_both_con"
                    d="m 314.7,381.8 c -33.2,57.3 -53.5,129.8 -56.9,201.8 l 10,1 c 3.2,-70.6 23,-141.7 55.5,-197.8 3.5,-6 7,-11.7 10.6,-17.1 l -9.2,-4.3 c -3.4,5.2 -6.7,10.7 -10,16.4 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_36_connection"
                  v-if="chartBodygraphConnection.gatesExt[36]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[36])
                  "
                  @mouseover="searchAcPlanets(36)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 36) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path562"
                    d="m 582,369.9 c 1.8,2.7 3.5,5.5 5.2,8.3 32.6,54.1 52.3,126.5 56.4,205.7 l 10,-1.1 c -4.2,-80.6 -24.4,-154.4 -57.8,-209.7 -1.6,-2.7 -3.2,-5.2 -4.9,-7.8 l -9.2,4.3 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#4caf50"
                  />
                  <path
                    id="path564"
                    d="m 639.1,535.1 c -0.5,-3.9 -1.1,-7.9 -1.8,-11.8 l 9.9,-1.6 1.8,12 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path566"
                    d="m 635.4,511.8 c -0.7,-3.9 -1.5,-7.8 -2.3,-11.7 l 9.8,-2 c 0.8,3.9 1.6,7.9 2.3,11.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path568"
                    d="m 630.4,488.6 c -0.9,-3.9 -1.9,-7.7 -2.9,-11.5 l 9.7,-2.5 c 1,3.9 2,7.8 2.9,11.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path570"
                    d="m 624.5,465.9 c -1.1,-3.8 -2.3,-7.6 -3.5,-11.4 l 9.5,-3 c 1.2,3.8 2.4,7.7 3.5,11.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path572"
                    d="m 617.3,443.2 c -1.3,-3.7 -2.7,-7.5 -4.1,-11.1 l 9.3,-3.6 c 1.4,3.7 2.8,7.6 4.2,11.4 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path574"
                    d="m 608.8,421 q -2.25,-5.55 -4.8,-10.8 l 9.1,-4.2 c 1.7,3.6 3.3,7.4 4.9,11.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path576"
                    d="m 599.2,399.8 c -1.8,-3.5 -3.6,-7.1 -5.5,-10.5 l 8.8,-4.8 q 2.85,5.25 5.7,10.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path578"
                    d="m 588.9,381 c -2,-3.4 -4.1,-6.7 -6.3,-10 l 9,-4.4 c 2.2,3.4 3.8,5.7 5.9,9.2 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="gate_36_both_con"
                    d="m 582,369.9 c 1.8,2.7 3.5,5.5 5.2,8.3 32.6,54.1 52.3,126.5 56.4,205.7 l 10,-1.1 c -4.2,-80.6 -24.4,-154.4 -57.8,-209.7 -1.6,-2.7 -3.2,-5.2 -4.9,-7.8 l -9.2,4.3 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_16_connection"
                  v-if="chartBodygraphConnection.gatesExt[16]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[16])
                  "
                  @mouseover="searchAcPlanets(16)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 16) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path581"
                    d="m 324.8,365.4 9.2,4.3 c 34.8,-52.2 77.2,-80.8 120.9,-81.2 v -10 c -26.6,0.2 -52.7,9.9 -77.4,28.8 -19.2,14.5 -36.8,34 -52.7,58.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#4caf50"
                  />
                  <path
                    id="path583"
                    d="m 336.9,365.2 -8.2,-5.7 c 2.3,-3.4 4.8,-6.7 7.2,-9.9 l 8,6.1 c -2.4,3 -4.7,6.2 -7,9.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path585"
                    d="m 351.2,346.5 -7.7,-6.4 c 2.6,-3.2 5.4,-6.3 8.1,-9.2 l 7.3,6.8 c -2.5,2.8 -5.1,5.7 -7.7,8.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path587"
                    d="m 367.2,329.3 -6.9,-7.2 c 3,-2.9 6,-5.7 9.1,-8.3 l 6.5,7.6 c -3,2.5 -5.9,5.1 -8.7,7.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path589"
                    d="m 384.9,314.1 -6,-8 c 3.3,-2.5 6.8,-4.8 10.2,-7 l 5.4,8.4 q -4.8,3.15 -9.6,6.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path591"
                    d="m 404.5,301.8 -4.6,-8.9 c 3.7,-1.9 7.5,-3.7 11.3,-5.3 l 3.8,9.2 c -3.5,1.5 -7,3.2 -10.5,5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="gate_16_both_con"
                    d="m 324.8,365.4 9.2,4.3 c 34.8,-52.2 77.2,-80.8 120.9,-81.2 v -10 c -26.6,0.2 -52.7,9.9 -77.4,28.8 -19.2,14.5 -36.8,34 -52.7,58.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_35_connection"
                  v-if="chartBodygraphConnection.gatesExt[35]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[35])
                  "
                  @mouseover="searchAcPlanets(35)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 35) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path594"
                    d="m 455.8,288.4 c 47.3,0 91.7,28.7 125.9,81.2 l 9.2,-4.3 c -0.1,-0.2 -0.3,-0.4 -0.4,-0.6 C 554.3,309 506.8,278.4 455.8,278.4 h -0.9 v 10 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#4caf50"
                  />
                  <path
                    id="path596"
                    d="m 574.8,359.2 c -2.3,-3.2 -4.7,-6.3 -7.1,-9.3 l 7.8,-6.3 c 2.5,3.1 5,6.4 7.4,9.7 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path598"
                    d="m 559.9,340.9 c -2.6,-2.9 -5.3,-5.8 -8,-8.5 l 7.1,-7.1 c 2.8,2.8 5.7,5.8 8.4,8.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path600"
                    d="m 543.5,324.3 c -2.9,-2.6 -6,-5.1 -9,-7.4 l 6.1,-7.9 c 3.2,2.5 6.4,5.1 9.5,7.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path602"
                    d="m 525.1,310.2 c -3.3,-2.1 -6.6,-4.2 -9.9,-6 l 4.8,-8.8 c 3.6,2 7.1,4.1 10.6,6.4 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path604"
                    d="M 504.7,299.1 A 107.79,107.79 0 0 0 494,294.9 l 3.2,-9.5 c 3.9,1.3 7.8,2.8 11.6,4.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="gate_35_both_con"
                    d="m 455.8,288.4 c 47.3,0 91.7,28.7 125.9,81.2 l 9.2,-4.3 c -0.1,-0.2 -0.3,-0.4 -0.4,-0.6 C 554.3,309 506.8,278.4 455.8,278.4 h -0.9 v 10 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_39_connection"
                  v-if="chartBodygraphConnection.gatesExt[39]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[39])
                  "
                  @mouseover="searchAcPlanets(39)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 39) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path607"
                    d="m 575.1,686.9 -5.3,-8.5 c -31.6,19.5 -72.5,30.9 -114,30.9 v 10 a 239.69,239.69 0 0 0 85.8,-15.8 190,190 0 0 0 33.5,-16.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#4caf50"
                  />
                  <path
                    id="path609"
                    d="m 508.7,713.7 -2.2,-9.8 c 3.8,-0.8 7.7,-1.8 11.4,-2.8 l 2.7,9.6 c -3.9,1.1 -7.9,2.1 -11.9,3 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path611"
                    d="m 532.5,706.8 -3.2,-9.5 c 3.7,-1.2 7.4,-2.6 11,-4 l 3.7,9.3 c -3.8,1.5 -7.7,2.9 -11.5,4.2 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path613"
                    d="m 555,698 -4.2,-9.1 c 3.6,-1.7 7.1,-3.4 10.4,-5.3 l 4.8,8.8 c -3.6,2 -7.2,3.8 -11,5.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="gate_39_both_con"
                    d="m 575.1,686.9 -5.3,-8.5 c -31.6,19.5 -72.5,30.9 -114,30.9 v 10 a 239.69,239.69 0 0 0 85.8,-15.8 190,190 0 0 0 33.5,-16.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_38_connection"
                  v-if="chartBodygraphConnection.gatesExt[38]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[38])
                  "
                  @mouseover="searchAcPlanets(38)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 38) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path616"
                    d="m 455.3,709.4 c -43.7,-0.1 -85.6,-12.2 -118.2,-34.2 l -5.5,8.3 c 34.2,23 78,35.7 123.7,35.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#4caf50"
                  />
                  <path
                    id="path618"
                    d="m 399.5,712.8 c -4,-1 -8,-2 -11.8,-3.2 l 2.9,-9.6 c 3.7,1.1 7.5,2.1 11.3,3.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path620"
                    d="m 376,705.7 q -5.85,-2.1 -11.4,-4.5 l 3.9,-9.2 c 3.5,1.5 7.2,3 10.9,4.3 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path622"
                    d="m 353.4,696.1 c -3.7,-1.8 -7.3,-3.8 -10.8,-5.8 l 5,-8.7 c 3.3,1.9 6.8,3.8 10.3,5.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="gate_38_both_con"
                    d="m 455.3,709.4 c -43.7,-0.1 -85.6,-12.2 -118.2,-34.2 l -5.5,8.3 c 34.2,23 78,35.7 123.7,35.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_55_connection"
                  v-if="chartBodygraphConnection.gatesExt[55]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[55])
                  "
                  @mouseover="searchAcPlanets(55)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 55) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path625"
                    d="m 635.9,595.8 c -0.1,-3.1 -0.1,-6.2 -0.2,-9.3 l -9.9,3 c 0.1,2.1 0.1,4.3 0.1,6.4 0.3,20.6 -9.2,41.4 -27.6,60.1 a 152.5,152.5 0 0 1 -28.5,22.4 l 5.3,8.5 A 165.59,165.59 0 0 0 605.4,663 c 20.3,-20.6 30.9,-43.9 30.5,-67.2 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#4caf50"
                  />
                  <path
                    id="path627"
                    d="m 576.8,685.8 -5.3,-8.5 c 3.3,-2.1 6.6,-4.3 9.6,-6.6 l 5.9,8.1 c -3.3,2.5 -6.7,4.8 -10.2,7 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path629"
                    d="m 596.4,671.4 -6.5,-7.6 c 3,-2.6 5.8,-5.2 8.5,-7.9 l 7.1,7 a 123.38,123.38 0 0 1 -9.1,8.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path631"
                    d="m 613.9,653.6 -7.8,-6.3 a 100.41,100.41 0 0 0 6.8,-9.3 l 8.4,5.4 c -2.3,3.5 -4.8,6.9 -7.4,10.2 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path633"
                    d="m 627.2,632.9 -9,-4.4 a 71.85,71.85 0 0 0 4.2,-10.5 l 9.5,3.1 a 74.39,74.39 0 0 1 -4.7,11.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="gate_55_both_con"
                    d="m 635.9,595.8 c -0.1,-3.1 -0.1,-6.2 -0.2,-9.3 l -9.9,3 c 0.1,2.1 0.1,4.3 0.1,6.4 0.3,20.6 -9.2,41.4 -27.6,60.1 a 152.5,152.5 0 0 1 -28.5,22.4 l 5.3,8.5 A 165.59,165.59 0 0 0 605.4,663 c 20.3,-20.6 30.9,-43.9 30.5,-67.2 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_28_connection"
                  v-if="chartBodygraphConnection.gatesExt[28]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[28])
                  "
                  @mouseover="searchAcPlanets(28)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 28) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path636"
                    d="m 275.7,595.9 c 0,31 19.5,62.4 53.4,86 0.8,0.6 1.6,1.1 2.4,1.6 l 5.5,-8.3 c -0.8,-0.5 -1.5,-1 -2.2,-1.5 -31.2,-21.8 -49.1,-50.1 -49.1,-77.8 0,-3.2 0.1,-6.3 0.1,-9.5 l -10,-1 c 0,3.5 -0.1,7 -0.1,10.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#4caf50"
                  />
                  <path
                    id="path638"
                    d="m 330.6,682.9 a 108.07,108.07 0 0 1 -8.9,-6.4 l 6.2,-7.9 c 3,2.3 6.2,4.6 8.3,6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path640"
                    d="m 312.5,668.7 a 121.75,121.75 0 0 1 -8.8,-8.8 l 7.4,-6.8 c 2.5,2.8 5.3,5.5 8.2,8.2 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path642"
                    d="m 295.4,650 a 111.39,111.39 0 0 1 -7,-10.4 l 8.6,-5.1 a 93,93 0 0 0 6.4,9.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path644"
                    d="m 282.8,628.4 a 95.23,95.23 0 0 1 -4.2,-12 l 9.7,-2.6 a 89.08,89.08 0 0 0 3.7,10.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="gate_28_both_con"
                    d="m 275.7,595.9 c 0,31 19.5,62.4 53.4,86 0.8,0.6 1.6,1.1 2.4,1.6 l 5.5,-8.3 c -0.8,-0.5 -1.5,-1 -2.2,-1.5 -31.2,-21.8 -49.1,-50.1 -49.1,-77.8 0,-3.2 0.1,-6.3 0.1,-9.5 l -10,-1 c 0,3.5 -0.1,7 -0.1,10.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_22_connection"
                  v-if="chartBodygraphConnection.gatesExt[22]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[22])
                  "
                  @mouseover="searchAcPlanets(22)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 22) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path647"
                    d="m 574.3,391.4 c 31.1,51.6 49.3,121.8 51.4,198.1 l 9.9,-3 c -2.6,-77 -21.2,-147.8 -52.8,-200.2 -2.8,-4.6 -5.6,-9 -8.6,-13.3 l -9.2,4.3 c 3.3,4.5 6.3,9.2 9.3,14.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#4caf50"
                  />
                  <path
                    id="path649"
                    d="m 624,557 c -0.4,-3.9 -0.8,-7.9 -1.2,-11.8 l 9.9,-1.1 c 0.5,4 0.9,8 1.2,12.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path651"
                    d="m 621.3,533.3 c -0.5,-3.9 -1.1,-7.9 -1.8,-11.7 l 9.9,-1.6 c 0.6,3.9 1.3,8 1.8,12 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path653"
                    d="m 617.3,510.1 c -0.7,-3.9 -1.5,-7.8 -2.4,-11.6 l 9.8,-2.1 c 0.9,3.9 1.7,7.9 2.4,11.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path655"
                    d="m 612.3,486.8 c -0.9,-3.8 -2,-7.7 -3,-11.5 l 9.6,-2.7 c 1.1,3.9 2.1,7.8 3.1,11.7 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path657"
                    d="m 605.8,463.8 c -1.2,-3.8 -2.4,-7.6 -3.7,-11.3 l 9.5,-3.2 c 1.3,3.8 2.6,7.7 3.8,11.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path659"
                    d="m 598.3,441.5 c -1.4,-3.7 -2.9,-7.4 -4.4,-11 l 9.2,-3.9 c 1.5,3.7 3.1,7.5 4.5,11.3 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path661"
                    d="m 589.5,420.2 c -1.7,-3.6 -3.4,-7.2 -5.2,-10.6 l 8.9,-4.5 c 1.8,3.6 3.6,7.3 5.3,11 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path663"
                    d="m 578.7,399.1 c -1.9,-3.5 -4,-6.9 -6,-10.1 l 8.5,-5.3 c 2.1,3.4 4.2,6.9 6.2,10.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="gate_22_both_con"
                    d="m 574.3,391.4 c 31.1,51.6 49.3,121.8 51.4,198.1 l 9.9,-3 c -2.6,-77 -21.2,-147.8 -52.8,-200.2 -2.8,-4.6 -5.6,-9 -8.6,-13.3 l -9.2,4.3 c 3.3,4.5 6.3,9.2 9.3,14.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_57_connection"
                  v-if="chartBodygraphConnection.gatesExt[57]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[57])
                  "
                  @mouseover="searchAcPlanets(57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 57) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path8089"
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:#4caf50"
                  />
                  <path
                    id="path668"
                    d="m 241.06,526.89 -10,-1 c 0.4,-4 0.9,-8.1 1.4,-12.1 l 9.9,1.3 c -0.5,3.9 -0.9,7.8 -1.3,11.8 z"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path670"
                    d="m 244.06,503.29 -9.9,-1.5 q 0.9,-6 2.1,-12 l 9.8,1.8 c -0.7,3.8 -1.4,7.8 -2,11.7 z"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="gate_57_both_con"
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_12_connection"
                  v-if="chartBodygraphConnection.gatesExt[12]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[12])
                  "
                  @mouseover="searchAcPlanets(12)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 12) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path685"
                    d="m 455.8,310.4 c 39.9,0 77.6,22.7 107.6,64.5 0.6,0.8 1.1,1.6 1.7,2.4 l 9.2,-4.3 c -0.5,-0.7 -0.9,-1.4 -1.4,-2.1 -32.1,-45.6 -73.2,-70.6 -117.1,-70.6 h -0.9 v 10 a 2.77,2.77 0 0 0 0.9,0.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#4caf50"
                  />
                  <path
                    id="path687"
                    d="m 564.4,376.5 c -2.2,-3.3 -3.1,-4.4 -5.4,-7.4 l 7.9,-6.1 c 2.4,3.2 4.9,6.5 7.2,9.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path689"
                    d="m 552.1,360.4 c -2.6,-3 -5.2,-5.9 -7.9,-8.6 l 7.2,-7 c 2.8,2.9 5.6,5.9 8.3,9.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path691"
                    d="m 535.8,343.6 c -2.9,-2.6 -5.9,-5.1 -8.9,-7.4 l 6.1,-7.9 c 3.2,2.5 6.4,5.1 9.5,7.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path693"
                    d="m 517.2,329.5 a 112.5,112.5 0 0 0 -10,-5.8 l 4.7,-8.9 c 3.6,1.9 7.2,4 10.7,6.3 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="gate_12_both_con"
                    d="m 455.8,310.4 c 39.9,0 77.6,22.7 107.6,64.5 0.6,0.8 1.1,1.6 1.7,2.4 l 9.2,-4.3 c -0.5,-0.7 -0.9,-1.4 -1.4,-2.1 -32.1,-45.6 -73.2,-70.6 -117.1,-70.6 h -0.9 v 10 a 2.77,2.77 0 0 0 0.9,0.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_20_connection"
                  v-if="chartBodygraphConnection.gatesExt[20]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[20])
                  "
                  @mouseover="searchAcPlanets(20)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 20) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path8091"
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:#4caf50"
                  />
                  <path
                    id="path672"
                    transform="translate(-31.134997,-17.409983)"
                    d="m 272.51562,483.40625 c -0.10912,0.44615 -0.22035,0.89121 -0.32812,1.33789 -0.44354,1.89227 -0.86225,3.78956 -1.30273,5.69531 h 10.23437 c 0.0517,-0.2218 0.12378,-0.43458 0.17578,-0.65625 0.30752,-1.33162 0.58872,-2.66648 0.90039,-3.98242 z"
                    style="fill:#fb8c00"
                    inkscape:connector-curvature="0"
                  />
                  <path
                    id="path674"
                    d="m 254.06,456.89 -9.6,-2.7 c 1.1,-3.9 2.2,-7.8 3.4,-11.7 l 9.6,2.9 c -1.2,3.8 -2.4,7.7 -3.4,11.5 z"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path676"
                    d="m 260.96,434.19 -9.5,-3.2 c 1.3,-3.8 2.7,-7.7 4.1,-11.5 l 9.4,3.5 c -1.4,3.7 -2.7,7.5 -4,11.2 z"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path678"
                    d="m 269.26,411.99 -9.3,-3.8 c 1.5,-3.8 3.1,-7.5 4.8,-11.2 l 9.1,4.1 c -1.5,3.6 -3.1,7.2 -4.6,10.9 z"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path680"
                    d="m 278.96,390.39 -9,-4.4 c 1.8,-3.7 3.7,-7.3 5.5,-10.8 l 8.8,4.7 c -1.8,3.4 -3.6,6.9 -5.3,10.5 z"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path682"
                    d="m 290.06,369.59 -8.6,-5 c 2.1,-3.5 4.2,-7.1 6.4,-10.4 l 8.4,5.4 c -2.1,3.1 -4.2,6.5 -6.2,10 z"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path698"
                    d="m 303.46,348.79 -8.4,-5.1 c 2.4,-3.4 4.8,-6.7 7.3,-9.8 l 7.9,6.1 c -2.5,3 -4.5,5.6 -6.8,8.8 z"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                    sodipodi:nodetypes="ccccc"
                  />
                  <path
                    id="path700"
                    d="m 316.96,331.49 -7.6,-6.5 c 2.7,-3.1 5.5,-6.2 8.3,-9.1 l 7.2,7 c -2.6,2.7 -5.3,5.6 -7.9,8.6 z"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path702"
                    d="m 333.56,314.49 -6.7,-7.4 c 3.1,-2.8 6.3,-5.5 9.4,-8 l 6.2,7.9 c -3,2.3 -6,4.9 -8.9,7.5 z"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path704"
                    d="m 351.96,300.29 -5.5,-8.3 c 3.5,-2.3 7.1,-4.5 10.7,-6.4 l 4.8,8.8 a 109.46,109.46 0 0 0 -10,5.9 z"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="gate_20_both_con"
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_19_connection"
                  v-if="chartBodygraphConnection.gatesExt[19]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[19])
                  "
                  @mouseover="searchAcPlanets(19)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 19) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path707"
                    d="m 557.3,659.4 c -27.9,17.6 -64.4,27.9 -101.4,27.9 v 10 c 39.5,0 77.5,-10.7 107,-29.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#4caf50"
                  />
                  <path
                    id="path709"
                    d="m 505.5,691.4 -2.3,-9.7 c 3.8,-0.9 7.6,-1.9 11.3,-3 l 2.9,9.6 c -3.9,1.1 -7.9,2.2 -11.9,3.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path711"
                    d="m 529.1,684.3 -3.5,-9.4 c 3.7,-1.4 7.3,-2.8 10.8,-4.4 l 4.1,9.1 c -3.7,1.7 -7.5,3.3 -11.4,4.7 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path713"
                    d="m 551.6,674.3 -4.7,-8.8 c 3.5,-1.8 6.9,-3.8 10.1,-5.8 l 5.3,8.5 c -3.4,2.1 -7,4.1 -10.7,6.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="gate_19_both_con"
                    d="m 557.3,659.4 c -27.9,17.6 -64.4,27.9 -101.4,27.9 v 10 c 39.5,0 77.5,-10.7 107,-29.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_54_connection"
                  v-if="chartBodygraphConnection.gatesExt[54]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[54])
                  "
                  @mouseover="searchAcPlanets(54)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 54) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path716"
                    d="M 455.2,687.4 C 416,687.3 378.6,676.2 349.7,656.2 l -5.5,8.3 c 30.5,21.1 69.9,32.7 111,32.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#4caf50"
                  />
                  <path
                    id="path718"
                    d="m 420.3,694.5 c -4,-0.7 -8.1,-1.5 -12.1,-2.4 l 2.3,-9.7 c 3.8,0.9 7.6,1.6 11.5,2.3 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path720"
                    d="m 396.3,688.9 c -3.9,-1.2 -7.9,-2.5 -11.7,-3.9 l 3.4,-9.4 c 3.6,1.3 7.4,2.6 11.1,3.7 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path722"
                    d="m 373.2,680.5 c -3.8,-1.7 -7.5,-3.5 -11.1,-5.3 l 4.6,-8.9 q 5.1,2.7 10.5,5.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path724"
                    d="m 351.2,669.1 c -3.5,-2.2 -3.7,-2.2 -7,-4.6 l 5.5,-8.3 c 3.1,2.2 3.4,2.3 6.7,4.4 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="gate_54_both_con"
                    d="M 455.2,687.4 C 416,687.3 378.6,676.2 349.7,656.2 l -5.5,8.3 c 30.5,21.1 69.9,32.7 111,32.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_49_connection"
                  v-if="chartBodygraphConnection.gatesExt[49]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[49])
                  "
                  @mouseover="searchAcPlanets(49)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 49) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path727"
                    d="m 615.4,587.7 -10,0.2 c 0.3,18 -8.1,36.2 -24.2,52.6 a 136.22,136.22 0 0 1 -23.4,18.7 l 5.4,8.6 a 147.83,147.83 0 0 0 25.2,-20.2 c 17.9,-18.5 27.3,-39.2 27,-59.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#4caf50"
                  />
                  <path
                    id="path729"
                    d="m 572.3,661.3 -6,-8 c 3.1,-2.3 6.2,-4.8 9,-7.3 l 6.6,7.5 c -3,2.6 -6.2,5.3 -9.6,7.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path731"
                    d="m 590.9,644.7 -7.3,-6.8 c 2.6,-2.9 5.1,-5.8 7.3,-8.8 l 8.1,5.9 a 122.42,122.42 0 0 1 -8.1,9.7 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path733"
                    d="m 606,624.5 -8.8,-4.8 a 77.23,77.23 0 0 0 4.7,-10.2 l 9.4,3.4 a 80.06,80.06 0 0 1 -5.3,11.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="gate_49_both_con"
                    d="m 615.4,587.7 -10,0.2 c 0.3,18 -8.1,36.2 -24.2,52.6 a 136.22,136.22 0 0 1 -23.4,18.7 l 5.4,8.6 a 147.83,147.83 0 0 0 25.2,-20.2 c 17.9,-18.5 27.3,-39.2 27,-59.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_32_connection"
                  v-if="chartBodygraphConnection.gatesExt[32]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[32])
                  "
                  @mouseover="searchAcPlanets(32)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 32) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path736"
                    d="m 306.3,588.5 -6.9,-0.7 h -3.1 c 0,27.6 17.3,55.4 47.4,76.4 a 1,1 0 0 1 0.5,0.4 l 5.5,-8.3 a 0.52,0.52 0 0 0 -0.4,-0.2 c -27,-19 -42.7,-43.6 -43,-67.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#4caf50"
                  />
                  <path
                    id="path738"
                    d="m 331.4,654.7 a 123.89,123.89 0 0 1 -9,-8.7 l 7.3,-6.8 c 2.6,2.7 5.4,5.4 8.3,8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path740"
                    d="m 314.1,636.6 a 94.67,94.67 0 0 1 -6.9,-10.5 l 8.7,-5 a 78.08,78.08 0 0 0 6.3,9.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path742"
                    d="m 301.9,614.9 a 82.16,82.16 0 0 1 -3.7,-12.3 l 9.8,-2 a 62.11,62.11 0 0 0 3.2,10.7 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="gate_32_both_con"
                    d="m 306.3,588.5 -6.9,-0.7 h -3.1 c 0,27.6 17.3,55.4 47.4,76.4 a 1,1 0 0 1 0.5,0.4 l 5.5,-8.3 a 0.52,0.52 0 0 0 -0.4,-0.2 c -27,-19 -42.7,-43.6 -43,-67.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_27_connection"
                  v-if="chartBodygraphConnection.gatesExt[27]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[27])
                  "
                  @mouseover="searchAcPlanets(27)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 27) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path745"
                    d="m 452.6,617.4 c -27.5,-0.4 -53.3,-4.1 -75.2,-10.3 l -5.3,9.1 c 23,6.7 50.3,10.8 80,11.2 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#4caf50"
                  />
                  <path
                    id="path747"
                    d="m 419.4,625.4 c -4.1,-0.5 -8.1,-1 -12.1,-1.6 l 1.5,-9.9 c 3.8,0.6 7.8,1.1 11.7,1.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path749"
                    d="m 395.2,621.6 c -4.1,-0.8 -8.1,-1.7 -11.9,-2.6 l 2.4,-9.7 c 3.7,0.9 7.6,1.8 11.5,2.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="gate_27_both_con"
                    d="m 452.6,617.4 c -27.5,-0.4 -53.3,-4.1 -75.2,-10.3 l -5.3,9.1 c 23,6.7 50.3,10.8 80,11.2 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_59_connection"
                  v-if="chartBodygraphConnection.gatesExt[59]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[59])
                  "
                  @mouseover="searchAcPlanets(59)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 59) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path752"
                    d="m 529.1,609.3 a 291.26,291.26 0 0 1 -43.2,6.9 c -9.2,0.8 -18.7,1.2 -28.3,1.2 h -4.8 l -0.5,10 h 5.4 c 11.2,0 22.1,-0.5 32.5,-1.5 a 287.12,287.12 0 0 0 44.4,-7.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#4caf50"
                  />
                  <path
                    id="path754"
                    d="m 504.3,624.3 -1.4,-9.9 c 3.9,-0.5 7.8,-1.2 11.7,-1.9 l 1.8,9.8 c -4,0.8 -8,1.4 -12.1,2 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path756"
                    d="m 523.5,621 -3.3,-9.8 c 3.9,-0.9 6.5,-1.4 7.4,-1.6 l 2.7,9.6 a 50.23,50.23 0 0 1 -6.8,1.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="gate_59_both_con"
                    d="m 529.1,609.3 a 291.26,291.26 0 0 1 -43.2,6.9 c -9.2,0.8 -18.7,1.2 -28.3,1.2 h -4.8 l -0.5,10 h 5.4 c 11.2,0 22.1,-0.5 32.5,-1.5 a 287.12,287.12 0 0 0 44.4,-7.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_50_connection"
                  v-if="chartBodygraphConnection.gatesExt[50]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[50])
                  "
                  @mouseover="searchAcPlanets(50)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 50) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path759"
                    d="m 314.1,585.1 c 10.3,10.8 26.1,20.1 45.9,27.2 q 5.85,2.1 12,3.9 l 5.3,-9.1 c -5,-1.4 -9.9,-3 -14.5,-4.6 -17,-6.1 -30.8,-13.8 -39.9,-22.5 a 42.73,42.73 0 0 1 -3.6,-3.8 l -8,6 a 37.64,37.64 0 0 1 2.8,2.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#4caf50"
                  />
                  <path
                    id="path761"
                    d="m 371.5,615.8 c -4,-1.2 -7.9,-2.5 -11.7,-3.8 l 3.4,-9.4 c 3.6,1.3 7.3,2.5 11.1,3.7 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path763"
                    d="m 348.3,607.4 c -3.9,-1.7 -7.7,-3.6 -11.1,-5.5 l 4.8,-8.8 a 111.9,111.9 0 0 0 10.4,5.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="gate_50_both_con"
                    d="m 314.1,585.1 c 10.3,10.8 26.1,20.1 45.9,27.2 q 5.85,2.1 12,3.9 l 5.3,-9.1 c -5,-1.4 -9.9,-3 -14.5,-4.6 -17,-6.1 -30.8,-13.8 -39.9,-22.5 a 42.73,42.73 0 0 1 -3.6,-3.8 l -8,6 a 37.64,37.64 0 0 1 2.8,2.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_06_connection"
                  v-if="chartBodygraphConnection.gatesExt[6]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[6])
                  "
                  @mouseover="searchAcPlanets(6)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 6) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path766"
                    d="m 582.1,587.7 a 129.57,129.57 0 0 1 -26.1,13.2 204.47,204.47 0 0 1 -27,8.3 l 5.4,9.2 a 228.06,228.06 0 0 0 25.3,-8 c 16,-6.2 29.1,-13.9 38.4,-22.7 0.9,-0.8 1.7,-1.7 2.6,-2.5 l -8,-6.1 a 72.06,72.06 0 0 1 -10.6,8.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#4caf50"
                  />
                  <path
                    id="path768"
                    d="m 534.6,618.5 -3.3,-9.8 c 3.9,-0.9 6.2,-1.6 7.1,-1.7 l 2.7,9.6 a 59.28,59.28 0 0 1 -6.5,1.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path770"
                    d="m 551.8,613.2 -3.2,-9.5 c 3.8,-1.3 7.5,-2.7 10.9,-4.1 l 3.8,9.2 c -3.6,1.6 -7.5,3 -11.5,4.4 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path772"
                    d="m 574.6,603.8 -4.5,-8.9 a 104.84,104.84 0 0 0 10,-5.7 l 5.4,8.4 c -3.4,2.1 -7,4.2 -10.9,6.2 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="gate_06_both_con"
                    d="m 582.1,587.7 a 129.57,129.57 0 0 1 -26.1,13.2 204.47,204.47 0 0 1 -27,8.3 l 5.4,9.2 a 228.06,228.06 0 0 0 25.3,-8 c 16,-6.2 29.1,-13.9 38.4,-22.7 0.9,-0.8 1.7,-1.7 2.6,-2.5 l -8,-6.1 a 72.06,72.06 0 0 1 -10.6,8.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_37_connection"
                  v-if="chartBodygraphConnection.gatesExt[37]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[37])
                  "
                  @mouseover="37"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 37) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path775"
                    d="m 609,537.7 -10.5,2 a 29.12,29.12 0 0 1 5.4,16.3 c 0,8.2 -4,16 -11.3,23.2 l 8,6.1 c 8.5,-8.9 13.3,-18.8 13.3,-29.3 A 35.42,35.42 0 0 0 609,537.7 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#4caf50"
                  />
                  <path
                    id="path777"
                    d="m 611.3,569.9 -9.3,-3.7 a 26,26 0 0 0 2,-9.8 h 10 a 36.11,36.11 0 0 1 -2.7,13.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path779"
                    d="m 603.8,553.2 a 28.9,28.9 0 0 0 -3,-9.6 l 9.2,-3.1 a 30.24,30.24 0 0 1 3.7,11.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="gate_37_both_con"
                    d="m 609,537.7 -10.5,2 a 29.12,29.12 0 0 1 5.4,16.3 c 0,8.2 -4,16 -11.3,23.2 l 8,6.1 c 8.5,-8.9 13.3,-18.8 13.3,-29.3 A 35.42,35.42 0 0 0 609,537.7 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_40_connection"
                  v-if="chartBodygraphConnection.gatesExt[40]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[40])
                  "
                  @mouseover="searchAcPlanets(40)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 40) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path782"
                    d="m 598.5,539.6 10.5,-2 c -9.5,-17 -31.9,-31.4 -62.5,-40.9 l -2.9,9.8 c 26.2,8.2 45.9,19.8 54.9,33.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#4caf50"
                  />
                  <path
                    id="path784"
                    d="m 595.2,534.8 a 60,60 0 0 0 -8.2,-7.5 l 6.1,-7.9 a 73.3,73.3 0 0 1 9.5,8.7 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="gate_40_both_con"
                    d="m 598.5,539.6 10.5,-2 c -9.5,-17 -31.9,-31.4 -62.5,-40.9 l -2.9,9.8 c 26.2,8.2 45.9,19.8 54.9,33.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_26_connection"
                  v-if="chartBodygraphConnection.gatesExt[26]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[26])
                  "
                  @mouseover="searchAcPlanets(26)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 26) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path787"
                    d="m 457.5,484.4 a 337.29,337.29 0 0 0 -51.6,3.8 l 3,9.8 a 327.49,327.49 0 0 1 48.6,-3.6 c 31.9,0 61.9,4.6 86.2,12.2 l 2.9,-9.8 c -25.1,-7.9 -55.8,-12.4 -89.1,-12.4 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#4caf50"
                  />
                  <path
                    id="path789"
                    d="m 511,498.7 c -3.8,-0.6 -7.7,-1.2 -11.7,-1.7 l 1.3,-9.9 c 4.1,0.5 8.1,1.1 12.1,1.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path791"
                    d="m 408.9,498 -3,-9.8 c 4,-0.7 6.9,-0.9 11,-1.4 l 1.2,9.9 c -3.9,0.5 -5.3,0.7 -9.2,1.3 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path793"
                    d="m 488.4,495.7 c -3.9,-0.3 -7.9,-0.6 -11.8,-0.8 l 0.5,-10 c 4.1,0.2 8.2,0.5 12.2,0.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path795"
                    d="m 428.8,495.6 -0.9,-10 c 4,-0.3 8.1,-0.6 12.2,-0.8 l 0.5,10 c -4,0.2 -8,0.5 -11.8,0.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path797"
                    d="m 464.3,494.6 c -3.9,-0.1 -7.9,-0.1 -11.8,0 l -0.2,-10 c 4,-0.1 8.1,-0.1 12.2,0 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="gate_26_both_con"
                    d="m 457.5,484.4 a 337.29,337.29 0 0 0 -51.6,3.8 l 3,9.8 a 327.49,327.49 0 0 1 48.6,-3.6 c 31.9,0 61.9,4.6 86.2,12.2 l 2.9,-9.8 c -25.1,-7.9 -55.8,-12.4 -89.1,-12.4 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_34_connection"
                  v-if="chartBodygraphConnection.gatesExt[34]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[34])
                  "
                  @mouseover="searchAcPlanets(34)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 34) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                  transform="translate(-1)"
                >
                  <path
                    id="path800"
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    style="fill:#4caf50"
                    transform="translate(-46.94,-28.51)"
                    sodipodi:nodetypes="cccccccsc"
                  />
                  <path
                    id="path802"
                    d="m 399.6,589.3 c -4.2,-0.6 -8.4,-1.4 -12.4,-2.2 l 2,-9.8 c 3.9,0.8 7.9,1.5 11.9,2.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path804"
                    d="m 374.8,584.2 c -4.1,-1.1 -8.2,-2.3 -12.2,-3.5 l 3,-9.5 c 3.8,1.2 7.7,2.3 11.6,3.4 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path806"
                    d="m 350.7,576.5 c -4,-1.6 -8,-3.3 -11.7,-5 l 4.3,-9 c 3.5,1.7 7.2,3.3 11,4.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path808"
                    d="m 327.7,565.5 c -3.71727,-2.14722 -7.322,-4.48362 -10.8,-7 l 6.11875,-8.69375 c 3.1,2.2 6.525,4.275 10.025,6.375 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                    sodipodi:nodetypes="ccccc"
                  />
                  <path
                    id="path810"
                    d="m 260.17875,521.49625 c -3.17562,-3.0407 -6.11731,-6.31667 -8.8,-9.8 l 8.09375,-6.1875 c 2.34365,3.05879 5.01157,5.87134 7.79375,8.5375 z"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                    sodipodi:nodetypes="ccccc"
                  />
                  <path
                    id="path812"
                    d="m 244.47875,500.0025 a 53,53 0 0 1 -3.9,-13.1 l 9.9,-1.5 a 40.64,40.64 0 0 0 3.1,10.5 z"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="gate_34_both_con"
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                    transform="translate(-46.94,-28.51)"
                    sodipodi:nodetypes="cccccccsc"
                  />
                </g>
                <g
                  id="gate_10_connection"
                  v-if="chartBodygraphConnection.gatesExt[10]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[10])
                  "
                  @mouseover="searchAcPlanets(10)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 10) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                  transform="translate(-0.75)"
                >
                  <path
                    id="path815"
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#4caf50"
                    sodipodi:nodetypes="cccsccc"
                  />
                  <path
                    id="path817"
                    d="m 297.67414,501.64104 h -10.32812 c 0.63581,-4.03751 1.90449,-8.79462 3.85398,-12.94104 l 9.1,4.1 c -1.50098,3.30975 -2.16352,6.15589 -2.62586,8.84104 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                    sodipodi:nodetypes="ccccc"
                  />
                  <path
                    id="path819"
                    d="m 306,483.1 -8,-6 a 80.13,80.13 0 0 1 8.8,-9.9 l 6.9,7.2 a 76.26,76.26 0 0 0 -7.7,8.7 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path821"
                    d="m 322.7,467 -5.9,-8.1 c 3.3,-2.4 7,-4.8 10.8,-7.1 l 5.1,8.6 a 108.22,108.22 0 0 0 -10,6.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path823"
                    d="m 343.2,454.9 -4.3,-9 c 3.7,-1.8 7.7,-3.5 11.7,-5.1 l 3.6,9.3 c -3.8,1.5 -7.5,3.1 -11,4.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path825"
                    d="m 365.6,446.1 -3.1,-9.5 c 3.9,-1.3 8,-2.4 12.2,-3.5 l 2.5,9.7 c -3.9,0.9 -7.8,2.1 -11.6,3.3 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path827"
                    d="m 389,440 -2,-9.8 c 4.1,-0.8 8.2,-1.6 12.4,-2.2 l 1.5,9.9 c -4,0.6 -8,1.3 -11.9,2.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="gate_10_both_con"
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                    sodipodi:nodetypes="cccsccc"
                  />
                </g>
                <g
                  id="gate_51_connection"
                  v-if="chartBodygraphConnection.gatesExt[51]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[51])
                  "
                  @mouseover="searchAcPlanets(51)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 51) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path830"
                    d="m 529.3,444.9 -6.9,7.3 c 16.3,13.5 26.6,32.8 26.6,54.2 h 10 c 0,-24.3 -11.5,-46.1 -29.7,-61.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#4caf50"
                  />
                  <path
                    id="path832"
                    d="m 535.3,465.7 a 73.85,73.85 0 0 0 -7.4,-8.5 l 7,-7.1 a 96.21,96.21 0 0 1 8.4,9.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="gate_51_both_con"
                    d="m 529.3,444.9 -6.9,7.3 c 16.3,13.5 26.6,32.8 26.6,54.2 h 10 c 0,-24.3 -11.5,-46.1 -29.7,-61.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_25_connection"
                  v-if="chartBodygraphConnection.gatesExt[25]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[25])
                  "
                  @mouseover="searchAcPlanets(25)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 25) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path835"
                    d="m 466,422.4 v 10 c 21.8,0 41.6,7.5 56.4,19.8 l 6.9,-7.3 A 98.63,98.63 0 0 0 466,422.4 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#4caf50"
                  />
                  <path
                    id="path837"
                    d="m 519.4,449.8 a 85.29,85.29 0 0 0 -9.5,-6.1 l 4.8,-8.8 a 92.83,92.83 0 0 1 10.7,6.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="gate_25_both_con"
                    d="m 466,422.4 v 10 c 21.8,0 41.6,7.5 56.4,19.8 l 6.9,-7.3 A 98.63,98.63 0 0 0 466,422.4 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_21_connection"
                  v-if="chartBodygraphConnection.gatesExt[21]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[21])
                  "
                  @mouseover="searchAcPlanets(21)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 21) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path840"
                    d="m 555,478.4 h 10 c 0,-33.3 -7,-65.1 -20.1,-91.7 l -9.1,4.2 c 12.1,24.5 19.2,54.8 19.2,87.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#4caf50"
                  />
                  <path
                    id="path842"
                    d="m 554,456 c -0.4,-3.9 -0.9,-7.8 -1.5,-11.6 l 9.9,-1.6 c 0.6,4 1.2,8.1 1.6,12.2 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path844"
                    d="m 550.3,432.7 c -0.8,-3.8 -1.8,-7.6 -2.8,-11.4 l 9.6,-2.7 c 1.1,3.9 2.1,7.9 3,11.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path846"
                    d="m 543.9,410 c -1.3,-3.7 -2.7,-7.4 -4.2,-10.9 l 9.2,-3.9 c 1.6,3.7 3.1,7.6 4.4,11.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="gate_21_both_con"
                    d="m 555,478.4 h 10 c 0,-33.3 -7,-65.1 -20.1,-91.7 l -9.1,4.2 c 12.1,24.5 19.2,54.8 19.2,87.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_45_connection"
                  v-if="chartBodygraphConnection.gatesExt[45]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[45])
                  "
                  @mouseover="searchAcPlanets(45)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 45) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <path
                    id="path849"
                    d="m 456.5,320.4 v 10 c 32.5,0 61.4,23.8 79.4,60.5 l 9.1,-4.2 a 158.69,158.69 0 0 0 -11.1,-19.3 c -20.6,-30.3 -48.1,-47 -77.4,-47 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#4caf50"
                  />
                  <path
                    id="path851"
                    d="m 534.8,388.6 c -1.8,-3.5 -3.7,-6.9 -5.7,-10.1 l 8.5,-5.2 c 2.1,3.5 4.2,7.1 6.1,10.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path853"
                    d="m 522.8,368.8 c -2.3,-3.1 -4.7,-6.2 -7.2,-9 l 7.5,-6.7 c 2.7,3 5.3,6.3 7.8,9.7 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="path855"
                    d="m 507.5,351.6 a 95.39,95.39 0 0 0 -8.9,-7.1 l 5.8,-8.2 a 116.91,116.91 0 0 1 9.8,7.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:#fb8c00"
                  />
                  <path
                    id="gate_45_both_con"
                    d="m 456.5,320.4 v 10 c 32.5,0 61.4,23.8 79.4,60.5 l 9.1,-4.2 a 158.69,158.69 0 0 0 -11.1,-19.3 c -20.6,-30.3 -48.1,-47 -77.4,-47 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
              </g>
              <g
                id="Слой_3"
                style="display:inline"
                data-name="Слой 3"
                transform="translate(31.240005,17.409983)"
              >
                <g
                  id="gate_42_connection"
                  v-if="chartBodygraphConnection.gatesExt[42]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[42])
                  "
                  @mouseover="searchAcPlanets(42)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 42) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect940"
                    x="383.35999"
                    y="570.89001"
                    width="10"
                    height="57"
                    style="fill:#4caf50"
                  />
                  <rect
                    id="rect942"
                    x="383.35999"
                    y="623.19"
                    width="10"
                    height="3.0999999"
                    style="fill:#fb8c00"
                  />
                  <rect
                    id="gate_42_both_con"
                    x="383.35999"
                    y="570.89001"
                    width="10"
                    height="57"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_05_connection"
                  v-if="chartBodygraphConnection.gatesExt[5]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[5])
                  "
                  @mouseover="searchAcPlanets(5)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 5) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect945"
                    x="383.35999"
                    y="483.89001"
                    width="10"
                    height="87"
                    style="fill:#4caf50"
                  />
                  <rect
                    id="rect947"
                    x="383.35999"
                    y="506.89001"
                    width="10"
                    height="12"
                    style="fill:#fb8c00"
                  />
                  <rect
                    id="rect949"
                    x="383.35999"
                    y="485.89001"
                    width="10"
                    height="12"
                    style="fill:#fb8c00"
                  />
                  <rect
                    id="gate_05_both_con"
                    x="383.35999"
                    y="483.89001"
                    width="10"
                    height="87"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_15_connection"
                  v-if="chartBodygraphConnection.gatesExt[15]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[15])
                  "
                  @mouseover="searchAcPlanets(15)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 15) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect952"
                    x="383.35999"
                    y="401.39001"
                    width="10"
                    height="82.5"
                    style="fill:#4caf50"
                  />
                  <rect
                    id="rect954"
                    x="383.35999"
                    y="461.89001"
                    width="10"
                    height="12"
                    style="fill:#fb8c00"
                  />
                  <rect
                    id="rect956"
                    x="383.35999"
                    y="437.89001"
                    width="10"
                    height="12"
                    style="fill:#fb8c00"
                  />
                  <rect
                    id="gate_15_both_con"
                    x="383.35999"
                    y="401.39001"
                    width="10"
                    height="82.5"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_07_connection"
                  v-if="chartBodygraphConnection.gatesExt[7]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[7])
                  "
                  @mouseover="searchAcPlanets(7)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 7) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect959"
                    x="383.35999"
                    y="339.89001"
                    width="10"
                    height="61.5"
                    style="fill:#4caf50"
                  />
                  <rect
                    id="rect961"
                    x="383.35999"
                    y="345.48999"
                    width="10"
                    height="6.4000001"
                    style="fill:#fb8c00"
                  />
                  <rect
                    id="gate_07_both_con"
                    x="383.35999"
                    y="339.89001"
                    width="10"
                    height="61.5"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_31_connection"
                  v-if="chartBodygraphConnection.gatesExt[31]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[31])
                  "
                  @mouseover="searchAcPlanets(31)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 31) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect964"
                    x="383.35999"
                    y="270.89001"
                    width="10"
                    height="69"
                    style="fill:#4caf50"
                  />
                  <rect
                    id="rect966"
                    x="383.35999"
                    y="328.59"
                    width="10"
                    height="6.4000001"
                    style="fill:#fb8c00"
                  />
                  <rect
                    id="gate_31_both_con"
                    x="383.35999"
                    y="270.89001"
                    width="10"
                    height="69"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_62_connection"
                  v-if="chartBodygraphConnection.gatesExt[62]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[62])
                  "
                  @mouseover="searchAcPlanets(62)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 62) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect969"
                    x="383.35999"
                    y="214.89"
                    width="10"
                    height="56"
                    style="fill:#4caf50"
                  />
                  <rect
                    id="rect971"
                    x="383.35999"
                    y="220.59"
                    width="10"
                    height="6"
                    style="fill:#fb8c00"
                  />
                  <rect
                    id="gate_62_both_con"
                    x="383.35999"
                    y="214.89"
                    width="10"
                    height="56"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_17_connection"
                  v-if="chartBodygraphConnection.gatesExt[17]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[17])
                  "
                  @mouseover="searchAcPlanets(17)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 17) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect974"
                    x="383.35999"
                    y="145.39"
                    width="10"
                    height="69.5"
                    style="fill:#4caf50"
                  />
                  <rect
                    id="rect976"
                    x="383.35999"
                    y="190.89"
                    width="10"
                    height="12"
                    style="fill:#fb8c00"
                  />
                  <rect
                    id="rect978"
                    x="383.35999"
                    y="166.89"
                    width="10"
                    height="12"
                    style="fill:#fb8c00"
                  />
                  <rect
                    id="gate_17_both_con"
                    x="383.35999"
                    y="145.39"
                    width="10"
                    height="69.5"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_64_connection"
                  v-if="chartBodygraphConnection.gatesExt[64]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[64])
                  "
                  @mouseover="searchAcPlanets(64)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 64) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect981"
                    x="383.35999"
                    y="73.889999"
                    width="10"
                    height="32"
                    style="fill:#4caf50"
                  />
                  <rect
                    id="rect983"
                    x="383.35999"
                    y="92.489998"
                    width="10"
                    height="8.8000002"
                    style="fill:#fb8c00"
                  />
                  <rect
                    id="rect985"
                    x="383.35999"
                    y="73.889999"
                    width="10"
                    height="8"
                    style="fill:#fb8c00"
                  />
                  <rect
                    id="gate_64_both_con"
                    x="383.35999"
                    y="73.889999"
                    width="10"
                    height="32"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_60_connection"
                  v-if="chartBodygraphConnection.gatesExt[60]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[60])
                  "
                  @mouseover="searchAcPlanets(60)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 60) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect988"
                    x="403.85999"
                    y="627.89001"
                    width="10"
                    height="79"
                    style="fill:#4caf50"
                  />
                  <rect
                    id="rect990"
                    x="403.85999"
                    y="630.09003"
                    width="10"
                    height="3.0999999"
                    style="fill:#fb8c00"
                  />
                  <rect
                    id="gate_60_both_con"
                    x="403.85999"
                    y="627.89001"
                    width="10"
                    height="79"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_03_connection"
                  v-if="chartBodygraphConnection.gatesExt[3]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[3])
                  "
                  @mouseover="searchAcPlanets(3)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 3) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect993"
                    x="403.85999"
                    y="570.89001"
                    width="10"
                    height="57"
                    style="fill:#4caf50"
                  />
                  <rect
                    id="rect995"
                    x="403.85999"
                    y="622.78998"
                    width="10"
                    height="3.0999999"
                    style="fill:#fb8c00"
                  />
                  <rect
                    id="gate_03_both_con"
                    x="403.85999"
                    y="570.89001"
                    width="10"
                    height="57"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_14_connection"
                  v-if="chartBodygraphConnection.gatesExt[14]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[14])
                  "
                  @mouseover="searchAcPlanets(14)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 14) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect998"
                    x="403.85999"
                    y="483.89001"
                    width="10"
                    height="87"
                    style="fill:#4caf50"
                  />
                  <rect
                    id="rect1000"
                    x="403.85999"
                    y="506.79001"
                    width="10"
                    height="12"
                    style="fill:#fb8c00"
                  />
                  <rect
                    id="rect1002"
                    x="403.85999"
                    y="485.48999"
                    width="10"
                    height="12"
                    style="fill:#fb8c00"
                  />
                  <rect
                    id="gate_14_both_con"
                    x="403.85999"
                    y="483.89001"
                    width="10"
                    height="87"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_02_connection"
                  v-if="chartBodygraphConnection.gatesExt[2]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[2])
                  "
                  @mouseover="searchAcPlanets(2)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 2) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect1005"
                    x="403.85999"
                    y="401.39001"
                    width="10"
                    height="82.5"
                    style="fill:#4caf50"
                  />
                  <rect
                    id="rect1007"
                    x="403.76001"
                    y="461.89001"
                    width="10"
                    height="12"
                    style="fill:#fb8c00"
                  />
                  <rect
                    id="gate_02_both_con"
                    x="403.85999"
                    y="401.39001"
                    width="10"
                    height="82.5"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_01_connection"
                  v-if="chartBodygraphConnection.gatesExt[1]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[1])
                  "
                  @mouseover="searchAcPlanets(1)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 1) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect1010"
                    x="403.85999"
                    y="339.89001"
                    width="10"
                    height="61.5"
                    style="fill:#4caf50"
                  />
                  <rect
                    id="rect1012"
                    x="403.76001"
                    y="342.29001"
                    width="10"
                    height="4.6999998"
                    style="fill:#fb8c00"
                  />
                  <rect
                    id="gate_01_both_con"
                    x="403.85999"
                    y="339.89001"
                    width="10"
                    height="61.5"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_08_connection"
                  v-if="chartBodygraphConnection.gatesExt[8]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[8])
                  "
                  @mouseover="searchAcPlanets(8)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 8) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect1015"
                    x="403.85999"
                    y="270.89001"
                    width="10"
                    height="69"
                    style="fill:#4caf50"
                  />
                  <rect
                    id="rect1017"
                    x="403.85999"
                    y="331.79001"
                    width="10"
                    height="4.3000002"
                    style="fill:#fb8c00"
                  />
                  <rect
                    id="gate_08_both_con"
                    x="403.85999"
                    y="270.89001"
                    width="10"
                    height="69"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_23_connection"
                  v-if="chartBodygraphConnection.gatesExt[23]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[23])
                  "
                  @mouseover="searchAcPlanets(23)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 23) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect1020"
                    x="403.85999"
                    y="214.89"
                    width="10"
                    height="56"
                    style="fill:#4caf50"
                  />
                  <rect
                    id="rect1022"
                    x="403.76001"
                    y="220.28999"
                    width="10"
                    height="5.5"
                    style="fill:#fb8c00"
                  />
                  <rect
                    id="gate_23_both_con"
                    x="403.85999"
                    y="214.89"
                    width="10"
                    height="56"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_43_connection"
                  v-if="chartBodygraphConnection.gatesExt[43]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[43])
                  "
                  @mouseover="searchAcPlanets(43)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 43) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect1025"
                    x="403.85999"
                    y="145.39"
                    width="10"
                    height="69.5"
                    style="fill:#4caf50"
                  />
                  <rect
                    id="rect1027"
                    x="403.76001"
                    y="197.89"
                    width="10"
                    height="12"
                    style="fill:#fb8c00"
                  />
                  <rect
                    id="gate_43_both_con"
                    x="403.85999"
                    y="145.39"
                    width="10"
                    height="69.5"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_61_connection"
                  v-if="chartBodygraphConnection.gatesExt[61]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[61])
                  "
                  @mouseover="searchAcPlanets(61)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 61) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect1030"
                    x="403.85999"
                    y="73.889999"
                    width="10"
                    height="32"
                    style="fill:#4caf50"
                  />
                  <rect
                    id="rect1032"
                    x="403.76001"
                    y="94.889999"
                    width="10"
                    height="6.5"
                    style="fill:#fb8c00"
                  />
                  <rect
                    id="gate_61_both_con"
                    x="403.85999"
                    y="73.889999"
                    width="10"
                    height="32"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_52_connection"
                  v-if="chartBodygraphConnection.gatesExt[52]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[52])
                  "
                  @mouseover="searchAcPlanets(52)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 52) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect1035"
                    x="424.35999"
                    y="627.89001"
                    width="10"
                    height="79"
                    style="fill:#4caf50"
                  />
                  <rect
                    id="rect1037"
                    x="424.35999"
                    y="630.39001"
                    width="10"
                    height="3"
                    style="fill:#fb8c00"
                  />
                  <rect
                    id="gate_52_both_con"
                    x="424.35999"
                    y="627.89001"
                    width="10"
                    height="79"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_09_connection"
                  v-if="chartBodygraphConnection.gatesExt[9]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[9])
                  "
                  @mouseover="searchAcPlanets(9)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 9) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect1040"
                    x="424.35999"
                    y="570.89001"
                    width="10"
                    height="57"
                    style="fill:#4caf50"
                  />
                  <rect
                    id="rect1042"
                    x="424.35999"
                    y="622.89001"
                    width="10"
                    height="3"
                    style="fill:#fb8c00"
                  />
                  <rect
                    id="gate_09_both_con"
                    x="424.35999"
                    y="570.89001"
                    width="10"
                    height="57"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_29_connection"
                  v-if="chartBodygraphConnection.gatesExt[29]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[29])
                  "
                  @mouseover="searchAcPlanets(29)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 29) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect1045"
                    x="424.35999"
                    y="483.89001"
                    width="10"
                    height="87"
                    style="fill:#4caf50"
                  />
                  <rect
                    id="rect1047"
                    x="424.35999"
                    y="506.89001"
                    width="10"
                    height="12"
                    style="fill:#fb8c00"
                  />
                  <rect
                    id="rect1049"
                    x="424.35999"
                    y="485.89001"
                    width="10"
                    height="12"
                    style="fill:#fb8c00"
                  />
                  <rect
                    id="gate_29_both_con"
                    x="424.35999"
                    y="483.89001"
                    width="10"
                    height="87"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_46_connection"
                  v-if="chartBodygraphConnection.gatesExt[46]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[46])
                  "
                  @mouseover="searchAcPlanets(46)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 46) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect1052"
                    x="424.35999"
                    y="401.39001"
                    width="10"
                    height="82.5"
                    style="fill:#4caf50"
                  />
                  <rect
                    id="rect1054"
                    x="424.35999"
                    y="461.89001"
                    width="10"
                    height="12"
                    style="fill:#fb8c00"
                  />
                  <rect
                    id="rect1056"
                    x="424.35999"
                    y="437.89001"
                    width="10"
                    height="12"
                    style="fill:#fb8c00"
                  />
                  <rect
                    id="gate_46_both_con"
                    x="424.35999"
                    y="401.39001"
                    width="10"
                    height="82.5"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_13_connection"
                  v-if="chartBodygraphConnection.gatesExt[13]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[13])
                  "
                  @mouseover="searchAcPlanets(13)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 13) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect1059"
                    x="424.35999"
                    y="339.89001"
                    width="10"
                    height="61.5"
                    style="fill:#4caf50"
                  />
                  <rect
                    id="rect1061"
                    x="424.35999"
                    y="344.59"
                    width="10"
                    height="7.5999999"
                    style="fill:#fb8c00"
                  />
                  <rect
                    id="gate_13_both_con"
                    x="424.35999"
                    y="339.89001"
                    width="10"
                    height="61.5"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_33_connection"
                  v-if="chartBodygraphConnection.gatesExt[33]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[33])
                  "
                  @mouseover="searchAcPlanets(33)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 33) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect1064"
                    x="424.35999"
                    y="270.89001"
                    width="10"
                    height="69"
                    style="fill:#4caf50"
                  />
                  <rect
                    id="rect1066"
                    x="424.35999"
                    y="328.79001"
                    width="10"
                    height="6.0999999"
                    style="fill:#fb8c00"
                  />
                  <rect
                    id="gate_33_both_con"
                    x="424.35999"
                    y="270.89001"
                    width="10"
                    height="69"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_56_connection"
                  v-if="chartBodygraphConnection.gatesExt[56]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[56])
                  "
                  @mouseover="searchAcPlanets(56)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 56) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect1069"
                    x="424.35999"
                    y="214.89"
                    width="10"
                    height="56"
                    style="fill:#4caf50"
                  />
                  <rect
                    id="rect1071"
                    x="424.35999"
                    y="220.28999"
                    width="10"
                    height="5.4000001"
                    style="fill:#fb8c00"
                  />
                  <rect
                    id="gate_56_both_con"
                    x="424.35999"
                    y="214.89"
                    width="10"
                    height="56"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_11_connection"
                  v-if="chartBodygraphConnection.gatesExt[11]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[11])
                  "
                  @mouseover="searchAcPlanets(11)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 11) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                  transform="translate(-0.75)"
                >
                  <rect
                    id="rect1074"
                    x="424.35999"
                    y="145.39"
                    width="10"
                    height="69.5"
                    style="fill:#4caf50"
                  />
                  <rect
                    id="rect1076"
                    x="424.35999"
                    y="193.89"
                    width="10"
                    height="12"
                    style="fill:#fb8c00"
                  />
                  <rect
                    id="rect1078"
                    x="424.35999"
                    y="169.89"
                    width="10"
                    height="12"
                    style="fill:#fb8c00"
                  />
                  <rect
                    id="gate_11_both_con"
                    x="424.35999"
                    y="145.39"
                    width="10"
                    height="69.5"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_04_connection"
                  v-if="chartBodygraphConnection.gatesExt[4]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[4])
                  "
                  @mouseover="searchAcPlanets(4)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 4) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect1081"
                    x="424.35999"
                    y="105.89"
                    width="10"
                    height="39.5"
                    style="fill:#4caf50"
                  />
                  <rect
                    id="rect1083"
                    x="424.35999"
                    y="107.89"
                    width="10"
                    height="3.3"
                    style="fill:#fb8c00"
                  />
                  <rect
                    id="gate_04_both_con"
                    x="424.35999"
                    y="105.89"
                    width="10"
                    height="39.5"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_63_connection"
                  v-if="chartBodygraphConnection.gatesExt[63]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[63])
                  "
                  @mouseover="searchAcPlanets(63)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 63) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect1086"
                    x="424.35999"
                    y="73.889999"
                    width="10"
                    height="32"
                    style="fill:#4caf50"
                  />
                  <rect
                    id="rect1088"
                    x="424.26001"
                    y="94.190002"
                    width="10"
                    height="6.4000001"
                    style="fill:#fb8c00"
                  />
                  <rect
                    id="gate_63_both_con"
                    x="424.35999"
                    y="73.889999"
                    width="10"
                    height="32"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_53_connection"
                  v-if="chartBodygraphConnection.gatesExt[53]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[53])
                  "
                  @mouseover="searchAcPlanets(53)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 53) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect1091"
                    x="383.35999"
                    y="627.89001"
                    width="10"
                    height="79"
                    style="fill:#4caf50"
                  />
                  <rect
                    id="rect1093"
                    x="383.45999"
                    y="630.59003"
                    width="10"
                    height="3.0999999"
                    style="fill:#fb8c00"
                  />
                  <rect
                    id="gate_53_both_con"
                    x="383.35999"
                    y="627.89001"
                    width="10"
                    height="79"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_47_connection"
                  v-if="chartBodygraphConnection.gatesExt[47]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[47])
                  "
                  @mouseover="searchAcPlanets(47)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 47) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect1096"
                    x="383.35999"
                    y="105.89"
                    width="10"
                    height="39.5"
                    style="fill:#4caf50"
                  />
                  <rect
                    id="rect1098"
                    x="383.16"
                    y="108.29"
                    width="10"
                    height="3.8"
                    style="fill:#fb8c00"
                  />
                  <rect
                    id="gate_47_both_con"
                    x="383.35999"
                    y="105.89"
                    width="10"
                    height="39.5"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_24_connection"
                  v-if="chartBodygraphConnection.gatesExt[24]"
                  @click="
                    openTooltipDialog(chartBodygraphConnection.gatesExt[24])
                  "
                  @mouseover="searchAcPlanets(24)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(connectionGatesDefined, 24) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  style="opacity:0;display:none"
                >
                  <rect
                    id="rect1101"
                    x="403.85999"
                    y="105.89"
                    width="10"
                    height="39.5"
                    style="fill:#4caf50"
                  />
                  <rect
                    id="rect1103"
                    x="403.95999"
                    y="108.09"
                    width="10"
                    height="3.8"
                    style="fill:#fb8c00"
                  />
                  <rect
                    id="gate_24_both_con"
                    x="403.85999"
                    y="105.89"
                    width="10"
                    height="39.5"
                    style="fill:transparent"
                  />
                </g>
              </g>
            </g>
            <g
              id="layer6.2"
              inkscape:groupmode="layer"
              inkscape:label="Channels Both"
              style="display:inline"
              sodipodi:insensitive="true"
            >
              <g
                id="Слой_14"
                style="display:inline"
                data-name="Слой 14"
                transform="translate(31.134997,17.409983)"
              >
                <g
                  id="gate_18_58_channel"
                  v-if="isChannel(chartBodygraphConnection.channels, '18-58')"
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.channels,
                        '18-58'
                      )
                    )
                  "
                  @mouseover="searchAcPlanets(18, 58)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_18_58_channel"
                >
                  <path
                    d="m 257.3,604.4 c 0,34.1 21.4,68.6 58.8,94.7 1.2,0.8 2.3,1.6 3.5,2.4 l 5.5,-8.3 c -1.1,-0.8 -2.2,-1.5 -3.4,-2.3 -34.7,-24.1 -54.5,-55.7 -54.5,-86.5 0,-6.6 0.2,-13.2 0.5,-19.9 l -10,-1 c -0.2,7 -0.4,13.9 -0.4,20.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 319.7,701.5 c 37.6,25.1 85.6,38.8 135.7,38.9 v -10 c -48.1,-0.1 -94.1,-13.3 -130.1,-37.3 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
              </g>
              <g
                id="Слой_16"
                style="display:inline"
                data-name="Слой 16"
                transform="translate(31.134997,17.409983)"
              >
                <g
                  id="gate_28_38_channel"
                  v-if="isChannel(chartBodygraphConnection.channels, '28-38')"
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.channels,
                        '28-38'
                      )
                    )
                  "
                  @mouseover="searchAcPlanets(28, 38)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_28_38_channel"
                >
                  <path
                    d="m 275.7,595.9 c 0,31 19.5,62.4 53.4,86 0.8,0.6 1.6,1.1 2.4,1.6 l 5.5,-8.3 c -0.8,-0.5 -1.5,-1 -2.2,-1.5 -31.2,-21.8 -49.1,-50.1 -49.1,-77.8 0,-3.2 0.1,-6.3 0.1,-9.5 l -10,-1 c 0,3.5 -0.1,7 -0.1,10.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 455.3,709.4 c -43.7,-0.1 -85.6,-12.2 -118.2,-34.2 l -5.5,8.3 c 34.2,23 78,35.7 123.7,35.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_32_54_channel"
                  v-if="isChannel(chartBodygraphConnection.channels, '32-54')"
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.channels,
                        '32-54'
                      )
                    )
                  "
                  @mouseover="searchAcPlanets(32, 54)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_32_54_channel"
                >
                  <path
                    d="m 306.3,588.5 -6.9,-0.7 h -3.1 c 0,27.6 17.3,55.4 47.4,76.4 a 1,1 0 0 1 0.5,0.4 l 5.5,-8.3 a 0.52,0.52 0 0 0 -0.4,-0.2 c -27,-19 -42.7,-43.6 -43,-67.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="M 455.2,687.4 C 416,687.3 378.6,676.2 349.7,656.2 l -5.5,8.3 c 30.5,21.1 69.9,32.7 111,32.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_24_61_channel"
                  v-if="isChannel(chartBodygraphConnection.channels, '24-61')"
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.channels,
                        '24-61'
                      )
                    )
                  "
                  @mouseover="searchAcPlanets(24, 61)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_24_61_channel"
                >
                  <rect
                    x="403.85999"
                    y="105.89"
                    width="10"
                    height="39.5"
                    style="fill:transparent"
                  />
                  <rect
                    x="403.85999"
                    y="73.889999"
                    width="10"
                    height="32"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_16_48_channel"
                  v-if="isChannel(chartBodygraphConnection.channels, '16-48')"
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.channels,
                        '16-48'
                      )
                    )
                  "
                  @mouseover="searchAcPlanets(16, 48)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_16_48_channel"
                >
                  <path
                    d="m 324.8,365.4 9.2,4.3 c 34.8,-52.2 77.2,-80.8 120.9,-81.2 v -10 c -26.6,0.2 -52.7,9.9 -77.4,28.8 -19.2,14.5 -36.8,34 -52.7,58.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 314.7,381.8 c -33.2,57.3 -53.5,129.8 -56.9,201.8 l 10,1 c 3.2,-70.6 23,-141.7 55.5,-197.8 3.5,-6 7,-11.7 10.6,-17.1 l -9.2,-4.3 c -3.4,5.2 -6.7,10.7 -10,16.4 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_20_57_channel"
                  v-if="isChannel(chartBodygraphConnection.channels, '20-57')"
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.channels,
                        '20-57'
                      )
                    )
                  "
                  @mouseover="searchAcPlanets(20, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_20_57_channel"
                >
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_20_34_channel"
                  v-if="isChannel(chartBodygraphConnection.channels, '20-34')"
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.channels,
                        '20-34'
                      )
                    )
                  "
                  @mouseover="searchAcPlanets(20, 34)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_20_34_channel"
                >
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                    transform="translate(-46.94,-28.51)"
                  />
                </g>
                <g
                  id="gate_35_36_channel"
                  v-if="isChannel(chartBodygraphConnection.channels, '35-36')"
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.channels,
                        '35-36'
                      )
                    )
                  "
                  @mouseover="searchAcPlanets(35, 36)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_35_36_channel"
                >
                  <path
                    d="m 455.8,288.4 c 47.3,0 91.7,28.7 125.9,81.2 l 9.2,-4.3 c -0.1,-0.2 -0.3,-0.4 -0.4,-0.6 C 554.3,309 506.8,278.4 455.8,278.4 h -0.9 v 10 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 582,369.9 c 1.8,2.7 3.5,5.5 5.2,8.3 32.6,54.1 52.3,126.5 56.4,205.7 l 10,-1.1 c -4.2,-80.6 -24.4,-154.4 -57.8,-209.7 -1.6,-2.7 -3.2,-5.2 -4.9,-7.8 l -9.2,4.3 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_12_22_channel"
                  v-if="isChannel(chartBodygraphConnection.channels, '12-22')"
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.channels,
                        '12-22'
                      )
                    )
                  "
                  @mouseover="searchAcPlanets(12, 22)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_12_22_channel"
                >
                  <path
                    d="m 455.8,310.4 c 39.9,0 77.6,22.7 107.6,64.5 0.6,0.8 1.1,1.6 1.7,2.4 l 9.2,-4.3 c -0.5,-0.7 -0.9,-1.4 -1.4,-2.1 -32.1,-45.6 -73.2,-70.6 -117.1,-70.6 h -0.9 v 10 a 2.77,2.77 0 0 0 0.9,0.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 574.3,391.4 c 31.1,51.6 49.3,121.8 51.4,198.1 l 9.9,-3 c -2.6,-77 -21.2,-147.8 -52.8,-200.2 -2.8,-4.6 -5.6,-9 -8.6,-13.3 l -9.2,4.3 c 3.3,4.5 6.3,9.2 9.3,14.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_21_45_channel"
                  v-if="isChannel(chartBodygraphConnection.channels, '21-45')"
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.channels,
                        '21-45'
                      )
                    )
                  "
                  @mouseover="searchAcPlanets(21, 45)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_21_45_channel"
                >
                  <path
                    id="gate_21_channel"
                    d="m 555,478.4 h 10 c 0,-33.3 -7,-65.1 -20.1,-91.7 l -9.1,4.2 c 12.1,24.5 19.2,54.8 19.2,87.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    id="gate_45_channel"
                    d="m 456.5,320.4 v 10 c 32.5,0 61.4,23.8 79.4,60.5 l 9.1,-4.2 a 158.69,158.69 0 0 0 -11.1,-19.3 c -20.6,-30.3 -48.1,-47 -77.4,-47 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_26_44_channel"
                  v-if="isChannel(chartBodygraphConnection.channels, '26-44')"
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.channels,
                        '26-44'
                      )
                    )
                  "
                  @mouseover="searchAcPlanets(26, 44)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_26_44_channel"
                >
                  <path
                    d="m 457.5,484.4 a 337.29,337.29 0 0 0 -51.6,3.8 l 3,9.8 a 327.49,327.49 0 0 1 48.6,-3.6 c 31.9,0 61.9,4.6 86.2,12.2 l 2.9,-9.8 c -25.1,-7.9 -55.8,-12.4 -89.1,-12.4 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 311,555.9 c 0,-26.3 41.4,-49.3 97.9,-57.9 l -3,-9.8 c -61.5,9.6 -104.9,36 -104.9,67.7 0,7.9 2.7,15.5 7.7,22.6 l 10,-3.3 c -5,-6.1 -7.7,-12.6 -7.7,-19.3 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_37_40_channel"
                  v-if="isChannel(chartBodygraphConnection.channels, '37-40')"
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.channels,
                        '37-40'
                      )
                    )
                  "
                  @mouseover="searchAcPlanets(37, 40)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_37_40_channel"
                >
                  <path
                    d="m 598.5,539.6 10.5,-2 c -9.5,-17 -31.9,-31.4 -62.5,-40.9 l -2.9,9.8 c 26.2,8.2 45.9,19.8 54.9,33.1 z"
                    style="fill:transparent"
                    transform="translate(-46.94,-28.51)"
                  />
                  <path
                    d="m 609,537.7 -10.5,2 a 29.12,29.12 0 0 1 5.4,16.3 c 0,8.2 -4,16 -11.3,23.2 l 8,6.1 c 8.5,-8.9 13.3,-18.8 13.3,-29.3 A 35.42,35.42 0 0 0 609,537.7 Z"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                    transform="translate(-46.94,-28.51)"
                  />
                </g>
              </g>
              <g
                id="Слой_3"
                style="display:inline"
                data-name="Слой 3"
                transform="translate(31.240005,17.409983)"
              >
                <g
                  id="gate_23_43_channel"
                  v-if="isChannel(chartBodygraphConnection.channels, '23-43')"
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.channels,
                        '23-43'
                      )
                    )
                  "
                  @mouseover="searchAcPlanets(23, 43)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_23_43_channel"
                >
                  <rect
                    x="403.85999"
                    y="214.89"
                    width="10"
                    height="56"
                    style="fill:transparent"
                  />
                  <rect
                    x="403.85999"
                    y="145.39"
                    width="10"
                    height="69.5"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_11_56_channel"
                  v-if="isChannel(chartBodygraphConnection.channels, '11-56')"
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.channels,
                        '11-56'
                      )
                    )
                  "
                  @mouseover="searchAcPlanets(11, 56)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_11_56_channel"
                >
                  <rect
                    x="424.35999"
                    y="145.39"
                    width="10"
                    height="69.5"
                    style="fill:transparent"
                  />
                  <rect
                    x="424.35999"
                    y="214.89"
                    width="10"
                    height="56"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_17_62_channel"
                  v-if="isChannel(chartBodygraphConnection.channels, '17-62')"
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.channels,
                        '17-62'
                      )
                    )
                  "
                  @mouseover="searchAcPlanets(17, 62)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_17_62_channel"
                >
                  <rect
                    x="383.35999"
                    y="145.39"
                    width="10"
                    height="69.5"
                    style="fill:transparent"
                  />
                  <rect
                    x="383.35999"
                    y="214.89"
                    width="10"
                    height="56"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_04_63_channel"
                  v-if="isChannel(chartBodygraphConnection.channels, '4-63')"
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.channels,
                        '4-63'
                      )
                    )
                  "
                  @mouseover="searchAcPlanets(4, 63)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_4_63_channel"
                >
                  <rect
                    id="gate_63_04_channel"
                    x="424.35999"
                    y="73.889999"
                    width="10"
                    height="32"
                    style="fill:transparent"
                  />
                  <rect
                    id="gate_04_64_channel"
                    x="424.35999"
                    y="105.89"
                    width="10"
                    height="39.5"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_47_64_channel"
                  v-if="isChannel(chartBodygraphConnection.channels, '47-64')"
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.channels,
                        '47-64'
                      )
                    )
                  "
                  @mouseover="searchAcPlanets(47, 64)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_47_64_channel"
                >
                  <rect
                    id="gate_64_47_rect"
                    x="383.35999"
                    y="73.889999"
                    width="10"
                    height="32"
                    style="fill:transparent"
                  />
                  <rect
                    id="gate_47_64_rect"
                    x="383.35999"
                    y="105.89"
                    width="10"
                    height="39.5"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_07_31_channel"
                  v-if="isChannel(chartBodygraphConnection.channels, '7-31')"
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.channels,
                        '7-31'
                      )
                    )
                  "
                  @mouseover="searchAcPlanets(7, 31)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_07_31_channel"
                >
                  <rect
                    x="383.35999"
                    y="339.89001"
                    width="10"
                    height="61.5"
                    style="fill:transparent"
                  />
                  <rect
                    x="383.35999"
                    y="270.89001"
                    width="10"
                    height="69"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10_20_channel"
                  v-if="isChannel(chartBodygraphConnection.channels, '10-20')"
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.channels,
                        '10-20'
                      )
                    )
                  "
                  @mouseover="searchAcPlanets(10, 20)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10_20_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10_34_channel"
                  v-if="isChannel(chartBodygraphConnection.channels, '10-34')"
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.channels,
                        '10-34'
                      )
                    )
                  "
                  @mouseover="searchAcPlanets(10, 34)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10_34_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10_57_channel"
                  v-if="isChannel(chartBodygraphConnection.channels, '10-57')"
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.channels,
                        '10-57'
                      )
                    )
                  "
                  @mouseover="searchAcPlanets(10, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10_57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-57_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-20') &&
                      isChannel(chartBodygraphConnection.channels, '10-57') &&
                      !isChannel(chartBodygraphConnection.channels, '10-34') &&
                      !isChannel(chartBodygraphConnection.channels, '20-57') &&
                      !isChannel(chartBodygraphConnection.channels, '20-34') &&
                      !isChannel(chartBodygraphConnection.channels, '34-57')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-20'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-57'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_20-57_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-20') &&
                      isChannel(chartBodygraphConnection.channels, '20-57') &&
                      !isChannel(chartBodygraphConnection.channels, '10-34') &&
                      !isChannel(chartBodygraphConnection.channels, '10-57') &&
                      !isChannel(chartBodygraphConnection.channels, '20-34') &&
                      !isChannel(chartBodygraphConnection.channels, '34-57')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-20'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-57'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_20-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-57_20-57_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-20') &&
                      isChannel(chartBodygraphConnection.channels, '10-57') &&
                      isChannel(chartBodygraphConnection.channels, '20-57') &&
                      !isChannel(chartBodygraphConnection.channels, '10-34') &&
                      !isChannel(chartBodygraphConnection.channels, '20-34') &&
                      !isChannel(chartBodygraphConnection.channels, '34-57')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-20'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-57'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-57'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-57_20-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-20') &&
                      isChannel(chartBodygraphConnection.channels, '10-34') &&
                      !isChannel(chartBodygraphConnection.channels, '10-57') &&
                      !isChannel(chartBodygraphConnection.channels, '20-57') &&
                      !isChannel(chartBodygraphConnection.channels, '20-34') &&
                      !isChannel(chartBodygraphConnection.channels, '34-57')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-20'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-34'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_20-34_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-34') &&
                      isChannel(chartBodygraphConnection.channels, '20-34') &&
                      !isChannel(chartBodygraphConnection.channels, '10-20') &&
                      !isChannel(chartBodygraphConnection.channels, '10-57') &&
                      !isChannel(chartBodygraphConnection.channels, '20-57') &&
                      !isChannel(chartBodygraphConnection.channels, '34-57')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-34'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-34_20-34_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_20-34_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-20') &&
                      isChannel(chartBodygraphConnection.channels, '20-34') &&
                      !isChannel(chartBodygraphConnection.channels, '10-34') &&
                      !isChannel(chartBodygraphConnection.channels, '10-57') &&
                      !isChannel(chartBodygraphConnection.channels, '20-57') &&
                      !isChannel(chartBodygraphConnection.channels, '34-57')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-20'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-34'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_20-34_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_20-34_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-20') &&
                      isChannel(chartBodygraphConnection.channels, '10-34') &&
                      isChannel(chartBodygraphConnection.channels, '20-34') &&
                      !isChannel(chartBodygraphConnection.channels, '10-57') &&
                      !isChannel(chartBodygraphConnection.channels, '20-57') &&
                      !isChannel(chartBodygraphConnection.channels, '34-57')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-20'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-34'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_20-34_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_20-34_20-57_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '20-34') &&
                      isChannel(chartBodygraphConnection.channels, '20-57') &&
                      !isChannel(chartBodygraphConnection.channels, '10-20') &&
                      !isChannel(chartBodygraphConnection.channels, '10-34') &&
                      !isChannel(chartBodygraphConnection.channels, '10-57') &&
                      !isChannel(chartBodygraphConnection.channels, '34-57')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-57'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_20-34_20-57_channel"
                >
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                    transform="translate(-46.94,-28.51)"
                  />
                </g>
                <g
                  id="gate_20-34_34-57_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '20-34') &&
                      isChannel(chartBodygraphConnection.channels, '34-57') &&
                      !isChannel(chartBodygraphConnection.channels, '10-20') &&
                      !isChannel(chartBodygraphConnection.channels, '10-34') &&
                      !isChannel(chartBodygraphConnection.channels, '10-57') &&
                      !isChannel(chartBodygraphConnection.channels, '20-57')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '34-57'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_20-34_34-57_channel"
                >
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                    transform="translate(-46.94,-28.51)"
                  />
                </g>
                <g
                  id="gate_20-57_34-57_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '20-57') &&
                      isChannel(chartBodygraphConnection.channels, '34-57') &&
                      !isChannel(chartBodygraphConnection.channels, '10-20') &&
                      !isChannel(chartBodygraphConnection.channels, '10-34') &&
                      !isChannel(chartBodygraphConnection.channels, '10-57') &&
                      !isChannel(chartBodygraphConnection.channels, '20-34')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-57'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '34-57'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_20-57_34-57_channel"
                >
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                    transform="translate(-46.94,-28.51)"
                  />
                </g>
                <g
                  id="gate_20-34_20-57_34-57_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '20-34') &&
                      isChannel(chartBodygraphConnection.channels, '20-57') &&
                      isChannel(chartBodygraphConnection.channels, '34-57') &&
                      !isChannel(chartBodygraphConnection.channels, '10-20') &&
                      !isChannel(chartBodygraphConnection.channels, '10-34') &&
                      !isChannel(chartBodygraphConnection.channels, '10-57')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-57'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '34-57'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_20-34_20-57_34-57_channel"
                >
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                    transform="translate(-46.94,-28.51)"
                  />
                </g>
                <g
                  id="gate_10-34_10-57_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-34') &&
                      isChannel(chartBodygraphConnection.channels, '10-57') &&
                      !isChannel(chartBodygraphConnection.channels, '10-20') &&
                      !isChannel(chartBodygraphConnection.channels, '20-34') &&
                      !isChannel(chartBodygraphConnection.channels, '20-57') &&
                      !isChannel(chartBodygraphConnection.channels, '34-57')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-57'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-34_10-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_34-57_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-34') &&
                      isChannel(chartBodygraphConnection.channels, '34-57') &&
                      !isChannel(chartBodygraphConnection.channels, '10-20') &&
                      !isChannel(chartBodygraphConnection.channels, '10-57') &&
                      !isChannel(chartBodygraphConnection.channels, '20-34') &&
                      !isChannel(chartBodygraphConnection.channels, '20-57')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '34-57'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-34_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-57_34-57_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-57') &&
                      isChannel(chartBodygraphConnection.channels, '34-57') &&
                      !isChannel(chartBodygraphConnection.channels, '10-20') &&
                      !isChannel(chartBodygraphConnection.channels, '10-34') &&
                      !isChannel(chartBodygraphConnection.channels, '20-34') &&
                      !isChannel(chartBodygraphConnection.channels, '20-57')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-57'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '34-57'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-57_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_10-57_34-57_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-34') &&
                      isChannel(chartBodygraphConnection.channels, '10-57') &&
                      isChannel(chartBodygraphConnection.channels, '34-57') &&
                      !isChannel(chartBodygraphConnection.channels, '10-20') &&
                      !isChannel(chartBodygraphConnection.channels, '20-34') &&
                      !isChannel(chartBodygraphConnection.channels, '20-57')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-57'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '34-57'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-34_10-57_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_34-57_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-20') &&
                      isChannel(chartBodygraphConnection.channels, '34-57') &&
                      !isChannel(chartBodygraphConnection.channels, '10-34') &&
                      !isChannel(chartBodygraphConnection.channels, '10-57') &&
                      !isChannel(chartBodygraphConnection.channels, '20-34') &&
                      !isChannel(chartBodygraphConnection.channels, '20-57')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-20'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '34-57'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_20-57_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-34') &&
                      isChannel(chartBodygraphConnection.channels, '20-57') &&
                      !isChannel(chartBodygraphConnection.channels, '10-20') &&
                      !isChannel(chartBodygraphConnection.channels, '10-57') &&
                      !isChannel(chartBodygraphConnection.channels, '20-34') &&
                      !isChannel(chartBodygraphConnection.channels, '34-57')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-57'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-34_20-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-57_20-34_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-57') &&
                      isChannel(chartBodygraphConnection.channels, '20-34') &&
                      !isChannel(chartBodygraphConnection.channels, '10-20') &&
                      !isChannel(chartBodygraphConnection.channels, '10-34') &&
                      !isChannel(chartBodygraphConnection.channels, '20-57') &&
                      !isChannel(chartBodygraphConnection.channels, '34-57')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-57'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-34'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-57_20-34_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_10-57_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-20') &&
                      isChannel(chartBodygraphConnection.channels, '10-34') &&
                      isChannel(chartBodygraphConnection.channels, '10-57') &&
                      !isChannel(chartBodygraphConnection.channels, '20-34') &&
                      !isChannel(chartBodygraphConnection.channels, '20-57') &&
                      !isChannel(chartBodygraphConnection.channels, '34-57')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-20'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-57'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_10-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_20-57_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-20') &&
                      isChannel(chartBodygraphConnection.channels, '10-34') &&
                      isChannel(chartBodygraphConnection.channels, '20-57') &&
                      !isChannel(chartBodygraphConnection.channels, '10-57') &&
                      !isChannel(chartBodygraphConnection.channels, '20-34') &&
                      !isChannel(chartBodygraphConnection.channels, '34-57')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-20'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-57'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_20-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_34-57_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-20') &&
                      isChannel(chartBodygraphConnection.channels, '10-34') &&
                      isChannel(chartBodygraphConnection.channels, '34-57') &&
                      !isChannel(chartBodygraphConnection.channels, '10-57') &&
                      !isChannel(chartBodygraphConnection.channels, '20-34') &&
                      !isChannel(chartBodygraphConnection.channels, '20-57')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-20'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '34-57'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_20-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-57_20-34_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-20') &&
                      isChannel(chartBodygraphConnection.channels, '10-57') &&
                      isChannel(chartBodygraphConnection.channels, '20-34') &&
                      !isChannel(chartBodygraphConnection.channels, '10-34') &&
                      !isChannel(chartBodygraphConnection.channels, '20-57') &&
                      !isChannel(chartBodygraphConnection.channels, '34-57')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-20'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-57'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-34'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-57_20-34_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-57_34-57_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-20') &&
                      isChannel(chartBodygraphConnection.channels, '10-57') &&
                      isChannel(chartBodygraphConnection.channels, '34-57') &&
                      !isChannel(chartBodygraphConnection.channels, '10-34') &&
                      !isChannel(chartBodygraphConnection.channels, '20-34') &&
                      !isChannel(chartBodygraphConnection.channels, '20-57')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-20'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-57'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '34-57'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-57_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_20-34_20-57_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-20') &&
                      isChannel(chartBodygraphConnection.channels, '20-34') &&
                      isChannel(chartBodygraphConnection.channels, '20-57') &&
                      !isChannel(chartBodygraphConnection.channels, '10-34') &&
                      !isChannel(chartBodygraphConnection.channels, '10-57') &&
                      !isChannel(chartBodygraphConnection.channels, '34-57')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-20'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-57'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_20-34_20-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_20-34_34-57_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-20') &&
                      isChannel(chartBodygraphConnection.channels, '20-34') &&
                      isChannel(chartBodygraphConnection.channels, '34-57') &&
                      !isChannel(chartBodygraphConnection.channels, '10-34') &&
                      !isChannel(chartBodygraphConnection.channels, '10-57') &&
                      !isChannel(chartBodygraphConnection.channels, '20-57')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-20'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '34-57'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_20-34_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_20-57_34-57_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-20') &&
                      isChannel(chartBodygraphConnection.channels, '20-57') &&
                      isChannel(chartBodygraphConnection.channels, '34-57') &&
                      !isChannel(chartBodygraphConnection.channels, '10-34') &&
                      !isChannel(chartBodygraphConnection.channels, '10-57') &&
                      !isChannel(chartBodygraphConnection.channels, '20-34')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-20'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-57'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '34-57'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_20-34_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_10-57_20-34_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-34') &&
                      isChannel(chartBodygraphConnection.channels, '10-57') &&
                      isChannel(chartBodygraphConnection.channels, '20-34') &&
                      !isChannel(chartBodygraphConnection.channels, '10-20') &&
                      !isChannel(chartBodygraphConnection.channels, '20-57') &&
                      !isChannel(chartBodygraphConnection.channels, '34-57')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-57'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-34'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-34_10-57_20-34_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_10-57_20-57_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-34') &&
                      isChannel(chartBodygraphConnection.channels, '10-57') &&
                      isChannel(chartBodygraphConnection.channels, '20-57') &&
                      !isChannel(chartBodygraphConnection.channels, '10-20') &&
                      !isChannel(chartBodygraphConnection.channels, '20-34') &&
                      !isChannel(chartBodygraphConnection.channels, '34-57')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-57'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-57'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-34_10-57_20-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_20-34_20-57_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-34') &&
                      isChannel(chartBodygraphConnection.channels, '20-34') &&
                      isChannel(chartBodygraphConnection.channels, '20-57') &&
                      !isChannel(chartBodygraphConnection.channels, '10-20') &&
                      !isChannel(chartBodygraphConnection.channels, '10-57') &&
                      !isChannel(chartBodygraphConnection.channels, '34-57')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-57'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-34_20-34_20-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_20-34_34-57_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-34') &&
                      isChannel(chartBodygraphConnection.channels, '20-34') &&
                      isChannel(chartBodygraphConnection.channels, '34-57') &&
                      !isChannel(chartBodygraphConnection.channels, '10-20') &&
                      !isChannel(chartBodygraphConnection.channels, '10-57') &&
                      !isChannel(chartBodygraphConnection.channels, '20-57')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '34-57'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-34_20-34_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_20-57_34-57_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-34') &&
                      isChannel(chartBodygraphConnection.channels, '20-57') &&
                      isChannel(chartBodygraphConnection.channels, '34-57') &&
                      !isChannel(chartBodygraphConnection.channels, '10-20') &&
                      !isChannel(chartBodygraphConnection.channels, '10-57') &&
                      !isChannel(chartBodygraphConnection.channels, '20-34')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-57'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '34-57'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-34_20-57_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-57_20-34_20-57_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-57') &&
                      isChannel(chartBodygraphConnection.channels, '20-34') &&
                      isChannel(chartBodygraphConnection.channels, '20-57') &&
                      !isChannel(chartBodygraphConnection.channels, '10-20') &&
                      !isChannel(chartBodygraphConnection.channels, '10-34') &&
                      !isChannel(chartBodygraphConnection.channels, '34-57')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-57'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-57'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-57_20-34_20-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-57_20-34_34-57_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-57') &&
                      isChannel(chartBodygraphConnection.channels, '20-34') &&
                      isChannel(chartBodygraphConnection.channels, '34-57') &&
                      !isChannel(chartBodygraphConnection.channels, '10-20') &&
                      !isChannel(chartBodygraphConnection.channels, '10-34') &&
                      !isChannel(chartBodygraphConnection.channels, '20-57')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-57'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '34-57'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-57_20-34_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-57_20-57_34-57_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-57') &&
                      isChannel(chartBodygraphConnection.channels, '20-57') &&
                      isChannel(chartBodygraphConnection.channels, '34-57') &&
                      !isChannel(chartBodygraphConnection.channels, '10-20') &&
                      !isChannel(chartBodygraphConnection.channels, '10-34') &&
                      !isChannel(chartBodygraphConnection.channels, '20-34')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-57'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-57'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '34-57'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-57_20-57_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_10-57_20-34_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-20') &&
                      isChannel(chartBodygraphConnection.channels, '10-34') &&
                      isChannel(chartBodygraphConnection.channels, '10-57') &&
                      isChannel(chartBodygraphConnection.channels, '20-34') &&
                      !isChannel(chartBodygraphConnection.channels, '20-57') &&
                      !isChannel(chartBodygraphConnection.channels, '34-57')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-20'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-57'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-34'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_10-57_20-34_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_10-57_20-57_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-20') &&
                      isChannel(chartBodygraphConnection.channels, '10-34') &&
                      isChannel(chartBodygraphConnection.channels, '10-57') &&
                      isChannel(chartBodygraphConnection.channels, '20-57') &&
                      !isChannel(chartBodygraphConnection.channels, '20-34') &&
                      !isChannel(chartBodygraphConnection.channels, '34-57')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-20'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-57'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-57'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_10-57_20-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_10-57_34-57_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-20') &&
                      isChannel(chartBodygraphConnection.channels, '10-34') &&
                      isChannel(chartBodygraphConnection.channels, '10-57') &&
                      isChannel(chartBodygraphConnection.channels, '34-57') &&
                      !isChannel(chartBodygraphConnection.channels, '20-34') &&
                      !isChannel(chartBodygraphConnection.channels, '20-57')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-20'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-57'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '34-57'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_10-57_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_20-34_20-57_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-20') &&
                      isChannel(chartBodygraphConnection.channels, '10-34') &&
                      isChannel(chartBodygraphConnection.channels, '20-34') &&
                      isChannel(chartBodygraphConnection.channels, '20-57') &&
                      !isChannel(chartBodygraphConnection.channels, '10-57') &&
                      !isChannel(chartBodygraphConnection.channels, '34-57')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-20'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-57'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_20-34_20-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_20-34_34-57_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-20') &&
                      isChannel(chartBodygraphConnection.channels, '10-34') &&
                      isChannel(chartBodygraphConnection.channels, '20-34') &&
                      isChannel(chartBodygraphConnection.channels, '34-57') &&
                      !isChannel(chartBodygraphConnection.channels, '10-57') &&
                      !isChannel(chartBodygraphConnection.channels, '20-57')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-20'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '34-57'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_20-34_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_20-57_34-57_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-20') &&
                      isChannel(chartBodygraphConnection.channels, '10-34') &&
                      isChannel(chartBodygraphConnection.channels, '20-57') &&
                      isChannel(chartBodygraphConnection.channels, '34-57') &&
                      !isChannel(chartBodygraphConnection.channels, '10-57') &&
                      !isChannel(chartBodygraphConnection.channels, '20-34')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-20'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-57'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '34-57'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_20-57_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-57_20-34_20-57_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-20') &&
                      isChannel(chartBodygraphConnection.channels, '10-57') &&
                      isChannel(chartBodygraphConnection.channels, '20-34') &&
                      isChannel(chartBodygraphConnection.channels, '20-57') &&
                      !isChannel(chartBodygraphConnection.channels, '10-34') &&
                      !isChannel(chartBodygraphConnection.channels, '34-57')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-20'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-57'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-57'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-57_20-34_20-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-57_20-34_34-57_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-20') &&
                      isChannel(chartBodygraphConnection.channels, '10-57') &&
                      isChannel(chartBodygraphConnection.channels, '20-34') &&
                      isChannel(chartBodygraphConnection.channels, '34-57') &&
                      !isChannel(chartBodygraphConnection.channels, '10-34') &&
                      !isChannel(chartBodygraphConnection.channels, '20-57')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-20'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-57'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '34-57'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-57_20-34_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-57_20-57_34-57_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-20') &&
                      isChannel(chartBodygraphConnection.channels, '10-57') &&
                      isChannel(chartBodygraphConnection.channels, '20-57') &&
                      isChannel(chartBodygraphConnection.channels, '34-57') &&
                      !isChannel(chartBodygraphConnection.channels, '10-34') &&
                      !isChannel(chartBodygraphConnection.channels, '20-34')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-20'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-57'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-57'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '34-57'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-57_20-34_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_10-57_20-34_20-57_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-34') &&
                      isChannel(chartBodygraphConnection.channels, '10-57') &&
                      isChannel(chartBodygraphConnection.channels, '20-34') &&
                      isChannel(chartBodygraphConnection.channels, '20-57') &&
                      !isChannel(chartBodygraphConnection.channels, '10-20') &&
                      !isChannel(chartBodygraphConnection.channels, '34-57')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-57'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-57'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-34_10-57_20-34_20-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_10-57_20-34_34-57_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-34') &&
                      isChannel(chartBodygraphConnection.channels, '10-57') &&
                      isChannel(chartBodygraphConnection.channels, '20-34') &&
                      isChannel(chartBodygraphConnection.channels, '34-57') &&
                      !isChannel(chartBodygraphConnection.channels, '10-20') &&
                      !isChannel(chartBodygraphConnection.channels, '20-57')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-57'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '34-57'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-34_10-57_20-34_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_10-57_20-57_34-57_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-34') &&
                      isChannel(chartBodygraphConnection.channels, '10-57') &&
                      isChannel(chartBodygraphConnection.channels, '20-57') &&
                      isChannel(chartBodygraphConnection.channels, '34-57') &&
                      !isChannel(chartBodygraphConnection.channels, '10-20') &&
                      !isChannel(chartBodygraphConnection.channels, '20-34')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-57'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-57'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '34-57'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-34_10-57_20-57_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_20-34_20-57_34-57_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-20') &&
                      isChannel(chartBodygraphConnection.channels, '20-34') &&
                      isChannel(chartBodygraphConnection.channels, '20-57') &&
                      isChannel(chartBodygraphConnection.channels, '34-57') &&
                      !isChannel(chartBodygraphConnection.channels, '10-34') &&
                      !isChannel(chartBodygraphConnection.channels, '10-57')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-20'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-57'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '34-57'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_20-34_20-57_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_20-34_20-57_34-57_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-34') &&
                      isChannel(chartBodygraphConnection.channels, '20-34') &&
                      isChannel(chartBodygraphConnection.channels, '20-57') &&
                      isChannel(chartBodygraphConnection.channels, '34-57') &&
                      !isChannel(chartBodygraphConnection.channels, '10-20') &&
                      !isChannel(chartBodygraphConnection.channels, '10-57')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-57'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '34-57'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-34_20-34_20-57_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-57_20-34_20-57_34-57_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-57') &&
                      isChannel(chartBodygraphConnection.channels, '20-34') &&
                      isChannel(chartBodygraphConnection.channels, '20-57') &&
                      isChannel(chartBodygraphConnection.channels, '34-57') &&
                      !isChannel(chartBodygraphConnection.channels, '10-20') &&
                      !isChannel(chartBodygraphConnection.channels, '10-34')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-57'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-57'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '34-57'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-57_20-34_20-57_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_10-57_20-34_20-57_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-20') &&
                      isChannel(chartBodygraphConnection.channels, '10-34') &&
                      isChannel(chartBodygraphConnection.channels, '10-57') &&
                      isChannel(chartBodygraphConnection.channels, '20-34') &&
                      isChannel(chartBodygraphConnection.channels, '20-57') &&
                      !isChannel(chartBodygraphConnection.channels, '34-57')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-20'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-57'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-57'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_10-57_20-34_20-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_10-57_20-34_34-57_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-20') &&
                      isChannel(chartBodygraphConnection.channels, '10-34') &&
                      isChannel(chartBodygraphConnection.channels, '10-57') &&
                      isChannel(chartBodygraphConnection.channels, '20-34') &&
                      isChannel(chartBodygraphConnection.channels, '34-57') &&
                      !isChannel(chartBodygraphConnection.channels, '20-57')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-20'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-57'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '34-57'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_10-57_20-34_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_10-57_20-57_34-57_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-20') &&
                      isChannel(chartBodygraphConnection.channels, '10-34') &&
                      isChannel(chartBodygraphConnection.channels, '10-57') &&
                      isChannel(chartBodygraphConnection.channels, '20-57') &&
                      isChannel(chartBodygraphConnection.channels, '34-57') &&
                      !isChannel(chartBodygraphConnection.channels, '20-34')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-20'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-57'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-57'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '34-57'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_10-57_20-57_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_20-34_20-57_34-57_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-20') &&
                      isChannel(chartBodygraphConnection.channels, '10-34') &&
                      isChannel(chartBodygraphConnection.channels, '20-34') &&
                      isChannel(chartBodygraphConnection.channels, '20-57') &&
                      isChannel(chartBodygraphConnection.channels, '34-57') &&
                      !isChannel(chartBodygraphConnection.channels, '10-57')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-20'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-57'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '34-57'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_20-34_20-57_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-57_20-34_20-57_34-57_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-20') &&
                      isChannel(chartBodygraphConnection.channels, '10-57') &&
                      isChannel(chartBodygraphConnection.channels, '20-34') &&
                      isChannel(chartBodygraphConnection.channels, '20-57') &&
                      isChannel(chartBodygraphConnection.channels, '34-57') &&
                      !isChannel(chartBodygraphConnection.channels, '10-34')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-20'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-57'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-57'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '34-57'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-57_20-34_20-57_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_10-57_20-34_20-57_34-57_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-34') &&
                      isChannel(chartBodygraphConnection.channels, '10-57') &&
                      isChannel(chartBodygraphConnection.channels, '20-34') &&
                      isChannel(chartBodygraphConnection.channels, '20-57') &&
                      isChannel(chartBodygraphConnection.channels, '34-57') &&
                      !isChannel(chartBodygraphConnection.channels, '10-20')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-57'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-57'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '34-57'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-34_10-57_20-34_20-57_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_10-57_20-34_20-57_34-57_channel"
                  v-if="
                    isChannel(chartBodygraphConnection.channels, '10-20') &&
                      isChannel(chartBodygraphConnection.channels, '10-34') &&
                      isChannel(chartBodygraphConnection.channels, '10-57') &&
                      isChannel(chartBodygraphConnection.channels, '20-34') &&
                      isChannel(chartBodygraphConnection.channels, '20-57') &&
                      isChannel(chartBodygraphConnection.channels, '34-57')
                  "
                  @click="
                    openMultiTooltipDialog([
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-20'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '10-57'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-34'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '20-57'
                      ),
                      toSearchItemAtArrInObj2(
                        chartBodygraphConnection.channels,
                        '34-57'
                      )
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_10-57_20-34_20-57_34-57_channel"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_01_08_channel"
                  v-if="isChannel(chartBodygraphConnection.channels, '1-8')"
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.channels,
                        '1-8'
                      )
                    )
                  "
                  @mouseover="searchAcPlanets(1, 8)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_01_08_channel"
                >
                  <rect
                    id="rect1015"
                    x="403.85999"
                    y="270.89001"
                    width="10"
                    height="69"
                    style="fill:transparent"
                  />
                  <rect
                    id="rect1010"
                    x="403.85999"
                    y="339.89001"
                    width="10"
                    height="61.5"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_13_33_channel"
                  v-if="isChannel(chartBodygraphConnection.channels, '13-33')"
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.channels,
                        '13-33'
                      )
                    )
                  "
                  @mouseover="searchAcPlanets(13, 33)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_13_33_channel"
                >
                  <rect
                    id="gate_13_33"
                    x="424.35999"
                    y="339.89001"
                    width="10"
                    height="61.5"
                    style="fill:transparent"
                  />
                  <rect
                    id="gate_33_13"
                    x="424.35999"
                    y="270.89001"
                    width="10"
                    height="69"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_25_51_channel"
                  v-if="isChannel(chartBodygraphConnection.channels, '25-51')"
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.channels,
                        '25-51'
                      )
                    )
                  "
                  @mouseover="searchAcPlanets(25, 51)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_25_51_channel"
                >
                  <path
                    d="m 466,422.4 v 10 c 21.8,0 41.6,7.5 56.4,19.8 l 6.9,-7.3 A 98.63,98.63 0 0 0 466,422.4 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 529.3,444.9 -6.9,7.3 c 16.3,13.5 26.6,32.8 26.6,54.2 h 10 c 0,-24.3 -11.5,-46.1 -29.7,-61.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_29_46_channel"
                  v-if="isChannel(chartBodygraphConnection.channels, '29-46')"
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.channels,
                        '29-46'
                      )
                    )
                  "
                  @mouseover="searchAcPlanets(29, 46)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_29_46_channel"
                >
                  <rect
                    id="gate_46_29"
                    x="424.35999"
                    y="401.39001"
                    width="10"
                    height="82.5"
                    style="fill:transparent"
                  />
                  <rect
                    id="gate_29_46"
                    x="424.35999"
                    y="483.89001"
                    width="10"
                    height="87"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_02_14_channel"
                  v-if="isChannel(chartBodygraphConnection.channels, '2-14')"
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.channels,
                        '2-14'
                      )
                    )
                  "
                  @mouseover="searchAcPlanets(2, 14)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_02_14_channel"
                >
                  <rect
                    id="gate_02_14"
                    x="403.85999"
                    y="401.39001"
                    width="10"
                    height="82.5"
                    style="fill:transparent"
                  />
                  <rect
                    id="gate_14_02"
                    x="403.85999"
                    y="483.89001"
                    width="10"
                    height="87"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_05_15_channel"
                  v-if="isChannel(chartBodygraphConnection.channels, '5-15')"
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.channels,
                        '5-15'
                      )
                    )
                  "
                  @mouseover="searchAcPlanets(5, 15)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_05_15_channel"
                >
                  <rect
                    x="383.35999"
                    y="483.89001"
                    width="10"
                    height="87"
                    style="fill:transparent"
                  />
                  <rect
                    x="383.35999"
                    y="401.39001"
                    width="10"
                    height="82.5"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_06_59_channel"
                  v-if="isChannel(chartBodygraphConnection.channels, '6-59')"
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.channels,
                        '6-59'
                      )
                    )
                  "
                  @mouseover="searchAcPlanets(6, 59)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_06_59_channel"
                >
                  <path
                    d="m 582.1,587.7 a 129.57,129.57 0 0 1 -26.1,13.2 204.47,204.47 0 0 1 -27,8.3 l 5.4,9.2 a 228.06,228.06 0 0 0 25.3,-8 c 16,-6.2 29.1,-13.9 38.4,-22.7 0.9,-0.8 1.7,-1.7 2.6,-2.5 l -8,-6.1 a 72.06,72.06 0 0 1 -10.6,8.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 529.1,609.3 a 291.26,291.26 0 0 1 -43.2,6.9 c -9.2,0.8 -18.7,1.2 -28.3,1.2 h -4.8 l -0.5,10 h 5.4 c 11.2,0 22.1,-0.5 32.5,-1.5 a 287.12,287.12 0 0 0 44.4,-7.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_19_49_channel"
                  v-if="isChannel(chartBodygraphConnection.channels, '19-49')"
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.channels,
                        '19-49'
                      )
                    )
                  "
                  @mouseover="searchAcPlanets(19, 49)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_19_49_channel"
                >
                  <path
                    d="m 557.3,659.4 c -27.9,17.6 -64.4,27.9 -101.4,27.9 v 10 c 39.5,0 77.5,-10.7 107,-29.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 615.4,587.7 -10,0.2 c 0.3,18 -8.1,36.2 -24.2,52.6 a 136.22,136.22 0 0 1 -23.4,18.7 l 5.4,8.6 a 147.83,147.83 0 0 0 25.2,-20.2 c 17.9,-18.5 27.3,-39.2 27,-59.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_39_55_channel"
                  v-if="isChannel(chartBodygraphConnection.channels, '39-55')"
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.channels,
                        '39-55'
                      )
                    )
                  "
                  @mouseover="searchAcPlanets(39, 55)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_39_55_channel"
                >
                  <path
                    d="m 575.1,686.9 -5.3,-8.5 c -31.6,19.5 -72.5,30.9 -114,30.9 v 10 a 239.69,239.69 0 0 0 85.8,-15.8 190,190 0 0 0 33.5,-16.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 635.9,595.8 c -0.1,-3.1 -0.1,-6.2 -0.2,-9.3 l -9.9,3 c 0.1,2.1 0.1,4.3 0.1,6.4 0.3,20.6 -9.2,41.4 -27.6,60.1 a 152.5,152.5 0 0 1 -28.5,22.4 l 5.3,8.5 A 165.59,165.59 0 0 0 605.4,663 c 20.3,-20.6 30.9,-43.9 30.5,-67.2 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_30_41_channel"
                  v-if="isChannel(chartBodygraphConnection.channels, '30-41')"
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.channels,
                        '30-41'
                      )
                    )
                  "
                  @mouseover="searchAcPlanets(30, 41)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_30_41_channel"
                >
                  <path
                    d="m 653.6,582.8 -10,1.1 c 0.3,6.8 0.6,13.7 0.7,20.6 0.4,22.9 -10.2,46 -30.7,66.8 a 172.56,172.56 0 0 1 -32.4,25.4 l 5.7,8.3 a 178.16,178.16 0 0 0 33.8,-26.6 c 22.4,-22.8 34,-48.3 33.6,-74 -0.1,-7.3 -0.4,-14.5 -0.7,-21.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 586.9,704.9 -5.7,-8.3 c -33.9,20.7 -77.5,33.1 -121.8,33.7 l 0.5,10 a 266.48,266.48 0 0 0 90.5,-17.3 225.62,225.62 0 0 0 36.5,-18.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_09_52_channel"
                  v-if="isChannel(chartBodygraphConnection.channels, '9-52')"
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.channels,
                        '9-52'
                      )
                    )
                  "
                  @mouseover="searchAcPlanets(9, 52)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_09_52_channel"
                >
                  <rect
                    id="gate_09_52"
                    x="424.35999"
                    y="570.89001"
                    width="10"
                    height="57"
                    style="fill:transparent"
                  />
                  <rect
                    id="gate_52_09"
                    x="424.35999"
                    y="627.89001"
                    width="10"
                    height="79"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_03_60_channel"
                  v-if="isChannel(chartBodygraphConnection.channels, '3-60')"
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.channels,
                        '3-60'
                      )
                    )
                  "
                  @mouseover="searchAcPlanets(3, 60)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_3_60_channel"
                >
                  <rect
                    x="403.85999"
                    y="570.89001"
                    width="10"
                    height="57"
                    style="fill:transparent"
                  />
                  <rect
                    x="403.85999"
                    y="627.89001"
                    width="10"
                    height="79"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_42_53_channel"
                  v-if="isChannel(chartBodygraphConnection.channels, '42-53')"
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.channels,
                        '42-53'
                      )
                    )
                  "
                  @mouseover="searchAcPlanets(42, 53)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_42_53_channel"
                >
                  <rect
                    x="383.35999"
                    y="570.89001"
                    width="10"
                    height="57"
                    style="fill:transparent"
                  />
                  <rect
                    x="383.35999"
                    y="627.89001"
                    width="10"
                    height="79"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_27_50_channel"
                  v-if="isChannel(chartBodygraphConnection.channels, '27-50')"
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.channels,
                        '27-50'
                      )
                    )
                  "
                  @mouseover="searchAcPlanets(27, 50)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_27_50_channel"
                >
                  <path
                    d="m 452.6,617.4 c -27.5,-0.4 -53.3,-4.1 -75.2,-10.3 l -5.3,9.1 c 23,6.7 50.3,10.8 80,11.2 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 314.1,585.1 c 10.3,10.8 26.1,20.1 45.9,27.2 q 5.85,2.1 12,3.9 l 5.3,-9.1 c -5,-1.4 -9.9,-3 -14.5,-4.6 -17,-6.1 -30.8,-13.8 -39.9,-22.5 a 42.73,42.73 0 0 1 -3.6,-3.8 l -8,6 a 37.64,37.64 0 0 1 2.8,2.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
              </g>
            </g>
            <g
              id="layer6.3"
              inkscape:groupmode="layer"
              inkscape:label="Channels Both for Chart1 & Chart2"
              style="display:inline"
              sodipodi:insensitive="true"
            >
              <g
                id="Слой_14"
                style="display:inline"
                data-name="Слой 14"
                transform="translate(31.134997,17.409983)"
              >
                <g
                  id="gate_18_58_channel_chart1"
                  v-if="chartBodygraphChart.channels2['18-58']"
                  @click="
                    openTooltipDialog(chartBodygraphChart.channels2['18-58'])
                  "
                  @mouseover="searchAcPlanets(18, 58)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_18_58_channel_chart1"
                >
                  <path
                    d="m 257.3,604.4 c 0,34.1 21.4,68.6 58.8,94.7 1.2,0.8 2.3,1.6 3.5,2.4 l 5.5,-8.3 c -1.1,-0.8 -2.2,-1.5 -3.4,-2.3 -34.7,-24.1 -54.5,-55.7 -54.5,-86.5 0,-6.6 0.2,-13.2 0.5,-19.9 l -10,-1 c -0.2,7 -0.4,13.9 -0.4,20.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 319.7,701.5 c 37.6,25.1 85.6,38.8 135.7,38.9 v -10 c -48.1,-0.1 -94.1,-13.3 -130.1,-37.3 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_18_58_channel_chart2"
                  v-if="chartBodygraphChart2.channels2['18-58']"
                  @click="
                    openTooltipDialog(chartBodygraphChart2.channels2['18-58'])
                  "
                  @mouseover="searchAcPlanets(18, 58)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_18_58_channel_chart2"
                >
                  <path
                    d="m 257.3,604.4 c 0,34.1 21.4,68.6 58.8,94.7 1.2,0.8 2.3,1.6 3.5,2.4 l 5.5,-8.3 c -1.1,-0.8 -2.2,-1.5 -3.4,-2.3 -34.7,-24.1 -54.5,-55.7 -54.5,-86.5 0,-6.6 0.2,-13.2 0.5,-19.9 l -10,-1 c -0.2,7 -0.4,13.9 -0.4,20.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 319.7,701.5 c 37.6,25.1 85.6,38.8 135.7,38.9 v -10 c -48.1,-0.1 -94.1,-13.3 -130.1,-37.3 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
              </g>
              <g
                id="Слой_16"
                style="display:inline"
                data-name="Слой 16"
                transform="translate(31.134997,17.409983)"
              >
                <g
                  id="gate_28_38_channel_chart1"
                  v-if="chartBodygraphChart.channels2['28-38']"
                  @click="
                    openTooltipDialog(chartBodygraphChart.channels2['28-38'])
                  "
                  @mouseover="searchAcPlanets(28, 38)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_28_38_channel_chart1"
                >
                  <path
                    d="m 275.7,595.9 c 0,31 19.5,62.4 53.4,86 0.8,0.6 1.6,1.1 2.4,1.6 l 5.5,-8.3 c -0.8,-0.5 -1.5,-1 -2.2,-1.5 -31.2,-21.8 -49.1,-50.1 -49.1,-77.8 0,-3.2 0.1,-6.3 0.1,-9.5 l -10,-1 c 0,3.5 -0.1,7 -0.1,10.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 455.3,709.4 c -43.7,-0.1 -85.6,-12.2 -118.2,-34.2 l -5.5,8.3 c 34.2,23 78,35.7 123.7,35.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_28_38_channel_chart2"
                  v-if="chartBodygraphChart2.channels2['28-38']"
                  @click="
                    openTooltipDialog(chartBodygraphChart2.channels2['28-38'])
                  "
                  @mouseover="searchAcPlanets(28, 38)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_28_38_channel_chart2"
                >
                  <path
                    d="m 275.7,595.9 c 0,31 19.5,62.4 53.4,86 0.8,0.6 1.6,1.1 2.4,1.6 l 5.5,-8.3 c -0.8,-0.5 -1.5,-1 -2.2,-1.5 -31.2,-21.8 -49.1,-50.1 -49.1,-77.8 0,-3.2 0.1,-6.3 0.1,-9.5 l -10,-1 c 0,3.5 -0.1,7 -0.1,10.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 455.3,709.4 c -43.7,-0.1 -85.6,-12.2 -118.2,-34.2 l -5.5,8.3 c 34.2,23 78,35.7 123.7,35.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_32_54_channel_chart1"
                  v-if="chartBodygraphChart.channels2['32-54']"
                  @click="
                    openTooltipDialog(chartBodygraphChart.channels2['32-54'])
                  "
                  @mouseover="searchAcPlanets(32, 54)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_32_54_channel_chart1"
                >
                  <path
                    d="m 306.3,588.5 -6.9,-0.7 h -3.1 c 0,27.6 17.3,55.4 47.4,76.4 a 1,1 0 0 1 0.5,0.4 l 5.5,-8.3 a 0.52,0.52 0 0 0 -0.4,-0.2 c -27,-19 -42.7,-43.6 -43,-67.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="M 455.2,687.4 C 416,687.3 378.6,676.2 349.7,656.2 l -5.5,8.3 c 30.5,21.1 69.9,32.7 111,32.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_32_54_channel_chart2"
                  v-if="chartBodygraphChart2.channels2['32-54']"
                  @click="
                    openTooltipDialog(chartBodygraphChart2.channels2['32-54'])
                  "
                  @mouseover="searchAcPlanets(32, 54)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_32_54_channel_chart2"
                >
                  <path
                    d="m 306.3,588.5 -6.9,-0.7 h -3.1 c 0,27.6 17.3,55.4 47.4,76.4 a 1,1 0 0 1 0.5,0.4 l 5.5,-8.3 a 0.52,0.52 0 0 0 -0.4,-0.2 c -27,-19 -42.7,-43.6 -43,-67.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="M 455.2,687.4 C 416,687.3 378.6,676.2 349.7,656.2 l -5.5,8.3 c 30.5,21.1 69.9,32.7 111,32.8 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_24_61_channel_chart1"
                  v-if="chartBodygraphChart.channels2['24-61']"
                  @click="
                    openTooltipDialog(chartBodygraphChart.channels2['24-61'])
                  "
                  @mouseover="searchAcPlanets(24, 61)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_24_61_channel_chart1"
                >
                  <rect
                    x="403.85999"
                    y="105.89"
                    width="10"
                    height="39.5"
                    style="fill:transparent"
                  />
                  <rect
                    x="403.85999"
                    y="73.889999"
                    width="10"
                    height="32"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_24_61_channel_chart2"
                  v-if="chartBodygraphChart2.channels2['24-61']"
                  @click="
                    openTooltipDialog(chartBodygraphChart2.channels2['24-61'])
                  "
                  @mouseover="searchAcPlanets(24, 61)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_24_61_channel_chart2"
                >
                  <rect
                    x="403.85999"
                    y="105.89"
                    width="10"
                    height="39.5"
                    style="fill:transparent"
                  />
                  <rect
                    x="403.85999"
                    y="73.889999"
                    width="10"
                    height="32"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_16_48_channel_chart1"
                  v-if="chartBodygraphChart.channels2['16-48']"
                  @click="
                    openTooltipDialog(chartBodygraphChart.channels2['16-48'])
                  "
                  @mouseover="searchAcPlanets(16, 48)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_16_48_channel_chart1"
                >
                  <path
                    d="m 324.8,365.4 9.2,4.3 c 34.8,-52.2 77.2,-80.8 120.9,-81.2 v -10 c -26.6,0.2 -52.7,9.9 -77.4,28.8 -19.2,14.5 -36.8,34 -52.7,58.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 314.7,381.8 c -33.2,57.3 -53.5,129.8 -56.9,201.8 l 10,1 c 3.2,-70.6 23,-141.7 55.5,-197.8 3.5,-6 7,-11.7 10.6,-17.1 l -9.2,-4.3 c -3.4,5.2 -6.7,10.7 -10,16.4 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_16_48_channel_chart2"
                  v-if="chartBodygraphChart2.channels2['16-48']"
                  @click="
                    openTooltipDialog(chartBodygraphChart2.channels2['16-48'])
                  "
                  @mouseover="searchAcPlanets(16, 48)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_16_48_channel_chart2"
                >
                  <path
                    d="m 324.8,365.4 9.2,4.3 c 34.8,-52.2 77.2,-80.8 120.9,-81.2 v -10 c -26.6,0.2 -52.7,9.9 -77.4,28.8 -19.2,14.5 -36.8,34 -52.7,58.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 314.7,381.8 c -33.2,57.3 -53.5,129.8 -56.9,201.8 l 10,1 c 3.2,-70.6 23,-141.7 55.5,-197.8 3.5,-6 7,-11.7 10.6,-17.1 l -9.2,-4.3 c -3.4,5.2 -6.7,10.7 -10,16.4 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_20_57_channel_chart1"
                  v-if="chartBodygraphChart.channels2['20-57']"
                  @click="
                    openTooltipDialog(chartBodygraphChart.channels2['20-57'])
                  "
                  @mouseover="searchAcPlanets(20, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_20_57_channel_chart1"
                >
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_20_57_channel_chart2"
                  v-if="chartBodygraphChart2.channels2['20-57']"
                  @click="
                    openTooltipDialog(chartBodygraphChart2.channels2['20-57'])
                  "
                  @mouseover="searchAcPlanets(20, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_20_57_channel_chart2"
                >
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_20_34_channel_chart1"
                  v-if="chartBodygraphChart.channels2['20-34']"
                  @click="
                    openTooltipDialog(chartBodygraphChart.channels2['20-34'])
                  "
                  @mouseover="searchAcPlanets(20, 34)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_20_34_channel_chart1"
                >
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                    transform="translate(-46.94,-28.51)"
                  />
                </g>
                <g
                  id="gate_20_34_channel_chart2"
                  v-if="chartBodygraphChart2.channels2['20-34']"
                  @click="
                    openTooltipDialog(chartBodygraphChart2.channels2['20-34'])
                  "
                  @mouseover="searchAcPlanets(20, 34)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_20_34_channel_chart2"
                >
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                    transform="translate(-46.94,-28.51)"
                  />
                </g>
                <g
                  id="gate_35_36_channel_chart1"
                  v-if="chartBodygraphChart.channels2['35-36']"
                  @click="
                    openTooltipDialog(chartBodygraphChart.channels2['35-36'])
                  "
                  @mouseover="searchAcPlanets(35, 36)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_35_36_channel_chart1"
                >
                  <path
                    d="m 455.8,288.4 c 47.3,0 91.7,28.7 125.9,81.2 l 9.2,-4.3 c -0.1,-0.2 -0.3,-0.4 -0.4,-0.6 C 554.3,309 506.8,278.4 455.8,278.4 h -0.9 v 10 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 582,369.9 c 1.8,2.7 3.5,5.5 5.2,8.3 32.6,54.1 52.3,126.5 56.4,205.7 l 10,-1.1 c -4.2,-80.6 -24.4,-154.4 -57.8,-209.7 -1.6,-2.7 -3.2,-5.2 -4.9,-7.8 l -9.2,4.3 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_35_36_channel_chart2"
                  v-if="chartBodygraphChart2.channels2['35-36']"
                  @click="
                    openTooltipDialog(chartBodygraphChart2.channels2['35-36'])
                  "
                  @mouseover="searchAcPlanets(35, 36)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_35_36_channel_chart2"
                >
                  <path
                    d="m 455.8,288.4 c 47.3,0 91.7,28.7 125.9,81.2 l 9.2,-4.3 c -0.1,-0.2 -0.3,-0.4 -0.4,-0.6 C 554.3,309 506.8,278.4 455.8,278.4 h -0.9 v 10 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 582,369.9 c 1.8,2.7 3.5,5.5 5.2,8.3 32.6,54.1 52.3,126.5 56.4,205.7 l 10,-1.1 c -4.2,-80.6 -24.4,-154.4 -57.8,-209.7 -1.6,-2.7 -3.2,-5.2 -4.9,-7.8 l -9.2,4.3 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_12_22_channel_chart1"
                  v-if="chartBodygraphChart.channels2['12-22']"
                  @click="
                    openTooltipDialog(chartBodygraphChart.channels2['12-22'])
                  "
                  @mouseover="searchAcPlanets(12, 22)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_12_22_channel_chart1"
                >
                  <path
                    d="m 455.8,310.4 c 39.9,0 77.6,22.7 107.6,64.5 0.6,0.8 1.1,1.6 1.7,2.4 l 9.2,-4.3 c -0.5,-0.7 -0.9,-1.4 -1.4,-2.1 -32.1,-45.6 -73.2,-70.6 -117.1,-70.6 h -0.9 v 10 a 2.77,2.77 0 0 0 0.9,0.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 574.3,391.4 c 31.1,51.6 49.3,121.8 51.4,198.1 l 9.9,-3 c -2.6,-77 -21.2,-147.8 -52.8,-200.2 -2.8,-4.6 -5.6,-9 -8.6,-13.3 l -9.2,4.3 c 3.3,4.5 6.3,9.2 9.3,14.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_12_22_channel_chart2"
                  v-if="chartBodygraphChart2.channels2['12-22']"
                  @click="
                    openTooltipDialog(chartBodygraphChart2.channels2['12-22'])
                  "
                  @mouseover="searchAcPlanets(12, 22)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_12_22_channel_chart2"
                >
                  <path
                    d="m 455.8,310.4 c 39.9,0 77.6,22.7 107.6,64.5 0.6,0.8 1.1,1.6 1.7,2.4 l 9.2,-4.3 c -0.5,-0.7 -0.9,-1.4 -1.4,-2.1 -32.1,-45.6 -73.2,-70.6 -117.1,-70.6 h -0.9 v 10 a 2.77,2.77 0 0 0 0.9,0.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 574.3,391.4 c 31.1,51.6 49.3,121.8 51.4,198.1 l 9.9,-3 c -2.6,-77 -21.2,-147.8 -52.8,-200.2 -2.8,-4.6 -5.6,-9 -8.6,-13.3 l -9.2,4.3 c 3.3,4.5 6.3,9.2 9.3,14.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_21_45_channel_chart1"
                  v-if="chartBodygraphChart.channels2['21-45']"
                  @click="
                    openTooltipDialog(chartBodygraphChart.channels2['21-45'])
                  "
                  @mouseover="searchAcPlanets(21, 45)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_21_45_channel_chart1"
                >
                  <path
                    id="gate_21_channel"
                    d="m 555,478.4 h 10 c 0,-33.3 -7,-65.1 -20.1,-91.7 l -9.1,4.2 c 12.1,24.5 19.2,54.8 19.2,87.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    id="gate_45_channel"
                    d="m 456.5,320.4 v 10 c 32.5,0 61.4,23.8 79.4,60.5 l 9.1,-4.2 a 158.69,158.69 0 0 0 -11.1,-19.3 c -20.6,-30.3 -48.1,-47 -77.4,-47 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_21_45_channel_chart2"
                  v-if="chartBodygraphChart2.channels2['21-45']"
                  @click="
                    openTooltipDialog(chartBodygraphChart2.channels2['21-45'])
                  "
                  @mouseover="searchAcPlanets(21, 45)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_21_45_channel_chart2"
                >
                  <path
                    id="gate_21_channel"
                    d="m 555,478.4 h 10 c 0,-33.3 -7,-65.1 -20.1,-91.7 l -9.1,4.2 c 12.1,24.5 19.2,54.8 19.2,87.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    id="gate_45_channel"
                    d="m 456.5,320.4 v 10 c 32.5,0 61.4,23.8 79.4,60.5 l 9.1,-4.2 a 158.69,158.69 0 0 0 -11.1,-19.3 c -20.6,-30.3 -48.1,-47 -77.4,-47 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_26_44_channel_chart1"
                  v-if="chartBodygraphChart.channels2['26-44']"
                  @click="
                    openTooltipDialog(chartBodygraphChart.channels2['26-44'])
                  "
                  @mouseover="searchAcPlanets(26, 44)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_26_44_channel_chart1"
                >
                  <path
                    d="m 457.5,484.4 a 337.29,337.29 0 0 0 -51.6,3.8 l 3,9.8 a 327.49,327.49 0 0 1 48.6,-3.6 c 31.9,0 61.9,4.6 86.2,12.2 l 2.9,-9.8 c -25.1,-7.9 -55.8,-12.4 -89.1,-12.4 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 311,555.9 c 0,-26.3 41.4,-49.3 97.9,-57.9 l -3,-9.8 c -61.5,9.6 -104.9,36 -104.9,67.7 0,7.9 2.7,15.5 7.7,22.6 l 10,-3.3 c -5,-6.1 -7.7,-12.6 -7.7,-19.3 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_26_44_channel_chart2"
                  v-if="chartBodygraphChart2.channels2['26-44']"
                  @click="
                    openTooltipDialog(chartBodygraphChart2.channels2['26-44'])
                  "
                  @mouseover="searchAcPlanets(26, 44)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_26_44_channel_chart2"
                >
                  <path
                    d="m 457.5,484.4 a 337.29,337.29 0 0 0 -51.6,3.8 l 3,9.8 a 327.49,327.49 0 0 1 48.6,-3.6 c 31.9,0 61.9,4.6 86.2,12.2 l 2.9,-9.8 c -25.1,-7.9 -55.8,-12.4 -89.1,-12.4 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 311,555.9 c 0,-26.3 41.4,-49.3 97.9,-57.9 l -3,-9.8 c -61.5,9.6 -104.9,36 -104.9,67.7 0,7.9 2.7,15.5 7.7,22.6 l 10,-3.3 c -5,-6.1 -7.7,-12.6 -7.7,-19.3 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_37_40_channel_chart1"
                  v-if="chartBodygraphChart.channels2['37-40']"
                  @click="
                    openTooltipDialog(chartBodygraphChart.channels2['37-40'])
                  "
                  @mouseover="searchAcPlanets(37, 40)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_37_40_channel_chart1"
                >
                  <path
                    d="m 598.5,539.6 10.5,-2 c -9.5,-17 -31.9,-31.4 -62.5,-40.9 l -2.9,9.8 c 26.2,8.2 45.9,19.8 54.9,33.1 z"
                    style="fill:transparent"
                    transform="translate(-46.94,-28.51)"
                  />
                  <path
                    d="m 609,537.7 -10.5,2 a 29.12,29.12 0 0 1 5.4,16.3 c 0,8.2 -4,16 -11.3,23.2 l 8,6.1 c 8.5,-8.9 13.3,-18.8 13.3,-29.3 A 35.42,35.42 0 0 0 609,537.7 Z"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                    transform="translate(-46.94,-28.51)"
                  />
                </g>
                <g
                  id="gate_37_40_channel_chart2"
                  v-if="chartBodygraphChart2.channels2['37-40']"
                  @click="
                    openTooltipDialog(chartBodygraphChart2.channels2['37-40'])
                  "
                  @mouseover="searchAcPlanets(37, 40)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_37_40_channel_chart2"
                >
                  <path
                    d="m 598.5,539.6 10.5,-2 c -9.5,-17 -31.9,-31.4 -62.5,-40.9 l -2.9,9.8 c 26.2,8.2 45.9,19.8 54.9,33.1 z"
                    style="fill:transparent"
                    transform="translate(-46.94,-28.51)"
                  />
                  <path
                    d="m 609,537.7 -10.5,2 a 29.12,29.12 0 0 1 5.4,16.3 c 0,8.2 -4,16 -11.3,23.2 l 8,6.1 c 8.5,-8.9 13.3,-18.8 13.3,-29.3 A 35.42,35.42 0 0 0 609,537.7 Z"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                    transform="translate(-46.94,-28.51)"
                  />
                </g>
              </g>
              <g
                id="Слой_3"
                style="display:inline"
                data-name="Слой 3"
                transform="translate(31.240005,17.409983)"
              >
                <g
                  id="gate_23_43_channel_chart1"
                  v-if="chartBodygraphChart.channels2['23-43']"
                  @click="
                    openTooltipDialog(chartBodygraphChart.channels2['23-43'])
                  "
                  @mouseover="searchAcPlanets(23, 43)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_23_43_channel_chart1"
                >
                  <rect
                    x="403.85999"
                    y="214.89"
                    width="10"
                    height="56"
                    style="fill:transparent"
                  />
                  <rect
                    x="403.85999"
                    y="145.39"
                    width="10"
                    height="69.5"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_23_43_channel_chart2"
                  v-if="chartBodygraphChart2.channels2['23-43']"
                  @click="
                    openTooltipDialog(chartBodygraphChart2.channels2['23-43'])
                  "
                  @mouseover="searchAcPlanets(23, 43)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_23_43_channel_chart2"
                >
                  <rect
                    x="403.85999"
                    y="214.89"
                    width="10"
                    height="56"
                    style="fill:transparent"
                  />
                  <rect
                    x="403.85999"
                    y="145.39"
                    width="10"
                    height="69.5"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_11_56_channel_chart1"
                  v-if="chartBodygraphChart.channels2['11-56']"
                  @click="
                    openTooltipDialog(chartBodygraphChart.channels2['11-56'])
                  "
                  @mouseover="searchAcPlanets(11, 56)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_11_56_channel_chart1"
                >
                  <rect
                    x="424.35999"
                    y="145.39"
                    width="10"
                    height="69.5"
                    style="fill:transparent"
                  />
                  <rect
                    x="424.35999"
                    y="214.89"
                    width="10"
                    height="56"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_11_56_channel_chart2"
                  v-if="chartBodygraphChart2.channels2['11-56']"
                  @click="
                    openTooltipDialog(chartBodygraphChart2.channels2['11-56'])
                  "
                  @mouseover="searchAcPlanets(11, 56)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_11_56_channel_chart2"
                >
                  <rect
                    x="424.35999"
                    y="145.39"
                    width="10"
                    height="69.5"
                    style="fill:transparent"
                  />
                  <rect
                    x="424.35999"
                    y="214.89"
                    width="10"
                    height="56"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_17_62_channel_chart1"
                  v-if="chartBodygraphChart.channels2['17-62']"
                  @click="
                    openTooltipDialog(chartBodygraphChart.channels2['17-62'])
                  "
                  @mouseover="searchAcPlanets(17, 62)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_17_62_channel_chart1"
                >
                  <rect
                    x="383.35999"
                    y="145.39"
                    width="10"
                    height="69.5"
                    style="fill:transparent"
                  />
                  <rect
                    x="383.35999"
                    y="214.89"
                    width="10"
                    height="56"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_17_62_channel_chart2"
                  v-if="chartBodygraphChart2.channels2['17-62']"
                  @click="
                    openTooltipDialog(chartBodygraphChart2.channels2['17-62'])
                  "
                  @mouseover="searchAcPlanets(17, 62)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_17_62_channel_chart2"
                >
                  <rect
                    x="383.35999"
                    y="145.39"
                    width="10"
                    height="69.5"
                    style="fill:transparent"
                  />
                  <rect
                    x="383.35999"
                    y="214.89"
                    width="10"
                    height="56"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_04_63_channel_chart1"
                  v-if="chartBodygraphChart.channels2['4-63']"
                  @click="
                    openTooltipDialog(chartBodygraphChart.channels2['4-63'])
                  "
                  @mouseover="searchAcPlanets(4, 63)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_4_63_channel_chart1"
                >
                  <rect
                    id="gate_63_04_channel"
                    x="424.35999"
                    y="73.889999"
                    width="10"
                    height="32"
                    style="fill:transparent"
                  />
                  <rect
                    id="gate_04_64_channel"
                    x="424.35999"
                    y="105.89"
                    width="10"
                    height="39.5"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_04_63_channel_chart2"
                  v-if="chartBodygraphChart2.channels2['4-63']"
                  @click="
                    openTooltipDialog(chartBodygraphChart2.channels2['4-63'])
                  "
                  @mouseover="searchAcPlanets(4, 63)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_4_63_channel_chart2"
                >
                  <rect
                    id="gate_63_04_channel"
                    x="424.35999"
                    y="73.889999"
                    width="10"
                    height="32"
                    style="fill:transparent"
                  />
                  <rect
                    id="gate_04_64_channel"
                    x="424.35999"
                    y="105.89"
                    width="10"
                    height="39.5"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_47_64_channel_chart1"
                  v-if="chartBodygraphChart.channels2['47-64']"
                  @click="
                    openTooltipDialog(chartBodygraphChart.channels2['47-64'])
                  "
                  @mouseover="searchAcPlanets(47, 64)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_47_64_channel_chart1"
                >
                  <rect
                    id="gate_64_47_rect"
                    x="383.35999"
                    y="73.889999"
                    width="10"
                    height="32"
                    style="fill:transparent"
                  />
                  <rect
                    id="gate_47_64_rect"
                    x="383.35999"
                    y="105.89"
                    width="10"
                    height="39.5"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_47_64_channel_chart2"
                  v-if="chartBodygraphChart2.channels2['47-64']"
                  @click="
                    openTooltipDialog(chartBodygraphChart2.channels2['47-64'])
                  "
                  @mouseover="searchAcPlanets(47, 64)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_47_64_channel_chart2"
                >
                  <rect
                    id="gate_64_47_rect"
                    x="383.35999"
                    y="73.889999"
                    width="10"
                    height="32"
                    style="fill:transparent"
                  />
                  <rect
                    id="gate_47_64_rect"
                    x="383.35999"
                    y="105.89"
                    width="10"
                    height="39.5"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_07_31_channel_chart1"
                  v-if="chartBodygraphChart.channels2['7-31']"
                  @click="
                    openTooltipDialog(chartBodygraphChart.channels2['7-31'])
                  "
                  @mouseover="searchAcPlanets(7, 31)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_07_31_channel_chart1"
                >
                  <rect
                    x="383.35999"
                    y="339.89001"
                    width="10"
                    height="61.5"
                    style="fill:transparent"
                  />
                  <rect
                    x="383.35999"
                    y="270.89001"
                    width="10"
                    height="69"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_07_31_channel_chart2"
                  v-if="chartBodygraphChart2.channels2['7-31']"
                  @click="
                    openTooltipDialog(chartBodygraphChart2.channels2['7-31'])
                  "
                  @mouseover="searchAcPlanets(7, 31)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_07_31_channel_chart2"
                >
                  <rect
                    x="383.35999"
                    y="339.89001"
                    width="10"
                    height="61.5"
                    style="fill:transparent"
                  />
                  <rect
                    x="383.35999"
                    y="270.89001"
                    width="10"
                    height="69"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10_20_channel_chart1"
                  v-if="chartBodygraphChart.channels2['10-20']"
                  @click="
                    openTooltipDialog(chartBodygraphChart.channels2['10-20'])
                  "
                  @mouseover="searchAcPlanets(10, 20)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10_20_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10_20_channel_chart2"
                  v-if="chartBodygraphChart2.channels2['10-20']"
                  @click="
                    openTooltipDialog(chartBodygraphChart2.channels2['10-20'])
                  "
                  @mouseover="searchAcPlanets(10, 20)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10_20_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10_34_channel_chart1"
                  v-if="chartBodygraphChart.channels2['10-34']"
                  @click="
                    openTooltipDialog(chartBodygraphChart.channels2['10-34'])
                  "
                  @mouseover="searchAcPlanets(10, 34)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10_34_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10_34_channel_chart2"
                  v-if="chartBodygraphChart2.channels2['10-34']"
                  @click="
                    openTooltipDialog(chartBodygraphChart2.channels2['10-34'])
                  "
                  @mouseover="searchAcPlanets(10, 34)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10_34_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10_57_channel_chart1"
                  v-if="chartBodygraphChart.channels2['10-57']"
                  @click="
                    openTooltipDialog(chartBodygraphChart.channels2['10-57'])
                  "
                  @mouseover="searchAcPlanets(10, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10_57_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10_57_channel_chart2"
                  v-if="chartBodygraphChart2.channels2['10-57']"
                  @click="
                    openTooltipDialog(chartBodygraphChart2.channels2['10-57'])
                  "
                  @mouseover="searchAcPlanets(10, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10_57_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-57_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['10-20'] &&
                      chartBodygraphChart.channels2['10-57'] &&
                      !chartBodygraphChart.channels2['10-34'] &&
                      !chartBodygraphChart.channels2['20-57'] &&
                      !chartBodygraphChart.channels2['20-34'] &&
                      !chartBodygraphChart.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-20'],
                      chartBodygraphChart.channels2['10-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-57_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-57_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-20'] &&
                      chartBodygraphChart2.channels2['10-57'] &&
                      !chartBodygraphChart2.channels2['10-34'] &&
                      !chartBodygraphChart2.channels2['20-57'] &&
                      !chartBodygraphChart2.channels2['20-34'] &&
                      !chartBodygraphChart2.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-20'],
                      chartBodygraphChart2.channels2['10-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-57_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_20-57_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['10-20'] &&
                      chartBodygraphChart.channels2['20-57'] &&
                      !chartBodygraphChart.channels2['10-34'] &&
                      !chartBodygraphChart.channels2['10-57'] &&
                      !chartBodygraphChart.channels2['20-34'] &&
                      !chartBodygraphChart.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-20'],
                      chartBodygraphChart.channels2['20-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_20-57_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_20-57_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-20'] &&
                      chartBodygraphChart2.channels2['20-57'] &&
                      !chartBodygraphChart2.channels2['10-34'] &&
                      !chartBodygraphChart2.channels2['10-57'] &&
                      !chartBodygraphChart2.channels2['20-34'] &&
                      !chartBodygraphChart2.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-20'],
                      chartBodygraphChart2.channels2['20-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_20-57_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-57_20-57_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['10-20'] &&
                      chartBodygraphChart.channels2['10-57'] &&
                      chartBodygraphChart.channels2['20-57'] &&
                      !chartBodygraphChart.channels2['10-34'] &&
                      !chartBodygraphChart.channels2['20-34'] &&
                      !chartBodygraphChart.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-20'],
                      chartBodygraphChart.channels2['10-57'],
                      chartBodygraphChart.channels2['20-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-57_20-57_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-57_20-57_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-20'] &&
                      chartBodygraphChart2.channels2['10-57'] &&
                      chartBodygraphChart2.channels2['20-57'] &&
                      !chartBodygraphChart2.channels2['10-34'] &&
                      !chartBodygraphChart2.channels2['20-34'] &&
                      !chartBodygraphChart2.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-20'],
                      chartBodygraphChart2.channels2['10-57'],
                      chartBodygraphChart2.channels2['20-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-57_20-57_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['10-20'] &&
                      chartBodygraphChart.channels2['10-34'] &&
                      !chartBodygraphChart.channels2['10-57'] &&
                      !chartBodygraphChart.channels2['20-34'] &&
                      !chartBodygraphChart.channels2['20-57'] &&
                      !chartBodygraphChart.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-20'],
                      chartBodygraphChart.channels2['10-34']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-20'] &&
                      chartBodygraphChart2.channels2['10-34'] &&
                      !chartBodygraphChart2.channels2['10-57'] &&
                      !chartBodygraphChart2.channels2['20-34'] &&
                      !chartBodygraphChart2.channels2['20-57'] &&
                      !chartBodygraphChart2.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-20'],
                      chartBodygraphChart2.channels2['10-34']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_20-34_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['10-34'] &&
                      chartBodygraphChart.channels2['20-34'] &&
                      !chartBodygraphChart.channels2['10-20'] &&
                      !chartBodygraphChart.channels2['10-57'] &&
                      !chartBodygraphChart.channels2['20-57'] &&
                      !chartBodygraphChart.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-34'],
                      chartBodygraphChart.channels2['20-34']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-34_20-34_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_20-34_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-34'] &&
                      chartBodygraphChart2.channels2['20-34'] &&
                      !chartBodygraphChart2.channels2['10-20'] &&
                      !chartBodygraphChart2.channels2['10-34'] &&
                      !chartBodygraphChart2.channels2['20-57'] &&
                      !chartBodygraphChart2.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-34'],
                      chartBodygraphChart2.channels2['20-34']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-34_20-34_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_20-34_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['10-20'] &&
                      chartBodygraphChart.channels2['20-34'] &&
                      !chartBodygraphChart.channels2['10-34'] &&
                      !chartBodygraphChart.channels2['10-57'] &&
                      !chartBodygraphChart.channels2['20-57'] &&
                      !chartBodygraphChart.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-20'],
                      chartBodygraphChart.channels2['20-34']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_20-34_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_20-34_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-20'] &&
                      chartBodygraphChart2.channels2['20-34'] &&
                      !chartBodygraphChart2.channels2['10-34'] &&
                      !chartBodygraphChart2.channels2['10-57'] &&
                      !chartBodygraphChart2.channels2['20-57'] &&
                      !chartBodygraphChart2.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-20'],
                      chartBodygraphChart2.channels2['20-34']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_20-34_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_20-34_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['10-20'] &&
                      chartBodygraphChart.channels2['10-34'] &&
                      chartBodygraphChart.channels2['20-34'] &&
                      !chartBodygraphChart.channels2['10-57'] &&
                      !chartBodygraphChart.channels2['20-57'] &&
                      !chartBodygraphChart.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-20'],
                      chartBodygraphChart.channels2['10-34'],
                      chartBodygraphChart.channels2['20-34']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_20-34_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_20-34_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-20'] &&
                      chartBodygraphChart2.channels2['10-34'] &&
                      chartBodygraphChart2.channels2['20-34'] &&
                      !chartBodygraphChart2.channels2['10-57'] &&
                      !chartBodygraphChart2.channels2['20-57'] &&
                      !chartBodygraphChart2.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-20'],
                      chartBodygraphChart2.channels2['10-34'],
                      chartBodygraphChart2.channels2['20-34']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_20-34_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_20-34_20-57_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['20-34'] &&
                      chartBodygraphChart.channels2['20-57'] &&
                      !chartBodygraphChart.channels2['10-20'] &&
                      !chartBodygraphChart.channels2['10-34'] &&
                      !chartBodygraphChart.channels2['10-57'] &&
                      !chartBodygraphChart.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['20-34'],
                      chartBodygraphChart.channels2['20-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_20-34_20-57_channel_chart1"
                >
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                    transform="translate(-46.94,-28.51)"
                  />
                </g>
                <g
                  id="gate_20-34_20-57_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['20-34'] &&
                      chartBodygraphChart2.channels2['20-57'] &&
                      !chartBodygraphChart2.channels2['10-20'] &&
                      !chartBodygraphChart2.channels2['10-34'] &&
                      !chartBodygraphChart2.channels2['10-57'] &&
                      !chartBodygraphChart2.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['20-34'],
                      chartBodygraphChart2.channels2['20-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_20-34_20-57_channel_chart2"
                >
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                    transform="translate(-46.94,-28.51)"
                  />
                </g>
                <g
                  id="gate_20-34_34-57_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['20-34'] &&
                      chartBodygraphChart.channels2['34-57'] &&
                      !chartBodygraphChart.channels2['10-20'] &&
                      !chartBodygraphChart.channels2['10-34'] &&
                      !chartBodygraphChart.channels2['10-57'] &&
                      !chartBodygraphChart.channels2['20-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['20-34'],
                      chartBodygraphChart.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_20-34_34-57_channel_chart1"
                >
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                    transform="translate(-46.94,-28.51)"
                  />
                </g>
                <g
                  id="gate_20-34_34-57_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['20-34'] &&
                      chartBodygraphChart2.channels2['34-57'] &&
                      !chartBodygraphChart2.channels2['10-20'] &&
                      !chartBodygraphChart2.channels2['10-34'] &&
                      !chartBodygraphChart2.channels2['10-57'] &&
                      !chartBodygraphChart2.channels2['20-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['20-34'],
                      chartBodygraphChart2.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_20-34_34-57_channel_chart2"
                >
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                    transform="translate(-46.94,-28.51)"
                  />
                </g>
                <g
                  id="gate_20-57_34-57_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['20-57'] &&
                      chartBodygraphChart.channels2['34-57'] &&
                      !chartBodygraphChart.channels2['10-20'] &&
                      !chartBodygraphChart.channels2['10-34'] &&
                      !chartBodygraphChart.channels2['10-57'] &&
                      !chartBodygraphChart.channels2['20-34']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['20-57'],
                      chartBodygraphChart.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_20-57_34-57_channel_chart1"
                >
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                    transform="translate(-46.94,-28.51)"
                  />
                </g>
                <g
                  id="gate_20-57_34-57_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['20-57'] &&
                      chartBodygraphChart2.channels2['34-57'] &&
                      !chartBodygraphChart2.channels2['10-20'] &&
                      !chartBodygraphChart2.channels2['10-34'] &&
                      !chartBodygraphChart2.channels2['10-57'] &&
                      !chartBodygraphChart2.channels2['20-34']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['20-57'],
                      chartBodygraphChart2.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_20-57_34-57_channel_chart2"
                >
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                    transform="translate(-46.94,-28.51)"
                  />
                </g>
                <g
                  id="gate_20-34_20-57_34-57_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['20-34'] &&
                      chartBodygraphChart.channels2['20-57'] &&
                      chartBodygraphChart.channels2['34-57'] &&
                      !chartBodygraphChart.channels2['10-20'] &&
                      !chartBodygraphChart.channels2['10-34'] &&
                      !chartBodygraphChart.channels2['10-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['20-34'],
                      chartBodygraphChart.channels2['20-57'],
                      chartBodygraphChart.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_20-34_20-57_34-57_channel_chart1"
                >
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                    transform="translate(-46.94,-28.51)"
                  />
                </g>
                <g
                  id="gate_20-34_20-57_34-57_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['20-34'] &&
                      chartBodygraphChart2.channels2['20-57'] &&
                      chartBodygraphChart2.channels2['34-57'] &&
                      !chartBodygraphChart2.channels2['10-20'] &&
                      !chartBodygraphChart2.channels2['10-34'] &&
                      !chartBodygraphChart2.channels2['10-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['20-34'],
                      chartBodygraphChart2.channels2['20-57'],
                      chartBodygraphChart2.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_20-34_20-57_34-57_channel_chart2"
                >
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                    transform="translate(-46.94,-28.51)"
                  />
                </g>
                <g
                  id="gate_10-34_10-57_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['10-34'] &&
                      chartBodygraphChart.channels2['10-57'] &&
                      !chartBodygraphChart.channels2['10-20'] &&
                      !chartBodygraphChart.channels2['20-34'] &&
                      !chartBodygraphChart.channels2['20-57'] &&
                      !chartBodygraphChart.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-34'],
                      chartBodygraphChart.channels2['10-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-34_10-57_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_10-57_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-34'] &&
                      chartBodygraphChart2.channels2['10-57'] &&
                      !chartBodygraphChart2.channels2['10-20'] &&
                      !chartBodygraphChart2.channels2['20-34'] &&
                      !chartBodygraphChart2.channels2['20-57'] &&
                      !chartBodygraphChart2.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-34'],
                      chartBodygraphChart2.channels2['10-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-34_10-57_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_34-57_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['10-34'] &&
                      chartBodygraphChart.channels2['34-57'] &&
                      !chartBodygraphChart.channels2['10-20'] &&
                      !chartBodygraphChart.channels2['10-57'] &&
                      !chartBodygraphChart.channels2['20-34'] &&
                      !chartBodygraphChart.channels2['20-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-34'],
                      chartBodygraphChart.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-34_34-57_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_34-57_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-34'] &&
                      chartBodygraphChart2.channels2['34-57'] &&
                      !chartBodygraphChart2.channels2['10-20'] &&
                      !chartBodygraphChart2.channels2['10-57'] &&
                      !chartBodygraphChart2.channels2['20-34'] &&
                      !chartBodygraphChart2.channels2['20-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-34'],
                      chartBodygraphChart2.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-34_34-57_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-57_34-57_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['10-57'] &&
                      chartBodygraphChart.channels2['34-57'] &&
                      !chartBodygraphChart.channels2['10-20'] &&
                      !chartBodygraphChart.channels2['10-34'] &&
                      !chartBodygraphChart.channels2['20-34'] &&
                      !chartBodygraphChart.channels2['20-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-57'],
                      chartBodygraphChart.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-57_34-57_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-57_34-57_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-57'] &&
                      chartBodygraphChart2.channels2['34-57'] &&
                      !chartBodygraphChart2.channels2['10-20'] &&
                      !chartBodygraphChart2.channels2['10-34'] &&
                      !chartBodygraphChart2.channels2['20-34'] &&
                      !chartBodygraphChart2.channels2['20-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-57'],
                      chartBodygraphChart2.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-57_34-57_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_10-57_34-57_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['10-34'] &&
                      chartBodygraphChart.channels2['10-57'] &&
                      chartBodygraphChart.channels2['34-57'] &&
                      !chartBodygraphChart.channels2['10-20'] &&
                      !chartBodygraphChart.channels2['20-34'] &&
                      !chartBodygraphChart.channels2['20-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-34'],
                      chartBodygraphChart.channels2['10-57'],
                      chartBodygraphChart.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-34_10-57_34-57_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_10-57_34-57_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-34'] &&
                      chartBodygraphChart2.channels2['10-57'] &&
                      chartBodygraphChart2.channels2['34-57'] &&
                      !chartBodygraphChart2.channels2['10-20'] &&
                      !chartBodygraphChart2.channels2['20-34'] &&
                      !chartBodygraphChart2.channels2['20-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-34'],
                      chartBodygraphChart2.channels2['10-57'],
                      chartBodygraphChart2.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-34_10-57_34-57_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_34-57_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['10-20'] &&
                      chartBodygraphChart.channels2['34-57'] &&
                      !chartBodygraphChart.channels2['10-34'] &&
                      !chartBodygraphChart.channels2['10-57'] &&
                      !chartBodygraphChart.channels2['20-34'] &&
                      !chartBodygraphChart.channels2['20-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-20'],
                      chartBodygraphChart.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_34-57_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_34-57_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-20'] &&
                      chartBodygraphChart2.channels2['34-57'] &&
                      !chartBodygraphChart2.channels2['10-34'] &&
                      !chartBodygraphChart2.channels2['10-57'] &&
                      !chartBodygraphChart2.channels2['20-34'] &&
                      !chartBodygraphChart2.channels2['20-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-20'],
                      chartBodygraphChart2.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_34-57_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_20-57_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['10-34'] &&
                      chartBodygraphChart.channels2['20-57'] &&
                      !chartBodygraphChart.channels2['10-20'] &&
                      !chartBodygraphChart.channels2['10-57'] &&
                      !chartBodygraphChart.channels2['20-34'] &&
                      !chartBodygraphChart.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-34'],
                      chartBodygraphChart.channels2['20-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-34_20-57_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_20-57_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-34'] &&
                      chartBodygraphChart2.channels2['20-57'] &&
                      !chartBodygraphChart2.channels2['10-20'] &&
                      !chartBodygraphChart2.channels2['10-57'] &&
                      !chartBodygraphChart2.channels2['20-34'] &&
                      !chartBodygraphChart2.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-34'],
                      chartBodygraphChart2.channels2['20-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-34_20-57_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-57_20-34_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['10-57'] &&
                      chartBodygraphChart.channels2['20-34'] &&
                      !chartBodygraphChart.channels2['10-20'] &&
                      !chartBodygraphChart.channels2['10-34'] &&
                      !chartBodygraphChart.channels2['20-57'] &&
                      !chartBodygraphChart.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-57'],
                      chartBodygraphChart.channels2['20-34']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-57_20-34_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-57_20-34_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-57'] &&
                      chartBodygraphChart2.channels2['20-34'] &&
                      !chartBodygraphChart2.channels2['10-20'] &&
                      !chartBodygraphChart2.channels2['10-34'] &&
                      !chartBodygraphChart2.channels2['20-57'] &&
                      !chartBodygraphChart2.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-57'],
                      chartBodygraphChart2.channels2['20-34']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-57_20-34_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_10-57_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['10-20'] &&
                      chartBodygraphChart.channels2['10-34'] &&
                      chartBodygraphChart.channels2['10-57'] &&
                      !chartBodygraphChart.channels2['20-34'] &&
                      !chartBodygraphChart.channels2['20-57'] &&
                      !chartBodygraphChart.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-20'],
                      chartBodygraphChart.channels2['10-34'],
                      chartBodygraphChart.channels2['10-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_10-57_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_10-57_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-20'] &&
                      chartBodygraphChart2.channels2['10-34'] &&
                      chartBodygraphChart2.channels2['10-57'] &&
                      !chartBodygraphChart2.channels2['20-34'] &&
                      !chartBodygraphChart2.channels2['20-57'] &&
                      !chartBodygraphChart2.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-20'],
                      chartBodygraphChart2.channels2['10-34'],
                      chartBodygraphChart2.channels2['10-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_10-57_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_20-57_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['10-20'] &&
                      chartBodygraphChart.channels2['10-34'] &&
                      chartBodygraphChart.channels2['20-57'] &&
                      !chartBodygraphChart.channels2['10-57'] &&
                      !chartBodygraphChart.channels2['20-34'] &&
                      !chartBodygraphChart.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-20'],
                      chartBodygraphChart.channels2['10-34'],
                      chartBodygraphChart.channels2['20-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_20-57_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_20-57_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-20'] &&
                      chartBodygraphChart2.channels2['10-34'] &&
                      chartBodygraphChart2.channels2['20-57'] &&
                      !chartBodygraphChart2.channels2['10-57'] &&
                      !chartBodygraphChart2.channels2['20-34'] &&
                      !chartBodygraphChart2.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-20'],
                      chartBodygraphChart2.channels2['10-34'],
                      chartBodygraphChart2.channels2['20-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_20-57_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_34-57_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['10-20'] &&
                      chartBodygraphChart.channels2['10-34'] &&
                      chartBodygraphChart.channels2['34-57'] &&
                      !chartBodygraphChart.channels2['10-57'] &&
                      !chartBodygraphChart.channels2['20-34'] &&
                      !chartBodygraphChart.channels2['20-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-20'],
                      chartBodygraphChart.channels2['10-34'],
                      chartBodygraphChart.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_34-57_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_34-57_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-20'] &&
                      chartBodygraphChart2.channels2['10-34'] &&
                      chartBodygraphChart2.channels2['34-57'] &&
                      !chartBodygraphChart2.channels2['10-57'] &&
                      !chartBodygraphChart2.channels2['20-34'] &&
                      !chartBodygraphChart2.channels2['20-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-20'],
                      chartBodygraphChart2.channels2['10-34'],
                      chartBodygraphChart2.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_34-57_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-57_20-34_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['10-20'] &&
                      chartBodygraphChart.channels2['10-57'] &&
                      chartBodygraphChart.channels2['20-34'] &&
                      !chartBodygraphChart.channels2['10-34'] &&
                      !chartBodygraphChart.channels2['20-57'] &&
                      !chartBodygraphChart.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-20'],
                      chartBodygraphChart.channels2['10-57'],
                      chartBodygraphChart.channels2['20-34']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-57_20-34_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-57_20-34_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-20'] &&
                      chartBodygraphChart2.channels2['10-57'] &&
                      chartBodygraphChart2.channels2['20-34'] &&
                      !chartBodygraphChart2.channels2['10-34'] &&
                      !chartBodygraphChart2.channels2['20-57'] &&
                      !chartBodygraphChart2.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-20'],
                      chartBodygraphChart2.channels2['10-57'],
                      chartBodygraphChart2.channels2['20-34']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-57_20-34_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-57_34-57_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['10-20'] &&
                      chartBodygraphChart.channels2['10-57'] &&
                      chartBodygraphChart.channels2['34-57'] &&
                      !chartBodygraphChart.channels2['10-34'] &&
                      !chartBodygraphChart.channels2['20-34'] &&
                      !chartBodygraphChart.channels2['20-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-20'],
                      chartBodygraphChart.channels2['10-57'],
                      chartBodygraphChart.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-57_34-57_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-57_34-57_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-20'] &&
                      chartBodygraphChart2.channels2['10-57'] &&
                      chartBodygraphChart2.channels2['34-57'] &&
                      !chartBodygraphChart2.channels2['10-34'] &&
                      !chartBodygraphChart2.channels2['20-34'] &&
                      !chartBodygraphChart2.channels2['20-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-20'],
                      chartBodygraphChart2.channels2['10-57'],
                      chartBodygraphChart2.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-57_34-57_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_20-34_20-57_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['10-20'] &&
                      chartBodygraphChart.channels2['20-34'] &&
                      chartBodygraphChart.channels2['20-57'] &&
                      !chartBodygraphChart.channels2['10-34'] &&
                      !chartBodygraphChart.channels2['10-57'] &&
                      !chartBodygraphChart.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-20'],
                      chartBodygraphChart.channels2['20-34'],
                      chartBodygraphChart.channels2['20-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_20-34_20-57_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_20-34_20-57_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-20'] &&
                      chartBodygraphChart2.channels2['20-34'] &&
                      chartBodygraphChart2.channels2['20-57'] &&
                      !chartBodygraphChart2.channels2['10-34'] &&
                      !chartBodygraphChart2.channels2['10-57'] &&
                      !chartBodygraphChart2.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-20'],
                      chartBodygraphChart2.channels2['20-34'],
                      chartBodygraphChart2.channels2['20-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_20-34_20-57_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_20-34_34-57_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['10-20'] &&
                      chartBodygraphChart.channels2['20-34'] &&
                      chartBodygraphChart.channels2['34-57'] &&
                      !chartBodygraphChart.channels2['10-34'] &&
                      !chartBodygraphChart.channels2['10-57'] &&
                      !chartBodygraphChart.channels2['20-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-20'],
                      chartBodygraphChart.channels2['20-34'],
                      chartBodygraphChart.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_20-34_34-57_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_20-34_34-57_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-20'] &&
                      chartBodygraphChart2.channels2['20-34'] &&
                      chartBodygraphChart2.channels2['34-57'] &&
                      !chartBodygraphChart2.channels2['10-34'] &&
                      !chartBodygraphChart2.channels2['10-57'] &&
                      !chartBodygraphChart2.channels2['20-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-20'],
                      chartBodygraphChart2.channels2['20-34'],
                      chartBodygraphChart2.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_20-34_34-57_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_20-57_34-57_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['10-20'] &&
                      chartBodygraphChart.channels2['20-57'] &&
                      chartBodygraphChart.channels2['34-57'] &&
                      !chartBodygraphChart.channels2['10-34'] &&
                      !chartBodygraphChart.channels2['10-57'] &&
                      !chartBodygraphChart.channels2['20-34']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-20'],
                      chartBodygraphChart.channels2['20-57'],
                      chartBodygraphChart.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_20-57_34-57_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_20-57_34-57_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-20'] &&
                      chartBodygraphChart2.channels2['20-57'] &&
                      chartBodygraphChart2.channels2['34-57'] &&
                      !chartBodygraphChart2.channels2['10-34'] &&
                      !chartBodygraphChart2.channels2['10-57'] &&
                      !chartBodygraphChart2.channels2['20-34']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-20'],
                      chartBodygraphChart2.channels2['20-57'],
                      chartBodygraphChart2.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_20-57_34-57_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_10-57_20-34_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['10-34'] &&
                      chartBodygraphChart.channels2['10-57'] &&
                      chartBodygraphChart.channels2['20-34'] &&
                      !chartBodygraphChart.channels2['10-20'] &&
                      !chartBodygraphChart.channels2['20-57'] &&
                      !chartBodygraphChart.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-34'],
                      chartBodygraphChart.channels2['10-57'],
                      chartBodygraphChart.channels2['20-34']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-34_10-57_20-34_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_10-57_20-34_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-34'] &&
                      chartBodygraphChart2.channels2['10-57'] &&
                      chartBodygraphChart2.channels2['20-34'] &&
                      !chartBodygraphChart2.channels2['10-20'] &&
                      !chartBodygraphChart2.channels2['20-57'] &&
                      !chartBodygraphChart2.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-34'],
                      chartBodygraphChart2.channels2['10-57'],
                      chartBodygraphChart2.channels2['20-34']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-34_10-57_20-34_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_10-57_20-57_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['10-34'] &&
                      chartBodygraphChart.channels2['10-57'] &&
                      chartBodygraphChart.channels2['20-57'] &&
                      !chartBodygraphChart.channels2['10-20'] &&
                      !chartBodygraphChart.channels2['20-34'] &&
                      !chartBodygraphChart.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-34'],
                      chartBodygraphChart.channels2['10-57'],
                      chartBodygraphChart.channels2['20-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-34_10-57_20-57_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_10-57_20-57_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-34'] &&
                      chartBodygraphChart2.channels2['10-57'] &&
                      chartBodygraphChart2.channels2['20-57'] &&
                      !chartBodygraphChart2.channels2['10-20'] &&
                      !chartBodygraphChart2.channels2['20-34'] &&
                      !chartBodygraphChart2.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-34'],
                      chartBodygraphChart2.channels2['10-57'],
                      chartBodygraphChart2.channels2['20-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-34_10-57_20-57_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_20-34_20-57_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['10-34'] &&
                      chartBodygraphChart.channels2['20-34'] &&
                      chartBodygraphChart.channels2['20-57'] &&
                      !chartBodygraphChart.channels2['10-20'] &&
                      !chartBodygraphChart.channels2['10-57'] &&
                      !chartBodygraphChart.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-34'],
                      chartBodygraphChart.channels2['20-34'],
                      chartBodygraphChart.channels2['20-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-34_20-34_20-57_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_20-34_20-57_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-34'] &&
                      chartBodygraphChart2.channels2['20-34'] &&
                      chartBodygraphChart2.channels2['20-57'] &&
                      !chartBodygraphChart2.channels2['10-20'] &&
                      !chartBodygraphChart2.channels2['10-57'] &&
                      !chartBodygraphChart2.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-34'],
                      chartBodygraphChart2.channels2['20-34'],
                      chartBodygraphChart2.channels2['20-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-34_20-34_20-57_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_20-34_34-57_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['10-34'] &&
                      chartBodygraphChart.channels2['20-34'] &&
                      chartBodygraphChart.channels2['34-57'] &&
                      !chartBodygraphChart.channels2['10-20'] &&
                      !chartBodygraphChart.channels2['10-57'] &&
                      !chartBodygraphChart.channels2['20-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-34'],
                      chartBodygraphChart.channels2['20-34'],
                      chartBodygraphChart.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-34_20-34_34-57_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_20-34_34-57_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-34'] &&
                      chartBodygraphChart2.channels2['20-34'] &&
                      chartBodygraphChart2.channels2['34-57'] &&
                      !chartBodygraphChart2.channels2['10-20'] &&
                      !chartBodygraphChart2.channels2['10-57'] &&
                      !chartBodygraphChart2.channels2['20-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-34'],
                      chartBodygraphChart2.channels2['20-34'],
                      chartBodygraphChart2.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-34_20-34_34-57_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_20-57_34-57_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['10-34'] &&
                      chartBodygraphChart.channels2['20-57'] &&
                      chartBodygraphChart.channels2['34-57'] &&
                      !chartBodygraphChart.channels2['10-20'] &&
                      !chartBodygraphChart.channels2['10-57'] &&
                      !chartBodygraphChart.channels2['20-34']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-34'],
                      chartBodygraphChart.channels2['20-57'],
                      chartBodygraphChart.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-34_20-57_34-57_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_20-57_34-57_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-34'] &&
                      chartBodygraphChart2.channels2['20-57'] &&
                      chartBodygraphChart2.channels2['34-57'] &&
                      !chartBodygraphChart2.channels2['10-20'] &&
                      !chartBodygraphChart2.channels2['10-57'] &&
                      !chartBodygraphChart2.channels2['20-34']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-34'],
                      chartBodygraphChart2.channels2['20-57'],
                      chartBodygraphChart2.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-34_20-57_34-57_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-57_20-34_20-57_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['10-57'] &&
                      chartBodygraphChart.channels2['20-34'] &&
                      chartBodygraphChart.channels2['20-57'] &&
                      !chartBodygraphChart.channels2['10-20'] &&
                      !chartBodygraphChart.channels2['10-34'] &&
                      !chartBodygraphChart.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-57'],
                      chartBodygraphChart.channels2['20-34'],
                      chartBodygraphChart.channels2['20-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-57_20-34_20-57_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-57_20-34_20-57_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-57'] &&
                      chartBodygraphChart2.channels2['20-34'] &&
                      chartBodygraphChart2.channels2['20-57'] &&
                      !chartBodygraphChart2.channels2['10-20'] &&
                      !chartBodygraphChart2.channels2['10-34'] &&
                      !chartBodygraphChart2.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-57'],
                      chartBodygraphChart2.channels2['20-34'],
                      chartBodygraphChart2.channels2['20-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-57_20-34_20-57_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-57_20-34_34-57_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['10-57'] &&
                      chartBodygraphChart.channels2['20-34'] &&
                      chartBodygraphChart.channels2['34-57'] &&
                      !chartBodygraphChart.channels2['10-20'] &&
                      !chartBodygraphChart.channels2['10-34'] &&
                      !chartBodygraphChart.channels2['20-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-57'],
                      chartBodygraphChart.channels2['20-34'],
                      chartBodygraphChart.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-57_20-34_34-57_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-57_20-34_34-57_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-57'] &&
                      chartBodygraphChart2.channels2['20-34'] &&
                      chartBodygraphChart2.channels2['34-57'] &&
                      !chartBodygraphChart2.channels2['10-20'] &&
                      !chartBodygraphChart2.channels2['10-34'] &&
                      !chartBodygraphChart2.channels2['20-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-57'],
                      chartBodygraphChart2.channels2['20-34'],
                      chartBodygraphChart2.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-57_20-34_34-57_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-57_20-57_34-57_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['10-57'] &&
                      chartBodygraphChart.channels2['20-57'] &&
                      chartBodygraphChart.channels2['34-57'] &&
                      !chartBodygraphChart.channels2['10-20'] &&
                      !chartBodygraphChart.channels2['10-34'] &&
                      !chartBodygraphChart.channels2['20-34']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-57'],
                      chartBodygraphChart.channels2['20-57'],
                      chartBodygraphChart.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-57_20-57_34-57_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-57_20-57_34-57_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-57'] &&
                      chartBodygraphChart2.channels2['20-57'] &&
                      chartBodygraphChart2.channels2['34-57'] &&
                      !chartBodygraphChart2.channels2['10-20'] &&
                      !chartBodygraphChart2.channels2['10-34'] &&
                      !chartBodygraphChart2.channels2['20-34']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-57'],
                      chartBodygraphChart2.channels2['20-57'],
                      chartBodygraphChart2.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-57_20-57_34-57_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_10-57_20-34_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['10-20'] &&
                      chartBodygraphChart.channels2['10-34'] &&
                      chartBodygraphChart.channels2['10-57'] &&
                      chartBodygraphChart.channels2['20-34'] &&
                      !chartBodygraphChart.channels2['20-57'] &&
                      !chartBodygraphChart.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-20'],
                      chartBodygraphChart.channels2['10-34'],
                      chartBodygraphChart.channels2['10-57'],
                      chartBodygraphChart.channels2['20-34']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_10-57_20-34_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_10-57_20-34_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-20'] &&
                      chartBodygraphChart2.channels2['10-34'] &&
                      chartBodygraphChart2.channels2['10-57'] &&
                      chartBodygraphChart2.channels2['20-34'] &&
                      !chartBodygraphChart2.channels2['20-57'] &&
                      !chartBodygraphChart2.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-20'],
                      chartBodygraphChart2.channels2['10-34'],
                      chartBodygraphChart2.channels2['10-57'],
                      chartBodygraphChart2.channels2['20-34']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_10-57_20-34_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_10-57_20-57_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['10-20'] &&
                      chartBodygraphChart.channels2['10-34'] &&
                      chartBodygraphChart.channels2['10-57'] &&
                      chartBodygraphChart.channels2['20-57'] &&
                      !chartBodygraphChart.channels2['20-34'] &&
                      !chartBodygraphChart.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-20'],
                      chartBodygraphChart.channels2['10-34'],
                      chartBodygraphChart.channels2['10-57'],
                      chartBodygraphChart.channels2['20-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_10-57_20-57_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_10-57_20-57_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-20'] &&
                      chartBodygraphChart2.channels2['10-34'] &&
                      chartBodygraphChart2.channels2['10-57'] &&
                      chartBodygraphChart2.channels2['20-57'] &&
                      !chartBodygraphChart2.channels2['20-34'] &&
                      !chartBodygraphChart2.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-20'],
                      chartBodygraphChart2.channels2['10-34'],
                      chartBodygraphChart2.channels2['10-57'],
                      chartBodygraphChart2.channels2['20-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_10-57_20-57_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_10-57_34-57_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['10-20'] &&
                      chartBodygraphChart.channels2['10-34'] &&
                      chartBodygraphChart.channels2['10-57'] &&
                      chartBodygraphChart.channels2['34-57'] &&
                      !chartBodygraphChart.channels2['20-34'] &&
                      !chartBodygraphChart.channels2['20-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-20'],
                      chartBodygraphChart.channels2['10-34'],
                      chartBodygraphChart.channels2['10-57'],
                      chartBodygraphChart.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_10-57_34-57_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_10-57_34-57_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-20'] &&
                      chartBodygraphChart2.channels2['10-34'] &&
                      chartBodygraphChart2.channels2['10-57'] &&
                      chartBodygraphChart2.channels2['34-57'] &&
                      !chartBodygraphChart2.channels2['20-34'] &&
                      !chartBodygraphChart2.channels2['20-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-20'],
                      chartBodygraphChart2.channels2['10-34'],
                      chartBodygraphChart2.channels2['10-57'],
                      chartBodygraphChart2.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_10-57_34-57_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_20-34_20-57_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['10-20'] &&
                      chartBodygraphChart.channels2['10-34'] &&
                      chartBodygraphChart.channels2['20-34'] &&
                      chartBodygraphChart.channels2['20-57'] &&
                      !chartBodygraphChart.channels2['10-57'] &&
                      !chartBodygraphChart.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-20'],
                      chartBodygraphChart.channels2['10-34'],
                      chartBodygraphChart.channels2['20-34'],
                      chartBodygraphChart.channels2['20-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_20-34_20-57_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_20-34_20-57_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-20'] &&
                      chartBodygraphChart2.channels2['10-34'] &&
                      chartBodygraphChart2.channels2['20-34'] &&
                      chartBodygraphChart2.channels2['20-57'] &&
                      !chartBodygraphChart2.channels2['10-57'] &&
                      !chartBodygraphChart2.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-20'],
                      chartBodygraphChart2.channels2['10-34'],
                      chartBodygraphChart2.channels2['20-34'],
                      chartBodygraphChart2.channels2['20-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_20-34_20-57_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_20-34_34-57_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['10-20'] &&
                      chartBodygraphChart.channels2['10-34'] &&
                      chartBodygraphChart.channels2['20-34'] &&
                      chartBodygraphChart.channels2['34-57'] &&
                      !chartBodygraphChart.channels2['10-57'] &&
                      !chartBodygraphChart.channels2['20-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-20'],
                      chartBodygraphChart.channels2['10-34'],
                      chartBodygraphChart.channels2['20-34'],
                      chartBodygraphChart.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_20-34_34-57_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_20-34_34-57_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-20'] &&
                      chartBodygraphChart2.channels2['10-34'] &&
                      chartBodygraphChart2.channels2['20-34'] &&
                      chartBodygraphChart2.channels2['34-57'] &&
                      !chartBodygraphChart2.channels2['10-57'] &&
                      !chartBodygraphChart2.channels2['20-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-20'],
                      chartBodygraphChart2.channels2['10-34'],
                      chartBodygraphChart2.channels2['20-34'],
                      chartBodygraphChart2.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_20-34_34-57_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_20-57_34-57_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['10-20'] &&
                      chartBodygraphChart.channels2['10-34'] &&
                      chartBodygraphChart.channels2['20-57'] &&
                      chartBodygraphChart.channels2['34-57'] &&
                      !chartBodygraphChart.channels2['10-57'] &&
                      !chartBodygraphChart.channels2['20-34']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-20'],
                      chartBodygraphChart.channels2['10-34'],
                      chartBodygraphChart.channels2['20-57'],
                      chartBodygraphChart.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_20-57_34-57_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_20-57_34-57_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-20'] &&
                      chartBodygraphChart2.channels2['10-34'] &&
                      chartBodygraphChart2.channels2['20-57'] &&
                      chartBodygraphChart2.channels2['34-57'] &&
                      !chartBodygraphChart2.channels2['10-57'] &&
                      !chartBodygraphChart2.channels2['20-34']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-20'],
                      chartBodygraphChart2.channels2['10-34'],
                      chartBodygraphChart2.channels2['20-57'],
                      chartBodygraphChart2.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_20-57_34-57_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-57_20-34_20-57_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['10-20'] &&
                      chartBodygraphChart.channels2['10-57'] &&
                      chartBodygraphChart.channels2['20-34'] &&
                      chartBodygraphChart.channels2['20-57'] &&
                      !chartBodygraphChart.channels2['10-34'] &&
                      !chartBodygraphChart.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-20'],
                      chartBodygraphChart.channels2['10-57'],
                      chartBodygraphChart.channels2['20-34'],
                      chartBodygraphChart.channels2['20-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-57_20-34_20-57_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-57_20-34_20-57_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-20'] &&
                      chartBodygraphChart2.channels2['10-57'] &&
                      chartBodygraphChart2.channels2['20-34'] &&
                      chartBodygraphChart2.channels2['20-57'] &&
                      !chartBodygraphChart2.channels2['10-34'] &&
                      !chartBodygraphChart2.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-20'],
                      chartBodygraphChart2.channels2['10-57'],
                      chartBodygraphChart2.channels2['20-34'],
                      chartBodygraphChart2.channels2['20-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-57_20-34_20-57_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-57_20-34_34-57_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['10-20'] &&
                      chartBodygraphChart.channels2['10-57'] &&
                      chartBodygraphChart.channels2['20-34'] &&
                      chartBodygraphChart.channels2['34-57'] &&
                      !chartBodygraphChart.channels2['10-34'] &&
                      !chartBodygraphChart.channels2['20-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-20'],
                      chartBodygraphChart.channels2['10-57'],
                      chartBodygraphChart.channels2['20-34'],
                      chartBodygraphChart.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-57_20-34_34-57_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-57_20-34_34-57_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-20'] &&
                      chartBodygraphChart2.channels2['10-57'] &&
                      chartBodygraphChart2.channels2['20-34'] &&
                      chartBodygraphChart2.channels2['34-57'] &&
                      !chartBodygraphChart2.channels2['10-34'] &&
                      !chartBodygraphChart2.channels2['20-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-20'],
                      chartBodygraphChart2.channels2['10-57'],
                      chartBodygraphChart2.channels2['20-34'],
                      chartBodygraphChart2.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-57_20-34_34-57_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-57_20-57_34-57_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['10-20'] &&
                      chartBodygraphChart.channels2['10-57'] &&
                      chartBodygraphChart.channels2['20-57'] &&
                      chartBodygraphChart.channels2['34-57'] &&
                      !chartBodygraphChart.channels2['10-34'] &&
                      !chartBodygraphChart.channels2['20-34']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-20'],
                      chartBodygraphChart.channels2['10-57'],
                      chartBodygraphChart.channels2['20-57'],
                      chartBodygraphChart.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-57_20-57_34-57_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-57_20-57_34-57_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-20'] &&
                      chartBodygraphChart2.channels2['10-57'] &&
                      chartBodygraphChart2.channels2['20-57'] &&
                      chartBodygraphChart2.channels2['34-57'] &&
                      !chartBodygraphChart2.channels2['10-34'] &&
                      !chartBodygraphChart2.channels2['20-34']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-20'],
                      chartBodygraphChart2.channels2['10-57'],
                      chartBodygraphChart2.channels2['20-57'],
                      chartBodygraphChart2.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-57_20-57_34-57_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_10-57_20-34_20-57_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['10-34'] &&
                      chartBodygraphChart.channels2['10-57'] &&
                      chartBodygraphChart.channels2['20-34'] &&
                      chartBodygraphChart.channels2['20-57'] &&
                      !chartBodygraphChart.channels2['10-20'] &&
                      !chartBodygraphChart.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-34'],
                      chartBodygraphChart.channels2['10-57'],
                      chartBodygraphChart.channels2['20-34'],
                      chartBodygraphChart.channels2['20-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-34_10-57_20-34_20-57_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_10-57_20-34_20-57_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-34'] &&
                      chartBodygraphChart2.channels2['10-57'] &&
                      chartBodygraphChart2.channels2['20-34'] &&
                      chartBodygraphChart2.channels2['20-57'] &&
                      !chartBodygraphChart2.channels2['10-20'] &&
                      !chartBodygraphChart2.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-34'],
                      chartBodygraphChart2.channels2['10-57'],
                      chartBodygraphChart2.channels2['20-34'],
                      chartBodygraphChart2.channels2['20-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-34_10-57_20-34_20-57_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_10-57_20-34_34-57_channel_chart1"
                  v-if="
                    chartBodygraphChart2.channels2['10-34'] &&
                      chartBodygraphChart2.channels2['10-57'] &&
                      chartBodygraphChart2.channels2['20-34'] &&
                      chartBodygraphChart2.channels2['34-57'] &&
                      !chartBodygraphChart.channels2['10-20'] &&
                      !chartBodygraphChart.channels2['20-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-34'],
                      chartBodygraphChart.channels2['10-57'],
                      chartBodygraphChart.channels2['20-34'],
                      chartBodygraphChart.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-34_10-57_20-34_34-57_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_10-57_20-34_34-57_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-34'] &&
                      chartBodygraphChart2.channels2['10-57'] &&
                      chartBodygraphChart2.channels2['20-34'] &&
                      chartBodygraphChart2.channels2['34-57'] &&
                      !chartBodygraphChart2.channels2['10-20'] &&
                      !chartBodygraphChart2.channels2['20-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-34'],
                      chartBodygraphChart2.channels2['10-57'],
                      chartBodygraphChart2.channels2['20-34'],
                      chartBodygraphChart2.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-34_10-57_20-34_34-57_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_10-57_20-57_34-57_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['10-34'] &&
                      chartBodygraphChart.channels2['10-57'] &&
                      chartBodygraphChart.channels2['20-57'] &&
                      chartBodygraphChart.channels2['34-57'] &&
                      !chartBodygraphChart.channels2['10-20'] &&
                      !chartBodygraphChart.channels2['20-34']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-34'],
                      chartBodygraphChart.channels2['10-57'],
                      chartBodygraphChart.channels2['20-57'],
                      chartBodygraphChart.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-34_10-57_20-57_34-57_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_10-57_20-57_34-57_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-34'] &&
                      chartBodygraphChart2.channels2['10-57'] &&
                      chartBodygraphChart2.channels2['20-57'] &&
                      chartBodygraphChart2.channels2['34-57'] &&
                      !chartBodygraphChart2.channels2['10-20'] &&
                      !chartBodygraphChart2.channels2['20-34']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-34'],
                      chartBodygraphChart2.channels2['10-57'],
                      chartBodygraphChart2.channels2['20-57'],
                      chartBodygraphChart2.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-34_10-57_20-57_34-57_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_20-34_20-57_34-57_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['10-20'] &&
                      chartBodygraphChart.channels2['20-34'] &&
                      chartBodygraphChart.channels2['20-57'] &&
                      chartBodygraphChart.channels2['34-57'] &&
                      !chartBodygraphChart.channels2['10-34'] &&
                      !chartBodygraphChart.channels2['10-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-20'],
                      chartBodygraphChart.channels2['20-34'],
                      chartBodygraphChart.channels2['20-57'],
                      chartBodygraphChart.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_20-34_20-57_34-57_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_20-34_20-57_34-57_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-20'] &&
                      chartBodygraphChart2.channels2['20-34'] &&
                      chartBodygraphChart2.channels2['20-57'] &&
                      chartBodygraphChart2.channels2['34-57'] &&
                      !chartBodygraphChart2.channels2['10-34'] &&
                      !chartBodygraphChart2.channels2['10-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-20'],
                      chartBodygraphChart2.channels2['20-34'],
                      chartBodygraphChart2.channels2['20-57'],
                      chartBodygraphChart2.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_20-34_20-57_34-57_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_20-34_20-57_34-57_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['10-34'] &&
                      chartBodygraphChart.channels2['20-34'] &&
                      chartBodygraphChart.channels2['20-57'] &&
                      chartBodygraphChart.channels2['34-57'] &&
                      !chartBodygraphChart.channels2['10-20'] &&
                      !chartBodygraphChart.channels2['10-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-34'],
                      chartBodygraphChart.channels2['20-34'],
                      chartBodygraphChart.channels2['20-57'],
                      chartBodygraphChart.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-34_20-34_20-57_34-57_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_20-34_20-57_34-57_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-34'] &&
                      chartBodygraphChart2.channels2['20-34'] &&
                      chartBodygraphChart2.channels2['20-57'] &&
                      chartBodygraphChart2.channels2['34-57'] &&
                      !chartBodygraphChart2.channels2['10-20'] &&
                      !chartBodygraphChart2.channels2['10-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-34'],
                      chartBodygraphChart2.channels2['20-34'],
                      chartBodygraphChart2.channels2['20-57'],
                      chartBodygraphChart2.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-34_20-34_20-57_34-57_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-57_20-34_20-57_34-57_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['10-57'] &&
                      chartBodygraphChart.channels2['20-34'] &&
                      chartBodygraphChart.channels2['20-57'] &&
                      chartBodygraphChart.channels2['34-57'] &&
                      !chartBodygraphChart.channels2['10-20'] &&
                      !chartBodygraphChart.channels2['10-34']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-57'],
                      chartBodygraphChart.channels2['20-34'],
                      chartBodygraphChart.channels2['20-57'],
                      chartBodygraphChart.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-57_20-34_20-57_34-57_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-57_20-34_20-57_34-57_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-57'] &&
                      chartBodygraphChart2.channels2['20-34'] &&
                      chartBodygraphChart2.channels2['20-57'] &&
                      chartBodygraphChart2.channels2['34-57'] &&
                      !chartBodygraphChart2.channels2['10-20'] &&
                      !chartBodygraphChart2.channels2['10-34']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-57'],
                      chartBodygraphChart2.channels2['20-34'],
                      chartBodygraphChart2.channels2['20-57'],
                      chartBodygraphChart2.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-57_20-34_20-57_34-57_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_10-57_20-34_20-57_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['10-20'] &&
                      chartBodygraphChart.channels2['10-34'] &&
                      chartBodygraphChart.channels2['10-57'] &&
                      chartBodygraphChart.channels2['20-34'] &&
                      chartBodygraphChart.channels2['20-57'] &&
                      !chartBodygraphChart.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-20'],
                      chartBodygraphChart.channels2['10-34'],
                      chartBodygraphChart.channels2['10-57'],
                      chartBodygraphChart.channels2['20-34'],
                      chartBodygraphChart.channels2['20-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_10-57_20-34_20-57_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_10-57_20-34_20-57_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-20'] &&
                      chartBodygraphChart2.channels2['10-34'] &&
                      chartBodygraphChart2.channels2['10-57'] &&
                      chartBodygraphChart2.channels2['20-34'] &&
                      chartBodygraphChart2.channels2['20-57'] &&
                      !chartBodygraphChart2.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-20'],
                      chartBodygraphChart2.channels2['10-34'],
                      chartBodygraphChart2.channels2['10-57'],
                      chartBodygraphChart2.channels2['20-34'],
                      chartBodygraphChart2.channels2['20-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_10-57_20-34_20-57_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_10-57_20-34_34-57_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['10-20'] &&
                      chartBodygraphChart.channels2['10-34'] &&
                      chartBodygraphChart.channels2['10-57'] &&
                      chartBodygraphChart.channels2['20-34'] &&
                      chartBodygraphChart.channels2['34-57'] &&
                      !chartBodygraphChart.channels2['20-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-20'],
                      chartBodygraphChart.channels2['10-34'],
                      chartBodygraphChart.channels2['10-57'],
                      chartBodygraphChart.channels2['20-34'],
                      chartBodygraphChart.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_10-57_20-34_34-57_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_10-57_20-34_34-57_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-20'] &&
                      chartBodygraphChart2.channels2['10-34'] &&
                      chartBodygraphChart2.channels2['10-57'] &&
                      chartBodygraphChart2.channels2['20-34'] &&
                      chartBodygraphChart2.channels2['34-57'] &&
                      !chartBodygraphChart2.channels2['20-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-20'],
                      chartBodygraphChart2.channels2['10-34'],
                      chartBodygraphChart2.channels2['10-57'],
                      chartBodygraphChart2.channels2['20-34'],
                      chartBodygraphChart2.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_10-57_20-34_34-57_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_10-57_20-57_34-57_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['10-20'] &&
                      chartBodygraphChart.channels2['10-34'] &&
                      chartBodygraphChart.channels2['10-57'] &&
                      chartBodygraphChart.channels2['20-57'] &&
                      chartBodygraphChart.channels2['34-57'] &&
                      !chartBodygraphChart.channels2['20-34']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-20'],
                      chartBodygraphChart.channels2['10-34'],
                      chartBodygraphChart.channels2['10-57'],
                      chartBodygraphChart.channels2['20-57'],
                      chartBodygraphChart.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_10-57_20-57_34-57_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_10-57_20-57_34-57_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-20'] &&
                      chartBodygraphChart2.channels2['10-34'] &&
                      chartBodygraphChart2.channels2['10-57'] &&
                      chartBodygraphChart2.channels2['20-57'] &&
                      chartBodygraphChart2.channels2['34-57'] &&
                      !chartBodygraphChart2.channels2['20-34']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-20'],
                      chartBodygraphChart2.channels2['10-34'],
                      chartBodygraphChart2.channels2['10-57'],
                      chartBodygraphChart2.channels2['20-57'],
                      chartBodygraphChart2.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_10-57_20-57_34-57_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_20-34_20-57_34-57_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['10-20'] &&
                      chartBodygraphChart.channels2['10-34'] &&
                      chartBodygraphChart.channels2['20-34'] &&
                      chartBodygraphChart.channels2['20-57'] &&
                      chartBodygraphChart.channels2['34-57'] &&
                      !chartBodygraphChart.channels2['10-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-20'],
                      chartBodygraphChart.channels2['10-34'],
                      chartBodygraphChart.channels2['20-34'],
                      chartBodygraphChart.channels2['20-57'],
                      chartBodygraphChart.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_20-34_20-57_34-57_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_20-34_20-57_34-57_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-20'] &&
                      chartBodygraphChart2.channels2['10-34'] &&
                      chartBodygraphChart2.channels2['20-34'] &&
                      chartBodygraphChart2.channels2['20-57'] &&
                      chartBodygraphChart2.channels2['34-57'] &&
                      !chartBodygraphChart2.channels2['10-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-20'],
                      chartBodygraphChart2.channels2['10-34'],
                      chartBodygraphChart2.channels2['20-34'],
                      chartBodygraphChart2.channels2['20-57'],
                      chartBodygraphChart2.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_20-34_20-57_34-57_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-57_20-34_20-57_34-57_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['10-20'] &&
                      chartBodygraphChart.channels2['10-57'] &&
                      chartBodygraphChart.channels2['20-34'] &&
                      chartBodygraphChart.channels2['20-57'] &&
                      chartBodygraphChart.channels2['34-57'] &&
                      !chartBodygraphChart.channels2['10-34']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-20'],
                      chartBodygraphChart.channels2['10-57'],
                      chartBodygraphChart.channels2['20-34'],
                      chartBodygraphChart.channels2['20-57'],
                      chartBodygraphChart.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-57_20-34_20-57_34-57_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-57_20-34_20-57_34-57_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-20'] &&
                      chartBodygraphChart2.channels2['10-57'] &&
                      chartBodygraphChart2.channels2['20-34'] &&
                      chartBodygraphChart2.channels2['20-57'] &&
                      chartBodygraphChart2.channels2['34-57'] &&
                      !chartBodygraphChart2.channels2['10-34']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-20'],
                      chartBodygraphChart2.channels2['10-57'],
                      chartBodygraphChart2.channels2['20-34'],
                      chartBodygraphChart2.channels2['20-57'],
                      chartBodygraphChart2.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-57_20-34_20-57_34-57_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_10-57_20-34_20-57_34-57_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['10-34'] &&
                      chartBodygraphChart.channels2['10-57'] &&
                      chartBodygraphChart.channels2['20-34'] &&
                      chartBodygraphChart.channels2['20-57'] &&
                      chartBodygraphChart.channels2['34-57'] &&
                      !chartBodygraphChart.channels2['10-20']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-34'],
                      chartBodygraphChart.channels2['10-57'],
                      chartBodygraphChart.channels2['20-34'],
                      chartBodygraphChart.channels2['20-57'],
                      chartBodygraphChart.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-34_10-57_20-34_20-57_34-57_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-34_10-57_20-34_20-57_34-57_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-34'] &&
                      chartBodygraphChart2.channels2['10-57'] &&
                      chartBodygraphChart2.channels2['20-34'] &&
                      chartBodygraphChart2.channels2['20-57'] &&
                      chartBodygraphChart2.channels2['34-57'] &&
                      !chartBodygraphChart2.channels2['10-20']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-34'],
                      chartBodygraphChart2.channels2['10-57'],
                      chartBodygraphChart2.channels2['20-34'],
                      chartBodygraphChart2.channels2['20-57'],
                      chartBodygraphChart2.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-34_10-57_20-34_20-57_34-57_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_10-57_20-34_20-57_34-57_channel_chart1"
                  v-if="
                    chartBodygraphChart.channels2['10-20'] &&
                      chartBodygraphChart.channels2['10-34'] &&
                      chartBodygraphChart.channels2['10-57'] &&
                      chartBodygraphChart.channels2['20-34'] &&
                      chartBodygraphChart.channels2['20-57'] &&
                      chartBodygraphChart.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart.channels2['10-20'],
                      chartBodygraphChart.channels2['10-34'],
                      chartBodygraphChart.channels2['10-57'],
                      chartBodygraphChart.channels2['20-34'],
                      chartBodygraphChart.channels2['20-57'],
                      chartBodygraphChart.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_10-57_20-34_20-57_34-57_channel_chart1"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_10-20_10-34_10-57_20-34_20-57_34-57_channel_chart2"
                  v-if="
                    chartBodygraphChart2.channels2['10-20'] &&
                      chartBodygraphChart2.channels2['10-34'] &&
                      chartBodygraphChart2.channels2['10-57'] &&
                      chartBodygraphChart2.channels2['20-34'] &&
                      chartBodygraphChart2.channels2['20-57'] &&
                      chartBodygraphChart2.channels2['34-57']
                  "
                  @click="
                    openMultiTooltipDialog([
                      chartBodygraphChart2.channels2['10-20'],
                      chartBodygraphChart2.channels2['10-34'],
                      chartBodygraphChart2.channels2['10-57'],
                      chartBodygraphChart2.channels2['20-34'],
                      chartBodygraphChart2.channels2['20-57'],
                      chartBodygraphChart2.channels2['34-57']
                    ])
                  "
                  @mouseover="searchAcPlanets(10, 20, 34, 57)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_10-20_10-34_10-57_20-34_20-57_34-57_channel_chart2"
                >
                  <path
                    d="M 338.6,457.5 C 366.5,443 403.8,435 443.5,435 v -10.6 c -41.2,0 -80,8.4 -109.3,23.6 -29.3,15.2 -43.77898,33.66604 -46.85398,53.64104 h 10.32812 C 300.38039,485.74104 313,470.8 338.6,457.5 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 334.4,569.4 c 29.3,15.2 67.8,23.8 109,23.8 l 0.1,-11.5 c -39.7,0 -77,-8 -104.9,-22.5 -26.8,-13.9 -41.6,-32 -41.6,-50.9 -0.0166,-0.90116 0.22218,-3.55179 0.92414,-6.65896 H 287.59602 C 287.19827,503.77848 287,507.4 287,508.3 c 0,23.2 17,45.3 47.4,61.1 z"
                    inkscape:connector-curvature="0"
                    transform="translate(-46.94,-28.51)"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 239.65533,473.03024 c -6.22339,27.38635 -9.99169,55.65595 -10.69532,83.75976 l 10,1 c 0.68476,-28.46 4.57922,-57.09341 11.02344,-84.75976 z"
                    style="fill:transparent"
                  />
                  <path
                    inkscape:connector-curvature="0"
                    d="m 407.96,271.79 c -24.2,0.2 -47.80078,8.99922 -70.30077,26.19922 -15.6,11.9 -30.20039,27.50039 -43.40039,46.40039 -4.7,6.6 -9.2,13.69922 -13.5,21.19922 -18.40354,31.70801 -32.29094,68.66116 -41.10351,107.44141 h 10.32812 c 8.61343,-36.97921 21.91524,-72.18102 39.47656,-102.44141 4.5,-7.8 9.2,-15.10039 14,-21.90039 30.5,-43 66.99999,-66.49844 104.49999,-66.89844 z"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_01_08_channel_chart1"
                  v-if="chartBodygraphChart.channels2['1-8']"
                  @click="
                    openTooltipDialog(chartBodygraphChart.channels2['1-8'])
                  "
                  @mouseover="searchAcPlanets(1, 8)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_01_08_channel_chart1"
                >
                  <rect
                    id="rect1015"
                    x="403.85999"
                    y="270.89001"
                    width="10"
                    height="69"
                    style="fill:transparent"
                  />
                  <rect
                    id="rect1010"
                    x="403.85999"
                    y="339.89001"
                    width="10"
                    height="61.5"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_01_08_channel_chart2"
                  v-if="chartBodygraphChart2.channels2['1-8']"
                  @click="
                    openTooltipDialog(chartBodygraphChart2.channels2['1-8'])
                  "
                  @mouseover="searchAcPlanets(1, 8)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_01_08_channel_chart2"
                >
                  <rect
                    id="rect1015"
                    x="403.85999"
                    y="270.89001"
                    width="10"
                    height="69"
                    style="fill:transparent"
                  />
                  <rect
                    id="rect1010"
                    x="403.85999"
                    y="339.89001"
                    width="10"
                    height="61.5"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_13_33_channel_chart1"
                  v-if="chartBodygraphChart.channels2['13-33']"
                  @click="
                    openTooltipDialog(chartBodygraphChart.channels2['13-33'])
                  "
                  @mouseover="searchAcPlanets(13, 33)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_13_33_channel_chart1"
                >
                  <rect
                    id="gate_13_33"
                    x="424.35999"
                    y="339.89001"
                    width="10"
                    height="61.5"
                    style="fill:transparent"
                  />
                  <rect
                    id="gate_33_13"
                    x="424.35999"
                    y="270.89001"
                    width="10"
                    height="69"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_13_33_channel_chart2"
                  v-if="chartBodygraphChart2.channels2['13-33']"
                  @click="
                    openTooltipDialog(chartBodygraphChart2.channels2['13-33'])
                  "
                  @mouseover="searchAcPlanets(13, 33)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_13_33_channel_chart2"
                >
                  <rect
                    id="gate_13_33"
                    x="424.35999"
                    y="339.89001"
                    width="10"
                    height="61.5"
                    style="fill:transparent"
                  />
                  <rect
                    id="gate_33_13"
                    x="424.35999"
                    y="270.89001"
                    width="10"
                    height="69"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_25_51_channel_chart1"
                  v-if="chartBodygraphChart.channels2['25-51']"
                  @click="
                    openTooltipDialog(chartBodygraphChart.channels2['25-51'])
                  "
                  @mouseover="searchAcPlanets(25, 51)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_25_51_channel_chart1"
                >
                  <path
                    d="m 466,422.4 v 10 c 21.8,0 41.6,7.5 56.4,19.8 l 6.9,-7.3 A 98.63,98.63 0 0 0 466,422.4 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 529.3,444.9 -6.9,7.3 c 16.3,13.5 26.6,32.8 26.6,54.2 h 10 c 0,-24.3 -11.5,-46.1 -29.7,-61.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_25_51_channel_chart2"
                  v-if="chartBodygraphChart2.channels2['25-51']"
                  @click="
                    openTooltipDialog(chartBodygraphChart2.channels2['25-51'])
                  "
                  @mouseover="searchAcPlanets(25, 51)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_25_51_channel_chart2"
                >
                  <path
                    d="m 466,422.4 v 10 c 21.8,0 41.6,7.5 56.4,19.8 l 6.9,-7.3 A 98.63,98.63 0 0 0 466,422.4 Z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 529.3,444.9 -6.9,7.3 c 16.3,13.5 26.6,32.8 26.6,54.2 h 10 c 0,-24.3 -11.5,-46.1 -29.7,-61.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_29_46_channel_chart1"
                  v-if="chartBodygraphChart.channels2['29-46']"
                  @click="
                    openTooltipDialog(chartBodygraphChart.channels2['29-46'])
                  "
                  @mouseover="searchAcPlanets(29, 46)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_29_46_channel_chart1"
                >
                  <rect
                    id="gate_46_29"
                    x="424.35999"
                    y="401.39001"
                    width="10"
                    height="82.5"
                    style="fill:transparent"
                  />
                  <rect
                    id="gate_29_46"
                    x="424.35999"
                    y="483.89001"
                    width="10"
                    height="87"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_29_46_channel_chart2"
                  v-if="chartBodygraphChart2.channels2['29-46']"
                  @click="
                    openTooltipDialog(chartBodygraphChart2.channels2['29-46'])
                  "
                  @mouseover="searchAcPlanets(29, 46)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_29_46_channel_chart2"
                >
                  <rect
                    id="gate_46_29"
                    x="424.35999"
                    y="401.39001"
                    width="10"
                    height="82.5"
                    style="fill:transparent"
                  />
                  <rect
                    id="gate_29_46"
                    x="424.35999"
                    y="483.89001"
                    width="10"
                    height="87"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_02_14_channel_chart1"
                  v-if="chartBodygraphChart.channels2['2-14']"
                  @click="
                    openTooltipDialog(chartBodygraphChart.channels2['2-14'])
                  "
                  @mouseover="searchAcPlanets(2, 14)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_02_14_channel_chart1"
                >
                  <rect
                    id="gate_02_14"
                    x="403.85999"
                    y="401.39001"
                    width="10"
                    height="82.5"
                    style="fill:transparent"
                  />
                  <rect
                    id="gate_14_02"
                    x="403.85999"
                    y="483.89001"
                    width="10"
                    height="87"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_02_14_channel_chart2"
                  v-if="chartBodygraphChart2.channels2['2-14']"
                  @click="
                    openTooltipDialog(chartBodygraphChart2.channels2['2-14'])
                  "
                  @mouseover="searchAcPlanets(2, 14)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_02_14_channel_chart2"
                >
                  <rect
                    id="gate_02_14"
                    x="403.85999"
                    y="401.39001"
                    width="10"
                    height="82.5"
                    style="fill:transparent"
                  />
                  <rect
                    id="gate_14_02"
                    x="403.85999"
                    y="483.89001"
                    width="10"
                    height="87"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_05_15_channel_chart1"
                  v-if="chartBodygraphChart.channels2['5-15']"
                  @click="
                    openTooltipDialog(chartBodygraphChart.channels2['5-15'])
                  "
                  @mouseover="searchAcPlanets(5, 15)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_05_15_channel_chart1"
                >
                  <rect
                    x="383.35999"
                    y="483.89001"
                    width="10"
                    height="87"
                    style="fill:transparent"
                  />
                  <rect
                    x="383.35999"
                    y="401.39001"
                    width="10"
                    height="82.5"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_05_15_channel_chart2"
                  v-if="chartBodygraphChart2.channels2['5-15']"
                  @click="
                    openTooltipDialog(chartBodygraphChart2.channels2['5-15'])
                  "
                  @mouseover="searchAcPlanets(5, 15)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_05_15_channel_chart2"
                >
                  <rect
                    x="383.35999"
                    y="483.89001"
                    width="10"
                    height="87"
                    style="fill:transparent"
                  />
                  <rect
                    x="383.35999"
                    y="401.39001"
                    width="10"
                    height="82.5"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_06_59_channel_chart1"
                  v-if="chartBodygraphChart.channels2['6-59']"
                  @click="
                    openTooltipDialog(chartBodygraphChart.channels2['6-59'])
                  "
                  @mouseover="searchAcPlanets(6, 59)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_06_59_channel_chart1"
                >
                  <path
                    d="m 582.1,587.7 a 129.57,129.57 0 0 1 -26.1,13.2 204.47,204.47 0 0 1 -27,8.3 l 5.4,9.2 a 228.06,228.06 0 0 0 25.3,-8 c 16,-6.2 29.1,-13.9 38.4,-22.7 0.9,-0.8 1.7,-1.7 2.6,-2.5 l -8,-6.1 a 72.06,72.06 0 0 1 -10.6,8.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 529.1,609.3 a 291.26,291.26 0 0 1 -43.2,6.9 c -9.2,0.8 -18.7,1.2 -28.3,1.2 h -4.8 l -0.5,10 h 5.4 c 11.2,0 22.1,-0.5 32.5,-1.5 a 287.12,287.12 0 0 0 44.4,-7.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_06_59_channel_chart2"
                  v-if="chartBodygraphChart2.channels2['6-59']"
                  @click="
                    openTooltipDialog(chartBodygraphChart2.channels2['6-59'])
                  "
                  @mouseover="searchAcPlanets(6, 59)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_06_59_channel_chart2"
                >
                  <path
                    d="m 582.1,587.7 a 129.57,129.57 0 0 1 -26.1,13.2 204.47,204.47 0 0 1 -27,8.3 l 5.4,9.2 a 228.06,228.06 0 0 0 25.3,-8 c 16,-6.2 29.1,-13.9 38.4,-22.7 0.9,-0.8 1.7,-1.7 2.6,-2.5 l -8,-6.1 a 72.06,72.06 0 0 1 -10.6,8.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 529.1,609.3 a 291.26,291.26 0 0 1 -43.2,6.9 c -9.2,0.8 -18.7,1.2 -28.3,1.2 h -4.8 l -0.5,10 h 5.4 c 11.2,0 22.1,-0.5 32.5,-1.5 a 287.12,287.12 0 0 0 44.4,-7.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_19_49_channel_chart1"
                  v-if="chartBodygraphChart.channels2['19-49']"
                  @click="
                    openTooltipDialog(chartBodygraphChart.channels2['19-49'])
                  "
                  @mouseover="searchAcPlanets(19, 49)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_19_49_channel_chart1"
                >
                  <path
                    d="m 557.3,659.4 c -27.9,17.6 -64.4,27.9 -101.4,27.9 v 10 c 39.5,0 77.5,-10.7 107,-29.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 615.4,587.7 -10,0.2 c 0.3,18 -8.1,36.2 -24.2,52.6 a 136.22,136.22 0 0 1 -23.4,18.7 l 5.4,8.6 a 147.83,147.83 0 0 0 25.2,-20.2 c 17.9,-18.5 27.3,-39.2 27,-59.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_19_49_channel_chart2"
                  v-if="chartBodygraphChart2.channels2['19-49']"
                  @click="
                    openTooltipDialog(chartBodygraphChart2.channels2['19-49'])
                  "
                  @mouseover="searchAcPlanets(19, 49)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_19_49_channel_chart2"
                >
                  <path
                    d="m 557.3,659.4 c -27.9,17.6 -64.4,27.9 -101.4,27.9 v 10 c 39.5,0 77.5,-10.7 107,-29.5 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 615.4,587.7 -10,0.2 c 0.3,18 -8.1,36.2 -24.2,52.6 a 136.22,136.22 0 0 1 -23.4,18.7 l 5.4,8.6 a 147.83,147.83 0 0 0 25.2,-20.2 c 17.9,-18.5 27.3,-39.2 27,-59.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_39_55_channel_chart1"
                  v-if="chartBodygraphChart.channels2['39-55']"
                  @click="
                    openTooltipDialog(chartBodygraphChart.channels2['39-55'])
                  "
                  @mouseover="searchAcPlanets(39, 55)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_39_55_channel_chart1"
                >
                  <path
                    d="m 575.1,686.9 -5.3,-8.5 c -31.6,19.5 -72.5,30.9 -114,30.9 v 10 a 239.69,239.69 0 0 0 85.8,-15.8 190,190 0 0 0 33.5,-16.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 635.9,595.8 c -0.1,-3.1 -0.1,-6.2 -0.2,-9.3 l -9.9,3 c 0.1,2.1 0.1,4.3 0.1,6.4 0.3,20.6 -9.2,41.4 -27.6,60.1 a 152.5,152.5 0 0 1 -28.5,22.4 l 5.3,8.5 A 165.59,165.59 0 0 0 605.4,663 c 20.3,-20.6 30.9,-43.9 30.5,-67.2 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_39_55_channel_chart2"
                  v-if="chartBodygraphChart2.channels2['39-55']"
                  @click="
                    openTooltipDialog(chartBodygraphChart2.channels2['39-55'])
                  "
                  @mouseover="searchAcPlanets(39, 55)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_39_55_channel_chart2"
                >
                  <path
                    d="m 575.1,686.9 -5.3,-8.5 c -31.6,19.5 -72.5,30.9 -114,30.9 v 10 a 239.69,239.69 0 0 0 85.8,-15.8 190,190 0 0 0 33.5,-16.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 635.9,595.8 c -0.1,-3.1 -0.1,-6.2 -0.2,-9.3 l -9.9,3 c 0.1,2.1 0.1,4.3 0.1,6.4 0.3,20.6 -9.2,41.4 -27.6,60.1 a 152.5,152.5 0 0 1 -28.5,22.4 l 5.3,8.5 A 165.59,165.59 0 0 0 605.4,663 c 20.3,-20.6 30.9,-43.9 30.5,-67.2 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_30_41_channel_chart1"
                  v-if="chartBodygraphChart.channels2['30-41']"
                  @click="
                    openTooltipDialog(chartBodygraphChart.channels2['30-41'])
                  "
                  @mouseover="searchAcPlanets(30, 41)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_30_41_channel_chart1"
                >
                  <path
                    d="m 653.6,582.8 -10,1.1 c 0.3,6.8 0.6,13.7 0.7,20.6 0.4,22.9 -10.2,46 -30.7,66.8 a 172.56,172.56 0 0 1 -32.4,25.4 l 5.7,8.3 a 178.16,178.16 0 0 0 33.8,-26.6 c 22.4,-22.8 34,-48.3 33.6,-74 -0.1,-7.3 -0.4,-14.5 -0.7,-21.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 586.9,704.9 -5.7,-8.3 c -33.9,20.7 -77.5,33.1 -121.8,33.7 l 0.5,10 a 266.48,266.48 0 0 0 90.5,-17.3 225.62,225.62 0 0 0 36.5,-18.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_30_41_channel_chart2"
                  v-if="chartBodygraphChart2.channels2['30-41']"
                  @click="
                    openTooltipDialog(chartBodygraphChart2.channels2['30-41'])
                  "
                  @mouseover="searchAcPlanets(30, 41)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_30_41_channel_chart2"
                >
                  <path
                    d="m 653.6,582.8 -10,1.1 c 0.3,6.8 0.6,13.7 0.7,20.6 0.4,22.9 -10.2,46 -30.7,66.8 a 172.56,172.56 0 0 1 -32.4,25.4 l 5.7,8.3 a 178.16,178.16 0 0 0 33.8,-26.6 c 22.4,-22.8 34,-48.3 33.6,-74 -0.1,-7.3 -0.4,-14.5 -0.7,-21.6 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 586.9,704.9 -5.7,-8.3 c -33.9,20.7 -77.5,33.1 -121.8,33.7 l 0.5,10 a 266.48,266.48 0 0 0 90.5,-17.3 225.62,225.62 0 0 0 36.5,-18.1 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_09_52_channel_chart1"
                  v-if="chartBodygraphChart.channels2['9-52']"
                  @click="
                    openTooltipDialog(chartBodygraphChart.channels2['9-52'])
                  "
                  @mouseover="searchAcPlanets(9, 52)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_09_52_channel_chart1"
                >
                  <rect
                    id="gate_09_52"
                    x="424.35999"
                    y="570.89001"
                    width="10"
                    height="57"
                    style="fill:transparent"
                  />
                  <rect
                    id="gate_52_09"
                    x="424.35999"
                    y="627.89001"
                    width="10"
                    height="79"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_09_52_channel_chart2"
                  v-if="chartBodygraphChart2.channels2['9-52']"
                  @click="
                    openTooltipDialog(chartBodygraphChart2.channels2['9-52'])
                  "
                  @mouseover="searchAcPlanets(9, 52)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_09_52_channel_chart2"
                >
                  <rect
                    id="gate_09_52"
                    x="424.35999"
                    y="570.89001"
                    width="10"
                    height="57"
                    style="fill:transparent"
                  />
                  <rect
                    id="gate_52_09"
                    x="424.35999"
                    y="627.89001"
                    width="10"
                    height="79"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_03_60_channel_chart1"
                  v-if="chartBodygraphChart.channels2['3-60']"
                  @click="
                    openTooltipDialog(chartBodygraphChart.channels2['3-60'])
                  "
                  @mouseover="searchAcPlanets(3, 60)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_3_60_channel_chart1"
                >
                  <rect
                    x="403.85999"
                    y="570.89001"
                    width="10"
                    height="57"
                    style="fill:transparent"
                  />
                  <rect
                    x="403.85999"
                    y="627.89001"
                    width="10"
                    height="79"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_03_60_channel_chart2"
                  v-if="chartBodygraphChart2.channels2['3-60']"
                  @click="
                    openTooltipDialog(chartBodygraphChart2.channels2['3-60'])
                  "
                  @mouseover="searchAcPlanets(3, 60)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_3_60_channel_chart2"
                >
                  <rect
                    x="403.85999"
                    y="570.89001"
                    width="10"
                    height="57"
                    style="fill:transparent"
                  />
                  <rect
                    x="403.85999"
                    y="627.89001"
                    width="10"
                    height="79"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_42_53_channel_chart1"
                  v-if="chartBodygraphChart.channels2['42-53']"
                  @click="
                    openTooltipDialog(chartBodygraphChart.channels2['42-53'])
                  "
                  @mouseover="searchAcPlanets(42, 53)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_42_53_channel_chart1"
                >
                  <rect
                    x="383.35999"
                    y="570.89001"
                    width="10"
                    height="57"
                    style="fill:transparent"
                  />
                  <rect
                    x="383.35999"
                    y="627.89001"
                    width="10"
                    height="79"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_42_53_channel_chart2"
                  v-if="chartBodygraphChart2.channels2['42-53']"
                  @click="
                    openTooltipDialog(chartBodygraphChart2.channels2['42-53'])
                  "
                  @mouseover="searchAcPlanets(42, 53)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_42_53_channel_chart2"
                >
                  <rect
                    x="383.35999"
                    y="570.89001"
                    width="10"
                    height="57"
                    style="fill:transparent"
                  />
                  <rect
                    x="383.35999"
                    y="627.89001"
                    width="10"
                    height="79"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_27_50_channel_chart1"
                  v-if="chartBodygraphChart.channels2['27-50']"
                  @click="
                    openTooltipDialog(chartBodygraphChart.channels2['27-50'])
                  "
                  @mouseover="searchAcPlanets(27, 50)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_27_50_channel_chart1"
                >
                  <path
                    d="m 452.6,617.4 c -27.5,-0.4 -53.3,-4.1 -75.2,-10.3 l -5.3,9.1 c 23,6.7 50.3,10.8 80,11.2 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 314.1,585.1 c 10.3,10.8 26.1,20.1 45.9,27.2 q 5.85,2.1 12,3.9 l 5.3,-9.1 c -5,-1.4 -9.9,-3 -14.5,-4.6 -17,-6.1 -30.8,-13.8 -39.9,-22.5 a 42.73,42.73 0 0 1 -3.6,-3.8 l -8,6 a 37.64,37.64 0 0 1 2.8,2.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
                <g
                  id="gate_27_50_channel_chart2"
                  v-if="chartBodygraphChart2.channels2['27-50']"
                  @click="
                    openTooltipDialog(chartBodygraphChart2.channels2['27-50'])
                  "
                  @mouseover="searchAcPlanets(27, 50)"
                  @mouseleave="delayedСlearAcPlanets()"
                  v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                  style="display:inline"
                  data-name="gate_27_50_channel_chart2"
                >
                  <path
                    d="m 452.6,617.4 c -27.5,-0.4 -53.3,-4.1 -75.2,-10.3 l -5.3,9.1 c 23,6.7 50.3,10.8 80,11.2 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                  <path
                    d="m 314.1,585.1 c 10.3,10.8 26.1,20.1 45.9,27.2 q 5.85,2.1 12,3.9 l 5.3,-9.1 c -5,-1.4 -9.9,-3 -14.5,-4.6 -17,-6.1 -30.8,-13.8 -39.9,-22.5 a 42.73,42.73 0 0 1 -3.6,-3.8 l -8,6 a 37.64,37.64 0 0 1 2.8,2.9 z"
                    transform="translate(-46.94,-28.51)"
                    inkscape:connector-curvature="0"
                    style="fill:transparent"
                  />
                </g>
              </g>
            </g>
            <g
              id="layer3"
              inkscape:groupmode="layer"
              inkscape:label="Centers"
              style="display:inline"
              sodipodi:insensitive="true"
            >
              <g
                id="g1824"
                transform="translate(30.889992,17.409983)"
                data-name="Слой 11"
                style="display:inline;fill:#0c0b00;fill-opacity:0.8125;filter:url(#filter1874_composite)"
              >
                <path
                  id="path1806"
                  d="m 404.16,5.39 -49,84.8 a 5.64,5.64 0 0 0 4.9,8.5 h 98 a 5.64,5.64 0 0 0 4.9,-8.5 l -49,-84.8 a 5.64,5.64 0 0 0 -9.8,0 z"
                  inkscape:connector-curvature="0"
                  style="fill:#0c0b00;fill-opacity:0.8125"
                />
                <path
                  id="path1808"
                  d="m 404.16,206.89 -49,-84.8 a 5.64,5.64 0 0 1 4.9,-8.5 h 98 a 5.64,5.64 0 0 1 4.9,8.5 l -49,84.8 a 5.64,5.64 0 0 1 -9.8,0 z"
                  inkscape:connector-curvature="0"
                  style="fill:#0c0b00;fill-opacity:0.8125"
                />
                <path
                  id="path1810"
                  d="m 523.16,425.39 31.6,62.3 a 6.17,6.17 0 0 1 -7,8.8 l -88.2,-21.3 a 6.2,6.2 0 0 1 -2.2,-11 l 56.6,-41 a 6.27,6.27 0 0 1 9.2,2.2 z"
                  inkscape:connector-curvature="0"
                  style="fill:#0c0b00;fill-opacity:0.8125"
                />
                <path
                  id="path1812"
                  style="fill:#0c0b00;fill-opacity:0.8125"
                  inkscape:connector-curvature="0"
                  d="m 292.86,563.59 -84.8,48.9 a 5.64,5.64 0 0 1 -8.5,-4.9 v -98 a 5.64,5.64 0 0 1 8.5,-4.9 l 84.8,49 a 5.72,5.72 0 0 1 0,9.9 z"
                />
                <path
                  id="path1814"
                  style="fill:#0c0b00;fill-opacity:0.8125"
                  inkscape:connector-curvature="0"
                  d="m 525.36,553.69 84.8,-49 a 5.64,5.64 0 0 1 8.5,4.9 v 98 a 5.64,5.64 0 0 1 -8.5,4.9 l -84.8,-49 a 5.64,5.64 0 0 1 0,-9.8 z"
                />
                <path
                  id="path1816"
                  d="m 433.36,518.19033 h -49.3 a 8.4,8.4 0 0 0 -7.2,4.1 l -24.7,42.7 a 8.22,8.22 0 0 0 0,8.3 l 24.7,42.7 a 8.19,8.19 0 0 0 7.2,4.1 h 49.3 a 8.4,8.4 0 0 0 7.2,-4.1 l 24.7,-42.7 a 8.22,8.22 0 0 0 0,-8.3 l -24.7,-42.7 a 8.19,8.19 0 0 0 -7.2,-4.1 z"
                  inkscape:connector-curvature="0"
                  style="fill:#0c0b00;fill-opacity:0.8125"
                />
                <path
                  id="path1818"
                  d="m 462.86,407.49 -48.1,48.1 a 8,8 0 0 1 -11.3,0 l -48.1,-48.1 a 8,8 0 0 1 0,-11.3 l 48.1,-48.1 a 8,8 0 0 1 11.3,0 l 48.1,48.1 a 8,8 0 0 1 0,11.3 z"
                  inkscape:connector-curvature="0"
                  style="fill:#0c0b00;fill-opacity:0.8125"
                />
                <circle
                  id="circle1820"
                  cx="409.06"
                  cy="279.39001"
                  r="51.5"
                  style="fill:#0c0b00;fill-opacity:0.8125"
                />
                <path
                  id="path1822"
                  d="M 445.89333,722.34736 H 372.22666 A 8.6666664,8.2626054 0 0 1 363.56,714.08475 v -70.23214 a 8.6666664,8.2626054 0 0 1 8.66666,-8.26261 h 73.66667 a 8.6666664,8.2626054 0 0 1 8.66666,8.26261 v 70.23214 a 8.6666664,8.2626054 0 0 1 -8.66666,8.26261 z"
                  inkscape:connector-curvature="0"
                  style="fill:#0c0b00;fill-opacity:0.8125;stroke-width:0.99999994"
                />
              </g>
              <g
                id="Слой_11"
                style="display:inline"
                data-name="Слой 11"
                transform="translate(30.89,17.409983)"
              >
                <path
                  id="path357"
                  v-if="
                    chartBodygraphChart.centersUndefinedExt.center_undefined_9
                  "
                  @click="
                    openTooltipDialog(
                      chartBodygraphChart.centersUndefinedExt.center_undefined_9
                    )
                  "
                  @mouseover="isGatesActiveForCenter(9)"
                  @mouseleave="delayedСlearAcPlanets()"
                  style="fill:#ffffff;cursor:pointer"
                  inkscape:connector-curvature="0"
                  d="m 404.16,5.39 -49,84.8 a 5.64,5.64 0 0 0 4.9,8.5 h 98 a 5.64,5.64 0 0 0 4.9,-8.5 l -49,-84.8 a 5.64,5.64 0 0 0 -9.8,0 z"
                />
                <path
                  id="path357_undefined"
                  v-if="
                    chartBodygraphChart.centersUndefinedExt.center_undefined_9
                  "
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.centers,
                        'head'
                      )
                    )
                  "
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphConnection.centersUndefined,
                        9
                      ) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  @mouseover="isGatesActiveForCenter(9)"
                  @mouseleave="delayedСlearAcPlanets()"
                  style="fill:#ffffff;stroke-width:0.99999994;opacity:0;cursor:pointer;display:none"
                  inkscape:connector-curvature="0"
                  d="m 404.16,5.39 -49,84.8 a 5.64,5.64 0 0 0 4.9,8.5 h 98 a 5.64,5.64 0 0 0 4.9,-8.5 l -49,-84.8 a 5.64,5.64 0 0 0 -9.8,0 z"
                />
                <path
                  id="path359"
                  v-if="
                    chartBodygraphChart.centersUndefinedExt.center_undefined_8
                  "
                  @click="
                    openTooltipDialog(
                      chartBodygraphChart.centersUndefinedExt.center_undefined_8
                    )
                  "
                  @mouseover="isGatesActiveForCenter(8)"
                  @mouseleave="delayedСlearAcPlanets()"
                  style="fill:#ffffff;cursor:pointer"
                  inkscape:connector-curvature="0"
                  d="m 404.16,206.89 -49,-84.8 a 5.64,5.64 0 0 1 4.9,-8.5 h 98 a 5.64,5.64 0 0 1 4.9,8.5 l -49,84.8 a 5.64,5.64 0 0 1 -9.8,0 z"
                />
                <path
                  id="path359_undefined"
                  v-if="
                    chartBodygraphChart.centersUndefinedExt.center_undefined_8
                  "
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.centers,
                        'ajna'
                      )
                    )
                  "
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphConnection.centersUndefined,
                        8
                      ) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  @mouseover="isGatesActiveForCenter(8)"
                  @mouseleave="delayedСlearAcPlanets()"
                  style="fill:#ffffff;stroke-width:0.99999994;opacity:0;cursor:pointer;display:none"
                  inkscape:connector-curvature="0"
                  d="m 404.16,206.89 -49,-84.8 a 5.64,5.64 0 0 1 4.9,-8.5 h 98 a 5.64,5.64 0 0 1 4.9,8.5 l -49,84.8 a 5.64,5.64 0 0 1 -9.8,0 z"
                />
                <path
                  id="path361"
                  v-if="
                    chartBodygraphChart.centersUndefinedExt.center_undefined_5
                  "
                  @click="
                    openTooltipDialog(
                      chartBodygraphChart.centersUndefinedExt.center_undefined_5
                    )
                  "
                  @mouseover="isGatesActiveForCenter(5)"
                  @mouseleave="delayedСlearAcPlanets()"
                  style="fill:#ffffff;cursor:pointer"
                  inkscape:connector-curvature="0"
                  d="m 523.16,425.39 31.6,62.3 a 6.17,6.17 0 0 1 -7,8.8 l -88.2,-21.3 a 6.2,6.2 0 0 1 -2.2,-11 l 56.6,-41 a 6.27,6.27 0 0 1 9.2,2.2 z"
                />
                <path
                  id="path361_undefined"
                  v-if="
                    chartBodygraphChart.centersUndefinedExt.center_undefined_5
                  "
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.centers,
                        'ego'
                      )
                    )
                  "
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphConnection.centersUndefined,
                        5
                      ) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  @mouseover="isGatesActiveForCenter(5)"
                  @mouseleave="delayedСlearAcPlanets()"
                  style="fill:#ffffff;stroke-width:0.99999994;opacity:0;cursor:pointer;display:none"
                  inkscape:connector-curvature="0"
                  d="m 523.16,425.39 31.6,62.3 a 6.17,6.17 0 0 1 -7,8.8 l -88.2,-21.3 a 6.2,6.2 0 0 1 -2.2,-11 l 56.6,-41 a 6.27,6.27 0 0 1 9.2,2.2 z"
                />
                <path
                  id="path363"
                  v-if="
                    chartBodygraphChart.centersUndefinedExt.center_undefined_4
                  "
                  @click="
                    openTooltipDialog(
                      chartBodygraphChart.centersUndefinedExt.center_undefined_4
                    )
                  "
                  @mouseover="isGatesActiveForCenter(4)"
                  @mouseleave="delayedСlearAcPlanets()"
                  style="fill:#ffffff;cursor:pointer"
                  d="m 292.86,563.59 -84.8,48.9 a 5.64,5.64 0 0 1 -8.5,-4.9 v -98 a 5.64,5.64 0 0 1 8.5,-4.9 l 84.8,49 a 5.72,5.72 0 0 1 0,9.9 z"
                  inkscape:connector-curvature="0"
                />
                <path
                  id="path363_undefined"
                  v-if="
                    chartBodygraphChart.centersUndefinedExt.center_undefined_4
                  "
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.centers,
                        'splenic'
                      )
                    )
                  "
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphConnection.centersUndefined,
                        4
                      ) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  @mouseover="isGatesActiveForCenter(4)"
                  @mouseleave="delayedСlearAcPlanets()"
                  style="fill:#ffffff;stroke-width:0.99999994;opacity:0;cursor:pointer;display:none"
                  d="m 292.86,563.59 -84.8,48.9 a 5.64,5.64 0 0 1 -8.5,-4.9 v -98 a 5.64,5.64 0 0 1 8.5,-4.9 l 84.8,49 a 5.72,5.72 0 0 1 0,9.9 z"
                  inkscape:connector-curvature="0"
                />
                <path
                  id="path365"
                  v-if="
                    chartBodygraphChart.centersUndefinedExt.center_undefined_2
                  "
                  @click="
                    openTooltipDialog(
                      chartBodygraphChart.centersUndefinedExt.center_undefined_2
                    )
                  "
                  @mouseover="isGatesActiveForCenter(2)"
                  @mouseleave="delayedСlearAcPlanets()"
                  style="fill:#ffffff;cursor:pointer"
                  d="m 525.36,553.69 84.8,-49 a 5.64,5.64 0 0 1 8.5,4.9 v 98 a 5.64,5.64 0 0 1 -8.5,4.9 l -84.8,-49 a 5.64,5.64 0 0 1 0,-9.8 z"
                  inkscape:connector-curvature="0"
                />
                <path
                  id="path365_undefined"
                  v-if="
                    chartBodygraphChart.centersUndefinedExt.center_undefined_2
                  "
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.centers,
                        'emo'
                      )
                    )
                  "
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphConnection.centersUndefined,
                        2
                      ) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  @mouseover="isGatesActiveForCenter(2)"
                  @mouseleave="delayedСlearAcPlanets()"
                  style="fill:#ffffff;stroke-width:0.99999994;opacity:0;cursor:pointer;display:none"
                  d="m 525.36,553.69 84.8,-49 a 5.64,5.64 0 0 1 8.5,4.9 v 98 a 5.64,5.64 0 0 1 -8.5,4.9 l -84.8,-49 a 5.64,5.64 0 0 1 0,-9.8 z"
                  inkscape:connector-curvature="0"
                />
                <path
                  id="path369"
                  v-if="
                    chartBodygraphChart.centersUndefinedExt.center_undefined_3
                  "
                  @click="
                    openTooltipDialog(
                      chartBodygraphChart.centersUndefinedExt.center_undefined_3
                    )
                  "
                  @mouseover="isGatesActiveForCenter(3)"
                  @mouseleave="delayedСlearAcPlanets()"
                  style="fill:#ffffff;cursor:pointer"
                  inkscape:connector-curvature="0"
                  d="m 433.36,518.19033 h -49.3 a 8.4,8.4 0 0 0 -7.2,4.1 l -24.7,42.7 a 8.22,8.22 0 0 0 0,8.3 l 24.7,42.7 a 8.19,8.19 0 0 0 7.2,4.1 h 49.3 a 8.4,8.4 0 0 0 7.2,-4.1 l 24.7,-42.7 a 8.22,8.22 0 0 0 0,-8.3 l -24.7,-42.7 a 8.19,8.19 0 0 0 -7.2,-4.1 z"
                />
                <path
                  id="path369_undefined"
                  v-if="
                    chartBodygraphChart.centersUndefinedExt.center_undefined_3
                  "
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.centers,
                        'sacral'
                      )
                    )
                  "
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphConnection.centersUndefined,
                        3
                      ) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  @mouseover="isGatesActiveForCenter(3)"
                  @mouseleave="delayedСlearAcPlanets()"
                  style="fill:#ffffff;stroke-width:0.99999994;opacity:0;cursor:pointer;display:none"
                  inkscape:connector-curvature="0"
                  d="m 433.36,518.19033 h -49.3 a 8.4,8.4 0 0 0 -7.2,4.1 l -24.7,42.7 a 8.22,8.22 0 0 0 0,8.3 l 24.7,42.7 a 8.19,8.19 0 0 0 7.2,4.1 h 49.3 a 8.4,8.4 0 0 0 7.2,-4.1 l 24.7,-42.7 a 8.22,8.22 0 0 0 0,-8.3 l -24.7,-42.7 a 8.19,8.19 0 0 0 -7.2,-4.1 z"
                />
                <path
                  id="path371"
                  v-if="
                    chartBodygraphChart.centersUndefinedExt.center_undefined_6
                  "
                  @click="
                    openTooltipDialog(
                      chartBodygraphChart.centersUndefinedExt.center_undefined_6
                    )
                  "
                  @mouseover="isGatesActiveForCenter(6)"
                  @mouseleave="delayedСlearAcPlanets()"
                  style="fill:#ffffff;cursor:pointer"
                  inkscape:connector-curvature="0"
                  d="m 462.86,407.49 -48.1,48.1 a 8,8 0 0 1 -11.3,0 l -48.1,-48.1 a 8,8 0 0 1 0,-11.3 l 48.1,-48.1 a 8,8 0 0 1 11.3,0 l 48.1,48.1 a 8,8 0 0 1 0,11.3 z"
                />
                <path
                  id="path371_undefined"
                  v-if="
                    chartBodygraphChart.centersUndefinedExt.center_undefined_6
                  "
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.centers,
                        '_g'
                      )
                    )
                  "
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphConnection.centersUndefined,
                        6
                      ) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  @mouseover="isGatesActiveForCenter(6)"
                  @mouseleave="delayedСlearAcPlanets()"
                  style="fill:#ffffff;stroke-width:0.99999994;opacity:0;cursor:pointer;display:none"
                  inkscape:connector-curvature="0"
                  d="m 462.86,407.49 -48.1,48.1 a 8,8 0 0 1 -11.3,0 l -48.1,-48.1 a 8,8 0 0 1 0,-11.3 l 48.1,-48.1 a 8,8 0 0 1 11.3,0 l 48.1,48.1 a 8,8 0 0 1 0,11.3 z"
                />
                <circle
                  id="circle373"
                  v-if="
                    chartBodygraphChart.centersUndefinedExt.center_undefined_7
                  "
                  @click="
                    openTooltipDialog(
                      chartBodygraphChart.centersUndefinedExt.center_undefined_7
                    )
                  "
                  @mouseover="isGatesActiveForCenter(7)"
                  @mouseleave="delayedСlearAcPlanets()"
                  style="fill:#ffffff;cursor:pointer"
                  r="51.5"
                  cy="279.39001"
                  cx="409.06"
                />
                <circle
                  id="circle373_undefined"
                  v-if="
                    chartBodygraphChart.centersUndefinedExt.center_undefined_7
                  "
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.centers,
                        'throat'
                      )
                    )
                  "
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphConnection.centersUndefined,
                        7
                      ) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  @mouseover="isGatesActiveForCenter(7)"
                  @mouseleave="delayedСlearAcPlanets()"
                  style="fill:#ffffff;stroke-width:0.99999994;opacity:0;cursor:pointer;display:none"
                  r="51.5"
                  cy="279.39001"
                  cx="409.06"
                />
                <path
                  id="path375"
                  v-if="
                    chartBodygraphChart.centersUndefinedExt.center_undefined_1
                  "
                  @click="
                    openTooltipDialog(
                      chartBodygraphChart.centersUndefinedExt.center_undefined_1
                    )
                  "
                  @mouseover="isGatesActiveForCenter(1)"
                  @mouseleave="delayedСlearAcPlanets()"
                  style="fill:#ffffff;stroke-width:0.99999994;cursor:pointer"
                  inkscape:connector-curvature="0"
                  d="M 445.89333,722.34736 H 372.22666 A 8.6666664,8.2626054 0 0 1 363.56,714.08475 v -70.23214 a 8.6666664,8.2626054 0 0 1 8.66666,-8.26261 h 73.66667 a 8.6666664,8.2626054 0 0 1 8.66666,8.26261 v 70.23214 a 8.6666664,8.2626054 0 0 1 -8.66666,8.26261 z"
                />
                <path
                  id="path375_undefined"
                  v-if="
                    chartBodygraphChart.centersUndefinedExt.center_undefined_1
                  "
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.centers,
                        'root'
                      )
                    )
                  "
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphConnection.centersUndefined,
                        1
                      ) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  @mouseover="isGatesActiveForCenter(1)"
                  @mouseleave="delayedСlearAcPlanets()"
                  style="fill:#ffffff;stroke-width:0.99999994;opacity:0;cursor:pointer;display:none"
                  inkscape:connector-curvature="0"
                  d="M 445.89333,722.34736 H 372.22666 A 8.6666664,8.2626054 0 0 1 363.56,714.08475 v -70.23214 a 8.6666664,8.2626054 0 0 1 8.66666,-8.26261 h 73.66667 a 8.6666664,8.2626054 0 0 1 8.66666,8.26261 v 70.23214 a 8.6666664,8.2626054 0 0 1 -8.66666,8.26261 z"
                />
              </g>
              <g
                id="Слой_4-2"
                style="display:inline"
                data-name="Слой 4"
                transform="translate(30.89,17.409983)"
              >
                <path
                  id="center_head"
                  v-if="chartBodygraphChart.centersDefinedExt.center_defined_9"
                  @click="
                    openTooltipDialog(
                      chartBodygraphChart.centersDefinedExt.center_defined_9
                    )
                  "
                  v-bind:class="{
                    opacity1:
                      (this.isDefinedObj(
                        chartBodygraphConnection.centersDefined,
                        9
                      ) &&
                        isChartToggle &&
                        isChart2Toggle) ||
                      (this.isDefinedObj(
                        chartBodygraphChart.centersDefined,
                        9
                      ) &&
                        !isChartToggle) ||
                      (this.isDefinedObj(
                        chartBodygraphChart2.centersDefined,
                        9
                      ) &&
                        !isChart2Toggle)
                  }"
                  @mouseover="isGatesActiveForCenter(9)"
                  @mouseleave="delayedСlearAcPlanets()"
                  d="m 404.16,5.39 -49,84.8 a 5.64,5.64 0 0 0 4.9,8.5 h 98 a 5.64,5.64 0 0 0 4.9,-8.5 l -49,-84.8 a 5.64,5.64 0 0 0 -9.8,0 z"
                  inkscape:connector-curvature="0"
                  style="fill:#fff343;opacity:0;cursor:pointer;display:none"
                />
                <path
                  id="center_head_connected"
                  v-if="chartBodygraphChart.centersDefinedExt.center_defined_9"
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.centers,
                        'head'
                      )
                    )
                  "
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphConnection.centersDefined,
                        9
                      ) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  @mouseover="isGatesActiveForCenter(9)"
                  @mouseleave="delayedСlearAcPlanets()"
                  d="m 404.16,5.39 -49,84.8 a 5.64,5.64 0 0 0 4.9,8.5 h 98 a 5.64,5.64 0 0 0 4.9,-8.5 l -49,-84.8 a 5.64,5.64 0 0 0 -9.8,0 z"
                  inkscape:connector-curvature="0"
                  style="fill:#fff343;opacity:0;cursor:pointer;display:none"
                />
                <path
                  id="center_ajna"
                  v-if="chartBodygraphChart.centersDefinedExt.center_defined_8"
                  @click="
                    openTooltipDialog(
                      chartBodygraphChart.centersDefinedExt.center_defined_8
                    )
                  "
                  v-bind:class="{
                    opacity1:
                      (this.isDefinedObj(
                        chartBodygraphChart.centersDefined,
                        8
                      ) &&
                        !isChartToggle) ||
                      (this.isDefinedObj(
                        chartBodygraphChart2.centersDefined,
                        8
                      ) &&
                        !isChart2Toggle)
                  }"
                  @mouseover="isGatesActiveForCenter(8)"
                  @mouseleave="delayedСlearAcPlanets()"
                  d="m 404.16,206.89 -49,-84.8 a 5.64,5.64 0 0 1 4.9,-8.5 h 98 a 5.64,5.64 0 0 1 4.9,8.5 l -49,84.8 a 5.64,5.64 0 0 1 -9.8,0 z"
                  inkscape:connector-curvature="0"
                  style="fill:#61ad42;opacity:0;cursor:pointer;display:none"
                />
                <path
                  id="center_ajna_connected"
                  v-if="chartBodygraphChart.centersDefinedExt.center_defined_8"
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.centers,
                        'ajna'
                      )
                    )
                  "
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphConnection.centersDefined,
                        8
                      ) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  @mouseover="isGatesActiveForCenter(8)"
                  @mouseleave="delayedСlearAcPlanets()"
                  d="m 404.16,206.89 -49,-84.8 a 5.64,5.64 0 0 1 4.9,-8.5 h 98 a 5.64,5.64 0 0 1 4.9,8.5 l -49,84.8 a 5.64,5.64 0 0 1 -9.8,0 z"
                  inkscape:connector-curvature="0"
                  style="fill:#61ad42;opacity:0;cursor:pointer;display:none"
                />
                <path
                  id="center_ego"
                  v-if="chartBodygraphChart.centersDefinedExt.center_defined_5"
                  @click="
                    openTooltipDialog(
                      chartBodygraphChart.centersDefinedExt.center_defined_5
                    )
                  "
                  v-bind:class="{
                    opacity1:
                      (this.isDefinedObj(
                        chartBodygraphConnection.centersDefined,
                        5
                      ) &&
                        isChartToggle &&
                        isChart2Toggle) ||
                      (this.isDefinedObj(
                        chartBodygraphChart.centersDefined,
                        5
                      ) &&
                        !isChartToggle) ||
                      (this.isDefinedObj(
                        chartBodygraphChart2.centersDefined,
                        5
                      ) &&
                        !isChart2Toggle)
                  }"
                  @mouseover="isGatesActiveForCenter(5)"
                  @mouseleave="delayedСlearAcPlanets()"
                  d="m 523.26,425.39 31.6,62.3 a 6.17,6.17 0 0 1 -7,8.8 l -88.2,-21.4 a 6.2,6.2 0 0 1 -2.2,-11 l 56.6,-40.9 a 6.18,6.18 0 0 1 9.2,2.2 z"
                  inkscape:connector-curvature="0"
                  style="fill:#de0052;opacity:0;cursor:pointer;display:none"
                />
                <path
                  id="center_ego_connected"
                  v-if="chartBodygraphChart.centersDefinedExt.center_defined_5"
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.centers,
                        'ego'
                      )
                    )
                  "
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphConnection.centersDefined,
                        5
                      ) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  @mouseover="isGatesActiveForCenter(5)"
                  @mouseleave="delayedСlearAcPlanets()"
                  d="m 523.26,425.39 31.6,62.3 a 6.17,6.17 0 0 1 -7,8.8 l -88.2,-21.4 a 6.2,6.2 0 0 1 -2.2,-11 l 56.6,-40.9 a 6.18,6.18 0 0 1 9.2,2.2 z"
                  inkscape:connector-curvature="0"
                  style="fill:#de0052;opacity:0;cursor:pointer;display:none"
                />
                <path
                  id="center_splenic"
                  v-if="chartBodygraphChart.centersDefinedExt.center_defined_4"
                  @click="
                    openTooltipDialog(
                      chartBodygraphChart.centersDefinedExt.center_defined_4
                    )
                  "
                  v-bind:class="{
                    opacity1:
                      (this.isDefinedObj(
                        chartBodygraphConnection.centersDefined,
                        4
                      ) &&
                        isChartToggle &&
                        isChart2Toggle) ||
                      (this.isDefinedObj(
                        chartBodygraphChart.centersDefined,
                        4
                      ) &&
                        !isChartToggle) ||
                      (this.isDefinedObj(
                        chartBodygraphChart2.centersDefined,
                        4
                      ) &&
                        !isChart2Toggle)
                  }"
                  @mouseover="isGatesActiveForCenter(4)"
                  @mouseleave="delayedСlearAcPlanets()"
                  style="fill:#c45808;opacity:0;cursor:pointer;display:none"
                  inkscape:connector-curvature="0"
                  d="m 292.86,563.59 -84.8,48.9 a 5.64,5.64 0 0 1 -8.5,-4.9 v -98 a 5.64,5.64 0 0 1 8.5,-4.9 l 84.8,49 a 5.72,5.72 0 0 1 0,9.9 z"
                />
                <path
                  id="center_splenic_connected"
                  v-if="chartBodygraphChart.centersDefinedExt.center_defined_4"
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.centers,
                        'splenic'
                      )
                    )
                  "
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphConnection.centersDefined,
                        4
                      ) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  @mouseover="isGatesActiveForCenter(4)"
                  @mouseleave="delayedСlearAcPlanets()"
                  style="fill:#c45808;opacity:0;cursor:pointer;display:none"
                  inkscape:connector-curvature="0"
                  d="m 292.86,563.59 -84.8,48.9 a 5.64,5.64 0 0 1 -8.5,-4.9 v -98 a 5.64,5.64 0 0 1 8.5,-4.9 l 84.8,49 a 5.72,5.72 0 0 1 0,9.9 z"
                />
                <path
                  id="center_emo"
                  v-if="chartBodygraphChart.centersDefinedExt.center_defined_2"
                  @click="
                    openTooltipDialog(
                      chartBodygraphChart.centersDefinedExt.center_defined_2
                    )
                  "
                  v-bind:class="{
                    opacity1:
                      (this.isDefinedObj(
                        chartBodygraphConnection.centersDefined,
                        2
                      ) &&
                        isChartToggle &&
                        isChart2Toggle) ||
                      (this.isDefinedObj(
                        chartBodygraphChart.centersDefined,
                        2
                      ) &&
                        !isChartToggle) ||
                      (this.isDefinedObj(
                        chartBodygraphChart2.centersDefined,
                        2
                      ) &&
                        !isChart2Toggle)
                  }"
                  @mouseover="isGatesActiveForCenter(2)"
                  @mouseleave="delayedСlearAcPlanets()"
                  style="fill:#c45808;opacity:0;cursor:pointer;display:none"
                  inkscape:connector-curvature="0"
                  d="m 525.36,553.69 84.8,-49 a 5.64,5.64 0 0 1 8.5,4.9 v 98 a 5.64,5.64 0 0 1 -8.5,4.9 l -84.8,-49 a 5.64,5.64 0 0 1 0,-9.8 z"
                />
                <path
                  id="center_emo_connected"
                  v-if="chartBodygraphChart.centersDefinedExt.center_defined_2"
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.centers,
                        'emo'
                      )
                    )
                  "
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphConnection.centersDefined,
                        2
                      ) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  @mouseover="isGatesActiveForCenter(2)"
                  @mouseleave="delayedСlearAcPlanets()"
                  style="fill:#c45808;opacity:0;cursor:pointer;display:none"
                  inkscape:connector-curvature="0"
                  d="m 525.36,553.69 84.8,-49 a 5.64,5.64 0 0 1 8.5,4.9 v 98 a 5.64,5.64 0 0 1 -8.5,4.9 l -84.8,-49 a 5.64,5.64 0 0 1 0,-9.8 z"
                />
                <path
                  id="center_sacral"
                  v-if="chartBodygraphChart.centersDefinedExt.center_defined_3"
                  @click="
                    openTooltipDialog(
                      chartBodygraphChart.centersDefinedExt.center_defined_3
                    )
                  "
                  v-bind:class="{
                    opacity1:
                      (this.isDefinedObj(
                        chartBodygraphConnection.centersDefined,
                        3
                      ) &&
                        isChartToggle &&
                        isChart2Toggle) ||
                      (this.isDefinedObj(
                        chartBodygraphChart.centersDefined,
                        3
                      ) &&
                        !isChartToggle) ||
                      (this.isDefinedObj(
                        chartBodygraphChart2.centersDefined,
                        3
                      ) &&
                        !isChart2Toggle)
                  }"
                  @mouseover="isGatesActiveForCenter(3)"
                  @mouseleave="delayedСlearAcPlanets()"
                  d="m 433.36,518.19 h -49.3 a 8.4,8.4 0 0 0 -7.2,4.1 l -24.7,42.7 a 8.22,8.22 0 0 0 0,8.3 l 24.7,42.7 a 8.19,8.19 0 0 0 7.2,4.1 h 49.3 a 8.4,8.4 0 0 0 7.2,-4.1 l 24.7,-42.7 a 8.22,8.22 0 0 0 0,-8.3 l -24.7,-42.7 a 8.65,8.65 0 0 0 -7.2,-4.1 z"
                  inkscape:connector-curvature="0"
                  style="fill:#de0052;opacity:0;cursor:pointer;display:none"
                />
                <path
                  id="center_sacral_connected"
                  v-if="chartBodygraphChart.centersDefinedExt.center_defined_3"
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.centers,
                        'sacral'
                      )
                    )
                  "
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphConnection.centersDefined,
                        3
                      ) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  @mouseover="isGatesActiveForCenter(3)"
                  @mouseleave="delayedСlearAcPlanets()"
                  d="m 433.36,518.19 h -49.3 a 8.4,8.4 0 0 0 -7.2,4.1 l -24.7,42.7 a 8.22,8.22 0 0 0 0,8.3 l 24.7,42.7 a 8.19,8.19 0 0 0 7.2,4.1 h 49.3 a 8.4,8.4 0 0 0 7.2,-4.1 l 24.7,-42.7 a 8.22,8.22 0 0 0 0,-8.3 l -24.7,-42.7 a 8.65,8.65 0 0 0 -7.2,-4.1 z"
                  inkscape:connector-curvature="0"
                  style="fill:#de0052;opacity:0;cursor:pointer;display:none"
                />
                <path
                  id="center_self"
                  v-if="chartBodygraphChart.centersDefinedExt.center_defined_6"
                  @click="
                    openTooltipDialog(
                      chartBodygraphChart.centersDefinedExt.center_defined_6
                    )
                  "
                  v-bind:class="{
                    opacity1:
                      (this.isDefinedObj(
                        chartBodygraphConnection.centersDefined,
                        6
                      ) &&
                        isChartToggle &&
                        isChart2Toggle) ||
                      (this.isDefinedObj(
                        chartBodygraphChart.centersDefined,
                        6
                      ) &&
                        !isChartToggle) ||
                      (this.isDefinedObj(
                        chartBodygraphChart2.centersDefined,
                        6
                      ) &&
                        !isChart2Toggle)
                  }"
                  @mouseover="isGatesActiveForCenter(6)"
                  @mouseleave="delayedСlearAcPlanets()"
                  d="m 462.76,407.49 -48.1,48.1 a 8,8 0 0 1 -11.3,0 l -48.1,-48.1 a 8,8 0 0 1 0,-11.3 l 48.1,-48.1 a 8,8 0 0 1 11.3,0 l 48.1,48.1 a 7.85,7.85 0 0 1 0,11.3 z"
                  inkscape:connector-curvature="0"
                  style="fill:#fff343;opacity:0;cursor:pointer;display:none"
                />
                <path
                  id="center_self_connected"
                  v-if="chartBodygraphChart.centersDefinedExt.center_defined_6"
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.centers,
                        '_g'
                      )
                    )
                  "
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphConnection.centersDefined,
                        6
                      ) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  @mouseover="isGatesActiveForCenter(6)"
                  @mouseleave="delayedСlearAcPlanets()"
                  d="m 462.76,407.49 -48.1,48.1 a 8,8 0 0 1 -11.3,0 l -48.1,-48.1 a 8,8 0 0 1 0,-11.3 l 48.1,-48.1 a 8,8 0 0 1 11.3,0 l 48.1,48.1 a 7.85,7.85 0 0 1 0,11.3 z"
                  inkscape:connector-curvature="0"
                  style="fill:#fff343;opacity:0;cursor:pointer;display:none"
                />
                <circle
                  id="center_throat"
                  v-if="chartBodygraphChart.centersDefinedExt.center_defined_7"
                  @click="
                    openTooltipDialog(
                      chartBodygraphChart.centersDefinedExt.center_defined_7
                    )
                  "
                  v-bind:class="{
                    opacity1:
                      (this.isDefinedObj(
                        chartBodygraphConnection.centersDefined,
                        7
                      ) &&
                        isChartToggle &&
                        isChart2Toggle) ||
                      (this.isDefinedObj(
                        chartBodygraphChart.centersDefined,
                        7
                      ) &&
                        !isChartToggle) ||
                      (this.isDefinedObj(
                        chartBodygraphChart2.centersDefined,
                        7
                      ) &&
                        !isChart2Toggle)
                  }"
                  @mouseover="isGatesActiveForCenter(7)"
                  @mouseleave="delayedСlearAcPlanets()"
                  cx="409.06"
                  cy="279.39001"
                  r="51.5"
                  style="fill:#49c4be;opacity:0;cursor:pointer;display:none"
                />
                <circle
                  id="center_throat_connected"
                  v-if="chartBodygraphChart.centersDefinedExt.center_defined_7"
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.centers,
                        'throat'
                      )
                    )
                  "
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphConnection.centersDefined,
                        7
                      ) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  @mouseover="isGatesActiveForCenter(7)"
                  @mouseleave="delayedСlearAcPlanets()"
                  cx="409.06"
                  cy="279.39001"
                  r="51.5"
                  style="fill:#49c4be;opacity:0;cursor:pointer;display:none"
                />
                <path
                  id="center_root"
                  v-if="chartBodygraphChart.centersDefinedExt.center_defined_1"
                  @click="
                    openTooltipDialog(
                      chartBodygraphChart.centersDefinedExt.center_defined_1
                    )
                  "
                  v-bind:class="{
                    opacity1:
                      (this.isDefinedObj(
                        chartBodygraphConnection.centersDefined,
                        1
                      ) &&
                        isChartToggle &&
                        isChart2Toggle) ||
                      (this.isDefinedObj(
                        chartBodygraphChart.centersDefined,
                        1
                      ) &&
                        !isChartToggle) ||
                      (this.isDefinedObj(
                        chartBodygraphChart2.centersDefined,
                        1
                      ) &&
                        !isChart2Toggle)
                  }"
                  @mouseover="isGatesActiveForCenter(1)"
                  @mouseleave="delayedСlearAcPlanets()"
                  d="m 446.56,722.34736 h -75 a 8,7.6270206 0 0 1 -8,-7.62702 v -71.50332 a 8,7.6270206 0 0 1 8,-7.62702 h 75 a 8,7.6270206 0 0 1 8,7.62702 v 71.50332 a 8,7.6270206 0 0 1 -8,7.62702 z"
                  inkscape:connector-curvature="0"
                  style="fill:#c45808;stroke-width:0.99999994;opacity:0;cursor:pointer;display:none"
                />
                <path
                  id="center_root_connected"
                  v-if="chartBodygraphChart.centersDefinedExt.center_defined_1"
                  @click="
                    openTooltipDialog(
                      toSearchItemAtArrInObj(
                        chartBodygraphConnection.centers,
                        'root'
                      )
                    )
                  "
                  v-bind:class="{
                    opacity1:
                      this.isDefinedObj(
                        chartBodygraphConnection.centersDefined,
                        1
                      ) &&
                      isChartToggle &&
                      isChart2Toggle
                  }"
                  @mouseover="isGatesActiveForCenter(1)"
                  @mouseleave="delayedСlearAcPlanets()"
                  d="m 446.56,722.34736 h -75 a 8,7.6270206 0 0 1 -8,-7.62702 v -71.50332 a 8,7.6270206 0 0 1 8,-7.62702 h 75 a 8,7.6270206 0 0 1 8,7.62702 v 71.50332 a 8,7.6270206 0 0 1 -8,7.62702 z"
                  inkscape:connector-curvature="0"
                  style="fill:#c45808;stroke-width:0.99999994;opacity:0;cursor:pointer;display:none"
                />
              </g>
            </g>
            <g
              id="layer4"
              inkscape:groupmode="layer"
              inkscape:label="Gate Numbers"
              style="display:inline"
            >
              <g id="g2173">
                <text
                  id="text1131"
                  y="110"
                  x="411"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  64
                </text>
                <text
                  id="text1133"
                  y="110"
                  x="454"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  63
                </text>
                <text
                  id="text1135"
                  y="266.33548"
                  x="412.12885"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  62
                </text>
                <text
                  id="text1137"
                  y="110"
                  x="432"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  61
                </text>
                <text
                  id="text1139"
                  y="668.43549"
                  x="434.2088"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  60
                </text>
                <text
                  id="text1141"
                  y="610.1355"
                  x="468.95541"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  59
                </text>
                <text
                  id="text1143"
                  y="728.73547"
                  x="398.26874"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  58
                </text>
                <text
                  id="text1145"
                  y="553.86212"
                  x="258.02878"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  57
                </text>
                <text
                  id="text1147"
                  y="265.3288"
                  x="452.94208"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  56
                </text>
                <text
                  id="text1149"
                  y="605.9621"
                  x="606.26886"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  55
                </text>
                <text
                  id="text1151"
                  y="685.36212"
                  x="398.56873"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  54
                </text>
                <text
                  id="text1153"
                  y="668.83551"
                  x="412.35553"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  53
                </text>
                <text
                  id="text1155"
                  y="669.03546"
                  x="455.27548"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  52
                </text>
                <text
                  id="text1157"
                  y="471.66211"
                  x="518.90259"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  51
                </text>
                <text
                  id="text1159"
                  y="580.33551"
                  x="302.95541"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  50
                </text>
                <text
                  id="text1161"
                  y="594.84125"
                  x="586.93549"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  49
                </text>
                <text
                  id="text1163"
                  y="543.33551"
                  x="239.14885"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  48
                </text>
                <text
                  id="text1165"
                  y="145.53548"
                  x="411.70886"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  47
                </text>
                <text
                  id="text1167"
                  y="447.9288"
                  x="453.22217"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  46
                </text>
                <text
                  id="text1169"
                  y="324.66211"
                  x="466.64893"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  45
                </text>
                <text
                  id="text1171"
                  y="566.93549"
                  x="280.34879"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  44
                </text>
                <text
                  id="text1173"
                  y="212.53548"
                  x="432.7356"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  43
                </text>
                <text
                  id="text1175"
                  y="631.20886"
                  x="411.45557"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  42
                </text>
                <text
                  id="text1177"
                  y="729.33551"
                  x="468.4827"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  41
                </text>
                <text
                  id="text1179"
                  y="506.53549"
                  x="566.03552"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  40
                </text>
                <text
                  id="text1181"
                  y="706.6355"
                  x="467.55542"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  39
                </text>
                <text
                  id="text1183"
                  y="705.93549"
                  x="398.56876"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  38
                </text>
                <text
                  id="text1185"
                  y="568.83551"
                  x="582.8288"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  37
                </text>
                <text
                  id="text1187"
                  y="544.83551"
                  x="624.54205"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  36
                </text>
                <text
                  id="text1189"
                  y="285.73547"
                  x="471.46884"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  35
                </text>
                <text
                  id="text1191"
                  y="577.83551"
                  x="393.86871"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  34
                </text>
                <text
                  id="text1193"
                  y="337.73547"
                  x="452.15552"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  33
                </text>
                <text
                  id="text1195"
                  y="593.93549"
                  x="280.37546"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  32
                </text>
                <text
                  id="text1197"
                  y="337.53549"
                  x="413.90259"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  31
                </text>
                <text
                  id="text1199"
                  y="617.83551"
                  x="627.25543"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  30
                </text>
                <text
                  id="text1201"
                  y="550.64124"
                  x="453.93539"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  29
                </text>
                <text
                  id="text1203"
                  y="605.6355"
                  x="258.54874"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  28
                </text>
                <text
                  id="text1205"
                  y="610.70886"
                  x="399.00879"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  27
                </text>
                <text
                  id="text1207"
                  y="489.23547"
                  x="494.32205"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  26
                </text>
                <text
                  id="text1209"
                  y="423.73547"
                  x="476.54883"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  25
                </text>
                <text
                  id="text1211"
                  y="145.40884"
                  x="433.34869"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  24
                </text>
                <text
                  id="text1213"
                  y="260.43549"
                  x="432.7355"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  23
                </text>
                <text
                  id="text1215"
                  y="556.10883"
                  x="604.35547"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  22
                </text>
                <text
                  id="text1217"
                  y="456.20886"
                  x="539.9826"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  21
                </text>
                <text
                  id="text1219"
                  y="308.23547"
                  x="392.43539"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  20
                </text>
                <text
                  id="text1221"
                  y="686.14124"
                  x="467.24188"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  19
                </text>
                <text
                  id="text1223"
                  y="616.93549"
                  x="238.85522"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  18
                </text>
                <text
                  id="text1225"
                  y="181.43549"
                  x="412.61523"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  17
                </text>
                <text
                  id="text1227"
                  y="284.8288"
                  x="394.52853"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  16
                </text>
                <text
                  id="text1229"
                  y="448.16211"
                  x="412.0553"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  15
                </text>
                <text
                  id="text1231"
                  y="550.33551"
                  x="432.15518"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  14
                </text>
                <text
                  id="text1233"
                  y="397.03549"
                  x="451.54196"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  13
                </text>
                <text
                  id="text1235"
                  y="307.10886"
                  x="473.56192"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  12
                </text>
                <text
                  id="text1237"
                  y="180.33548"
                  x="454.08905"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  11
                </text>
                <text
                  id="text1239"
                  y="423.43549"
                  x="388.64188"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  10
                </text>
                <text
                  id="text1241"
                  y="631.64124"
                  x="456.59497"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  9
                </text>
                <text
                  id="text1243"
                  y="342.93549"
                  x="436.52829"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  8
                </text>
                <text
                  id="text1245"
                  y="397.93549"
                  x="416.95493"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  7
                </text>
                <text
                  id="text1247"
                  y="580.32886"
                  x="565.72162"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  6
                </text>
                <text
                  id="text1249"
                  y="550.56213"
                  x="415.59503"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  5
                </text>
                <text
                  id="text1251"
                  y="145.53548"
                  x="457.47498"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  4
                </text>
                <text
                  id="text1253"
                  y="631.6355"
                  x="435.78171"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  3
                </text>
                <text
                  id="text1255"
                  y="467.60886"
                  x="436.48166"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  2
                </text>
                <text
                  id="text1257"
                  y="378.53549"
                  x="437.01526"
                  style="font-size:0.75rem;isolation:isolate;fill:#050505"
                  font-size="11.14"
                >
                  1
                </text>
              </g>
            </g>
            <g
              id="layer5"
              inkscape:groupmode="layer"
              inkscape:label="Gates Active"
              style="display:inline;opacity:1"
            >
              <g
                id="gate_64_on"
                v-if="chartBodygraphChart.gatesExt[64]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[64])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 64),
                  opacity4:
                    isDefinedTogglePointGate1(64) ||
                    isDefinedTogglePointGate2(64)
                }"
                @mouseover="searchAcPlanets(64)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 64)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="87.889999"
                  cx="387.45999"
                />
                <circle
                  id="circle1261"
                  class="gates"
                  style="fill:#504b00"
                  r="10.1"
                  cy="87.889999"
                  cx="387.45999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      64
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="87.889999"
                  cx="387.45999"
                />
                <text
                  id="text1263"
                  y="93"
                  x="380"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                >
                  64
                </text>
              </g>
              <g
                id="gate_64_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[64]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[64])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 64),
                  opacity4:
                    isDefinedTogglePointGate1(64) ||
                    isDefinedTogglePointGate2(64)
                }"
                @mouseover="searchAcPlanets(64)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 64)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="87.889999"
                  cx="387.45999"
                />
                <circle
                  id="circle1261"
                  class="gates"
                  style="fill:#504b00"
                  r="10.1"
                  cy="87.889999"
                  cx="387.45999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      64
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="87.889999"
                  cx="387.45999"
                />
                <text
                  id="text1263"
                  y="93"
                  x="380"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                >
                  64
                </text>
              </g>
              <g
                id="gate_64_both_on"
                v-if="chartBodygraphConnection.gatesExt[64]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[64])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    64
                  )
                }"
                @mouseover="searchAcPlanets(64)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 64)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="87.889999"
                  cx="387.45999"
                />
                <circle
                  id="circle1261"
                  class="gates"
                  style="fill:#504b00"
                  r="10.1"
                  cy="87.889999"
                  cx="387.45999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      64
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="87.889999"
                  cx="387.45999"
                />
                <text
                  id="text1263"
                  y="93"
                  x="380"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                >
                  64
                </text>
              </g>
              <g
                id="gate_63_on"
                v-if="chartBodygraphChart.gatesExt[63]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[63])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 63),
                  opacity4:
                    isDefinedTogglePointGate1(63) ||
                    isDefinedTogglePointGate2(63)
                }"
                @mouseover="searchAcPlanets(63)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 63)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="88.389999"
                  cx="430.16"
                />
                <circle
                  id="circle1266"
                  class="gates"
                  style="fill:#504b00"
                  r="10.1"
                  cy="88.389999"
                  cx="430.16"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      63
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="88.389999"
                  cx="430.16"
                />
                <text
                  id="text1268"
                  y="93"
                  x="422.5"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                >
                  63
                </text>
              </g>
              <g
                id="gate_63_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[63]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[63])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 63),
                  opacity4:
                    isDefinedTogglePointGate1(63) ||
                    isDefinedTogglePointGate2(63)
                }"
                @mouseover="searchAcPlanets(63)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 63)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="88.389999"
                  cx="430.16"
                />
                <circle
                  id="circle1266"
                  class="gates"
                  style="fill:#504b00"
                  r="10.1"
                  cy="88.389999"
                  cx="430.16"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      63
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="88.389999"
                  cx="430.16"
                />
                <text
                  id="text1268"
                  y="93"
                  x="422.5"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                >
                  63
                </text>
              </g>
              <g
                id="gate_63_both_on"
                v-if="chartBodygraphConnection.gatesExt[63]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[63])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    63
                  )
                }"
                @mouseover="searchAcPlanets(63)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 63)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="88.389999"
                  cx="430.16"
                />
                <circle
                  id="circle1266"
                  class="gates"
                  style="fill:#504b00"
                  r="10.1"
                  cy="88.389999"
                  cx="430.16"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      63
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="88.389999"
                  cx="430.16"
                />
                <text
                  id="text1268"
                  y="93"
                  x="422.5"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                >
                  63
                </text>
              </g>
              <g
                id="gate_62_on"
                v-if="chartBodygraphChart.gatesExt[62]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[62])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 62),
                  opacity4:
                    isDefinedTogglePointGate1(62) ||
                    isDefinedTogglePointGate2(62)
                }"
                @mouseover="searchAcPlanets(62)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 62)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="244.59"
                  cx="388.06"
                />
                <circle
                  id="circle1271"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="244.59"
                  cx="388.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      62
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="244.59"
                  cx="388.06"
                />
                <text
                  id="text1273"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="380.5"
                  y="248.92549"
                >
                  62
                </text>
              </g>
              <g
                id="gate_62_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[62]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[62])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 62),
                  opacity4:
                    isDefinedTogglePointGate1(62) ||
                    isDefinedTogglePointGate2(62)
                }"
                @mouseover="searchAcPlanets(62)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 62)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="244.59"
                  cx="388.06"
                />
                <circle
                  id="circle1271"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="244.59"
                  cx="388.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      62
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="244.59"
                  cx="388.06"
                />
                <text
                  id="text1273"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="380.5"
                  y="248.92549"
                >
                  62
                </text>
              </g>
              <g
                id="gate_62_both_on"
                v-if="chartBodygraphConnection.gatesExt[62]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[62])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    62
                  )
                }"
                @mouseover="searchAcPlanets(62)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 62)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="244.59"
                  cx="388.06"
                />
                <circle
                  id="circle1271"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="244.59"
                  cx="388.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      62
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="244.59"
                  cx="388.06"
                />
                <text
                  id="text1273"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="380.5"
                  y="248.92549"
                >
                  62
                </text>
              </g>
              <g
                id="gate_61_on"
                v-if="chartBodygraphChart.gatesExt[61]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[61])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 61),
                  opacity4:
                    isDefinedTogglePointGate1(61) ||
                    isDefinedTogglePointGate2(61)
                }"
                @mouseover="searchAcPlanets(61)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 61)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="88.290001"
                  cx="408.85999"
                />
                <circle
                  id="circle1276"
                  class="gates"
                  style="fill:#504b00"
                  r="10.1"
                  cy="88.290001"
                  cx="408.85999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      61
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="88.290001"
                  cx="408.85999"
                />
                <text
                  id="text1278"
                  y="93"
                  x="401.5"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                >
                  61
                </text>
              </g>
              <g
                id="gate_61_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[61]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[61])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 61),
                  opacity4:
                    isDefinedTogglePointGate1(61) ||
                    isDefinedTogglePointGate2(61)
                }"
                @mouseover="searchAcPlanets(61)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 61)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="88.290001"
                  cx="408.85999"
                />
                <circle
                  id="circle1276"
                  class="gates"
                  style="fill:#504b00"
                  r="10.1"
                  cy="88.290001"
                  cx="408.85999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      61
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="88.290001"
                  cx="408.85999"
                />
                <text
                  id="text1278"
                  y="93"
                  x="401.5"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                >
                  61
                </text>
              </g>
              <g
                id="gate_61_both_on"
                v-if="chartBodygraphConnection.gatesExt[61]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[61])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    61
                  )
                }"
                @mouseover="searchAcPlanets(61)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 61)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="88.290001"
                  cx="408.85999"
                />
                <circle
                  id="circle1276"
                  class="gates"
                  style="fill:#504b00"
                  r="10.1"
                  cy="88.290001"
                  cx="408.85999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      61
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="88.290001"
                  cx="408.85999"
                />
                <text
                  id="text1278"
                  y="93"
                  x="401.5"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                >
                  61
                </text>
              </g>
              <g
                id="gate_60_on"
                v-if="chartBodygraphChart.gatesExt[60]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[60])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 60),
                  opacity4:
                    isDefinedTogglePointGate1(60) ||
                    isDefinedTogglePointGate2(60)
                }"
                @mouseover="searchAcPlanets(60)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 60)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="644.69"
                  cx="410.26001"
                />
                <circle
                  id="circle1281"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="644.69"
                  cx="410.26001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      60
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="644.69"
                  cx="410.26001"
                />
                <text
                  id="text1283"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="403"
                  y="649.02551"
                >
                  60
                </text>
              </g>
              <g
                id="gate_60_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[60]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[60])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 60),
                  opacity4:
                    isDefinedTogglePointGate1(60) ||
                    isDefinedTogglePointGate2(60)
                }"
                @mouseover="searchAcPlanets(60)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 60)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="644.69"
                  cx="410.26001"
                />
                <circle
                  id="circle1281"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="644.69"
                  cx="410.26001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      60
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="644.69"
                  cx="410.26001"
                />
                <text
                  id="text1283"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="403"
                  y="649.02551"
                >
                  60
                </text>
              </g>
              <g
                id="gate_60_both_on"
                v-if="chartBodygraphConnection.gatesExt[60]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[60])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    60
                  )
                }"
                @mouseover="searchAcPlanets(60)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 60)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="644.69"
                  cx="410.26001"
                />
                <circle
                  id="circle1281"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="644.69"
                  cx="410.26001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      60
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="644.69"
                  cx="410.26001"
                />
                <text
                  id="text1283"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="403"
                  y="649.02551"
                >
                  60
                </text>
              </g>
              <g
                id="gate_59_on"
                v-if="chartBodygraphChart.gatesExt[59]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[59])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 59),
                  opacity4:
                    isDefinedTogglePointGate1(59) ||
                    isDefinedTogglePointGate2(59)
                }"
                @mouseover="searchAcPlanets(59)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 59)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="590.39001"
                  cx="445.06"
                />
                <circle
                  id="circle1286"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="590.39001"
                  cx="445.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      59
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="590.39001"
                  cx="445.06"
                />
                <text
                  id="text1288"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="437.5"
                  y="594.72552"
                >
                  59
                </text>
              </g>
              <g
                id="gate_59_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[59]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[59])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 59),
                  opacity4:
                    isDefinedTogglePointGate1(59) ||
                    isDefinedTogglePointGate2(59)
                }"
                @mouseover="searchAcPlanets(59)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 59)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="590.39001"
                  cx="445.06"
                />
                <circle
                  id="circle1286"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="590.39001"
                  cx="445.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      59
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="590.39001"
                  cx="445.06"
                />
                <text
                  id="text1288"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="437.5"
                  y="594.72552"
                >
                  59
                </text>
              </g>
              <g
                id="gate_59_both_on"
                v-if="chartBodygraphConnection.gatesExt[59]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[59])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    59
                  )
                }"
                @mouseover="searchAcPlanets(59)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 59)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="590.39001"
                  cx="445.06"
                />
                <circle
                  id="circle1286"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="590.39001"
                  cx="445.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      59
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="590.39001"
                  cx="445.06"
                />
                <text
                  id="text1288"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="437.5"
                  y="594.72552"
                >
                  59
                </text>
              </g>
              <g
                id="gate_58_on"
                v-if="chartBodygraphChart.gatesExt[58]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[58])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 58),
                  opacity4:
                    isDefinedTogglePointGate1(58) ||
                    isDefinedTogglePointGate2(58)
                }"
                @mouseover="searchAcPlanets(58)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 58)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="704.98999"
                  cx="374.35999"
                />
                <circle
                  id="circle1291"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="704.98999"
                  cx="374.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      58
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="704.98999"
                  cx="374.35999"
                />
                <text
                  id="text1293"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="367"
                  y="709.3255"
                >
                  58
                </text>
              </g>
              <g
                id="gate_58_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[58]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[58])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 58),
                  opacity4:
                    isDefinedTogglePointGate1(58) ||
                    isDefinedTogglePointGate2(58)
                }"
                @mouseover="searchAcPlanets(58)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 58)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="704.98999"
                  cx="374.35999"
                />
                <circle
                  id="circle1291"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="704.98999"
                  cx="374.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      58
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="704.98999"
                  cx="374.35999"
                />
                <text
                  id="text1293"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="367"
                  y="709.3255"
                >
                  58
                </text>
              </g>
              <g
                id="gate_58_both_on"
                v-if="chartBodygraphConnection.gatesExt[58]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[58])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    58
                  )
                }"
                @mouseover="searchAcPlanets(58)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 58)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="704.98999"
                  cx="374.35999"
                />
                <circle
                  id="circle1291"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="704.98999"
                  cx="374.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      58
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="704.98999"
                  cx="374.35999"
                />
                <text
                  id="text1293"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="367"
                  y="709.3255"
                >
                  58
                </text>
              </g>
              <g
                id="gate_57_on"
                v-if="chartBodygraphChart.gatesExt[57]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[57])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 57),
                  opacity4:
                    isDefinedTogglePointGate1(57) ||
                    isDefinedTogglePointGate2(57)
                }"
                @mouseover="searchAcPlanets(57)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 57)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="532.19"
                  cx="234.06"
                />
                <circle
                  id="circle1296"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="532.19"
                  cx="234.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      57
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="532.19"
                  cx="234.06"
                />
                <text
                  id="text1298"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="226.7"
                  y="536.45215"
                >
                  57
                </text>
              </g>
              <g
                id="gate_57_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[57]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[57])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 57),
                  opacity4:
                    isDefinedTogglePointGate1(57) ||
                    isDefinedTogglePointGate2(57)
                }"
                @mouseover="searchAcPlanets(57)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 57)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="532.19"
                  cx="234.06"
                />
                <circle
                  id="circle1296"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="532.19"
                  cx="234.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      57
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="532.19"
                  cx="234.06"
                />
                <text
                  id="text1298"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="226.7"
                  y="536.45215"
                >
                  57
                </text>
              </g>
              <g
                id="gate_57_both_on"
                v-if="chartBodygraphConnection.gatesExt[57]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[57])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    57
                  )
                }"
                @mouseover="searchAcPlanets(57)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 57)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="532.19"
                  cx="234.06"
                />
                <circle
                  id="circle1296"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="532.19"
                  cx="234.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      57
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="532.19"
                  cx="234.06"
                />
                <text
                  id="text1298"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="226.7"
                  y="536.45215"
                >
                  57
                </text>
              </g>
              <g
                id="gate_56_on"
                v-if="chartBodygraphChart.gatesExt[56]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[56])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 56),
                  opacity4:
                    isDefinedTogglePointGate1(56) ||
                    isDefinedTogglePointGate2(56)
                }"
                @mouseover="searchAcPlanets(56)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 56)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="243.59"
                  cx="429.06"
                />
                <circle
                  id="circle1301"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="243.59"
                  cx="429.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      56
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="243.59"
                  cx="429.06"
                />
                <text
                  id="text1303"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="421.8"
                  y="247.91882"
                >
                  56
                </text>
              </g>
              <g
                id="gate_56_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[56]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[56])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 56),
                  opacity4:
                    isDefinedTogglePointGate1(56) ||
                    isDefinedTogglePointGate2(56)
                }"
                @mouseover="searchAcPlanets(56)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 56)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="243.59"
                  cx="429.06"
                />
                <circle
                  id="circle1301"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="243.59"
                  cx="429.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      56
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="243.59"
                  cx="429.06"
                />
                <text
                  id="text1303"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="421.8"
                  y="247.91882"
                >
                  56
                </text>
              </g>
              <g
                id="gate_56_both_on"
                v-if="chartBodygraphConnection.gatesExt[56]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[56])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    56
                  )
                }"
                @mouseover="searchAcPlanets(56)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 56)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="243.59"
                  cx="429.06"
                />
                <circle
                  id="circle1301"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="243.59"
                  cx="429.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      56
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="243.59"
                  cx="429.06"
                />
                <text
                  id="text1303"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="421.8"
                  y="247.91882"
                >
                  56
                </text>
              </g>
              <g
                id="gate_55_on"
                v-if="chartBodygraphChart.gatesExt[55]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[55])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 55),
                  opacity4:
                    isDefinedTogglePointGate1(55) ||
                    isDefinedTogglePointGate2(55)
                }"
                @mouseover="searchAcPlanets(55)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 55)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="584.28998"
                  cx="582.15997"
                />
                <circle
                  id="circle1306"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="584.28998"
                  cx="582.15997"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      55
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="584.28998"
                  cx="582.15997"
                />
                <text
                  id="text1308"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="574.7"
                  y="588.55212"
                >
                  55
                </text>
              </g>
              <g
                id="gate_55_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[55]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[55])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 55),
                  opacity4:
                    isDefinedTogglePointGate1(55) ||
                    isDefinedTogglePointGate2(55)
                }"
                @mouseover="searchAcPlanets(55)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 55)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="584.28998"
                  cx="582.15997"
                />
                <circle
                  id="circle1306"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="584.28998"
                  cx="582.15997"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      55
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="584.28998"
                  cx="582.15997"
                />
                <text
                  id="text1308"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="574.7"
                  y="588.55212"
                >
                  55
                </text>
              </g>
              <g
                id="gate_55_both_on"
                v-if="chartBodygraphConnection.gatesExt[55]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[55])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    55
                  )
                }"
                @mouseover="searchAcPlanets(55)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 55)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="584.28998"
                  cx="582.15997"
                />
                <circle
                  id="circle1306"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="584.28998"
                  cx="582.15997"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      55
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="584.28998"
                  cx="582.15997"
                />
                <text
                  id="text1308"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="574.7"
                  y="588.55212"
                >
                  55
                </text>
              </g>
              <g
                id="gate_54_on"
                v-if="chartBodygraphChart.gatesExt[54]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[54])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 54),
                  opacity4:
                    isDefinedTogglePointGate1(54) ||
                    isDefinedTogglePointGate2(54)
                }"
                @mouseover="searchAcPlanets(54)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 54)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="661.69"
                  cx="374.76001"
                />
                <circle
                  id="circle1311"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="661.69"
                  cx="374.76001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      54
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="661.69"
                  cx="374.76001"
                />
                <text
                  id="text1313"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="367"
                  y="665.95215"
                >
                  54
                </text>
              </g>
              <g
                id="gate_54_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[54]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[54])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 54),
                  opacity4:
                    isDefinedTogglePointGate1(54) ||
                    isDefinedTogglePointGate2(54)
                }"
                @mouseover="searchAcPlanets(54)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 54)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="661.69"
                  cx="374.76001"
                />
                <circle
                  id="circle1311"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="661.69"
                  cx="374.76001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      54
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="661.69"
                  cx="374.76001"
                />
                <text
                  id="text1313"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="367"
                  y="665.95215"
                >
                  54
                </text>
              </g>
              <g
                id="gate_54_both_on"
                v-if="chartBodygraphConnection.gatesExt[54]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[54])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    54
                  )
                }"
                @mouseover="searchAcPlanets(54)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 54)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="661.69"
                  cx="374.76001"
                />
                <circle
                  id="circle1311"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="661.69"
                  cx="374.76001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      54
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="661.69"
                  cx="374.76001"
                />
                <text
                  id="text1313"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="367"
                  y="665.95215"
                >
                  54
                </text>
              </g>
              <g
                id="gate_53_on"
                v-if="chartBodygraphChart.gatesExt[53]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[53])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 53),
                  opacity4:
                    isDefinedTogglePointGate1(53) ||
                    isDefinedTogglePointGate2(53)
                }"
                @mouseover="searchAcPlanets(53)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 53)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="645.09003"
                  cx="388.26001"
                />
                <circle
                  id="circle1316"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="645.09003"
                  cx="388.26001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      53
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="645.09003"
                  cx="388.26001"
                />
                <text
                  id="text1318"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="381"
                  y="649.42554"
                >
                  53
                </text>
              </g>
              <g
                id="gate_53_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[53]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[53])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 53),
                  opacity4:
                    isDefinedTogglePointGate1(53) ||
                    isDefinedTogglePointGate2(53)
                }"
                @mouseover="searchAcPlanets(53)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 53)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="645.09003"
                  cx="388.26001"
                />
                <circle
                  id="circle1316"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="645.09003"
                  cx="388.26001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      53
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="645.09003"
                  cx="388.26001"
                />
                <text
                  id="text1318"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="381"
                  y="649.42554"
                >
                  53
                </text>
              </g>
              <g
                id="gate_53_both_on"
                v-if="chartBodygraphConnection.gatesExt[53]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[53])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    53
                  )
                }"
                @mouseover="searchAcPlanets(53)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 53)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="645.09003"
                  cx="388.26001"
                />
                <circle
                  id="circle1316"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="645.09003"
                  cx="388.26001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      53
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="645.09003"
                  cx="388.26001"
                />
                <text
                  id="text1318"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="381"
                  y="649.42554"
                >
                  53
                </text>
              </g>
              <g
                id="gate_52_on"
                v-if="chartBodygraphChart.gatesExt[52]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[52])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 52),
                  opacity4:
                    isDefinedTogglePointGate1(52) ||
                    isDefinedTogglePointGate2(52)
                }"
                @mouseover="searchAcPlanets(52)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 52)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="645.28998"
                  cx="431.26001"
                />
                <circle
                  id="circle1321"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="645.28998"
                  cx="431.26001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      52
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="645.28998"
                  cx="431.26001"
                />
                <text
                  id="text1323"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="423.5"
                  y="649.62549"
                >
                  52
                </text>
              </g>
              <g
                id="gate_52_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[52]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[52])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 52),
                  opacity4:
                    isDefinedTogglePointGate1(52) ||
                    isDefinedTogglePointGate2(52)
                }"
                @mouseover="searchAcPlanets(52)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 52)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="645.28998"
                  cx="431.26001"
                />
                <circle
                  id="circle1321"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="645.28998"
                  cx="431.26001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      52
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="645.28998"
                  cx="431.26001"
                />
                <text
                  id="text1323"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="423.5"
                  y="649.62549"
                >
                  52
                </text>
              </g>
              <g
                id="gate_52_both_on"
                v-if="chartBodygraphConnection.gatesExt[52]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[52])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    52
                  )
                }"
                @mouseover="searchAcPlanets(52)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 52)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="645.28998"
                  cx="431.26001"
                />
                <circle
                  id="circle1321"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="645.28998"
                  cx="431.26001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      52
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="645.28998"
                  cx="431.26001"
                />
                <text
                  id="text1323"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="423.5"
                  y="649.62549"
                >
                  52
                </text>
              </g>
              <g
                id="gate_51_on"
                v-if="chartBodygraphChart.gatesExt[51]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[51])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 51),
                  opacity4:
                    isDefinedTogglePointGate1(51) ||
                    isDefinedTogglePointGate2(51)
                }"
                @mouseover="searchAcPlanets(51)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 51)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="449.98999"
                  cx="493.95999"
                />
                <circle
                  id="circle1326"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="449.98999"
                  cx="493.95999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      51
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="449.98999"
                  cx="493.95999"
                />
                <text
                  id="text1328"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="487.3"
                  y="454.25211"
                >
                  51
                </text>
              </g>
              <g
                id="gate_51_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[51]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[51])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 51),
                  opacity4:
                    isDefinedTogglePointGate1(51) ||
                    isDefinedTogglePointGate2(51)
                }"
                @mouseover="searchAcPlanets(51)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 51)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="449.98999"
                  cx="493.95999"
                />
                <circle
                  id="circle1326"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="449.98999"
                  cx="493.95999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      51
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="449.98999"
                  cx="493.95999"
                />
                <text
                  id="text1328"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="487.3"
                  y="454.25211"
                >
                  51
                </text>
              </g>
              <g
                id="gate_51_both_on"
                v-if="chartBodygraphConnection.gatesExt[51]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[51])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    51
                  )
                }"
                @mouseover="searchAcPlanets(51)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 51)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="449.98999"
                  cx="493.95999"
                />
                <circle
                  id="circle1326"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="449.98999"
                  cx="493.95999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      51
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="449.98999"
                  cx="493.95999"
                />
                <text
                  id="text1328"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="487.3"
                  y="454.25211"
                >
                  51
                </text>
              </g>
              <g
                id="gate_50_on"
                v-if="chartBodygraphChart.gatesExt[50]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[50])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 50),
                  opacity4:
                    isDefinedTogglePointGate1(50) ||
                    isDefinedTogglePointGate2(50)
                }"
                @mouseover="searchAcPlanets(50)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 50)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="558.59003"
                  cx="279.06"
                />
                <circle
                  id="circle1331"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="558.59003"
                  cx="279.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      50
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="558.59003"
                  cx="279.06"
                />
                <text
                  id="text1333"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="271.5"
                  y="562.92554"
                >
                  50
                </text>
              </g>
              <g
                id="gate_50_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[50]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[50])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 50),
                  opacity4:
                    isDefinedTogglePointGate1(50) ||
                    isDefinedTogglePointGate2(50)
                }"
                @mouseover="searchAcPlanets(50)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 50)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="558.59003"
                  cx="279.06"
                />
                <circle
                  id="circle1331"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="558.59003"
                  cx="279.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      50
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="558.59003"
                  cx="279.06"
                />
                <text
                  id="text1333"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="271.5"
                  y="562.92554"
                >
                  50
                </text>
              </g>
              <g
                id="gate_50_both_on"
                v-if="chartBodygraphConnection.gatesExt[50]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[50])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    50
                  )
                }"
                @mouseover="searchAcPlanets(50)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 50)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="558.59003"
                  cx="279.06"
                />
                <circle
                  id="circle1331"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="558.59003"
                  cx="279.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      50
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="558.59003"
                  cx="279.06"
                />
                <text
                  id="text1333"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="271.5"
                  y="562.92554"
                >
                  50
                </text>
              </g>
              <g
                id="gate_49_on"
                v-if="chartBodygraphChart.gatesExt[49]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[49])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 49),
                  opacity4:
                    isDefinedTogglePointGate1(49) ||
                    isDefinedTogglePointGate2(49)
                }"
                @mouseover="searchAcPlanets(49)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 49)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="573.09003"
                  cx="562.85999"
                />
                <circle
                  id="circle1336"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="573.09003"
                  cx="562.85999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      49
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="573.09003"
                  cx="562.85999"
                />
                <text
                  id="text1338"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="555.4"
                  y="577.43127"
                >
                  49
                </text>
              </g>
              <g
                id="gate_49_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[49]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[49])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 49),
                  opacity4:
                    isDefinedTogglePointGate1(49) ||
                    isDefinedTogglePointGate2(49)
                }"
                @mouseover="searchAcPlanets(49)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 49)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="573.09003"
                  cx="562.85999"
                />
                <circle
                  id="circle1336"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="573.09003"
                  cx="562.85999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      49
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="573.09003"
                  cx="562.85999"
                />
                <text
                  id="text1338"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="555.4"
                  y="577.43127"
                >
                  49
                </text>
              </g>
              <g
                id="gate_49_both_on"
                v-if="chartBodygraphConnection.gatesExt[49]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[49])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    49
                  )
                }"
                @mouseover="searchAcPlanets(49)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 49)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="573.09003"
                  cx="562.85999"
                />
                <circle
                  id="circle1336"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="573.09003"
                  cx="562.85999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      49
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="573.09003"
                  cx="562.85999"
                />
                <text
                  id="text1338"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="555.4"
                  y="577.43127"
                >
                  49
                </text>
              </g>
              <g
                id="gate_48_on"
                v-if="chartBodygraphChart.gatesExt[48]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[48])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 48),
                  opacity4:
                    isDefinedTogglePointGate1(48) ||
                    isDefinedTogglePointGate2(48)
                }"
                @mouseover="searchAcPlanets(48)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 48)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="521.59003"
                  cx="215.06"
                />
                <circle
                  id="circle1341"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="521.59003"
                  cx="215.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      48
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="521.59003"
                  cx="215.06"
                />
                <text
                  id="text1343"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="207.7"
                  y="525.92554"
                >
                  48
                </text>
              </g>
              <g
                id="gate_48_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[48]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[48])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 48),
                  opacity4:
                    isDefinedTogglePointGate1(48) ||
                    isDefinedTogglePointGate2(48)
                }"
                @mouseover="searchAcPlanets(48)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 48)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="521.59003"
                  cx="215.06"
                />
                <circle
                  id="circle1341"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="521.59003"
                  cx="215.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      48
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="521.59003"
                  cx="215.06"
                />
                <text
                  id="text1343"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="207.7"
                  y="525.92554"
                >
                  48
                </text>
              </g>
              <g
                id="gate_48_both_on"
                v-if="chartBodygraphConnection.gatesExt[48]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[48])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    48
                  )
                }"
                @mouseover="searchAcPlanets(48)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 48)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="521.59003"
                  cx="215.06"
                />
                <circle
                  id="circle1341"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="521.59003"
                  cx="215.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      48
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="521.59003"
                  cx="215.06"
                />
                <text
                  id="text1343"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="207.7"
                  y="525.92554"
                >
                  48
                </text>
              </g>
              <g
                id="gate_47_on"
                v-if="chartBodygraphChart.gatesExt[47]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[47])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 47),
                  opacity4:
                    isDefinedTogglePointGate1(47) ||
                    isDefinedTogglePointGate2(47)
                }"
                @mouseover="searchAcPlanets(47)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 47)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="123.79"
                  cx="387.56"
                />
                <circle
                  id="circle1346"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="123.79"
                  cx="387.56"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      47
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="123.79"
                  cx="387.56"
                />
                <text
                  id="text1348"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="379.7"
                  y="128"
                >
                  47
                </text>
              </g>
              <g
                id="gate_47_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[47]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[47])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 47),
                  opacity4:
                    isDefinedTogglePointGate1(47) ||
                    isDefinedTogglePointGate2(47)
                }"
                @mouseover="searchAcPlanets(47)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 47)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="123.79"
                  cx="387.56"
                />
                <circle
                  id="circle1346"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="123.79"
                  cx="387.56"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      47
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="123.79"
                  cx="387.56"
                />
                <text
                  id="text1348"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="379.7"
                  y="128"
                >
                  47
                </text>
              </g>
              <g
                id="gate_47_both_on"
                v-if="chartBodygraphConnection.gatesExt[47]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[47])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    47
                  )
                }"
                @mouseover="searchAcPlanets(47)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 47)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="123.79"
                  cx="387.56"
                />
                <circle
                  id="circle1346"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="123.79"
                  cx="387.56"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      47
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="123.79"
                  cx="387.56"
                />
                <text
                  id="text1348"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="379.7"
                  y="128"
                >
                  47
                </text>
              </g>
              <g
                id="gate_46_on"
                v-if="chartBodygraphChart.gatesExt[46]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[46])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 46),
                  opacity4:
                    isDefinedTogglePointGate1(46) ||
                    isDefinedTogglePointGate2(46)
                }"
                @mouseover="searchAcPlanets(46)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 46)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="426.19"
                  cx="429.16"
                />
                <circle
                  id="circle1351"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="426.19"
                  cx="429.16"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      46
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="426.19"
                  cx="429.16"
                />
                <text
                  id="text1353"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="421.7"
                  y="430.51883"
                >
                  46
                </text>
              </g>
              <g
                id="gate_46_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[46]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[46])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 46),
                  opacity4:
                    isDefinedTogglePointGate1(46) ||
                    isDefinedTogglePointGate2(46)
                }"
                @mouseover="searchAcPlanets(46)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 46)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="426.19"
                  cx="429.16"
                />
                <circle
                  id="circle1351"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="426.19"
                  cx="429.16"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      46
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="426.19"
                  cx="429.16"
                />
                <text
                  id="text1353"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="421.7"
                  y="430.51883"
                >
                  46
                </text>
              </g>
              <g
                id="gate_46_both_on"
                v-if="chartBodygraphConnection.gatesExt[46]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[46])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    46
                  )
                }"
                @mouseover="searchAcPlanets(46)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 46)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="426.19"
                  cx="429.16"
                />
                <circle
                  id="circle1351"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="426.19"
                  cx="429.16"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      46
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="426.19"
                  cx="429.16"
                />
                <text
                  id="text1353"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="421.7"
                  y="430.51883"
                >
                  46
                </text>
              </g>
              <g
                id="gate_45_on"
                v-if="chartBodygraphChart.gatesExt[45]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[45])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 45),
                  opacity4:
                    isDefinedTogglePointGate1(45) ||
                    isDefinedTogglePointGate2(45)
                }"
                @mouseover="searchAcPlanets(45)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 45)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="302.98999"
                  cx="442.35999"
                />
                <circle
                  id="circle1356"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="302.98999"
                  cx="442.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      45
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="302.98999"
                  cx="442.35999"
                />
                <text
                  id="text1358"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="435"
                  y="307.25211"
                >
                  45
                </text>
              </g>
              <g
                id="gate_45_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[45]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[45])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 45),
                  opacity4:
                    isDefinedTogglePointGate1(45) ||
                    isDefinedTogglePointGate2(45)
                }"
                @mouseover="searchAcPlanets(45)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 45)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="302.98999"
                  cx="442.35999"
                />
                <circle
                  id="circle1356"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="302.98999"
                  cx="442.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      45
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="302.98999"
                  cx="442.35999"
                />
                <text
                  id="text1358"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="435"
                  y="307.25211"
                >
                  45
                </text>
              </g>
              <g
                id="gate_45_both_on"
                v-if="chartBodygraphConnection.gatesExt[45]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[45])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    45
                  )
                }"
                @mouseover="searchAcPlanets(45)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 45)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="302.98999"
                  cx="442.35999"
                />
                <circle
                  id="circle1356"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="302.98999"
                  cx="442.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      45
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="302.98999"
                  cx="442.35999"
                />
                <text
                  id="text1358"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="435"
                  y="307.25211"
                >
                  45
                </text>
              </g>
              <g
                id="gate_44_on"
                v-if="chartBodygraphChart.gatesExt[44]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[44])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 44),
                  opacity4:
                    isDefinedTogglePointGate1(44) ||
                    isDefinedTogglePointGate2(44)
                }"
                @mouseover="searchAcPlanets(44)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 44)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="545.19"
                  cx="256.35999"
                />
                <circle
                  id="circle1361"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="545.19"
                  cx="256.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      44
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="545.19"
                  cx="256.35999"
                />
                <text
                  id="text1363"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="248.5"
                  y="549.52551"
                >
                  44
                </text>
              </g>
              <g
                id="gate_44_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[44]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[44])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 44),
                  opacity4:
                    isDefinedTogglePointGate1(44) ||
                    isDefinedTogglePointGate2(44)
                }"
                @mouseover="searchAcPlanets(44)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 44)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="545.19"
                  cx="256.35999"
                />
                <circle
                  id="circle1361"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="545.19"
                  cx="256.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      44
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="545.19"
                  cx="256.35999"
                />
                <text
                  id="text1363"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="248.5"
                  y="549.52551"
                >
                  44
                </text>
              </g>
              <g
                id="gate_44_both_on"
                v-if="chartBodygraphConnection.gatesExt[44]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[44])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    44
                  )
                }"
                @mouseover="searchAcPlanets(44)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 44)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="545.19"
                  cx="256.35999"
                />
                <circle
                  id="circle1361"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="545.19"
                  cx="256.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      44
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="545.19"
                  cx="256.35999"
                />
                <text
                  id="text1363"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="248.5"
                  y="549.52551"
                >
                  44
                </text>
              </g>
              <g
                id="gate_43_on"
                v-if="chartBodygraphChart.gatesExt[43]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[43])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 43),
                  opacity4:
                    isDefinedTogglePointGate1(43) ||
                    isDefinedTogglePointGate2(43)
                }"
                @mouseover="searchAcPlanets(43)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 43)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="190.78999"
                  cx="408.45999"
                />
                <circle
                  id="circle1366"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="190.78999"
                  cx="408.45999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      43
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="190.78999"
                  cx="408.45999"
                />
                <text
                  id="text1368"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="401"
                  y="195"
                >
                  43
                </text>
              </g>
              <g
                id="gate_43_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[43]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[43])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 43),
                  opacity4:
                    isDefinedTogglePointGate1(43) ||
                    isDefinedTogglePointGate2(43)
                }"
                @mouseover="searchAcPlanets(43)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 43)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="190.78999"
                  cx="408.45999"
                />
                <circle
                  id="circle1366"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="190.78999"
                  cx="408.45999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      43
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="190.78999"
                  cx="408.45999"
                />
                <text
                  id="text1368"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="401"
                  y="195"
                >
                  43
                </text>
              </g>
              <g
                id="gate_43_both_on"
                v-if="chartBodygraphConnection.gatesExt[43]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[43])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    43
                  )
                }"
                @mouseover="searchAcPlanets(43)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 43)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="190.78999"
                  cx="408.45999"
                />
                <circle
                  id="circle1366"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="190.78999"
                  cx="408.45999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      43
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="190.78999"
                  cx="408.45999"
                />
                <text
                  id="text1368"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="401"
                  y="195"
                >
                  43
                </text>
              </g>
              <g
                id="gate_42_on"
                v-if="chartBodygraphChart.gatesExt[42]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[42])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 42),
                  opacity4:
                    isDefinedTogglePointGate1(42) ||
                    isDefinedTogglePointGate2(42)
                }"
                @mouseover="searchAcPlanets(42)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 42)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="611.39001"
                  cx="387.26001"
                />
                <circle
                  id="circle1371"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="611.39001"
                  cx="387.26001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      42
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="611.39001"
                  cx="387.26001"
                />
                <text
                  id="text1373"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="379.6"
                  y="615.79889"
                >
                  42
                </text>
              </g>
              <g
                id="gate_42_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[42]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[42])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 42),
                  opacity4:
                    isDefinedTogglePointGate1(42) ||
                    isDefinedTogglePointGate2(42)
                }"
                @mouseover="searchAcPlanets(42)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 42)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="611.39001"
                  cx="387.26001"
                />
                <circle
                  id="circle1371"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="611.39001"
                  cx="387.26001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      42
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="611.39001"
                  cx="387.26001"
                />
                <text
                  id="text1373"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="379.6"
                  y="615.79889"
                >
                  42
                </text>
              </g>
              <g
                id="gate_42_both_on"
                v-if="chartBodygraphConnection.gatesExt[42]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[42])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    42
                  )
                }"
                @mouseover="searchAcPlanets(42)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 42)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="611.39001"
                  cx="387.26001"
                />
                <circle
                  id="circle1371"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="611.39001"
                  cx="387.26001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      42
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="611.39001"
                  cx="387.26001"
                />
                <text
                  id="text1373"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="379.6"
                  y="615.79889"
                >
                  42
                </text>
              </g>
              <g
                id="gate_41_on"
                v-if="chartBodygraphChart.gatesExt[41]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[41])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 41),
                  opacity4:
                    isDefinedTogglePointGate1(41) ||
                    isDefinedTogglePointGate2(41)
                }"
                @mouseover="searchAcPlanets(41)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 41)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="705.59003"
                  cx="443.35999"
                />
                <circle
                  id="circle1376"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="705.59003"
                  cx="443.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      41
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="705.59003"
                  cx="443.35999"
                />
                <text
                  id="text1378"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="436"
                  y="709.92554"
                >
                  41
                </text>
              </g>
              <g
                id="gate_41_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[41]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[41])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 41),
                  opacity4:
                    isDefinedTogglePointGate1(41) ||
                    isDefinedTogglePointGate2(41)
                }"
                @mouseover="searchAcPlanets(41)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 41)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="705.59003"
                  cx="443.35999"
                />
                <circle
                  id="circle1376"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="705.59003"
                  cx="443.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      41
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="705.59003"
                  cx="443.35999"
                />
                <text
                  id="text1378"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="436"
                  y="709.92554"
                >
                  41
                </text>
              </g>
              <g
                id="gate_41_both_on"
                v-if="chartBodygraphConnection.gatesExt[41]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[41])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    41
                  )
                }"
                @mouseover="searchAcPlanets(41)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 41)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="705.59003"
                  cx="443.35999"
                />
                <circle
                  id="circle1376"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="705.59003"
                  cx="443.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      41
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="705.59003"
                  cx="443.35999"
                />
                <text
                  id="text1378"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="436"
                  y="709.92554"
                >
                  41
                </text>
              </g>
              <g
                id="gate_40_on"
                v-if="chartBodygraphChart.gatesExt[40]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[40])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 40),
                  opacity4:
                    isDefinedTogglePointGate1(40) ||
                    isDefinedTogglePointGate2(40)
                }"
                @mouseover="searchAcPlanets(40)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 40)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="484.79001"
                  cx="541.96002"
                />
                <circle
                  id="circle1381"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="484.79001"
                  cx="541.96002"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      40
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="484.79001"
                  cx="541.96002"
                />
                <text
                  id="text1383"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="534"
                  y="489.12552"
                >
                  40
                </text>
              </g>
              <g
                id="gate_40_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[40]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[40])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 40),
                  opacity4:
                    isDefinedTogglePointGate1(40) ||
                    isDefinedTogglePointGate2(40)
                }"
                @mouseover="searchAcPlanets(40)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 40)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="484.79001"
                  cx="541.96002"
                />
                <circle
                  id="circle1381"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="484.79001"
                  cx="541.96002"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      40
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="484.79001"
                  cx="541.96002"
                />
                <text
                  id="text1383"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="534"
                  y="489.12552"
                >
                  40
                </text>
              </g>
              <g
                id="gate_40_both_on"
                v-if="chartBodygraphConnection.gatesExt[40]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[40])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    40
                  )
                }"
                @mouseover="searchAcPlanets(40)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 40)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="484.79001"
                  cx="541.96002"
                />
                <circle
                  id="circle1381"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="484.79001"
                  cx="541.96002"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      40
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="484.79001"
                  cx="541.96002"
                />
                <text
                  id="text1383"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="534"
                  y="489.12552"
                >
                  40
                </text>
              </g>
              <g
                id="gate_39_on"
                v-if="chartBodygraphChart.gatesExt[39]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[39])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 39),
                  opacity4:
                    isDefinedTogglePointGate1(39) ||
                    isDefinedTogglePointGate2(39)
                }"
                @mouseover="searchAcPlanets(39)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 39)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="682.89001"
                  cx="443.66"
                />
                <circle
                  id="circle1386"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="682.89001"
                  cx="443.66"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      39
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="682.89001"
                  cx="443.66"
                />
                <text
                  id="text1388"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="436"
                  y="687.22552"
                >
                  39
                </text>
              </g>
              <g
                id="gate_39_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[39]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[39])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 39),
                  opacity4:
                    isDefinedTogglePointGate1(39) ||
                    isDefinedTogglePointGate2(39)
                }"
                @mouseover="searchAcPlanets(39)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 39)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="682.89001"
                  cx="443.66"
                />
                <circle
                  id="circle1386"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="682.89001"
                  cx="443.66"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      39
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="682.89001"
                  cx="443.66"
                />
                <text
                  id="text1388"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="436"
                  y="687.22552"
                >
                  39
                </text>
              </g>
              <g
                id="gate_39_both_on"
                v-if="chartBodygraphConnection.gatesExt[39]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[39])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    39
                  )
                }"
                @mouseover="searchAcPlanets(39)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 39)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="682.89001"
                  cx="443.66"
                />
                <circle
                  id="circle1386"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="682.89001"
                  cx="443.66"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      39
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="682.89001"
                  cx="443.66"
                />
                <text
                  id="text1388"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="436"
                  y="687.22552"
                >
                  39
                </text>
              </g>
              <g
                id="gate_38_on"
                v-if="chartBodygraphChart.gatesExt[38]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[38])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 38),
                  opacity4:
                    isDefinedTogglePointGate1(38) ||
                    isDefinedTogglePointGate2(38)
                }"
                @mouseover="searchAcPlanets(38)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 38)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="682.19"
                  cx="374.66"
                />
                <circle
                  id="circle1391"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="682.19"
                  cx="374.66"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      38
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="682.19"
                  cx="374.66"
                />
                <text
                  id="text1393"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="367.2"
                  y="686.52551"
                >
                  38
                </text>
              </g>
              <g
                id="gate_38_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[38]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[38])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 38),
                  opacity4:
                    isDefinedTogglePointGate1(38) ||
                    isDefinedTogglePointGate2(38)
                }"
                @mouseover="searchAcPlanets(38)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 38)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="682.19"
                  cx="374.66"
                />
                <circle
                  id="circle1391"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="682.19"
                  cx="374.66"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      38
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="682.19"
                  cx="374.66"
                />
                <text
                  id="text1393"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="367.2"
                  y="686.52551"
                >
                  38
                </text>
              </g>
              <g
                id="gate_38_both_on"
                v-if="chartBodygraphConnection.gatesExt[38]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[38])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    38
                  )
                }"
                @mouseover="searchAcPlanets(38)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 38)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="682.19"
                  cx="374.66"
                />
                <circle
                  id="circle1391"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="682.19"
                  cx="374.66"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      38
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="682.19"
                  cx="374.66"
                />
                <text
                  id="text1393"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="367.2"
                  y="686.52551"
                >
                  38
                </text>
              </g>
              <g
                id="gate_37_on"
                v-if="chartBodygraphChart.gatesExt[37]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[37])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 37),
                  opacity4:
                    isDefinedTogglePointGate1(37) ||
                    isDefinedTogglePointGate2(37)
                }"
                @mouseover="searchAcPlanets(37)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 37)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="547.09003"
                  cx="558.85999"
                />
                <circle
                  id="circle1396"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="547.09003"
                  cx="558.85999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      37
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="547.09003"
                  cx="558.85999"
                />
                <text
                  id="text1398"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="551.5"
                  y="551.42554"
                >
                  37
                </text>
              </g>
              <g
                id="gate_37_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[37]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[37])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 37),
                  opacity4:
                    isDefinedTogglePointGate1(37) ||
                    isDefinedTogglePointGate2(37)
                }"
                @mouseover="searchAcPlanets(37)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 37)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="547.09003"
                  cx="558.85999"
                />
                <circle
                  id="circle1396"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="547.09003"
                  cx="558.85999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      37
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="547.09003"
                  cx="558.85999"
                />
                <text
                  id="text1398"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="551.5"
                  y="551.42554"
                >
                  37
                </text>
              </g>
              <g
                id="gate_37_both_on"
                v-if="chartBodygraphConnection.gatesExt[37]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[37])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    37
                  )
                }"
                @mouseover="searchAcPlanets(37)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 37)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="547.09003"
                  cx="558.85999"
                />
                <circle
                  id="circle1396"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="547.09003"
                  cx="558.85999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      37
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="547.09003"
                  cx="558.85999"
                />
                <text
                  id="text1398"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="551.5"
                  y="551.42554"
                >
                  37
                </text>
              </g>
              <g
                id="gate_36_on"
                v-if="chartBodygraphChart.gatesExt[36]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[36])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 36),
                  opacity4:
                    isDefinedTogglePointGate1(36) ||
                    isDefinedTogglePointGate2(36)
                }"
                @mouseover="searchAcPlanets(36)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 36)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="523.09003"
                  cx="600.65997"
                />
                <circle
                  id="circle1401"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="523.09003"
                  cx="600.65997"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      36
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="523.09003"
                  cx="600.65997"
                />
                <text
                  id="text1403"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="593.75201"
                  y="527.42554"
                >
                  36
                </text>
              </g>
              <g
                id="gate_36_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[36]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[36])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 36),
                  opacity4:
                    isDefinedTogglePointGate1(36) ||
                    isDefinedTogglePointGate2(36)
                }"
                @mouseover="searchAcPlanets(36)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 36)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="523.09003"
                  cx="600.65997"
                />
                <circle
                  id="circle1401"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="523.09003"
                  cx="600.65997"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      36
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="523.09003"
                  cx="600.65997"
                />
                <text
                  id="text1403"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="593.75201"
                  y="527.42554"
                >
                  36
                </text>
              </g>
              <g
                id="gate_36_both_on"
                v-if="chartBodygraphConnection.gatesExt[36]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[36])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    36
                  )
                }"
                @mouseover="searchAcPlanets(36)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 36)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="523.09003"
                  cx="600.65997"
                />
                <circle
                  id="circle1401"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="523.09003"
                  cx="600.65997"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      36
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="523.09003"
                  cx="600.65997"
                />
                <text
                  id="text1403"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="593.75201"
                  y="527.42554"
                >
                  36
                </text>
              </g>
              <g
                id="gate_35_on"
                v-if="chartBodygraphChart.gatesExt[35]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[35])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 35),
                  opacity4:
                    isDefinedTogglePointGate1(35) ||
                    isDefinedTogglePointGate2(35)
                }"
                @mouseover="searchAcPlanets(35)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 35)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="263.98999"
                  cx="447.35999"
                />
                <circle
                  id="circle1406"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="263.98999"
                  cx="447.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      35
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="263.98999"
                  cx="447.35999"
                />
                <text
                  id="text1408"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="440.67883"
                  y="268.3255"
                >
                  35
                </text>
              </g>
              <g
                id="gate_35_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[35]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[35])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 35),
                  opacity4:
                    isDefinedTogglePointGate1(35) ||
                    isDefinedTogglePointGate2(35)
                }"
                @mouseover="searchAcPlanets(35)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 35)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="263.98999"
                  cx="447.35999"
                />
                <circle
                  id="circle1406"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="263.98999"
                  cx="447.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      35
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="263.98999"
                  cx="447.35999"
                />
                <text
                  id="text1408"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="440.67883"
                  y="268.3255"
                >
                  35
                </text>
              </g>
              <g
                id="gate_35_both_on"
                v-if="chartBodygraphConnection.gatesExt[35]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[35])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    35
                  )
                }"
                @mouseover="searchAcPlanets(35)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 35)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="263.98999"
                  cx="447.35999"
                />
                <circle
                  id="circle1406"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="263.98999"
                  cx="447.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      35
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="263.98999"
                  cx="447.35999"
                />
                <text
                  id="text1408"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="440.67883"
                  y="268.3255"
                >
                  35
                </text>
              </g>
              <g
                id="gate_34_on"
                v-if="chartBodygraphChart.gatesExt[34]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[34])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 34),
                  opacity4:
                    isDefinedTogglePointGate1(34) ||
                    isDefinedTogglePointGate2(34)
                }"
                @mouseover="searchAcPlanets(34)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 34)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="558.09003"
                  cx="370.06"
                />
                <circle
                  id="circle1411"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="558.09003"
                  cx="370.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      34
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="558.09003"
                  cx="370.06"
                />
                <text
                  id="text1413"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="362.5"
                  y="562.42554"
                >
                  34
                </text>
              </g>
              <g
                id="gate_34_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[34]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[34])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 34),
                  opacity4:
                    isDefinedTogglePointGate1(34) ||
                    isDefinedTogglePointGate2(34)
                }"
                @mouseover="searchAcPlanets(34)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 34)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="558.09003"
                  cx="370.06"
                />
                <circle
                  id="circle1411"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="558.09003"
                  cx="370.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      34
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="558.09003"
                  cx="370.06"
                />
                <text
                  id="text1413"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="362.5"
                  y="562.42554"
                >
                  34
                </text>
              </g>
              <g
                id="gate_34_both_on"
                v-if="chartBodygraphConnection.gatesExt[34]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[34])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    34
                  )
                }"
                @mouseover="searchAcPlanets(34)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 34)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="558.09003"
                  cx="370.06"
                />
                <circle
                  id="circle1411"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="558.09003"
                  cx="370.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      34
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="558.09003"
                  cx="370.06"
                />
                <text
                  id="text1413"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="362.5"
                  y="562.42554"
                >
                  34
                </text>
              </g>
              <g
                id="gate_33_on"
                v-if="chartBodygraphChart.gatesExt[33]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[33])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 33),
                  opacity4:
                    isDefinedTogglePointGate1(33) ||
                    isDefinedTogglePointGate2(33)
                }"
                @mouseover="searchAcPlanets(33)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 33)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="315.98999"
                  cx="428.06"
                />
                <circle
                  id="circle1416"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="315.98999"
                  cx="428.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      33
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="315.98999"
                  cx="428.06"
                />
                <text
                  id="text1418"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="421"
                  y="320.3255"
                >
                  33
                </text>
              </g>
              <g
                id="gate_33_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[33]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[33])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 33),
                  opacity4:
                    isDefinedTogglePointGate1(33) ||
                    isDefinedTogglePointGate2(33)
                }"
                @mouseover="searchAcPlanets(33)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 33)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="315.98999"
                  cx="428.06"
                />
                <circle
                  id="circle1416"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="315.98999"
                  cx="428.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      33
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="315.98999"
                  cx="428.06"
                />
                <text
                  id="text1418"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="421"
                  y="320.3255"
                >
                  33
                </text>
              </g>
              <g
                id="gate_33_both_on"
                v-if="chartBodygraphConnection.gatesExt[33]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[33])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    33
                  )
                }"
                @mouseover="searchAcPlanets(33)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 33)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="315.98999"
                  cx="428.06"
                />
                <circle
                  id="circle1416"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="315.98999"
                  cx="428.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      33
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="315.98999"
                  cx="428.06"
                />
                <text
                  id="text1418"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="421"
                  y="320.3255"
                >
                  33
                </text>
              </g>
              <g
                id="gate_32_on"
                v-if="chartBodygraphChart.gatesExt[32]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[32])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 32),
                  opacity4:
                    isDefinedTogglePointGate1(32) ||
                    isDefinedTogglePointGate2(32)
                }"
                @mouseover="searchAcPlanets(32)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 32)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="572.19"
                  cx="256.35999"
                />
                <circle
                  id="circle1421"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="572.19"
                  cx="256.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      32
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="572.19"
                  cx="256.35999"
                />
                <text
                  id="text1423"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="249"
                  y="576.52551"
                >
                  32
                </text>
              </g>
              <g
                id="gate_32_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[32]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[32])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 32),
                  opacity4:
                    isDefinedTogglePointGate1(32) ||
                    isDefinedTogglePointGate2(32)
                }"
                @mouseover="searchAcPlanets(32)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 32)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="572.19"
                  cx="256.35999"
                />
                <circle
                  id="circle1421"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="572.19"
                  cx="256.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      32
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="572.19"
                  cx="256.35999"
                />
                <text
                  id="text1423"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="249"
                  y="576.52551"
                >
                  32
                </text>
              </g>
              <g
                id="gate_32_both_on"
                v-if="chartBodygraphConnection.gatesExt[32]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[32])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    32
                  )
                }"
                @mouseover="searchAcPlanets(32)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 32)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="572.19"
                  cx="256.35999"
                />
                <circle
                  id="circle1421"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="572.19"
                  cx="256.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      32
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="572.19"
                  cx="256.35999"
                />
                <text
                  id="text1423"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="249"
                  y="576.52551"
                >
                  32
                </text>
              </g>
              <g
                id="gate_31_on"
                v-if="chartBodygraphChart.gatesExt[31]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[31])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 31),
                  opacity4:
                    isDefinedTogglePointGate1(31) ||
                    isDefinedTogglePointGate2(31)
                }"
                @mouseover="searchAcPlanets(31)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 31)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="315.79001"
                  cx="388.95999"
                />
                <circle
                  id="circle1426"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="315.79001"
                  cx="388.95999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      31
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="315.79001"
                  cx="388.95999"
                />
                <text
                  id="text1428"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="382"
                  y="320.5"
                >
                  31
                </text>
              </g>
              <g
                id="gate_31_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[31]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[31])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 31),
                  opacity4:
                    isDefinedTogglePointGate1(31) ||
                    isDefinedTogglePointGate2(31)
                }"
                @mouseover="searchAcPlanets(31)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 31)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="315.79001"
                  cx="388.95999"
                />
                <circle
                  id="circle1426"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="315.79001"
                  cx="388.95999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      31
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="315.79001"
                  cx="388.95999"
                />
                <text
                  id="text1428"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="382"
                  y="320.5"
                >
                  31
                </text>
              </g>
              <g
                id="gate_31_both_on"
                v-if="chartBodygraphConnection.gatesExt[31]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[31])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    31
                  )
                }"
                @mouseover="searchAcPlanets(31)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 31)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="315.79001"
                  cx="388.95999"
                />
                <circle
                  id="circle1426"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="315.79001"
                  cx="388.95999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      31
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="315.79001"
                  cx="388.95999"
                />
                <text
                  id="text1428"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="382"
                  y="320.5"
                >
                  31
                </text>
              </g>
              <g
                id="gate_30_on"
                v-if="chartBodygraphChart.gatesExt[30]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[30])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 30),
                  opacity4:
                    isDefinedTogglePointGate1(30) ||
                    isDefinedTogglePointGate2(30)
                }"
                @mouseover="searchAcPlanets(30)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 30)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="596.09003"
                  cx="603.35999"
                />
                <circle
                  id="circle1431"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="596.09003"
                  cx="603.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      30
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="596.09003"
                  cx="603.35999"
                />
                <text
                  id="text1433"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="596"
                  y="600.42554"
                >
                  30
                </text>
              </g>
              <g
                id="gate_30_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[30]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[30])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 30),
                  opacity4:
                    isDefinedTogglePointGate1(30) ||
                    isDefinedTogglePointGate2(30)
                }"
                @mouseover="searchAcPlanets(30)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 30)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="596.09003"
                  cx="603.35999"
                />
                <circle
                  id="circle1431"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="596.09003"
                  cx="603.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      30
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="596.09003"
                  cx="603.35999"
                />
                <text
                  id="text1433"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="596"
                  y="600.42554"
                >
                  30
                </text>
              </g>
              <g
                id="gate_30_both_on"
                v-if="chartBodygraphConnection.gatesExt[30]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[30])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    30
                  )
                }"
                @mouseover="searchAcPlanets(30)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 30)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="596.09003"
                  cx="603.35999"
                />
                <circle
                  id="circle1431"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="596.09003"
                  cx="603.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      30
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="596.09003"
                  cx="603.35999"
                />
                <text
                  id="text1433"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="596"
                  y="600.42554"
                >
                  30
                </text>
              </g>
              <g
                id="gate_29_on"
                v-if="chartBodygraphChart.gatesExt[29]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[29])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 29),
                  opacity4:
                    isDefinedTogglePointGate1(29) ||
                    isDefinedTogglePointGate2(29)
                }"
                @mouseover="searchAcPlanets(29)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 29)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="530.89001"
                  cx="430.06"
                />
                <circle
                  id="circle1436"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="530.89001"
                  cx="430.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      29
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="530.89001"
                  cx="430.06"
                />
                <text
                  id="text1438"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="422.7"
                  y="535.23126"
                >
                  29
                </text>
              </g>
              <g
                id="gate_29_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[29]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[29])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 29),
                  opacity4:
                    isDefinedTogglePointGate1(29) ||
                    isDefinedTogglePointGate2(29)
                }"
                @mouseover="searchAcPlanets(29)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 29)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="530.89001"
                  cx="430.06"
                />
                <circle
                  id="circle1436"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="530.89001"
                  cx="430.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      29
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="530.89001"
                  cx="430.06"
                />
                <text
                  id="text1438"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="422.7"
                  y="535.23126"
                >
                  29
                </text>
              </g>
              <g
                id="gate_29_both_on"
                v-if="chartBodygraphConnection.gatesExt[29]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[29])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    29
                  )
                }"
                @mouseover="searchAcPlanets(29)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 29)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="530.89001"
                  cx="430.06"
                />
                <circle
                  id="circle1436"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="530.89001"
                  cx="430.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      29
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="530.89001"
                  cx="430.06"
                />
                <text
                  id="text1438"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="422.7"
                  y="535.23126"
                >
                  29
                </text>
              </g>
              <g
                id="gate_28_on"
                v-if="chartBodygraphChart.gatesExt[28]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[28])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 28),
                  opacity4:
                    isDefinedTogglePointGate1(28) ||
                    isDefinedTogglePointGate2(28)
                }"
                @mouseover="searchAcPlanets(28)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 28)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="583.89001"
                  cx="234.66"
                />
                <circle
                  id="circle1441"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="583.89001"
                  cx="234.66"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      28
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="583.89001"
                  cx="234.66"
                />
                <text
                  id="text1443"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="227"
                  y="588.22552"
                >
                  28
                </text>
              </g>
              <g
                id="gate_28_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[28]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[28])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 28),
                  opacity4:
                    isDefinedTogglePointGate1(28) ||
                    isDefinedTogglePointGate2(28)
                }"
                @mouseover="searchAcPlanets(28)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 28)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="583.89001"
                  cx="234.66"
                />
                <circle
                  id="circle1441"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="583.89001"
                  cx="234.66"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      28
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="583.89001"
                  cx="234.66"
                />
                <text
                  id="text1443"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="227"
                  y="588.22552"
                >
                  28
                </text>
              </g>
              <g
                id="gate_28_both_on"
                v-if="chartBodygraphConnection.gatesExt[28]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[28])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    28
                  )
                }"
                @mouseover="searchAcPlanets(28)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 28)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="583.89001"
                  cx="234.66"
                />
                <circle
                  id="circle1441"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="583.89001"
                  cx="234.66"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      28
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="583.89001"
                  cx="234.66"
                />
                <text
                  id="text1443"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="227"
                  y="588.22552"
                >
                  28
                </text>
              </g>
              <g
                id="gate_27_on"
                v-if="chartBodygraphChart.gatesExt[27]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[27])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 27),
                  opacity4:
                    isDefinedTogglePointGate1(27) ||
                    isDefinedTogglePointGate2(27)
                }"
                @mouseover="searchAcPlanets(27)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 27)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="590.89001"
                  cx="375.06"
                />
                <circle
                  id="circle1446"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="590.89001"
                  cx="375.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      27
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="590.89001"
                  cx="375.06"
                />
                <text
                  id="text1448"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="368.21878"
                  y="595.29889"
                >
                  27
                </text>
              </g>
              <g
                id="gate_27_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[27]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[27])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 27),
                  opacity4:
                    isDefinedTogglePointGate1(27) ||
                    isDefinedTogglePointGate2(27)
                }"
                @mouseover="searchAcPlanets(27)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 27)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="590.89001"
                  cx="375.06"
                />
                <circle
                  id="circle1446"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="590.89001"
                  cx="375.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      27
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="590.89001"
                  cx="375.06"
                />
                <text
                  id="text1448"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="368.21878"
                  y="595.29889"
                >
                  27
                </text>
              </g>
              <g
                id="gate_27_both_on"
                v-if="chartBodygraphConnection.gatesExt[27]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[27])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    27
                  )
                }"
                @mouseover="searchAcPlanets(27)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 27)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="590.89001"
                  cx="375.06"
                />
                <circle
                  id="circle1446"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="590.89001"
                  cx="375.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      27
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="590.89001"
                  cx="375.06"
                />
                <text
                  id="text1448"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="368.21878"
                  y="595.29889"
                >
                  27
                </text>
              </g>
              <g
                id="gate_26_on"
                v-if="chartBodygraphChart.gatesExt[26]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[26])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 26),
                  opacity4:
                    isDefinedTogglePointGate1(26) ||
                    isDefinedTogglePointGate2(26)
                }"
                @mouseover="searchAcPlanets(26)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 26)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="467.48999"
                  cx="470.45999"
                />
                <circle
                  id="circle1451"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="467.48999"
                  cx="470.45999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      26
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="467.48999"
                  cx="470.45999"
                />
                <text
                  id="text1453"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="463"
                  y="471.8255"
                >
                  26
                </text>
              </g>
              <g
                id="gate_26_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[26]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[26])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 26),
                  opacity4:
                    isDefinedTogglePointGate1(26) ||
                    isDefinedTogglePointGate2(26)
                }"
                @mouseover="searchAcPlanets(26)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 26)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="467.48999"
                  cx="470.45999"
                />
                <circle
                  id="circle1451"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="467.48999"
                  cx="470.45999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      26
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="467.48999"
                  cx="470.45999"
                />
                <text
                  id="text1453"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="463"
                  y="471.8255"
                >
                  26
                </text>
              </g>
              <g
                id="gate_26_both_on"
                v-if="chartBodygraphConnection.gatesExt[26]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[26])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    26
                  )
                }"
                @mouseover="searchAcPlanets(26)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 26)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="467.48999"
                  cx="470.45999"
                />
                <circle
                  id="circle1451"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="467.48999"
                  cx="470.45999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      26
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="467.48999"
                  cx="470.45999"
                />
                <text
                  id="text1453"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="463"
                  y="471.8255"
                >
                  26
                </text>
              </g>
              <g
                id="gate_25_on"
                v-if="chartBodygraphChart.gatesExt[25]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[25])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 25),
                  opacity4:
                    isDefinedTogglePointGate1(25) ||
                    isDefinedTogglePointGate2(25)
                }"
                @mouseover="searchAcPlanets(25)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 25)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="401.98999"
                  cx="452.45999"
                />
                <circle
                  id="circle1456"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="401.98999"
                  cx="452.45999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      25
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="401.98999"
                  cx="452.45999"
                />
                <text
                  id="text1458"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="445"
                  y="406.3255"
                >
                  25
                </text>
              </g>
              <g
                id="gate_25_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[25]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[25])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 25),
                  opacity4:
                    isDefinedTogglePointGate1(25) ||
                    isDefinedTogglePointGate2(25)
                }"
                @mouseover="searchAcPlanets(25)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 25)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="401.98999"
                  cx="452.45999"
                />
                <circle
                  id="circle1456"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="401.98999"
                  cx="452.45999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      25
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="401.98999"
                  cx="452.45999"
                />
                <text
                  id="text1458"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="445"
                  y="406.3255"
                >
                  25
                </text>
              </g>
              <g
                id="gate_25_both_on"
                v-if="chartBodygraphConnection.gatesExt[25]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[25])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    25
                  )
                }"
                @mouseover="searchAcPlanets(25)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 25)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="401.98999"
                  cx="452.45999"
                />
                <circle
                  id="circle1456"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="401.98999"
                  cx="452.45999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      25
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="401.98999"
                  cx="452.45999"
                />
                <text
                  id="text1458"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="445"
                  y="406.3255"
                >
                  25
                </text>
              </g>
              <g
                id="gate_24_on"
                v-if="chartBodygraphChart.gatesExt[24]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[24])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 24),
                  opacity4:
                    isDefinedTogglePointGate1(24) ||
                    isDefinedTogglePointGate2(24)
                }"
                @mouseover="searchAcPlanets(24)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 24)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="123.59"
                  cx="409.56"
                />
                <circle
                  id="circle1461"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="123.59"
                  cx="409.56"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      24
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="123.59"
                  cx="409.56"
                />
                <text
                  id="text1463"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="402.3"
                  y="128"
                >
                  24
                </text>
              </g>
              <g
                id="gate_24_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[24]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[24])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 24),
                  opacity4:
                    isDefinedTogglePointGate1(24) ||
                    isDefinedTogglePointGate2(24)
                }"
                @mouseover="searchAcPlanets(24)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 24)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="123.59"
                  cx="409.56"
                />
                <circle
                  id="circle1461"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="123.59"
                  cx="409.56"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      24
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="123.59"
                  cx="409.56"
                />
                <text
                  id="text1463"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="402.3"
                  y="128"
                >
                  24
                </text>
              </g>
              <g
                id="gate_24_both_on"
                v-if="chartBodygraphConnection.gatesExt[24]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[24])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    24
                  )
                }"
                @mouseover="searchAcPlanets(24)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 24)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="123.59"
                  cx="409.56"
                />
                <circle
                  id="circle1461"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="123.59"
                  cx="409.56"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      24
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="123.59"
                  cx="409.56"
                />
                <text
                  id="text1463"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="402.3"
                  y="128"
                >
                  24
                </text>
              </g>
              <g
                id="gate_23_on"
                v-if="chartBodygraphChart.gatesExt[23]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[23])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 23),
                  opacity4:
                    isDefinedTogglePointGate1(23) ||
                    isDefinedTogglePointGate2(23)
                }"
                @mouseover="searchAcPlanets(23)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 23)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="238.69"
                  cx="408.66"
                />
                <circle
                  id="circle1466"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="238.69"
                  cx="408.66"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      23
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="238.69"
                  cx="408.66"
                />
                <text
                  id="text1468"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="401.4"
                  y="243.0255"
                >
                  23
                </text>
              </g>
              <g
                id="gate_23_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[23]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[23])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 23),
                  opacity4:
                    isDefinedTogglePointGate1(23) ||
                    isDefinedTogglePointGate2(23)
                }"
                @mouseover="searchAcPlanets(23)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 23)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="238.69"
                  cx="408.66"
                />
                <circle
                  id="circle1466"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="238.69"
                  cx="408.66"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      23
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="238.69"
                  cx="408.66"
                />
                <text
                  id="text1468"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="401.4"
                  y="243.0255"
                >
                  23
                </text>
              </g>
              <g
                id="gate_23_both_on"
                v-if="chartBodygraphConnection.gatesExt[23]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[23])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    23
                  )
                }"
                @mouseover="searchAcPlanets(23)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 23)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="238.69"
                  cx="408.66"
                />
                <circle
                  id="circle1466"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="238.69"
                  cx="408.66"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      23
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="238.69"
                  cx="408.66"
                />
                <text
                  id="text1468"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="401.4"
                  y="243.0255"
                >
                  23
                </text>
              </g>
              <g
                id="gate_22_on"
                v-if="chartBodygraphChart.gatesExt[22]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[22])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 22),
                  opacity4:
                    isDefinedTogglePointGate1(22) ||
                    isDefinedTogglePointGate2(22)
                }"
                @mouseover="searchAcPlanets(22)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 22)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="534.28998"
                  cx="580.35999"
                />
                <circle
                  id="circle1471"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="534.28998"
                  cx="580.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      22
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="534.28998"
                  cx="580.35999"
                />
                <text
                  id="text1473"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="573"
                  y="538.69885"
                >
                  22
                </text>
              </g>
              <g
                id="gate_22_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[22]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[22])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 22),
                  opacity4:
                    isDefinedTogglePointGate1(22) ||
                    isDefinedTogglePointGate2(22)
                }"
                @mouseover="searchAcPlanets(22)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 22)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="534.28998"
                  cx="580.35999"
                />
                <circle
                  id="circle1471"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="534.28998"
                  cx="580.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      22
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="534.28998"
                  cx="580.35999"
                />
                <text
                  id="text1473"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="573"
                  y="538.69885"
                >
                  22
                </text>
              </g>
              <g
                id="gate_22_both_on"
                v-if="chartBodygraphConnection.gatesExt[22]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[22])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    22
                  )
                }"
                @mouseover="searchAcPlanets(22)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 22)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="534.28998"
                  cx="580.35999"
                />
                <circle
                  id="circle1471"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="534.28998"
                  cx="580.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      22
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="534.28998"
                  cx="580.35999"
                />
                <text
                  id="text1473"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="573"
                  y="538.69885"
                >
                  22
                </text>
              </g>
              <g
                id="gate_21_on"
                v-if="chartBodygraphChart.gatesExt[21]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[21])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 21),
                  opacity4:
                    isDefinedTogglePointGate1(21) ||
                    isDefinedTogglePointGate2(21)
                }"
                @mouseover="searchAcPlanets(21)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 21)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="434.39001"
                  cx="515.06"
                />
                <circle
                  id="circle1476"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="434.39001"
                  cx="515.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      21
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="434.39001"
                  cx="515.06"
                />
                <text
                  id="text1478"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="508.6"
                  y="438.79889"
                >
                  21
                </text>
              </g>
              <g
                id="gate_21_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[21]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[21])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 21),
                  opacity4:
                    isDefinedTogglePointGate1(21) ||
                    isDefinedTogglePointGate2(21)
                }"
                @mouseover="searchAcPlanets(21)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 21)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="434.39001"
                  cx="515.06"
                />
                <circle
                  id="circle1476"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="434.39001"
                  cx="515.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      21
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="434.39001"
                  cx="515.06"
                />
                <text
                  id="text1478"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="508.6"
                  y="438.79889"
                >
                  21
                </text>
              </g>
              <g
                id="gate_21_both_on"
                v-if="chartBodygraphConnection.gatesExt[21]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[21])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    21
                  )
                }"
                @mouseover="searchAcPlanets(21)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 21)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="434.39001"
                  cx="515.06"
                />
                <circle
                  id="circle1476"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="434.39001"
                  cx="515.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      21
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="434.39001"
                  cx="515.06"
                />
                <text
                  id="text1478"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="508.6"
                  y="438.79889"
                >
                  21
                </text>
              </g>
              <g
                id="gate_20_on"
                v-if="chartBodygraphChart.gatesExt[20]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[20])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 20),
                  opacity4:
                    isDefinedTogglePointGate1(20) ||
                    isDefinedTogglePointGate2(20)
                }"
                @mouseover="searchAcPlanets(20)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 20)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="286.48999"
                  cx="368.56"
                />
                <circle
                  id="circle1481"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="286.48999"
                  cx="368.56"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      20
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="286.48999"
                  cx="368.56"
                />
                <text
                  id="text1483"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="361.2"
                  y="290.8255"
                >
                  20
                </text>
              </g>
              <g
                id="gate_20_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[20]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[20])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 20),
                  opacity4:
                    isDefinedTogglePointGate1(20) ||
                    isDefinedTogglePointGate2(20)
                }"
                @mouseover="searchAcPlanets(20)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 20)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="286.48999"
                  cx="368.56"
                />
                <circle
                  id="circle1481"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="286.48999"
                  cx="368.56"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      20
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="286.48999"
                  cx="368.56"
                />
                <text
                  id="text1483"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="361.2"
                  y="290.8255"
                >
                  20
                </text>
              </g>
              <g
                id="gate_20_both_on"
                v-if="chartBodygraphConnection.gatesExt[20]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[20])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    20
                  )
                }"
                @mouseover="searchAcPlanets(20)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 20)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="286.48999"
                  cx="368.56"
                />
                <circle
                  id="circle1481"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="286.48999"
                  cx="368.56"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      20
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="286.48999"
                  cx="368.56"
                />
                <text
                  id="text1483"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="361.2"
                  y="290.8255"
                >
                  20
                </text>
              </g>
              <g
                id="gate_19_on"
                v-if="chartBodygraphChart.gatesExt[19]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[19])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 19),
                  opacity4:
                    isDefinedTogglePointGate1(19) ||
                    isDefinedTogglePointGate2(19)
                }"
                @mouseover="searchAcPlanets(19)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 19)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="662.39001"
                  cx="443.76001"
                />
                <circle
                  id="circle1486"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="662.39001"
                  cx="443.76001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      19
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="662.39001"
                  cx="443.76001"
                />
                <text
                  id="text1488"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="435.5"
                  y="666.73126"
                >
                  19
                </text>
              </g>
              <g
                id="gate_19_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[19]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[19])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 19),
                  opacity4:
                    isDefinedTogglePointGate1(19) ||
                    isDefinedTogglePointGate2(19)
                }"
                @mouseover="searchAcPlanets(19)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 19)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="662.39001"
                  cx="443.76001"
                />
                <circle
                  id="circle1486"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="662.39001"
                  cx="443.76001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      19
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="662.39001"
                  cx="443.76001"
                />
                <text
                  id="text1488"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="435.5"
                  y="666.73126"
                >
                  19
                </text>
              </g>
              <g
                id="gate_19_both_on"
                v-if="chartBodygraphConnection.gatesExt[19]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[19])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    19
                  )
                }"
                @mouseover="searchAcPlanets(19)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 19)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="662.39001"
                  cx="443.76001"
                />
                <circle
                  id="circle1486"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="662.39001"
                  cx="443.76001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      19
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="662.39001"
                  cx="443.76001"
                />
                <text
                  id="text1488"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="435.5"
                  y="666.73126"
                >
                  19
                </text>
              </g>
              <g
                id="gate_18_on"
                v-if="chartBodygraphChart.gatesExt[18]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[18])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 18),
                  opacity4:
                    isDefinedTogglePointGate1(18) ||
                    isDefinedTogglePointGate2(18)
                }"
                @mouseover="searchAcPlanets(18)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 18)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="595.19"
                  cx="215.36"
                />
                <circle
                  id="circle1491"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="595.19"
                  cx="215.36"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      18
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="595.19"
                  cx="215.36"
                />
                <text
                  id="text1493"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="207.5"
                  y="599.52551"
                >
                  18
                </text>
              </g>
              <g
                id="gate_18_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[18]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[18])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 18),
                  opacity4:
                    isDefinedTogglePointGate1(18) ||
                    isDefinedTogglePointGate2(18)
                }"
                @mouseover="searchAcPlanets(18)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 18)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="595.19"
                  cx="215.36"
                />
                <circle
                  id="circle1491"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="595.19"
                  cx="215.36"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      18
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="595.19"
                  cx="215.36"
                />
                <text
                  id="text1493"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="207.5"
                  y="599.52551"
                >
                  18
                </text>
              </g>
              <g
                id="gate_18_both_on"
                v-if="chartBodygraphConnection.gatesExt[18]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[18])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    18
                  )
                }"
                @mouseover="searchAcPlanets(18)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 18)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="595.19"
                  cx="215.36"
                />
                <circle
                  id="circle1491"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="595.19"
                  cx="215.36"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      18
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="595.19"
                  cx="215.36"
                />
                <text
                  id="text1493"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="207.5"
                  y="599.52551"
                >
                  18
                </text>
              </g>
              <g
                id="gate_17_on"
                v-if="chartBodygraphChart.gatesExt[17]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[17])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 17),
                  opacity4:
                    isDefinedTogglePointGate1(17) ||
                    isDefinedTogglePointGate2(17)
                }"
                @mouseover="searchAcPlanets(17)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 17)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="159.69"
                  cx="389.06"
                />
                <circle
                  id="circle1496"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="159.69"
                  cx="389.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      17
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="159.69"
                  cx="389.06"
                />
                <text
                  id="text1498"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="381.4"
                  y="164"
                >
                  17
                </text>
              </g>
              <g
                id="gate_17_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[17]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[17])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 17),
                  opacity4:
                    isDefinedTogglePointGate1(17) ||
                    isDefinedTogglePointGate2(17)
                }"
                @mouseover="searchAcPlanets(17)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 17)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="159.69"
                  cx="389.06"
                />
                <circle
                  id="circle1496"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="159.69"
                  cx="389.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      17
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="159.69"
                  cx="389.06"
                />
                <text
                  id="text1498"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="381.4"
                  y="164"
                >
                  17
                </text>
              </g>
              <g
                id="gate_17_both_on"
                v-if="chartBodygraphConnection.gatesExt[17]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[17])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    17
                  )
                }"
                @mouseover="searchAcPlanets(17)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 17)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="159.69"
                  cx="389.06"
                />
                <circle
                  id="circle1496"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="159.69"
                  cx="389.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      17
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="159.69"
                  cx="389.06"
                />
                <text
                  id="text1498"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="381.4"
                  y="164"
                >
                  17
                </text>
              </g>
              <g
                id="gate_16_on"
                v-if="chartBodygraphChart.gatesExt[16]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[16])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 16),
                  opacity4:
                    isDefinedTogglePointGate1(16) ||
                    isDefinedTogglePointGate2(16)
                }"
                @mouseover="searchAcPlanets(16)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 16)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="263.09"
                  cx="371.06"
                />
                <circle
                  id="circle1501"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="263.09"
                  cx="371.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      16
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="263.09"
                  cx="371.06"
                />
                <text
                  id="text1503"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="363.4"
                  y="267.41882"
                >
                  16
                </text>
              </g>
              <g
                id="gate_16_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[16]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[16])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 16),
                  opacity4:
                    isDefinedTogglePointGate1(16) ||
                    isDefinedTogglePointGate2(16)
                }"
                @mouseover="searchAcPlanets(16)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 16)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="263.09"
                  cx="371.06"
                />
                <circle
                  id="circle1501"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="263.09"
                  cx="371.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      16
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="263.09"
                  cx="371.06"
                />
                <text
                  id="text1503"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="363.4"
                  y="267.41882"
                >
                  16
                </text>
              </g>
              <g
                id="gate_16_both_on"
                v-if="chartBodygraphConnection.gatesExt[16]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[16])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    16
                  )
                }"
                @mouseover="searchAcPlanets(16)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 16)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="263.09"
                  cx="371.06"
                />
                <circle
                  id="circle1501"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="263.09"
                  cx="371.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      16
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="263.09"
                  cx="371.06"
                />
                <text
                  id="text1503"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="363.4"
                  y="267.41882"
                >
                  16
                </text>
              </g>
              <g
                id="gate_15_on"
                v-if="chartBodygraphChart.gatesExt[15]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[15])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 15),
                  opacity4:
                    isDefinedTogglePointGate1(15) ||
                    isDefinedTogglePointGate2(15)
                }"
                @mouseover="searchAcPlanets(15)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 15)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="426.48999"
                  cx="388.35999"
                />
                <circle
                  id="circle1506"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="426.48999"
                  cx="388.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      15
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="426.48999"
                  cx="388.35999"
                />
                <text
                  id="text1508"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="380.7"
                  y="430.75211"
                >
                  15
                </text>
              </g>
              <g
                id="gate_15_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[15]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[15])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 15),
                  opacity4:
                    isDefinedTogglePointGate1(15) ||
                    isDefinedTogglePointGate2(15)
                }"
                @mouseover="searchAcPlanets(15)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 15)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="426.48999"
                  cx="388.35999"
                />
                <circle
                  id="circle1506"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="426.48999"
                  cx="388.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      15
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="426.48999"
                  cx="388.35999"
                />
                <text
                  id="text1508"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="380.7"
                  y="430.75211"
                >
                  15
                </text>
              </g>
              <g
                id="gate_15_both_on"
                v-if="chartBodygraphConnection.gatesExt[15]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[15])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    15
                  )
                }"
                @mouseover="searchAcPlanets(15)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 15)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="426.48999"
                  cx="388.35999"
                />
                <circle
                  id="circle1506"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="426.48999"
                  cx="388.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      15
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="426.48999"
                  cx="388.35999"
                />
                <text
                  id="text1508"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="380.7"
                  y="430.75211"
                >
                  15
                </text>
              </g>
              <g
                id="gate_14_on"
                v-if="chartBodygraphChart.gatesExt[14]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[14])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 14),
                  opacity4:
                    isDefinedTogglePointGate1(14) ||
                    isDefinedTogglePointGate2(14)
                }"
                @mouseover="searchAcPlanets(14)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 14)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="530.59003"
                  cx="408.76001"
                />
                <circle
                  id="circle1511"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="530.59003"
                  cx="408.76001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      14
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="530.59003"
                  cx="408.76001"
                />
                <text
                  id="text1513"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="400.7"
                  y="534.92554"
                >
                  14
                </text>
              </g>
              <g
                id="gate_14_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[14]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[14])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 14),
                  opacity4:
                    isDefinedTogglePointGate1(14) ||
                    isDefinedTogglePointGate2(14)
                }"
                @mouseover="searchAcPlanets(14)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 14)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="530.59003"
                  cx="408.76001"
                />
                <circle
                  id="circle1511"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="530.59003"
                  cx="408.76001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      14
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="530.59003"
                  cx="408.76001"
                />
                <text
                  id="text1513"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="400.7"
                  y="534.92554"
                >
                  14
                </text>
              </g>
              <g
                id="gate_14_both_on"
                v-if="chartBodygraphConnection.gatesExt[14]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[14])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    14
                  )
                }"
                @mouseover="searchAcPlanets(14)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 14)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="530.59003"
                  cx="408.76001"
                />
                <circle
                  id="circle1511"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="530.59003"
                  cx="408.76001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      14
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="530.59003"
                  cx="408.76001"
                />
                <text
                  id="text1513"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="400.7"
                  y="534.92554"
                >
                  14
                </text>
              </g>
              <g
                id="gate_13_on"
                v-if="chartBodygraphChart.gatesExt[13]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[13])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 13),
                  opacity4:
                    isDefinedTogglePointGate1(13) ||
                    isDefinedTogglePointGate2(13)
                }"
                @mouseover="searchAcPlanets(13)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 13)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="375.29001"
                  cx="427.85999"
                />
                <circle
                  id="circle1516"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="375.29001"
                  cx="427.85999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      13
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="375.29001"
                  cx="427.85999"
                />
                <text
                  id="text1518"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="420"
                  y="379.62552"
                >
                  13
                </text>
              </g>
              <g
                id="gate_13_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[13]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[13])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 13),
                  opacity4:
                    isDefinedTogglePointGate1(13) ||
                    isDefinedTogglePointGate2(13)
                }"
                @mouseover="searchAcPlanets(13)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 13)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="375.29001"
                  cx="427.85999"
                />
                <circle
                  id="circle1516"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="375.29001"
                  cx="427.85999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      13
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="375.29001"
                  cx="427.85999"
                />
                <text
                  id="text1518"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="420"
                  y="379.62552"
                >
                  13
                </text>
              </g>
              <g
                id="gate_13_both_on"
                v-if="chartBodygraphConnection.gatesExt[13]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[13])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    13
                  )
                }"
                @mouseover="searchAcPlanets(13)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 13)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="375.29001"
                  cx="427.85999"
                />
                <circle
                  id="circle1516"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="375.29001"
                  cx="427.85999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      13
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="375.29001"
                  cx="427.85999"
                />
                <text
                  id="text1518"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="420"
                  y="379.62552"
                >
                  13
                </text>
              </g>
              <g
                id="gate_12_on"
                v-if="chartBodygraphChart.gatesExt[12]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[12])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 12),
                  opacity4:
                    isDefinedTogglePointGate1(12) ||
                    isDefinedTogglePointGate2(12)
                }"
                @mouseover="searchAcPlanets(12)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 12)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="285.29001"
                  cx="449.95999"
                />
                <circle
                  id="circle1521"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="285.29001"
                  cx="449.95999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      12
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="285.29001"
                  cx="449.95999"
                />
                <text
                  id="text1523"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="442.3"
                  y="289.69888"
                >
                  12
                </text>
              </g>
              <g
                id="gate_12_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[12]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[12])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 12),
                  opacity4:
                    isDefinedTogglePointGate1(12) ||
                    isDefinedTogglePointGate2(12)
                }"
                @mouseover="searchAcPlanets(12)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 12)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="285.29001"
                  cx="449.95999"
                />
                <circle
                  id="circle1521"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="285.29001"
                  cx="449.95999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      12
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="285.29001"
                  cx="449.95999"
                />
                <text
                  id="text1523"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="442.3"
                  y="289.69888"
                >
                  12
                </text>
              </g>
              <g
                id="gate_12_both_on"
                v-if="chartBodygraphConnection.gatesExt[12]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[12])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    12
                  )
                }"
                @mouseover="searchAcPlanets(12)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 12)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="285.29001"
                  cx="449.95999"
                />
                <circle
                  id="circle1521"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="285.29001"
                  cx="449.95999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      12
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="285.29001"
                  cx="449.95999"
                />
                <text
                  id="text1523"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="442.3"
                  y="289.69888"
                >
                  12
                </text>
              </g>
              <g
                id="gate_11_on"
                v-if="chartBodygraphChart.gatesExt[11]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[11])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 11),
                  opacity4:
                    isDefinedTogglePointGate1(11) ||
                    isDefinedTogglePointGate2(11)
                }"
                @mouseover="searchAcPlanets(11)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 11)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="158.59"
                  cx="429.56"
                />
                <circle
                  id="circle1526"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="158.59"
                  cx="429.56"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      11
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="158.59"
                  cx="429.56"
                />
                <text
                  id="text1528"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="422.4"
                  y="163"
                >
                  11
                </text>
              </g>
              <g
                id="gate_11_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[11]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[11])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 11),
                  opacity4:
                    isDefinedTogglePointGate1(11) ||
                    isDefinedTogglePointGate2(11)
                }"
                @mouseover="searchAcPlanets(11)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 11)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="158.59"
                  cx="429.56"
                />
                <circle
                  id="circle1526"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="158.59"
                  cx="429.56"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      11
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="158.59"
                  cx="429.56"
                />
                <text
                  id="text1528"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="422.4"
                  y="163"
                >
                  11
                </text>
              </g>
              <g
                id="gate_11_both_on"
                v-if="chartBodygraphConnection.gatesExt[11]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[11])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    11
                  )
                }"
                @mouseover="searchAcPlanets(11)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 11)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="158.59"
                  cx="429.56"
                />
                <circle
                  id="circle1526"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="158.59"
                  cx="429.56"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      11
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="158.59"
                  cx="429.56"
                />
                <text
                  id="text1528"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="422.4"
                  y="163"
                >
                  11
                </text>
              </g>
              <g
                id="gate_10_on"
                v-if="chartBodygraphChart.gatesExt[10]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[10])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 10),
                  opacity4:
                    isDefinedTogglePointGate1(10) ||
                    isDefinedTogglePointGate2(10)
                }"
                @mouseover="searchAcPlanets(10)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 10)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="401.69"
                  cx="365.16"
                />
                <circle
                  id="circle1531"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="401.69"
                  cx="365.16"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      10
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="401.69"
                  cx="365.16"
                />
                <text
                  id="text1533"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="357.3"
                  y="406.02551"
                >
                  10
                </text>
              </g>
              <g
                id="gate_10_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[10]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[10])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 10),
                  opacity4:
                    isDefinedTogglePointGate1(10) ||
                    isDefinedTogglePointGate2(10)
                }"
                @mouseover="searchAcPlanets(10)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 10)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="401.69"
                  cx="365.16"
                />
                <circle
                  id="circle1531"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="401.69"
                  cx="365.16"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      10
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="401.69"
                  cx="365.16"
                />
                <text
                  id="text1533"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="357.3"
                  y="406.02551"
                >
                  10
                </text>
              </g>
              <g
                id="gate_10_both_on"
                v-if="chartBodygraphConnection.gatesExt[10]"
                @click="
                  openTooltipDialog(chartBodygraphConnection.gatesExt[10])
                "
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    10
                  )
                }"
                @mouseover="searchAcPlanets(10)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 10)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="401.69"
                  cx="365.16"
                />
                <circle
                  id="circle1531"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="401.69"
                  cx="365.16"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      10
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="401.69"
                  cx="365.16"
                />
                <text
                  id="text1533"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="357.3"
                  y="406.02551"
                >
                  10
                </text>
              </g>
              <g
                id="gate_09_on"
                v-if="chartBodygraphChart.gatesExt[9]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[9])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 9),
                  opacity4:
                    isDefinedTogglePointGate1(9) || isDefinedTogglePointGate2(9)
                }"
                @mouseover="searchAcPlanets(9)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 9)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="611.89001"
                  cx="429.26001"
                />
                <circle
                  id="circle1536"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="611.89001"
                  cx="429.26001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      9
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="611.89001"
                  cx="429.26001"
                />
                <text
                  id="text1538"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="425.5"
                  y="616.23126"
                >
                  9
                </text>
              </g>
              <g
                id="gate_09_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[9]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[9])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 9),
                  opacity4:
                    isDefinedTogglePointGate1(9) || isDefinedTogglePointGate2(9)
                }"
                @mouseover="searchAcPlanets(9)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 9)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="611.89001"
                  cx="429.26001"
                />
                <circle
                  id="circle1536"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="611.89001"
                  cx="429.26001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      9
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="611.89001"
                  cx="429.26001"
                />
                <text
                  id="text1538"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="425.5"
                  y="616.23126"
                >
                  9
                </text>
              </g>
              <g
                id="gate_09_both_on"
                v-if="chartBodygraphConnection.gatesExt[9]"
                @click="openTooltipDialog(chartBodygraphConnection.gatesExt[9])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    9
                  )
                }"
                @mouseover="searchAcPlanets(9)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 9)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="611.89001"
                  cx="429.26001"
                />
                <circle
                  id="circle1536"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="611.89001"
                  cx="429.26001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      9
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="611.89001"
                  cx="429.26001"
                />
                <text
                  id="text1538"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="425.5"
                  y="616.23126"
                >
                  9
                </text>
              </g>
              <g
                id="gate_08_on"
                v-if="chartBodygraphChart.gatesExt[8]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[8])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 8),
                  opacity4:
                    isDefinedTogglePointGate1(8) || isDefinedTogglePointGate2(8)
                }"
                @mouseover="searchAcPlanets(8)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 8)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="321.19"
                  cx="409.16"
                />
                <circle
                  id="circle1541"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="321.19"
                  cx="409.16"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      8
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="321.19"
                  cx="409.16"
                />
                <text
                  id="text1543"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="405.3"
                  y="325.52551"
                >
                  8
                </text>
              </g>
              <g
                id="gate_08_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[8]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[8])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 8),
                  opacity4:
                    isDefinedTogglePointGate1(8) || isDefinedTogglePointGate2(8)
                }"
                @mouseover="searchAcPlanets(8)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 8)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="321.19"
                  cx="409.16"
                />
                <circle
                  id="circle1541"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="321.19"
                  cx="409.16"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      8
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="321.19"
                  cx="409.16"
                />
                <text
                  id="text1543"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="405.3"
                  y="325.52551"
                >
                  8
                </text>
              </g>
              <g
                id="gate_08_both_on"
                v-if="chartBodygraphConnection.gatesExt[8]"
                @click="openTooltipDialog(chartBodygraphConnection.gatesExt[8])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    8
                  )
                }"
                @mouseover="searchAcPlanets(8)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 8)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="321.19"
                  cx="409.16"
                />
                <circle
                  id="circle1541"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="321.19"
                  cx="409.16"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      8
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="321.19"
                  cx="409.16"
                />
                <text
                  id="text1543"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="405.3"
                  y="325.52551"
                >
                  8
                </text>
              </g>
              <g
                id="gate_07_on"
                v-if="chartBodygraphChart.gatesExt[7]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[7])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 7),
                  opacity4:
                    isDefinedTogglePointGate1(7) || isDefinedTogglePointGate2(7)
                }"
                @mouseover="searchAcPlanets(7)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 7)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="376.19"
                  cx="389.66"
                />
                <circle
                  id="circle1546"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="376.19"
                  cx="389.66"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      7
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="376.19"
                  cx="389.66"
                />
                <text
                  id="text1548"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="386.16492"
                  y="380.52551"
                >
                  7
                </text>
              </g>
              <g
                id="gate_07_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[7]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[7])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 7),
                  opacity4:
                    isDefinedTogglePointGate1(7) || isDefinedTogglePointGate2(7)
                }"
                @mouseover="searchAcPlanets(7)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 7)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="376.19"
                  cx="389.66"
                />
                <circle
                  id="circle1546"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="376.19"
                  cx="389.66"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      7
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="376.19"
                  cx="389.66"
                />
                <text
                  id="text1548"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="386.16492"
                  y="380.52551"
                >
                  7
                </text>
              </g>
              <g
                id="gate_07_both_on"
                v-if="chartBodygraphConnection.gatesExt[7]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[7])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    7
                  )
                }"
                @mouseover="searchAcPlanets(7)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 7)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="376.19"
                  cx="389.66"
                />
                <circle
                  id="circle1546"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="376.19"
                  cx="389.66"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      7
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="376.19"
                  cx="389.66"
                />
                <text
                  id="text1548"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="386.16492"
                  y="380.52551"
                >
                  7
                </text>
              </g>
              <g
                id="gate_06_on"
                v-if="chartBodygraphChart.gatesExt[6]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[6])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 6),
                  opacity4:
                    isDefinedTogglePointGate1(6) || isDefinedTogglePointGate2(6)
                }"
                @mouseover="searchAcPlanets(6)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 6)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="558.59003"
                  cx="538.35999"
                />
                <circle
                  id="circle1551"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="558.59003"
                  cx="538.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      6
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="558.59003"
                  cx="538.35999"
                />
                <text
                  id="text1553"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="534.4"
                  y="562.91888"
                >
                  6
                </text>
              </g>
              <g
                id="gate_06_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[6]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[6])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 6),
                  opacity4:
                    isDefinedTogglePointGate1(6) || isDefinedTogglePointGate2(6)
                }"
                @mouseover="searchAcPlanets(6)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 6)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="558.59003"
                  cx="538.35999"
                />
                <circle
                  id="circle1551"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="558.59003"
                  cx="538.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      6
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="558.59003"
                  cx="538.35999"
                />
                <text
                  id="text1553"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="534.4"
                  y="562.91888"
                >
                  6
                </text>
              </g>
              <g
                id="gate_06_both_on"
                v-if="chartBodygraphConnection.gatesExt[6]"
                @click="openTooltipDialog(chartBodygraphConnection.gatesExt[6])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    6
                  )
                }"
                @mouseover="searchAcPlanets(6)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 6)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="558.59003"
                  cx="538.35999"
                />
                <circle
                  id="circle1551"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="558.59003"
                  cx="538.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      6
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="558.59003"
                  cx="538.35999"
                />
                <text
                  id="text1553"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="534.4"
                  y="562.91888"
                >
                  6
                </text>
              </g>
              <g
                id="gate_05_on"
                v-if="chartBodygraphChart.gatesExt[5]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[5])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 5),
                  opacity4:
                    isDefinedTogglePointGate1(5) || isDefinedTogglePointGate2(5)
                }"
                @mouseover="searchAcPlanets(5)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 5)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="530.89001"
                  cx="388.06"
                />
                <circle
                  id="circle1556"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="530.89001"
                  cx="388.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      5
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="530.89001"
                  cx="388.06"
                />
                <text
                  id="text1558"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="384"
                  y="535.15216"
                >
                  5
                </text>
              </g>
              <g
                id="gate_05_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[5]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[5])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 5),
                  opacity4:
                    isDefinedTogglePointGate1(5) || isDefinedTogglePointGate2(5)
                }"
                @mouseover="searchAcPlanets(5)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 5)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="530.89001"
                  cx="388.06"
                />
                <circle
                  id="circle1556"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="530.89001"
                  cx="388.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      5
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="530.89001"
                  cx="388.06"
                />
                <text
                  id="text1558"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="384"
                  y="535.15216"
                >
                  5
                </text>
              </g>
              <g
                id="gate_05_both_on"
                v-if="chartBodygraphConnection.gatesExt[5]"
                @click="openTooltipDialog(chartBodygraphConnection.gatesExt[5])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    5
                  )
                }"
                @mouseover="searchAcPlanets(5)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 5)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="530.89001"
                  cx="388.06"
                />
                <circle
                  id="circle1556"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="530.89001"
                  cx="388.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      5
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="530.89001"
                  cx="388.06"
                />
                <text
                  id="text1558"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="384"
                  y="535.15216"
                >
                  5
                </text>
              </g>
              <g
                id="gate_04_on"
                v-if="chartBodygraphChart.gatesExt[4]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[4])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 4),
                  opacity4:
                    isDefinedTogglePointGate1(4) || isDefinedTogglePointGate2(4)
                }"
                @mouseover="searchAcPlanets(4)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 4)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="123.79"
                  cx="430.06"
                />
                <circle
                  id="circle1561"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="123.79"
                  cx="430.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      4
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="123.79"
                  cx="430.06"
                />
                <text
                  id="text1563"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="425.7"
                  y="128"
                >
                  4
                </text>
              </g>
              <g
                id="gate_04_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[4]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[4])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 4),
                  opacity4:
                    isDefinedTogglePointGate1(4) || isDefinedTogglePointGate2(4)
                }"
                @mouseover="searchAcPlanets(4)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 4)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="123.79"
                  cx="430.06"
                />
                <circle
                  id="circle1561"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="123.79"
                  cx="430.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      4
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="123.79"
                  cx="430.06"
                />
                <text
                  id="text1563"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="425.7"
                  y="128"
                >
                  4
                </text>
              </g>
              <g
                id="gate_04_both_on"
                v-if="chartBodygraphConnection.gatesExt[4]"
                @click="openTooltipDialog(chartBodygraphConnection.gatesExt[4])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    4
                  )
                }"
                @mouseover="searchAcPlanets(4)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 4)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="123.79"
                  cx="430.06"
                />
                <circle
                  id="circle1561"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="123.79"
                  cx="430.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      4
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="123.79"
                  cx="430.06"
                />
                <text
                  id="text1563"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="425.7"
                  y="128"
                >
                  4
                </text>
              </g>
              <g
                id="gate_03_on"
                v-if="chartBodygraphChart.gatesExt[3]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[3])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 3),
                  opacity4:
                    isDefinedTogglePointGate1(3) || isDefinedTogglePointGate2(3)
                }"
                @mouseover="searchAcPlanets(3)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 3)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="611.89001"
                  cx="408.26001"
                />
                <circle
                  id="circle1566"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="611.89001"
                  cx="408.26001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      3
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="611.89001"
                  cx="408.26001"
                />
                <text
                  id="text1568"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="404.4"
                  y="616.22552"
                >
                  3
                </text>
              </g>
              <g
                id="gate_03_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[3]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[3])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 3),
                  opacity4:
                    isDefinedTogglePointGate1(3) || isDefinedTogglePointGate2(3)
                }"
                @mouseover="searchAcPlanets(3)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 3)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="611.89001"
                  cx="408.26001"
                />
                <circle
                  id="circle1566"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="611.89001"
                  cx="408.26001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      3
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="611.89001"
                  cx="408.26001"
                />
                <text
                  id="text1568"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="404.4"
                  y="616.22552"
                >
                  3
                </text>
              </g>
              <g
                id="gate_03_chart2_on"
                v-if="chartBodygraphConnection.gatesExt[3]"
                @click="openTooltipDialog(chartBodygraphConnection.gatesExt[3])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    3
                  )
                }"
                @mouseover="searchAcPlanets(3)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 3)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="611.89001"
                  cx="408.26001"
                />
                <circle
                  id="circle1566"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="611.89001"
                  cx="408.26001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      3
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="611.89001"
                  cx="408.26001"
                />
                <text
                  id="text1568"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="404.4"
                  y="616.22552"
                >
                  3
                </text>
              </g>
              <g
                id="gate_02_on"
                v-if="chartBodygraphChart.gatesExt[2]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[2])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 2),
                  opacity4:
                    isDefinedTogglePointGate1(2) || isDefinedTogglePointGate2(2)
                }"
                @mouseover="searchAcPlanets(2)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 2)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="445.79001"
                  cx="409.06"
                />
                <circle
                  id="circle1571"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="445.79001"
                  cx="409.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      2
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="445.79001"
                  cx="409.06"
                />
                <text
                  id="text1573"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="405.69165"
                  y="450.19888"
                >
                  2
                </text>
              </g>
              <g
                id="gate_02_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[2]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[2])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 2),
                  opacity4:
                    isDefinedTogglePointGate1(2) || isDefinedTogglePointGate2(2)
                }"
                @mouseover="searchAcPlanets(2)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 2)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="445.79001"
                  cx="409.06"
                />
                <circle
                  id="circle1571"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="445.79001"
                  cx="409.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      2
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="445.79001"
                  cx="409.06"
                />
                <text
                  id="text1573"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="405.69165"
                  y="450.19888"
                >
                  2
                </text>
              </g>
              <g
                id="gate_02_both_on"
                v-if="chartBodygraphConnection.gatesExt[2]"
                @click="openTooltipDialog(chartBodygraphConnection.gatesExt[2])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    2
                  )
                }"
                @mouseover="searchAcPlanets(2)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 2)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="445.79001"
                  cx="409.06"
                />
                <circle
                  id="circle1571"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="445.79001"
                  cx="409.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      2
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="445.79001"
                  cx="409.06"
                />
                <text
                  id="text1573"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="405.69165"
                  y="450.19888"
                >
                  2
                </text>
              </g>
              <g
                id="gate_01_on"
                v-if="chartBodygraphChart.gatesExt[1]"
                @click="openTooltipDialog(chartBodygraphChart.gatesExt[1])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart.gates, 1),
                  opacity4:
                    isDefinedTogglePointGate1(1) || isDefinedTogglePointGate2(1)
                }"
                @mouseover="searchAcPlanets(1)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 1)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="356.79001"
                  cx="409.06"
                />
                <circle
                  id="circle1576"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="356.79001"
                  cx="409.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      1
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="356.79001"
                  cx="409.06"
                />
                <text
                  id="text1578"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="405.5"
                  y="361.12552"
                >
                  1
                </text>
              </g>
              <g
                id="gate_01_chart2_on"
                v-if="chartBodygraphChart2.gatesExt[1]"
                @click="openTooltipDialog(chartBodygraphChart2.gatesExt[1])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(chartBodygraphChart2.gates, 1),
                  opacity4:
                    isDefinedTogglePointGate1(1) || isDefinedTogglePointGate2(1)
                }"
                @mouseover="searchAcPlanets(1)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 1)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="356.79001"
                  cx="409.06"
                />
                <circle
                  id="circle1576"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="356.79001"
                  cx="409.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      1
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="356.79001"
                  cx="409.06"
                />
                <text
                  id="text1578"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="405.5"
                  y="361.12552"
                >
                  1
                </text>
              </g>
              <g
                id="gate_01_both_on"
                v-if="chartBodygraphConnection.gatesExt[1]"
                @click="openTooltipDialog(chartBodygraphConnection.gatesExt[1])"
                v-bind:class="{
                  opacity1: this.isDefinedObj(
                    chartBodygraphConnection.gates3,
                    1
                  )
                }"
                @mouseover="searchAcPlanets(1)"
                @mouseleave="delayedСlearAcPlanets()"
                style="opacity:0;cursor:pointer;display:none"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 1)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="356.79001"
                  cx="409.06"
                />
                <circle
                  id="circle1576"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="356.79001"
                  cx="409.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      1
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="356.79001"
                  cx="409.06"
                />
                <text
                  id="text1578"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="405.5"
                  y="361.12552"
                >
                  1
                </text>
              </g>
            </g>
            <g
              id="layer5.2"
              inkscape:groupmode="layer"
              inkscape:label="Gates Channels"
              style="display:inline;opacity:1"
            >
              <g
                id="gate_64_47_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '47-64')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '47-64'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(47, 64)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 64)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="87.889999"
                  cx="387.45999"
                />
                <circle
                  id="circle1261"
                  class="gates"
                  style="fill:#504b00"
                  r="10.1"
                  cy="87.889999"
                  cx="387.45999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      64
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="87.889999"
                  cx="387.45999"
                />
                <text
                  id="text1263"
                  y="93"
                  x="380"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                >
                  64
                </text>
              </g>
              <g
                id="gate_63_04_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '4-63')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '4-63'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(4, 63)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 63)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="88.389999"
                  cx="430.16"
                />
                <circle
                  id="circle1266"
                  class="gates"
                  style="fill:#504b00"
                  r="10.1"
                  cy="88.389999"
                  cx="430.16"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      63
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="88.389999"
                  cx="430.16"
                />
                <text
                  id="text1268"
                  y="93"
                  x="422.5"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                >
                  63
                </text>
              </g>
              <g
                id="gate_62_17_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '17-62')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '17-62'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(17, 62)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 62)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="244.59"
                  cx="388.06"
                />
                <circle
                  id="circle1271"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="244.59"
                  cx="388.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      62
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="244.59"
                  cx="388.06"
                />
                <text
                  id="text1273"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="380.5"
                  y="248.92549"
                >
                  62
                </text>
              </g>
              <g
                id="gate_61_24_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '24-61')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '24-61'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(24, 61)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 61)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="88.290001"
                  cx="408.85999"
                />
                <circle
                  id="circle1276"
                  class="gates"
                  style="fill:#504b00"
                  r="10.1"
                  cy="88.290001"
                  cx="408.85999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      61
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="88.290001"
                  cx="408.85999"
                />
                <text
                  id="text1278"
                  y="93"
                  x="401.5"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                >
                  61
                </text>
              </g>
              <g
                id="gate_60_03_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '3-60')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '3-60'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(3, 60)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 60)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="644.69"
                  cx="410.26001"
                />
                <circle
                  id="circle1281"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="644.69"
                  cx="410.26001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      60
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="644.69"
                  cx="410.26001"
                />
                <text
                  id="text1283"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="403"
                  y="649.02551"
                >
                  60
                </text>
              </g>
              <g
                id="gate_59_06_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '6-59')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '6-59'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(6, 59)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 59)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="590.39001"
                  cx="445.06"
                />
                <circle
                  id="circle1286"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="590.39001"
                  cx="445.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      59
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="590.39001"
                  cx="445.06"
                />
                <text
                  id="text1288"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="437.5"
                  y="594.72552"
                >
                  59
                </text>
              </g>
              <g
                id="gate_58_18_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '18-58')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '18-58'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(18, 58)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 58)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="704.98999"
                  cx="374.35999"
                />
                <circle
                  id="circle1291"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="704.98999"
                  cx="374.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      58
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="704.98999"
                  cx="374.35999"
                />
                <text
                  id="text1293"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="367"
                  y="709.3255"
                >
                  58
                </text>
              </g>
              <g
                id="gate_57_20_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '20-57')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '20-57'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(20, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 57)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="532.19"
                  cx="234.06"
                />
                <circle
                  id="circle1296"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="532.19"
                  cx="234.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      57
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="532.19"
                  cx="234.06"
                />
                <text
                  id="text1298"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="226.7"
                  y="536.45215"
                >
                  57
                </text>
              </g>
              <g
                id="gate_56_11_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '11-56')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '11-56'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(11, 56)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 56)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="243.59"
                  cx="429.06"
                />
                <circle
                  id="circle1301"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="243.59"
                  cx="429.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      56
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="243.59"
                  cx="429.06"
                />
                <text
                  id="text1303"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="421.8"
                  y="247.91882"
                >
                  56
                </text>
              </g>
              <g
                id="gate_55_39_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '39-55')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '39-55'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(39, 55)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 55)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="584.28998"
                  cx="582.15997"
                />
                <circle
                  id="circle1306"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="584.28998"
                  cx="582.15997"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      55
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="584.28998"
                  cx="582.15997"
                />
                <text
                  id="text1308"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="574.7"
                  y="588.55212"
                >
                  55
                </text>
              </g>
              <g
                id="gate_54_32_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '32-54')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '32-54'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(32, 54)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 54)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="661.69"
                  cx="374.76001"
                />
                <circle
                  id="circle1311"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="661.69"
                  cx="374.76001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      54
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="661.69"
                  cx="374.76001"
                />
                <text
                  id="text1313"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="367"
                  y="665.95215"
                >
                  54
                </text>
              </g>
              <g
                id="gate_53_42_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '42-53')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '42-53'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(42, 53)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 53)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="645.09003"
                  cx="388.26001"
                />
                <circle
                  id="circle1316"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="645.09003"
                  cx="388.26001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      53
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="645.09003"
                  cx="388.26001"
                />
                <text
                  id="text1318"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="381"
                  y="649.42554"
                >
                  53
                </text>
              </g>
              <g
                id="gate_52_09_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '9-52')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '9-52'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(9, 52)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 52)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="645.28998"
                  cx="431.26001"
                />
                <circle
                  id="circle1321"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="645.28998"
                  cx="431.26001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      52
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="645.28998"
                  cx="431.26001"
                />
                <text
                  id="text1323"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="423.5"
                  y="649.62549"
                >
                  52
                </text>
              </g>
              <g
                id="gate_51_25_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '25-51')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '25-51'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(25, 51)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 51)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="449.98999"
                  cx="493.95999"
                />
                <circle
                  id="circle1326"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="449.98999"
                  cx="493.95999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      51
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="449.98999"
                  cx="493.95999"
                />
                <text
                  id="text1328"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="487.3"
                  y="454.25211"
                >
                  51
                </text>
              </g>
              <g
                id="gate_50_27_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '27-50')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '27-50'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(27, 50)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 50)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="558.59003"
                  cx="279.06"
                />
                <circle
                  id="circle1331"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="558.59003"
                  cx="279.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      50
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="558.59003"
                  cx="279.06"
                />
                <text
                  id="text1333"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="271.5"
                  y="562.92554"
                >
                  50
                </text>
              </g>
              <g
                id="gate_49_19_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '19-49')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '19-49'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(19, 49)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 49)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="573.09003"
                  cx="562.85999"
                />
                <circle
                  id="circle1336"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="573.09003"
                  cx="562.85999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      49
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="573.09003"
                  cx="562.85999"
                />
                <text
                  id="text1338"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="555.4"
                  y="577.43127"
                >
                  49
                </text>
              </g>
              <g
                id="gate_48_16_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '16-48')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '16-48'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(16, 48)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 48)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="521.59003"
                  cx="215.06"
                />
                <circle
                  id="circle1341"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="521.59003"
                  cx="215.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      48
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="521.59003"
                  cx="215.06"
                />
                <text
                  id="text1343"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="207.7"
                  y="525.92554"
                >
                  48
                </text>
              </g>
              <g
                id="gate_47_64_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '47-64')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '47-64'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(47, 64)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 47)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="123.79"
                  cx="387.56"
                />
                <circle
                  id="circle1346"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="123.79"
                  cx="387.56"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      47
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="123.79"
                  cx="387.56"
                />
                <text
                  id="text1348"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="379.7"
                  y="128"
                >
                  47
                </text>
              </g>
              <g
                id="gate_46_29_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '29-46')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '29-46'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(29, 46)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 46)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="426.19"
                  cx="429.16"
                />
                <circle
                  id="circle1351"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="426.19"
                  cx="429.16"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      46
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="426.19"
                  cx="429.16"
                />
                <text
                  id="text1353"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="421.7"
                  y="430.51883"
                >
                  46
                </text>
              </g>
              <g
                id="gate_45_21_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '21-45')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '21-45'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(21, 45)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 45)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="302.98999"
                  cx="442.35999"
                />
                <circle
                  id="circle1356"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="302.98999"
                  cx="442.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      45
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="302.98999"
                  cx="442.35999"
                />
                <text
                  id="text1358"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="435"
                  y="307.25211"
                >
                  45
                </text>
              </g>
              <g
                id="gate_44_26_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '26-44')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '26-44'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(26, 44)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 44)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="545.19"
                  cx="256.35999"
                />
                <circle
                  id="circle1361"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="545.19"
                  cx="256.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      44
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="545.19"
                  cx="256.35999"
                />
                <text
                  id="text1363"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="248.5"
                  y="549.52551"
                >
                  44
                </text>
              </g>
              <g
                id="gate_43_23_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '23-43')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '23-43'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(23, 43)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 43)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="190.78999"
                  cx="408.45999"
                />
                <circle
                  id="circle1366"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="190.78999"
                  cx="408.45999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      43
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="190.78999"
                  cx="408.45999"
                />
                <text
                  id="text1368"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="401"
                  y="195"
                >
                  43
                </text>
              </g>
              <g
                id="gate_42_53_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '42-53')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '42-53'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(42, 53)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 42)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="611.39001"
                  cx="387.26001"
                />
                <circle
                  id="circle1371"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="611.39001"
                  cx="387.26001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      42
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="611.39001"
                  cx="387.26001"
                />
                <text
                  id="text1373"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="379.6"
                  y="615.79889"
                >
                  42
                </text>
              </g>
              <g
                id="gate_41_30_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '30-41')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '30-41'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(30, 41)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 41)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="705.59003"
                  cx="443.35999"
                />
                <circle
                  id="circle1376"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="705.59003"
                  cx="443.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      41
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="705.59003"
                  cx="443.35999"
                />
                <text
                  id="text1378"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="436"
                  y="709.92554"
                >
                  41
                </text>
              </g>
              <g
                id="gate_40_37_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '37-40')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '37-40'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(37, 40)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 40)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="484.79001"
                  cx="541.96002"
                />
                <circle
                  id="circle1381"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="484.79001"
                  cx="541.96002"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      40
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="484.79001"
                  cx="541.96002"
                />
                <text
                  id="text1383"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="534"
                  y="489.12552"
                >
                  40
                </text>
              </g>
              <g
                id="gate_39_55_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '39-55')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '39-55'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(39, 55)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 39)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="682.89001"
                  cx="443.66"
                />
                <circle
                  id="circle1386"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="682.89001"
                  cx="443.66"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      39
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="682.89001"
                  cx="443.66"
                />
                <text
                  id="text1388"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="436"
                  y="687.22552"
                >
                  39
                </text>
              </g>
              <g
                id="gate_38_28_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '28-38')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '28-38'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(28, 38)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 38)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="682.19"
                  cx="374.66"
                />
                <circle
                  id="circle1391"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="682.19"
                  cx="374.66"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      38
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="682.19"
                  cx="374.66"
                />
                <text
                  id="text1393"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="367.2"
                  y="686.52551"
                >
                  38
                </text>
              </g>
              <g
                id="gate_37_40_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '37-40')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '37-40'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(37, 40)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 37)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="547.09003"
                  cx="558.85999"
                />
                <circle
                  id="circle1396"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="547.09003"
                  cx="558.85999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      37
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="547.09003"
                  cx="558.85999"
                />
                <text
                  id="text1398"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="551.5"
                  y="551.42554"
                >
                  37
                </text>
              </g>
              <g
                id="gate_36_35_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '35-36')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '35-36'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(35, 36)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 36)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="523.09003"
                  cx="600.65997"
                />
                <circle
                  id="circle1401"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="523.09003"
                  cx="600.65997"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      36
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="523.09003"
                  cx="600.65997"
                />
                <text
                  id="text1403"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="593.75201"
                  y="527.42554"
                >
                  36
                </text>
              </g>
              <g
                id="gate_35_36_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '35-36')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '35-36'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(35, 36)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 35)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="263.98999"
                  cx="447.35999"
                />
                <circle
                  id="circle1406"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="263.98999"
                  cx="447.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      35
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="263.98999"
                  cx="447.35999"
                />
                <text
                  id="text1408"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="440.67883"
                  y="268.3255"
                >
                  35
                </text>
              </g>
              <g
                id="gate_34_20_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '20-34')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '20-34'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(20, 34)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 34)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="558.09003"
                  cx="370.06"
                />
                <circle
                  id="circle1411"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="558.09003"
                  cx="370.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      34
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="558.09003"
                  cx="370.06"
                />
                <text
                  id="text1413"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="362.5"
                  y="562.42554"
                >
                  34
                </text>
              </g>
              <g
                id="gate_33_13_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '13-33')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '13-33'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(13, 33)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 33)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="315.98999"
                  cx="428.06"
                />
                <circle
                  id="circle1416"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="315.98999"
                  cx="428.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      33
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="315.98999"
                  cx="428.06"
                />
                <text
                  id="text1418"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="421"
                  y="320.3255"
                >
                  33
                </text>
              </g>
              <g
                id="gate_32_54_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '32-54')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '32-54'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(32, 54)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 32)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="572.19"
                  cx="256.35999"
                />
                <circle
                  id="circle1421"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="572.19"
                  cx="256.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      32
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="572.19"
                  cx="256.35999"
                />
                <text
                  id="text1423"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="249"
                  y="576.52551"
                >
                  32
                </text>
              </g>
              <g
                id="gate_31_07_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '7-31')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '7-31'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(7, 31)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 31)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="315.79001"
                  cx="388.95999"
                />
                <circle
                  id="circle1426"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="315.79001"
                  cx="388.95999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      31
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="315.79001"
                  cx="388.95999"
                />
                <text
                  id="text1428"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="382"
                  y="320.5"
                >
                  31
                </text>
              </g>
              <g
                id="gate_30_41_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '30-41')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '30-41'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(30, 41)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 30)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="596.09003"
                  cx="603.35999"
                />
                <circle
                  id="circle1431"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="596.09003"
                  cx="603.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      30
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="596.09003"
                  cx="603.35999"
                />
                <text
                  id="text1433"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="596"
                  y="600.42554"
                >
                  30
                </text>
              </g>
              <g
                id="gate_29_46_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '29-46')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '29-46'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(29, 46)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 29)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="530.89001"
                  cx="430.06"
                />
                <circle
                  id="circle1436"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="530.89001"
                  cx="430.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      29
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="530.89001"
                  cx="430.06"
                />
                <text
                  id="text1438"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="422.7"
                  y="535.23126"
                >
                  29
                </text>
              </g>
              <g
                id="gate_28_38_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '28-38')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '28-38'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(28, 38)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 28)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="583.89001"
                  cx="234.66"
                />
                <circle
                  id="circle1441"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="583.89001"
                  cx="234.66"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      28
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="583.89001"
                  cx="234.66"
                />
                <text
                  id="text1443"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="227"
                  y="588.22552"
                >
                  28
                </text>
              </g>
              <g
                id="gate_27_50_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '27-50')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '27-50'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(27, 50)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 27)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="590.89001"
                  cx="375.06"
                />
                <circle
                  id="circle1446"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="590.89001"
                  cx="375.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      27
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="590.89001"
                  cx="375.06"
                />
                <text
                  id="text1448"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="368.21878"
                  y="595.29889"
                >
                  27
                </text>
              </g>
              <g
                id="gate_26_44_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '26-44')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '26-44'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(26, 44)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 26)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="467.48999"
                  cx="470.45999"
                />
                <circle
                  id="circle1451"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="467.48999"
                  cx="470.45999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      26
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="467.48999"
                  cx="470.45999"
                />
                <text
                  id="text1453"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="463"
                  y="471.8255"
                >
                  26
                </text>
              </g>
              <g
                id="gate_25_51_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '25-51')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '25-51'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(25, 51)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 25)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="401.98999"
                  cx="452.45999"
                />
                <circle
                  id="circle1456"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="401.98999"
                  cx="452.45999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      25
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="401.98999"
                  cx="452.45999"
                />
                <text
                  id="text1458"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="445"
                  y="406.3255"
                >
                  25
                </text>
              </g>
              <g
                id="gate_24_61_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '24-61')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '24-61'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(24, 61)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 24)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="123.59"
                  cx="409.56"
                />
                <circle
                  id="circle1461"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="123.59"
                  cx="409.56"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      24
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="123.59"
                  cx="409.56"
                />
                <text
                  id="text1463"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="402.3"
                  y="128"
                >
                  24
                </text>
              </g>
              <g
                id="gate_23_43_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '23-43')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '23-43'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(23, 43)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 23)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="238.69"
                  cx="408.66"
                />
                <circle
                  id="circle1466"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="238.69"
                  cx="408.66"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      23
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="238.69"
                  cx="408.66"
                />
                <text
                  id="text1468"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="401.4"
                  y="243.0255"
                >
                  23
                </text>
              </g>
              <g
                id="gate_22_12_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '12-22')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '12-22'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(12, 22)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 22)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="534.28998"
                  cx="580.35999"
                />
                <circle
                  id="circle1471"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="534.28998"
                  cx="580.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      22
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="534.28998"
                  cx="580.35999"
                />
                <text
                  id="text1473"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="573"
                  y="538.69885"
                >
                  22
                </text>
              </g>
              <g
                id="gate_21_45_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '21-45')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '21-45'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(21, 45)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 21)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="434.39001"
                  cx="515.06"
                />
                <circle
                  id="circle1476"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="434.39001"
                  cx="515.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      21
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="434.39001"
                  cx="515.06"
                />
                <text
                  id="text1478"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="508.6"
                  y="438.79889"
                >
                  21
                </text>
              </g>
              <g
                id="gate_20_57_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '20-57')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '20-57'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(20, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 20)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="286.48999"
                  cx="368.56"
                />
                <circle
                  id="circle1481"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="286.48999"
                  cx="368.56"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      20
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="286.48999"
                  cx="368.56"
                />
                <text
                  id="text1483"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="361.2"
                  y="290.8255"
                >
                  20
                </text>
              </g>
              <g
                id="gate_19_49_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '19-49')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '19-49'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(19, 49)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 19)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="662.39001"
                  cx="443.76001"
                />
                <circle
                  id="circle1486"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="662.39001"
                  cx="443.76001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      19
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="662.39001"
                  cx="443.76001"
                />
                <text
                  id="text1488"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="435.5"
                  y="666.73126"
                >
                  19
                </text>
              </g>
              <g
                id="gate_18_58_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '18-58')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '18-58'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(18, 58)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 18)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="595.19"
                  cx="215.36"
                />
                <circle
                  id="circle1491"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="595.19"
                  cx="215.36"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      18
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="595.19"
                  cx="215.36"
                />
                <text
                  id="text1493"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="207.5"
                  y="599.52551"
                >
                  18
                </text>
              </g>
              <g
                id="gate_17_62_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '17-62')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '17-62'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(17, 62)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 17)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="159.69"
                  cx="389.06"
                />
                <circle
                  id="circle1496"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="159.69"
                  cx="389.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      17
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="159.69"
                  cx="389.06"
                />
                <text
                  id="text1498"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="381.4"
                  y="164"
                >
                  17
                </text>
              </g>
              <g
                id="gate_16_48_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '16-48')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '16-48'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(16, 48)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 16)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="263.09"
                  cx="371.06"
                />
                <circle
                  id="circle1501"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="263.09"
                  cx="371.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      16
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="263.09"
                  cx="371.06"
                />
                <text
                  id="text1503"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="363.4"
                  y="267.41882"
                >
                  16
                </text>
              </g>
              <g
                id="gate_15_05_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '5-15')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '5-15'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(5, 15)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 15)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="426.48999"
                  cx="388.35999"
                />
                <circle
                  id="circle1506"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="426.48999"
                  cx="388.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      15
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="426.48999"
                  cx="388.35999"
                />
                <text
                  id="text1508"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="380.7"
                  y="430.75211"
                >
                  15
                </text>
              </g>
              <g
                id="gate_14_02_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '2-14')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '2-14'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(2, 14)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 14)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="530.59003"
                  cx="408.76001"
                />
                <circle
                  id="circle1511"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="530.59003"
                  cx="408.76001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      14
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="530.59003"
                  cx="408.76001"
                />
                <text
                  id="text1513"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="400.7"
                  y="534.92554"
                >
                  14
                </text>
              </g>
              <g
                id="gate_13_33_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '13-33')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '13-33'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(13, 33)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 13)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="375.29001"
                  cx="427.85999"
                />
                <circle
                  id="circle1516"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="375.29001"
                  cx="427.85999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      13
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="375.29001"
                  cx="427.85999"
                />
                <text
                  id="text1518"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="420"
                  y="379.62552"
                >
                  13
                </text>
              </g>
              <g
                id="gate_12_22_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '12-22')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '12-22'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(12, 22)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 12)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="285.29001"
                  cx="449.95999"
                />
                <circle
                  id="circle1521"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="285.29001"
                  cx="449.95999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      12
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="285.29001"
                  cx="449.95999"
                />
                <text
                  id="text1523"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="442.3"
                  y="289.69888"
                >
                  12
                </text>
              </g>
              <g
                id="gate_11_56_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '11-56')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '11-56'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(11, 56)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 11)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="158.59"
                  cx="429.56"
                />
                <circle
                  id="circle1526"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="158.59"
                  cx="429.56"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      11
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="158.59"
                  cx="429.56"
                />
                <text
                  id="text1528"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="422.4"
                  y="163"
                >
                  11
                </text>
              </g>
              <g
                id="gate_10_57_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '10-57')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '10-57'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(10, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 10)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="401.69"
                  cx="365.16"
                />
                <circle
                  id="circle1531"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="401.69"
                  cx="365.16"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      10
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="401.69"
                  cx="365.16"
                />
                <text
                  id="text1533"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="357.3"
                  y="406.02551"
                >
                  10
                </text>
              </g>
              <g
                id="gate_10_20_34_channel_on"
                v-if="
                  isChannel(chartBodygraphConnection.channels, '10-20') &&
                    isChannel(chartBodygraphConnection.channels, '10-34')
                "
                @click="
                  openMultiTooltipDialog([
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '10-20'
                    ),
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '10-34'
                    )
                  ])
                "
                @mouseover="searchAcPlanets(10, 20, 34)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="401.69"
                  cx="365.16"
                />
              </g>
              <g
                id="gate_10-20_10-34_20-34_channel_on"
                v-if="
                  isChannel(chartBodygraphConnection.channels, '10-20') &&
                    isChannel(chartBodygraphConnection.channels, '10-34') &&
                    isChannel(chartBodygraphConnection.channels, '20-34')
                "
                @click="
                  openMultiTooltipDialog([
                    toSearchItemAtArrInObj2(
                      chartBodygraphConnection.channels,
                      '10-20'
                    ),
                    toSearchItemAtArrInObj2(
                      chartBodygraphConnection.channels,
                      '10-34'
                    ),
                    toSearchItemAtArrInObj2(
                      chartBodygraphConnection.channels,
                      '20-34'
                    )
                  ])
                "
                @mouseover="searchAcPlanets(10, 20, 34)"
                @mouseleave="delayedСlearAcPlanets()"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="401.69"
                  cx="365.16"
                />
              </g>
              <g
                id="gate_10_20_57_channel_on"
                v-if="
                  isChannel(chartBodygraphConnection.channels, '10-20') &&
                    isChannel(chartBodygraphConnection.channels, '10-57')
                "
                @click="
                  openMultiTooltipDialog([
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '10-20'
                    ),
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '10-57'
                    )
                  ])
                "
                @mouseover="searchAcPlanets(10, 20, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="401.69"
                  cx="365.16"
                />
              </g>
              <g
                id="gate_10_34_57_channel_on"
                v-if="
                  isChannel(chartBodygraphConnection.channels, '10-34') &&
                    isChannel(chartBodygraphConnection.channels, '10-57')
                "
                @click="
                  openMultiTooltipDialog([
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '10-34'
                    ),
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '10-57'
                    )
                  ])
                "
                @mouseover="searchAcPlanets(10, 34, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="401.69"
                  cx="365.16"
                />
              </g>
              <g
                id="gate_10_20_34_57_channel_on"
                v-if="
                  isChannel(chartBodygraphConnection.channels, '10-20') &&
                    isChannel(chartBodygraphConnection.channels, '10-34') &&
                    isChannel(chartBodygraphConnection.channels, '10-57')
                "
                @click="
                  openMultiTooltipDialog([
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '10-20'
                    ),
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '10-34'
                    ),
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '10-57'
                    )
                  ])
                "
                @mouseover="searchAcPlanets(10, 20, 34, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="401.69"
                  cx="365.16"
                />
              </g>
              <g
                id="gate_20_10_34_channel_on"
                v-if="
                  isChannel(chartBodygraphConnection.channels, '10-20') &&
                    isChannel(chartBodygraphConnection.channels, '20-34')
                "
                @click="
                  openMultiTooltipDialog([
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '10-20'
                    ),
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '20-34'
                    )
                  ])
                "
                @mouseover="searchAcPlanets(10, 20, 34)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="286.48999"
                  cx="368.56"
                />
              </g>
              <g
                id="gate_20-10_10-34_20-34_channel_on"
                v-if="
                  isChannel(chartBodygraphConnection.channels, '10-20') &&
                    isChannel(chartBodygraphConnection.channels, '10-34') &&
                    isChannel(chartBodygraphConnection.channels, '20-34')
                "
                @click="
                  openMultiTooltipDialog([
                    toSearchItemAtArrInObj2(
                      chartBodygraphConnection.channels,
                      '10-20'
                    ),
                    toSearchItemAtArrInObj2(
                      chartBodygraphConnection.channels,
                      '10-34'
                    ),
                    toSearchItemAtArrInObj2(
                      chartBodygraphConnection.channels,
                      '20-34'
                    )
                  ])
                "
                @mouseover="searchAcPlanets(10, 20, 34)"
                @mouseleave="delayedСlearAcPlanets()"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="286.48999"
                  cx="368.56"
                />
              </g>
              <g
                id="gate_20_10_57_channel_on"
                v-if="
                  isChannel(chartBodygraphConnection.channels, '10-20') &&
                    isChannel(chartBodygraphConnection.channels, '20-57')
                "
                @click="
                  openMultiTooltipDialog([
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '10-20'
                    ),
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '20-57'
                    )
                  ])
                "
                @mouseover="searchAcPlanets(10, 20, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="286.48999"
                  cx="368.56"
                />
              </g>
              <g
                id="gate_20_34_57_channel_on"
                v-if="
                  isChannel(chartBodygraphConnection.channels, '20-34') &&
                    isChannel(chartBodygraphConnection.channels, '20-57')
                "
                @click="
                  openMultiTooltipDialog([
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '20-34'
                    ),
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '20-57'
                    )
                  ])
                "
                @mouseover="searchAcPlanets(20, 34, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="286.48999"
                  cx="368.56"
                />
              </g>
              <g
                id="gate_20_10_34_57_channel_on"
                v-if="
                  isChannel(chartBodygraphConnection.channels, '10-20') &&
                    isChannel(chartBodygraphConnection.channels, '20-34') &&
                    isChannel(chartBodygraphConnection.channels, '20-57')
                "
                @click="
                  openMultiTooltipDialog([
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '10-20'
                    ),
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '20-34'
                    ),
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '20-57'
                    )
                  ])
                "
                @mouseover="searchAcPlanets(10, 20, 34, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="286.48999"
                  cx="368.56"
                />
              </g>
              <g
                id="gate_34_10_20_channel_on"
                v-if="
                  isChannel(chartBodygraphConnection.channels, '10-34') &&
                    isChannel(chartBodygraphConnection.channels, '20-34')
                "
                @click="
                  openMultiTooltipDialog([
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '10-34'
                    ),
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '20-34'
                    )
                  ])
                "
                @mouseover="searchAcPlanets(10, 20, 34)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="558.09003"
                  cx="370.06"
                />
              </g>
              <g
                id="gate_34-20_10-20_10-34_channel_on"
                v-if="
                  isChannel(chartBodygraphConnection.channels, '10-20') &&
                    isChannel(chartBodygraphConnection.channels, '10-34') &&
                    isChannel(chartBodygraphConnection.channels, '20-34')
                "
                @click="
                  openMultiTooltipDialog([
                    toSearchItemAtArrInObj2(
                      chartBodygraphConnection.channels,
                      '10-20'
                    ),
                    toSearchItemAtArrInObj2(
                      chartBodygraphConnection.channels,
                      '10-34'
                    ),
                    toSearchItemAtArrInObj2(
                      chartBodygraphConnection.channels,
                      '20-34'
                    )
                  ])
                "
                @mouseover="searchAcPlanets(10, 20, 34)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="558.09003"
                  cx="370.06"
                />
              </g>
              <g
                id="gate_34_10_57_channel_on"
                v-if="
                  isChannel(chartBodygraphConnection.channels, '10-34') &&
                    isChannel(chartBodygraphConnection.channels, '34-57')
                "
                @click="
                  openMultiTooltipDialog([
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '10-34'
                    ),
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '34-57'
                    )
                  ])
                "
                @mouseover="searchAcPlanets(10, 34, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="558.09003"
                  cx="370.06"
                />
              </g>
              <g
                id="gate_34_20_57_channel_on"
                v-if="
                  isChannel(chartBodygraphConnection.channels, '20-34') &&
                    isChannel(chartBodygraphConnection.channels, '34-57')
                "
                @click="
                  openMultiTooltipDialog([
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '20-34'
                    ),
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '34-57'
                    )
                  ])
                "
                @mouseover="searchAcPlanets(20, 34, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="558.09003"
                  cx="370.06"
                />
              </g>
              <g
                id="gate_34_10_20_57_channel_on"
                v-if="
                  isChannel(chartBodygraphConnection.channels, '10-34') &&
                    isChannel(chartBodygraphConnection.channels, '20-34') &&
                    isChannel(chartBodygraphConnection.channels, '34-57')
                "
                @click="
                  openMultiTooltipDialog([
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '10-34'
                    ),
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '20-34'
                    ),
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '34-57'
                    )
                  ])
                "
                @mouseover="searchAcPlanets(10, 20, 34, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="558.09003"
                  cx="370.06"
                />
              </g>
              <g
                id="gate_57_10_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '10-57')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '10-57'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(10, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1296"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="532.19"
                  cx="234.06"
                />
              </g>
              <g
                id="gate_57_10_20_channel_on"
                v-if="
                  isChannel(chartBodygraphConnection.channels, '10-57') &&
                    isChannel(chartBodygraphConnection.channels, '20-57')
                "
                @click="
                  openMultiTooltipDialog([
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '10-57'
                    ),
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '20-57'
                    )
                  ])
                "
                @mouseover="searchAcPlanets(10, 20, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1296"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="532.19"
                  cx="234.06"
                />
              </g>
              <g
                id="gate_57_10_34_channel_on"
                v-if="
                  isChannel(chartBodygraphConnection.channels, '10-57') &&
                    isChannel(chartBodygraphConnection.channels, '34-57')
                "
                @click="
                  openMultiTooltipDialog([
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '10-57'
                    ),
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '34-57'
                    )
                  ])
                "
                @mouseover="searchAcPlanets(10, 34, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1296"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="532.19"
                  cx="234.06"
                />
              </g>
              <g
                id="gate_57_20_34_channel_on"
                v-if="
                  isChannel(chartBodygraphConnection.channels, '20-57') &&
                    isChannel(chartBodygraphConnection.channels, '34-57')
                "
                @click="
                  openMultiTooltipDialog([
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '20-57'
                    ),
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '34-57'
                    )
                  ])
                "
                @mouseover="searchAcPlanets(20, 34, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1296"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="532.19"
                  cx="234.06"
                />
              </g>
              <g
                id="gate_57_10_20_34_channel_on"
                v-if="
                  isChannel(chartBodygraphConnection.channels, '10-57') &&
                    isChannel(chartBodygraphConnection.channels, '20-57') &&
                    isChannel(chartBodygraphConnection.channels, '34-57')
                "
                @click="
                  openMultiTooltipDialog([
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '10-57'
                    ),
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '20-57'
                    ),
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '34-57'
                    )
                  ])
                "
                @mouseover="searchAcPlanets(10, 20, 34, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1296"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="532.19"
                  cx="234.06"
                />
              </g>
              <g
                id="gate_09_52_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '9-52')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '9-52'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(9, 52)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 9)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="611.89001"
                  cx="429.26001"
                />
                <circle
                  id="circle1536"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="611.89001"
                  cx="429.26001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      9
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="611.89001"
                  cx="429.26001"
                />
                <text
                  id="text1538"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="425.5"
                  y="616.23126"
                >
                  9
                </text>
              </g>
              <g
                id="gate_08_01_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '1-8')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '1-8'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(1, 8)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 8)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="321.19"
                  cx="409.16"
                />
                <circle
                  id="circle1541"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="321.19"
                  cx="409.16"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      8
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="321.19"
                  cx="409.16"
                />
                <text
                  id="text1543"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="405.3"
                  y="325.52551"
                >
                  8
                </text>
              </g>
              <g
                id="gate_07_31_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '7-31')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '7-31'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(7, 31)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 7)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="376.19"
                  cx="389.66"
                />
                <circle
                  id="circle1546"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="376.19"
                  cx="389.66"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      7
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="376.19"
                  cx="389.66"
                />
                <text
                  id="text1548"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="386.16492"
                  y="380.52551"
                >
                  7
                </text>
              </g>
              <g
                id="gate_06_59_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '6-59')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '6-59'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(6, 59)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 6)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="558.59003"
                  cx="538.35999"
                />
                <circle
                  id="circle1551"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="558.59003"
                  cx="538.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      6
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="558.59003"
                  cx="538.35999"
                />
                <text
                  id="text1553"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="534.4"
                  y="562.91888"
                >
                  6
                </text>
              </g>
              <g
                id="gate_05_15_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '5-15')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '5-15'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(5, 15)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 5)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="530.89001"
                  cx="388.06"
                />
                <circle
                  id="circle1556"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="530.89001"
                  cx="388.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      5
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="530.89001"
                  cx="388.06"
                />
                <text
                  id="text1558"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="384"
                  y="535.15216"
                >
                  5
                </text>
              </g>
              <g
                id="gate_04_63_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '4-63')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '4-53'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(4, 63)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 4)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="123.79"
                  cx="430.06"
                />
                <circle
                  id="circle1561"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="123.79"
                  cx="430.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      4
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="123.79"
                  cx="430.06"
                />
                <text
                  id="text1563"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="425.7"
                  y="128"
                >
                  4
                </text>
              </g>
              <g
                id="gate_03_60_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '3-60')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '3-60'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(3, 60)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 3)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="611.89001"
                  cx="408.26001"
                />
                <circle
                  id="circle1566"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="611.89001"
                  cx="408.26001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      3
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="611.89001"
                  cx="408.26001"
                />
                <text
                  id="text1568"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="404.4"
                  y="616.22552"
                >
                  3
                </text>
              </g>
              <g
                id="gate_02_14_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '2-14')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '2-14'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(2, 14)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 2)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="445.79001"
                  cx="409.06"
                />
                <circle
                  id="circle1571"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="445.79001"
                  cx="409.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      2
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="445.79001"
                  cx="409.06"
                />
                <text
                  id="text1573"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="405.69165"
                  y="450.19888"
                >
                  2
                </text>
              </g>
              <g
                id="gate_01_08_channel_on"
                v-if="isChannel(chartBodygraphConnection.channels, '1-8')"
                @click="
                  openTooltipDialog(
                    toSearchItemAtArrInObj(
                      chartBodygraphConnection.channels,
                      '1-8'
                    )
                  )
                "
                v-bind:class="{ opacity4: !isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(1, 8)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 1)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="356.79001"
                  cx="409.06"
                />
                <circle
                  id="circle1576"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="356.79001"
                  cx="409.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      1
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="356.79001"
                  cx="409.06"
                />
                <text
                  id="text1578"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="405.5"
                  y="361.12552"
                >
                  1
                </text>
              </g>
            </g>
            <g
              id="layer5.3"
              inkscape:groupmode="layer"
              inkscape:label="Channels On Both for Chart1 & Chart2"
              style="display:inline;opacity:1"
            >
              <g
                id="gate_64_47_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['47-64']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['47-64'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(47, 64)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 64)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="87.889999"
                  cx="387.45999"
                />
                <circle
                  id="circle1261"
                  class="gates"
                  style="fill:#504b00"
                  r="10.1"
                  cy="87.889999"
                  cx="387.45999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      64
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="87.889999"
                  cx="387.45999"
                />
                <text
                  id="text1263"
                  y="93"
                  x="380"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                >
                  64
                </text>
              </g>
              <g
                id="gate_64_47_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['47-64']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['47-64'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(47, 64)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 64)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="87.889999"
                  cx="387.45999"
                />
                <circle
                  id="circle1261"
                  class="gates"
                  style="fill:#504b00"
                  r="10.1"
                  cy="87.889999"
                  cx="387.45999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      64
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="87.889999"
                  cx="387.45999"
                />
                <text
                  id="text1263"
                  y="93"
                  x="380"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                >
                  64
                </text>
              </g>
              <g
                id="gate_63_04_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['4-63']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['4-63'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(4, 63)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 63)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="88.389999"
                  cx="430.16"
                />
                <circle
                  id="circle1266"
                  class="gates"
                  style="fill:#504b00"
                  r="10.1"
                  cy="88.389999"
                  cx="430.16"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      63
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="88.389999"
                  cx="430.16"
                />
                <text
                  id="text1268"
                  y="93"
                  x="422.5"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                >
                  63
                </text>
              </g>
              <g
                id="gate_63_04_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['4-63']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['4-63'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(4, 63)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 63)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="88.389999"
                  cx="430.16"
                />
                <circle
                  id="circle1266"
                  class="gates"
                  style="fill:#504b00"
                  r="10.1"
                  cy="88.389999"
                  cx="430.16"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      63
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="88.389999"
                  cx="430.16"
                />
                <text
                  id="text1268"
                  y="93"
                  x="422.5"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                >
                  63
                </text>
              </g>
              <g
                id="gate_62_17_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['17-62']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['17-62'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(17, 62)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 62)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="244.59"
                  cx="388.06"
                />
                <circle
                  id="circle1271"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="244.59"
                  cx="388.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      62
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="244.59"
                  cx="388.06"
                />
                <text
                  id="text1273"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="380.5"
                  y="248.92549"
                >
                  62
                </text>
              </g>
              <g
                id="gate_62_17_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['17-62']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['17-62'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(17, 62)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 62)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="244.59"
                  cx="388.06"
                />
                <circle
                  id="circle1271"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="244.59"
                  cx="388.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      62
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="244.59"
                  cx="388.06"
                />
                <text
                  id="text1273"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="380.5"
                  y="248.92549"
                >
                  62
                </text>
              </g>
              <g
                id="gate_61_24_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['24-61']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['24-61'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(24, 61)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 61)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="88.290001"
                  cx="408.85999"
                />
                <circle
                  id="circle1276"
                  class="gates"
                  style="fill:#504b00"
                  r="10.1"
                  cy="88.290001"
                  cx="408.85999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      61
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="88.290001"
                  cx="408.85999"
                />
                <text
                  id="text1278"
                  y="93"
                  x="401.5"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                >
                  61
                </text>
              </g>
              <g
                id="gate_61_24_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['24-61']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['24-61'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(24, 61)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 61)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="88.290001"
                  cx="408.85999"
                />
                <circle
                  id="circle1276"
                  class="gates"
                  style="fill:#504b00"
                  r="10.1"
                  cy="88.290001"
                  cx="408.85999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      61
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="88.290001"
                  cx="408.85999"
                />
                <text
                  id="text1278"
                  y="93"
                  x="401.5"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                >
                  61
                </text>
              </g>
              <g
                id="gate_60_03_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['3-60']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['3-60'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(3, 60)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 60)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="644.69"
                  cx="410.26001"
                />
                <circle
                  id="circle1281"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="644.69"
                  cx="410.26001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      60
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="644.69"
                  cx="410.26001"
                />
                <text
                  id="text1283"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="403"
                  y="649.02551"
                >
                  60
                </text>
              </g>
              <g
                id="gate_60_03_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['3-60']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['3-60'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(3, 60)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 60)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="644.69"
                  cx="410.26001"
                />
                <circle
                  id="circle1281"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="644.69"
                  cx="410.26001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      60
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="644.69"
                  cx="410.26001"
                />
                <text
                  id="text1283"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="403"
                  y="649.02551"
                >
                  60
                </text>
              </g>
              <g
                id="gate_59_06_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['6-59']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['6-59'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(6, 59)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 59)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="590.39001"
                  cx="445.06"
                />
                <circle
                  id="circle1286"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="590.39001"
                  cx="445.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      59
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="590.39001"
                  cx="445.06"
                />
                <text
                  id="text1288"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="437.5"
                  y="594.72552"
                >
                  59
                </text>
              </g>
              <g
                id="gate_59_06_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['6-59']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['6-59'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(6, 59)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 59)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="590.39001"
                  cx="445.06"
                />
                <circle
                  id="circle1286"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="590.39001"
                  cx="445.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      59
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="590.39001"
                  cx="445.06"
                />
                <text
                  id="text1288"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="437.5"
                  y="594.72552"
                >
                  59
                </text>
              </g>
              <g
                id="gate_58_18_channel_on_chart"
                v-if="chartBodygraphChart.channels2['18-58']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['18-58'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(18, 58)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 58)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="704.98999"
                  cx="374.35999"
                />
                <circle
                  id="circle1291"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="704.98999"
                  cx="374.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      58
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="704.98999"
                  cx="374.35999"
                />
                <text
                  id="text1293"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="367"
                  y="709.3255"
                >
                  58
                </text>
              </g>
              <g
                id="gate_58_18_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['18-58']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['18-58'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(18, 58)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 58)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="704.98999"
                  cx="374.35999"
                />
                <circle
                  id="circle1291"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="704.98999"
                  cx="374.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      58
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="704.98999"
                  cx="374.35999"
                />
                <text
                  id="text1293"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="367"
                  y="709.3255"
                >
                  58
                </text>
              </g>
              <g
                id="gate_57_20_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['20-57']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['20-57'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(20, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 57)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="532.19"
                  cx="234.06"
                />
                <circle
                  id="circle1296"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="532.19"
                  cx="234.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      57
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="532.19"
                  cx="234.06"
                />
                <text
                  id="text1298"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="226.7"
                  y="536.45215"
                >
                  57
                </text>
              </g>
              <g
                id="gate_57_20_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['20-57']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['20-57'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(20, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 57)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="532.19"
                  cx="234.06"
                />
                <circle
                  id="circle1296"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="532.19"
                  cx="234.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      57
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="532.19"
                  cx="234.06"
                />
                <text
                  id="text1298"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="226.7"
                  y="536.45215"
                >
                  57
                </text>
              </g>
              <g
                id="gate_56_11_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['11-56']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['11-56'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(11, 56)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 56)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="243.59"
                  cx="429.06"
                />
                <circle
                  id="circle1301"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="243.59"
                  cx="429.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      56
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="243.59"
                  cx="429.06"
                />
                <text
                  id="text1303"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="421.8"
                  y="247.91882"
                >
                  56
                </text>
              </g>
              <g
                id="gate_56_11_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['11-56']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['11-56'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(11, 56)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 56)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="243.59"
                  cx="429.06"
                />
                <circle
                  id="circle1301"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="243.59"
                  cx="429.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      56
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="243.59"
                  cx="429.06"
                />
                <text
                  id="text1303"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="421.8"
                  y="247.91882"
                >
                  56
                </text>
              </g>
              <g
                id="gate_55_39_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['39-55']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['39-55'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(39, 55)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 55)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="584.28998"
                  cx="582.15997"
                />
                <circle
                  id="circle1306"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="584.28998"
                  cx="582.15997"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      55
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="584.28998"
                  cx="582.15997"
                />
                <text
                  id="text1308"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="574.7"
                  y="588.55212"
                >
                  55
                </text>
              </g>
              <g
                id="gate_55_39_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['39-55']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['39-55'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(39, 55)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 55)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="584.28998"
                  cx="582.15997"
                />
                <circle
                  id="circle1306"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="584.28998"
                  cx="582.15997"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      55
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="584.28998"
                  cx="582.15997"
                />
                <text
                  id="text1308"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="574.7"
                  y="588.55212"
                >
                  55
                </text>
              </g>
              <g
                id="gate_54_32_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['32-54']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['32-54'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(32, 54)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 54)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="661.69"
                  cx="374.76001"
                />
                <circle
                  id="circle1311"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="661.69"
                  cx="374.76001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      54
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="661.69"
                  cx="374.76001"
                />
                <text
                  id="text1313"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="367"
                  y="665.95215"
                >
                  54
                </text>
              </g>
              <g
                id="gate_54_32_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['32-54']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['32-54'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(32, 54)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 54)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="661.69"
                  cx="374.76001"
                />
                <circle
                  id="circle1311"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="661.69"
                  cx="374.76001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      54
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="661.69"
                  cx="374.76001"
                />
                <text
                  id="text1313"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="367"
                  y="665.95215"
                >
                  54
                </text>
              </g>
              <g
                id="gate_53_42_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['42-53']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['42-53'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(42, 53)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 53)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="645.09003"
                  cx="388.26001"
                />
                <circle
                  id="circle1316"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="645.09003"
                  cx="388.26001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      53
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="645.09003"
                  cx="388.26001"
                />
                <text
                  id="text1318"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="381"
                  y="649.42554"
                >
                  53
                </text>
              </g>
              <g
                id="gate_53_42_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['42-53']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['42-53'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(42, 53)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 53)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="645.09003"
                  cx="388.26001"
                />
                <circle
                  id="circle1316"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="645.09003"
                  cx="388.26001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      53
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="645.09003"
                  cx="388.26001"
                />
                <text
                  id="text1318"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="381"
                  y="649.42554"
                >
                  53
                </text>
              </g>
              <g
                id="gate_52_09_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['9-52']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['9-52'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(9, 52)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 52)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="645.28998"
                  cx="431.26001"
                />
                <circle
                  id="circle1321"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="645.28998"
                  cx="431.26001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      52
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="645.28998"
                  cx="431.26001"
                />
                <text
                  id="text1323"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="423.5"
                  y="649.62549"
                >
                  52
                </text>
              </g>
              <g
                id="gate_52_09_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['9-52']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['9-52'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(9, 52)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 52)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="645.28998"
                  cx="431.26001"
                />
                <circle
                  id="circle1321"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="645.28998"
                  cx="431.26001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      52
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="645.28998"
                  cx="431.26001"
                />
                <text
                  id="text1323"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="423.5"
                  y="649.62549"
                >
                  52
                </text>
              </g>
              <g
                id="gate_51_25_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['25-51']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['25-51'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(25, 51)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 51)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="449.98999"
                  cx="493.95999"
                />
                <circle
                  id="circle1326"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="449.98999"
                  cx="493.95999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      51
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="449.98999"
                  cx="493.95999"
                />
                <text
                  id="text1328"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="487.3"
                  y="454.25211"
                >
                  51
                </text>
              </g>
              <g
                id="gate_51_25_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['25-51']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['25-51'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(25, 51)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 51)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="449.98999"
                  cx="493.95999"
                />
                <circle
                  id="circle1326"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="449.98999"
                  cx="493.95999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      51
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="449.98999"
                  cx="493.95999"
                />
                <text
                  id="text1328"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="487.3"
                  y="454.25211"
                >
                  51
                </text>
              </g>
              <g
                id="gate_50_27_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['27-50']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['27-50'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(27, 50)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 50)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="558.59003"
                  cx="279.06"
                />
                <circle
                  id="circle1331"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="558.59003"
                  cx="279.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      50
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="558.59003"
                  cx="279.06"
                />
                <text
                  id="text1333"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="271.5"
                  y="562.92554"
                >
                  50
                </text>
              </g>
              <g
                id="gate_50_27_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['27-50']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['27-50'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(27, 50)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 50)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="558.59003"
                  cx="279.06"
                />
                <circle
                  id="circle1331"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="558.59003"
                  cx="279.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      50
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="558.59003"
                  cx="279.06"
                />
                <text
                  id="text1333"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="271.5"
                  y="562.92554"
                >
                  50
                </text>
              </g>
              <g
                id="gate_49_19_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['19-49']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['19-49'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(19, 49)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 49)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="573.09003"
                  cx="562.85999"
                />
                <circle
                  id="circle1336"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="573.09003"
                  cx="562.85999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      49
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="573.09003"
                  cx="562.85999"
                />
                <text
                  id="text1338"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="555.4"
                  y="577.43127"
                >
                  49
                </text>
              </g>
              <g
                id="gate_49_19_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['19-49']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['19-49'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(19, 49)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 49)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="573.09003"
                  cx="562.85999"
                />
                <circle
                  id="circle1336"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="573.09003"
                  cx="562.85999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      49
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="573.09003"
                  cx="562.85999"
                />
                <text
                  id="text1338"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="555.4"
                  y="577.43127"
                >
                  49
                </text>
              </g>
              <g
                id="gate_48_16_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['16-48']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['16-48'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(16, 48)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 48)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="521.59003"
                  cx="215.06"
                />
                <circle
                  id="circle1341"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="521.59003"
                  cx="215.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      48
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="521.59003"
                  cx="215.06"
                />
                <text
                  id="text1343"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="207.7"
                  y="525.92554"
                >
                  48
                </text>
              </g>
              <g
                id="gate_48_16_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['16-48']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['16-48'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(16, 48)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 48)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="521.59003"
                  cx="215.06"
                />
                <circle
                  id="circle1341"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="521.59003"
                  cx="215.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      48
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="521.59003"
                  cx="215.06"
                />
                <text
                  id="text1343"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="207.7"
                  y="525.92554"
                >
                  48
                </text>
              </g>
              <g
                id="gate_47_64_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['47-64']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['47-64'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(47, 64)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 47)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="123.79"
                  cx="387.56"
                />
                <circle
                  id="circle1346"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="123.79"
                  cx="387.56"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      47
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="123.79"
                  cx="387.56"
                />
                <text
                  id="text1348"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="379.7"
                  y="128"
                >
                  47
                </text>
              </g>
              <g
                id="gate_47_64_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['47-64']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['47-64'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(47, 64)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 47)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="123.79"
                  cx="387.56"
                />
                <circle
                  id="circle1346"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="123.79"
                  cx="387.56"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      47
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="123.79"
                  cx="387.56"
                />
                <text
                  id="text1348"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="379.7"
                  y="128"
                >
                  47
                </text>
              </g>
              <g
                id="gate_46_29_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['29-46']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['29-46'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(29, 46)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 46)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="426.19"
                  cx="429.16"
                />
                <circle
                  id="circle1351"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="426.19"
                  cx="429.16"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      46
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="426.19"
                  cx="429.16"
                />
                <text
                  id="text1353"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="421.7"
                  y="430.51883"
                >
                  46
                </text>
              </g>
              <g
                id="gate_46_29_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['29-46']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['29-46'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(29, 46)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 46)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="426.19"
                  cx="429.16"
                />
                <circle
                  id="circle1351"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="426.19"
                  cx="429.16"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      46
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="426.19"
                  cx="429.16"
                />
                <text
                  id="text1353"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="421.7"
                  y="430.51883"
                >
                  46
                </text>
              </g>
              <g
                id="gate_45_21_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['21-45']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['21-45'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(21, 45)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 45)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="302.98999"
                  cx="442.35999"
                />
                <circle
                  id="circle1356"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="302.98999"
                  cx="442.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      45
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="302.98999"
                  cx="442.35999"
                />
                <text
                  id="text1358"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="435"
                  y="307.25211"
                >
                  45
                </text>
              </g>
              <g
                id="gate_45_21_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['21-45']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['21-45'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(21, 45)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 45)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="302.98999"
                  cx="442.35999"
                />
                <circle
                  id="circle1356"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="302.98999"
                  cx="442.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      45
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="302.98999"
                  cx="442.35999"
                />
                <text
                  id="text1358"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="435"
                  y="307.25211"
                >
                  45
                </text>
              </g>
              <g
                id="gate_44_26_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['26-44']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['26-44'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(26, 44)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 44)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="545.19"
                  cx="256.35999"
                />
                <circle
                  id="circle1361"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="545.19"
                  cx="256.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      44
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="545.19"
                  cx="256.35999"
                />
                <text
                  id="text1363"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="248.5"
                  y="549.52551"
                >
                  44
                </text>
              </g>
              <g
                id="gate_44_26_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['26-44']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['26-44'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(26, 44)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 44)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="545.19"
                  cx="256.35999"
                />
                <circle
                  id="circle1361"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="545.19"
                  cx="256.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      44
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="545.19"
                  cx="256.35999"
                />
                <text
                  id="text1363"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="248.5"
                  y="549.52551"
                >
                  44
                </text>
              </g>
              <g
                id="gate_43_23_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['23-43']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['23-43'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(23, 43)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 43)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="190.78999"
                  cx="408.45999"
                />
                <circle
                  id="circle1366"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="190.78999"
                  cx="408.45999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      43
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="190.78999"
                  cx="408.45999"
                />
                <text
                  id="text1368"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="401"
                  y="195"
                >
                  43
                </text>
              </g>
              <g
                id="gate_43_23_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['23-43']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['23-43'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(23, 43)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 43)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="190.78999"
                  cx="408.45999"
                />
                <circle
                  id="circle1366"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="190.78999"
                  cx="408.45999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      43
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="190.78999"
                  cx="408.45999"
                />
                <text
                  id="text1368"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="401"
                  y="195"
                >
                  43
                </text>
              </g>
              <g
                id="gate_42_53_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['42-53']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['42-53'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(42, 53)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 42)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="611.39001"
                  cx="387.26001"
                />
                <circle
                  id="circle1371"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="611.39001"
                  cx="387.26001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      42
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="611.39001"
                  cx="387.26001"
                />
                <text
                  id="text1373"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="379.6"
                  y="615.79889"
                >
                  42
                </text>
              </g>
              <g
                id="gate_42_53_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['42-53']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['42-53'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(42, 53)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 42)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="611.39001"
                  cx="387.26001"
                />
                <circle
                  id="circle1371"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="611.39001"
                  cx="387.26001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      42
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="611.39001"
                  cx="387.26001"
                />
                <text
                  id="text1373"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="379.6"
                  y="615.79889"
                >
                  42
                </text>
              </g>
              <g
                id="gate_41_30_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['30-41']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['30-41'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(30, 41)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 41)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="705.59003"
                  cx="443.35999"
                />
                <circle
                  id="circle1376"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="705.59003"
                  cx="443.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      41
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="705.59003"
                  cx="443.35999"
                />
                <text
                  id="text1378"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="436"
                  y="709.92554"
                >
                  41
                </text>
              </g>
              <g
                id="gate_41_30_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['30-41']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['30-41'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(30, 41)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 41)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="705.59003"
                  cx="443.35999"
                />
                <circle
                  id="circle1376"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="705.59003"
                  cx="443.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      41
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="705.59003"
                  cx="443.35999"
                />
                <text
                  id="text1378"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="436"
                  y="709.92554"
                >
                  41
                </text>
              </g>
              <g
                id="gate_40_37_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['37-40']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['37-40'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(37, 40)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 40)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="484.79001"
                  cx="541.96002"
                />
                <circle
                  id="circle1381"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="484.79001"
                  cx="541.96002"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      40
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="484.79001"
                  cx="541.96002"
                />
                <text
                  id="text1383"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="534"
                  y="489.12552"
                >
                  40
                </text>
              </g>
              <g
                id="gate_40_37_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['37-40']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['37-40'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(37, 40)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 40)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="484.79001"
                  cx="541.96002"
                />
                <circle
                  id="circle1381"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="484.79001"
                  cx="541.96002"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      40
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="484.79001"
                  cx="541.96002"
                />
                <text
                  id="text1383"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="534"
                  y="489.12552"
                >
                  40
                </text>
              </g>
              <g
                id="gate_39_55_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['39-55']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['39-55'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(39, 55)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 39)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="682.89001"
                  cx="443.66"
                />
                <circle
                  id="circle1386"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="682.89001"
                  cx="443.66"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      39
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="682.89001"
                  cx="443.66"
                />
                <text
                  id="text1388"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="436"
                  y="687.22552"
                >
                  39
                </text>
              </g>
              <g
                id="gate_39_55_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['39-55']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['39-55'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(39, 55)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 39)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="682.89001"
                  cx="443.66"
                />
                <circle
                  id="circle1386"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="682.89001"
                  cx="443.66"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      39
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="682.89001"
                  cx="443.66"
                />
                <text
                  id="text1388"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="436"
                  y="687.22552"
                >
                  39
                </text>
              </g>
              <g
                id="gate_38_28_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['28-38']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['28-38'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(28, 38)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 38)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="682.19"
                  cx="374.66"
                />
                <circle
                  id="circle1391"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="682.19"
                  cx="374.66"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      38
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="682.19"
                  cx="374.66"
                />
                <text
                  id="text1393"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="367.2"
                  y="686.52551"
                >
                  38
                </text>
              </g>
              <g
                id="gate_38_28_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['28-38']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['28-38'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(28, 38)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 38)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="682.19"
                  cx="374.66"
                />
                <circle
                  id="circle1391"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="682.19"
                  cx="374.66"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      38
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="682.19"
                  cx="374.66"
                />
                <text
                  id="text1393"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="367.2"
                  y="686.52551"
                >
                  38
                </text>
              </g>
              <g
                id="gate_37_40_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['37-40']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['37-40'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(37, 40)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 37)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="547.09003"
                  cx="558.85999"
                />
                <circle
                  id="circle1396"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="547.09003"
                  cx="558.85999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      37
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="547.09003"
                  cx="558.85999"
                />
                <text
                  id="text1398"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="551.5"
                  y="551.42554"
                >
                  37
                </text>
              </g>
              <g
                id="gate_37_40_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['37-40']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['37-40'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(37, 40)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 37)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="547.09003"
                  cx="558.85999"
                />
                <circle
                  id="circle1396"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="547.09003"
                  cx="558.85999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      37
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="547.09003"
                  cx="558.85999"
                />
                <text
                  id="text1398"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="551.5"
                  y="551.42554"
                >
                  37
                </text>
              </g>
              <g
                id="gate_36_35_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['35-36']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['35-36'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(35, 36)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 36)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="523.09003"
                  cx="600.65997"
                />
                <circle
                  id="circle1401"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="523.09003"
                  cx="600.65997"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      36
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="523.09003"
                  cx="600.65997"
                />
                <text
                  id="text1403"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="593.75201"
                  y="527.42554"
                >
                  36
                </text>
              </g>
              <g
                id="gate_36_35_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['35-36']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['35-36'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(35, 36)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 36)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="523.09003"
                  cx="600.65997"
                />
                <circle
                  id="circle1401"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="523.09003"
                  cx="600.65997"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      36
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="523.09003"
                  cx="600.65997"
                />
                <text
                  id="text1403"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="593.75201"
                  y="527.42554"
                >
                  36
                </text>
              </g>
              <g
                id="gate_35_36_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['35-36']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['35-36'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(35, 36)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 35)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="263.98999"
                  cx="447.35999"
                />
                <circle
                  id="circle1406"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="263.98999"
                  cx="447.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      35
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="263.98999"
                  cx="447.35999"
                />
                <text
                  id="text1408"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="440.67883"
                  y="268.3255"
                >
                  35
                </text>
              </g>
              <g
                id="gate_35_36_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['35-36']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['35-36'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(35, 36)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 35)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="263.98999"
                  cx="447.35999"
                />
                <circle
                  id="circle1406"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="263.98999"
                  cx="447.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      35
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="263.98999"
                  cx="447.35999"
                />
                <text
                  id="text1408"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="440.67883"
                  y="268.3255"
                >
                  35
                </text>
              </g>
              <g
                id="gate_34_20_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['20-34']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['20-34'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(20, 34)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 34)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="558.09003"
                  cx="370.06"
                />
                <circle
                  id="circle1411"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="558.09003"
                  cx="370.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      34
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="558.09003"
                  cx="370.06"
                />
                <text
                  id="text1413"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="362.5"
                  y="562.42554"
                >
                  34
                </text>
              </g>
              <g
                id="gate_34_20_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['20-34']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['20-34'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(20, 34)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 34)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="558.09003"
                  cx="370.06"
                />
                <circle
                  id="circle1411"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="558.09003"
                  cx="370.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      34
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="558.09003"
                  cx="370.06"
                />
                <text
                  id="text1413"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="362.5"
                  y="562.42554"
                >
                  34
                </text>
              </g>
              <g
                id="gate_33_13_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['13-33']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['13-33'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(13, 33)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 33)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="315.98999"
                  cx="428.06"
                />
                <circle
                  id="circle1416"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="315.98999"
                  cx="428.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      33
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="315.98999"
                  cx="428.06"
                />
                <text
                  id="text1418"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="421"
                  y="320.3255"
                >
                  33
                </text>
              </g>
              <g
                id="gate_33_13_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['13-33']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['13-33'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(13, 33)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 33)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="315.98999"
                  cx="428.06"
                />
                <circle
                  id="circle1416"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="315.98999"
                  cx="428.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      33
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="315.98999"
                  cx="428.06"
                />
                <text
                  id="text1418"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="421"
                  y="320.3255"
                >
                  33
                </text>
              </g>
              <g
                id="gate_32_54_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['32-54']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['32-54'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(32, 54)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 32)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="572.19"
                  cx="256.35999"
                />
                <circle
                  id="circle1421"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="572.19"
                  cx="256.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      32
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="572.19"
                  cx="256.35999"
                />
                <text
                  id="text1423"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="249"
                  y="576.52551"
                >
                  32
                </text>
              </g>
              <g
                id="gate_32_54_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['32-54']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['32-54'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(32, 54)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 32)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="572.19"
                  cx="256.35999"
                />
                <circle
                  id="circle1421"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="572.19"
                  cx="256.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      32
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="572.19"
                  cx="256.35999"
                />
                <text
                  id="text1423"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="249"
                  y="576.52551"
                >
                  32
                </text>
              </g>
              <g
                id="gate_31_07_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['7-31']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['7-31'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(7, 31)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 31)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="315.79001"
                  cx="388.95999"
                />
                <circle
                  id="circle1426"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="315.79001"
                  cx="388.95999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      31
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="315.79001"
                  cx="388.95999"
                />
                <text
                  id="text1428"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="382"
                  y="320.5"
                >
                  31
                </text>
              </g>
              <g
                id="gate_31_07_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['7-31']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['7-31'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(7, 31)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 31)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="315.79001"
                  cx="388.95999"
                />
                <circle
                  id="circle1426"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="315.79001"
                  cx="388.95999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      31
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="315.79001"
                  cx="388.95999"
                />
                <text
                  id="text1428"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="382"
                  y="320.5"
                >
                  31
                </text>
              </g>
              <g
                id="gate_30_41_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['30-41']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['30-41'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(30, 41)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 30)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="596.09003"
                  cx="603.35999"
                />
                <circle
                  id="circle1431"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="596.09003"
                  cx="603.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      30
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="596.09003"
                  cx="603.35999"
                />
                <text
                  id="text1433"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="596"
                  y="600.42554"
                >
                  30
                </text>
              </g>
              <g
                id="gate_30_41_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['30-41']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['30-41'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(30, 41)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 30)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="596.09003"
                  cx="603.35999"
                />
                <circle
                  id="circle1431"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="596.09003"
                  cx="603.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      30
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="596.09003"
                  cx="603.35999"
                />
                <text
                  id="text1433"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="596"
                  y="600.42554"
                >
                  30
                </text>
              </g>
              <g
                id="gate_29_46_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['29-46']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['29-46'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(29, 46)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 29)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="530.89001"
                  cx="430.06"
                />
                <circle
                  id="circle1436"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="530.89001"
                  cx="430.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      29
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="530.89001"
                  cx="430.06"
                />
                <text
                  id="text1438"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="422.7"
                  y="535.23126"
                >
                  29
                </text>
              </g>
              <g
                id="gate_29_46_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['29-46']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['29-46'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(29, 46)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 29)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="530.89001"
                  cx="430.06"
                />
                <circle
                  id="circle1436"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="530.89001"
                  cx="430.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      29
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="530.89001"
                  cx="430.06"
                />
                <text
                  id="text1438"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="422.7"
                  y="535.23126"
                >
                  29
                </text>
              </g>
              <g
                id="gate_28_38_channel_on_chart"
                v-if="chartBodygraphChart.channels2['28-38']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['28-38'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(28, 38)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 28)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="583.89001"
                  cx="234.66"
                />
                <circle
                  id="circle1441"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="583.89001"
                  cx="234.66"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      28
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="583.89001"
                  cx="234.66"
                />
                <text
                  id="text1443"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="227"
                  y="588.22552"
                >
                  28
                </text>
              </g>
              <g
                id="gate_28_38_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['28-38']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['28-38'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(28, 38)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 28)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="583.89001"
                  cx="234.66"
                />
                <circle
                  id="circle1441"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="583.89001"
                  cx="234.66"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      28
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="583.89001"
                  cx="234.66"
                />
                <text
                  id="text1443"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="227"
                  y="588.22552"
                >
                  28
                </text>
              </g>
              <g
                id="gate_27_50_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['27-50']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['27-50'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(27, 50)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 27)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="590.89001"
                  cx="375.06"
                />
                <circle
                  id="circle1446"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="590.89001"
                  cx="375.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      27
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="590.89001"
                  cx="375.06"
                />
                <text
                  id="text1448"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="368.21878"
                  y="595.29889"
                >
                  27
                </text>
              </g>
              <g
                id="gate_27_50_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['27-50']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['27-50'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(27, 50)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 27)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="590.89001"
                  cx="375.06"
                />
                <circle
                  id="circle1446"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="590.89001"
                  cx="375.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      27
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="590.89001"
                  cx="375.06"
                />
                <text
                  id="text1448"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="368.21878"
                  y="595.29889"
                >
                  27
                </text>
              </g>
              <g
                id="gate_26_44_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['26-44']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['26-44'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(26, 44)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 26)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="467.48999"
                  cx="470.45999"
                />
                <circle
                  id="circle1451"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="467.48999"
                  cx="470.45999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      26
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="467.48999"
                  cx="470.45999"
                />
                <text
                  id="text1453"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="463"
                  y="471.8255"
                >
                  26
                </text>
              </g>
              <g
                id="gate_26_44_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['26-44']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['26-44'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(26, 44)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 26)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="467.48999"
                  cx="470.45999"
                />
                <circle
                  id="circle1451"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="467.48999"
                  cx="470.45999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      26
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="467.48999"
                  cx="470.45999"
                />
                <text
                  id="text1453"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="463"
                  y="471.8255"
                >
                  26
                </text>
              </g>
              <g
                id="gate_25_51_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['25-51']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['25-51'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(25, 51)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 25)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="401.98999"
                  cx="452.45999"
                />
                <circle
                  id="circle1456"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="401.98999"
                  cx="452.45999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      25
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="401.98999"
                  cx="452.45999"
                />
                <text
                  id="text1458"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="445"
                  y="406.3255"
                >
                  25
                </text>
              </g>
              <g
                id="gate_25_51_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['25-51']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['25-51'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(25, 51)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 25)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="401.98999"
                  cx="452.45999"
                />
                <circle
                  id="circle1456"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="401.98999"
                  cx="452.45999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      25
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="401.98999"
                  cx="452.45999"
                />
                <text
                  id="text1458"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="445"
                  y="406.3255"
                >
                  25
                </text>
              </g>
              <g
                id="gate_24_61_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['24-61']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['24-61'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(24, 61)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 24)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="123.59"
                  cx="409.56"
                />
                <circle
                  id="circle1461"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="123.59"
                  cx="409.56"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      24
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="123.59"
                  cx="409.56"
                />
                <text
                  id="text1463"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="402.3"
                  y="128"
                >
                  24
                </text>
              </g>
              <g
                id="gate_24_61_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['24-61']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['24-61'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(24, 61)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 24)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="123.59"
                  cx="409.56"
                />
                <circle
                  id="circle1461"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="123.59"
                  cx="409.56"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      24
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="123.59"
                  cx="409.56"
                />
                <text
                  id="text1463"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="402.3"
                  y="128"
                >
                  24
                </text>
              </g>
              <g
                id="gate_23_43_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['23-43']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['23-43'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(23, 43)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 23)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="238.69"
                  cx="408.66"
                />
                <circle
                  id="circle1466"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="238.69"
                  cx="408.66"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      23
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="238.69"
                  cx="408.66"
                />
                <text
                  id="text1468"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="401.4"
                  y="243.0255"
                >
                  23
                </text>
              </g>
              <g
                id="gate_23_43_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['23-43']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['23-43'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(23, 43)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 23)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="238.69"
                  cx="408.66"
                />
                <circle
                  id="circle1466"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="238.69"
                  cx="408.66"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      23
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="238.69"
                  cx="408.66"
                />
                <text
                  id="text1468"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="401.4"
                  y="243.0255"
                >
                  23
                </text>
              </g>
              <g
                id="gate_22_12_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['12-22']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['12-22'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(12, 22)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 22)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="534.28998"
                  cx="580.35999"
                />
                <circle
                  id="circle1471"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="534.28998"
                  cx="580.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      22
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="534.28998"
                  cx="580.35999"
                />
                <text
                  id="text1473"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="573"
                  y="538.69885"
                >
                  22
                </text>
              </g>
              <g
                id="gate_22_12_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['12-22']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['12-22'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(12, 22)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 22)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="534.28998"
                  cx="580.35999"
                />
                <circle
                  id="circle1471"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="534.28998"
                  cx="580.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      22
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="534.28998"
                  cx="580.35999"
                />
                <text
                  id="text1473"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="573"
                  y="538.69885"
                >
                  22
                </text>
              </g>
              <g
                id="gate_21_45_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['21-45']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['21-45'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(21, 45)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 21)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="434.39001"
                  cx="515.06"
                />
                <circle
                  id="circle1476"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="434.39001"
                  cx="515.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      21
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="434.39001"
                  cx="515.06"
                />
                <text
                  id="text1478"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="508.6"
                  y="438.79889"
                >
                  21
                </text>
              </g>
              <g
                id="gate_21_45_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['21-45']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['21-45'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(21, 45)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 21)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="434.39001"
                  cx="515.06"
                />
                <circle
                  id="circle1476"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="434.39001"
                  cx="515.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      21
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="434.39001"
                  cx="515.06"
                />
                <text
                  id="text1478"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="508.6"
                  y="438.79889"
                >
                  21
                </text>
              </g>
              <g
                id="gate_20_57_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['20-57']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['20-57'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(20, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 20)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="286.48999"
                  cx="368.56"
                />
                <circle
                  id="circle1481"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="286.48999"
                  cx="368.56"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      20
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="286.48999"
                  cx="368.56"
                />
                <text
                  id="text1483"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="361.2"
                  y="290.8255"
                >
                  20
                </text>
              </g>
              <g
                id="gate_20_57_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['20-57']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['20-57'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(20, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 20)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="286.48999"
                  cx="368.56"
                />
                <circle
                  id="circle1481"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="286.48999"
                  cx="368.56"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      20
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="286.48999"
                  cx="368.56"
                />
                <text
                  id="text1483"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="361.2"
                  y="290.8255"
                >
                  20
                </text>
              </g>
              <g
                id="gate_19_49_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['19-49']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['19-49'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(19, 49)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 19)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="662.39001"
                  cx="443.76001"
                />
                <circle
                  id="circle1486"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="662.39001"
                  cx="443.76001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      19
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="662.39001"
                  cx="443.76001"
                />
                <text
                  id="text1488"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="435.5"
                  y="666.73126"
                >
                  19
                </text>
              </g>
              <g
                id="gate_19_49_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['19-49']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['19-49'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(19, 49)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,19.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 19)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="662.39001"
                  cx="443.76001"
                />
                <circle
                  id="circle1486"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="662.39001"
                  cx="443.76001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      19
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="662.39001"
                  cx="443.76001"
                />
                <text
                  id="text1488"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="435.5"
                  y="666.73126"
                >
                  19
                </text>
              </g>
              <g
                id="gate_18_58_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['18-58']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['18-58'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(18, 58)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 18)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="595.19"
                  cx="215.36"
                />
                <circle
                  id="circle1491"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="595.19"
                  cx="215.36"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      18
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="595.19"
                  cx="215.36"
                />
                <text
                  id="text1493"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="207.5"
                  y="599.52551"
                >
                  18
                </text>
              </g>
              <g
                id="gate_18_58_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['18-58']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['18-58'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(18, 58)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 18)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="595.19"
                  cx="215.36"
                />
                <circle
                  id="circle1491"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="595.19"
                  cx="215.36"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      18
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="595.19"
                  cx="215.36"
                />
                <text
                  id="text1493"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="207.5"
                  y="599.52551"
                >
                  18
                </text>
              </g>
              <g
                id="gate_17_62_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['17-62']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['17-62'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(17, 62)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 17)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="159.69"
                  cx="389.06"
                />
                <circle
                  id="circle1496"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="159.69"
                  cx="389.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      17
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="159.69"
                  cx="389.06"
                />
                <text
                  id="text1498"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="381.4"
                  y="164"
                >
                  17
                </text>
              </g>
              <g
                id="gate_17_62_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['17-62']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['17-62'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(17, 62)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 17)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="159.69"
                  cx="389.06"
                />
                <circle
                  id="circle1496"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="159.69"
                  cx="389.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      17
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="159.69"
                  cx="389.06"
                />
                <text
                  id="text1498"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="381.4"
                  y="164"
                >
                  17
                </text>
              </g>
              <g
                id="gate_16_48_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['16-48']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['16-48'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(16, 48)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 16)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="263.09"
                  cx="371.06"
                />
                <circle
                  id="circle1501"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="263.09"
                  cx="371.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      16
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="263.09"
                  cx="371.06"
                />
                <text
                  id="text1503"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="363.4"
                  y="267.41882"
                >
                  16
                </text>
              </g>
              <g
                id="gate_16_48_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['16-48']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['16-48'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(16, 48)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 16)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="263.09"
                  cx="371.06"
                />
                <circle
                  id="circle1501"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="263.09"
                  cx="371.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      16
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="263.09"
                  cx="371.06"
                />
                <text
                  id="text1503"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="363.4"
                  y="267.41882"
                >
                  16
                </text>
              </g>
              <g
                id="gate_15_05_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['5-15']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['5-15'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(5, 15)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 15)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="426.48999"
                  cx="388.35999"
                />
                <circle
                  id="circle1506"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="426.48999"
                  cx="388.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      15
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="426.48999"
                  cx="388.35999"
                />
                <text
                  id="text1508"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="380.7"
                  y="430.75211"
                >
                  15
                </text>
              </g>
              <g
                id="gate_15_05_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['5-15']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['5-15'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(5, 15)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 15)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="426.48999"
                  cx="388.35999"
                />
                <circle
                  id="circle1506"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="426.48999"
                  cx="388.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      15
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="426.48999"
                  cx="388.35999"
                />
                <text
                  id="text1508"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="380.7"
                  y="430.75211"
                >
                  15
                </text>
              </g>
              <g
                id="gate_14_02_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['2-14']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['2-14'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(2, 14)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 14)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="530.59003"
                  cx="408.76001"
                />
                <circle
                  id="circle1511"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="530.59003"
                  cx="408.76001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      14
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="530.59003"
                  cx="408.76001"
                />
                <text
                  id="text1513"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="400.7"
                  y="534.92554"
                >
                  14
                </text>
              </g>
              <g
                id="gate_14_02_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['2-14']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['2-14'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(2, 14)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 14)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="530.59003"
                  cx="408.76001"
                />
                <circle
                  id="circle1511"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="530.59003"
                  cx="408.76001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      14
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="530.59003"
                  cx="408.76001"
                />
                <text
                  id="text1513"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="400.7"
                  y="534.92554"
                >
                  14
                </text>
              </g>
              <g
                id="gate_13_33_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['13-33']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['13-33'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(13, 33)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 13)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="375.29001"
                  cx="427.85999"
                />
                <circle
                  id="circle1516"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="375.29001"
                  cx="427.85999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      13
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="375.29001"
                  cx="427.85999"
                />
                <text
                  id="text1518"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="420"
                  y="379.62552"
                >
                  13
                </text>
              </g>
              <g
                id="gate_13_33_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['13-33']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['13-33'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(13, 33)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 13)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="375.29001"
                  cx="427.85999"
                />
                <circle
                  id="circle1516"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="375.29001"
                  cx="427.85999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      13
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="375.29001"
                  cx="427.85999"
                />
                <text
                  id="text1518"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="420"
                  y="379.62552"
                >
                  13
                </text>
              </g>
              <g
                id="gate_12_22_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['12-22']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['12-22'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(12, 22)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 12)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="285.29001"
                  cx="449.95999"
                />
                <circle
                  id="circle1521"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="285.29001"
                  cx="449.95999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      12
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="285.29001"
                  cx="449.95999"
                />
                <text
                  id="text1523"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="442.3"
                  y="289.69888"
                >
                  12
                </text>
              </g>
              <g
                id="gate_12_22_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['12-22']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['12-22'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(12, 22)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 12)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="285.29001"
                  cx="449.95999"
                />
                <circle
                  id="circle1521"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="285.29001"
                  cx="449.95999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      12
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="285.29001"
                  cx="449.95999"
                />
                <text
                  id="text1523"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="442.3"
                  y="289.69888"
                >
                  12
                </text>
              </g>
              <g
                id="gate_11_56_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['11-56']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['11-56'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(11, 56)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 11)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="158.59"
                  cx="429.56"
                />
                <circle
                  id="circle1526"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="158.59"
                  cx="429.56"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      11
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="158.59"
                  cx="429.56"
                />
                <text
                  id="text1528"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="422.4"
                  y="163"
                >
                  11
                </text>
              </g>
              <g
                id="gate_11_56_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['11-56']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['11-56'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(11, 56)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 11)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="158.59"
                  cx="429.56"
                />
                <circle
                  id="circle1526"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="158.59"
                  cx="429.56"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      11
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="158.59"
                  cx="429.56"
                />
                <text
                  id="text1528"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="422.4"
                  y="163"
                >
                  11
                </text>
              </g>
              <g
                id="gate_10_57_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['10-57']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['10-57'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(10, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 10)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="401.69"
                  cx="365.16"
                />
                <circle
                  id="circle1531"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="401.69"
                  cx="365.16"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      10
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="401.69"
                  cx="365.16"
                />
                <text
                  id="text1533"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="357.3"
                  y="406.02551"
                >
                  10
                </text>
              </g>
              <g
                id="gate_10_57_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['10-57']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['10-57'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(10, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 10)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="401.69"
                  cx="365.16"
                />
                <circle
                  id="circle1531"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="401.69"
                  cx="365.16"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      10
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="401.69"
                  cx="365.16"
                />
                <text
                  id="text1533"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="357.3"
                  y="406.02551"
                >
                  10
                </text>
              </g>
              <g
                id="gate_10_20_34_channel_on_chart1"
                v-if="
                  chartBodygraphChart.channels2['10-20'] &&
                    chartBodygraphChart.channels2['10-34']
                "
                @click="
                  openMultiTooltipDialog([
                    chartBodygraphChart.channels2['10-20'],
                    chartBodygraphChart.channels2['10-34']
                  ])
                "
                @mouseover="searchAcPlanets(10, 20, 34)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="401.69"
                  cx="365.16"
                />
              </g>
              <g
                id="gate_10_20_34_channel_on_chart2"
                v-if="
                  chartBodygraphChart2.channels2['10-20'] &&
                    chartBodygraphChart2.channels2['10-34']
                "
                @click="
                  openMultiTooltipDialog([
                    chartBodygraphChart2.channels2['10-20'],
                    chartBodygraphChart2.channels2['10-34']
                  ])
                "
                @mouseover="searchAcPlanets(10, 20, 34)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="401.69"
                  cx="365.16"
                />
              </g>
              <g
                id="gate_10_20_57_channel_on_chart1"
                v-if="
                  chartBodygraphChart.channels2['10-20'] &&
                    chartBodygraphChart.channels2['10-57']
                "
                @click="
                  openMultiTooltipDialog([
                    chartBodygraphChart.channels2['10-20'],
                    chartBodygraphChart.channels2['10-57']
                  ])
                "
                @mouseover="searchAcPlanets(10, 20, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="401.69"
                  cx="365.16"
                />
              </g>
              <g
                id="gate_10_20_57_channel_on_chart2"
                v-if="
                  chartBodygraphChart2.channels2['10-20'] &&
                    chartBodygraphChart2.channels2['10-57']
                "
                @click="
                  openMultiTooltipDialog([
                    chartBodygraphChart2.channels2['10-20'],
                    chartBodygraphChart2.channels2['10-57']
                  ])
                "
                @mouseover="searchAcPlanets(10, 20, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="401.69"
                  cx="365.16"
                />
              </g>
              <g
                id="gate_10_34_57_channel_on_chart1"
                v-if="
                  chartBodygraphChart.channels2['10-34'] &&
                    chartBodygraphChart.channels2['10-57']
                "
                @click="
                  openMultiTooltipDialog([
                    chartBodygraphChart.channels2['10-34'],
                    chartBodygraphChart.channels2['10-57']
                  ])
                "
                @mouseover="searchAcPlanets(10, 34, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="401.69"
                  cx="365.16"
                />
              </g>
              <g
                id="gate_10_34_57_channel_on_chart2"
                v-if="
                  chartBodygraphChart2.channels2['10-34'] &&
                    chartBodygraphChart2.channels2['10-57']
                "
                @click="
                  openMultiTooltipDialog([
                    chartBodygraphChart2.channels2['10-34'],
                    chartBodygraphChart2.channels2['10-57']
                  ])
                "
                @mouseover="searchAcPlanets(10, 34, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="401.69"
                  cx="365.16"
                />
              </g>
              <g
                id="gate_10_20_34_57_channel_on_chart1"
                v-if="
                  chartBodygraphChart.channels2['10-20'] &&
                    chartBodygraphChart.channels2['10-34'] &&
                    chartBodygraphChart.channels2['10-57']
                "
                @click="
                  openMultiTooltipDialog([
                    chartBodygraphChart.channels2['10-20'],
                    chartBodygraphChart.channels2['10-34'],
                    chartBodygraphChart.channels2['10-57']
                  ])
                "
                @mouseover="searchAcPlanets(10, 20, 34, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="401.69"
                  cx="365.16"
                />
              </g>
              <g
                id="gate_10_20_34_57_channel_on_chart2"
                v-if="
                  chartBodygraphChart2.channels2['10-20'] &&
                    chartBodygraphChart2.channels2['10-34'] &&
                    chartBodygraphChart2.channels2['10-57']
                "
                @click="
                  openMultiTooltipDialog([
                    chartBodygraphChart2.channels2['10-20'],
                    chartBodygraphChart2.channels2['10-34'],
                    chartBodygraphChart2.channels2['10-57']
                  ])
                "
                @mouseover="searchAcPlanets(10, 20, 34, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="401.69"
                  cx="365.16"
                />
              </g>
              <g
                id="gate_20_10_34_channel_on_chart1"
                v-if="
                  chartBodygraphChart.channels2['10-20'] &&
                    chartBodygraphChart.channels2['20-34']
                "
                @click="
                  openMultiTooltipDialog([
                    chartBodygraphChart.channels2['10-20'],
                    chartBodygraphChart.channels2['20-34']
                  ])
                "
                @mouseover="searchAcPlanets(10, 20, 34)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="286.48999"
                  cx="368.56"
                />
              </g>
              <g
                id="gate_20_10_34_channel_on_chart2"
                v-if="
                  chartBodygraphChart2.channels2['10-20'] &&
                    chartBodygraphChart2.channels2['20-34']
                "
                @click="
                  openMultiTooltipDialog([
                    chartBodygraphChart2.channels2['10-20'],
                    chartBodygraphChart2.channels2['20-34']
                  ])
                "
                @mouseover="searchAcPlanets(10, 20, 34)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="286.48999"
                  cx="368.56"
                />
              </g>
              <g
                id="gate_20_10_57_channel_on_chart1"
                v-if="
                  chartBodygraphChart.channels2['10-20'] &&
                    chartBodygraphChart.channels2['20-57']
                "
                @click="
                  openMultiTooltipDialog([
                    chartBodygraphChart.channels2['10-20'],
                    chartBodygraphChart.channels2['20-57']
                  ])
                "
                @mouseover="searchAcPlanets(10, 20, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="286.48999"
                  cx="368.56"
                />
              </g>
              <g
                id="gate_20_10_57_channel_on_chart2"
                v-if="
                  chartBodygraphChart2.channels2['10-20'] &&
                    chartBodygraphChart2.channels2['20-57']
                "
                @click="
                  openMultiTooltipDialog([
                    chartBodygraphChart2.channels2['10-20'],
                    chartBodygraphChart2.channels2['20-57']
                  ])
                "
                @mouseover="searchAcPlanets(10, 20, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="286.48999"
                  cx="368.56"
                />
              </g>
              <g
                id="gate_20_34_57_channel_on_chart1"
                v-if="
                  chartBodygraphChart.channels2['20-34'] &&
                    chartBodygraphChart.channels2['20-57']
                "
                @click="
                  openMultiTooltipDialog([
                    chartBodygraphChart.channels2['20-34'],
                    chartBodygraphChart.channels2['20-57']
                  ])
                "
                @mouseover="searchAcPlanets(20, 34, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="286.48999"
                  cx="368.56"
                />
              </g>
              <g
                id="gate_20_34_57_channel_on_chart2"
                v-if="
                  chartBodygraphChart2.channels2['20-34'] &&
                    chartBodygraphChart2.channels2['20-57']
                "
                @click="
                  openMultiTooltipDialog([
                    chartBodygraphChart2.channels2['20-34'],
                    chartBodygraphChart2.channels2['20-57']
                  ])
                "
                @mouseover="searchAcPlanets(20, 34, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="286.48999"
                  cx="368.56"
                />
              </g>
              <g
                id="gate_20_10_34_57_channel_on_chart1"
                v-if="
                  chartBodygraphChart.channels2['10-20'] &&
                    chartBodygraphChart.channels2['20-34'] &&
                    chartBodygraphChart.channels2['20-57']
                "
                @click="
                  openMultiTooltipDialog([
                    chartBodygraphChart.channels2['10-20'],
                    chartBodygraphChart.channels2['20-34'],
                    chartBodygraphChart.channels2['20-57']
                  ])
                "
                @mouseover="searchAcPlanets(10, 20, 34, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="286.48999"
                  cx="368.56"
                />
              </g>
              <g
                id="gate_20_10_34_57_channel_on_chart2"
                v-if="
                  chartBodygraphChart2.channels2['10-20'] &&
                    chartBodygraphChart2.channels2['20-34'] &&
                    chartBodygraphChart2.channels2['20-57']
                "
                @click="
                  openMultiTooltipDialog([
                    chartBodygraphChart2.channels2['10-20'],
                    chartBodygraphChart2.channels2['20-34'],
                    chartBodygraphChart2.channels2['20-57']
                  ])
                "
                @mouseover="searchAcPlanets(10, 20, 34, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="286.48999"
                  cx="368.56"
                />
              </g>
              <g
                id="gate_34_10_20_channel_on_chart1"
                v-if="
                  chartBodygraphChart.channels2['10-34'] &&
                    chartBodygraphChart.channels2['20-34']
                "
                @click="
                  openMultiTooltipDialog([
                    chartBodygraphChart.channels2['10-34'],
                    chartBodygraphChart.channels2['20-34']
                  ])
                "
                @mouseover="searchAcPlanets(10, 20, 34)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="558.09003"
                  cx="370.06"
                />
              </g>
              <g
                id="gate_34_10_20_channel_on_chart2"
                v-if="
                  chartBodygraphChart2.channels2['10-34'] &&
                    chartBodygraphChart2.channels2['20-34']
                "
                @click="
                  openMultiTooltipDialog([
                    chartBodygraphChart2.channels2['10-34'],
                    chartBodygraphChart2.channels2['20-34']
                  ])
                "
                @mouseover="searchAcPlanets(10, 20, 34)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="558.09003"
                  cx="370.06"
                />
              </g>
              <g
                id="gate_34_10_57_channel_on_chart1"
                v-if="
                  chartBodygraphChart.channels2['10-34'] &&
                    chartBodygraphChart.channels2['34-57']
                "
                @click="
                  openMultiTooltipDialog([
                    chartBodygraphChart.channels2['10-34'],
                    chartBodygraphChart.channels2['34-57']
                  ])
                "
                @mouseover="searchAcPlanets(10, 34, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="558.09003"
                  cx="370.06"
                />
              </g>
              <g
                id="gate_34_10_57_channel_on_chart2"
                v-if="
                  chartBodygraphChart2.channels2['10-34'] &&
                    chartBodygraphChart2.channels2['34-57']
                "
                @click="
                  openMultiTooltipDialog([
                    chartBodygraphChart2.channels2['10-34'],
                    chartBodygraphChart2.channels2['34-57']
                  ])
                "
                @mouseover="searchAcPlanets(10, 34, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="558.09003"
                  cx="370.06"
                />
              </g>
              <g
                id="gate_34_20_57_channel_on_chart1"
                v-if="
                  chartBodygraphChart.channels2['20-34'] &&
                    chartBodygraphChart.channels2['34-57']
                "
                @click="
                  openMultiTooltipDialog([
                    chartBodygraphChart.channels2['20-34'],
                    chartBodygraphChart.channels2['34-57']
                  ])
                "
                @mouseover="searchAcPlanets(20, 34, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="558.09003"
                  cx="370.06"
                />
              </g>
              <g
                id="gate_34_20_57_channel_on_chart2"
                v-if="
                  chartBodygraphChart2.channels2['20-34'] &&
                    chartBodygraphChart2.channels2['34-57']
                "
                @click="
                  openMultiTooltipDialog([
                    chartBodygraphChart2.channels2['20-34'],
                    chartBodygraphChart2.channels2['34-57']
                  ])
                "
                @mouseover="searchAcPlanets(20, 34, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="558.09003"
                  cx="370.06"
                />
              </g>
              <g
                id="gate_34_10_20_57_channel_on_chart1"
                v-if="
                  chartBodygraphChart.channels2['10-34'] &&
                    chartBodygraphChart.channels2['20-34'] &&
                    chartBodygraphChart.channels2['34-57']
                "
                @click="
                  openMultiTooltipDialog([
                    chartBodygraphChart.channels2['10-34'],
                    chartBodygraphChart.channels2['20-34'],
                    chartBodygraphChart.channels2['34-57']
                  ])
                "
                @mouseover="searchAcPlanets(10, 20, 34, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="558.09003"
                  cx="370.06"
                />
              </g>
              <g
                id="gate_34_10_20_57_channel_on_chart2"
                v-if="
                  chartBodygraphChart2.channels2['10-34'] &&
                    chartBodygraphChart2.channels2['20-34'] &&
                    chartBodygraphChart2.channels2['34-57']
                "
                @click="
                  openMultiTooltipDialog([
                    chartBodygraphChart2.channels2['10-34'],
                    chartBodygraphChart2.channels2['20-34'],
                    chartBodygraphChart2.channels2['34-57']
                  ])
                "
                @mouseover="searchAcPlanets(10, 20, 34, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="558.09003"
                  cx="370.06"
                />
              </g>
              <g
                id="gate_57_10_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['10-57']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['10-57'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(10, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1296"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="532.19"
                  cx="234.06"
                />
              </g>
              <g
                id="gate_57_10_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['10-57']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['10-57'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(10, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1296"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="532.19"
                  cx="234.06"
                />
              </g>
              <g
                id="gate_57_10_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['10-57']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['10-57'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(10, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1296"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="532.19"
                  cx="234.06"
                />
              </g>
              <g
                id="gate_57_10_20_channel_on_chart1"
                v-if="
                  chartBodygraphChart.channels2['10-57'] &&
                    chartBodygraphChart.channels2['20-57']
                "
                @click="
                  openMultiTooltipDialog([
                    chartBodygraphChart.channels2['10-57'],
                    chartBodygraphChart.channels2['20-57']
                  ])
                "
                @mouseover="searchAcPlanets(10, 20, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1296"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="532.19"
                  cx="234.06"
                />
              </g>
              <g
                id="gate_57_10_20_channel_on_chart2"
                v-if="
                  chartBodygraphChart2.channels2['10-57'] &&
                    chartBodygraphChart2.channels2['20-57']
                "
                @click="
                  openMultiTooltipDialog([
                    chartBodygraphChart2.channels2['10-57'],
                    chartBodygraphChart2.channels2['20-57']
                  ])
                "
                @mouseover="searchAcPlanets(10, 20, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1296"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="532.19"
                  cx="234.06"
                />
              </g>
              <g
                id="gate_57_10_34_channel_on_chart1"
                v-if="
                  chartBodygraphChart.channels2['10-57'] &&
                    chartBodygraphChart.channels2['34-57']
                "
                @click="
                  openMultiTooltipDialog([
                    chartBodygraphChart.channels2['10-57'],
                    chartBodygraphChart.channels2['34-57']
                  ])
                "
                @mouseover="searchAcPlanets(10, 34, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1296"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="532.19"
                  cx="234.06"
                />
              </g>
              <g
                id="gate_57_10_34_channel_on_chart2"
                v-if="
                  chartBodygraphChart2.channels2['10-57'] &&
                    chartBodygraphChart2.channels2['34-57']
                "
                @click="
                  openMultiTooltipDialog([
                    chartBodygraphChart2.channels2['10-57'],
                    chartBodygraphChart2.channels2['34-57']
                  ])
                "
                @mouseover="searchAcPlanets(10, 34, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1296"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="532.19"
                  cx="234.06"
                />
              </g>
              <g
                id="gate_57_20_34_channel_on_chart1"
                v-if="
                  chartBodygraphChart.channels2['20-57'] &&
                    chartBodygraphChart.channels2['34-57']
                "
                @click="
                  openMultiTooltipDialog([
                    chartBodygraphChart.channels2['20-57'],
                    chartBodygraphChart.channels2['34-57']
                  ])
                "
                @mouseover="searchAcPlanets(20, 34, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1296"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="532.19"
                  cx="234.06"
                />
              </g>
              <g
                id="gate_57_20_34_channel_on_chart2"
                v-if="
                  chartBodygraphChart2.channels2['20-57'] &&
                    chartBodygraphChart2.channels2['34-57']
                "
                @click="
                  openMultiTooltipDialog([
                    chartBodygraphChart2.channels2['20-57'],
                    chartBodygraphChart2.channels2['34-57']
                  ])
                "
                @mouseover="searchAcPlanets(20, 34, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1296"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="532.19"
                  cx="234.06"
                />
              </g>
              <g
                id="gate_57_10_20_34_channel_on_chart1"
                v-if="
                  chartBodygraphChart.channels2['10-57'] &&
                    chartBodygraphChart.channels2['20-57'] &&
                    chartBodygraphChart.channels2['34-57']
                "
                @click="
                  openMultiTooltipDialog([
                    chartBodygraphChart.channels2['10-57'],
                    chartBodygraphChart.channels2['20-57'],
                    chartBodygraphChart.channels2['34-57']
                  ])
                "
                @mouseover="searchAcPlanets(10, 20, 34, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1296"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="532.19"
                  cx="234.06"
                />
              </g>
              <g
                id="gate_57_10_20_34_channel_on_chart2"
                v-if="
                  chartBodygraphChart2.channels2['10-57'] &&
                    chartBodygraphChart2.channels2['20-57'] &&
                    chartBodygraphChart2.channels2['34-57']
                "
                @click="
                  openMultiTooltipDialog([
                    chartBodygraphChart2.channels2['10-57'],
                    chartBodygraphChart2.channels2['20-57'],
                    chartBodygraphChart2.channels2['34-57']
                  ])
                "
                @mouseover="searchAcPlanets(10, 20, 34, 57)"
                @mouseleave="delayedСlearAcPlanets()"
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                style="display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  id="circle1296"
                  class="gates"
                  style="fill:transparent;cursor:pointer"
                  r="10"
                  cy="532.19"
                  cx="234.06"
                />
              </g>
              <g
                id="gate_09_52_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['9-52']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['9-52'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(9, 52)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 9)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="611.89001"
                  cx="429.26001"
                />
                <circle
                  id="circle1536"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="611.89001"
                  cx="429.26001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      9
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="611.89001"
                  cx="429.26001"
                />
                <text
                  id="text1538"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="425.5"
                  y="616.23126"
                >
                  9
                </text>
              </g>
              <g
                id="gate_09_52_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['9-52']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['9-52'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(9, 52)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 9)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="611.89001"
                  cx="429.26001"
                />
                <circle
                  id="circle1536"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="611.89001"
                  cx="429.26001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      9
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="611.89001"
                  cx="429.26001"
                />
                <text
                  id="text1538"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="425.5"
                  y="616.23126"
                >
                  9
                </text>
              </g>
              <g
                id="gate_08_01_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['1-8']"
                @click="openTooltipDialog(chartBodygraphChart.channels2['1-8'])"
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(1, 8)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 8)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="321.19"
                  cx="409.16"
                />
                <circle
                  id="circle1541"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="321.19"
                  cx="409.16"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      8
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="321.19"
                  cx="409.16"
                />
                <text
                  id="text1543"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="405.3"
                  y="325.52551"
                >
                  8
                </text>
              </g>
              <g
                id="gate_08_01_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['1-8']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['1-8'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(1, 8)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 8)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="321.19"
                  cx="409.16"
                />
                <circle
                  id="circle1541"
                  class="gates"
                  style="fill:#22938a"
                  r="10"
                  cy="321.19"
                  cx="409.16"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      8
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="321.19"
                  cx="409.16"
                />
                <text
                  id="text1543"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="405.3"
                  y="325.52551"
                >
                  8
                </text>
              </g>
              <g
                id="gate_07_31_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['7-31']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['7-31'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(7, 31)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 7)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="376.19"
                  cx="389.66"
                />
                <circle
                  id="circle1546"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="376.19"
                  cx="389.66"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      7
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="376.19"
                  cx="389.66"
                />
                <text
                  id="text1548"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="386.16492"
                  y="380.52551"
                >
                  7
                </text>
              </g>
              <g
                id="gate_07_31_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['7-31']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['7-31'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(7, 31)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 7)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="376.19"
                  cx="389.66"
                />
                <circle
                  id="circle1546"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="376.19"
                  cx="389.66"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      7
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="376.19"
                  cx="389.66"
                />
                <text
                  id="text1548"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="386.16492"
                  y="380.52551"
                >
                  7
                </text>
              </g>
              <g
                id="gate_06_59_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['6-59']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['6-59'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(6, 59)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 6)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="558.59003"
                  cx="538.35999"
                />
                <circle
                  id="circle1551"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="558.59003"
                  cx="538.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      6
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="558.59003"
                  cx="538.35999"
                />
                <text
                  id="text1553"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="534.4"
                  y="562.91888"
                >
                  6
                </text>
              </g>
              <g
                id="gate_06_59_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['6-59']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['6-59'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(6, 59)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 6)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="558.59003"
                  cx="538.35999"
                />
                <circle
                  id="circle1551"
                  class="gates"
                  style="fill:#492702"
                  r="10"
                  cy="558.59003"
                  cx="538.35999"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      6
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="558.59003"
                  cx="538.35999"
                />
                <text
                  id="text1553"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="534.4"
                  y="562.91888"
                >
                  6
                </text>
              </g>
              <g
                id="gate_05_15_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['5-15']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['5-15'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(5, 15)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 5)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="530.89001"
                  cx="388.06"
                />
                <circle
                  id="circle1556"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="530.89001"
                  cx="388.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      5
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="530.89001"
                  cx="388.06"
                />
                <text
                  id="text1558"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="384"
                  y="535.15216"
                >
                  5
                </text>
              </g>
              <g
                id="gate_05_15_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['5-15']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['5-15'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(5, 15)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 5)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="530.89001"
                  cx="388.06"
                />
                <circle
                  id="circle1556"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="530.89001"
                  cx="388.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      5
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="530.89001"
                  cx="388.06"
                />
                <text
                  id="text1558"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="384"
                  y="535.15216"
                >
                  5
                </text>
              </g>
              <g
                id="gate_04_63_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['4-63']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['4-63'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(4, 63)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 4)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="123.79"
                  cx="430.06"
                />
                <circle
                  id="circle1561"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="123.79"
                  cx="430.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      4
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="123.79"
                  cx="430.06"
                />
                <text
                  id="text1563"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="425.7"
                  y="128"
                >
                  4
                </text>
              </g>
              <g
                id="gate_04_63_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['4-63']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['4-63'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(4, 63)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 4)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="123.79"
                  cx="430.06"
                />
                <circle
                  id="circle1561"
                  class="gates"
                  style="fill:#27540e"
                  r="10"
                  cy="123.79"
                  cx="430.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      4
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="123.79"
                  cx="430.06"
                />
                <text
                  id="text1563"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="425.7"
                  y="128"
                >
                  4
                </text>
              </g>
              <g
                id="gate_03_60_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['3-60']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['3-60'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(3, 60)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 3)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="611.89001"
                  cx="408.26001"
                />
                <circle
                  id="circle1566"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="611.89001"
                  cx="408.26001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      3
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="611.89001"
                  cx="408.26001"
                />
                <text
                  id="text1568"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="404.4"
                  y="616.22552"
                >
                  3
                </text>
              </g>
              <g
                id="gate_03_60_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['3-60']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['3-60'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(3, 60)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,15.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 3)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="611.89001"
                  cx="408.26001"
                />
                <circle
                  id="circle1566"
                  class="gates"
                  style="fill:#5a0127"
                  r="10"
                  cy="611.89001"
                  cx="408.26001"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      3
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="611.89001"
                  cx="408.26001"
                />
                <text
                  id="text1568"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="404.4"
                  y="616.22552"
                >
                  3
                </text>
              </g>
              <g
                id="gate_02_14_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['2-14']"
                @click="
                  openTooltipDialog(chartBodygraphChart.channels2['2-14'])
                "
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(2, 14)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 2)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="445.79001"
                  cx="409.06"
                />
                <circle
                  id="circle1571"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="445.79001"
                  cx="409.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      2
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="445.79001"
                  cx="409.06"
                />
                <text
                  id="text1573"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="405.69165"
                  y="450.19888"
                >
                  2
                </text>
              </g>
              <g
                id="gate_02_14_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['2-14']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['2-14'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(2, 14)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 2)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="445.79001"
                  cx="409.06"
                />
                <circle
                  id="circle1571"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="445.79001"
                  cx="409.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      2
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="445.79001"
                  cx="409.06"
                />
                <text
                  id="text1573"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="405.69165"
                  y="450.19888"
                >
                  2
                </text>
              </g>
              <g
                id="gate_01_08_channel_on_chart1"
                v-if="chartBodygraphChart.channels2['1-8']"
                @click="openTooltipDialog(chartBodygraphChart.channels2['1-8'])"
                v-bind:class="{ opacity4: isChartToggle || !isChart2Toggle }"
                @mouseover="searchAcPlanets(1, 8)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 1)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="356.79001"
                  cx="409.06"
                />
                <circle
                  id="circle1576"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="356.79001"
                  cx="409.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      1
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="356.79001"
                  cx="409.06"
                />
                <text
                  id="text1578"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="405.5"
                  y="361.12552"
                >
                  1
                </text>
              </g>
              <g
                id="gate_01_08_channel_on_chart2"
                v-if="chartBodygraphChart2.channels2['1-8']"
                @click="
                  openTooltipDialog(chartBodygraphChart2.channels2['1-8'])
                "
                v-bind:class="{ opacity4: !isChartToggle || isChart2Toggle }"
                @mouseover="searchAcPlanets(1, 8)"
                @mouseleave="delayedСlearAcPlanets()"
                style="cursor:pointer;display:inline"
                transform="translate(30.790009,17.409983)"
              >
                <circle
                  v-bind:class="{
                    opacity3: this.isDefinedArr(gatesUndefinedCentres, 1)
                  }"
                  style="fill:#fff343;opacity:0;display:none"
                  r="13"
                  cy="356.79001"
                  cx="409.06"
                />
                <circle
                  id="circle1576"
                  class="gates"
                  style="fill:#504b00"
                  r="10"
                  cy="356.79001"
                  cx="409.06"
                />
                <circle
                  v-bind:class="{
                    blink_me: this.isDefinedArr(
                      $store.state.account.gateInCenterHover,
                      1
                    )
                  }"
                  style="fill:#49c4be;opacity:0.7;display:none"
                  r="13"
                  cy="356.79001"
                  cx="409.06"
                />
                <text
                  id="text1578"
                  style="font-size:0.75rem;isolation:isolate;fill:#ffffff"
                  font-size="11.14"
                  x="405.5"
                  y="361.12552"
                >
                  1
                </text>
              </g>
            </g>
          </svg>
        </v-col>
      </v-row>
      <v-row v-else class="d-flex align-center justify-center">
        <v-card flat d-flex pa-2 class="card-md">
          <v-card-title
            class="headline padding-bottom-0 mob-padding-bottom-16 margin-top-5"
            style="font-size: 1rem !important;flex-direction:column;align-items: flex-start"
          >
            <div
              v-if="reportsHistoryCount <= 3"
              class="help-flex-tb margin-top-15"
            >
              <span class="padding-left-15-nomob">
                Используйте расчет совместимости правильно
              </span>
              <span>
                <v-icon
                  @click="informationDialog"
                  style="font-size:19px;color:#fb8c01;margin-left:7px"
                >
                  mdi-help-circle-outline
                </v-icon>
              </span>
            </div>
            <span v-if="isReports" class="help-flex-tb">
              <span
                v-if="chartNum === 1"
                class="no-mobile padding-left-15-nomob"
              >
                Введите данные для первого человека или выберите из кабинета
              </span>
              <span
                v-else
                class="сolor-comp-chart2 no-mobile padding-left-15-nomob"
              >
                Введите данные для второго человека или выберите из кабинета
              </span>
              <span v-if="chartNum === 1" class="no-desktop">
                Введите данные для первого человека<br />
                или выберите из кабинета
              </span>
              <span v-else class="сolor-comp-chart2 no-desktop"
                >Введите данные для второго человека<br />
                или выберите из кабинета
              </span>
              <v-btn
                @click="chartCompositeReport"
                class="mx-2 margin-top-10-mob margin-btm-5 mob-block hd-gradient no-mobile"
                small
                color="primary accent-4"
              >
                <v-icon left class="mob-no-margin-right">
                  mdi-plus
                </v-icon>
                <span>Выбрать</span>
              </v-btn>
              <span class="no-desktop">
                <v-btn
                  @click="chartCompositeReport"
                  class="my-prof-controls-flex"
                  color="primary"
                  fab
                  small
                  icon
                  dark
                  style="margin-left:15px"
                >
                  <v-icon style="font-size:26px">mdi-plus</v-icon>
                  <span class="d-flex" style="font-size:11px;margin-top:5px">
                    выбрать
                  </span>
                </v-btn>
              </span>
            </span>
            <span v-else class="help-flex-tb">
              <span v-if="chartNum === 1" class="padding-left-15-nomob">
                Введите данные для первого человека
              </span>
              <span v-else class="padding-left-15-nomob сolor-comp-chart2">
                Введите данные для второго человека
              </span>
            </span>
            <div v-if="reportsHistoryCount > 3" class="padding-left-15-nomob">
              <v-btn
                @click="compositeHistoryDialog = true"
                rounded
                small
                color="primary"
                class="hd-gradient report-history-btn"
              >
                история расчетов
              </v-btn>
            </div>
          </v-card-title>
          <v-card-text class="padding-bottom-0">
            <v-container class="mob-padding-top-0">
              <v-row>
                <form>
                  <v-card-text class="mob-nopadding padding-bottom-0">
                    <v-row>
                      <v-col
                        class="mob-padding-top-0-bottom-10"
                        cols="4"
                        sm="2"
                        md="2"
                        lg="2"
                        xl="2"
                      >
                        <v-select
                          v-on:change="onDayChange"
                          v-model="day"
                          :hint="`день`"
                          :items="days"
                          item-text="day"
                          label="Выберите день"
                          autofocus
                          persistent-hint
                          return-object
                          single-line
                          required
                          menu-props="auto, bottom, offset-y"
                        ></v-select>
                      </v-col>
                      <v-col
                        class="mob-padding-top-0-bottom-10"
                        cols="4"
                        sm="4"
                        md="4"
                        lg="4"
                        xl="4"
                      >
                        <v-select
                          ref="monthSelect"
                          v-on:change="onMonthChange"
                          v-model="month"
                          :hint="`месяц`"
                          :items="months"
                          item-text="month"
                          label="Выберите месяц"
                          persistent-hint
                          return-object
                          single-line
                          required
                          menu-props="auto, bottom, offset-y"
                        ></v-select>
                      </v-col>
                      <v-col
                        class="mob-padding-top-0-bottom-10"
                        cols="4"
                        sm="2"
                        md="2"
                        lg="2"
                        xl="2"
                      >
                        <v-select
                          ref="yearSelect"
                          v-on:change="onYearChange"
                          v-model="year"
                          :hint="`год`"
                          :items="years"
                          item-text="year"
                          label="Выберите год"
                          persistent-hint
                          return-object
                          single-line
                          required
                          menu-props="auto, bottom, offset-y"
                        ></v-select>
                      </v-col>
                      <v-col
                        class="mob-padding-top-0-bottom-10"
                        cols="6"
                        sm="2"
                        md="2"
                        lg="2"
                        xl="2"
                      >
                        <v-select
                          ref="hourSelect"
                          v-on:change="onHourChange"
                          v-model="hour"
                          :hint="`часы`"
                          :items="hours"
                          item-text="hour"
                          label="Выберите час"
                          persistent-hint
                          return-object
                          single-line
                          required
                          menu-props="auto, bottom, offset-y"
                        ></v-select>
                      </v-col>
                      <v-col
                        class="mob-padding-top-0-bottom-10"
                        cols="6"
                        sm="2"
                        md="2"
                        lg="2"
                        xl="2"
                      >
                        <v-select
                          ref="minuteSelect"
                          v-on:change="onMinuteChange"
                          v-model="minute"
                          :hint="`минуты`"
                          :items="minutes"
                          item-text="minute"
                          label="Выберите минуту"
                          persistent-hint
                          return-object
                          single-line
                          required
                          menu-props="auto, bottom, offset-y"
                        ></v-select>
                      </v-col>
                      <v-col
                        class="mob-padding-top-0-bottom-10"
                        cols="12"
                        sm="6"
                        md="6"
                        lg="6"
                        xl="6"
                      >
                        <v-autocomplete
                          ref="cityInput"
                          v-on:change="onCityChange"
                          v-model="city"
                          :items="cities"
                          :loading="isLoading"
                          :search-input.sync="search"
                          :error-messages="cityErrors"
                          :no-filter="true"
                          @input="$v.city.$touch()"
                          @blur="$v.city.$touch()"
                          chips
                          clearable
                          hide-details
                          hide-selected
                          item-text="title"
                          item-value="id"
                          label="Город"
                          required
                        >
                          <template v-slot:no-data>
                            <v-list-item>
                              <v-list-item-title v-if="!search || isLoading">
                                Ближайший крупный <strong>город</strong>...
                              </v-list-item-title>
                              <v-list-item-title
                                v-else
                                class="v-list-item-title-custom"
                              >
                                <span
                                  v-if="!searchResult"
                                  v-html="getMessage('tooltip_find_city')"
                                ></span>
                              </v-list-item-title>
                            </v-list-item>
                          </template>
                          <template
                            v-slot:selection="{ attr, on, item, selected }"
                          >
                            <v-chip
                              v-on="on"
                              v-bind="attr"
                              :input-value="selected"
                              color="white"
                              class="black--text"
                            >
                              <span v-text="item.title"></span>&nbsp;
                              <img
                                :src="
                                  'https://purecatamphetamine.github.io/country-flag-icons/3x2/' +
                                    item.country.iso +
                                    '.svg'
                                "
                                height="15"
                              />
                            </v-chip>
                          </template>
                          <template v-slot:item="{ item }">
                            <v-list-item-content>
                              <v-list-item-title
                                v-text="item.title"
                              ></v-list-item-title>
                              <v-list-item-subtitle
                                v-text="item.country.title"
                              ></v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                              <img
                                :src="
                                  'https://purecatamphetamine.github.io/country-flag-icons/3x2/' +
                                    item.country.iso +
                                    '.svg'
                                "
                                height="15"
                              />
                            </v-list-item-action>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="2" class="no-mobile"></v-col>
                      <v-col
                        class="mob-padding-top-0-bottom-10"
                        cols="12"
                        sm="4"
                        md="4"
                        lg="4"
                        xl="4"
                      >
                        <v-text-field
                          ref="firstnameInput"
                          v-model="firstname"
                          :error-messages="nameErrors"
                          :counter="35"
                          @input="$v.firstname.$touch()"
                          @blur="$v.firstname.$touch()"
                          height="42"
                          label="Имя"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row v-if="chartNum === 1" class="d-flex justify-center">
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                        class="text-center padding-top-0"
                      >
                        <div
                          v-if="
                            isLogged &&
                              User.is_subscribed_to_justclick &&
                              Profile.full_tooltips_count > 0
                          "
                          class="full-tooltips-switcher"
                        >
                          <FullTooltipsSwitcher :isHelpText="true" />
                        </div>
                      </v-col>
                    </v-row>
                    <v-row v-else class="d-flex justify-center">
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        lg="6"
                        xl="6"
                        class="text-center padding-top-0 margin-top-15"
                      >
                        <v-checkbox
                          v-if="
                            User.isLogged && User.is_subscribed_to_justclick
                          "
                          v-model="isStored"
                          class="v-checkbox-composite-custom"
                          label="Сохранить результат в кабинете"
                        ></v-checkbox>
                        <div v-else class="height-28"></div>
                      </v-col>
                      <v-col cols="2" class="no-mobile"></v-col>
                      <v-col
                        cols="12"
                        sm="4"
                        md="4"
                        lg="4"
                        xl="4"
                        class="text-center padding-top-0 margin-top-0"
                      >
                        <div
                          v-if="
                            isLogged &&
                              User.is_subscribed_to_justclick &&
                              Profile.full_tooltips_count > 0
                          "
                          class="full-tooltips-switcher"
                        >
                          <FullTooltipsSwitcher :isHelpText="true" />
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions class="nopadding">
                    <v-row class="d-flex justify-center">
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                        class="text-center padding-0-12 margin-top-15 margin-bottom-30"
                      >
                        <v-btn
                          @click="validateNewReport(chartNum)"
                          rounded
                          large
                          color="primary"
                          class="v-btn-custom v-btn-custom--bg-f hd-gradient width-100-mob"
                          style="margin-top:3px"
                        >
                          <span v-if="chartNum === 1">Следующий шаг</span>
                          <span v-else>Начать расчет</span>
                          &nbsp;<img
                            src="https://static.human-design.space/widget/images/svg/bodygraph-icon.svg"
                            width="22"
                          />
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </form>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-row>
    </v-card-text>
    <v-card-actions :class="{ 'noactive-comp': !chartBodygraphChart }">
      <v-row class="d-flex justify-center">
        <v-col
          v-if="chartBodygraphChart"
          sm="12"
          md="12"
          class="bodygraph-desc"
        >
          <v-expansion-panels
            v-model="panel"
            multiple
            class="expansion-panels-user-stat v-expansion-custom-panels additional-info-block d-none d-sm-block"
            style="margin-top:10px"
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="v-expansion-custom-panel-header">
                <span
                  class="d-sm-block addit-info-b-header"
                  style="text-align:center"
                >
                  Демо-расшифровка совместимости
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-tabs
                  v-model="tabAddInfo"
                  class="additional-info-block-tabs"
                  background-color="transparent"
                  color="basil"
                  grow
                  show-arrows
                >
                  <v-tab v-if="chartBodygraphLove.id">
                    Формула любви
                  </v-tab>
                  <v-tab v-if="chartBodygraphTypeComptb.id">
                    Совместимость типов
                  </v-tab>
                  <v-tab>Каналы</v-tab>
                  <v-tab>Центры</v-tab>
                  <v-tabs-items v-model="tabAddInfo" touchless>
                    <v-tab-item>
                      <v-card
                        class="mx-auto margin-bottom-25 bg-dark-lighten"
                        outlined
                      >
                        <v-list-item three-line>
                          <v-list-item-content
                            class="expansion-panels-channels-cont"
                          >
                            <v-list-item-title
                              class="headline mb-1 padding-bottom-15"
                              style="opacity:0.7;overflow:auto;white-space:initial;font-size:1.25rem !important"
                            >
                              {{ chartBodygraphLove.title }}
                            </v-list-item-title>
                            <div v-html="chartBodygraphLove.tooltip"></div>
                          </v-list-item-content>
                        </v-list-item>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item>
                      <v-card
                        class="mx-auto margin-bottom-25 bg-dark-lighten"
                        outlined
                      >
                        <v-list-item three-line>
                          <v-list-item-content
                            class="expansion-panels-channels-cont"
                          >
                            <v-list-item-title
                              class="headline mb-1 padding-bottom-15"
                              style="opacity:0.7;overflow:auto;white-space:initial;font-size:1.25rem !important"
                            >
                              {{ chartBodygraphTypeComptb.title }}
                            </v-list-item-title>
                            <div
                              v-html="chartBodygraphTypeComptb.tooltip"
                            ></div>
                          </v-list-item-content>
                        </v-list-item>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item>
                      <div v-if="chartBodygraphChannels.channelsBoth.length">
                        <h2 class="expansion-panels-user-header">
                          Компаньонские каналы:
                        </h2>
                        <v-card
                          v-for="(item,
                          i) in chartBodygraphChannels.channelsBoth"
                          :key="i"
                          class="mx-auto margin-bottom-25 bg-dark-lighten"
                          outlined
                        >
                          <v-list-item three-line>
                            <v-list-item-content
                              class="expansion-panels-channels-cont"
                            >
                              <v-list-item-title
                                class="headline mb-1 padding-bottom-15"
                                style="opacity:0.7;overflow:auto;white-space:initial;font-size:1.25rem !important"
                              >
                                {{ item.title }}
                              </v-list-item-title>
                              <div v-html="item.tooltip"></div>
                            </v-list-item-content>
                          </v-list-item>
                        </v-card>
                      </div>
                      <div v-if="chartBodygraphChannels.channelsElectro.length">
                        <h2 class="expansion-panels-user-header">
                          Электромагнитные каналы:
                        </h2>
                        <v-card
                          v-for="(item,
                          i) in chartBodygraphChannels.channelsElectro"
                          :key="i"
                          class="mx-auto margin-bottom-25 bg-dark-lighten"
                          outlined
                        >
                          <v-list-item three-line>
                            <v-list-item-content
                              class="expansion-panels-channels-cont"
                            >
                              <v-list-item-title
                                class="headline mb-1 padding-bottom-15"
                                style="opacity:0.7;overflow:auto;white-space:initial;font-size:1.25rem !important"
                              >
                                {{ item.title }}
                              </v-list-item-title>
                              <div v-html="item.tooltip"></div>
                            </v-list-item-content>
                          </v-list-item>
                        </v-card>
                      </div>
                      <div
                        v-if="chartBodygraphChannels.channelsCompromise1.length"
                      >
                        <h2 class="expansion-panels-user-header">
                          Компромиссные каналы ({{ chartBodygraphChartName }}):
                        </h2>
                        <v-card
                          v-for="(item,
                          i) in chartBodygraphChannels.channelsCompromise1"
                          :key="i"
                          class="mx-auto margin-bottom-25 bg-dark-lighten"
                          outlined
                        >
                          <v-list-item three-line>
                            <v-list-item-content
                              class="expansion-panels-channels-cont"
                            >
                              <v-list-item-title
                                class="headline mb-1 padding-bottom-15"
                                style="opacity:0.7;overflow:auto;white-space:initial;font-size:1.25rem !important"
                              >
                                {{ item.title }}
                              </v-list-item-title>
                              <div v-html="item.tooltip"></div>
                            </v-list-item-content>
                          </v-list-item>
                        </v-card>
                      </div>
                      <div
                        v-if="chartBodygraphChannels.channelsCompromise2.length"
                      >
                        <h2 class="expansion-panels-user-header">
                          Компромиссные каналы ({{ chartBodygraphChartName2 }}):
                        </h2>
                        <v-card
                          v-for="(item,
                          i) in chartBodygraphChannels.channelsCompromise2"
                          :key="i"
                          class="mx-auto margin-bottom-25 bg-dark-lighten"
                          outlined
                        >
                          <v-list-item three-line>
                            <v-list-item-content
                              class="expansion-panels-channels-cont"
                            >
                              <v-list-item-title
                                class="headline mb-1 padding-bottom-15"
                                style="opacity:0.7;overflow:auto;white-space:initial;font-size:1.25rem !important"
                              >
                                {{ item.title }}
                              </v-list-item-title>
                              <div v-html="item.tooltip"></div>
                            </v-list-item-content>
                          </v-list-item>
                        </v-card>
                      </div>
                      <div
                        v-if="
                          chartBodygraphChannels.channelsEducational1.length
                        "
                      >
                        <h2 class="expansion-panels-user-header">
                          Обучающие каналы ({{ chartBodygraphChartName }}):
                        </h2>
                        <v-card
                          v-for="(item,
                          i) in chartBodygraphChannels.channelsEducational1"
                          :key="i"
                          class="mx-auto margin-bottom-25 bg-dark-lighten"
                          outlined
                        >
                          <v-list-item three-line>
                            <v-list-item-content
                              class="expansion-panels-channels-cont"
                            >
                              <v-list-item-title
                                class="headline mb-1 padding-bottom-15"
                                style="opacity:0.7;overflow:auto;white-space:initial;font-size:1.25rem !important"
                              >
                                {{ item.title }}
                              </v-list-item-title>
                              <div v-html="item.tooltip"></div>
                            </v-list-item-content>
                          </v-list-item>
                        </v-card>
                      </div>
                      <div
                        v-if="
                          chartBodygraphChannels.channelsEducational2.length
                        "
                      >
                        <h2 class="expansion-panels-user-header">
                          Обучающие каналы ({{ chartBodygraphChartName2 }}):
                        </h2>
                        <v-card
                          v-for="(item,
                          i) in chartBodygraphChannels.channelsEducational2"
                          :key="i"
                          class="mx-auto margin-bottom-25 bg-dark-lighten"
                          outlined
                        >
                          <v-list-item three-line>
                            <v-list-item-content
                              class="expansion-panels-channels-cont"
                            >
                              <v-list-item-title
                                class="headline mb-1 padding-bottom-15"
                                style="opacity:0.7;overflow:auto;white-space:initial;font-size:1.25rem !important"
                              >
                                {{ item.title }}
                              </v-list-item-title>
                              <div v-html="item.tooltip"></div>
                            </v-list-item-content>
                          </v-list-item>
                        </v-card>
                      </div>
                    </v-tab-item>
                    <v-tab-item>
                      <div
                        v-if="
                          chartBodygraphCenters.centersDefinedAtBothCharts
                            .length
                        "
                      >
                        <h2 class="expansion-panels-user-header">
                          Компаньонские центры:
                        </h2>
                        <v-card
                          v-for="(item,
                          i) in chartBodygraphCenters.centersDefinedAtBothCharts"
                          :key="i"
                          class="mx-auto margin-bottom-25 bg-dark-lighten"
                          outlined
                        >
                          <v-list-item three-line>
                            <v-list-item-content
                              class="expansion-panels-channels-cont"
                            >
                              <v-list-item-title
                                class="headline mb-1 padding-bottom-15"
                                style="opacity:0.7;overflow:auto;white-space:initial;font-size:1.25rem !important"
                              >
                                {{ item.title }}
                              </v-list-item-title>
                              <div v-html="item.tooltip"></div>
                            </v-list-item-content>
                          </v-list-item>
                        </v-card>
                      </div>
                      <div
                        v-if="
                          chartBodygraphCenters
                            .centersDefinedOnlyAtChartsConnection.length
                        "
                      >
                        <h2 class="expansion-panels-user-header">
                          Электромагнитные центры:
                        </h2>
                        <v-card
                          v-for="(item,
                          i) in chartBodygraphCenters.centersDefinedOnlyAtChartsConnection"
                          :key="i"
                          class="mx-auto margin-bottom-25 bg-dark-lighten"
                          outlined
                        >
                          <v-list-item three-line>
                            <v-list-item-content
                              class="expansion-panels-channels-cont"
                            >
                              <v-list-item-title
                                class="headline mb-1 padding-bottom-15"
                                style="opacity:0.7;overflow:auto;white-space:initial;font-size:1.25rem !important"
                              >
                                {{ item.title }}
                              </v-list-item-title>
                              <div v-html="item.tooltip"></div>
                            </v-list-item-content>
                          </v-list-item>
                        </v-card>
                      </div>
                      <div
                        v-if="
                          chartBodygraphCenters.centersDefinedOnlyAtChart1
                            .length
                        "
                      >
                        <h2 class="expansion-panels-user-header">
                          Доминирующие центры ({{ chartBodygraphChartName }}):
                        </h2>
                        <v-card
                          v-for="(item,
                          i) in chartBodygraphCenters.centersDefinedOnlyAtChart1"
                          :key="i"
                          class="mx-auto margin-bottom-25 bg-dark-lighten"
                          outlined
                        >
                          <v-list-item three-line>
                            <v-list-item-content
                              class="expansion-panels-channels-cont"
                            >
                              <v-list-item-title
                                class="headline mb-1 padding-bottom-15"
                                style="opacity:0.7;overflow:auto;white-space:initial;font-size:1.25rem !important"
                              >
                                {{ item.title }}
                              </v-list-item-title>
                              <div v-html="item.tooltip"></div>
                            </v-list-item-content>
                          </v-list-item>
                        </v-card>
                      </div>
                      <div
                        v-if="
                          chartBodygraphCenters.centersDefinedOnlyAtChart2
                            .length
                        "
                      >
                        <h2 class="expansion-panels-user-header">
                          Доминирующие центры ({{ chartBodygraphChartName2 }}):
                        </h2>
                        <v-card
                          v-for="(item,
                          i) in chartBodygraphCenters.centersDefinedOnlyAtChart2"
                          :key="i"
                          class="mx-auto margin-bottom-25 bg-dark-lighten"
                          outlined
                        >
                          <v-list-item three-line>
                            <v-list-item-content
                              class="expansion-panels-channels-cont"
                            >
                              <v-list-item-title
                                class="headline mb-1 padding-bottom-15"
                                style="opacity:0.7;overflow:auto;white-space:initial;font-size:1.25rem !important"
                              >
                                {{ item.title }}
                              </v-list-item-title>
                              <div v-html="item.tooltip"></div>
                            </v-list-item-content>
                          </v-list-item>
                        </v-card>
                      </div>
                    </v-tab-item>
                  </v-tabs-items>
                </v-tabs>
                <!-- <div
                  v-if="isVisibleTooltipBtn"
                  class="reg-go"
                  style="background-color:transparent;position:initial"
                >
                  <div v-html="RegGoTextBeforeBtn" class="reg-go-div"></div>
                  <v-btn
                    @click="clickTooltipBtnCP(extTips)"
                    v-html="this.getMessage(btnTooltipName)"
                    class="tooltip-btn tooltip-btn-bg min-width-135"
                    style="height:36px"
                    small
                  ></v-btn>
                </div> -->
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-card
            @click="openTooltipDialog(chartBodygraphLove)"
            class="mx-auto no-desktop custom-expansion-panel"
            style="margin-top: 10px;"
          >
            <v-card-text class="d-flex-j-c-space-btw">
              <h3>
                <span>Формула</span>
              </h3>
              <v-icon class="icon-chevron-down">
                mdi-chevron-down
              </v-icon>
            </v-card-text>
          </v-card>
          <v-card
            @click="openTooltipDialog(chartBodygraphTypeComptb)"
            class="mx-auto no-desktop custom-expansion-panel"
            style="margin-top: 10px;"
          >
            <v-card-text class="d-flex-j-c-space-btw">
              <h3>
                <span>Совместимость</span>
              </h3>
              <v-icon class="icon-chevron-down">
                mdi-chevron-down
              </v-icon>
            </v-card-text>
          </v-card>
          <v-card
            @click="openCompositeChannelsTooltipDialog()"
            class="mx-auto no-desktop custom-expansion-panel"
            style="margin-top: 10px;"
          >
            <v-card-text class="d-flex-j-c-space-btw">
              <h3>
                <span>Каналы</span>
              </h3>
              <v-icon class="icon-chevron-down">
                mdi-chevron-down
              </v-icon>
            </v-card-text>
          </v-card>
          <v-card
            @click="openCompositeCentersTooltipDialog()"
            class="mx-auto no-desktop custom-expansion-panel"
            style="margin-top: 10px;"
          >
            <v-card-text class="d-flex-j-c-space-btw">
              <h3>
                <span>Центры</span>
              </h3>
              <v-icon class="icon-chevron-down">
                mdi-chevron-down
              </v-icon>
            </v-card-text>
          </v-card>
          <v-col
            cols="12"
            class="text-center flex-center-mob nopadding margin-top-25"
          >
            <v-btn
              v-if="chartBodygraphChart"
              @click="newReport(1)"
              target="_blank"
              class="my-prof-controls-flex"
              color="primary"
              fab
              small
              icon
              dark
            >
              <v-icon style="font-size:26px">mdi-plus</v-icon>
              <span class="d-flex" style="font-size:11px;margin-top:5px">
                новый расчёт
              </span>
            </v-btn>
          </v-col>
          <div
            class="reg-go3 margin-bottom-0"
            style="background-color:transparent;position:initial"
          >
            <div class="reg-go-div2" style="text-align:center">
              Закажите полную расшифровку совместимости в PDF формате
            </div>
            <v-btn
              :href="$store.state.conf.ConfApp.composite_shop_url"
              target="_blank"
              class="tooltip-btn tooltip-btn-bg min-width-135 mob-margin-bottom-20"
              style="margin-bottom:5px"
            >
              Смотреть
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card-actions>
    <v-dialog
      v-model="chartcompositedialog"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      scrollable
      max-width="900px"
      style="z-index: 9999"
    >
      <v-card class="modal-pay-report">
        <v-card-title>
          <span
            v-html="getMessage('dialog_txt_chartcomposite')"
            class="headline-d-blue modal-header2 сolor-comp-chart1"
          >
          </span>
          <v-btn @click="chartcompositedialog = false" icon absolute right>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                v-if="userProdReports.length && User.isLogged"
                class="mob-nopadding"
                cols="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
              >
                <div
                  class="display-0-5 text--primary demo-report-header"
                  style="margin-top: 25px"
                >
                  <p class="text--primary">
                    Расшифровки:
                  </p>
                </div>
                <div v-for="(usereport, i) in userProdReports" :key="i">
                  <div class="no-mobile">
                    <div
                      class="text--primary d-flex modal-view-reports-wrapper"
                    >
                      <v-col
                        v-if="
                          userProdReports.length === 1 &&
                            usereport.id === User.reportid
                        "
                        cols="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                      >
                        <div class="text-center display-0-24">
                          Данный отчет текущий
                        </div>
                      </v-col>
                      <v-col
                        v-if="
                          userProdReports.length === 1 && !usereport.is_used
                        "
                        cols="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                      >
                        <div class="text-center display-0-24">
                          Данный расчет еще неактивирован
                        </div>
                      </v-col>
                      <v-col
                        v-if="
                          usereport.id !== User.reportid && usereport.is_used
                        "
                        cols="12"
                        sm="6"
                        md="4"
                        lg="4"
                        xl="4"
                      >
                        <div class="display-0-24">Исходные данные</div>
                        <ul class="ul-no-li-style">
                          <li v-if="usereport.first_name">
                            <v-icon>mdi-account</v-icon>
                            <span>{{ usereport.first_name }}</span>
                          </li>
                          <li v-if="usereport.birthday">
                            <v-icon>mdi-calendar-today</v-icon>
                            <span>{{ usereport.birthday }}</span>
                          </li>
                          <li v-if="usereport.city">
                            <v-icon>mdi-city-variant</v-icon>
                            <span>{{ usereport.city.title }}</span>
                          </li>
                        </ul>
                      </v-col>
                      <v-col
                        v-if="
                          usereport.id !== User.reportid && usereport.is_used
                        "
                        cols="12"
                        sm="6"
                        md="4"
                        lg="4"
                        xl="4"
                      >
                        <div class="display-0-24">Human Design</div>
                        <ul class="ul-no-li-style">
                          <li v-if="usereport.chart_type">
                            <v-icon>mdi-account-box-outline</v-icon>
                            <span>{{ usereport.chart_type }}</span>
                          </li>
                          <li v-if="usereport.chart_profile">
                            <v-icon>mdi-account-circle</v-icon>
                            <span>Профиль {{ usereport.chart_profile }}</span>
                          </li>
                        </ul>
                      </v-col>
                      <v-col
                        v-if="
                          usereport.id !== User.reportid && usereport.is_used
                        "
                        cols="12"
                        sm="6"
                        md="4"
                        lg="4"
                        xl="4"
                      >
                        <div class="display-0-24">Действия</div>
                        <v-tooltip right>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              @click="selectUserReport(usereport.id, 'user')"
                              class="mx-2 min-width-120 margin-btm-5"
                              small
                              color="primary"
                            >
                              <v-icon dark>mdi-call-split</v-icon
                              >&nbsp;Рейв-карта 1
                            </v-btn>
                          </template>
                          <span>Выбрать первую рейв-карту</span>
                        </v-tooltip>
                      </v-col>
                    </div>
                  </div>
                  <div class="no-desktop margin-bottom-15">
                    <div class="text--primary d-flex">
                      <v-col
                        v-if="
                          usereport.id !== User.reportid && usereport.is_used
                        "
                        cols="6"
                      >
                        <ul class="ul-no-li-style">
                          <li v-if="usereport.first_name">
                            <v-icon>mdi-account</v-icon>
                            <span>{{ usereport.first_name }}</span>
                          </li>
                          <li v-if="usereport.birthday">
                            <v-icon>mdi-calendar-today</v-icon>
                            <span>
                              {{ usereport.birthday }}
                            </span>
                          </li>
                          <li v-if="usereport.city">
                            <v-icon>mdi-city-variant</v-icon>
                            <span>{{ usereport.city.title }}</span>
                          </li>
                        </ul>
                      </v-col>
                      <v-col
                        v-if="
                          usereport.id !== User.reportid && usereport.is_used
                        "
                        cols="6"
                      >
                        <ul class="ul-no-li-style">
                          <li v-if="usereport.chart_type">
                            <v-icon>mdi-account-box-outline</v-icon>
                            <span>{{ usereport.chart_type }}</span>
                          </li>
                          <li v-if="usereport.chart_profile">
                            <v-icon>mdi-account-circle</v-icon>
                            <span>Профиль {{ usereport.chart_profile }}</span>
                          </li>
                        </ul>
                      </v-col>
                    </div>
                    <div
                      v-if="usereport.id !== User.reportid && usereport.is_used"
                      class="text--primary d-flex"
                    >
                      <v-col class="flex-center padding-top-0" cols="12">
                        <v-btn
                          @click="selectUserReport(usereport.id, 'user')"
                          class="my-prof-controls-flex"
                          color="primary"
                          fab
                          small
                          icon
                          dark
                        >
                          <v-icon dark>mdi-call-split</v-icon>
                          <span
                            class="d-flex"
                            style="font-size:11px;margin-top:5px"
                          >
                            Рейв-карта 1
                          </span>
                        </v-btn>
                      </v-col>
                    </div>
                  </div>
                </div>
              </v-col>
              <br />
              <v-col
                v-if="careerUserProducts.length && User.isLogged"
                class="mob-nopadding"
                cols="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
              >
                <div
                  class="display-0-5 text--primary demo-report-header"
                  style="margin-top: 25px"
                >
                  <p class="text--primary">
                    Бизнес расшифровки:
                  </p>
                </div>
                <div v-for="(usereport, i) in careerUserProducts" :key="i">
                  <div class="no-mobile">
                    <div
                      class="text--primary d-flex modal-view-reports-wrapper"
                    >
                      <v-col
                        v-if="
                          careerUserProducts.length === 1 &&
                            usereport.id === User.reportid
                        "
                        cols="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                      >
                        <div class="text-center display-0-24">
                          Данный отчет текущий
                        </div>
                      </v-col>
                      <v-col
                        v-if="
                          careerUserProducts.length === 1 && !usereport.is_used
                        "
                        cols="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                      >
                        <div class="text-center display-0-24">
                          Данный расчет еще неактивирован
                        </div>
                      </v-col>
                      <v-col
                        v-if="
                          usereport.id !== User.reportid && usereport.is_used
                        "
                        cols="12"
                        sm="6"
                        md="4"
                        lg="4"
                        xl="4"
                      >
                        <div class="display-0-24">Исходные данные</div>
                        <ul class="ul-no-li-style">
                          <li v-if="usereport.first_name">
                            <v-icon>mdi-account</v-icon>
                            <span>{{ usereport.first_name }}</span>
                          </li>
                          <li v-if="usereport.birthday">
                            <v-icon>mdi-calendar-today</v-icon>
                            <span>{{ usereport.birthday }}</span>
                          </li>
                          <li v-if="usereport.city">
                            <v-icon>mdi-city-variant</v-icon>
                            <span>{{ usereport.city.title }}</span>
                          </li>
                        </ul>
                      </v-col>
                      <v-col
                        v-if="
                          usereport.id !== User.reportid && usereport.is_used
                        "
                        cols="12"
                        sm="6"
                        md="4"
                        lg="4"
                        xl="4"
                      >
                        <div class="display-0-24">Human Design</div>
                        <ul class="ul-no-li-style">
                          <li v-if="usereport.chart_type">
                            <v-icon>mdi-account-box-outline</v-icon>
                            <span>{{ usereport.chart_type }}</span>
                          </li>
                          <li v-if="usereport.chart_profile">
                            <v-icon>mdi-account-circle</v-icon>
                            <span>Профиль {{ usereport.chart_profile }}</span>
                          </li>
                        </ul>
                      </v-col>
                      <v-col
                        v-if="
                          usereport.id !== User.reportid && usereport.is_used
                        "
                        cols="12"
                        sm="6"
                        md="4"
                        lg="4"
                        xl="4"
                      >
                        <div class="display-0-24">Действия</div>
                        <v-tooltip right>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              @click="selectUserReport(usereport.id, 'user')"
                              class="mx-2 min-width-120 margin-btm-5"
                              small
                              color="primary"
                            >
                              <v-icon dark>mdi-call-split</v-icon
                              >&nbsp;Рейв-карта 1
                            </v-btn>
                          </template>
                          <span>Выбрать первую рейв-карту</span>
                        </v-tooltip>
                      </v-col>
                    </div>
                  </div>
                  <div class="no-desktop margin-bottom-15">
                    <div class="text--primary d-flex">
                      <v-col
                        v-if="
                          usereport.id !== User.reportid && usereport.is_used
                        "
                        cols="6"
                      >
                        <ul class="ul-no-li-style">
                          <li v-if="usereport.first_name">
                            <v-icon>mdi-account</v-icon>
                            <span>{{ usereport.first_name }}</span>
                          </li>
                          <li v-if="usereport.birthday">
                            <v-icon>mdi-calendar-today</v-icon>
                            <span>
                              {{ usereport.birthday }}
                            </span>
                          </li>
                          <li v-if="usereport.city">
                            <v-icon>mdi-city-variant</v-icon>
                            <span>{{ usereport.city.title }}</span>
                          </li>
                        </ul>
                      </v-col>
                      <v-col
                        v-if="
                          usereport.id !== User.reportid && usereport.is_used
                        "
                        cols="6"
                      >
                        <ul class="ul-no-li-style">
                          <li v-if="usereport.chart_type">
                            <v-icon>mdi-account-box-outline</v-icon>
                            <span>{{ usereport.chart_type }}</span>
                          </li>
                          <li v-if="usereport.chart_profile">
                            <v-icon>mdi-account-circle</v-icon>
                            <span>Профиль {{ usereport.chart_profile }}</span>
                          </li>
                        </ul>
                      </v-col>
                    </div>
                    <div
                      v-if="usereport.id !== User.reportid && usereport.is_used"
                      class="text--primary d-flex"
                    >
                      <v-col class="flex-center padding-top-0" cols="12">
                        <v-btn
                          @click="selectUserReport(usereport.id, 'user')"
                          class="my-prof-controls-flex"
                          color="primary"
                          fab
                          small
                          icon
                          dark
                        >
                          <v-icon dark>mdi-call-split</v-icon>
                          <span
                            class="d-flex"
                            style="font-size:11px;margin-top:5px"
                          >
                            Рейв-карта 1
                          </span>
                        </v-btn>
                      </v-col>
                    </div>
                  </div>
                </div>
              </v-col>
              <br />
              <v-col
                v-if="childUserProducts.length && User.isLogged"
                class="mob-nopadding"
                cols="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
              >
                <div
                  class="display-0-5 text--primary demo-report-header"
                  style="margin-top: 25px"
                >
                  <p class="text--primary">
                    Детские расшифровки:
                  </p>
                </div>
                <div v-for="(usereport, i) in childUserProducts" :key="i">
                  <div class="no-mobile">
                    <div
                      class="text--primary d-flex modal-view-reports-wrapper"
                    >
                      <v-col
                        v-if="
                          childUserProducts.length === 1 &&
                            usereport.id === User.reportid
                        "
                        cols="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                      >
                        <div class="text-center display-0-24">
                          Данный отчет текущий
                        </div>
                      </v-col>
                      <v-col
                        v-if="
                          childUserProducts.length === 1 && !usereport.is_used
                        "
                        cols="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                      >
                        <div class="text-center display-0-24">
                          Данный расчет еще неактивирован
                        </div>
                      </v-col>
                      <v-col
                        v-if="
                          usereport.id !== User.reportid && usereport.is_used
                        "
                        cols="12"
                        sm="6"
                        md="4"
                        lg="4"
                        xl="4"
                      >
                        <div class="display-0-24">Исходные данные</div>
                        <ul class="ul-no-li-style">
                          <li v-if="usereport.first_name">
                            <v-icon>mdi-account</v-icon>
                            <span>{{ usereport.first_name }}</span>
                          </li>
                          <li v-if="usereport.birthday">
                            <v-icon>mdi-calendar-today</v-icon>
                            <span>{{ usereport.birthday }}</span>
                          </li>
                          <li v-if="usereport.city">
                            <v-icon>mdi-city-variant</v-icon>
                            <span>{{ usereport.city.title }}</span>
                          </li>
                        </ul>
                      </v-col>
                      <v-col
                        v-if="
                          usereport.id !== User.reportid && usereport.is_used
                        "
                        cols="12"
                        sm="6"
                        md="4"
                        lg="4"
                        xl="4"
                      >
                        <div class="display-0-24">Human Design</div>
                        <ul class="ul-no-li-style">
                          <li v-if="usereport.chart_type">
                            <v-icon>mdi-account-box-outline</v-icon>
                            <span>{{ usereport.chart_type }}</span>
                          </li>
                          <li v-if="usereport.chart_profile">
                            <v-icon>mdi-account-circle</v-icon>
                            <span>Профиль {{ usereport.chart_profile }}</span>
                          </li>
                        </ul>
                      </v-col>
                      <v-col
                        v-if="
                          usereport.id !== User.reportid && usereport.is_used
                        "
                        cols="12"
                        sm="6"
                        md="4"
                        lg="4"
                        xl="4"
                      >
                        <div class="display-0-24">Действия</div>
                        <v-tooltip right>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              @click="selectUserReport(usereport.id, 'user')"
                              class="mx-2 min-width-120 margin-btm-5"
                              small
                              color="primary"
                            >
                              <v-icon dark>mdi-call-split</v-icon
                              >&nbsp;Рейв-карта 1
                            </v-btn>
                          </template>
                          <span>Выбрать первую рейв-карту</span>
                        </v-tooltip>
                      </v-col>
                    </div>
                  </div>
                  <div class="no-desktop margin-bottom-15">
                    <div class="text--primary d-flex">
                      <v-col
                        v-if="
                          usereport.id !== User.reportid && usereport.is_used
                        "
                        cols="6"
                      >
                        <ul class="ul-no-li-style">
                          <li v-if="usereport.first_name">
                            <v-icon>mdi-account</v-icon>
                            <span>{{ usereport.first_name }}</span>
                          </li>
                          <li v-if="usereport.birthday">
                            <v-icon>mdi-calendar-today</v-icon>
                            <span>
                              {{ usereport.birthday }}
                            </span>
                          </li>
                          <li v-if="usereport.city">
                            <v-icon>mdi-city-variant</v-icon>
                            <span>{{ usereport.city.title }}</span>
                          </li>
                        </ul>
                      </v-col>
                      <v-col
                        v-if="
                          usereport.id !== User.reportid && usereport.is_used
                        "
                        cols="6"
                      >
                        <ul class="ul-no-li-style">
                          <li v-if="usereport.chart_type">
                            <v-icon>mdi-account-box-outline</v-icon>
                            <span>{{ usereport.chart_type }}</span>
                          </li>
                          <li v-if="usereport.chart_profile">
                            <v-icon>mdi-account-circle</v-icon>
                            <span>Профиль {{ usereport.chart_profile }}</span>
                          </li>
                        </ul>
                      </v-col>
                    </div>
                    <div
                      v-if="usereport.id !== User.reportid && usereport.is_used"
                      class="text--primary d-flex"
                    >
                      <v-col class="flex-center padding-top-0" cols="12">
                        <v-btn
                          @click="selectUserReport(usereport.id, 'user')"
                          class="my-prof-controls-flex"
                          color="primary"
                          fab
                          small
                          icon
                          dark
                        >
                          <v-icon dark>mdi-call-split</v-icon>
                          <span
                            class="d-flex"
                            style="font-size:11px;margin-top:5px"
                          >
                            Рейв-карта 1
                          </span>
                        </v-btn>
                      </v-col>
                    </div>
                  </div>
                </div>
              </v-col>
              <br />
              <v-col
                v-if="userProReports.length && User.isLogged"
                class="mob-nopadding"
                cols="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
              >
                <div
                  class="display-0-5 text--primary demo-report-header"
                  style="margin-top: 25px"
                >
                  <p class="text--primary">
                    Pro-подсказки:
                  </p>
                </div>
                <div v-for="(proreport, i) in userProReports" :key="i">
                  <div class="no-mobile">
                    <div
                      class="text--primary d-flex modal-view-reports-wrapper"
                    >
                      <v-col
                        v-if="
                          userProReports.length === 1 &&
                            proreport.id === User.reportid
                        "
                        cols="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                      >
                        <div class="text-center display-0-24">
                          Данный отчет текущий
                        </div>
                      </v-col>
                      <v-col
                        v-if="userProReports.length === 1 && !proreport.is_used"
                        cols="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                      >
                        <div class="text-center display-0-24">
                          Данный расчет еще неактивирован
                        </div>
                      </v-col>
                      <v-col
                        v-if="
                          proreport.id !== User.reportid && proreport.is_used
                        "
                        cols="12"
                        sm="6"
                        md="4"
                        lg="4"
                        xl="4"
                      >
                        <div class="display-0-24">Исходные данные</div>
                        <ul class="ul-no-li-style">
                          <li v-if="proreport.first_name">
                            <v-icon>mdi-account</v-icon>
                            <span>{{ proreport.first_name }}</span>
                          </li>
                          <li v-if="proreport.birthday">
                            <v-icon>mdi-calendar-today</v-icon>
                            <span>{{ proreport.birthday }}</span>
                          </li>
                          <li v-if="proreport.city">
                            <v-icon>mdi-city-variant</v-icon>
                            <span>{{ proreport.city.title }}</span>
                          </li>
                        </ul>
                      </v-col>
                      <v-col
                        v-if="
                          proreport.id !== User.reportid && proreport.is_used
                        "
                        cols="12"
                        sm="6"
                        md="4"
                        lg="4"
                        xl="4"
                      >
                        <div class="display-0-24">Human Design</div>
                        <ul class="ul-no-li-style">
                          <li v-if="proreport.chart_type">
                            <v-icon>mdi-account-box-outline</v-icon>
                            <span>{{ proreport.chart_type }}</span>
                          </li>
                          <li v-if="proreport.chart_profile">
                            <v-icon>mdi-account-circle</v-icon>
                            <span>Профиль {{ proreport.chart_profile }}</span>
                          </li>
                        </ul>
                      </v-col>
                      <v-col
                        v-if="
                          proreport.id !== User.reportid && proreport.is_used
                        "
                        cols="12"
                        sm="6"
                        md="4"
                        lg="4"
                        xl="4"
                      >
                        <div class="display-0-24">Действия</div>
                        <v-tooltip right>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              @click="selectUserReport(proreport.id)"
                              class="mx-2 min-width-120 margin-btm-5"
                              small
                              color="primary"
                            >
                              <v-icon dark>mdi-call-split</v-icon
                              >&nbsp;Рейв-карта 1
                            </v-btn>
                          </template>
                          <span>Выбрать первую рейв-карту</span>
                        </v-tooltip>
                      </v-col>
                    </div>
                  </div>
                  <div class="no-desktop margin-bottom-15">
                    <div class="text--primary d-flex">
                      <v-col
                        v-if="
                          proreport.id !== User.reportid && proreport.is_used
                        "
                        cols="6"
                      >
                        <ul class="ul-no-li-style">
                          <li v-if="proreport.first_name">
                            <v-icon>mdi-account</v-icon>
                            <span>{{ proreport.first_name }}</span>
                          </li>
                          <li v-if="proreport.birthday">
                            <v-icon>mdi-calendar-today</v-icon>
                            <span>
                              {{ proreport.birthday }}
                            </span>
                          </li>
                          <li v-if="proreport.city">
                            <v-icon>mdi-city-variant</v-icon>
                            <span>{{ proreport.city.title }}</span>
                          </li>
                        </ul>
                      </v-col>
                      <v-col
                        v-if="
                          proreport.id !== User.reportid && proreport.is_used
                        "
                        cols="6"
                      >
                        <ul class="ul-no-li-style">
                          <li v-if="proreport.chart_type">
                            <v-icon>mdi-account-box-outline</v-icon>
                            <span>{{ proreport.chart_type }}</span>
                          </li>
                          <li v-if="proreport.chart_profile">
                            <v-icon>mdi-account-circle</v-icon>
                            <span>Профиль {{ proreport.chart_profile }}</span>
                          </li>
                        </ul>
                      </v-col>
                    </div>
                    <div
                      v-if="proreport.id !== User.reportid && proreport.is_used"
                      class="text--primary d-flex"
                    >
                      <v-col class="flex-center padding-top-0" cols="12">
                        <v-btn
                          @click="selectUserReport(proreport.id)"
                          class="my-prof-controls-flex"
                          color="primary"
                          fab
                          small
                          icon
                          dark
                        >
                          <v-icon dark>mdi-call-split</v-icon>
                          <span
                            class="d-flex"
                            style="font-size:11px;margin-top:5px"
                          >
                            Рейв-карта 1
                          </span>
                        </v-btn>
                      </v-col>
                    </div>
                  </div>
                </div>
              </v-col>
              <br />
              <v-col
                v-if="userReports.length && User.isLogged"
                class="mob-nopadding"
                cols="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
              >
                <div
                  class="display-0-5 text--primary demo-report-header"
                  style="margin-top: 25px"
                >
                  <p class="text--primary">
                    Демо-расчеты:
                  </p>
                </div>
                <div v-for="(report, i) in userReports" :key="i">
                  <div class="no-mobile">
                    <div
                      class="text--primary d-flex modal-view-reports-wrapper"
                    >
                      <v-col
                        v-if="
                          userReports.length === 1 &&
                            report.id === User.reportid
                        "
                        cols="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                      >
                        <div class="text-center display-0-24">
                          Данный отчет текущий
                        </div>
                      </v-col>
                      <v-col
                        v-if="report.id !== User.reportid"
                        cols="12"
                        sm="6"
                        md="4"
                        lg="4"
                        xl="4"
                      >
                        <div class="display-0-24">Исходные данные</div>
                        <ul class="ul-no-li-style">
                          <li v-if="report.first_name">
                            <v-icon>mdi-account</v-icon>
                            <span>{{ report.first_name }}</span>
                          </li>
                          <li v-if="report.birthday">
                            <v-icon>mdi-calendar-today</v-icon>
                            <span>{{ report.birthday }}</span>
                          </li>
                          <li v-if="report.city">
                            <v-icon>mdi-city-variant</v-icon>
                            <span>{{ report.city.title }}</span>
                          </li>
                        </ul>
                      </v-col>
                      <v-col
                        v-if="report.id !== User.reportid"
                        cols="12"
                        sm="6"
                        md="4"
                        lg="4"
                        xl="4"
                      >
                        <div class="display-0-24">Human Design</div>
                        <ul class="ul-no-li-style">
                          <li v-if="report.chart_type">
                            <v-icon>mdi-account-box-outline</v-icon>
                            <span>{{ report.chart_type }}</span>
                          </li>
                          <li v-if="report.chart_profile">
                            <v-icon>mdi-account-circle</v-icon>
                            <span>Профиль {{ report.chart_profile }}</span>
                          </li>
                        </ul>
                      </v-col>
                      <v-col
                        v-if="report.id !== User.reportid"
                        cols="12"
                        sm="6"
                        md="4"
                        lg="4"
                        xl="4"
                      >
                        <div class="display-0-24">Действия</div>
                        <v-tooltip right>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              @click="selectUserReport(report.id)"
                              class="mx-2 min-width-120 margin-btm-5"
                              small
                              color="primary"
                            >
                              <v-icon dark>mdi-call-split</v-icon
                              >&nbsp;Рейв-карта 1
                            </v-btn>
                          </template>
                          <span>Выбрать первую рейв-карту</span>
                        </v-tooltip>
                      </v-col>
                    </div>
                  </div>
                  <div class="no-desktop margin-bottom-15">
                    <div class="text--primary d-flex">
                      <v-col v-if="report.id !== User.reportid" cols="6">
                        <ul class="ul-no-li-style">
                          <li v-if="report.first_name">
                            <v-icon>mdi-account</v-icon>
                            <span>{{ report.first_name }}</span>
                          </li>
                          <li v-if="report.birthday">
                            <v-icon>mdi-calendar-today</v-icon>
                            <span>
                              {{ report.birthday }}
                            </span>
                          </li>
                          <li v-if="report.city">
                            <v-icon>mdi-city-variant</v-icon>
                            <span>{{ report.city.title }}</span>
                          </li>
                        </ul>
                      </v-col>
                      <v-col v-if="report.id !== User.reportid" cols="6">
                        <ul class="ul-no-li-style">
                          <li v-if="report.chart_type">
                            <v-icon>mdi-account-box-outline</v-icon>
                            <span>{{ report.chart_type }}</span>
                          </li>
                          <li v-if="report.chart_profile">
                            <v-icon>mdi-account-circle</v-icon>
                            <span>Профиль {{ report.chart_profile }}</span>
                          </li>
                        </ul>
                      </v-col>
                    </div>
                    <div
                      v-if="report.id !== User.reportid"
                      class="text--primary d-flex"
                    >
                      <v-col class="flex-center padding-top-0" cols="12">
                        <v-btn
                          @click="selectUserReport(report.id)"
                          class="my-prof-controls-flex"
                          color="primary"
                          fab
                          small
                          icon
                          dark
                        >
                          <v-icon dark>mdi-call-split</v-icon>
                          <span
                            class="d-flex"
                            style="font-size:11px;margin-top:5px"
                          >
                            Рейв-карта 1
                          </span>
                        </v-btn>
                      </v-col>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="chart2compositedialog"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      scrollable
      max-width="900px"
      style="z-index: 9999"
    >
      <v-card class="modal-pay-report">
        <v-card-title>
          <span
            v-html="getMessage('dialog_txt_chart2composite')"
            class="headline-d-blue modal-header2 сolor-comp-chart2"
          >
          </span>
          <v-btn @click="chart2compositedialog = false" icon absolute right>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                v-if="userProdReports.length && User.isLogged"
                class="mob-nopadding"
                cols="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
              >
                <div
                  class="display-0-5 text--primary demo-report-header"
                  style="margin-top: 25px"
                >
                  <p class="text--primary">
                    Расшифровки:
                  </p>
                </div>
                <div v-for="(usereport, i) in userProdReports" :key="i">
                  <div class="no-mobile">
                    <div
                      class="text--primary d-flex modal-view-reports-wrapper"
                    >
                      <v-col
                        v-if="
                          userProdReports.length === 1 &&
                            usereport.id === compReports.id1
                        "
                        cols="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                      >
                        <div class="text-center display-0-24">
                          Данный расчет уже выбран
                        </div>
                      </v-col>
                      <v-col
                        v-if="
                          usereport.id !== compReports.id1 && usereport.is_used
                        "
                        cols="12"
                        sm="6"
                        md="4"
                        lg="4"
                        xl="4"
                      >
                        <div class="display-0-24">Исходные данные</div>
                        <ul class="ul-no-li-style">
                          <li v-if="usereport.first_name">
                            <v-icon>mdi-account</v-icon>
                            <span>{{ usereport.first_name }}</span>
                          </li>
                          <li v-if="usereport.birthday">
                            <v-icon>mdi-calendar-today</v-icon>
                            <span>{{ usereport.birthday }}</span>
                          </li>
                          <li v-if="usereport.city">
                            <v-icon>mdi-city-variant</v-icon>
                            <span>{{ usereport.city.title }}</span>
                          </li>
                        </ul>
                      </v-col>
                      <v-col
                        v-if="
                          usereport.id !== compReports.id1 && usereport.is_used
                        "
                        cols="12"
                        sm="6"
                        md="4"
                        lg="4"
                        xl="4"
                      >
                        <div class="display-0-24">Human Design</div>
                        <ul class="ul-no-li-style">
                          <li v-if="usereport.chart_type">
                            <v-icon>mdi-account-box-outline</v-icon>
                            <span>{{ usereport.chart_type }}</span>
                          </li>
                          <li v-if="usereport.chart_profile">
                            <v-icon>mdi-account-circle</v-icon>
                            <span>Профиль {{ usereport.chart_profile }}</span>
                          </li>
                        </ul>
                      </v-col>
                      <v-col
                        v-if="
                          usereport.id !== compReports.id1 && usereport.is_used
                        "
                        cols="12"
                        sm="6"
                        md="4"
                        lg="4"
                        xl="4"
                      >
                        <div class="display-0-24">Действия</div>
                        <v-tooltip right>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              @click="
                                getBodygraphByComposite(
                                  'create',
                                  compReports.id1,
                                  usereport.id,
                                  'user'
                                )
                              "
                              class="mx-2 min-width-120 margin-btm-5"
                              small
                              color="primary"
                            >
                              <v-icon dark>mdi-call-split</v-icon
                              >&nbsp;Рейв-карта 2
                            </v-btn>
                          </template>
                          <span>Выбрать вторую рейв-карту</span>
                        </v-tooltip>
                      </v-col>
                    </div>
                  </div>
                  <div class="no-desktop margin-bottom-15">
                    <div class="text--primary d-flex">
                      <v-col
                        v-if="
                          usereport.id !== compReports.id1 && usereport.is_used
                        "
                        cols="6"
                      >
                        <ul class="ul-no-li-style">
                          <li v-if="usereport.first_name">
                            <v-icon>mdi-account</v-icon>
                            <span>{{ usereport.first_name }}</span>
                          </li>
                          <li v-if="usereport.birthday">
                            <v-icon>mdi-calendar-today</v-icon>
                            <span>
                              {{ usereport.birthday }}
                            </span>
                          </li>
                          <li v-if="usereport.city">
                            <v-icon>mdi-city-variant</v-icon>
                            <span>{{ usereport.city.title }}</span>
                          </li>
                        </ul>
                      </v-col>
                      <v-col
                        v-if="
                          usereport.id !== compReports.id1 && usereport.is_used
                        "
                        cols="6"
                      >
                        <ul class="ul-no-li-style">
                          <li v-if="usereport.chart_type">
                            <v-icon>mdi-account-box-outline</v-icon>
                            <span>{{ usereport.chart_type }}</span>
                          </li>
                          <li v-if="usereport.chart_profile">
                            <v-icon>mdi-account-circle</v-icon>
                            <span>Профиль {{ usereport.chart_profile }}</span>
                          </li>
                        </ul>
                      </v-col>
                    </div>
                    <div
                      v-if="
                        usereport.id !== compReports.id1 && usereport.is_used
                      "
                      class="text--primary d-flex"
                    >
                      <v-col class="flex-center padding-top-0" cols="12">
                        <v-btn
                          @click="
                            getBodygraphByComposite(
                              'create',
                              compReports.id1,
                              usereport.id,
                              'user'
                            )
                          "
                          class="my-prof-controls-flex"
                          color="primary"
                          fab
                          small
                          icon
                          dark
                        >
                          <v-icon dark>mdi-call-split</v-icon>
                          <span
                            class="d-flex"
                            style="font-size:11px;margin-top:5px"
                          >
                            Рейв-карта 2
                          </span>
                        </v-btn>
                      </v-col>
                    </div>
                  </div>
                </div>
              </v-col>
              <br />
              <v-col
                v-if="careerUserProducts.length && User.isLogged"
                class="mob-nopadding"
                cols="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
              >
                <div
                  class="display-0-5 text--primary demo-report-header"
                  style="margin-top: 25px"
                >
                  <p class="text--primary">
                    Бизнес расшифровки:
                  </p>
                </div>
                <div v-for="(usereport, i) in careerUserProducts" :key="i">
                  <div class="no-mobile">
                    <div
                      class="text--primary d-flex modal-view-reports-wrapper"
                    >
                      <v-col
                        v-if="
                          careerUserProducts.length === 1 &&
                            usereport.id === compReports.id1
                        "
                        cols="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                      >
                        <div class="text-center display-0-24">
                          Данный расчет уже выбран
                        </div>
                      </v-col>
                      <v-col
                        v-if="
                          usereport.id !== compReports.id1 && usereport.is_used
                        "
                        cols="12"
                        sm="6"
                        md="4"
                        lg="4"
                        xl="4"
                      >
                        <div class="display-0-24">Исходные данные</div>
                        <ul class="ul-no-li-style">
                          <li v-if="usereport.first_name">
                            <v-icon>mdi-account</v-icon>
                            <span>{{ usereport.first_name }}</span>
                          </li>
                          <li v-if="usereport.birthday">
                            <v-icon>mdi-calendar-today</v-icon>
                            <span>{{ usereport.birthday }}</span>
                          </li>
                          <li v-if="usereport.city">
                            <v-icon>mdi-city-variant</v-icon>
                            <span>{{ usereport.city.title }}</span>
                          </li>
                        </ul>
                      </v-col>
                      <v-col
                        v-if="
                          usereport.id !== compReports.id1 && usereport.is_used
                        "
                        cols="12"
                        sm="6"
                        md="4"
                        lg="4"
                        xl="4"
                      >
                        <div class="display-0-24">Human Design</div>
                        <ul class="ul-no-li-style">
                          <li v-if="usereport.chart_type">
                            <v-icon>mdi-account-box-outline</v-icon>
                            <span>{{ usereport.chart_type }}</span>
                          </li>
                          <li v-if="usereport.chart_profile">
                            <v-icon>mdi-account-circle</v-icon>
                            <span>Профиль {{ usereport.chart_profile }}</span>
                          </li>
                        </ul>
                      </v-col>
                      <v-col
                        v-if="
                          usereport.id !== compReports.id1 && usereport.is_used
                        "
                        cols="12"
                        sm="6"
                        md="4"
                        lg="4"
                        xl="4"
                      >
                        <div class="display-0-24">Действия</div>
                        <v-tooltip right>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              @click="
                                getBodygraphByComposite(
                                  'create',
                                  compReports.id1,
                                  usereport.id,
                                  'user'
                                )
                              "
                              class="mx-2 min-width-120 margin-btm-5"
                              small
                              color="primary"
                            >
                              <v-icon dark>mdi-call-split</v-icon
                              >&nbsp;Рейв-карта 2
                            </v-btn>
                          </template>
                          <span>Выбрать вторую рейв-карту</span>
                        </v-tooltip>
                      </v-col>
                    </div>
                  </div>
                  <div class="no-desktop margin-bottom-15">
                    <div class="text--primary d-flex">
                      <v-col
                        v-if="
                          usereport.id !== compReports.id1 && usereport.is_used
                        "
                        cols="6"
                      >
                        <ul class="ul-no-li-style">
                          <li v-if="usereport.first_name">
                            <v-icon>mdi-account</v-icon>
                            <span>{{ usereport.first_name }}</span>
                          </li>
                          <li v-if="usereport.birthday">
                            <v-icon>mdi-calendar-today</v-icon>
                            <span>
                              {{ usereport.birthday }}
                            </span>
                          </li>
                          <li v-if="usereport.city">
                            <v-icon>mdi-city-variant</v-icon>
                            <span>{{ usereport.city.title }}</span>
                          </li>
                        </ul>
                      </v-col>
                      <v-col
                        v-if="
                          usereport.id !== compReports.id1 && usereport.is_used
                        "
                        cols="6"
                      >
                        <ul class="ul-no-li-style">
                          <li v-if="usereport.chart_type">
                            <v-icon>mdi-account-box-outline</v-icon>
                            <span>{{ usereport.chart_type }}</span>
                          </li>
                          <li v-if="usereport.chart_profile">
                            <v-icon>mdi-account-circle</v-icon>
                            <span>Профиль {{ usereport.chart_profile }}</span>
                          </li>
                        </ul>
                      </v-col>
                    </div>
                    <div
                      v-if="
                        usereport.id !== compReports.id1 && usereport.is_used
                      "
                      class="text--primary d-flex"
                    >
                      <v-col class="flex-center padding-top-0" cols="12">
                        <v-btn
                          @click="
                            getBodygraphByComposite(
                              'create',
                              compReports.id1,
                              usereport.id,
                              'user'
                            )
                          "
                          class="my-prof-controls-flex"
                          color="primary"
                          fab
                          small
                          icon
                          dark
                        >
                          <v-icon dark>mdi-call-split</v-icon>
                          <span
                            class="d-flex"
                            style="font-size:11px;margin-top:5px"
                          >
                            Рейв-карта 2
                          </span>
                        </v-btn>
                      </v-col>
                    </div>
                  </div>
                </div>
              </v-col>
              <br />
              <v-col
                v-if="childUserProducts.length && User.isLogged"
                class="mob-nopadding"
                cols="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
              >
                <div
                  class="display-0-5 text--primary demo-report-header"
                  style="margin-top: 25px"
                >
                  <p class="text--primary">
                    Детские расшифровки:
                  </p>
                </div>
                <div v-for="(usereport, i) in childUserProducts" :key="i">
                  <div class="no-mobile">
                    <div
                      class="text--primary d-flex modal-view-reports-wrapper"
                    >
                      <v-col
                        v-if="
                          childUserProducts.length === 1 &&
                            usereport.id === compReports.id1
                        "
                        cols="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                      >
                        <div class="text-center display-0-24">
                          Данный расчет уже выбран
                        </div>
                      </v-col>
                      <v-col
                        v-if="
                          usereport.id !== compReports.id1 && usereport.is_used
                        "
                        cols="12"
                        sm="6"
                        md="4"
                        lg="4"
                        xl="4"
                      >
                        <div class="display-0-24">Исходные данные</div>
                        <ul class="ul-no-li-style">
                          <li v-if="usereport.first_name">
                            <v-icon>mdi-account</v-icon>
                            <span>{{ usereport.first_name }}</span>
                          </li>
                          <li v-if="usereport.birthday">
                            <v-icon>mdi-calendar-today</v-icon>
                            <span>{{ usereport.birthday }}</span>
                          </li>
                          <li v-if="usereport.city">
                            <v-icon>mdi-city-variant</v-icon>
                            <span>{{ usereport.city.title }}</span>
                          </li>
                        </ul>
                      </v-col>
                      <v-col
                        v-if="
                          usereport.id !== compReports.id1 && usereport.is_used
                        "
                        cols="12"
                        sm="6"
                        md="4"
                        lg="4"
                        xl="4"
                      >
                        <div class="display-0-24">Human Design</div>
                        <ul class="ul-no-li-style">
                          <li v-if="usereport.chart_type">
                            <v-icon>mdi-account-box-outline</v-icon>
                            <span>{{ usereport.chart_type }}</span>
                          </li>
                          <li v-if="usereport.chart_profile">
                            <v-icon>mdi-account-circle</v-icon>
                            <span>Профиль {{ usereport.chart_profile }}</span>
                          </li>
                        </ul>
                      </v-col>
                      <v-col
                        v-if="
                          usereport.id !== compReports.id1 && usereport.is_used
                        "
                        cols="12"
                        sm="6"
                        md="4"
                        lg="4"
                        xl="4"
                      >
                        <div class="display-0-24">Действия</div>
                        <v-tooltip right>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              @click="
                                getBodygraphByComposite(
                                  'create',
                                  compReports.id1,
                                  usereport.id,
                                  'user'
                                )
                              "
                              class="mx-2 min-width-120 margin-btm-5"
                              small
                              color="primary"
                            >
                              <v-icon dark>mdi-call-split</v-icon
                              >&nbsp;Рейв-карта 2
                            </v-btn>
                          </template>
                          <span>Выбрать вторую рейв-карту</span>
                        </v-tooltip>
                      </v-col>
                    </div>
                  </div>
                  <div class="no-desktop margin-bottom-15">
                    <div class="text--primary d-flex">
                      <v-col
                        v-if="
                          usereport.id !== compReports.id1 && usereport.is_used
                        "
                        cols="6"
                      >
                        <ul class="ul-no-li-style">
                          <li v-if="usereport.first_name">
                            <v-icon>mdi-account</v-icon>
                            <span>{{ usereport.first_name }}</span>
                          </li>
                          <li v-if="usereport.birthday">
                            <v-icon>mdi-calendar-today</v-icon>
                            <span>
                              {{ usereport.birthday }}
                            </span>
                          </li>
                          <li v-if="usereport.city">
                            <v-icon>mdi-city-variant</v-icon>
                            <span>{{ usereport.city.title }}</span>
                          </li>
                        </ul>
                      </v-col>
                      <v-col
                        v-if="
                          usereport.id !== compReports.id1 && usereport.is_used
                        "
                        cols="6"
                      >
                        <ul class="ul-no-li-style">
                          <li v-if="usereport.chart_type">
                            <v-icon>mdi-account-box-outline</v-icon>
                            <span>{{ usereport.chart_type }}</span>
                          </li>
                          <li v-if="usereport.chart_profile">
                            <v-icon>mdi-account-circle</v-icon>
                            <span>Профиль {{ usereport.chart_profile }}</span>
                          </li>
                        </ul>
                      </v-col>
                    </div>
                    <div
                      v-if="
                        usereport.id !== compReports.id1 && usereport.is_used
                      "
                      class="text--primary d-flex"
                    >
                      <v-col class="flex-center padding-top-0" cols="12">
                        <v-btn
                          @click="
                            getBodygraphByComposite(
                              'create',
                              compReports.id1,
                              usereport.id,
                              'user'
                            )
                          "
                          class="my-prof-controls-flex"
                          color="primary"
                          fab
                          small
                          icon
                          dark
                        >
                          <v-icon dark>mdi-call-split</v-icon>
                          <span
                            class="d-flex"
                            style="font-size:11px;margin-top:5px"
                          >
                            Рейв-карта 2
                          </span>
                        </v-btn>
                      </v-col>
                    </div>
                  </div>
                </div>
              </v-col>
              <br />
              <v-col
                v-if="userProReports.length && User.isLogged"
                cols="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
              >
                <div
                  class="display-0-5 text--primary demo-report-header"
                  style="margin-top: 25px"
                >
                  <p class="text--primary">
                    Pro-подсказки:
                  </p>
                </div>
                <div v-for="(proreport, i) in userProReports" :key="i">
                  <div class="no-mobile">
                    <div
                      class="text--primary d-flex modal-view-reports-wrapper"
                    >
                      <v-col
                        v-if="
                          userProReports.length === 1 &&
                            proreport.id === compReports.id1
                        "
                        cols="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                      >
                        <div class="text-center display-0-24">
                          Данный расчет уже выбран
                        </div>
                      </v-col>
                      <v-col
                        v-if="
                          proreport.id !== compReports.id1 && proreport.is_used
                        "
                        cols="12"
                        sm="6"
                        md="4"
                        lg="4"
                        xl="4"
                      >
                        <div class="display-0-24">Исходные данные</div>
                        <ul class="ul-no-li-style">
                          <li v-if="proreport.first_name">
                            <v-icon>mdi-account</v-icon>
                            <span>{{ proreport.first_name }}</span>
                          </li>
                          <li v-if="proreport.birthday">
                            <v-icon>mdi-calendar-today</v-icon>
                            <span>{{ proreport.birthday }}</span>
                          </li>
                          <li v-if="proreport.city">
                            <v-icon>mdi-city-variant</v-icon>
                            <span>{{ proreport.city.title }}</span>
                          </li>
                        </ul>
                      </v-col>
                      <v-col
                        v-if="
                          proreport.id !== compReports.id1 && proreport.is_used
                        "
                        cols="12"
                        sm="6"
                        md="4"
                        lg="4"
                        xl="4"
                      >
                        <div class="display-0-24">Human Design</div>
                        <ul class="ul-no-li-style">
                          <li v-if="proreport.chart_type">
                            <v-icon>mdi-account-box-outline</v-icon>
                            <span>{{ proreport.chart_type }}</span>
                          </li>
                          <li v-if="proreport.chart_profile">
                            <v-icon>mdi-account-circle</v-icon>
                            <span>Профиль {{ proreport.chart_profile }}</span>
                          </li>
                        </ul>
                      </v-col>
                      <v-col
                        v-if="
                          proreport.id !== compReports.id1 && proreport.is_used
                        "
                        cols="12"
                        sm="6"
                        md="4"
                        lg="4"
                        xl="4"
                      >
                        <div class="display-0-24">Действия</div>
                        <v-tooltip right>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              @click="
                                getBodygraphByComposite(
                                  'create',
                                  compReports.id1,
                                  proreport.id
                                )
                              "
                              class="mx-2 min-width-120 margin-btm-5"
                              small
                              color="primary"
                            >
                              <v-icon dark>mdi-call-split</v-icon
                              >&nbsp;Рейв-карта 2
                            </v-btn>
                          </template>
                          <span>Выбрать вторую рейв-карту</span>
                        </v-tooltip>
                      </v-col>
                    </div>
                  </div>
                  <div class="no-desktop margin-bottom-15">
                    <div class="text--primary d-flex">
                      <v-col
                        v-if="
                          proreport.id !== compReports.id1 && proreport.is_used
                        "
                        cols="6"
                      >
                        <ul class="ul-no-li-style">
                          <li v-if="proreport.first_name">
                            <v-icon>mdi-account</v-icon>
                            <span>{{ proreport.first_name }}</span>
                          </li>
                          <li v-if="proreport.birthday">
                            <v-icon>mdi-calendar-today</v-icon>
                            <span>
                              {{ proreport.birthday }}
                            </span>
                          </li>
                          <li v-if="proreport.city">
                            <v-icon>mdi-city-variant</v-icon>
                            <span>{{ proreport.city.title }}</span>
                          </li>
                        </ul>
                      </v-col>
                      <v-col
                        v-if="
                          proreport.id !== compReports.id1 && proreport.is_used
                        "
                        cols="6"
                      >
                        <ul class="ul-no-li-style">
                          <li v-if="proreport.chart_type">
                            <v-icon>mdi-account-box-outline</v-icon>
                            <span>{{ proreport.chart_type }}</span>
                          </li>
                          <li v-if="proreport.chart_profile">
                            <v-icon>mdi-account-circle</v-icon>
                            <span>Профиль {{ proreport.chart_profile }}</span>
                          </li>
                        </ul>
                      </v-col>
                    </div>
                    <div
                      v-if="
                        proreport.id !== compReports.id1 && proreport.is_used
                      "
                      class="text--primary d-flex"
                    >
                      <v-col class="flex-center padding-top-0" cols="12">
                        <v-btn
                          @click="
                            getBodygraphByComposite(
                              'create',
                              compReports.id1,
                              proreport.id
                            )
                          "
                          class="my-prof-controls-flex"
                          color="primary"
                          fab
                          small
                          icon
                          dark
                        >
                          <v-icon dark>mdi-call-split</v-icon>
                          <span
                            class="d-flex"
                            style="font-size:11px;margin-top:5px"
                          >
                            Рейв-карта 2
                          </span>
                        </v-btn>
                      </v-col>
                    </div>
                  </div>
                </div>
              </v-col>
              <br />
              <v-col
                v-if="userReports.length && User.isLogged"
                class="mob-nopadding"
                cols="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
              >
                <div
                  class="display-0-5 text--primary demo-report-header"
                  style="margin-top: 25px"
                >
                  <p class="text-align-left text--primary">
                    Демо-расчеты:
                  </p>
                </div>
                <div v-for="(report, i) in userReports" :key="i">
                  <div class="no-mobile">
                    <div
                      class="text--primary d-flex modal-view-reports-wrapper"
                    >
                      <v-col
                        v-if="
                          userReports.length === 1 &&
                            report.id === compReports.id1
                        "
                        cols="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                      >
                        <div class="text-center display-0-24">
                          Данный расчет уже выбран
                        </div>
                      </v-col>
                      <v-col
                        v-if="
                          report.id !== User.reportid &&
                            report.id !== compReports.id1
                        "
                        cols="12"
                        sm="6"
                        md="4"
                        lg="4"
                        xl="4"
                      >
                        <div class="display-0-24">Исходные данные</div>
                        <ul class="ul-no-li-style">
                          <li v-if="report.first_name">
                            <v-icon>mdi-account</v-icon>
                            <span>{{ report.first_name }}</span>
                          </li>
                          <li v-if="report.birthday">
                            <v-icon>mdi-calendar-today</v-icon>
                            <span>{{ report.birthday }}</span>
                          </li>
                          <li v-if="report.city">
                            <v-icon>mdi-city-variant</v-icon>
                            <span>{{ report.city.title }}</span>
                          </li>
                        </ul>
                      </v-col>
                      <v-col
                        v-if="
                          report.id !== User.reportid &&
                            report.id !== compReports.id1
                        "
                        cols="12"
                        sm="6"
                        md="4"
                        lg="4"
                        xl="4"
                      >
                        <div class="display-0-24">Human Design</div>
                        <ul class="ul-no-li-style">
                          <li v-if="report.chart_type">
                            <v-icon>mdi-account-box-outline</v-icon>
                            <span>{{ report.chart_type }}</span>
                          </li>
                          <li v-if="report.chart_profile">
                            <v-icon>mdi-account-circle</v-icon>
                            <span>Профиль {{ report.chart_profile }}</span>
                          </li>
                        </ul>
                      </v-col>
                      <v-col
                        v-if="
                          report.id !== User.reportid &&
                            report.id !== compReports.id1
                        "
                        cols="12"
                        sm="6"
                        md="4"
                        lg="4"
                        xl="4"
                      >
                        <div class="display-0-24">Действия</div>
                        <v-tooltip right>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              @click="
                                getBodygraphByComposite(
                                  'create',
                                  compReports.id1,
                                  report.id
                                )
                              "
                              class="mx-2 min-width-120 margin-btm-5"
                              small
                              color="primary"
                            >
                              <v-icon dark>mdi-call-split</v-icon
                              >&nbsp;Рейв-карта 2
                            </v-btn>
                          </template>
                          <span>Выбрать вторую рейв-карту</span>
                        </v-tooltip>
                      </v-col>
                    </div>
                  </div>
                  <div class="no-desktop margin-bottom-15">
                    <div class="text--primary d-flex">
                      <v-col
                        v-if="
                          report.id !== User.reportid &&
                            report.id !== compReports.id1
                        "
                        cols="6"
                      >
                        <ul class="ul-no-li-style">
                          <li v-if="report.first_name">
                            <v-icon>mdi-account</v-icon>
                            <span>{{ report.first_name }}</span>
                          </li>
                          <li v-if="report.birthday">
                            <v-icon>mdi-calendar-today</v-icon>
                            <span>
                              {{ report.birthday }}
                            </span>
                          </li>
                          <li v-if="report.city">
                            <v-icon>mdi-city-variant</v-icon>
                            <span>{{ report.city.title }}</span>
                          </li>
                        </ul>
                      </v-col>
                      <v-col
                        v-if="
                          report.id !== User.reportid &&
                            report.id !== compReports.id1
                        "
                        cols="6"
                      >
                        <ul class="ul-no-li-style">
                          <li v-if="report.chart_type">
                            <v-icon>mdi-account-box-outline</v-icon>
                            <span>{{ report.chart_type }}</span>
                          </li>
                          <li v-if="report.chart_profile">
                            <v-icon>mdi-account-circle</v-icon>
                            <span>Профиль {{ report.chart_profile }}</span>
                          </li>
                        </ul>
                      </v-col>
                    </div>
                    <div
                      v-if="
                        report.id !== User.reportid &&
                          report.id !== compReports.id1
                      "
                      class="text--primary d-flex"
                    >
                      <v-col class="flex-center padding-top-0" cols="12">
                        <v-btn
                          @click="
                            getBodygraphByComposite(
                              'create',
                              compReports.id1,
                              report.id
                            )
                          "
                          class="my-prof-controls-flex"
                          color="primary"
                          fab
                          small
                          icon
                          dark
                        >
                          <v-icon dark>mdi-call-split</v-icon>
                          <span
                            class="d-flex"
                            style="font-size:11px;margin-top:5px"
                          >
                            Рейв-карта 2
                          </span>
                        </v-btn>
                      </v-col>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="swichToFullTooltipsUsage"
      :class="swichToFullTooltipsUsageColor"
      top
      style="margin-top:60px"
    >
      <div class="d-flex">
        <v-icon color="white">mdi-alert-circle-outline</v-icon>
        <div style="padding-left:7px">{{ swichToFullTooltipsUsageText }}</div>
      </div>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          @click="swichToFullTooltipsUsage = false"
          color="red"
          x-small
          text
        >
          <v-icon dark>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <progress-dialog :progress="progressdialog" />
    <TooltipDialog
      :showDialog.sync="showTooltipDialog"
      :obj.sync="objTooltipDialog"
      @close="closeTooltipDialog"
    />
    <MultiTooltipDialog
      :showDialog.sync="showMultiTooltipDialog"
      :arr.sync="arrMultiTooltipDialog"
      @close="closeMultiTooltipDialog"
    />
    <CompositeChannelsTooltipDialog
      :showDialog.sync="showCompositeChannelsTooltipDialog"
      @closeCompositeChannelsDialog="closeCompositeChannelsTooltipDialog"
    />
    <CompositeCentersTooltipDialog
      :showDialog.sync="showCompositeCentersTooltipDialog"
      @closeCompositeCentersTooltipDialog="closeCompositeCentersTooltipDialog"
    />
    <CompositesHistoryDialog
      :compositesHistoryDialogIncome.sync="compositeHistoryDialog"
      @closeCompositesDialog="closeCompositesDialog"
    />
  </v-card>
</template>

<script>
import Api from "@/api/api";
import { mapState, mapMutations } from "vuex";
import { commonInitAppMethods } from "@/mixins/common-init-app-methods";
import { commonReportingMethods } from "@/mixins/common-reporting-methods";
import { commonErrorsMethods } from "@/mixins/common-errors-methods";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import ProgressDialog from "@/components/loaders/progress-dialog";
import TooltipDialog from "@/components/dialogs/TooltipDialog.vue";
import MultiTooltipDialog from "@/components/dialogs/MultiTooltipDialog.vue";
import CompositeChannelsTooltipDialog from "@/components/dialogs/CompositeChannelsTooltipDialog";
import CompositeCentersTooltipDialog from "@/components/dialogs/CompositeCentersTooltipDialog";
import FullTooltipsSwitcher from "@/components/parts/FullTooltipsSwitcher.vue";
import CompositesHistoryDialog from "@/components/dialogs/CompositesHistoryDialog";

export default {
  components: {
    ProgressDialog,
    TooltipDialog,
    MultiTooltipDialog,
    CompositeChannelsTooltipDialog,
    CompositeCentersTooltipDialog,
    FullTooltipsSwitcher,
    CompositesHistoryDialog
  },
  mixins: [
    validationMixin,
    commonInitAppMethods,
    commonReportingMethods,
    commonErrorsMethods
  ],
  validations: {
    firstname: { required, maxLength: maxLength(35) },
    day: { required },
    month: { required },
    year: { required },
    hour: { required },
    minute: { required },
    city: { required }
  },
  data() {
    return {
      tabAddInfo: 0,
      firstname: "",
      isChartToggle: true,
      isChart2Toggle: true,
      chartcompositedialog: false,
      chart2compositedialog: false,
      progressdialog: false,
      isVisibleTooltipBtn: true,
      chartNum: 1,
      // chartBodygraphChart: null,
      // chartBodygraphChart2: null,
      // chartBodygraphConnection: [],
      // chartBodygraphChannels: {},
      // chartBodygraphLove: {},
      // chartBodygraphTypeComptb: {},
      // chartBodygraphCenters: {},
      // connectionGatesDefined: [],
      connectionCentersUndefined: [],
      loading: true,
      isLoading: false,
      isShowTDButton: false,
      compReports: {},
      city: null,
      search: null,
      searchResult: true,
      toolTips: {},
      gatesUndefinedCentres: [],
      day: { day: "15" },
      month: { month: "Июнь (6)", id: "06" },
      year: { year: "1990" },
      hour: { hour: "12" },
      minute: { minute: "30" },
      panel: [0],
      isStored: false,
      swichToFullTooltipsUsage: false,
      swichToFullTooltipsUsageText: "",
      swichToFullTooltipsUsageColor: "switch-on",
      showDialog: false,
      showLoginDialog: false,
      objTooltipDialog: {},
      showTooltipDialog: false,
      showCompositeChannelsTooltipDialog: false,
      showCompositeCentersTooltipDialog: false,
      arrMultiTooltipDialog: [],
      showMultiTooltipDialog: false,
      compositeHistoryDialog: false
    };
  },
  computed: {
    ...mapState(["dateTime"]),
    ...mapState({
      User: (state) => state.account.User,
      Profile: (state) => state.account.Profile,
      ConfApp: (state) => state.conf.ConfApp,
      Messages: (state) => state.conf.Messages,
      HttpOptions: (state) => state.conf.http_options,
      DarkTheme: (state) => state.conf.DarkTheme
    }),
    gexagrammColor() {
      return this.User.gexagrammColor;
    },
    days() {
      return this.dateTime.days;
    },
    months() {
      return this.dateTime.months;
    },
    years() {
      return this.cutAllYearsMoreCurrent(this.dateTime.years);
    },
    hours() {
      return this.dateTime.hours;
    },
    minutes() {
      return this.dateTime.minutes;
    },
    cities: {
      get() {
        return this.User.lastCities;
      },
      set(value) {
        this.$store.commit("account/update_last_cities", value);
      }
    },
    currentReportName: {
      get() {
        return this.User.name;
      },
      set(value) {
        this.$store.commit("account/update_first_name", value);
      }
    },
    currentReportBirthtime: {
      get() {
        const year = this.User.year.year;
        const month = this.User.month.id;
        const day = this.User.day.day;
        const hour = this.User.hour.hour;
        const minute = this.User.minute.minute;
        const birthtime =
          year + "-" + month + "-" + day + " " + hour + ":" + minute;
        return birthtime;
        // return this.User.chartBodygraphChart.birthtime
      },
      set(value) {
        this.$store.commit("account/UPDATE_CHART_BODYGRAPH_BIRTHTIME", value);
      }
    },
    currentReportCity() {
      this.getItemFromLocalStorage("city", "account/update_city");
      return this.User.city;
    },
    currentReportType() {
      let type = "";
      if (this.User.chartBodygraph) type = this.User.chartBodygraph.type.title;
      return type;
    },
    currentReportProfile() {
      let profile = "";
      if (this.User.chartBodygraph)
        profile = this.User.chartBodygraph.profile.title;
      return profile;
    },
    currentReportProfileId() {
      let profileId = "";
      if (this.User.chartBodygraph)
        profileId = this.User.chartBodygraph.profile.id;
      return profileId;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.firstname.$dirty) return errors;
      !this.$v.firstname.maxLength &&
        errors.push("Имя должно быть не более 35 символов.");
      !this.$v.firstname.required && errors.push("Введите имя.");
      return errors;
    },
    cityErrors() {
      const errors = [];
      if (!this.$v.city.$dirty) return errors;
      !this.$v.city.required && errors.push("Выберите город.");
      return errors;
    },
    RegGoTextBeforeBtn: {
      get() {
        return this.User.RegGoTextBeforeBtnComposite;
      },
      set(v) {
        this.$store.commit("account/update_reg_go_text_before_btn_2", v);
      }
    },
    btnTooltipName: {
      get() {
        return this.User.btnTooltipNameComposite;
      },
      set(v) {
        this.$store.commit("account/update_btn_tooltip_name_2", v);
      }
    },
    extTips() {
      return this.$store.state.conf.ConfApp.isBuyedProTooltips;
    },
    isReports() {
      if (
        this.User.isLogged &&
        // ((this.chartNum === 1 && this.User.reportid) ||
        (this.userProdReports.length ||
          this.careerUserProducts.length ||
          this.childUserProducts.length ||
          this.userProReports.length ||
          this.userReports.length)
      ) {
        return true;
      } else {
        return false;
      }
    },
    reportsHistoryCount() {
      return this.User.reportsHistory.totalCount;
    },
    isFullTooltipsUsageEnabled: {
      get() {
        return this.Profile.is_full_tooltips_usage_enabled;
      },
      set(bul) {
        this.$store.commit("account/update_is_full_tooltips_usage_enable", bul);
      }
    },
    fullTooltipsCount: {
      get() {
        return (
          this.Profile.full_tooltips_count +
          this.Profile.full_composite_tooltips_count
        );
      },
      set(v) {
        this.$store.commit("account/update_full_tooltips_count", v);
      }
    },
    chartBodygraphChart: {
      get() {
        return this.$store.state.account.Composite.chartBodygraphChart;
      },
      set(value) {
        this.$store.commit("account/set_chart_bodygraph_composite", value);
      }
    },
    chartBodygraphChart2: {
      get() {
        return this.$store.state.account.Composite.chartBodygraphChart2;
      },
      set(value) {
        this.$store.commit("account/set_chart_bodygraph_composite2", value);
      }
    },
    chartBodygraphConnection: {
      get() {
        return this.$store.state.account.Composite.chartBodygraphConnection;
      },
      set(value) {
        this.$store.commit(
          "account/set_chart_bodygraph_composite_connection",
          value
        );
      }
    },
    isLogged: {
      get() {
        return this.$store.state.account.User.isLogged;
      },
      set(bulean) {
        this.$store.commit("account/login_success", bulean);
      }
    },
    chartBodygraphChannels() {
      return this.$store.state.account.Composite.chartBodygraphConnection
        .channels;
    },
    chartBodygraphLove() {
      return this.$store.state.account.Composite.chartBodygraphConnection.love;
    },
    chartBodygraphCenters() {
      return this.$store.state.account.Composite.chartBodygraphConnection
        .centers;
    },
    chartBodygraphTypeComptb() {
      return this.$store.state.account.Composite.chartBodygraphConnection
        .type_compatibility;
    },
    chartBodygraphChartName() {
      return this.$store.state.account.Composite.chartBodygraphChartName;
    },
    chartBodygraphChartName2() {
      return this.$store.state.account.Composite.chartBodygraphChartName2;
    },
    connectionGatesDefined() {
      return this.$store.state.account.Composite.chartBodygraphConnection
        .gates3;
    },
    userReports() {
      return this.$store.state.account.Profile.reports || [];
    },
    careerUserProducts() {
      return this.$store.state.account.Profile.career_user_products || [];
    },
    childUserProducts() {
      return this.$store.state.account.Profile.child_user_products || [];
    },
    userProReports() {
      return this.$store.state.account.Profile.pro_reports || [];
    },
    userProdReports() {
      return this.$store.state.account.Profile.user_products || [];
    }
  },
  watch: {
    search(val) {
      if (!val) return;
      this.fetchCitiesDebounced(val);
    },
    isLogged(val) {
      if (!val) return;
      this.getProfile();
    }
  },
  beforeMount() {
    window.clickTooltipBtnCP = this.clickTooltipBtnCP;
    window.clearTooltips = this.clearTooltips;
    window.clearTooltipsCP = this.clearTooltips;
    window.offerTooltipsBuyCP = this.$offerTooltipsBuy;
    window.offerTooltipsReverseCP = this.$offerTooltipsReverse;
  },
  mounted() {
    this.$store.subscribe((mutation) => {
      switch (mutation.type) {
        case "UPDATE_CLEAR_TOOLTIPS":
          this.clearTooltips();
          break;
        case "GET_COMPOSITE_BY_ID":
          this.getBodygraphByComposite(
            this.$store.state.Composite.method,
            this.$store.state.Composite.report_id
          );
          break;
        case "CALL_UPDATE_PROFILE":
          this.getProfile();
          break;
      }
    });
    if (this.$store.state.Composite.report_id)
      this.getBodygraphByComposite(
        this.$store.state.Composite.method,
        this.$store.state.Composite.report_id
      );
    /* if (this.$store.state.ext_composite_hash)
      this.getCompositeByHash(this.$store.state.ext_composite_hash); */
    // this.initActiveGatesInUndefinedCentres()
    setTimeout(() => {
      this.getProfile();
    }, 0);
  },
  methods: {
    ...mapMutations({
      update_login_dialog: "conf/update_login_dialog"
    }),
    async getProfile() {
      if (this.isLogged) {
        this.$store.dispatch("account/getProfile");
      }
    },
    onDayChange() {
      this.$refs.monthSelect.activateMenu();
      this.$refs.monthSelect.focus();
    },
    onMonthChange() {
      this.$refs.yearSelect.activateMenu();
      this.$refs.yearSelect.focus();
    },
    onYearChange() {
      this.$refs.hourSelect.activateMenu();
      this.$refs.hourSelect.focus();
    },
    onHourChange() {
      this.$refs.minuteSelect.activateMenu();
      this.$refs.minuteSelect.focus();
    },
    onMinuteChange() {
      this.$refs.cityInput.focus();
    },
    onCityChange() {
      this.$refs.cityInput.blur();
      this.$refs.firstnameInput.focus();
    },
    validateNewReport(chartNum) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.getBodygraph(chartNum);
      }
    },
    chartCompositeReport() {
      this.selectUserReport();
    },
    selectUserReport(id, type) {
      if (!this.User.isLogged) {
        this.userReports = [];
        this.careerUserProducts = [];
        this.childUserProducts = [];
        this.userProReports = [];
        this.userProdReports = [];
        this.compReports = {};
        if (id) {
          this.compReports.id1 = id;
          if (type) this.compReports.type1 = type;
          this.chartcompositedialog = false;
          this.chart2compositedialog = true;
        } else {
          this.newReport(1);
          // this.chartcompositedialog = true
        }
      } else {
        if (this.isReports) {
          if (id) {
            this.compReports.id1 = id;
            if (type) this.compReports.type1 = type;
            this.chartcompositedialog = false;
            /* if (
                this.userReports.length ||
                this.userProReports.length ||
                this.userProdReports.length
              ) {
                this.chart2compositedialog = true
              } else {
                this.newReport(2)
              } */
            this.chartNum = 2;
            this.newReport(2);
          } else {
            this.chartNum === 1
              ? (this.chartcompositedialog = true)
              : (this.chart2compositedialog = true);
          }
        } else {
          this.chartNum = 1;
          this.newReport(1);
        }
      }
    },
    async getBodygraphByComposite(method, id, id2, type2) {
      try {
        this.progressdialog = true;
        this.chartcompositedialog = false;
        this.chart2compositedialog = false;
        this.chartNum = 1;
        const compositereport = { is_stored: this.isStored };
        if (method === "get" || method === "edit") {
          compositereport.report_id = id;
        } else if (method === "create") {
          if (this.compReports.type1 === "user") {
            compositereport.user_product_id = id;
          } else {
            compositereport.report_id = id;
          }
          if (type2 === "user") {
            compositereport.user_product_id2 = id2;
          } else {
            compositereport.report_id2 = id2;
          }
        }
        let successResult;
        method === "create"
          ? (successResult = await this.$store.dispatch(
              "account/createCompositeReport",
              compositereport
            ))
          : (successResult = await this.$store.dispatch(
              "account/getCompositeReportById",
              compositereport.report_id
            ));
        if (successResult) {
          this.compReports = {};
          this.progressdialog = false;
          this.loading = false;
          if (method === "edit") {
            this.chartCompositeReport();
          }
          // setTimeout(() => this.$callPanZoom('#svg1582_composite'), 0)
          this.isStored = false;
          this.scrollToTop();
        } else {
          this.progressdialog = false;
        }
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
      }
    },
    async getBodygraph(chartNum) {
      try {
        const birthday =
          this.year.year +
          "-" +
          this.month.id +
          "-" +
          this.day.day +
          " " +
          this.hour.hour +
          ":" +
          this.minute.minute;
        const reportParams = {
          birthday,
          city_id: this.city,
          first_name: this.firstname,
          email: this.User.email,
          fcr: 1
        };
        const result = await Api.createRaveReport(
          reportParams,
          this.HttpOptions
        );
        if (result.success) {
          if (chartNum === 1) {
            this.compReports.id1 = result.report.id;
            this.chartNum = 2;
            this.newReport(2);
            this.scrollToTop();
          } else {
            this.getBodygraphByComposite(
              "create",
              this.compReports.id1,
              result.report.id
            );
          }
        }
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
      }
    },
    // Events for init bodygraph
    initActiveGatesInUndefinedCentres() {
      this.gatesUndefinedCentres = [];
      this.connectionCentersUnDefined = [];
      if (this.chartBodygraphChart) {
        // Search activated gates in undefined centers
        this.connectionCentersUnDefined.forEach((center) => {
          this.$store.state.centerToGates[center].forEach((gate) => {
            Object.values(this.chartBodygraphChart.gates).forEach((acgates) => {
              if (+acgates === +gate) this.gatesUndefinedCentres.push(gate);
            });
          });
        });
        // console.log(this.gatesUndefinedCentres)
        // For mobile devices scroll down
        clearTimeout(this._searchTimerId);
        this._searchTimerId = setTimeout(() => {
          this.scrollToTop();
        }, 500); /* 500ms throttle */
      }
    },
    /* scrollToSvg() {
        document.getElementsByClassName('svg_bg')[0].scrollTo({
          left: document.getElementById('svg1582_composite').scrollWidth / 5,
          behavior: 'smooth'
        })
      }, */
    clickTooltipBtnCP(extTips) {
      switch (this.$store.state.conf.ConfApp.cp_tooltip_button_type) {
        case 1:
          this.update_login_dialog(true);
          break;
        case 2:
          this.$router.push("/profile");
          break;
        case 3:
          this.$router.push("/products");
          break;
        case 4: {
          const SHOP_URL4 = extTips
            ? this.$store.state.conf.ConfApp.tooltips_shop_url
            : this.$store.state.conf.ConfApp.tooltips_sale_shop_url;
          const url4 = SHOP_URL4 + "&tag=r" + this.User.reportid;
          window.open(url4, "_blank");
          break;
        }
        case 5: {
          const url5 =
            this.$store.state.conf.ConfApp.composite_shop_url +
            "&tag=r" +
            this.User.reportid;
          window.open(url5, "_blank");
          break;
        }
        case 6: {
          /* const SHOP_URL6 =
              extTips ||
              extTips === 'ext_tips' ||
              this.User.full_tooltips_count
                ? this.$store.state.ConfApp.tooltips_buy_full_url
                : this.$store.state.ConfApp.tooltips_shop_url */
          // Если приходит 6, то в композите предлагаются только расширенные
          const SHOP_URL6 = this.$store.state.conf.ConfApp
            .tooltips_buy_full_url;
          const url6 = SHOP_URL6 + "&tag=r" + this.User.reportid;
          window.open(url6, "_blank");
          break;
        }
        case 7:
          // FULL-подсказки выключены, предложение включить
          this.$router.push("/profile");
          break;
        default:
          this.$store.commit(
            "account/set_partner_info_text",
            this.getMessage("partners")
          );
          this.$store.commit("account/call_partner_dialog");
          break;
      }
      /* console.log('extTips === ext_tips: ', extTips === 'ext_tips')
        console.log('extTips: ', extTips)
        console.log(
          'full_tooltips_count: ',
          this.User.full_tooltips_count
        )
        console.log(this.$store.state.ConfApp.cp_tooltip_button_type) */
    },
    fetchCitiesDebounced(val) {
      this.search = this.searchRequestProcessing(val);
      this.isLoading = true;
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        this.fetchCities(this.search);
        this.isLoading = false;
      }, 500); /* 500ms throttle */
    },
    async fetchCities(searchrequest) {
      if (searchrequest) {
        try {
          const result = await Api.searchCity(searchrequest, this.HttpOptions);
          if (result.success) {
            this.cities = result.cities;
            this.citiesMemoization(result.cities);
            if (!this.cities.length) {
              this.searchResult = false;
            }
          }
        } catch (e) {
          this.$store.dispatch("errorHandler", e);
        }
      }
    },
    // Defined Context Gates Points
    isDefinedTogglePointGate1(num) {
      if (
        this.isDefinedObj(
          this.chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
          num
        ) &&
        this.isChartToggle &&
        this.isChart2Toggle
      ) {
        return false;
      } else if (
        this.isDefinedObj(
          this.chartBodygraphChart2.gatesActivatedAtPersonalityAndDesign,
          num
        ) &&
        !this.isChartToggle
      ) {
        return true;
      } else if (
        this.isDefinedObj(
          this.chartBodygraphChart2.gatesActivatedAtDesignOnly,
          num
        ) &&
        !this.isChartToggle
      ) {
        return true;
      } else if (
        this.isDefinedObj(
          this.chartBodygraphChart2.gatesActivatedAtPersonalityOnly,
          num
        ) &&
        !this.isChartToggle
      ) {
        return true;
      }
    },
    isDefinedTogglePointGate2(num) {
      if (
        this.isDefinedObj(
          this.chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
          num
        ) &&
        this.isChartToggle &&
        this.isChart2Toggle
      ) {
        return false;
      } else if (
        this.isDefinedObj(
          this.chartBodygraphChart.gatesActivatedAtPersonalityAndDesign,
          num
        ) &&
        !this.isChart2Toggle
      ) {
        return true;
      } else if (
        this.isDefinedObj(
          this.chartBodygraphChart.gatesActivatedAtDesignOnly,
          num
        ) &&
        !this.isChart2Toggle
      ) {
        return true;
      } else if (
        this.isDefinedObj(
          this.chartBodygraphChart.gatesActivatedAtPersonalityOnly,
          num
        ) &&
        !this.isChart2Toggle
      ) {
        return true;
      }
    },
    // Search activated gates in hovered center
    isGatesActiveForCenter(center) {
      if (this.chartBodygraphChart) {
        this.$store.state.account.centerToGates[center].forEach((gate) => {
          Object.values(this.chartBodygraphChart.gates).forEach((acgates) => {
            if (+acgates === +gate) {
              // this.$store.commit('PUSH_TO_GATE_LINES_CENTER_HOVER', gate)
              this.searchAcPlanets(gate); // Call to activated gates in planet context
            }
          });
          Object.values(this.chartBodygraphChart2.gates).forEach((acgates) => {
            if (+acgates === +gate) {
              // this.$store.commit('PUSH_TO_GATE_LINES_CENTER_HOVER', gate)
              this.searchAcPlanets(gate); // Call to activated gates in planet context
            }
          });
        });
        // console.log(this.gateLinesCenterHover)
      }
    },
    // Search activated planets for gate
    // Key is gate number
    // versum = 1 is Person; versum = 2 is Design; versum = 3 is Both;
    searchAcPlanets(key, key2, key3, key4) {
      if (key in this.chartBodygraphChart.gatesExtended) {
        if (this.chartBodygraphChart.gatesExtended[key].versum === 1) {
          this.chartBodygraphChart.gatesExtended[key].planets.forEach(
            (planet) => {
              this.$store.commit("account/set_is_planet_per", {
                planet,
                num: 1
              });
            }
          );
        } else if (this.chartBodygraphChart.gatesExtended[key].versum === 2) {
          this.chartBodygraphChart.gatesExtended[key].planets.forEach(
            (planet) => {
              this.$store.commit("account/set_is_planet_des", {
                planet,
                num: 1
              });
            }
          );
        } else {
          this.chartBodygraphChart.gatesExtended[key].planets.forEach(
            (planet) => {
              if (
                +key === +this.chartBodygraphChart.mandalas[0][planet].gate &&
                +key === +this.chartBodygraphChart.mandalas[1][planet].gate
              ) {
                this.$store.commit("account/set_is_planet_per", {
                  planet,
                  num: 1
                });
                this.$store.commit("account/set_is_planet_des", {
                  planet,
                  num: 1
                });
              } else if (
                +key === +this.chartBodygraphChart.mandalas[0][planet].gate
              ) {
                this.$store.commit("account/set_is_planet_per", {
                  planet,
                  num: 1
                });
              } else if (
                +key === +this.chartBodygraphChart.mandalas[1][planet].gate
              ) {
                this.$store.commit("account/set_is_planet_des", {
                  planet,
                  num: 1
                });
              } else {
                // console.log('Data Error! Check chartBodygraphChart')
              }
            }
          );
        }
      } else {
        // console.log('Property is undefined')
      }
      if (key2 in this.chartBodygraphChart.gatesExtended) {
        if (this.chartBodygraphChart.gatesExtended[key2].versum === 1) {
          this.chartBodygraphChart.gatesExtended[key2].planets.forEach(
            (planet) => {
              this.$store.commit("account/set_is_planet_per", {
                planet,
                num: 1
              });
            }
          );
        } else if (this.chartBodygraphChart.gatesExtended[key2].versum === 2) {
          this.chartBodygraphChart.gatesExtended[key2].planets.forEach(
            (planet) => {
              this.$store.commit("account/set_is_planet_des", {
                planet,
                num: 1
              });
            }
          );
        } else {
          this.chartBodygraphChart.gatesExtended[key2].planets.forEach(
            (planet) => {
              if (
                +key2 === +this.chartBodygraphChart.mandalas[0][planet].gate &&
                +key2 === +this.chartBodygraphChart.mandalas[1][planet].gate
              ) {
                this.$store.commit("account/set_is_planet_per", {
                  planet,
                  num: 1
                });
                this.$store.commit("account/set_is_planet_des", {
                  planet,
                  num: 1
                });
              } else if (
                +key2 === +this.chartBodygraphChart.mandalas[0][planet].gate
              ) {
                this.$store.commit("account/set_is_planet_per", {
                  planet,
                  num: 1
                });
              } else if (
                +key2 === +this.chartBodygraphChart.mandalas[1][planet].gate
              ) {
                this.$store.commit("account/set_is_planet_des", {
                  planet,
                  num: 1
                });
              } else {
                // console.log('Data Error! Check chartBodygraphChart')
              }
            }
          );
        }
      } else {
        // console.log('Property is undefined')
      }
      if (key3 in this.chartBodygraphChart.gatesExtended) {
        if (this.chartBodygraphChart.gatesExtended[key3].versum === 1) {
          this.chartBodygraphChart.gatesExtended[key3].planets.forEach(
            (planet) => {
              this.$store.commit("account/set_is_planet_per", {
                planet,
                num: 1
              });
            }
          );
        } else if (this.chartBodygraphChart.gatesExtended[key3].versum === 2) {
          this.chartBodygraphChart.gatesExtended[key3].planets.forEach(
            (planet) => {
              this.$store.commit("account/set_is_planet_des", {
                planet,
                num: 1
              });
            }
          );
        } else {
          this.chartBodygraphChart.gatesExtended[key3].planets.forEach(
            (planet) => {
              if (
                +key3 === +this.chartBodygraphChart.mandalas[0][planet].gate &&
                +key3 === +this.chartBodygraphChart.mandalas[1][planet].gate
              ) {
                this.$store.commit("account/set_is_planet_per", {
                  planet,
                  num: 1
                });
                this.$store.commit("account/set_is_planet_des", {
                  planet,
                  num: 1
                });
              } else if (
                +key3 === +this.chartBodygraphChart.mandalas[0][planet].gate
              ) {
                this.$store.commit("account/set_is_planet_per", {
                  planet,
                  num: 1
                });
              } else if (
                +key3 === +this.chartBodygraphChart.mandalas[1][planet].gate
              ) {
                this.$store.commit("account/set_is_planet_des", {
                  planet,
                  num: 1
                });
              } else {
                // console.log('Data Error! Check chartBodygraphChart')
              }
            }
          );
        }
      } else {
        // console.log('Property is undefined')
      }
      if (key4 in this.chartBodygraphChart.gatesExtended) {
        if (this.chartBodygraphChart.gatesExtended[key4].versum === 1) {
          this.chartBodygraphChart.gatesExtended[key4].planets.forEach(
            (planet) => {
              this.$store.commit("account/set_is_planet_per", {
                planet,
                num: 1
              });
            }
          );
        } else if (this.chartBodygraphChart.gatesExtended[key4].versum === 2) {
          this.chartBodygraphChart.gatesExtended[key4].planets.forEach(
            (planet) => {
              this.$store.commit("account/set_is_planet_des", {
                planet,
                num: 1
              });
            }
          );
        } else {
          this.chartBodygraphChart.gatesExtended[key4].planets.forEach(
            (planet) => {
              if (
                +key4 === +this.chartBodygraphChart.mandalas[0][planet].gate &&
                +key4 === +this.chartBodygraphChart.mandalas[1][planet].gate
              ) {
                this.$store.commit("account/set_is_planet_per", {
                  planet,
                  num: 1
                });
                this.$store.commit("account/set_is_planet_des", {
                  planet,
                  num: 1
                });
              } else if (
                +key4 === +this.chartBodygraphChart.mandalas[0][planet].gate
              ) {
                this.$store.commit("account/set_is_planet_per", {
                  planet,
                  num: 1
                });
              } else if (
                +key4 === +this.chartBodygraphChart.mandalas[1][planet].gate
              ) {
                this.$store.commit("account/set_is_planet_des", {
                  planet,
                  num: 1
                });
              } else {
                // console.log('Data Error! Check chartBodygraphChart')
              }
            }
          );
        }
      } else {
        // console.log('Property is undefined')
      }
      if (key in this.chartBodygraphChart2.gatesExtended) {
        if (this.chartBodygraphChart2.gatesExtended[key].versum === 1) {
          this.chartBodygraphChart2.gatesExtended[key].planets.forEach(
            (planet) => {
              this.$store.commit("account/set_is_planet_per2", {
                planet,
                num: 1
              });
            }
          );
        } else if (this.chartBodygraphChart2.gatesExtended[key].versum === 2) {
          this.chartBodygraphChart2.gatesExtended[key].planets.forEach(
            (planet) => {
              this.$store.commit("account/set_is_planet_des2", {
                planet,
                num: 1
              });
            }
          );
        } else {
          this.chartBodygraphChart2.gatesExtended[key].planets.forEach(
            (planet) => {
              if (
                +key === +this.chartBodygraphChart2.mandalas[0][planet].gate &&
                +key === +this.chartBodygraphChart2.mandalas[1][planet].gate
              ) {
                this.$store.commit("account/set_is_planet_per2", {
                  planet,
                  num: 1
                });
                this.$store.commit("account/set_is_planet_des2", {
                  planet,
                  num: 1
                });
              } else if (
                +key === +this.chartBodygraphChart2.mandalas[0][planet].gate
              ) {
                this.$store.commit("account/set_is_planet_per2", {
                  planet,
                  num: 1
                });
              } else if (
                +key === +this.chartBodygraphChart2.mandalas[1][planet].gate
              ) {
                this.$store.commit("account/set_is_planet_des2", {
                  planet,
                  num: 1
                });
              } else {
                // console.log('Data Error! Check chartBodygraphChart2')
              }
            }
          );
        }
      } else {
        // console.log('Property is undefined')
      }
      if (key2 in this.chartBodygraphChart2.gatesExtended) {
        if (this.chartBodygraphChart2.gatesExtended[key2].versum === 1) {
          this.chartBodygraphChart2.gatesExtended[key2].planets.forEach(
            (planet) => {
              this.$store.commit("account/set_is_planet_per2", {
                planet,
                num: 1
              });
            }
          );
        } else if (this.chartBodygraphChart2.gatesExtended[key2].versum === 2) {
          this.chartBodygraphChart2.gatesExtended[key2].planets.forEach(
            (planet) => {
              this.$store.commit("account/set_is_planet_des2", {
                planet,
                num: 1
              });
            }
          );
        } else {
          this.chartBodygraphChart2.gatesExtended[key2].planets.forEach(
            (planet) => {
              if (
                +key2 === +this.chartBodygraphChart2.mandalas[0][planet].gate &&
                +key2 === +this.chartBodygraphChart2.mandalas[1][planet].gate
              ) {
                this.$store.commit("account/set_is_planet_per2", {
                  planet,
                  num: 1
                });
                this.$store.commit("account/set_is_planet_des2", {
                  planet,
                  num: 1
                });
              } else if (
                +key2 === +this.chartBodygraphChart2.mandalas[0][planet].gate
              ) {
                this.$store.commit("account/set_is_planet_per2", {
                  planet,
                  num: 1
                });
              } else if (
                +key2 === +this.chartBodygraphChart2.mandalas[1][planet].gate
              ) {
                this.$store.commit("account/set_is_planet_des2", {
                  planet,
                  num: 1
                });
              } else {
                // console.log('Data Error! Check chartBodygraphChart2')
              }
            }
          );
        }
      } else {
        // console.log('Property is undefined')
      }
      if (key3 in this.chartBodygraphChart2.gatesExtended) {
        if (this.chartBodygraphChart2.gatesExtended[key3].versum === 1) {
          this.chartBodygraphChart2.gatesExtended[key3].planets.forEach(
            (planet) => {
              this.$store.commit("account/set_is_planet_per2", {
                planet,
                num: 1
              });
            }
          );
        } else if (this.chartBodygraphChart2.gatesExtended[key3].versum === 2) {
          this.chartBodygraphChart2.gatesExtended[key3].planets.forEach(
            (planet) => {
              this.$store.commit("account/set_is_planet_des2", {
                planet,
                num: 1
              });
            }
          );
        } else {
          this.chartBodygraphChart2.gatesExtended[key3].planets.forEach(
            (planet) => {
              if (
                +key3 === +this.chartBodygraphChart2.mandalas[0][planet].gate &&
                +key3 === +this.chartBodygraphChart2.mandalas[1][planet].gate
              ) {
                this.$store.commit("account/set_is_planet_per2", {
                  planet,
                  num: 1
                });
                this.$store.commit("account/set_is_planet_des2", {
                  planet,
                  num: 1
                });
              } else if (
                +key3 === +this.chartBodygraphChart2.mandalas[0][planet].gate
              ) {
                this.$store.commit("account/set_is_planet_per2", {
                  planet,
                  num: 1
                });
              } else if (
                +key3 === +this.chartBodygraphChart2.mandalas[1][planet].gate
              ) {
                this.$store.commit("account/set_is_planet_des2", {
                  planet,
                  num: 1
                });
              } else {
                // console.log('Data Error! Check chartBodygraphChart2')
              }
            }
          );
        }
      } else {
        // console.log('Property is undefined')
      }
      if (key4 in this.chartBodygraphChart2.gatesExtended) {
        if (this.chartBodygraphChart2.gatesExtended[key4].versum === 1) {
          this.chartBodygraphChart2.gatesExtended[key4].planets.forEach(
            (planet) => {
              this.$store.commit("account/set_is_planet_per2", {
                planet,
                num: 1
              });
            }
          );
        } else if (this.chartBodygraphChart2.gatesExtended[key4].versum === 2) {
          this.chartBodygraphChart2.gatesExtended[key4].planets.forEach(
            (planet) => {
              this.$store.commit("account/set_is_planet_des2", {
                planet,
                num: 1
              });
            }
          );
        } else {
          this.chartBodygraphChart2.gatesExtended[key4].planets.forEach(
            (planet) => {
              if (
                +key4 === +this.chartBodygraphChart2.mandalas[0][planet].gate &&
                +key4 === +this.chartBodygraphChart2.mandalas[1][planet].gate
              ) {
                this.$store.commit("account/set_is_planet_per2", {
                  planet,
                  num: 1
                });
                this.$store.commit("account/set_is_planet_des2", {
                  planet,
                  num: 1
                });
              } else if (
                +key4 === +this.chartBodygraphChart2.mandalas[0][planet].gate
              ) {
                this.$store.commit("account/set_is_planet_per2", {
                  planet,
                  num: 1
                });
              } else if (
                +key4 === +this.chartBodygraphChart2.mandalas[1][planet].gate
              ) {
                this.$store.commit("account/set_is_planet_des2", {
                  planet,
                  num: 1
                });
              } else {
                // console.log('Data Error! Check chartBodygraphChart2')
              }
            }
          );
        }
      } else {
        // console.log('Property is undefined')
      }
    },
    // Search The All Same Gates
    searchAllAcPlanets(num) {
      this.searchAcPlanets(num);
      this.searchGateAcInCenter(num);
    },
    // Search & Return Activated Gate (planet hover) in Center
    searchGateAcInCenter(num) {
      if (this.chartBodygraphChart) {
        for (const center in this.$store.state.account.centerToGates) {
          this.$store.state.account.centerToGates[center].forEach((gate) => {
            if (+num === +gate) {
              this.$store.commit("account/push_to_gate_in_center_hover", gate);
              // console.log(gate, center, this.$store.state.account.gateInCenterHover)
            }
          });
        }
      }
      if (this.chartBodygraphChart2) {
        for (const center in this.$store.state.account.centerToGates) {
          this.$store.state.account.centerToGates[center].forEach((gate) => {
            if (+num === +gate) {
              this.$store.commit("account/push_to_gate_in_center_hover", gate);
            }
          });
        }
      }
    },
    newReport(num) {
      if (num) this.chartNum = num;
      if (this.chartBodygraphChart) this.chartBodygraphChart = null;
      if (this.chartBodygraphChart2) this.chartBodygraphChart2 = null;
      this.day = { day: "15" };
      this.month = { month: "Июнь (6)", id: "06" };
      this.year = { year: "1990" };
      this.hour = { hour: "12" };
      this.minute = { minute: "30" };
      this.firstname = "";
      this.city = "";
      this.chartcompositedialog = false;
      this.chart2compositedialog = false;
      this.$store.commit("account/update_composite_report_id", null);
      this.$store.commit("account/update_composite_report_hash", null);
    },
    showChart() {
      this.clearTooltips();
      this.isChartToggle = !this.isChartToggle;
    },
    showChart2() {
      this.clearTooltips();
      this.isChart2Toggle = !this.isChart2Toggle;
    },
    delayedСlearAcPlanets() {
      if (this.$device.isIos) {
        setTimeout(() => {
          this.clearAcPlanets();
        }, 500);
      } else {
        this.clearAcPlanets();
      }
    },
    async changeTooltipsUsage(v) {
      try {
        const data = { is_full_tooltips_usage_enabled: v };
        const result = await this.$setNotifications.post(data);
        if (result.success) {
          // console.log(result)
          this.swichToFullTooltipsUsage = true;
          if (result.is_full_tooltips_usage_enabled) {
            this.swichToFullTooltipsUsageText =
              "Расчеты будут созданы с расширенными подсказками.";
            this.swichToFullTooltipsUsageColor = "switch-on";
          } else {
            this.swichToFullTooltipsUsageText =
              "Вы отключили расширенные подсказки. Теперь для новых расчетов не будут списываться балы.";
            this.swichToFullTooltipsUsageColor = "switch-off";
          }
        }
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
      }
    },
    getSettings() {
      if (Object.keys(this.$store.state.conf.Messages).length === 0) {
        this.$store.dispatch("conf/getSettings");
      }
    },
    openTooltipDialog(obj) {
      this.objTooltipDialog = obj;
      this.showTooltipDialog = true;
    },
    openCompositeChannelsTooltipDialog() {
      this.showCompositeChannelsTooltipDialog = true;
    },
    closeCompositeChannelsTooltipDialog() {
      this.showCompositeChannelsTooltipDialog = false;
    },
    openCompositeCentersTooltipDialog() {
      this.showCompositeCentersTooltipDialog = true;
    },
    closeCompositeCentersTooltipDialog() {
      this.showCompositeCentersTooltipDialog = false;
    },
    convertToTooltipDialogObj(title, description) {
      return { title, description };
    },
    openColorTooltipDialog(key, prop) {
      if (prop in this.User.chartBodygraph[key]) {
        const obj = {
          title: this.User.chartBodygraph[key][prop].title,
          description: this.User.chartBodygraph[key][prop].tooltip
        };
        this.objTooltipDialog = obj;
        this.showTooltipDialog = true;
      }
    },
    openMultiTooltipDialog(arr) {
      this.arrMultiTooltipDialog = arr;
      this.showMultiTooltipDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
    },
    closeTooltipDialog() {
      this.showTooltipDialog = false;
    },
    closeMultiTooltipDialog() {
      this.showMultiTooltipDialog = false;
    },
    closeCompositesDialog() {
      this.compositeHistoryDialog = false;
    },
    informationDialog() {
      this.$store.commit("conf/update_service_close_modal", {
        message: this.getMessage("explanation_composite")
      });
    },
    toSearchItemAtArrInObj(obj, q) {
      let foundItem;
      for (const key in obj) {
        obj[key].forEach((item) => {
          if (item.id.includes(q)) {
            foundItem = item;
          }
        });
      }
      return foundItem;
    },
    toSearchItemAtArrInObj2(obj, q) {
      let foundItem;
      for (const key in obj) {
        obj[key].forEach((item) => {
          if (item.id.includes(q)) {
            foundItem = item;
            foundItem.id = q; // для красивого заголовка канала в табах типа '10-20'
          }
        });
      }
      return foundItem;
    },
    isChannel(obj, q) {
      let isChannel = false;
      for (const key in obj) {
        obj[key].forEach((item) => {
          if (item.id.includes(q)) {
            isChannel = true;
          }
        });
      }
      return isChannel;
    }
  }
};
</script>

<style scoped>
.svgbg-dark {
  fill: #303030;
}
.card-md {
  max-width: 1024px !important;
  /* min-height: 712.5px; */
  background-color: #f8f8f8 !important;
}
.headline {
  font-size: 1.3rem;
}
.link {
  cursor: pointer;
}
.desc-tooltip {
  position: absolute;
  cursor: pointer;
  z-index: 3;
  margin-left: 180px;
}
@media screen and (min-width: 320px) and (max-width: 420px) {
  .desc-tooltip {
    margin-left: 0;
  }
  .desc-tooltip-transit {
    left: 285px;
  }
  .mob-margin-left-0 {
    margin-left: 0 !important;
  }
}
.desc-tooltip i {
  color: #85a3b1 !important;
}
.transit-header {
  margin-top: 14px;
  margin-bottom: 5px;
}
.transit-button {
  display: flex;
}
.header-name,
.header-name2 {
  margin-top: 24px;
  padding-left: 7px;
  padding-bottom: 0 !important;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 400;
}
.header-name2 {
  margin-top: 7px;
}
.theme--dark .header-name,
.theme--dark .header-desc {
  color: #378fb5 !important;
}
.header-desc {
  padding-left: 7px;
  line-height: 13px;
  font-size: 1.03rem;
  margin-bottom: 14px !important;
}
.rave-profile-cont {
  height: 85%;
}
.bodygraph-desc-sup {
  position: absolute;
  z-index: 1;
  width: 342px;
  min-height: 615px;
}
.bodygraph-desc-sub {
  position: absolute;
  z-index: 2;
}
.bodygraph-desc h2 {
  padding-bottom: 7px;
}
.bodygraph-desc h3 {
  font-family: "Roboto", sans-serif !important;
  padding: 4px 7px 4px 7px;
  font-size: 17px;
  font-weight: 400;
  color: #85a3b1;
}
.bodygraph-desc h3 span {
  color: #378fb5;
  display: block;
  font-size: 18px;
  line-height: 22px;
}
/* Bottom toolbar bodygraph */
.none {
  display: none;
}
/* .theme--dark .bodygraph-desc h3 span {
    color: #00aeff;
  } */
.theme--dark .v-tabs-slider-wrapper {
  color: #177a9b;
}
.theme--dark .v-card__title {
  color: #177a99;
}
/* .theme--dark .v-card {
  background-color: #0b1f2c !important;
} */
.theme--dark .bodygraph-desc h3 span.bodygraph-profile3 {
  color: #4caf50;
}
/* .bodygraph-desc h3 span.bodygraph-inline {
    display: inline-block;
  } */
.bodygraph-desc h4 {
  padding: 2px 7px;
}
.bodygraph-profiles {
  font-size: 24px;
  line-height: 24px;
}
.bodygraph-desc h3 span.bodygraph-profile1 {
  color: #00ba88;
  display: inline-block;
}
.bodygraph-desc h3 span.bodygraph-profile3 {
  color: #4caf50;
  display: inline-block;
}
.bodygraph-profile-num {
  font-size: 22px !important;
}
.bodygraph-desc h3 span.bodygraph-mainquarter {
  color: #00ba88;
  display: inline-block;
}
.bodygraph-desc i.v-icon {
  font-size: 16px;
}
/* Animated opacity */
.opacity1,
.opacity5 {
  display: block !important;
  opacity: 1 !important;
  animation: show 1.5s 1;
}
.opacity2 {
  opacity: 1 !important;
  animation: show 0.5s 1;
}
.opacity3 {
  display: block !important;
  /* opacity: 0.6 !important; */
  opacity: 0 !important;
  animation: show 1s 1;
}
.opacity4 {
  display: block !important;
  visibility: hidden !important;
  opacity: 0 !important;
  transition: visibility 0s 0.2s, opacity 0.2s linear;
}
.design_color {
  fill: #ff001d !important;
}
.person_color {
  fill: #3e4448 !important;
}
.gates-center-hover {
  fill: #c443ae !important;
}
.gates {
  /* stroke: #986423; */
  stroke-width: 3 !important;
  stroke-opacity: 0;
  transition: stroke-opacity 0.7s;
}
.gates:hover {
  stroke-opacity: 1;
}
.des_toggle {
  stroke: #ff001d;
}
.des_toggle_cir {
  fill: #ff001d;
}
.theme--dark .des_toggle {
  stroke: #f80574;
}
.theme--dark .des_toggle_cir {
  fill: #f80574;
}
.des_toggle_quest {
  stroke: #85a3b1;
  fill: transparent;
}
.des_quest_quest {
  color: #85a3b1;
  fill: #85a3b1;
}
.per_toggle_quest {
  stroke: #85a3b1;
  fill: transparent;
}
.per_quest_quest {
  color: #85a3b1;
  fill: #85a3b1;
}
.per_toggle {
  stroke: #3e4448;
}
.per_toggle_cir {
  fill: #3e4448;
}
.theme--dark .per_toggle {
  stroke: #00aeff;
}
.theme--dark .per_toggle_cir {
  fill: #00aeff;
}
.not_active_des_toggle,
.not_active_per_toggle {
  stroke-opacity: 0.37;
  transition: stroke-opacity 0.25s;
}
.not_active_des {
  opacity: 0.37;
  cx: 75 !important;
  transition: opacity 0.25s;
}
.not_active_per {
  opacity: 0.37;
  cx: 796.5 !important;
  transition: opacity 0.25s;
}
.both-arrow {
  display: none;
}
.help-flex-tb {
  display: flex;
  max-height: 30px;
  margin: 20px 0 10px 0;
}
.report-history-btn {
  margin: 30px 0 10px 0;
}
.full-tooltips-switcher {
  display: flex;
  height: 30px;
  margin: 15px 0 35px 0;
}
@media screen and (min-width: 320px) and (max-width: 450px) {
  .help-flex-tb {
    margin: 10px 0 25px;
  }
  .help-flex-tb span {
    font-size: 0.9rem;
    line-height: 1.2rem;
  }
  .report-history-btn {
    margin: 10px 0 10px 0;
  }
  .full-tooltips-switcher {
    margin: 15px 0 30px 7px;
  }
  .both-arrow {
    display: block;
    position: absolute;
    width: 24px;
    margin-left: -12px;
    left: 50%;
  }
  /* .svg_bg {
      overflow-x: auto;
      overflow-y: hidden;
    }
    .svg_bg svg {
      min-width: 600px;
    } */
  .bodygraph-desc-sup {
    min-height: 560px;
  }
  .card-md {
    /* min-height: 890px; */
  }
}
@media screen and (max-width: 420px) {
  .bodygraph-sm {
    max-width: 319px;
  }
  .theme--light .col-xs {
    flex-basis: auto;
    background-color: #f8f8f8;
  }
  .theme--dark .col-xs {
    flex-basis: auto;
    background-color: #102c3e;
  }
  .card-md {
    /* min-height: 890px; */
  }
}
@media screen and (min-width: 768px) {
  .card-md {
    min-width: 744px;
  }
  .theme--light .col-xs {
    background-color: #f8f8f8;
  }
  .theme--dark .col-xs {
    background-color: #102c3e;
  }
}
@media screen and (max-width: 768px) {
  .transit-header {
    /* display: flex; */
    margin: 0 0 15px;
  }
  .transit-button {
    align-items: center;
    justify-content: center;
  }
  .header-name {
    margin-top: 0;
  }
  .bodygraph-desc {
    padding: 0 16px !important;
  }
  .svg_bg {
    padding: 0 !important;
  }
}
@media screen and (min-width: 1024px) {
  .card-md {
    min-width: 876px;
  }
  .nopadding {
    padding: 0 !important;
  }
}
@media screen and (min-width: 1100px) {
  .card-md {
    min-width: 965px;
  }
  .container {
    max-width: 995px;
  }
}
.svg_bg_composite {
  padding: 0 120px !important;
}
@media screen and (min-width: 320px) and (max-width: 450px) {
  .svg_bg_composite {
    padding: 0 !important;
  }
}
.col-bg-chart2 {
  fill: #fb8c00;
}
.col-bg-person {
  fill: rgb(62, 68, 72);
}
.col-bg-des {
  fill: rgb(255, 0, 29);
}
.col-strk-person {
  stroke: #fb8c00;
}
.theme--dark .col-strk-person {
  stroke: #00aeff !important;
}
.col-bg-chart {
  fill: #4caf50;
}
.col-strk-chart {
  stroke: #4caf50;
}
.col-strk-chart1 {
  stroke: #ff001d;
}
.theme--dark .v-messages__message,
.theme--dark .v-counter,
.theme--dark .v-label,
.v-tab.v-tab {
  color: #177a99 !important;
}
.theme--dark.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: #85a3b1 !important;
}
.theme--dark .v-input:not(.v-input--is-disabled) input,
.theme--dark .v-select .v-select__selections {
  color: #85a3b1 !important;
}
.theme--dark.mdi-menu-down::before {
  color: #628b9e !important;
}
.theme--dark .col-bg-chart2 {
  fill: #fb8c00;
}
.theme--dark .col-bg-chart {
  fill: #4caf50;
}
.theme--dark .col-bg-person {
  fill: #00aeff;
}
.theme--dark .col-bg-des {
  fill: #f80574;
}
.theme--dark .col-strk-person {
  stroke: #fb8c00;
}
.theme--dark .col-strk-chart {
  stroke: #4caf50;
}
.theme--dark .svg_bg,
.theme--dark .card-md,
.theme--dark .card-md-small {
  background-color: #102c3e !important;
}
.theme--dark .planet-chart1 {
  fill: #4caf50;
  stroke: #4caf50;
}
.svg_bg {
  background-color: #f8f8f8;
}
.theme--dark #rect1853 {
  fill: #102c3e;
  background: radial-gradient(#01506b, #102c3e);
}
#rect1853 {
  fill: rgb(248, 248, 248);
}
#g8332,
#g8416 {
  opacity: 0;
  transition: 1s;
  animation: show 1s 1;
  animation-fill-mode: forwards;
  animation-delay: 1s;
}
/* Planets & number rect */
.planet-per,
.planet-des {
  cursor: pointer;
  fill: rgb(62, 68, 72);
  stroke: rgb(62, 68, 72);
  stroke-width: 2;
  stroke-opacity: 0;
  transition: stroke-opacity 0.7s;
}
.theme--dark .planet-per {
  fill: #00aeff;
  stroke: #00aeff;
}
.planet-des {
  fill: rgb(255, 0, 29);
  stroke: rgb(255, 0, 29);
}
.planet-number-per,
.planet-number-des {
  cursor: pointer;
  width: 48px;
  height: 29px;
  stroke: #3e4448;
  fill: transparent;
  stroke-width: 2;
  opacity: 0;
}
.theme--dark .planet-number-per {
  stroke: #00aeff;
}
.planet-number-des {
  stroke: rgb(255, 0, 29);
}
.theme--dark .planet-number-des {
  stroke: #f80574;
}
.theme--dark .planet-des {
  fill: #f80574;
  stroke: #f80574;
}
.planet-substrate-rect {
  width: 30px;
  height: 30px;
  fill: transparent;
  stroke: transparent;
}
.design-rect {
  cursor: pointer;
  width: 65px;
  height: 33px;
  fill: transparent;
  stroke: transparent;
}
.design-rect_quest {
  cursor: pointer;
  width: 20px;
  height: 20px;
  fill: transparent;
  stroke: transparent;
}
.planet-chart1,
.planet-chart2 {
  cursor: pointer;
  fill: #fb8c00;
  stroke: #fb8c00;
  stroke-width: 2;
  stroke-opacity: 0;
  transition: stroke-opacity 0.7s;
}
.planet-chart1 {
  fill: #4caf50;
  stroke: #4caf50;
  text-transform: uppercase;
}
.planet-substrate-rect {
  width: 30px;
  height: 30px;
  fill: transparent;
  stroke: transparent;
}
@media screen and (min-width: 320px) and (max-width: 450px) {
  .add-info-block-head-desc p {
    padding: 0 !important;
  }
  .sun-chart-comp {
    width: 50px;
    height: 50px;
    x: 45;
    y: 60;
  }
  .sun-num-chart {
    height: 40px !important;
    y: 65;
  }
  .earth-chart-comp {
    width: 50px;
    height: 50px;
    x: 45;
    y: 111;
  }
  .earth-num-chart {
    height: 40px !important;
    y: 116;
  }
  .moon-chart-comp {
    width: 50px;
    height: 50px;
    x: 45;
    y: 161;
  }
  .moon-num-chart {
    height: 40px !important;
    y: 166;
  }
  .north-node-chart-comp {
    width: 50px;
    height: 50px;
    x: 45;
    y: 214;
  }
  .north-node-num-chart {
    height: 40px !important;
    y: 218;
  }
  .south-node-chart-comp {
    width: 50px;
    height: 50px;
    x: 45;
    y: 271;
  }
  .south-node-num-chart {
    height: 40px !important;
    y: 268;
  }
  .mercury-chart-comp {
    width: 50px;
    height: 50px;
    x: 45;
    y: 316;
  }
  .mercury-num-chart {
    height: 40px !important;
    y: 319;
  }
  .venus-chart-comp {
    width: 50px;
    height: 50px;
    x: 45;
    y: 368;
  }
  .venus-num-chart {
    height: 40px !important;
    y: 370;
  }
  .mars-chart-comp {
    width: 50px;
    height: 50px;
    x: 45;
    y: 420;
  }
  .mars-num-chart {
    height: 40px !important;
    y: 420;
  }
  .jupiter-chart-comp {
    width: 50px;
    height: 50px;
    x: 45;
    y: 470;
  }
  .jupiter-num-chart {
    height: 40px !important;
    y: 472;
  }
  .saturn-chart-comp {
    width: 50px;
    height: 50px;
    x: 45;
    y: 521;
  }
  .saturn-num-chart {
    height: 40px !important;
    y: 524;
  }
  .uranus-chart-comp {
    width: 50px;
    height: 50px;
    x: 45;
    y: 572;
  }
  .uranus-num-chart {
    height: 40px !important;
    y: 576;
  }
  .neptune-chart-comp {
    width: 50px;
    height: 50px;
    x: 45;
    y: 621;
  }
  .neptune-num-chart {
    height: 40px !important;
    y: 626;
  }
  .pluto-chart-comp {
    width: 50px;
    height: 50px;
    x: 45;
    y: 672;
  }
  .pluto-num-chart {
    height: 40px !important;
    y: 675;
  }
  .sun-per {
    width: 50px;
    height: 50px;
    x: 790;
    y: 56;
  }
  .sun-num-per {
    height: 40px !important;
    y: 83;
  }
  .earth-per {
    width: 50px;
    height: 50px;
    x: 790;
    y: 106;
  }
  .earth-num-per {
    height: 40px !important;
    y: 134;
  }
  .moon-per {
    width: 50px;
    height: 50px;
    x: 815;
    y: 180;
  }
  .moon-num-per {
    height: 40px !important;
    y: 184;
  }
  .north-node-per {
    width: 50px;
    height: 50px;
    x: 815;
    y: 232;
  }
  .north-node-num-per {
    height: 40px !important;
    y: 234;
  }
  .south-node-per {
    width: 50px;
    height: 50px;
    x: 815;
    y: 281;
  }
  .south-node-num-per {
    height: 40px !important;
    y: 285;
  }
  .mercury-per {
    width: 50px;
    height: 50px;
    x: 790;
    y: 310;
  }
  .mercury-num-per {
    height: 40px !important;
    y: 337;
  }
  .venus-per {
    width: 50px;
    height: 50px;
    x: 790;
    y: 361;
  }
  .venus-num-per {
    height: 40px !important;
    y: 388;
  }
  .mars-per {
    width: 50px;
    height: 50px;
    x: 790;
    y: 415;
  }
  .mars-num-per {
    height: 40px !important;
    y: 440;
  }
  .jupiter-per {
    width: 50px;
    height: 50px;
    x: 790;
    y: 462;
  }
  .jupiter-num-per {
    height: 40px !important;
    y: 489;
  }
  .saturn-per {
    width: 50px;
    height: 50px;
    x: 790;
    y: 516;
  }
  .saturn-num-per {
    height: 40px !important;
    y: 542;
  }
  .uranus-per {
    width: 50px;
    height: 50px;
    x: 790;
    y: 566;
  }
  .uranus-num-per {
    height: 40px !important;
    y: 592;
  }
  .neptune-per {
    width: 50px;
    height: 50px;
    x: 790;
    y: 615;
  }
  .neptune-num-per {
    height: 40px !important;
    y: 643;
  }
  .pluto-per {
    width: 50px;
    height: 50px;
    x: 815;
    y: 690;
  }
  .pluto-num-per {
    height: 40px !important;
    y: 695;
  }
}
.theme--dark .planet-chart2 {
  fill: #fb8c00;
  stroke: #fb8c00;
}
.planet-chart2:hover,
.planet-chart1:hover {
  stroke-opacity: 1;
}
.planet-number-chart2,
.planet-number-chart {
  cursor: pointer;
  width: 48px;
  height: 29px;
  stroke: #fb8c00;
  fill: transparent;
  stroke-width: 2;
  opacity: 0;
}
.theme--dark .planet-number-chart2 {
  stroke: #fb8c00;
}
.planet-number-chart {
  stroke: #4caf50;
}
.theme--dark .planet-number-chart {
  stroke: #4caf50;
}
.line-arrow-chart2 {
  fill: #fb8c00;
}
.theme--dark .line-arrow-chart2 {
  fill: #fb8c00;
}
/* Centres & Gate lines */
#center_head,
#center_ajna,
#center_throat,
#center_ego,
#center_emo,
#center_self,
#center_sacral,
#center_splenic,
#center_root,
#path357,
#path359,
#circle373,
#path371,
#path361,
#path369,
#path365,
#path363,
#path375 {
  stroke: #49c4be;
  stroke-width: 4 !important;
  stroke-opacity: 0;
  transition: stroke-opacity 0.7s;
}
.theme--dark #center_head,
.theme--dark #center_ajna,
.theme--dark #center_throat,
.theme--dark #center_ego,
.theme--dark #center_emo,
.theme--dark #center_self,
.theme--dark #center_sacral,
.theme--dark #center_splenic,
.theme--dark #center_root,
.theme--dark #path357,
.theme--dark #path359,
.theme--dark #circle373,
.theme--dark #path371,
.theme--dark #path361,
.theme--dark #path369,
.theme--dark #path365,
.theme--dark #path363,
.theme--dark #path375 {
  stroke: #00adfe;
}
#center_throat {
  stroke: rgb(161, 117, 218);
}
#center_head:hover,
#center_ajna:hover,
#center_throat:hover,
#center_ego:hover,
#center_sacral:hover,
#center_emo:hover,
#center_self:hover,
#center_splenic:hover,
#center_root:hover,
#path357:hover,
#path359:hover,
#circle373:hover,
#path371:hover,
#path361:hover,
#path369:hover,
#path365:hover,
#path363:hover,
#path375:hover {
  stroke-opacity: 0.9;
}
.theme--dark #circle1820,
.theme--dark #path1818,
.theme--dark #path1814,
.theme--dark #path1814,
.theme--dark #path1822,
.theme--dark #path1806,
.theme--dark #path1808,
.theme--dark #path1810,
.theme--dark #path1812,
.theme--dark #path1816 {
  fill: #378fb5 !important;
  fill-opacity: 0.95 !important;
}
#gate_01_design,
#gate_01_person,
#gate_01_design2,
#gate_01_person2,
#gate_01_both_chart,
#gate_01_both_chart2,
#gate_01_both_con,
#gate_02_design,
#gate_02_person,
#gate_02_design2,
#gate_02_person2,
#gate_02_both_chart,
#gate_02_both_chart2,
#gate_02_both_con,
#gate_03_design,
#gate_03_person,
#gate_03_design2,
#gate_03_person2,
#gate_03_both_chart,
#gate_03_both_chart2,
#gate_03_both_con,
#gate_04_design,
#gate_04_person,
#gate_04_design2,
#gate_04_person2,
#gate_04_both_chart,
#gate_04_both_chart2,
#gate_04_both_con,
#gate_05_design,
#gate_05_person,
#gate_05_design2,
#gate_05_person2,
#gate_05_both_chart,
#gate_05_both_chart2,
#gate_05_both_con,
#gate_06_design,
#gate_06_person,
#gate_06_design2,
#gate_06_person2,
#gate_06_both_chart,
#gate_06_both_chart2,
#gate_06_both_con,
#gate_07_design,
#gate_07_person,
#gate_07_design2,
#gate_07_person2,
#gate_07_both_chart,
#gate_07_both_chart2,
#gate_07_both_con,
#gate_08_design,
#gate_08_person,
#gate_08_design2,
#gate_08_person2,
#gate_08_both_chart,
#gate_08_both_chart2,
#gate_08_both_con,
#gate_09_design,
#gate_09_person,
#gate_09_design2,
#gate_09_person2,
#gate_09_both_chart,
#gate_09_both_chart2,
#gate_09_both_con,
#gate_10_design,
#gate_10_person,
#gate_10_design2,
#gate_10_person2,
#gate_10_both_chart,
#gate_10_both_chart2,
#gate_10_both_con,
#gate_11_design,
#gate_11_person,
#gate_11_design2,
#gate_11_person2,
#gate_11_both_chart,
#gate_11_both_chart2,
#gate_11_both_con,
#gate_12_design,
#gate_12_person,
#gate_12_design2,
#gate_12_person2,
#gate_12_both_chart,
#gate_12_both_chart2,
#gate_12_both_con,
#gate_13_design,
#gate_13_person,
#gate_13_design2,
#gate_13_person2,
#gate_13_both_chart,
#gate_13_both_chart2,
#gate_13_both_con,
#gate_14_design,
#gate_14_person,
#gate_14_design2,
#gate_14_person2,
#gate_14_both_chart,
#gate_14_both_chart2,
#gate_14_both_con,
#gate_15_design,
#gate_15_person,
#gate_15_design2,
#gate_15_person2,
#gate_15_both_chart,
#gate_15_both_chart2,
#gate_15_both_con,
#gate_16_design,
#gate_16_person,
#gate_16_design2,
#gate_16_person2,
#gate_16_both_chart,
#gate_16_both_chart2,
#gate_16_both_con,
#gate_17_design,
#gate_17_person,
#gate_17_design2,
#gate_17_person2,
#gate_17_both_chart,
#gate_17_both_chart2,
#gate_17_both_con,
#gate_18_design,
#gate_18_person,
#gate_18_design2,
#gate_18_person2,
#gate_18_both_chart,
#gate_18_both_chart2,
#gate_18_both_con,
#gate_19_design,
#gate_19_person,
#gate_19_design2,
#gate_19_person2,
#gate_19_both_chart,
#gate_19_both_chart2,
#gate_19_both_con,
#gate_20_design,
#gate_20_person,
#gate_20_design2,
#gate_20_person2,
#gate_20_both_chart,
#gate_20_both_chart2,
#gate_20_both_con,
#gate_21_design,
#gate_21_person,
#gate_21_design2,
#gate_21_person2,
#gate_21_both_chart,
#gate_21_both_chart2,
#gate_21_both_con,
#gate_22_design,
#gate_22_person,
#gate_22_design2,
#gate_22_person2,
#gate_22_both_chart,
#gate_22_both_chart2,
#gate_22_both_con,
#gate_23_design,
#gate_23_person,
#gate_23_design2,
#gate_23_person2,
#gate_23_both_chart,
#gate_23_both_chart2,
#gate_23_both_con,
#gate_24_design,
#gate_24_person,
#gate_24_design2,
#gate_24_person2,
#gate_24_both_chart,
#gate_24_both_chart2,
#gate_24_both_con,
#gate_25_design,
#gate_25_person,
#gate_25_design2,
#gate_25_person2,
#gate_25_both_chart,
#gate_25_both_chart2,
#gate_25_both_con,
#gate_26_design,
#gate_26_person,
#gate_26_design2,
#gate_26_person2,
#gate_26_both_chart,
#gate_26_both_chart2,
#gate_26_both_con,
#gate_27_design,
#gate_27_person,
#gate_27_design2,
#gate_27_person2,
#gate_27_both_chart,
#gate_27_both_chart2,
#gate_27_both_con,
#gate_28_design,
#gate_28_person,
#gate_28_design2,
#gate_28_person2,
#gate_28_both_chart,
#gate_28_both_chart2,
#gate_28_both_con,
#gate_29_design,
#gate_29_person,
#gate_29_design2,
#gate_29_person2,
#gate_29_both_chart,
#gate_29_both_chart2,
#gate_29_both_con,
#gate_30_design,
#gate_30_person,
#gate_30_design2,
#gate_30_person2,
#gate_30_both_chart,
#gate_30_both_chart2,
#gate_30_both_con,
#gate_31_design,
#gate_31_person,
#gate_31_design2,
#gate_31_person2,
#gate_31_both_chart,
#gate_31_both_chart2,
#gate_31_both_con,
#gate_32_design,
#gate_32_person,
#gate_32_design2,
#gate_32_person2,
#gate_32_both_chart,
#gate_32_both_chart2,
#gate_32_both_con,
#gate_33_design,
#gate_33_person,
#gate_33_design2,
#gate_33_person2,
#gate_33_both_chart,
#gate_33_both_chart2,
#gate_33_both_con,
#gate_34_design,
#gate_34_person,
#gate_34_design2,
#gate_34_person2,
#gate_34_both_chart,
#gate_34_both_chart2,
#gate_34_both_con,
#gate_35_design,
#gate_35_person,
#gate_35_design2,
#gate_35_person2,
#gate_35_both_chart,
#gate_35_both_chart2,
#gate_35_both_con,
#gate_36_design,
#gate_36_person,
#gate_36_design2,
#gate_36_person2,
#gate_36_both_chart,
#gate_36_both_chart2,
#gate_36_both_con,
#gate_37_design,
#gate_37_person,
#gate_37_design2,
#gate_37_person2,
#gate_37_both_chart,
#gate_37_both_chart2,
#gate_37_both_con,
#gate_38_design,
#gate_38_person,
#gate_38_design2,
#gate_38_person2,
#gate_38_both_chart,
#gate_38_both_chart2,
#gate_38_both_con,
#gate_39_design,
#gate_39_person,
#gate_39_design2,
#gate_39_person2,
#gate_39_both_chart,
#gate_39_both_chart2,
#gate_39_both_con,
#gate_40_design,
#gate_40_person,
#gate_40_design2,
#gate_40_person2,
#gate_40_both_chart,
#gate_40_both_chart2,
#gate_40_both_con,
#gate_41_design,
#gate_41_person,
#gate_41_design2,
#gate_41_person2,
#gate_41_both_chart,
#gate_41_both_chart2,
#gate_41_both_con,
#gate_42_design,
#gate_42_person,
#gate_42_design2,
#gate_42_person2,
#gate_42_both_chart,
#gate_42_both_chart2,
#gate_42_both_con,
#gate_43_design,
#gate_43_person,
#gate_43_design2,
#gate_43_person2,
#gate_43_both_chart,
#gate_43_both_chart2,
#gate_43_both_con,
#gate_44_design,
#gate_44_person,
#gate_44_design2,
#gate_44_person2,
#gate_44_both_chart,
#gate_44_both_chart2,
#gate_44_both_con,
#gate_45_design,
#gate_45_person,
#gate_45_design2,
#gate_45_person2,
#gate_45_both_chart,
#gate_45_both_chart2,
#gate_45_both_con,
#gate_46_design,
#gate_46_person,
#gate_46_design2,
#gate_46_person2,
#gate_46_both_chart,
#gate_46_both_chart2,
#gate_46_both_con,
#gate_47_design,
#gate_47_person,
#gate_47_design2,
#gate_47_person2,
#gate_47_both_chart,
#gate_47_both_chart2,
#gate_47_both_con,
#gate_48_design,
#gate_48_person,
#gate_48_design2,
#gate_48_person2,
#gate_48_both_chart,
#gate_48_both_chart2,
#gate_48_both_con,
#gate_49_design,
#gate_49_person,
#gate_49_design2,
#gate_49_person2,
#gate_49_both_chart,
#gate_49_both_chart2,
#gate_49_both_con,
#gate_50_design,
#gate_50_person,
#gate_50_design2,
#gate_50_person2,
#gate_50_both_chart,
#gate_50_both_chart2,
#gate_50_both_con,
#gate_51_design,
#gate_51_person,
#gate_51_design2,
#gate_51_person2,
#gate_51_both_chart,
#gate_51_both_chart2,
#gate_51_both_con,
#gate_52_design,
#gate_52_person,
#gate_52_design2,
#gate_52_person2,
#gate_52_both_chart,
#gate_52_both_chart2,
#gate_52_both_con,
#gate_53_design,
#gate_53_person,
#gate_53_design2,
#gate_53_person2,
#gate_53_both_chart,
#gate_53_both_chart2,
#gate_53_both_con,
#gate_54_design,
#gate_54_person,
#gate_54_design2,
#gate_54_person2,
#gate_54_both_chart,
#gate_54_both_chart2,
#gate_54_both_con,
#gate_55_design,
#gate_55_person,
#gate_55_design2,
#gate_55_person2,
#gate_55_both_chart,
#gate_55_both_chart2,
#gate_55_both_con,
#gate_56_design,
#gate_56_person,
#gate_56_design2,
#gate_56_person2,
#gate_56_both_chart,
#gate_56_both_chart2,
#gate_56_both_con,
#gate_57_design,
#gate_57_person,
#gate_57_design2,
#gate_57_person2,
#gate_57_both_chart,
#gate_57_both_chart2,
#gate_57_both_con,
#gate_58_design,
#gate_58_person,
#gate_58_design2,
#gate_58_person2,
#gate_58_both_chart,
#gate_58_both_chart2,
#gate_58_both_con,
#gate_59_design,
#gate_59_person,
#gate_59_design2,
#gate_59_person2,
#gate_59_both_chart,
#gate_59_both_chart2,
#gate_59_both_con,
#gate_60_design,
#gate_60_person,
#gate_60_design2,
#gate_60_person2,
#gate_60_both_chart,
#gate_60_both_chart2,
#gate_60_both_con,
#gate_61_design,
#gate_61_person,
#gate_61_design2,
#gate_61_person2,
#gate_61_both_chart,
#gate_61_both_chart2,
#gate_61_both_con,
#gate_62_design,
#gate_62_person,
#gate_62_design2,
#gate_62_person2,
#gate_62_both_chart,
#gate_62_both_chart2,
#gate_62_both_con,
#gate_63_design,
#gate_63_person,
#gate_63_design2,
#gate_63_person2,
#gate_63_both_chart,
#gate_63_both_chart2,
#gate_63_both_con,
#gate_64_design,
#gate_64_person,
#gate_64_design2,
#gate_64_person2,
#gate_64_both_chart,
#gate_18_58_channel,
#gate_18_58_channel_chart1,
#gate_18_58_channel_chart2,
#gate_28_38_channel,
#gate_28_38_channel_chart1,
#gate_28_38_channel_chart2,
#gate_32_54_channel,
#gate_32_54_channel_chart1,
#gate_32_54_channel_chart2,
#gate_47_64_channel,
#gate_47_64_channel_chart1,
#gate_47_64_channel_chart2,
#gate_04_63_channel,
#gate_04_63_channel_chart1,
#gate_04_63_channel_chart2,
#gate_24_61_channel,
#gate_24_61_channel_chart1,
#gate_24_61_channel_chart2,
#gate_17_62_channel,
#gate_17_62_channel_chart1,
#gate_17_62_channel_chart2,
#gate_23_43_channel,
#gate_23_43_channel_chart1,
#gate_23_43_channel_chart2,
#gate_11_56_channel,
#gate_11_56_channel_chart1,
#gate_11_56_channel_chart2,
#gate_16_48_channel,
#gate_16_48_channel_chart1,
#gate_16_48_channel_chart2,
#gate_20_57_channel,
#gate_20_57_channel_chart1,
#gate_20_57_channel_chart2,
#gate_20_34_channel,
#gate_20_34_channel_chart1,
#gate_20_34_channel_chart2,
#gate_35_36_channel,
#gate_35_36_channel_chart1,
#gate_35_36_channel_chart2,
#gate_12_22_channel,
#gate_12_22_channel_chart1,
#gate_12_22_channel_chart2,
#gate_21_45_channel,
#gate_21_45_channel_chart1,
#gate_21_45_channel_chart2,
#gate_26_44_channel,
#gate_26_44_channel_chart1,
#gate_26_44_channel_chart2,
#gate_37_40_channel,
#gate_37_40_channel_chart1,
#gate_37_40_channel_chart2,
#gate_01_08_channel,
#gate_01_08_channel_chart1,
#gate_01_08_channel_chart2,
#gate_07_31_channel,
#gate_07_31_channel_chart1,
#gate_07_31_channel_chart2,
#gate_13_33_channel,
#gate_13_33_channel_chart1,
#gate_13_33_channel_chart2,
#gate_25_51_channel,
#gate_25_51_channel_chart1,
#gate_25_51_channel_chart2,
#gate_10_20_channel,
#gate_10_20_channel_chart1,
#gate_10_20_channel_chart2,
#gate_10_34_channel,
#gate_10_34_channel_chart1,
#gate_10_34_channel_chart2,
#gate_10_57_channel,
#gate_10_57_channel_chart1,
#gate_10_57_channel_chart2,
#gate_10-20_10-57_channel,
#gate_10-20_10-57_channel_chart1,
#gate_10-20_10-57_channel_chart2,
#gate_10-20_20-57_channel,
#gate_10-20_20-57_channel_chart1,
#gate_10-20_20-57_channel_chart2,
#gate_10-57_20-57_channel,
#gate_10-57_20-57_channel_chart1,
#gate_10-57_20-57_channel_chart2,
#gate_10-20_10-57_20-57_channel,
#gate_10-20_10-57_20-57_channel_chart1,
#gate_10-20_10-57_20-57_channel_chart2,
#gate_10-20_10-34_channel,
#gate_10-20_10-34_channel_chart1,
#gate_10-20_10-34_channel_chart2,
#gate_10-34_20-34_channel,
#gate_10-34_20-34_channel_chart1,
#gate_10-34_20-34_channel_chart2,
#gate_10-20_20-34_channel,
#gate_10-20_20-34_channel_chart1,
#gate_10-20_20-34_channel_chart2,
#gate_10-20_10-34_20-34_channel,
#gate_10-20_10-34_20-34_channel_chart1,
#gate_10-20_10-34_20-34_channel_chart2,
#gate_20-34_20-57_channel,
#gate_20-34_20-57_channel_chart1,
#gate_20-34_20-57_channel_chart2,
#gate_20-34_34-57_channel,
#gate_20-34_34-57_channel_chart1,
#gate_20-34_34-57_channel_chart2,
#gate_20-57_34-57_channel,
#gate_20-57_34-57_channel_chart1,
#gate_20-57_34-57_channel_chart2,
#gate_20-34_20-57_34-57_channel,
#gate_20-34_20-57_34-57_channel_chart1,
#gate_20-34_20-57_34-57_channel_chart2,
#gate_10-34_10-57_channel,
#gate_10-34_10-57_channel_chart1,
#gate_10-34_10-57_channel_chart2,
#gate_10-34_34-57_channel,
#gate_10-34_34-57_channel_chart1,
#gate_10-34_34-57_channel_chart2,
#gate_10-57_34-57_channel,
#gate_10-57_34-57_channel_chart1,
#gate_10-57_34-57_channel_chart2,
#gate_10-34_10-57_34-57_channel,
#gate_10-34_10-57_34-57_channel_chart1,
#gate_10-34_10-57_34-57_channel_chart2,
#gate_10-20_34-57_channel,
#gate_10-20_34-57_channel_chart1,
#gate_10-20_34-57_channel_chart2,
#gate_10-34_20-57_channel,
#gate_10-34_20-57_channel_chart1,
#gate_10-34_20-57_channel_chart2,
#gate_10-57_20-34_channel,
#gate_10-57_20-34_channel_chart1,
#gate_10-57_20-34_channel_chart2,
#gate_10-20_10-34_10-57_channel,
#gate_10-20_10-34_10-57_channel_chart1,
#gate_10-20_10-34_10-57_channel_chart2,
#gate_10-20_10-34_20-57_channel,
#gate_10-20_10-34_20-57_channel_chart1,
#gate_10-20_10-34_20-57_channel_chart2,
#gate_10-20_10-34_34-57_channel,
#gate_10-20_10-34_34-57_channel_chart1,
#gate_10-20_10-34_34-57_channel_chart2,
#gate_10-20_10-57_20-34_channel,
#gate_10-20_10-57_20-34_channel_chart1,
#gate_10-20_10-57_20-34_channel_chart2,
#gate_10-20_10-57_34-57_channel,
#gate_10-20_10-57_34-57_channel_chart1,
#gate_10-20_10-57_34-57_channel_chart2,
#gate_10-20_20-34_20-57_channel,
#gate_10-20_20-34_20-57_channel_chart1,
#gate_10-20_20-34_20-57_channel_chart2,
#gate_10-20_20-34_34-57_channel,
#gate_10-20_20-34_34-57_channel_chart1,
#gate_10-20_20-34_34-57_channel_chart2,
#gate_10-20_20-57_34-57_channel,
#gate_10-20_20-57_34-57_channel_chart1,
#gate_10-20_20-57_34-57_channel_chart2,
#gate_10-34_10-57_20-34_channel,
#gate_10-34_10-57_20-34_channel_chart1,
#gate_10-34_10-57_20-34_channel_chart2,
#gate_10-34_10-57_20-57_channel,
#gate_10-34_10-57_20-57_channel_chart1,
#gate_10-34_10-57_20-57_channel_chart2,
#gate_10-34_20-34_20-57_channel,
#gate_10-34_20-34_20-57_channel_chart1,
#gate_10-34_20-34_20-57_channel_chart2,
#gate_10-34_20-34_34-57_channel,
#gate_10-34_20-34_34-57_channel_chart1,
#gate_10-34_20-34_34-57_channel_chart2,
#gate_10-34_20-57_34-57_channel,
#gate_10-34_20-57_34-57_channel_chart1,
#gate_10-34_20-57_34-57_channel_chart2,
#gate_10-57_20-34_20-57_channel,
#gate_10-57_20-34_20-57_channel_chart1,
#gate_10-57_20-34_20-57_channel_chart2,
#gate_10-57_20-34_34-57_channel,
#gate_10-57_20-34_34-57_channel_chart1,
#gate_10-57_20-34_34-57_channel_chart2,
#gate_10-57_20-57_34-57_channel,
#gate_10-57_20-57_34-57_channel_chart1,
#gate_10-57_20-57_34-57_channel_chart2,
#gate_10-20_10-34_10-57_20-34_channel,
#gate_10-20_10-34_10-57_20-34_channel_chart1,
#gate_10-20_10-34_10-57_20-34_channel_chart2,
#gate_10-20_10-34_10-57_20-57_channel,
#gate_10-20_10-34_10-57_20-57_channel_chart1,
#gate_10-20_10-34_10-57_20-57_channel_chart2,
#gate_10-20_10-34_10-57_34-57_channel,
#gate_10-20_10-34_10-57_34-57_channel_chart1,
#gate_10-20_10-34_10-57_34-57_channel_chart2,
#gate_10-20_10-34_20-34_20-57_channel,
#gate_10-20_10-34_20-34_20-57_channel_chart1,
#gate_10-20_10-34_20-34_20-57_channel_chart2,
#gate_10-20_10-34_20-34_34-57_channel,
#gate_10-20_10-34_20-34_34-57_channel_chart1,
#gate_10-20_10-34_20-34_34-57_channel_chart2,
#gate_10-20_10-34_20-57_34-57_channel,
#gate_10-20_10-34_20-57_34-57_channel_chart1,
#gate_10-20_10-34_20-57_34-57_channel_chart2,
#gate_10-20_10-57_20-34_20-57_channel,
#gate_10-20_10-57_20-34_20-57_channel_chart1,
#gate_10-20_10-57_20-34_20-57_channel_chart2,
#gate_10-20_10-57_20-34_34-57_channel,
#gate_10-20_10-57_20-34_34-57_channel_chart1,
#gate_10-20_10-57_20-34_34-57_channel_chart2,
#gate_10-20_10-57_20-57_34-57_channel,
#gate_10-20_10-57_20-57_34-57_channel_chart1,
#gate_10-20_10-57_20-57_34-57_channel_chart2,
#gate_10-34_10-57_20-34_20-57_channel,
#gate_10-34_10-57_20-34_20-57_channel_chart1,
#gate_10-34_10-57_20-34_20-57_channel_chart2,
#gate_10-34_10-57_20-34_34-57_channel,
#gate_10-34_10-57_20-34_34-57_channel_chart1,
#gate_10-34_10-57_20-34_34-57_channel_chart2,
#gate_10-34_10-57_20-57_34-57_channel,
#gate_10-34_10-57_20-57_34-57_channel_chart1,
#gate_10-34_10-57_20-57_34-57_channel_chart2,
#gate_10-20_20-34_20-57_34-57_channel,
#gate_10-20_20-34_20-57_34-57_channel_chart1,
#gate_10-20_20-34_20-57_34-57_channel_chart2,
#gate_10-34_20-34_20-57_34-57_channel,
#gate_10-34_20-34_20-57_34-57_channel_chart1,
#gate_10-34_20-34_20-57_34-57_channel_chart2,
#gate_10-57_20-34_20-57_34-57_channel,
#gate_10-57_20-34_20-57_34-57_channel_chart1,
#gate_10-57_20-34_20-57_34-57_channel_chart2,
#gate_10-20_10-34_10-57_20-34_20-57_channel,
#gate_10-20_10-34_10-57_20-34_20-57_channel_chart1,
#gate_10-20_10-34_10-57_20-34_20-57_channel_chart2,
#gate_10-20_10-34_10-57_20-34_34-57_channel,
#gate_10-20_10-34_10-57_20-34_34-57_channel_chart1,
#gate_10-20_10-34_10-57_20-34_34-57_channel_chart2,
#gate_10-20_10-34_10-57_20-57_34-57_channel,
#gate_10-20_10-34_10-57_20-57_34-57_channel_chart1,
#gate_10-20_10-34_10-57_20-57_34-57_channel_chart2,
#gate_10-20_10-34_20-34_20-57_34-57_channel,
#gate_10-20_10-34_20-34_20-57_34-57_channel_chart1,
#gate_10-20_10-34_20-34_20-57_34-57_channel_chart2,
#gate_10-20_10-57_20-34_20-57_34-57_channel,
#gate_10-20_10-57_20-34_20-57_34-57_channel_chart1,
#gate_10-20_10-57_20-34_20-57_34-57_channel_chart2,
#gate_10-34_10-57_20-34_20-57_34-57_channel,
#gate_10-34_10-57_20-34_20-57_34-57_channel_chart1,
#gate_10-34_10-57_20-34_20-57_34-57_channel_chart2,
#gate_10-20_10-34_10-57_20-34_20-57_34-57_channel,
#gate_10-20_10-34_10-57_20-34_20-57_34-57_channel_chart1,
#gate_10-20_10-34_10-57_20-34_20-57_34-57_channel_chart2,
#gate_05_15_channel,
#gate_05_15_channel_chart1,
#gate_05_15_channel_chart2,
#gate_06_59_channel,
#gate_06_59_channel_chart1,
#gate_06_59_channel_chart2,
#gate_19_49_channel,
#gate_19_49_channel_chart1,
#gate_19_49_channel_chart2,
#gate_39_55_channel,
#gate_39_55_channel_chart1,
#gate_39_55_channel_chart2,
#gate_30_41_channel,
#gate_30_41_channel_chart1,
#gate_30_41_channel_chart2,
#gate_09_52_channel,
#gate_09_52_channel_chart1,
#gate_09_52_channel_chart2,
#gate_03_60_channel,
#gate_03_60_channel_chart1,
#gate_03_60_channel_chart2,
#gate_42_53_channel,
#gate_42_53_channel_chart1,
#gate_42_53_channel_chart2,
#gate_27_50_channel,
#gate_27_50_channel_chart1,
#gate_27_50_channel_chart2,
#gate_29_46_channel,
#gate_29_46_channel_chart1,
#gate_29_46_channel_chart2,
#gate_02_14_channel,
#gate_02_14_channel_chart1,
#gate_02_14_channel_chart2 {
  cursor: pointer;
  stroke: #49c4be;
  stroke-width: 3;
  stroke-opacity: 0;
  transition: stroke-opacity 0.3s;
}
#gate_01_design:hover,
#gate_01_person:hover,
#gate_01_design2:hover,
#gate_01_person2:hover,
#gate_01_both_chart:hover,
#gate_01_both_chart2:hover,
#gate_01_both_con:hover,
#gate_02_design:hover,
#gate_02_person:hover,
#gate_02_design2:hover,
#gate_02_person2:hover,
#gate_02_both_chart:hover,
#gate_02_both_chart2:hover,
#gate_02_both_con:hover,
#gate_03_design:hover,
#gate_03_person:hover,
#gate_03_design2:hover,
#gate_03_person2:hover,
#gate_03_both_chart:hover,
#gate_03_both_chart2:hover,
#gate_03_both_con:hover,
#gate_04_design:hover,
#gate_04_person:hover,
#gate_04_design2:hover,
#gate_04_person2:hover,
#gate_04_both_chart:hover,
#gate_04_both_chart2:hover,
#gate_04_both_con:hover,
#gate_05_design:hover,
#gate_05_person:hover,
#gate_05_design2:hover,
#gate_05_person2:hover,
#gate_05_both_chart:hover,
#gate_05_both_chart2:hover,
#gate_05_both_con:hover,
#gate_06_design:hover,
#gate_06_person:hover,
#gate_06_design2:hover,
#gate_06_person2:hover,
#gate_06_both_chart:hover,
#gate_06_both_chart2:hover,
#gate_06_both_con:hover,
#gate_07_design:hover,
#gate_07_person:hover,
#gate_07_design2:hover,
#gate_07_person2:hover,
#gate_07_both_chart:hover,
#gate_07_both_chart2:hover,
#gate_07_both_con:hover,
#gate_08_design:hover,
#gate_08_person:hover,
#gate_08_design2:hover,
#gate_08_person2:hover,
#gate_08_both_chart:hover,
#gate_08_both_chart2:hover,
#gate_08_both_con:hover,
#gate_09_design:hover,
#gate_09_person:hover,
#gate_09_design2:hover,
#gate_09_person2:hover,
#gate_09_both_chart:hover,
#gate_09_both_chart2:hover,
#gate_09_both_con:hover,
#gate_10_design:hover,
#gate_10_person:hover,
#gate_10_design2:hover,
#gate_10_person2:hover,
#gate_10_both_chart:hover,
#gate_10_both_chart2:hover,
#gate_10_both_con:hover,
#gate_11_design:hover,
#gate_11_person:hover,
#gate_11_design2:hover,
#gate_11_person2:hover,
#gate_11_both_chart:hover,
#gate_11_both_chart2:hover,
#gate_11_both_con:hover,
#gate_12_design:hover,
#gate_12_design2:hover,
#gate_12_person:hover,
#gate_12_person2:hover,
#gate_12_both_chart:hover,
#gate_12_both_chart2:hover,
#gate_12_both_con:hover,
#gate_13_design:hover,
#gate_13_person:hover,
#gate_13_design2:hover,
#gate_13_person2:hover,
#gate_13_both_chart:hover,
#gate_13_both_chart2:hover,
#gate_13_both_con:hover,
#gate_14_design:hover,
#gate_14_person:hover,
#gate_14_design2:hover,
#gate_14_person2:hover,
#gate_14_both_chart:hover,
#gate_14_both_chart2:hover,
#gate_14_both_con:hover,
#gate_15_design:hover,
#gate_15_person:hover,
#gate_15_design2:hover,
#gate_15_person2:hover,
#gate_15_both_chart:hover,
#gate_15_both_chart2:hover,
#gate_15_both_con:hover,
#gate_16_design:hover,
#gate_16_person:hover,
#gate_16_design2:hover,
#gate_16_person2:hover,
#gate_16_both_chart:hover,
#gate_16_both_chart2:hover,
#gate_16_both_con:hover,
#gate_17_design:hover,
#gate_17_person:hover,
#gate_17_design2:hover,
#gate_17_person2:hover,
#gate_17_both_chart:hover,
#gate_17_both_chart2:hover,
#gate_17_both_con:hover,
#gate_18_design:hover,
#gate_18_person:hover,
#gate_18_design2:hover,
#gate_18_person2:hover,
#gate_18_both_chart:hover,
#gate_18_both_chart2:hover,
#gate_18_both_con:hover,
#gate_19_design:hover,
#gate_19_person:hover,
#gate_19_design2:hover,
#gate_19_person2:hover,
#gate_19_both_chart:hover,
#gate_19_both_chart2:hover,
#gate_19_both_con:hover,
#gate_20_design:hover,
#gate_20_person:hover,
#gate_20_design2:hover,
#gate_20_person2:hover,
#gate_20_both_chart:hover,
#gate_20_both_chart2:hover,
#gate_20_both_con:hover,
#gate_21_design:hover,
#gate_21_person:hover,
#gate_21_design2:hover,
#gate_21_person2:hover,
#gate_21_both_chart:hover,
#gate_21_both_chart2:hover,
#gate_21_both_con:hover,
#gate_22_design:hover,
#gate_22_person:hover,
#gate_22_design2:hover,
#gate_22_person2:hover,
#gate_22_both_chart:hover,
#gate_22_both_chart2:hover,
#gate_22_both_con:hover,
#gate_23_design:hover,
#gate_23_person:hover,
#gate_23_design2:hover,
#gate_23_person2:hover,
#gate_23_both_chart:hover,
#gate_23_both_chart2:hover,
#gate_23_both_con:hover,
#gate_24_design:hover,
#gate_24_person:hover,
#gate_24_design2:hover,
#gate_24_person2:hover,
#gate_24_both_chart:hover,
#gate_24_both_chart2:hover,
#gate_24_both_con:hover,
#gate_25_design:hover,
#gate_25_person:hover,
#gate_25_design2:hover,
#gate_25_person2:hover,
#gate_25_both_chart:hover,
#gate_25_both_chart2:hover,
#gate_25_both_con:hover,
#gate_26_design:hover,
#gate_26_person:hover,
#gate_26_design2:hover,
#gate_26_person2:hover,
#gate_26_both_chart:hover,
#gate_26_both_chart2:hover,
#gate_26_both_con:hover,
#gate_27_design:hover,
#gate_27_person:hover,
#gate_27_design2:hover,
#gate_27_person2:hover,
#gate_27_both_chart:hover,
#gate_27_both_chart2:hover,
#gate_27_both_con:hover,
#gate_28_design:hover,
#gate_28_person:hover,
#gate_28_design2:hover,
#gate_28_person2:hover,
#gate_28_both_chart:hover,
#gate_28_both_chart2:hover,
#gate_28_both_con:hover,
#gate_29_design:hover,
#gate_29_person:hover,
#gate_29_design2:hover,
#gate_29_person2:hover,
#gate_29_both_chart:hover,
#gate_29_both_chart2:hover,
#gate_29_both_con:hover,
#gate_30_design:hover,
#gate_30_person:hover,
#gate_30_design2:hover,
#gate_30_person2:hover,
#gate_30_both_chart:hover,
#gate_30_both_chart2:hover,
#gate_30_both_con:hover,
#gate_31_design:hover,
#gate_31_person:hover,
#gate_31_design2:hover,
#gate_31_person2:hover,
#gate_31_both_chart:hover,
#gate_31_both_chart2:hover,
#gate_31_both_con:hover,
#gate_32_design:hover,
#gate_32_person:hover,
#gate_32_design2:hover,
#gate_32_person2:hover,
#gate_32_both_chart:hover,
#gate_32_both_chart2:hover,
#gate_32_both_con:hover,
#gate_33_design:hover,
#gate_33_person:hover,
#gate_33_design2:hover,
#gate_33_person2:hover,
#gate_33_both_chart:hover,
#gate_33_both_chart2:hover,
#gate_33_both_con:hover,
#gate_34_design:hover,
#gate_34_person:hover,
#gate_34_design2:hover,
#gate_34_person2:hover,
#gate_34_both_chart:hover,
#gate_34_both_chart2:hover,
#gate_34_both_con:hover,
#gate_35_design:hover,
#gate_35_person:hover,
#gate_35_design2:hover,
#gate_35_person2:hover,
#gate_35_both_chart:hover,
#gate_35_both_chart2:hover,
#gate_35_both_con:hover,
#gate_36_design:hover,
#gate_36_person:hover,
#gate_36_design2:hover,
#gate_36_person2:hover,
#gate_36_both_chart:hover,
#gate_36_both_chart2:hover,
#gate_36_both_con:hover,
#gate_37_design:hover,
#gate_37_person:hover,
#gate_37_design2:hover,
#gate_37_design2:hover,
#gate_37_person2:hover,
#gate_37_both_chart:hover,
#gate_37_both_chart2:hover,
#gate_37_both_con:hover,
#gate_38_design:hover,
#gate_38_person:hover,
#gate_38_design2:hover,
#gate_38_person2:hover,
#gate_38_both_chart:hover,
#gate_38_both_chart2:hover,
#gate_38_both_con:hover,
#gate_39_design:hover,
#gate_39_design2:hover,
#gate_39_person:hover,
#gate_39_person2:hover,
#gate_39_both_chart:hover,
#gate_39_both_chart2:hover,
#gate_39_both_con:hover,
#gate_40_design:hover,
#gate_40_person:hover,
#gate_40_design2:hover,
#gate_40_person2:hover,
#gate_40_both_chart:hover,
#gate_40_both_chart2:hover,
#gate_40_both_con:hover,
#gate_41_design:hover,
#gate_41_person:hover,
#gate_41_design2:hover,
#gate_41_person2:hover,
#gate_41_both_chart:hover,
#gate_41_both_chart2:hover,
#gate_41_both_con:hover,
#gate_42_design:hover,
#gate_42_person:hover,
#gate_42_design2:hover,
#gate_42_person2:hover,
#gate_42_both_chart:hover,
#gate_42_both_chart2:hover,
#gate_42_both_con:hover,
#gate_43_design:hover,
#gate_43_person:hover,
#gate_43_design2:hover,
#gate_43_person2:hover,
#gate_43_both_chart:hover,
#gate_43_both_chart2:hover,
#gate_43_both_con:hover,
#gate_44_design:hover,
#gate_44_person:hover,
#gate_44_design2:hover,
#gate_44_person2:hover,
#gate_44_both_chart:hover,
#gate_44_both_chart2:hover,
#gate_44_both_con:hover,
#gate_45_design:hover,
#gate_45_person:hover,
#gate_45_design2:hover,
#gate_45_person2:hover,
#gate_45_both_chart:hover,
#gate_45_both_chart2:hover,
#gate_45_both_con:hover,
#gate_46_design:hover,
#gate_46_person:hover,
#gate_46_design2:hover,
#gate_46_person2:hover,
#gate_46_both_chart:hover,
#gate_46_both_chart2:hover,
#gate_46_both_con:hover,
#gate_47_design:hover,
#gate_47_person:hover,
#gate_47_design2:hover,
#gate_47_person2:hover,
#gate_47_both_chart:hover,
#gate_47_both_chart2:hover,
#gate_47_both_con:hover,
#gate_48_design:hover,
#gate_48_person:hover,
#gate_48_design2:hover,
#gate_48_person2:hover,
#gate_48_both_chart:hover,
#gate_48_both_chart2:hover,
#gate_48_both_con:hover,
#gate_49_design:hover,
#gate_49_person:hover,
#gate_49_design2:hover,
#gate_49_person2:hover,
#gate_49_both_chart:hover,
#gate_49_both_chart2:hover,
#gate_49_both_con:hover,
#gate_50_design:hover,
#gate_50_person:hover,
#gate_50_design2:hover,
#gate_50_person2:hover,
#gate_50_both_chart:hover,
#gate_50_both_chart2:hover,
#gate_50_both_con:hover,
#gate_51_design:hover,
#gate_51_person:hover,
#gate_51_design2:hover,
#gate_51_person2:hover,
#gate_51_both_chart:hover,
#gate_51_both_chart2:hover,
#gate_51_both_con:hover,
#gate_52_design:hover,
#gate_52_person:hover,
#gate_52_design2:hover,
#gate_52_person2:hover,
#gate_52_both_chart:hover,
#gate_52_both_chart2:hover,
#gate_52_both_con:hover,
#gate_53_design:hover,
#gate_53_person:hover,
#gate_53_design2:hover,
#gate_53_person2:hover,
#gate_53_both_chart:hover,
#gate_53_both_chart2:hover,
#gate_53_both_con:hover,
#gate_54_design:hover,
#gate_54_person:hover,
#gate_54_design2:hover,
#gate_54_person2:hover,
#gate_54_both_chart:hover,
#gate_54_both_chart2:hover,
#gate_54_both_con:hover,
#gate_55_design:hover,
#gate_55_person:hover,
#gate_55_design2:hover,
#gate_55_person2:hover,
#gate_55_both_chart:hover,
#gate_55_both_chart2:hover,
#gate_55_both_con:hover,
#gate_56_design:hover,
#gate_56_person:hover,
#gate_56_design2:hover,
#gate_56_person2:hover,
#gate_56_both_chart:hover,
#gate_56_both_chart2:hover,
#gate_56_both_con:hover,
#gate_57_design:hover,
#gate_57_person:hover,
#gate_57_design2:hover,
#gate_57_person2:hover,
#gate_57_both_chart:hover,
#gate_57_both_chart2:hover,
#gate_57_both_con:hover,
#gate_58_design:hover,
#gate_58_person:hover,
#gate_58_design2:hover,
#gate_58_person2:hover,
#gate_58_both_chart:hover,
#gate_58_both_chart2:hover,
#gate_58_both_con:hover,
#gate_59_design:hover,
#gate_59_person:hover,
#gate_59_design2:hover,
#gate_59_person2:hover,
#gate_59_both_chart:hover,
#gate_59_both_chart2:hover,
#gate_59_both_con:hover,
#gate_60_design:hover,
#gate_60_person:hover,
#gate_60_design2:hover,
#gate_60_person2:hover,
#gate_60_both_chart:hover,
#gate_60_both_chart2:hover,
#gate_60_both_con:hover,
#gate_61_design:hover,
#gate_61_person:hover,
#gate_61_design2:hover,
#gate_61_person2:hover,
#gate_61_both_chart:hover,
#gate_61_both_chart2:hover,
#gate_61_both_con:hover,
#gate_62_design:hover,
#gate_62_person:hover,
#gate_62_design2:hover,
#gate_62_person2:hover,
#gate_62_both_chart:hover,
#gate_62_both_chart2:hover,
#gate_62_both_con:hover,
#gate_63_design:hover,
#gate_63_person:hover,
#gate_63_design2:hover,
#gate_63_person2:hover,
#gate_63_both_chart:hover,
#gate_63_both_chart2:hover,
#gate_63_both_con:hover,
#gate_64_design:hover,
#gate_64_person:hover,
#gate_64_design2:hover,
#gate_64_person2:hover,
#gate_64_both_chart:hover,
#gate_64_both_chart2:hover,
#gate_64_both_con:hover,
#gate_18_58_channel:hover,
#gate_18_58_channel_chart1:hover,
#gate_18_58_channel_chart2:hover,
#gate_28_38_channel:hover,
#gate_28_38_channel_chart1:hover,
#gate_28_38_channel_chart2:hover,
#gate_32_54_channel:hover,
#gate_32_54_channel_chart1:hover,
#gate_32_54_channel_chart2:hover,
#gate_47_64_channel:hover,
#gate_47_64_channel_chart1:hover,
#gate_47_64_channel_chart2:hover,
#gate_04_63_channel:hover,
#gate_04_63_channel_chart1:hover,
#gate_04_63_channel_chart2:hover,
#gate_24_61_channel:hover,
#gate_24_61_channel_chart1:hover,
#gate_24_61_channel_chart2:hover,
#gate_17_62_channel:hover,
#gate_17_62_channel_chart1:hover,
#gate_17_62_channel_chart2:hover,
#gate_23_43_channel:hover,
#gate_23_43_channel_chart1:hover,
#gate_23_43_channel_chart2:hover,
#gate_11_56_channel:hover,
#gate_11_56_channel_chart1:hover,
#gate_11_56_channel_chart2:hover,
#gate_16_48_channel:hover,
#gate_16_48_channel_chart1:hover,
#gate_16_48_channel_chart2:hover,
#gate_20_57_channel:hover,
#gate_20_57_channel_chart1:hover,
#gate_20_57_channel_chart2:hover,
#gate_20_34_channel:hover,
#gate_20_34_channel_chart1:hover,
#gate_20_34_channel_chart2:hover,
#gate_35_36_channel:hover,
#gate_35_36_channel_chart1:hover,
#gate_35_36_channel_chart2:hover,
#gate_12_22_channel:hover,
#gate_12_22_channel_chart1:hover,
#gate_12_22_channel_chart2:hover,
#gate_21_45_channel:hover,
#gate_21_45_channel_chart1:hover,
#gate_21_45_channel_chart2:hover,
#gate_26_44_channel:hover,
#gate_26_44_channel_chart1:hover,
#gate_26_44_channel_chart2:hover,
#gate_37_40_channel:hover,
#gate_37_40_channel_chart1:hover,
#gate_37_40_channel_chart2:hover,
#gate_01_08_channel:hover,
#gate_01_08_channel_chart1:hover,
#gate_01_08_channel_chart2:hover,
#gate_07_31_channel:hover,
#gate_07_31_channel_chart1:hover,
#gate_07_31_channel_chart2:hover,
#gate_13_33_channel:hover,
#gate_13_33_channel_chart1:hover,
#gate_13_33_channel_chart2:hover,
#gate_25_51_channel:hover,
#gate_25_51_channel_chart1:hover,
#gate_25_51_channel_chart2:hover,
#gate_10_20_channel:hover,
#gate_10_20_channel_chart1:hover,
#gate_10_20_channel_chart2:hover,
#gate_10_34_channel:hover,
#gate_10_34_channel_chart1:hover,
#gate_10_34_channel_chart2:hover,
#gate_10_57_channel:hover,
#gate_10_57_channel_chart1:hover,
#gate_10_57_channel_chart2:hover,
#gate_10-20_10-57_channel:hover,
#gate_10-20_10-57_channel_chart1:hover,
#gate_10-20_10-57_channel_chart2:hover,
#gate_10-20_20-57_channel:hover,
#gate_10-20_20-57_channel_chart1:hover,
#gate_10-20_20-57_channel_chart2:hover,
#gate_10-57_20-57_channel:hover,
#gate_10-57_20-57_channel_chart1:hover,
#gate_10-57_20-57_channel_chart2:hover,
#gate_10-20_10-57_20-57_channel:hover,
#gate_10-20_10-57_20-57_channel_chart1:hover,
#gate_10-20_10-57_20-57_channel_chart2:hover,
#gate_10-20_10-34_channel:hover,
#gate_10-20_10-34_channel_chart1:hover,
#gate_10-20_10-34_channel_chart2:hover,
#gate_10-34_20-34_channel:hover,
#gate_10-34_20-34_channel_chart1:hover,
#gate_10-34_20-34_channel_chart2:hover,
#gate_10-20_20-34_channel:hover,
#gate_10-20_20-34_channel_chart1:hover,
#gate_10-20_20-34_channel_chart2:hover,
#gate_10-20_10-34_20-34_channel:hover,
#gate_10-20_10-34_20-34_channel_chart1:hover,
#gate_10-20_10-34_20-34_channel_chart2:hover,
#gate_20-34_20-57_channel:hover,
#gate_20-34_20-57_channel_chart1:hover,
#gate_20-34_20-57_channel_chart2:hover,
#gate_20-34_34-57_channel:hover,
#gate_20-34_34-57_channel_chart1:hover,
#gate_20-34_34-57_channel_chart2:hover,
#gate_20-57_34-57_channel:hover,
#gate_20-57_34-57_channel_chart1:hover,
#gate_20-57_34-57_channel_chart2:hover,
#gate_20-34_20-57_34-57_channel:hover,
#gate_20-34_20-57_34-57_channel_chart1:hover,
#gate_20-34_20-57_34-57_channel_chart2:hover,
#gate_10-34_10-57_channel:hover,
#gate_10-34_10-57_channel_chart1:hover,
#gate_10-34_10-57_channel_chart2:hover,
#gate_10-34_34-57_channel:hover,
#gate_10-34_34-57_channel_chart1:hover,
#gate_10-34_34-57_channel_chart2:hover,
#gate_10-57_34-57_channel:hover,
#gate_10-57_34-57_channel_chart1:hover,
#gate_10-57_34-57_channel_chart2:hover,
#gate_10-34_10-57_34-57_channel:hover,
#gate_10-34_10-57_34-57_channel_chart1:hover,
#gate_10-34_10-57_34-57_channel_chart2:hover,
#gate_10-20_34-57_channel:hover,
#gate_10-20_34-57_channel_chart1:hover,
#gate_10-20_34-57_channel_chart2:hover,
#gate_10-34_20-57_channel:hover,
#gate_10-34_20-57_channel_chart1:hover,
#gate_10-34_20-57_channel_chart2:hover,
#gate_10-57_20-34_channel:hover,
#gate_10-57_20-34_channel_chart1:hover,
#gate_10-57_20-34_channel_chart2:hover,
#gate_10-20_10-34_10-57_channel:hover,
#gate_10-20_10-34_10-57_channel_chart1:hover,
#gate_10-20_10-34_10-57_channel_chart2:hover,
#gate_10-20_10-34_20-57_channel:hover,
#gate_10-20_10-34_20-57_channel_chart1:hover,
#gate_10-20_10-34_20-57_channel_chart2:hover,
#gate_10-20_10-34_34-57_channel:hover,
#gate_10-20_10-34_34-57_channel_chart1:hover,
#gate_10-20_10-34_34-57_channel_chart2:hover,
#gate_10-20_10-57_20-34_channel:hover,
#gate_10-20_10-57_20-34_channel_chart1:hover,
#gate_10-20_10-57_20-34_channel_chart2:hover,
#gate_10-20_10-57_34-57_channel:hover,
#gate_10-20_10-57_34-57_channel_chart1:hover,
#gate_10-20_10-57_34-57_channel_chart2:hover,
#gate_10-20_20-34_20-57_channel:hover,
#gate_10-20_20-34_20-57_channel_chart1:hover,
#gate_10-20_20-34_20-57_channel_chart2:hover,
#gate_10-20_20-34_34-57_channel:hover,
#gate_10-20_20-34_34-57_channel_chart1:hover,
#gate_10-20_20-34_34-57_channel_chart2:hover,
#gate_10-20_20-57_34-57_channel:hover,
#gate_10-20_20-57_34-57_channel_chart1:hover,
#gate_10-20_20-57_34-57_channel_chart2:hover,
#gate_10-34_10-57_20-34_channel:hover,
#gate_10-34_10-57_20-34_channel_chart1:hover,
#gate_10-34_10-57_20-34_channel_chart2:hover,
#gate_10-34_10-57_20-57_channel:hover,
#gate_10-34_10-57_20-57_channel_chart1:hover,
#gate_10-34_10-57_20-57_channel_chart2:hover,
#gate_10-34_20-34_20-57_channel:hover,
#gate_10-34_20-34_20-57_channel_chart1:hover,
#gate_10-34_20-34_20-57_channel_chart2:hover,
#gate_10-34_20-34_34-57_channel:hover,
#gate_10-34_20-34_34-57_channel_chart1:hover,
#gate_10-34_20-34_34-57_channel_chart2:hover,
#gate_10-34_20-57_34-57_channel:hover,
#gate_10-34_20-57_34-57_channel_chart1:hover,
#gate_10-34_20-57_34-57_channel_chart2:hover,
#gate_10-57_20-34_20-57_channel:hover,
#gate_10-57_20-34_20-57_channel_chart1:hover,
#gate_10-57_20-34_20-57_channel_chart2:hover,
#gate_10-57_20-34_34-57_channel:hover,
#gate_10-57_20-34_34-57_channel_chart1:hover,
#gate_10-57_20-34_34-57_channel_chart2:hover,
#gate_10-57_20-57_34-57_channel:hover,
#gate_10-57_20-57_34-57_channel_chart1:hover,
#gate_10-57_20-57_34-57_channel_chart2:hover,
#gate_10-20_10-34_10-57_20-34_channel:hover,
#gate_10-20_10-34_10-57_20-34_channel_chart1:hover,
#gate_10-20_10-34_10-57_20-34_channel_chart2:hover,
#gate_10-20_10-34_10-57_20-57_channel:hover,
#gate_10-20_10-34_10-57_20-57_channel_chart1:hover,
#gate_10-20_10-34_10-57_20-57_channel_chart2:hover,
#gate_10-20_10-34_10-57_34-57_channel:hover,
#gate_10-20_10-34_10-57_34-57_channel_chart1:hover,
#gate_10-20_10-34_10-57_34-57_channel_chart2:hover,
#gate_10-20_10-34_20-34_20-57_channel:hover,
#gate_10-20_10-34_20-34_20-57_channel_chart1:hover,
#gate_10-20_10-34_20-34_20-57_channel_chart2:hover,
#gate_10-20_10-34_20-34_34-57_channel:hover,
#gate_10-20_10-34_20-34_34-57_channel_chart1:hover,
#gate_10-20_10-34_20-34_34-57_channel_chart2:hover,
#gate_10-20_10-34_20-57_34-57_channel:hover,
#gate_10-20_10-34_20-57_34-57_channel_chart1:hover,
#gate_10-20_10-34_20-57_34-57_channel_chart2:hover,
#gate_10-20_10-57_20-34_20-57_channel:hover,
#gate_10-20_10-57_20-34_20-57_channel_chart1:hover,
#gate_10-20_10-57_20-34_20-57_channel_chart2:hover,
#gate_10-20_10-57_20-34_34-57_channel:hover,
#gate_10-20_10-57_20-34_34-57_channel_chart1:hover,
#gate_10-20_10-57_20-34_34-57_channel_chart2:hover,
#gate_10-20_10-57_20-57_34-57_channel:hover,
#gate_10-20_10-57_20-57_34-57_channel_chart1:hover,
#gate_10-20_10-57_20-57_34-57_channel_chart2:hover,
#gate_10-34_10-57_20-34_20-57_channel:hover,
#gate_10-34_10-57_20-34_20-57_channel_chart1:hover,
#gate_10-34_10-57_20-34_20-57_channel_chart2:hover,
#gate_10-34_10-57_20-34_34-57_channel:hover,
#gate_10-34_10-57_20-34_34-57_channel_chart1:hover,
#gate_10-34_10-57_20-34_34-57_channel_chart2:hover,
#gate_10-34_10-57_20-57_34-57_channel:hover,
#gate_10-34_10-57_20-57_34-57_channel_chart1:hover,
#gate_10-34_10-57_20-57_34-57_channel_chart2:hover,
#gate_10-20_20-34_20-57_34-57_channel:hover,
#gate_10-20_20-34_20-57_34-57_channel_chart1:hover,
#gate_10-20_20-34_20-57_34-57_channel_chart2:hover,
#gate_10-34_20-34_20-57_34-57_channel:hover,
#gate_10-34_20-34_20-57_34-57_channel_chart1:hover,
#gate_10-34_20-34_20-57_34-57_channel_chart2:hover,
#gate_10-57_20-34_20-57_34-57_channel:hover,
#gate_10-57_20-34_20-57_34-57_channel_chart1:hover,
#gate_10-57_20-34_20-57_34-57_channel_chart2:hover,
#gate_10-20_10-34_10-57_20-34_20-57_channel:hover,
#gate_10-20_10-34_10-57_20-34_20-57_channel_chart1:hover,
#gate_10-20_10-34_10-57_20-34_20-57_channel_chart2:hover,
#gate_10-20_10-34_10-57_20-34_34-57_channel:hover,
#gate_10-20_10-34_10-57_20-34_34-57_channel_chart1:hover,
#gate_10-20_10-34_10-57_20-34_34-57_channel_chart2:hover,
#gate_10-20_10-34_10-57_20-57_34-57_channel:hover,
#gate_10-20_10-34_10-57_20-57_34-57_channel_chart1:hover,
#gate_10-20_10-34_10-57_20-57_34-57_channel_chart2:hover,
#gate_10-20_10-34_20-34_20-57_34-57_channel:hover,
#gate_10-20_10-34_20-34_20-57_34-57_channel_chart1:hover,
#gate_10-20_10-34_20-34_20-57_34-57_channel_chart2:hover,
#gate_10-20_10-57_20-34_20-57_34-57_channel:hover,
#gate_10-20_10-57_20-34_20-57_34-57_channel_chart1:hover,
#gate_10-20_10-57_20-34_20-57_34-57_channel_chart2:hover,
#gate_10-34_10-57_20-34_20-57_34-57_channel:hover,
#gate_10-34_10-57_20-34_20-57_34-57_channel_chart1:hover,
#gate_10-34_10-57_20-34_20-57_34-57_channel_chart2:hover,
#gate_10-20_10-34_10-57_20-34_20-57_34-57_channel:hover,
#gate_10-20_10-34_10-57_20-34_20-57_34-57_channel_chart1:hover,
#gate_10-20_10-34_10-57_20-34_20-57_34-57_channel_chart2:hover,
#gate_05_15_channel:hover,
#gate_05_15_channel_chart1:hover,
#gate_05_15_channel_chart2:hover,
#gate_06_59_channel:hover,
#gate_06_59_channel_chart1:hover,
#gate_06_59_channel_chart2:hover,
#gate_19_49_channel:hover,
#gate_19_49_channel_chart1:hover,
#gate_19_49_channel_chart2:hover,
#gate_39_55_channel:hover,
#gate_39_55_channel_chart1:hover,
#gate_39_55_channel_chart2:hover,
#gate_30_41_channel:hover,
#gate_30_41_channel_chart1:hover,
#gate_30_41_channel_chart2:hover,
#gate_09_52_channel:hover,
#gate_09_52_channel_chart1:hover,
#gate_09_52_channel_chart2:hover,
#gate_03_60_channel:hover,
#gate_03_60_channel_chart1:hover,
#gate_03_60_channel_chart2:hover,
#gate_42_53_channel:hover,
#gate_42_53_channel_chart1:hover,
#gate_42_53_channel_chart2:hover,
#gate_27_50_channel:hover,
#gate_27_50_channel_chart1:hover,
#gate_27_50_channel_chart2:hover,
#gate_29_46_channel:hover,
#gate_29_46_channel_chart1:hover,
#gate_29_46_channel_chart2:hover,
#gate_02_14_channel:hover,
#gate_02_14_channel_chart1:hover,
#gate_02_14_channel_chart2:hover,
.gate_hover {
  stroke-opacity: 1;
}
.blink_me {
  display: block !important;
  animation: blinker 3s linear infinite;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}
@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.chart_toggle {
  stroke: #4caf50;
}
.chart_toggle_cir {
  fill: #4caf50;
}
.chart2_toggle {
  stroke: #fb8c00;
}
.chart2_toggle_cir {
  fill: #fb8c00;
}
.chart1-rect,
.chart2-rect {
  cursor: pointer;
  width: 75px;
  height: 33px;
  fill: transparent;
  stroke: transparent;
}
.not_active_toggle {
  stroke: #bbb;
  /* stroke-opacity: 0.37; */
  transition: stroke-opacity 0.25s;
}
.not_active_chart {
  fill: #bbb;
  cx: 90 !important;
  transition: opacity 0.25s;
}
.not_active_chart2 {
  fill: #bbb;
  /* opacity: 0.37; */
  cx: 781.5 !important;
  transition: opacity 0.25s;
}
.red_black_25 {
  fill: url(#bg-red-black25) !important;
}
.red_black__25 {
  fill: url(#bg-red-black-25) !important;
}
.red_black_35 {
  fill: url(#bg-red-black35) !important;
}
.red_black__35 {
  fill: url(#bg-red-black-35) !important;
}
.red_black_50 {
  fill: url(#bg-red-black50) !important;
}
.red_black_70 {
  fill: url(#bg-red-black70) !important;
}
.red_black_90 {
  fill: url(#bg-red-black90) !important;
}
.red_black_120 {
  fill: url(#bg-red-black120) !important;
}
.red_black_128 {
  fill: url(#bg-red-black128) !important;
}
.red_black_135 {
  fill: url(#bg-red-black135) !important;
}
.red_black_150 {
  fill: url(#bg-red-black150) !important;
}
.noactive-comp {
  height: 0 !important;
  padding: 0 !important;
}
</style>
