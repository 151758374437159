<template>
  <!-- v-layout column justify-center align-center>
    <Login
      :closeButton.sync="closeButtonAttr"
      :email.sync="email"
      :activeNameTab.sync="activeNameTab"
      @close="closeDialog"
    />
    <v-btn @click="showDialog = true">Login/Reg</v-btn>
    <LoginDialog :showDialog.sync="showDialog" @close="closeDialog" />
    <MenuBottom />
  </v-layout -->
  <v-card flat d-flex pa-2 class="card-md card-md-filled" max-width="950">
    <v-skeleton-loader
      v-if="preloading"
      height="48"
      type="text"
      class="mx-auto flex-v-center"
    >
    </v-skeleton-loader>
    <v-card-title
      v-else
      class="headline padding-bottom-0"
      style="font-size: 1rem !important;flex-direction:column;align-items: flex-start"
    >
      <v-row v-if="reportsHistoryCount <= 3" style="margin: 15px 0;width:100%">
        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
          <div class="help-flex-tb">
            <span>Заполните поля, чтобы создать карту</span>
            <span>
              <v-icon
                @click="informationDialog"
                style="font-size:19px;color:#fb8c01;margin-left:7px;padding:0"
              >
                mdi-help-circle-outline
              </v-icon>
            </span>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="6"
          lg="6"
          xl="6"
          class="flex-align-right no-mobile"
        >
          <div class="help-flex-tb">
            <span>Я не знаю точного времени рождения</span>
            <span>
              <v-icon
                @click="information2Dialog"
                style="font-size:19px;color:#fb8c01;margin-left:7px;padding:0"
              >
                mdi-help-circle-outline
              </v-icon>
            </span>
          </div>
        </v-col>
      </v-row>
      <div v-if="reportsHistoryCount > 3" class="margin-top-15">
        <v-btn
          @click="reportHistoryDialog = true"
          rounded
          small
          color="primary"
          class="hd-gradient"
          style="margin: 5px 0 15px 0"
        >
          история расчетов
        </v-btn>
      </div>
    </v-card-title>
    <form>
      <v-card-text class="card-md padding-top-0 padding-bottom-0">
        <v-row>
          <v-col cols="4" sm="2" md="2" lg="2" xl="2">
            <v-skeleton-loader
              v-if="preloading"
              height="64"
              type="text"
              class="mx-auto flex-v-center"
            >
            </v-skeleton-loader>
            <v-select
              v-on:change="onDayChange"
              v-else
              v-model="day"
              :hint="`день`"
              :items="days"
              item-text="day"
              label="Выберите день"
              persistent-hint
              return-object
              single-line
              required
              menu-props="auto, bottom, offset-y"
            ></v-select>
          </v-col>
          <v-col cols="4" sm="4" md="4" lg="4" xl="4">
            <v-skeleton-loader
              v-if="preloading"
              height="64"
              type="text"
              class="mx-auto flex-v-center"
            >
            </v-skeleton-loader>
            <v-select
              ref="monthSelect"
              v-on:change="onMonthChange"
              v-else
              v-model="month"
              :hint="`месяц`"
              :items="months"
              item-text="month"
              label="Выберите месяц"
              persistent-hint
              return-object
              single-line
              required
              menu-props="auto, bottom, offset-y"
            ></v-select>
          </v-col>
          <v-col cols="4" sm="2" md="2" lg="2" xl="2">
            <v-skeleton-loader
              v-if="preloading"
              height="64"
              type="text"
              class="mx-auto flex-v-center"
            >
            </v-skeleton-loader>
            <v-select
              ref="yearSelect"
              v-on:change="onYearChange"
              v-else
              v-model="year"
              :hint="`год`"
              :items="years"
              item-text="year"
              label="Выберите год"
              persistent-hint
              return-object
              single-line
              required
              menu-props="auto, bottom, offset-y"
              open-on-clear
            ></v-select>
          </v-col>
          <v-col
            v-if="reportsHistoryCount <= 3"
            cols="12"
            sm="12"
            md="6"
            lg="6"
            xl="6"
            class="flex-align-right no-desktop"
          >
            <div class="help-flex-tb margin-top-15">
              <span>Я не знаю точного времени рождения</span>
              <span>
                <v-icon
                  @click="information2Dialog"
                  style="font-size:19px;color:#fb8c01;margin-left:7px"
                >
                  mdi-help-circle-outline
                </v-icon>
              </span>
            </div>
          </v-col>
          <v-col cols="6" sm="2" md="2" lg="2" xl="2">
            <v-skeleton-loader
              v-if="preloading"
              height="64"
              type="text"
              class="mx-auto flex-v-center"
            >
            </v-skeleton-loader>
            <v-select
              ref="hourSelect"
              v-on:change="onHourChange"
              v-else
              v-model="hour"
              :hint="`часы`"
              :items="hours"
              item-text="hour"
              label="Выберите час"
              persistent-hint
              return-object
              single-line
              required
              menu-props="auto, bottom, offset-y"
            ></v-select>
          </v-col>
          <v-col cols="6" sm="2" md="2" lg="2" xl="2">
            <v-skeleton-loader
              v-if="preloading"
              height="64"
              type="text"
              class="mx-auto flex-v-center"
            >
            </v-skeleton-loader>
            <v-select
              ref="minuteSelect"
              v-on:change="onMinuteChange"
              v-else
              v-model="minute"
              :hint="`минуты`"
              :items="minutes"
              item-text="minute"
              label="Выберите минуту"
              persistent-hint
              return-object
              single-line
              required
              menu-props="auto, bottom, offset-y"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="6" xl="6">
            <v-skeleton-loader
              v-if="preloading"
              height="64"
              type="text"
              class="mx-auto flex-v-center"
            >
            </v-skeleton-loader>
            <v-autocomplete
              ref="cityInput"
              v-on:change="onCityChange"
              v-else
              v-model="city"
              :items="cities"
              :loading="isLoading"
              :search-input.sync="search"
              :error-messages="cityErrors"
              :no-filter="true"
              @input="$v.city.$touch()"
              @blur="$v.city.$touch()"
              chips
              clearable
              hide-details
              hide-selected
              item-text="title"
              item-value="id"
              label="Город рождения"
              name="someusercity"
              required
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title v-if="!search || isLoading">
                    Ближайший крупный <strong>город</strong>...
                  </v-list-item-title>
                  <v-list-item-title v-else class="v-list-item-title-custom">
                    <span
                      v-if="!searchResult"
                      v-html="getMessage('tooltip_find_city')"
                    ></span>
                  </v-list-item-title>
                </v-list-item>
              </template>
              <template v-slot:selection="{ attr, on, item, selected }">
                <v-chip
                  v-bind="attr"
                  v-on="on"
                  :input-value="selected"
                  color="white"
                  class="black--text"
                >
                  <span v-text="item.title"></span>&nbsp;
                  <img
                    :src="
                      'https://purecatamphetamine.github.io/country-flag-icons/3x2/' +
                        item.country.iso +
                        '.svg'
                    "
                    height="15"
                  />
                </v-chip>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="item.country.title"
                  ></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <img
                    :src="
                      'https://purecatamphetamine.github.io/country-flag-icons/3x2/' +
                        item.country.iso +
                        '.svg'
                    "
                    height="15"
                  />
                </v-list-item-action>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="2" class="no-mobile"></v-col>
          <v-col cols="12" sm="4" md="4" lg="4" xl="4">
            <v-skeleton-loader
              v-if="preloading"
              height="64"
              type="text"
              class="mx-auto flex-v-center"
            >
            </v-skeleton-loader>
            <v-text-field
              ref="firstnameInput"
              v-else
              v-model="firstname"
              :error-messages="nameErrors"
              :counter="35"
              @input="$v.firstname.$touch()"
              @blur="$v.firstname.$touch()"
              height="42"
              label="Имя"
              required
            ></v-text-field>
          </v-col>
          <!-- <v-col
            class="text-center display-0-24-no-line-h"
            cols="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
          >
            У вас осталось
            {{ $store.state.account.User.full_tooltips_count }} бесплатных расчетов.
          </v-col> -->
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row
          v-if="
            isLogged &&
              $store.state.account.User.is_subscribed_to_justclick &&
              Profile.full_tooltips_count > 0
          "
          class="d-flex justify-center"
        >
          <v-col
            cols="12"
            sm="6"
            md="6"
            lg="6"
            xl="6"
            class="text-center padding-top-0 mob-height-25 save-result-to-profile"
          >
            <v-skeleton-loader
              v-if="preloading"
              height="35"
              type="button"
              class="mx-auto flex-center"
            >
            </v-skeleton-loader>
            <div v-else>
              <v-checkbox
                v-if="
                  isLogged &&
                    $store.state.account.User.is_subscribed_to_justclick
                "
                v-model="isStored"
                label="Сохранить результат в кабинете"
                class="save-report-checkbox"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="2" class="no-mobile"></v-col>
          <v-col
            cols="12"
            sm="4"
            md="4"
            lg="4"
            xl="4"
            class="text-center padding-top-0"
          >
            <v-skeleton-loader
              v-if="preloading"
              height="35"
              type="button"
              class="mx-auto flex-center"
            >
            </v-skeleton-loader>
            <div v-else>
              <div class="full-tooltips-switcher">
                <FullTooltipsSwitcher :isHelpText="true" />
              </div>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            class="text-center padding-top-0"
          >
            <v-skeleton-loader
              v-if="preloading"
              height="35"
              type="button"
              class="mx-auto flex-center"
            >
            </v-skeleton-loader>
            <div v-else class="margin-top-5">
              <v-btn
                @click="validate"
                rounded
                large
                color="primary"
                class="v-btn-custom hd-gradient v-btn-custom--bg-f mob-width-97 margin-bottom-30"
              >
                Построить карту&nbsp;<img
                  src="https://static.human-design.space/widget/images/svg/bodygraph-icon.svg"
                  width="20"
                />
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row v-else class="d-flex justify-center">
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            class="text-center padding-top-0"
          >
            <v-skeleton-loader
              v-if="preloading"
              height="35"
              type="button"
              class="mx-auto flex-center"
            >
            </v-skeleton-loader>
            <div v-else class="margin-top-25">
              <v-checkbox
                v-if="
                  isLogged &&
                    $store.state.account.User.is_subscribed_to_justclick
                "
                v-model="isStored"
                label="Сохранить результат в кабинете"
                style="padding-left:5px;margin-top:0"
              ></v-checkbox>
              <v-btn
                @click="validate"
                rounded
                large
                color="primary"
                class="v-btn-custom hd-gradient v-btn-custom--bg-f mob-width-97 margin-bottom-30"
              >
                Построить карту&nbsp;<img
                  src="https://static.human-design.space/widget/images/svg/bodygraph-icon.svg"
                  width="20"
                />
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-actions>
    </form>
    <ReportsHistoryDialog
      :reportsHistoryDialogIncome.sync="reportHistoryDialog"
      @closeReportsDialog="closeReportsDialog"
    />
  </v-card>
</template>

<script>
import Api from "@/api/api";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import { commonInitAppMethods } from "@/mixins/common-init-app-methods";
import ReportsHistoryDialog from "@/components/dialogs/ReportsHistoryDialog";
import FullTooltipsSwitcher from "@/components/parts/FullTooltipsSwitcher.vue";
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
// import Login from "@/components/forms/Login.vue";
// import LoginDialog from "@/components/dialogs/LoginDialog.vue";

export default {
  name: "Home",
  components: {
    // HelloWorld
    // LoginDialog,
    ReportsHistoryDialog,
    FullTooltipsSwitcher
  },
  mixins: [validationMixin, commonInitAppMethods],
  validations: {
    firstname: { required, maxLength: maxLength(35) },
    day: { required },
    month: { required },
    year: { required },
    hour: { required },
    minute: { required },
    city: { required }
  },
  data: () => ({
    firstname: "",
    preloading: true,
    isLoading: false,
    cities: [],
    city: null,
    search: null,
    searchResult: true,
    day: { day: "15" },
    month: { month: "Июнь (6)", id: "06" },
    year: { year: "1990" },
    hour: { hour: "12" },
    minute: { minute: "30" },
    isStored: false,
    showDialog: false,
    showSuccessDialog: false,
    showTooltipDialog: {},
    closeButtonAttr: false,
    reportHistoryDialog: false
  }),
  computed: {
    ...mapState(["dateTime"]),
    ...mapState({
      User: (state) => state.account.User,
      Profile: (state) => state.account.Profile,
      ConfApp: (state) => state.conf.ConfApp,
      HttpOptions: (state) => state.conf.http_options,
      DarkTheme: (state) => state.conf.DarkTheme
    }),
    activeNameTab: {
      get() {
        return this.$store.state.account.User.activeNameTab;
      },
      set(value) {
        this.$store.commit("SET_ACTIVE_NAME_TAB", value);
      }
    },
    email: {
      get() {
        return this.$store.state.account.User.email;
      },
      set(value) {
        this.$store.commit("SET_EMAIL", value);
      }
    },
    days() {
      return this.dateTime.days;
    },
    months() {
      return this.dateTime.months;
    },
    years() {
      return this.cutAllYearsMoreCurrent(this.dateTime.years);
    },
    hours() {
      return this.dateTime.hours;
    },
    minutes() {
      return this.dateTime.minutes;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.firstname.$dirty) return errors;
      !this.$v.firstname.maxLength &&
        errors.push("Имя должно быть не более 35 символов.");
      !this.$v.firstname.required && errors.push("Введите имя.");
      return errors;
    },
    cityErrors() {
      const errors = [];
      if (!this.$v.city.$dirty) return errors;
      !this.$v.city.required && errors.push("Выберите город.");
      return errors;
    },
    isProTooltips: {
      get() {
        return this.$store.state.account.User.isProTooltips;
      },
      set(bulean) {
        this.$store.commit("IS_RPO_TOOLTIPS", bulean);
      }
    },
    isLogged: {
      get() {
        return this.$store.state.account.User.isLogged;
      },
      set(bulean) {
        this.$store.commit("account/login_success", bulean);
      }
    },
    reportsHistoryCount() {
      return this.$store.state.account.User.reportsHistory.totalCount;
    }
  },
  watch: {
    search(val) {
      if (!val) return;
      this.fetchCitiesDebounced(val);
    },
    isLogged(val) {
      if (!val) return;
      this.getGetProfile();
    }
  },
  mounted() {
    this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case "account/update_first_name":
          this.firstname = state.account.User.name;
          break;
        case "UPDATE_YEAR":
          this.year = state.account.User.year;
          break;
        case "UPDATE_MONTH":
          this.month = state.account.User.month;
          break;
        case "UPDATE_DAY":
          this.day = state.account.User.day;
          break;
        case "UPDATE_HOUR":
          this.hour = state.account.User.hour;
          break;
        case "UPDATE_MINUTE":
          this.minute = state.account.User.minute;
          break;
        case "UPDATE_CITY_ID":
          this.city = state.account.User.cityid;
          break;
        case "account/update_last_cities":
          this.cities = state.account.User.lastCities;
          break;
        case "GET_BODY_GRAPH_HASH":
          if (state.ext_report_hash) {
            this.getBodygraphByHash(state.ext_report_hash);
          } else {
            this.getBodygraph(state.account.User.reportid, "get");
          }
          break;
        case "GET_BODY_GRAPH_UP_HASH":
          this.getBodygraphByHash(state.ext_user_product_hash);
          break;
        case "GET_COMPOSITE_BY_HASH":
          if (state.ext_composite_hash) {
            this.getComposite();
          }
          break;
        case "GET_TRANSIT_BY_HASH":
          if (state.ext_transit_hash) {
            this.getTransit();
          }
          break;
        case "GET_INFLICTION_BY_HASH":
          if (state.ext_infliction_hash) {
            this.getTransit();
          }
          break;
        case "GET_RETURN_BY_HASH":
          if (state.ext_return_hash) {
            this.getReturn();
          }
          break;
        case "account/get_body_graph_just_click":
          this.getBodygraph(state.User.reportid, state.getBodygraph);
          break;
        case "account/get_body_graph":
          this.getBodygraph(state.User.reportid, state.getBodygraph, 1);
          break;
        case "SET_APP_THEME_COLOR":
          this.setColor(state.ConfApp.theme);
          break;
      }
    });
    this.verify();
    this.initConfApp();
    this.$store.commit("account/update_path_history");
    // Get rave report by hash
    if (this.$route.query.report_hash) {
      this.getRaveReportByHash(this.$route.query.report_hash);
    }
    // Get composite by hash
    if (this.$route.query.composite_hash) {
      this.getCompositeByHash(this.$route.query.composite_hash);
    }
    // Get transit report by hash
    if (this.$route.query.transit_hash) {
      this.getTransitByHash(this.$route.query.transit_hash);
    }
    // Get infliction report by hash
    if (this.$route.query.infliction_hash) {
      this.getInflictionByHash(this.$route.query.infliction_hash);
    }
    // Get return report by hash
    if (this.$route.query.return_hash) {
      this.getReturnByHash(this.$route.query.return_hash);
    }
    setTimeout(() => {
      this.preloading = false;
    });
  },
  methods: {
    updateCityId(val) {
      // console.log(val)
      this.$store.commit("UPDATE_CITY_ID", val);
    },
    fetchCitiesDebounced(val) {
      this.search = this.searchRequestProcessing(val);
      this.isLoading = true;
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        this.fetchCities(this.search);
        this.isLoading = false;
      }, 500); /* 500ms throttle */
    },
    async fetchCities(searchrequest) {
      if (searchrequest) {
        try {
          const result = await Api.searchCity(searchrequest, this.HttpOptions);
          if (result.success) {
            this.cities = result.cities;
            this.citiesMemoization(result.cities);
            if (!this.cities.length) {
              this.searchResult = false;
            }
          }
        } catch (e) {
          this.$store.dispatch("errorHandler", e);
        }
      }
    },
    onDayChange() {
      this.$refs.monthSelect.activateMenu();
      this.$refs.monthSelect.focus();
    },
    onMonthChange() {
      this.$refs.yearSelect.activateMenu();
      this.$refs.yearSelect.focus();
    },
    onYearChange() {
      this.$refs.hourSelect.activateMenu();
      this.$refs.hourSelect.focus();
    },
    onHourChange() {
      this.$refs.minuteSelect.activateMenu();
      this.$refs.minuteSelect.focus();
    },
    onMinuteChange() {
      this.$refs.cityInput.focus();
      this.$refs.cityInput.focus();
    },
    onCityChange() {
      this.$refs.cityInput.blur();
      this.$refs.firstnameInput.focus();
    },
    validate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        // this.getBodygraph(this.User.reportid, "", 1);
        this.createReport();
      }
    },
    async createReport() {
      const birthday =
        this.year.year +
        "-" +
        this.month.id +
        "-" +
        this.day.day +
        " " +
        this.hour.hour +
        ":" +
        this.minute.minute;
      const reportParams = {
        birthday,
        city_id: this.city,
        first_name: this.firstname,
        email: this.$store.state.account.User.email,
        is_stored: this.isStored
      };
      const successResult = await this.$store.dispatch(
        "account/createRaveReport",
        reportParams
      );
      if (successResult) {
        this.$router.push("/bodygraph");
        // firstbgdialog
        if (!localStorage.getItem("firstBgDialog") && !this.User.isLogged) {
          localStorage.setItem("firstBgDialog", true);
          localStorage.setItem("blinkFirstime", true);
          setTimeout(() => {
            this.$store.commit("account/update_first_bg_dialog", true);
          }, 0);
        }
        // Save name for registration
        this.$store.commit("account/update_name", this.firstname);
      }
    },
    async getBodygraph(reportid, action, tab) {
      try {
        // Prefetch Cities, UserName, DD/MM/YYYY HH:MM to localStorage
        localStorage.setItem("name", this.firstname);
        localStorage.setItem("lastCities", JSON.stringify(this.cities));
        this.$store.commit("account/update_last_cities", this.cities);
        localStorage.setItem("cityId", JSON.stringify(this.city));
        localStorage.setItem("day", JSON.stringify(this.day));
        localStorage.setItem("month", JSON.stringify(this.month));
        localStorage.setItem("year", JSON.stringify(this.year));
        localStorage.setItem("hour", JSON.stringify(this.hour));
        localStorage.setItem("minute", JSON.stringify(this.minute));
        if (action === "get" && !reportid) {
          return;
        }
        let apiMethod;
        action === "get"
          ? (apiMethod = this.getRaveReport)
          : (apiMethod = this.createRaveReport);
        if (action === "create" || action !== "get" || action === "") {
          reportid = "";
          // сброс user-product-id, при создании нового репорта, если до этого
          // загружался (get) платный репорт, для корректного расчета транзита & etc
          localStorage.removeItem("userProductId");
          this.$store.commit("UPDATE_USER_PRODUCT_ID", "");
        }
        const birthday =
          this.year.year +
          "-" +
          this.month.id +
          "-" +
          this.day.day +
          " " +
          this.hour.hour +
          ":" +
          this.minute.minute;
        const reportParams = {
          birthday,
          city_id: this.city,
          first_name: this.firstname,
          email: this.$store.state.account.User.email,
          report_id: reportid,
          is_stored: this.isStored
        };
        let result;
        if (action !== "get") {
          result = await apiMethod.post(reportParams);
        } else {
          result = await apiMethod.get(reportParams);
        }
        if (result.success) {
          if (action !== "get") {
            // Refresh appStorage chartBodygraph, Tooltips, Report Id & Stored
            this.$store.commit(
              "UPDATE_CHART_BODYGRAPH",
              result.report.body_graph
            );
            this.$store.commit("UPDATE_TOOLTIPS", result.report.tooltips);
            this.$store.commit("UPDATE_REPORT_ID", result.report.id);
            this.$store.commit("UPDATE_IS_STORED", result.report.is_stored);
            this.$matchChannels();
            // Save to localStorage chartBodygraph, Tooltips, Report Id & Stored
            localStorage.setItem(
              "chartBodygraph",
              JSON.stringify(result.report.body_graph)
            );
            localStorage.setItem(
              "toolTips",
              JSON.stringify(result.report.tooltips)
            );
            localStorage.setItem(
              "city",
              JSON.stringify(result.report.city.title)
            );
            localStorage.setItem("reportId", result.report.id);
            localStorage.setItem("is_stored", result.report.is_stored);
            localStorage.setItem("age", result.report.age);
            // Refresh Birthday in $store
            this.$store.commit("UPDATE_DAY", {
              day: result.report.birthday.slice(8, 10)
            });
            this.$store.commit(
              "UPDATE_MONTH",
              this.months[result.report.birthday.slice(5, 7) - 1]
            );
            this.$store.commit("UPDATE_YEAR", {
              year: result.report.birthday.slice(0, 4)
            });
            this.$store.commit("UPDATE_HOUR", {
              hour: result.report.birthday.slice(11, 13)
            });
            this.$store.commit("UPDATE_MINUTE", {
              minute: result.report.birthday.slice(14, 16)
            });
          } else {
            // Refresh appStorage chartBodygraph, Tooltips, Report Id & Stored
            this.$store.commit(
              "UPDATE_CHART_BODYGRAPH",
              result.report.body_graph
            );
            this.$store.commit("UPDATE_TOOLTIPS", result.report.tooltips);
            this.$matchChannels();
            // Save to localStorage chartBodygraph, Tooltips, Report Id & Stored
            localStorage.setItem(
              "chartBodygraph",
              JSON.stringify(result.report.body_graph)
            );
            localStorage.setItem(
              "toolTips",
              JSON.stringify(result.report.tooltips)
            );
            this.$store.commit("UPDATE_REPORT_ID", result.report.id);
            this.$store.commit("UPDATE_IS_STORED", result.report.is_stored);
            localStorage.setItem("reportId", result.report.id);
            localStorage.setItem("is_stored", result.report.is_stored);
            localStorage.setItem("age", result.report.age);
          }
          this.$store.commit("UPDATE_CITY_ID", result.report.city.id);
          this.$store.commit(
            "account/update_first_name",
            result.report.first_name
          );
          this.$store.commit("UPDATE_USER_AGE", result.report.age);
          if (tab) {
            this.$store.commit("UPDATE_CONF_APP_TAB", tab);
          }
          // firstbgdialog
          if (!localStorage.getItem("firstBgDialog") && !this.User.isLogged) {
            localStorage.setItem("firstBgDialog", true);
            localStorage.setItem("blinkFirstime", true);
            setTimeout(() => {
              this.$store.commit("account/update_first_bg_dialog", true);
            }, 0);
          }
          // blinkFirstime
          if (!localStorage.getItem("blinkFirstime")) {
            localStorage.setItem("blinkFirstime", true);
            this.$store.commit("UPDATE_BLINK_FIRST_TIME", false);
          }
          localStorage.setItem(
            "tooltip_button_type",
            result.report.tooltip_button_type
          );
          this.$store.commit(
            "UPDATE_TOOLTIPS_BUTTON_TYPE",
            result.report.tooltip_button_type
          );
          localStorage.setItem("reportHash", result.report.report_hash);
          this.$store.commit("UPDATE_REPORT_HASH", result.report.report_hash);
          localStorage.setItem("confApp", JSON.stringify(this.ConfApp));
          this.$store.commit("UPDATE_FRAME_DATA", { scroll: true });
          this.$store.dispatch("СALL_MAGICK_ZOOM");
          this.isStored = false;
          // console.log(this.chartBodygraph.mandalas[1][2].tone) //Design tone Sun
        }
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
      }
    },
    async getBodygraphByHash(hash) {
      try {
        if (localStorage.getItem("authToken")) {
          this.$store.commit(
            "account/update_token",
            localStorage.getItem("authToken")
          );
        }
        const reportHash = {};
        let apiMethod;
        this.$store.state.ext_user_product_hash
          ? (apiMethod = this.$userProductAction)
          : (apiMethod = this.$getSharedRaveReport);
        this.$store.state.ext_user_product_hash
          ? (reportHash.user_product_hash = hash)
          : (reportHash.report_hash = hash);
        const result = await apiMethod.get(reportHash);
        if (result.success) {
          // Refresh appStorage chartBodygraph, Tooltips, Report Id & Stored
          this.$store.commit(
            "account/update_chart_bodygraph",
            result.report.body_graph
          );
          this.$store.commit("account/update_day", {
            day: result.report.birthday.slice(8, 10)
          });
          this.$store.commit(
            "account/update_month",
            this.months[result.report.birthday.slice(5, 7) - 1]
          );
          this.$store.commit("account/update_year", {
            year: result.report.birthday.slice(0, 4)
          });
          this.$store.commit("account/update_hour", {
            hour: result.report.birthday.slice(11, 13)
          });
          this.$store.commit("account/update_minute", {
            minute: result.report.birthday.slice(14, 16)
          });
          this.$matchChannels();
          this.$store.commit(
            "conf/update_tooltips_button_type",
            result.report.tooltip_button_type
          );
          this.$store.commit("account/update_tooltips", result.report.tooltips);
          this.$store.commit("account/update_report_id", result.report.id);
          this.$store.commit(
            "account/update_is_stored",
            result.report.is_stored
          );
          this.$store.state.account.User.lastCities = [result.report.city];
          this.cities = [result.report.city];
          this.$store.commit("account/update_city_id", result.report.city.id);
          this.$store.commit("account/update_name", result.report.first_name);
          localStorage.setItem("age", result.report.age);
          this.$store.commit("account/update_user_age", result.report.age);
          this.$router.push("/");
          // console.log(this.chartBodygraph.mandalas[1][2].tone) //Design tone Sun
        }
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
      }
    },
    getGetProfile() {
      if (this.User.isLogged) {
        this.$store.dispatch("account/getProfile");
      }
    },
    getComposite() {
      this.$router.push("/composite");
    },
    getTransit() {
      this.$router.push("/transit");
    },
    getReturn() {
      this.$router.push("/returns");
    },
    informationDialog() {
      this.$store.commit("conf/update_service_close_modal", {
        message: this.getMessage("intro")
      });
    },
    information2Dialog() {
      this.$store.commit("conf/update_service_close_modal", {
        message: this.getMessage("help_i_not_have_birthday")
      });
    },
    setColor(color) {
      if (color === "dark") {
        this.$vuetify.theme.dark = true;
        this.switchDarkTheme = true;
        this.$store.commit("conf/update_dark_theme", true);
        localStorage.setItem("confApp", JSON.stringify(this.ConfApp));
      } else if (color === "light") {
        this.$vuetify.theme.dark = false;
        this.switchDarkTheme = false;
        this.$store.commit("conf/update_dark_theme", false);
        localStorage.setItem("confApp", JSON.stringify(this.ConfApp));
      }
    },
    getReportHistory() {
      this.$store.commit("UPDATE_REPORTS_HISTORY_DIALOG", true);
    },
    closeDialog(val) {
      // console.log("emitReg: ", val);
      if (val === "regSuccess") {
        this.showSuccessDialog = true;
      }
      this.showDialog = false;
    },
    closeReportsDialog() {
      this.reportHistoryDialog = false;
    },
    async getRaveReportByHash(hash) {
      const successResult = await this.$store.dispatch(
        "account/getRaveReportByHash",
        hash
      );
      if (successResult) {
        this.$router.push("/bodygraph");
      }
    },
    async getCompositeByHash(hash) {
      const successResult = await this.$store.dispatch(
        "account/getCompositeByHash",
        hash
      );
      if (successResult) {
        this.$router.push("/composite");
      }
    },
    getTransitByHash(hash) {
      this.$store.commit("account/set_ext_transit_hash", hash);
      this.$router.push("/transit");
    },
    getInflictionByHash(hash) {
      this.$store.commit("account/set_ext_infliction_hash", hash);
      this.$router.push("/transit");
    },
    async getReturnByHash(hash) {
      this.$store.commit("account/set_ext_return_by_hash", hash);
      this.$router.push("/returns");
    }
  }
};
</script>
<style scoped>
.help-flex-tb {
  display: flex;
  justify-content: center;
  width: 300px;
  max-height: 30px;
}
.theme--dark .help-flex-tb {
  color: #85a3b1;
}
.help-flex-tb span {
  font-size: 15px;
}
.save-result-to-profile {
  margin: 15px 0 0 0;
}
.full-tooltips-switcher {
  display: flex;
  margin: 30px 0 0 0;
}
@media screen and (min-width: 280px) and (max-width: 430px) {
  .help-flex-tb {
    width: 100%;
    font-size: 0.7rem;
  }
  .save-result-to-profile {
    margin: 25px 0 20px 0;
  }
  .full-tooltips-switcher {
    margin: 15px 0 25px 9px;
  }
}
</style>
